import { DistributorInformation } from '../types';

export type DistributorInfosState = {
    list: DistributorInformation[];
    byId: { [id: string]: DistributorInformation };
};

const initialState: DistributorInfosState = {
    list: [],
    byId: {},
};

type Action =
    | {
          type: 'LOAD_DISTRIBUTOR_INFOS_SUCCESS';
          distributorinfos: DistributorInformation[];
      }
    | {
          type: 'UPDATE_DISTRIBUTOR_INFO';
          distributorinfo: DistributorInformation;
      };

export default function distributorinfosReducer(
    state = initialState,
    action: Action,
): DistributorInfosState {
    switch (action.type) {
        case 'LOAD_DISTRIBUTOR_INFOS_SUCCESS': {
            const byId: { [id: string]: DistributorInformation } = {};
            action.distributorinfos.forEach((distributorinfo) => {
                byId[distributorinfo.id] = distributorinfo;
            });
            return { ...state, list: action.distributorinfos, byId };
        }
        case 'UPDATE_DISTRIBUTOR_INFO': {
            const byId = { ...state.byId };
            byId[action.distributorinfo.id] = action.distributorinfo;

            // find and update in list
            const list = [...state.list];
            const index = list.findIndex(
                (distributorinfo) =>
                    distributorinfo.id === action.distributorinfo.id,
            );
            if (index !== -1) {
                list[index] = action.distributorinfo;
            }

            return { ...state, list, byId };
        }
        default:
            return state;
    }
}
