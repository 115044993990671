import React, { Component } from 'react';
import { Button, Container, List } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import * as _ from 'lodash';

import chartApi from '../../api/chartApi';
import ErrorBoundary from './../common/ErrorBoundary';

class ApiCallsPage extends Component {
    state = {
        apicalls: [],
        newChartTitle: '',
    };

    componentDidMount() {
        chartApi.getAPICalls().then((charts) => {
            this.setState({ apicalls: _.orderBy(charts, 'label') });
        });
    }

    updateStateValue = (event, { name, value }) => {
        this.setState({
            [name]: value,
        });
    };

    render() {
        const { apicalls } = this.state;
        return (
            <Container>
                <List>
                    {apicalls.map((apiCall) => (
                        <List.Item
                            key={apiCall.id}
                            as={Link}
                            to={`/apicalls/${apiCall.id}`}
                        >
                            {apiCall.label}
                        </List.Item>
                    ))}
                </List>
                <Button as={Link} to="/apicalls/">
                    Create New Chart
                </Button>
            </Container>
        );
    }
}

ApiCallsPage.propTypes = {};

export default ErrorBoundary(ApiCallsPage);
