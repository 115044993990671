import React from 'react';
import * as PropTypes from 'prop-types';
import { Icon, Table, Progress, Header } from 'semantic-ui-react';

import AttachmentDetailsForm from './AttachmentDetailsForm';

const UploadProgressListRow = ({
    file,
    attachment,
    attachmentsConstants,
    attachmentStateActions,
    uploadAttachmentSuccess,
    setEditingAttachmentId,
    isEditOpen,
}) => {
    const getIconColor = (file) => {
        if (file.errorMessage) return 'red';
        if (file.uploaded) return 'green';
        return 'grey';
    };
    const uploadAttachmentCallback = () => {
        uploadAttachmentSuccess();
    };
    return (
        <React.Fragment>
            <Table.Row>
                <Table.Cell className="file-name">
                    {file.name}
                    {file.uploading && (
                        <Progress percent={file.progress} color="purple" />
                    )}
                    {file.errorMessage && (
                        <Header as="h5" color="red">
                            {file.errorMessage}
                        </Header>
                    )}
                </Table.Cell>
                <Table.Cell collapsing>
                    <Icon
                        circular
                        inverted
                        name={file.errorMessage ? 'close' : 'checkmark'}
                        color={getIconColor(file)}
                        size="small"
                        style={{
                            opacity:
                                file.uploaded || file.errorMessage ? 1 : 0.2,
                            margin: 0,
                        }}
                    />
                </Table.Cell>
                {file.uploaded && attachment && (
                    <Table.Cell
                        onClick={() => setEditingAttachmentId(attachment.id)}
                        collapsing
                        textAlign="center"
                    >
                        <Icon
                            circular
                            inverted
                            name="edit"
                            color={isEditOpen ? 'green' : 'grey'}
                            size="small"
                        />
                    </Table.Cell>
                )}
            </Table.Row>
            {attachment && isEditOpen ? (
                <Table.Row>
                    <Table.Cell colSpan="3">
                        <AttachmentDetailsForm
                            attachment={attachment}
                            attachmentsConstants={attachmentsConstants}
                            attachmentStateActions={attachmentStateActions}
                            uploadAttachmentSuccess={uploadAttachmentCallback}
                        />
                    </Table.Cell>
                </Table.Row>
            ) : null}
        </React.Fragment>
    );
};

UploadProgressListRow.propTypes = {
    file: PropTypes.object,
    attachment: PropTypes.object,
    attachmentsConstants: PropTypes.object,
    attachmentStateActions: PropTypes.object,
    uploadAttachmentSuccess: PropTypes.func,
    setEditingAttachmentId: PropTypes.func,
    isEditOpen: PropTypes.bool,
};

export default UploadProgressListRow;
