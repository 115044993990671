import { useEffect, useMemo, useState } from 'react';
import ticketApi from '../../api/ticketApi';
import entriesApi from '../../api/entriesApi';
import executionFieldsApi from '../../api/executionFieldsApi';
import { ExecutionField, ExecutionTicketEntry } from '../../types';
import {
    Button,
    Dimmer,
    Divider,
    Dropdown,
    Form,
    Loader,
    Message,
    Segment,
    Table,
} from 'semantic-ui-react';
import Threshold from '../common/fields/Threshold';
import { set } from 'lodash';

// Utility function to group fields by activityId
const groupByActivity = (fields: ExecutionField[]) => {
    return fields.reduce(
        (acc, field) => {
            if (!acc[field.activityId]) {
                acc[field.activityId] = [];
            }
            acc[field.activityId].push(field);
            return acc;
        },
        {} as Record<string, ExecutionField[]>,
    );
};

export const ThresholdMatrix = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [executionFields, setExecutionFields] = useState<ExecutionField[]>(
        [],
    );
    const [executionEntities, setExecutionEntities] = useState<
        ExecutionField[]
    >([]);
    const [executionEntries, setExecutionEntries] = useState<
        Record<
            string,
            Record<
                string,
                Pick<
                    ExecutionTicketEntry,
                    'value' | 'executionTicketId' | 'fieldId'
                >
            >
        >
    >({});

    const [editedCells, setEditedCells] = useState<Set<string>>(new Set());

    type ModifiedEntry = {
        fieldId: string;
        entityId: string;
        executionTicketId: number;
        value: string;
        saving?: boolean;
    };

    const [modifiedEntries, setModifiedEntries] = useState<ModifiedEntry[]>([]);

    const [selectedEntities, setSelectedEntities] = useState<string[]>([]);
    const [editingCell, setEditingCell] = useState<{
        fieldId: string;
        entityId: string;
    } | null>(null);

    useEffect(() => {
        executionFieldsApi
            .getExecutionFields({
                where: { activityFieldTypeId: 'Threshold' },
                limit: 500,
            })
            .then((data) => {
                const sortedFields = data.sort(
                    (a, b) =>
                        new Date(b.executionCreatedAt).getTime() -
                        new Date(a.executionCreatedAt).getTime(),
                );

                const uniqueFields: ExecutionField[] = [];
                const uniqueEntities: ExecutionField[] = [];
                const seenFieldIds = new Set<string>();
                const seenEntityIds = new Set<string>();

                sortedFields.forEach((field) => {
                    if (!seenFieldIds.has(field.fieldId)) {
                        seenFieldIds.add(field.fieldId);
                        uniqueFields.push(field);
                    }

                    if (!seenEntityIds.has(field.executionEntityId as string)) {
                        seenEntityIds.add(field.executionEntityId as string);
                        uniqueEntities.push(field);
                    }
                });

                // Sort uniqueFields alphabetically based on fieldLabel
                uniqueFields.sort((a, b) =>
                    a.fieldLabel.localeCompare(b.fieldLabel),
                );

                setExecutionFields(uniqueFields);
                setExecutionEntities(uniqueEntities);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });

        entriesApi
            .getExecutionEntries({
                where: { activityFieldTypeId: 'Threshold' },
                limit: 500,
            })
            .then((data) => {
                const sortedEntries = data.sort(
                    (a, b) =>
                        new Date(b.createdAt).getTime() -
                        new Date(a.createdAt).getTime(),
                );

                const entriesMap: Record<
                    string,
                    Record<string, ExecutionTicketEntry>
                > = {};

                sortedEntries.forEach((entry) => {
                    const fieldId = entry.fieldId;
                    const entityId = entry.executionEntityId;

                    if (!entriesMap[fieldId]) {
                        entriesMap[fieldId] = {};
                    }

                    if (!entriesMap[fieldId][entityId]) {
                        entriesMap[fieldId][entityId] = entry;
                    }
                });

                setExecutionEntries(entriesMap);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    useEffect(() => {
        // Filter out the modified entries that are not being edited and are not being saved already
        const entriesToSave = modifiedEntries.filter((entry) => {
            const isEditing =
                editingCell?.fieldId === entry.fieldId &&
                editingCell?.entityId === entry.entityId;
            return !entry.saving && !isEditing;
        });

        if (entriesToSave.length === 0) return;

        entriesToSave.forEach(async (entry) => {
            if (entry.saving) return;
            // Set saving flag for the specific entry
            setModifiedEntries((prev) =>
                prev.map((e) => {
                    if (
                        e.fieldId === entry.fieldId &&
                        e.executionTicketId === entry.executionTicketId
                    ) {
                        return { ...e, saving: true };
                    }
                    return e;
                }),
            );

            try {
                // Save the entry
                await saveEntry(entry);

                // Remove the entry from modifiedEntries
                setModifiedEntries((prev) =>
                    prev.filter(
                        (e) =>
                            e.fieldId !== entry.fieldId ||
                            e.executionTicketId !== entry.executionTicketId,
                    ),
                );

                // Update executionEntries
                setExecutionEntries((prev) => {
                    const updatedEntries = { ...prev };
                    if (!updatedEntries[entry.fieldId]) {
                        updatedEntries[entry.fieldId] = {};
                    }
                    updatedEntries[entry.fieldId][entry.entityId] = {
                        value: entry.value,
                        executionTicketId: entry.executionTicketId,
                        fieldId: entry.fieldId,
                    };
                    return updatedEntries;
                });
            } catch (error) {
                console.error('Failed to save the entry', error);
                // Reset saving flag in case of an error
                setModifiedEntries((prev) =>
                    prev.map((e) => {
                        if (
                            e.fieldId === entry.fieldId &&
                            e.executionTicketId === entry.executionTicketId
                        ) {
                            return { ...e, saving: false };
                        }
                        return e;
                    }),
                );
            }
        });
    }, [modifiedEntries, editingCell]);

    const saveEntry = async (entry: ModifiedEntry) => {
        // Assuming ticketApi.saveTicketEntries is the method to save the entry
        await ticketApi.saveTicketEntries(entry.executionTicketId, [
            {
                activityFieldId: entry.fieldId,
                value: entry.value,
                executionTicketId: entry.executionTicketId,
            },
        ]);
    };

    const entityOptions = useMemo(() => {
        return executionEntities.map((entity) => ({
            key: entity.executionEntityId,
            text: entity.executionEntityLabel,
            value: entity.executionEntityId,
        }));
    }, [executionEntities]);

    const groupedFieldsByActivity = useMemo(
        () => groupByActivity(executionFields),
        [executionFields],
    );

    const handleThresholdChange = (
        fieldId: string,
        executionTicketId: number,
        entityId: string,
        value: string,
    ) => {
        const cellId = `${fieldId}-${executionTicketId}`;
        editedCells.add(cellId);
        setEditedCells(new Set(editedCells));

        // Add or replace modified entry
        const entryIndex = modifiedEntries.findIndex(
            (e) =>
                e.fieldId === fieldId &&
                e.executionTicketId === executionTicketId,
        );

        const entry = {
            fieldId,
            executionTicketId,
            value,
            entityId,
        };

        if (entryIndex === -1) {
            setModifiedEntries((prev) => [...prev, entry]);
        } else {
            setModifiedEntries((prev) => {
                const newEntries = [...prev];
                newEntries[entryIndex] = entry;
                return newEntries;
            });
        }
    };

    const handleCellClick = (fieldId: string, entityId: string) => {
        setEditingCell({ fieldId, entityId });
    };

    const handleSaveEdit = (event: React.MouseEvent) => {
        event.stopPropagation();
        setEditingCell(null);
    };

    return (
        <div>
            {isLoading && (
                <Dimmer active inverted>
                    <Loader inverted></Loader>
                </Dimmer>
            )}
            <Dropdown
                placeholder="Select Entities"
                fluid
                multiple
                selection
                options={entityOptions}
                onChange={(e, { value }) =>
                    setSelectedEntities(value as string[])
                }
            />

            {selectedEntities.length === 0 && (
                <Message>
                    <Message.Header>
                        Select at least one entity to view thresholds
                    </Message.Header>
                </Message>
            )}

            {selectedEntities.length > 0 &&
                Object.entries(groupedFieldsByActivity).map(
                    ([activityId, fields]) => (
                        <Segment key={activityId}>
                            {/* Display the header with ticketLabel. */}
                            <h2>{fields[0].ticketLabel}</h2>

                            <Table celled compact fixed>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell width={3} />
                                        {/* This is for the top-left cell which is empty */}
                                        {executionEntities
                                            .filter((entity) =>
                                                selectedEntities.includes(
                                                    entity.executionEntityId,
                                                ),
                                            )
                                            .map((entry) => (
                                                <Table.HeaderCell
                                                    key={entry.id}
                                                >
                                                    {entry.executionEntityLabel}
                                                </Table.HeaderCell>
                                            ))}
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {fields.map((field) => (
                                        <Table.Row key={field.id}>
                                            <Table.Cell width={3}>
                                                <strong>
                                                    {' '}
                                                    {field.fieldLabel}
                                                </strong>
                                                <p>{field.fieldDescription}</p>
                                                <p>
                                                    {field.options?.inverted
                                                        ? '(Lower is better)'
                                                        : '(Higher is better)'}
                                                </p>
                                            </Table.Cell>
                                            {executionEntities
                                                .filter((entity) =>
                                                    selectedEntities.includes(
                                                        entity.executionEntityId,
                                                    ),
                                                )
                                                .map((entity) => {
                                                    const cellId = `${field.fieldId}-${entity.id}`;

                                                    const isEditingThisCell =
                                                        editingCell?.fieldId ===
                                                            field.fieldId &&
                                                        editingCell?.entityId ===
                                                            entity.executionEntityId;

                                                    const specificModifiedEntry =
                                                        modifiedEntries.find(
                                                            (e) =>
                                                                e.fieldId ===
                                                                    field.fieldId &&
                                                                e.executionTicketId ===
                                                                    entity.executionTicketId,
                                                        );
                                                    const specificEntry =
                                                        executionEntries[
                                                            field.fieldId
                                                        ]?.[
                                                            entity
                                                                .executionEntityId
                                                        ];
                                                    const entryValue =
                                                        specificModifiedEntry?.value ||
                                                        specificEntry?.value;

                                                    return (
                                                        <Table.Cell
                                                            key={entity.id}
                                                            warning={
                                                                !!specificModifiedEntry
                                                            }
                                                            onClick={() =>
                                                                handleCellClick(
                                                                    field.fieldId,
                                                                    entity.executionEntityId,
                                                                )
                                                            }
                                                            selectable={
                                                                !isEditingThisCell
                                                            }
                                                        >
                                                            <div
                                                                style={{
                                                                    position:
                                                                        'relative',
                                                                    padding:
                                                                        !isEditingThisCell
                                                                            ? '0.5em'
                                                                            : 0,
                                                                }}
                                                            >
                                                                {specificModifiedEntry?.saving && (
                                                                    <Dimmer
                                                                        active
                                                                        inverted
                                                                    >
                                                                        <Loader
                                                                            inverted
                                                                            size="tiny"
                                                                        ></Loader>
                                                                    </Dimmer>
                                                                )}
                                                                <Threshold
                                                                    name={
                                                                        cellId
                                                                    }
                                                                    label=""
                                                                    value={
                                                                        entryValue
                                                                    }
                                                                    editable={
                                                                        isEditingThisCell
                                                                    }
                                                                    options={
                                                                        field.options
                                                                    }
                                                                    onChange={(
                                                                        e,
                                                                        data,
                                                                    ) =>
                                                                        handleThresholdChange(
                                                                            field.fieldId,
                                                                            entity.executionTicketId,
                                                                            entity.executionEntityId,
                                                                            data.value,
                                                                        )
                                                                    }
                                                                />
                                                                {isEditingThisCell && (
                                                                    <>
                                                                        <Button
                                                                            icon="check"
                                                                            onClick={
                                                                                handleSaveEdit
                                                                            }
                                                                        />
                                                                    </>
                                                                )}
                                                            </div>
                                                        </Table.Cell>
                                                    );
                                                })}
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>
                        </Segment>
                    ),
                )}
        </div>
    );
};
