const config = {
    pdf: {
        responseType: 'arraybuffer',
        contentType: 'application/pdf',
        encoding: 'binary',
        renderEngine: 'jsrender',
        renderRecipe: 'chrome-pdf',

        icon: 'file pdf outline',
    },
    docx: {
        responseType: 'arraybuffer',
        contentType:
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        encoding: 'binary',

        icon: 'file word outline',
        customCss: 'word.css',
        stripTags: [
            'table',
            'thead',
            'tr',
            'th',
            'td',
            'tbody',
            'html',
            'head',
            'style',
            'body',
            'h1',
            'h2',
            'h3',
            'h4',
            'h5',
            'h6',
            'p',
            'div',
            'span',
            'ul',
            'li',
            'ol',
            'a',
            'img',
        ],
    },
    xlsx: {
        responseType: 'arraybuffer',
        contentType: 'application/xlsx',
        encoding: 'binary',
        renderEngine: 'jsrender',
        renderRecipe: 'html-to-xlsx',
        customCss: 'xlsx.css',
        stripTags: [
            'table',
            'thead',
            'tr',
            'th',
            'td',
            'tbody',
            'html',
            'head',
            'style',
            'body',
        ],

        icon: 'file excel outline',
    },
    print: {
        responseType: 'text/html',
        contentType: 'text/html',
        encoding: '8bit',

        icon: 'print',
    },
};

// module.exports = config;

export default config;
