import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Label, List, Popup, Segment } from 'semantic-ui-react';
import { useAppSelector } from '../../../actions/store';
import {
    ExecutionExtended,
    ExecutionTicketExtended,
    TicketRelationshipTypeID,
} from '../../../types';

import { StackedBarProgress } from '@vidende/components/StackedBarProgress';

import ExecutionStatusOverview from '@vidende/components/ExecutionStatusOverview';

export interface Props {
    data?: PropsData;
    header?: string;
    refresh?: boolean;
}

export interface PropsData {
    selection?: null;
    data?: DataData;
}

export interface DataData {
    main?: ExecutionTicketExtended[];
    executions?: ExecutionExtended[];
}

const TicketTreeComponent = (props: Props) => {
    const workflowStatusTypes = useAppSelector(
        (state) => state.constants.workflowStatusTypes,
    );

    const tickets = props.data.data.main;
    const executions = props.data.data.executions;

    const ticketsById = useMemo(() => {
        if (!tickets) return {};
        return tickets.reduce((previous, current) => {
            return {
                ...previous,
                [current.id]: current,
            };
        }, {});
    }, [tickets]);

    const renderTicket = (ticket: ExecutionTicketExtended) => {
        return (
            <List.Item>
                <List.Icon name="circle" color={ticket.statusNodeColor} />
                <List.Content>
                    <a href={`/ticket/${ticket.id}`}>
                        {ticket.executionTicketSet?.assignedParty?.label}
                    </a>

                    {/* <Label color={ticket.statusNodeColor}>
                    {ticket.statusLabel}
                </Label> */}

                    {ticket.toTicketRelationships.length > 0 && (
                        <>
                            {/* <h3>Distributors</h3> */}
                            <List>
                                {ticket.toTicketRelationships.map(
                                    (relationship) => {
                                        const ticket =
                                            ticketsById[
                                                relationship.fromTicketId
                                            ];
                                        return renderTicket(ticket);
                                    },
                                )}
                            </List>
                        </>
                    )}
                </List.Content>
            </List.Item>
        );
    };

    if (!tickets || !executions) return null;

    const rootTickets = tickets.filter((ticket) =>
        ticket.fromTicketRelationships
            ? ticket.fromTicketRelationships.length === 0
            : false,
    );

    return (
        <Segment>
            {executions.map((execution) => (
                <Segment key={execution.id}>
                    <h3>
                        <a href={`/execution/${execution.id}`}>
                            {execution.label}
                        </a>
                    </h3>

                    <Popup
                        wide="very"
                        content={
                            <ExecutionStatusOverview
                                workflowStatusTypes={workflowStatusTypes}
                                execution={execution}
                            />
                        }
                        position="left center"
                        on={['hover', 'click']}
                        trigger={
                            <div>
                                <StackedBarProgress
                                    workflowStatusTypes={workflowStatusTypes}
                                    execution={execution}
                                />
                            </div>
                        }
                    />

                    <List>
                        {rootTickets
                            .filter(
                                (ticket) => ticket.executionId === execution.id,
                            )
                            .map((ticket) => renderTicket(ticket))}
                    </List>
                </Segment>
            ))}

            {/* <pre>
            {JSON.stringify(workflowStatusTypes, null, ' ')}
        </pre> */}
        </Segment>
    );
};

export default TicketTreeComponent;
