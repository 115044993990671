import _ from 'lodash';
import moment from 'moment';

import ticketApi from '../api/ticketApi';
import * as types from '../constants/actionTypes';
import { getAttachmentsId } from '../utils/CommonUtils';
import { performChainOfRequests } from '../utils/TicketsUtils';
import toastr from 'toastr';

export function addTicketToFollowUpList(id) {
    return {
        type: types.TICKET_ADD_TO_FOLLOW_UP_LIST,
        id,
    };
}
export function removeTicketFromFollowUpList(id) {
    return {
        type: types.TICKET_REMOVE_FROM_FOLLOW_UP_LIST,
        id,
    };
}
export function addTicketsToFollowUpList(ids) {
    return {
        type: types.TICKETS_ADD_TO_FOLLOW_UP_LIST,
        ids,
    };
}

export function removeTicketsFromFollowUpList(ids) {
    return {
        type: types.TICKETS_REMOVE_FROM_FOLLOW_UP_LIST,
        ids,
    };
}

export function updateFieldEntry(
    ticket,
    activityField,
    value,
    edited = true,
    outlier = false,
    excludeFromStatistics = false
) {
    return {
        type: `${types.TICKET_UPDATE_ENTRY}`,
        ticket,
        activityField,
        value,
        edited,
        outlier,
        excludeFromStatistics,
    };
}

export function addAttachments(ticket, attachments, currentUser) {
    return {
        type: `${types.TICKET_ADD_ATTACHMENTS}`,
        ticket,
        attachments,
        currentUser,
    };
}

export function deleteAttachment(ticket, attachment) {
    return function (dispatch) {
        return ticketApi.deleteAttachment(ticket, attachment.id).then(() => {
            return dispatch({
                type: `${types.TICKET_DELETE_ATTACHMENT_SUCCESS}`,
                ticket,
                attachment,
            });
        });
    };
}

export function saveActivityFields(ticket, currentUserDetails) {
    return function (dispatch) {
        dispatch({
            type: `${types.SAVING_TICKET_ENTRIES}`,
            ticket,
        });
        return ticketApi
            .saveTicketEntries(ticket.id, ticket.entries)
            .then((result) => {
                return dispatch({
                    type: `${types.SAVE_TICKET_ENTRIES_SUCCESS}`,
                    ticket,
                    currentUserDetails,
                    result,
                });
            });
    };
}

export function saveWorkflowFields(ticket) {
    return function (dispatch) {
        dispatch({
            type: `${types.SAVING_TICKET_WORKFLOW_ENTRIES}`,
            ticket,
        });
        return ticketApi
            .saveTicketWorkflowEntries(
                ticket,
                ticket.workflowEntries.map((entry) =>
                    Object.assign({}, entry, { edited: false })
                )
            )
            .then(() => {
                return ticketApi
                    .getTicketWorkflowEntries(ticket)
                    .then((result) => {
                        return dispatch({
                            type: `${types.SAVE_TICKET_WORKFLOW_ENTRIES_SUCCESS}`,
                            ticket,
                            result,
                        });
                    });
            });
    };
}
export function transition(ticket, transitionId) {
    return function (dispatch) {
        dispatch({
            type: `${types.SAVING_TICKET_TRANSITION}`,
            ticket,
        });
        return ticketApi
            .transitionTicket(ticket, transitionId)
            .then((result) => {
                if (result.refreshFields.length > 0) {
                    dispatch(loadTicket({ id: ticket.id }));
                }
                return dispatch({
                    type: `${types.SAVING_TICKET_TRANSITION_SUCCESS}`,
                    ticket,
                    result,
                });
            });
    };
}

export function assign(ticket, principalId) {
    return function (dispatch) {
        dispatch({
            type: `${types.SAVING_TICKET}`,
            ticket,
        });
        return ticketApi.assignTicket(ticket, principalId).then((result) => {
            return dispatch({
                type: `${types.SAVING_TICKET_SUCCESS}`,
                ticket,
                result,
            });
        });
    };
}

export function bulkAssign(tickets, principal) {
    const principalId = principal ? principal.id : null;
    return function (dispatch) {
        return ticketApi
            .assignTickets(
                tickets.map((ticket) => ticket.id),
                principalId
            )
            .then((response) => {
                return dispatch({
                    type: `${types.SAVING_TICKETS_SUCCESS}`,
                    response,
                    principal,
                });
            });
    };
}

export function resolve(ticket, statusId, statusResolutionId, resolution) {
    const shouldRemoveResolution = !!_.find(ticket.statusResolutions, {
        statusResolutionId,
    });

    return function (dispatch) {
        dispatch({
            type: `${types.SAVING_TICKET_TRANSITION}`,
            ticket,
        });
        return ticketApi
            .resolveTicket(
                ticket,
                statusId,
                shouldRemoveResolution ? null : statusResolutionId
            )
            .then((result) => {
                if (shouldRemoveResolution) {
                    return dispatch({
                        type: `${types.SAVING_TICKET_REMOVE_RESOLUTION_SUCCESS}`,
                        ticketId: ticket.id,
                        statusId,
                    });
                }
                return dispatch({
                    type: `${types.SAVING_TICKET_RESOLUTION_SUCCESS}`,
                    ticket,
                    result,
                    resolution,
                });
            });
    };
}

export function loadTicket({ id, isPreview = false }) {
    return function (dispatch) {
        dispatch({
            type: types.LOAD_TICKET,
            id,
        });
        return ticketApi.getTicket({ id, isPreview }).then((ticket) => {
            return dispatch({
                type: types.LOAD_TICKET_SUCCESS,
                ticket,
                isPreview,
            });
        });
    };
}

export function loadTickets({ tickets, isPreview = true }) {
    return function (dispatch) {
        return performChainOfRequests(dispatch, tickets, isPreview);
    };
}

export function searchTickets(filter = {}, isPreview = true) {
    const combinedFilter = _.merge(
        {
            limit: 10,
        },
        filter
    );
    return function (dispatch) {
        return ticketApi.getTickets(combinedFilter).then((tickets) => {
            return dispatch({
                type: types.LOAD_TICKETS_SUCCESS,
                tickets,
                isPreview,
            });
        });
    };
}

export function searchTicketsCount(where = {}, updateUserCount = false) {
    return function (dispatch) {
        return ticketApi.getTicketsCount(where).then((result) => {
            if (!updateUserCount) return result;
            return dispatch({
                type: types.UPDATE_USER_TICKET_COUNT,
                count: result.count,
            });
        });
    };
}

export function addExistingAttachment(ticket, attachments) {
    return function (dispatch) {
        return ticketApi
            .addExistingAttachment(ticket.id, getAttachmentsId(attachments))
            .then(() => {
                return dispatch({
                    type: `${types.TICKET_ADD_EXISTING_ATTACHMENTS}`,
                    attachments,
                    ticket,
                });
            });
    };
}

export function setDefaultValue(
    fields,
    defaults,
    entries,
    resolving,
    ticket,
    confirmPopupActions
) {
    return function (dispatch) {
        if (resolving) {
            const defaultKeys = _.keys(defaults);
            const unsavedWorkflowEntries = _.filter(entries, (entry) =>
                _.some(
                    _.map(
                        defaultKeys,
                        (defaultKey) => entry.fieldKey === defaultKey
                    )
                )
            );
            const savedWorflowEntries = _.filter(
                entries,
                (entry) =>
                    entry.field &&
                    _.some(
                        _.map(
                            defaultKeys,
                            (defaultKey) => entry.field.fieldKey === defaultKey
                        )
                    )
            );
            if (savedWorflowEntries.length || unsavedWorkflowEntries.length) {
                confirmPopupActions.showConfirmDialog(
                    `Would you like an automated response? (This will overwrite the previous value)`,
                    () => {
                        overrideValues(
                            defaults,
                            fields,
                            entries,
                            ticket,
                            dispatch
                        );
                    },
                    () => {},
                    'Yes',
                    'No'
                );
            } else {
                overrideValues(defaults, fields, entries, ticket, dispatch);
            }
        }
    };
}

function overrideValues(defaults, fields, entries, ticket, dispatch) {
    _.map(defaults, (defaultValue, key) => {
        const activityField = _.find(fields, { fieldKey: key });
        if (activityField) {
            let newValue = defaultValue;
            const isDate =
                _.get(activityField, 'fieldType.name') === 'Date' ||
                _.get(activityField, 'activityFieldTypeId.name') === 'Date';
            if (isDate) {
                newValue = moment()
                    .add(parseInt(defaultValue), 'days')
                    .startOf('day')
                    .toISOString();
            }
            return dispatch({
                type: `${types.TICKET_UPDATE_ENTRY}`,
                ticket,
                activityField,
                value: newValue,
            });
        }
    });
}

export function addDeadline(ticketId, params) {
    return function (dispatch) {
        return ticketApi.addDeadline(ticketId, params).then((result) => {
            return dispatch({
                type: `${types.TICKET_ADD_DEADLINE_SUCCESS}`,
                result,
            });
        });
    };
}

export function removeDeadline(ticketId, deadlineId) {
    return function (dispatch) {
        return ticketApi.removeDeadline(ticketId, deadlineId).then((result) => {
            return dispatch({
                type: `${types.TICKET_REMOVE_DEADLINE_SUCCESS}`,
                result,
                ticketId,
                deadlineId,
            });
        });
    };
}
