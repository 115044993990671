import apiTools from './apiTools';
import { EVENT_LIST_LIMIT } from '../utils/EventConsts';

class EventApi {
    static getEventsList(filter = {}, showHidden) {
        let addParams = {
            filter: {
                limit: EVENT_LIST_LIMIT,
                order: 'createdAt DESC',
                include: ['eventType', 'targetPrincipal', 'modifiedBy'],
                ...filter,
            },
        };
        if (showHidden) {
            addParams['showHidden'] = true;
        }
        return apiTools
            .get('Events', addParams)
            .then((response) => {
                return response.data;
            })
            .catch((e) => {
                throw new Error(e);
            });
    }
}

export default EventApi;
