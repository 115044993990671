import React from 'react';
import * as PropTypes from 'prop-types';
import ActionButtons from './ActionButtons';

ShowHideButton.propTypes = {
    visible: PropTypes.bool,
    setVisible: PropTypes.func,
};

function ShowHideButton(props) {
    const { visible, setVisible } = props;
    return (
        <ActionButtons
            size="tiny"
            compact={true}
            viewClicked={
                visible
                    ? null
                    : (e) => {
                          e.preventDefault();
                          setVisible(true);
                      }
            }
            hideClicked={
                visible
                    ? (e) => {
                          e.preventDefault();
                          setVisible(false);
                      }
                    : null
            }
        />
    );
}

export default ShowHideButton;
