import React, { Component } from 'react';
import { Checkbox, Form, Label, Table, TextArea } from 'semantic-ui-react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';

class ActivityResolutionListRow extends Component {
    state = {
        addNote: false,
        allowNotes: true,
    };

    handleChange = (event, data) => {
        const { type, resolutions, onChange } = this.props;
        const resolutionValue = Object.assign(
            {},
            _.find(resolutions, {
                activityResolutionTypeId: type.id,
            }),
        );
        resolutionValue[data.name] = data.value;
        const newValue = Object.assign({}, resolutionValue);
        onChange(type, newValue);
    };

    toggleAddNote = (event, data) => {
        this.setState({ addNote: data.checked });
        if (!data.checked) {
            this.handleChange(
                {},
                {
                    name: 'note',
                    value: '',
                },
            );
        }
    };

    render() {
        const {
            type,
            resolutions,
            toggleResolution,
            allowNotes,
            showUseDefault = false,
        } = this.props;

        const resolutionValue = _.find(resolutions, {
            activityResolutionTypeId: type.id,
        });

        const available = resolutionValue
            ? _.isBoolean(resolutionValue.available)
                ? resolutionValue.available
                : true
            : false;

        const useDefault = !resolutionValue;

        const addNote =
            this.state.addNote || (resolutionValue && resolutionValue.note);
        return (
            <React.Fragment>
                <Table.Row>
                    <Table.Cell>
                        <Label color={type.colourId}>{type.label}</Label>
                    </Table.Cell>
                    <Table.Cell collapsing>
                        <Checkbox
                            onChange={(event, data) => {
                                if (!data.checked)
                                    this.toggleAddNote(event, {
                                        checked: false,
                                    });
                                toggleResolution(type, data.checked);
                            }}
                            checked={available}
                        />
                        {useDefault && showUseDefault && (
                            <div>(Use default)</div>
                        )}
                    </Table.Cell>
                    <Table.Cell>
                        <Form>
                            <Form.Field>
                                <Form.Input
                                    placeholder="Label Override"
                                    value={
                                        resolutionValue
                                            ? resolutionValue.labelOverride
                                            : ''
                                    }
                                    onChange={(e, data) =>
                                        this.handleChange(type, data)
                                    }
                                    size="mini"
                                    disabled={!available}
                                    name="labelOverride"
                                />
                            </Form.Field>
                        </Form>
                    </Table.Cell>
                    {allowNotes && (
                        <Table.Cell collapsing>
                            <Checkbox
                                disabled={!available}
                                onChange={this.toggleAddNote}
                                checked={!!addNote}
                            />
                        </Table.Cell>
                    )}
                </Table.Row>
                {addNote && available ? (
                    <Table.Row>
                        <Table.Cell colSpan="4">
                            <Form>
                                <TextArea
                                    autoHeight
                                    value={
                                        resolutionValue
                                            ? resolutionValue.note
                                            : ''
                                    }
                                    onChange={(e, data) =>
                                        this.handleChange(type, data)
                                    }
                                    placeholder="Add resolution note here"
                                    name="note"
                                />
                            </Form>
                        </Table.Cell>
                    </Table.Row>
                ) : (
                    <React.Fragment />
                )}
            </React.Fragment>
        );
    }
}

ActivityResolutionListRow.propTypes = {
    type: PropTypes.object.isRequired,
    resolutions: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    toggleResolution: PropTypes.func.isRequired,
    allowNotes: PropTypes.bool,
    showUseDefault: PropTypes.bool,
};

export default ActivityResolutionListRow;
