import * as types from '../constants/actionTypes';
import initialState from './initialState';
import _ from 'lodash';
import { checkifEdited } from '../utils/React';
import update from 'immutability-helper/index';
import * as activityTypes from '../constants/storeTypes';
import * as formBuilderHelper from './helpers/formBuilderHelper';
import * as ticketHelper from './helpers/ticketHelper';

export default function createActivityReducer() {
    return function activityReducer(
        state = { byId: { NEW: { ...initialState.activity, id: 'NEW' } } },
        action,
    ) {
        switch (action.type) {
            case types.LOAD_ACTIVITY_SUCCESS: {
                const activity = Object.assign(
                    {},
                    initialState.activity,
                    action.activity,
                    { edited: false },
                );
                activity.fields = _.orderBy(activity.fields, 'sort').map(
                    (field, index) => ({ ...field, sort: index }),
                );
                if (action.name === activityTypes.NEW) {
                    activity.id = 'NEW';
                    activity.fields = activity.fields.map((field) => {
                        const newField = _.clone(field);
                        delete newField.id;
                        return newField;
                    });
                    activity.resolutionNotes = activity.resolutionNotes.map(
                        (resolutionNote) =>
                            Object.assign({}, resolutionNote, { id: null }),
                    );
                    activity.activityStatusId = 1;
                }
                return formBuilderHelper.updateActivityById({
                    state,
                    activity,
                    id: activity.id,
                });
            }

            case types.UPDATE_ACTIVITY: {
                const { id } = action;
                let activity = Object.assign({}, state.byId[id]);
                activity[action.keyName] = action.value;
                activity = checkifEdited(activity, state.byId[id]);

                return formBuilderHelper.updateActivityById({
                    state,
                    activity,
                    id,
                });
            }

            case types.UPDATE_RESOLUTION_LABEL: {
                const { id, statusKey, resolutionTypeId, value } = action;
                let activity = Object.assign({}, state.byId[id]);

                const meta = _.cloneDeep(activity.meta) || {};

                if (_.isNull(value)) {
                    _.unset(meta, [
                        'resolutionLabels',
                        statusKey,
                        resolutionTypeId,
                    ]);
                } else {
                    _.set(
                        meta,
                        ['resolutionLabels', statusKey, resolutionTypeId],
                        value,
                    );
                }

                let newActivity = update(activity, {
                    meta: {
                        $set: meta,
                    },
                });

                newActivity = checkifEdited(newActivity, activity);
                return formBuilderHelper.updateActivityById({
                    state,
                    activity: newActivity,
                    id,
                });
            }

            case types.ADD_ACTIVITY_FIELD: {
                const { fieldType, label, options, id, fieldKey } = action;

                let activity = state.byId[id];

                let newActivity = Object.assign({}, activity, {
                    fields: formBuilderHelper.addField(
                        activity.fields,
                        fieldType,
                        label,
                        options,
                        fieldKey,
                    ),
                });
                newActivity = checkifEdited(newActivity, activity);
                return formBuilderHelper.updateActivityById({
                    state,
                    activity: newActivity,
                    id,
                });
            }

            case types.REMOVE_ACTIVITY_FIELD: {
                const { fieldIndex, id } = action;
                let activity = state.byId[id];

                let newActivity = update(activity, {
                    fields: {
                        $set: formBuilderHelper.removeField(
                            activity.fields,
                            fieldIndex,
                        ),
                    },
                });

                newActivity = checkifEdited(newActivity, activity);
                return formBuilderHelper.updateActivityById({
                    state,
                    activity: newActivity,
                    id,
                });
            }

            case types.UPDATE_ACTIVITY_FIELD: {
                const { fieldIndex, value, id } = action;
                let activity = Object.assign({}, state.byId[id]);
                const newFieldsValue = formBuilderHelper.updateField(
                    activity.fields,
                    fieldIndex,
                    value,
                );
                let newActivity = Object.assign({}, activity, {
                    fields: newFieldsValue,
                });
                newActivity = checkifEdited(newActivity, activity);
                return formBuilderHelper.updateActivityById({
                    state,
                    activity: newActivity,
                    id,
                });
            }

            case types.SAVE_ACTIVITY:
                return initialState.currentActivity;

            case types.SAVE_ACTIVITY_SUCCESS: {
                const { activity } = action;
                let currentActivity = Object.assign(
                    {},
                    state.byId[activity.id],
                );
                const newActivity = Object.assign(
                    {},
                    currentActivity,
                    activity,
                    {
                        edited: false,
                    },
                );
                return formBuilderHelper.updateActivityById({
                    state,
                    activity: newActivity,
                    id: activity.id,
                });
            }

            case types.REMOVE_ACTIVITY_WORKFLOW_FIELD_DEFAULT: {
                const { field } = action;
                const workflowStatusFieldDefaults =
                    state.workflowStatusFieldDefaults;

                const index = _.findIndex(workflowStatusFieldDefaults, {
                    workFlowStatusFieldId: field.id,
                });

                const activity = update(state, {
                    workflowStatusFieldDefaults: { $splice: [[index, 1]] },
                });
                return checkifEdited(activity, state);
            }

            case types.UPDATE_ACTIVITY_WORKFLOW_FIELD_DEFAULT: {
                const { field, value } = action;
                const workflowStatusFieldDefaults =
                    state.workflowStatusFieldDefaults;

                const { workflowEntries } = ticketHelper.updateFieldEntry(
                    { workflowEntries: workflowStatusFieldDefaults },
                    field,
                    value,
                );

                const activity = update(state, {
                    workflowStatusFieldDefaults: { $set: workflowEntries },
                });
                return checkifEdited(activity, state);
            }

            case types.RESET_ACTIVITY: {
                const { id, resetState } = action;
                return formBuilderHelper.updateActivityById({
                    state,
                    activity: resetState,
                    id,
                });
            }
            default:
                return state;
        }
    };
}
