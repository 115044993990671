import './KpiComponent.scss';
import { ComponentSize, RAGState } from '../../../../../types';
import { SemanticCOLORS, SemanticICONS } from 'semantic-ui-react';
import KpiComponent from './KpiComponent';
import { useMemo } from 'react';
import { RAGStatusResult, Result } from '../../../../../api/statusApi';
import { logicResultToStatus } from '../../../../../utils/data/statusUtils';
import { StatusPopupWrapper } from '../StatusPopupWrapper';
import { convertToRag } from '../../../../../utils/thresholds/convertToRag';

interface KpiComponentProps {
    value: string | number | { value: string | number };
    format?: string;
    iconName?: SemanticICONS;
    label?: string;
    color?: SemanticCOLORS;
    currencyType?: string;
    style?: number | string;
    link?: string;
    buttonText?: string;
    drillThroughEnabled?: boolean;
    fullHighlight?: boolean;
    drillThroughProperties?: object;
    callback?: (data: { dataValue: object }) => void;
    state?: RAGState;
    labelSize?: ComponentSize;
    valueSize?: ComponentSize;
    ragMetric?: string;
    ragField?: string;
    ragThresholdField?: string;
    data?: PropsData;
    header?: string;
}
export interface PropsData {
    selection: null;
    data: DataData;
}

export interface DataData {
    main: RAGStatusResult;
}

export interface Main {
    results: Result[];
}

const MetricKpiComponent = (props: KpiComponentProps) => {
    const { data, ragMetric, ragField, ragThresholdField, header, value } =
        props;

    const { ragResult, args, ragThresholdResult } = useMemo(() => {
        const args = data?.data?.main?.arguments;
        if (data?.data?.main?.results && (ragMetric || ragField)) {
            const result = data?.data?.main?.results.find(
                (result) => result.ref === ragMetric || result.ref === ragField,
            );
            const ragThresholdResult = data?.data?.main?.results.find(
                (result) => result.ref === ragThresholdField,
            );

            return { ragResult: result, ragThresholdResult, args };
        }
        return { args };
    }, [data, ragMetric, ragField]);
    let label = ragResult?.label;
    if (header) label = header;

    const result = ragResult?.statusResults[0].logicResult;
    // const status = result ? logicResultToStatus(result.logicResult) : undefined;

    // const threshold = !Array.isArray(
    //   ragThresholdResult?.statusResults[0]?.logicResult
    // )
    //   ? ragThresholdResult?.statusResults[0]?.logicResult?.result
    //   : null;

    const ragValue = Array.isArray(result)
        ? result.map((r) => r?.result).join(', ')
        : typeof result?.result !== 'object'
          ? result?.result
          : undefined;

    const ragState = Array.isArray(result)
        ? result[0]?.thresholdResult
        : result?.thresholdResult;

    // const ragState =
    //   threshold &&
    //   ragValue &&
    //   typeof ragValue === 'number' &&
    //   typeof threshold === 'object' &&
    //   'upper' in threshold
    //     ? convertToRag(ragValue, threshold)
    //     : undefined;

    return (
        <StatusPopupWrapper result={ragResult} args={args}>
            <div>
                <KpiComponent
                    {...props}
                    label={label}
                    value={ragValue || value || '-'}
                    state={ragState}
                />
            </div>
        </StatusPopupWrapper>
    );
};

export default MetricKpiComponent;

MetricKpiComponent.fields = [
    {
        id: 'ragMetric',
        required: false,
        label: 'RAG Metric',
        activityFieldTypeId: 'ModelSearchField',
        options: {
            modelName: 'Status',
            valueProperty: 'ref',
        },
    },
    {
        id: 'ragField',
        required: false,
        label: 'RAG Field',
        activityFieldTypeId: 'ModelSearchField',
        options: {
            modelName: 'ActivityField',
            valueProperty: 'fieldKey',
            refProperty: 'fieldKey',
        },
    },
    {
        id: 'ragThresholdField',
        required: false,
        label: 'RAG Threshold Field',
        activityFieldTypeId: 'ModelSearchField',
        options: {
            modelName: 'ActivityField',
            valueProperty: 'fieldKey',
            refProperty: 'fieldKey',
        },
    },
    {
        id: 'value',
        required: false,
        label: 'Value',
        activityFieldTypeId: 'Text',
    },
    {
        id: 'fullHighlight',
        required: false,
        label: 'Full Highlight',
        activityFieldTypeId: 'CheckBoxBool',
    },
    {
        id: 'format',
        required: false,
        label: 'Format',
        activityFieldTypeId: 'RadioGroup',
        options: {
            values: ['Number', 'Currency', 'Percentage', 'Date'],
        },
    },
    {
        id: 'style',
        required: false,
        label: 'Style',
        activityFieldTypeId: 'RadioGroup',
        options: {
            values: ['1', '2', '3'],
        },
    },
    {
        id: 'state',
        required: false,
        label: 'State',
        activityFieldTypeId: 'Select',
        options: {
            values: [
                'DEFAULT',
                'ERROR',
                'EXCEPTION',
                'NO_RESULTS',
                'UNFINISHED',
                'POSITIVE',
                'CAUTION',
            ],
        },
    },
    {
        id: 'labelSize',
        required: false,
        label: 'Label Size',
        activityFieldTypeId: 'Select',
        options: {
            values: ['tiny', 'small', 'medium', 'large', 'huge'],
        },
    },
    {
        id: 'valueSize',
        required: false,
        label: 'Value Size',
        activityFieldTypeId: 'Select',
        options: {
            values: ['tiny', 'small', 'medium', 'large', 'huge'],
        },
    },
];
