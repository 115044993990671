import React from 'react';
import * as PropTypes from 'prop-types';
import { Label } from 'semantic-ui-react';

const Tags = ({ tags }) => {
    return (
        <div>
            {tags.map((tag, index) => (
                <Label key={index} as="span" className="activity-tag">
                    {tag.label.toUpperCase()}
                </Label>
            ))}
        </div>
    );
};

Tags.propTypes = {
    tags: PropTypes.array.isRequired,
};

export default Tags;
