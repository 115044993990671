import React from 'react';
import * as PropTypes from 'prop-types';
import { Button, Modal, Form } from 'semantic-ui-react';
import FormFields from '../common/FormFields';

const OptionsModal = (props) => {
    const { open, onChange, closeModal, onSave, fields, entries } = props;
    return (
        <Modal open={open}>
            <Modal.Header>Options</Modal.Header>
            <Modal.Content>
                <Form>
                    <FormFields
                        fields={fields}
                        entries={entries}
                        fieldIdKey="id"
                        handleFieldsDataChange={() => {}}
                        onChange={onChange}
                    />
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button positive onClick={onSave}>
                    Save
                </Button>
                <Button secondary onClick={closeModal}>
                    Cancel
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

OptionsModal.propTypes = {
    open: PropTypes.bool,
    charts: PropTypes.array,
    onChange: PropTypes.func.isRequired,
    selectedChartId: PropTypes.string,
    closeModal: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    fields: PropTypes.array,
    entries: PropTypes.array,
};

export default OptionsModal;
