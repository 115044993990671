import fairValueApi from '../api/fairValueApi';
import * as types from '../constants/actionTypes';

export function getTickets(filter = {}) {
    return function (dispatch) {
        return fairValueApi.getTickets(filter).then((tickets) => {
            return dispatch({
                type: types.FAIR_VALUE_LOAD_TICKETS_SUCCESS,
                tickets,
            });
        });
    };
}
