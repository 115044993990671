import React, { useState } from 'react';
import * as _ from 'lodash';
import { useKeyPressEvent } from 'react-use';
import { Header, Message, Select, Table } from 'semantic-ui-react';
import { mapToOptions } from '../../utils/React';
import { colors } from '../../constants/config';
import ActionButtons from '../common/ActionButtons';

import './BinderMatrix.scss';
import * as PropTypes from 'prop-types';

const BinderMatrix = (props) => {
    const {
        binders,
        programme,
        onRemoveBinder,
        onChange,
        selectedValues,
        setSelectedValues,
    } = props;

    const [selectionStart, setSelectionStart] = useState([-1, -1]);
    const [selectionEnd, setSelectionEnd] = useState([-1, -1]);
    const [singleSelection, setSingleSelection] = useState([-1, -1]);

    const emptyImage = document.createElement('img');
    emptyImage.src =
        'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';

    const handleKeyPress = (event) => {
        const { key } = event;

        let num = parseInt(key);
        if (key === 'b') num = 'Binder';
        if (key === 'Delete') num = 'Not in scope';

        const clone = _.clone(selectedValues);

        _.forEach(binders, (binder, binderIndex) => {
            _.forEach(sortedActivitySets, (activitySet, activitySetIndex) => {
                if (
                    (binderIndex === selectionStart[0] ||
                        binderIndex === selectionEnd[0] ||
                        _.inRange(
                            binderIndex,
                            selectionStart[0],
                            selectionEnd[0],
                        )) &&
                    (activitySetIndex === selectionStart[1] ||
                        activitySetIndex === selectionEnd[1] ||
                        _.inRange(
                            activitySetIndex,
                            selectionStart[1],
                            selectionEnd[1],
                        ))
                ) {
                    _.set(clone, [binder.id, activitySet.id], num);
                }
            });
        });

        setSelectedValues(clone);
        onChange(clone);
    };

    useKeyPressEvent('1', handleKeyPress);
    useKeyPressEvent('2', handleKeyPress);
    useKeyPressEvent('3', handleKeyPress);
    useKeyPressEvent('4', handleKeyPress);
    useKeyPressEvent('5', handleKeyPress);
    useKeyPressEvent('6', handleKeyPress);
    useKeyPressEvent('7', handleKeyPress);
    useKeyPressEvent('8', handleKeyPress);
    useKeyPressEvent('9', handleKeyPress);
    useKeyPressEvent('b', handleKeyPress);
    useKeyPressEvent('Delete', handleKeyPress);

    const sortedActivitySets = _.orderBy(programme.activitySets, 'sort');

    const options = mapToOptions(
        ['Not in scope', 'Binder', 1, 2, 3, 4, 5, 6, 7, 8, 9],
        null,
    );

    const mappedColors = {
        1: colors.orange,
        2: colors.yellow,
        3: colors.olive,
        4: colors.green,
        5: colors.teal,
        6: colors.blue,
        7: colors.red,
        8: colors.violet,
        9: colors.brown,
        Binder: colors.purple,
        'Not in scope': '#cccccc',
    };

    const handleSelectionChange = (binder, activitySet, value) => {
        const clone = _.clone(selectedValues);
        _.set(clone, [binder.id, activitySet.id], value);
        setSelectedValues(clone);
        onChange(clone);
    };

    const handleRemoveBinder = (binder, binderIndex) => {
        const clone = _.clone(selectedValues);
        delete clone[binder.id];
        setSelectedValues(clone);
        onChange(clone);
        onRemoveBinder(binderIndex);
    };

    const getValue = (binder, activitySet) => {
        return _.get(selectedValues, [binder.id, activitySet.id]);
    };

    if (!binders || binders.length === 0)
        return (
            <Message>
                <Message.Header>Please select at least 1 Binder</Message.Header>
            </Message>
        );

    return (
        <React.Fragment>
            <Table
                celled
                compact
                striped
                style={{ userSelect: 'none' }}
                className="binder-matrix"
            >
                <Table.Header>
                    <Table.Row>
                        <Table.Cell width={2} />
                        {binders.map((binder, index) => (
                            <Table.Cell className="matrix-header" key={index}>
                                <div>
                                    <Header as="h4">
                                        <Header.Content>
                                            {binder.label}
                                            <Header.Subheader>
                                                {
                                                    binder.entriesMapped[
                                                        'BND-UMR'
                                                    ].value
                                                }
                                            </Header.Subheader>
                                        </Header.Content>
                                    </Header>

                                    <ActionButtons
                                        removeClicked={() => {
                                            handleRemoveBinder(binder, index);
                                        }}
                                    />
                                </div>
                            </Table.Cell>
                        ))}
                    </Table.Row>
                </Table.Header>

                {sortedActivitySets.map((activitySet, activitySetIndex) => (
                    <Table.Row key={activitySetIndex}>
                        <Table.HeaderCell
                            onClick={() => {
                                setSelectionStart([0, activitySetIndex]);
                                setSelectionEnd([
                                    binders.length - 1,
                                    activitySetIndex,
                                ]);
                                setSingleSelection([-1, -1]);
                            }}
                        >
                            {activitySet.label}
                        </Table.HeaderCell>
                        {binders.map((binder, binderIndex) => {
                            const value = getValue(binder, activitySet);

                            const color = mappedColors[value];

                            const active =
                                (binderIndex === selectionStart[0] ||
                                    binderIndex === selectionEnd[0] ||
                                    _.inRange(
                                        binderIndex,
                                        selectionStart[0],
                                        selectionEnd[0],
                                    )) &&
                                (activitySetIndex === selectionStart[1] ||
                                    activitySetIndex === selectionEnd[1] ||
                                    _.inRange(
                                        activitySetIndex,
                                        selectionStart[1],
                                        selectionEnd[1],
                                    ));

                            const style = {};
                            if (color)
                                style.backgroundColor =
                                    color + (active ? '99' : '66');
                            const selected =
                                binderIndex === singleSelection[0] &&
                                activitySetIndex === singleSelection[1];
                            return (
                                <Table.Cell
                                    key={binderIndex}
                                    draggable
                                    selectable
                                    style={style}
                                    onDragStart={(e) => {
                                        setSelectionStart([
                                            binderIndex,
                                            activitySetIndex,
                                        ]);
                                        setSingleSelection([-1, -1]);
                                        e.dataTransfer.setDragImage(
                                            emptyImage,
                                            0,
                                            0,
                                        );
                                    }}
                                    onDragEnter={() => {
                                        setSelectionEnd([
                                            binderIndex,
                                            activitySetIndex,
                                        ]);
                                    }}
                                    onClick={() => {
                                        setSelectionStart([-1, -1]);
                                        setSelectionEnd([-1, -1]);
                                        setSingleSelection([
                                            binderIndex,
                                            activitySetIndex,
                                        ]);
                                    }}
                                >
                                    {selected ? (
                                        <Select
                                            defaultOpen={true}
                                            options={options}
                                            compact
                                            fluid
                                            value={value}
                                            onChange={(event, { value }) => {
                                                handleSelectionChange(
                                                    binder,
                                                    activitySet,
                                                    value,
                                                );
                                            }}
                                            onClose={() => {
                                                setSingleSelection([-1, -1]);
                                            }}
                                        />
                                    ) : (
                                        value
                                    )}
                                </Table.Cell>
                            );
                        })}
                    </Table.Row>
                ))}
            </Table>
        </React.Fragment>
    );
};

export default BinderMatrix;

BinderMatrix.propTypes = {
    binders: PropTypes.array,
    programme: PropTypes.object,
    onRemoveBinder: PropTypes.func,
    onChange: PropTypes.func,
    selectedValues: PropTypes.object,
    setSelectedValues: PropTypes.func,
};
