import React from 'react';
import * as PropTypes from 'prop-types';
import { colors, lightColors } from '../../constants/config';
import { Button, Icon, Label, Popup } from 'semantic-ui-react';
import { useGetFeatures } from '../../utils/Features';

const TicketRowStatus = (props) => {
    const { color, followUp, statusNodeLabel, onFollowUpClick } = props;
    const { FollowUp } = useGetFeatures();

    // if (!FollowUp) {
    //   return <Label color={color}>{statusNodeLabel}</Label>;
    // }
    return (
        <Popup
            trigger={
                <div
                    className={`status ${followUp ? ' with-icon' : ''}`}
                    style={{
                        background: `linear-gradient(0deg, ${lightColors[color]} 0%,  ${colors[color]} 100%)`,
                    }}
                >
                    {FollowUp && (
                        <div className="icons">
                            <Button
                                basic
                                icon
                                onClick={onFollowUpClick}
                                className={`${followUp ? 'active' : ''}`}
                            >
                                <Icon name="clipboard check" inverted />
                            </Button>
                        </div>
                    )}
                </div>
            }
            content={<Label color={color}>{statusNodeLabel}</Label>}
            position="right center"
        />
    );
};

TicketRowStatus.propTypes = {
    color: PropTypes.string,
    followUp: PropTypes.bool,
    statusNodeLabel: PropTypes.string,
    onFollowUpClick: PropTypes.func,
};

export default TicketRowStatus;
