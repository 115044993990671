import _ from 'lodash';

import * as types from '../constants/actionTypes';
import * as executionHelper from './helpers/executionHelper';
import { checkifEdited } from '../utils/React';
import { ExecutionExtended } from '../types';
import update from 'immutability-helper';

export default function createExecutionReducer(name = '') {
    return function executionReducer(
        state: ExecutionExtended = {} as ExecutionExtended,
        action,
    ) {
        switch (action.type) {
            case `${types.LOAD_EXECUTION_SUCCESS}_${name}`: {
                const { execution } = action;
                const relationships = _.chain(execution.ticketSets)
                    .map((ts) => ts.assignedPartyRelationship)
                    .filter((i) => !_.isUndefined(i))
                    .uniqBy('id')
                    .value();

                return { ...state, ...execution, relationships };
            }

            case types.SAVING_TICKET_SUCCESS: {
                if (!state) return state;
                const { result } = action;

                const ticketSetIndex = _.findIndex(state.ticketSets, {
                    id: result.executionTicketSetId,
                });

                if (ticketSetIndex !== -1) {
                    const ticketIndex = _.findIndex(
                        state.ticketSets[ticketSetIndex].tickets,
                        {
                            id: result.id,
                        },
                    );
                    return update(state, {
                        // @ts-ignore
                        ticketSets: {
                            [ticketSetIndex]: {
                                tickets: {
                                    [ticketIndex]: {
                                        $set: result,
                                    },
                                },
                            },
                        },
                    });
                }
                return state;
            }

            case `${types.EXECUTION_UPDATE_VALUE}_${name}`: {
                const { name, value } = action;
                return update(state, {
                    [name]: { $set: value },
                });
            }
            case `${types.EXECUTION_ADD_ATTACHMENTS}`:
            case `${types.EXECUTION_ADD_EXISTING_ATTACHMENTS}`: {
                const { attachments, currentUser } = action;
                let attachmentsResult = [];
                if (currentUser) {
                    attachmentsResult = _.map(attachments, (attachment) => ({
                        ...attachment,
                        creator: currentUser,
                    }));
                } else {
                    attachmentsResult = attachments;
                }
                return update(state, {
                    attachments: state.attachments
                        ? { $push: attachmentsResult }
                        : { $set: attachmentsResult },
                });
            }

            case `${types.EXECUTION_DELETE_ATTACHMENT_SUCCESS}`: {
                const { attachment } = action;
                const attachmentIndex = _.findIndex(state.attachments, {
                    id: attachment.id,
                });
                return update(state, {
                    attachments: { $splice: [[attachmentIndex, 1]] },
                });
            }

            case `${types.LOAD_EXECUTION_MATRIX_ROLE_SUCCESS}`: {
                const { response } = action;
                return update(state, {
                    permissionsMatrix: { $set: [...response.ticketSets] },
                });
            }

            case `${types.ADD_PERMISSION_SUCCESS}`: {
                const { principalRole } = action;
                const { structuralNodeId } = principalRole;
                const permissionIndex = _.findIndex(state.permissionsMatrix, {
                    structuralNodeId: structuralNodeId,
                });
                if (permissionIndex === -1) return state;
                return update(state, {
                    // @ts-ignore
                    permissionsMatrix: {
                        [permissionIndex]: {
                            permissionsNode: {
                                principalRoles: { $push: [principalRole] },
                            },
                        },
                    },
                });
            }

            case types.UPDATE_EXECUTION_PERMISSION_SUCCESS: {
                const roleDefinition = action.roleDefinition;
                const roleIndex = state.permissionsMatrix.findIndex(
                    (p) =>
                        p.structuralNodeId ===
                        action.principalRole.structuralNodeId,
                );
                console.log({ roleIndex });
                // const principalRoleIndex = _.findIndex(
                //   state.permissionsMatrix[xxxxx].permissionsNode.principalRoles,
                //   { principalRole: action.principalRole.id }
                // );
                const principalRoleIndex = state.permissionsMatrix[
                    roleIndex
                ].permissionsNode.principalRoles.findIndex(
                    (p) => p.id === action.principalRole.id,
                );

                if (principalRoleIndex === -1) return state;
                return update(state, {
                    // @ts-ignore
                    permissionsMatrix: {
                        [roleIndex]: {
                            permissionsNode: {
                                principalRoles: {
                                    [principalRoleIndex]: {
                                        roleDefinition: {
                                            $set: roleDefinition,
                                        },
                                        roleDefinitionId: {
                                            $set: roleDefinition.id,
                                        },
                                    },
                                },
                            },
                        },
                    },
                });
            }

            case `${types.ADD_VIEW_PERMISSION_SUCCESS}`: {
                const { principalRole } = action;
                const { structuralNodeId } = principalRole;
                const permissionIndex = _.findIndex(state.permissionsMatrix, {
                    structuralNodeId: structuralNodeId,
                });
                return update(state, {
                    // @ts-ignore
                    permissionsMatrix: {
                        [permissionIndex]: {
                            permissionsNode: {
                                principalRoles: { $push: [principalRole] },
                            },
                        },
                    },
                });
            }

            case `${types.DELETE_PERMISSION_SUCCESS}`: {
                const { principalRoleId, structuralNodeId } = action;
                const permissionI = state.permissionsMatrix.findIndex((p) => {
                    return p.structuralNodeId === structuralNodeId;
                });
                if (permissionI != -1) {
                    const principalRoleI = state.permissionsMatrix[
                        permissionI
                    ].permissionsNode.principalRoles.findIndex(
                        (principalRole) => principalRole.id === principalRoleId,
                    );
                    if (principalRoleI === -1) return state;
                    return update(state, {
                        // @ts-ignore
                        permissionsMatrix: {
                            [permissionI]: {
                                permissionsNode: {
                                    principalRoles: {
                                        $splice: [[principalRoleI, 1]],
                                    },
                                },
                            },
                        },
                    });
                }
                return state;
            }

            case `${types.EXECUTION_SET_FILTER}`: {
                const { partyId } = action;
                if (!partyId) {
                    return {
                        ...state,
                        filter: null,
                    };
                }
                return {
                    ...state,
                    filter: {
                        assignedPartyRelationship: {
                            partyId,
                        },
                    },
                };
            }

            case `${types.SAVE_EXECUTION_DETAILS_SUCCESS}`: {
                const { response } = action;
                const { label, dueDate, applicableDate } = response;
                const newState = _.cloneDeep(state);
                if (label !== newState.label) {
                    newState.label = label;
                }
                if (dueDate !== newState.dueDate) {
                    newState.dueDate = dueDate;
                }
                if (applicableDate !== newState.applicableDate) {
                    newState.applicableDate = applicableDate;
                }
                return newState;
            }

            case `${types.UPDATE_ATTACHMENT_SUCCESS}`: {
                const { attachment, userDetails } = action;
                const indexOfAttachment = _.findIndex(state.attachments, {
                    id: attachment.id,
                });
                if (indexOfAttachment !== -1) {
                    return update(state, {
                        // @ts-ignore
                        attachments: {
                            [indexOfAttachment]: {
                                $set: {
                                    ...attachment,
                                    creator: userDetails,
                                },
                            },
                        },
                    });
                }
                return state;
            }

            case `${types.SET_EXECUTION_TICKET_DEADLINE_COUNT}`: {
                const { count } = action;
                return { ...state, executionTicketDeadlineCount: count };
            }

            case `${types.EXECUTION_UPDATE_ENTRY}`: {
                const { activityField, value, edited } = action;
                let updatedExecution = executionHelper.updateFieldEntry(
                    state,
                    activityField,
                    value,
                    edited,
                );
                const editedValue = checkifEdited(updatedExecution, state);

                return update(state, {
                    $set: { ...editedValue },
                });
            }

            case `${types.SAVING_EXECUTION_ENTRIES}`: {
                const { execution } = action;
                const updatedExecution = update(execution, {
                    saving: { $set: true },
                });
                return update(state, {
                    $set: {
                        ...updatedExecution,
                    },
                });
            }

            case `${types.SAVE_EXECUTION_ENTRIES_SUCCESS}`: {
                const { result, currentUserDetails } = action;
                const currentExecution = state;
                const resultMerged = _.map(
                    currentExecution.entries,
                    (entry, index) => {
                        const modifiedBy =
                            (currentExecution.entries as any).modifiedBy ||
                            currentUserDetails;
                        const updatedEntry = {
                            ...entry,
                            ...result[index],
                            edited: false,
                        };
                        if (entry.edited) {
                            updatedEntry.modifiedBy = modifiedBy;
                        }
                        return updatedEntry;
                    },
                );

                const updatedExecution = update(currentExecution, {
                    // @ts-ignore
                    edited: { $set: false },
                    saving: { $set: false },
                    entries: { $set: resultMerged },
                });

                return update(state, {
                    $set: {
                        ...updatedExecution,
                    },
                });
            }

            default:
                return state;
        }
    };
}
