import React, { Component, useEffect, useState } from 'react';
import statusApi from '../../api/statusApi';
import {
    Button,
    Container,
    Dimmer,
    Form,
    Input,
    List,
    Loader,
    Modal,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { StatusEditor } from './StatusEditor';
import { Status } from '../../types';
import toastr from 'toastr';
import { orderBy } from 'lodash';
import { useAppSelector } from '../../actions/store';
import StatusList from './StatusList';
import ConfirmWrapper from './../common/ConfirmWrapper';
import { useDispatch } from 'react-redux';
import { loadStatuses } from '../../actions/statusActions';

const StatusPage = () => {
    const dispatch = useDispatch();

    // const [statuses, setStatuses] = useState([]);

    const statuses = useAppSelector((state) =>
        state.status.statuses.filter((status) => !status.deleted),
    );

    const [chosenStatus, setChosenStatus] = useState(null);
    const currentUser = useAppSelector((state) => state.currentUser);

    const fetchStatuses = async () => {
        dispatch(loadStatuses());
    };

    useEffect(() => {
        fetchStatuses();
    }, []);

    const handleSaveStatus = async (status: Status) => {
        if (status.id) {
            try {
                await statusApi.updateStatus(status);
                toastr.success('Metric saved');
                dispatch(loadStatuses());
            } catch (error) {
                toastr.error(error);
            }
            return;
        }

        const newStatus: Status = {
            ...status,
            structuralNodeId: currentUser.details.party.structuralNodeId,
        };

        try {
            await statusApi.createStatus(newStatus);
            toastr.success('New Metric created');
            dispatch(loadStatuses());
        } catch (error) {
            toastr.error(error);
        }
    };

    return (
        <Container>
            <Dimmer active={!statuses} inverted>
                <Loader />
            </Dimmer>
            <StatusList entities={statuses} onSave={handleSaveStatus} />

            <Button as={Link} to="/metrics/new">
                Create New Metric
            </Button>
            <Modal
                open={chosenStatus !== null}
                onClose={() => setChosenStatus(null)}
            >
                <Modal.Content>
                    <StatusEditor
                        status={statuses[chosenStatus]}
                        onSave={handleSaveStatus}
                    />
                </Modal.Content>
            </Modal>
        </Container>
    );
};
// }

// StatusPage.propTypes = {};

export default ConfirmWrapper(StatusPage);
