import React from 'react';
import * as PropTypes from 'prop-types';
import ModelRendererComponents from './modelRenderers/ModelRendererComponents';

const ModelInstanceRenderer = (props) => {
    const { model, data } = props;

    const ModelComponent = ModelRendererComponents[model];
    if (ModelComponent) return <ModelComponent data={data} />;

    return <React.Fragment>{data.label}</React.Fragment>;
};

ModelInstanceRenderer.propTypes = {
    data: PropTypes.object,
    model: PropTypes.string,
};

export default ModelInstanceRenderer;
