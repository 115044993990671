/* eslint-disable react/no-did-mount-set-state */
/* eslint-disable react/no-did-update-set-state */

import { useEffect, useState } from 'react';
import {
    Form,
    Checkbox,
    Item,
    Select,
    Message,
    Header,
} from 'semantic-ui-react';
import { useDispatch } from 'react-redux';

import FormFields from '../../common/FormFields';
import Text from '../../common/fields/Text';
import './SaveEntityModalForm.scss';
import { EntityEntry } from '../../../types';
import { useAppSelector } from '../../../actions/store';
import { loadEntityType } from '../../../actions/entityActions';
import { FieldErrors } from '../../../utils/Validation';
import DateField from '../../common/fields/Date';
import ExecutionPlanApi from '../../../api/executionPlanApi';
import { useGetFeatures } from '../../../utils/Features';

export interface EntityFormData {
    label: string;
    executionPlanId: number | null;
    launchExecution: boolean;
    entries: EntityEntry[];
    dueDate?: string;
    coManufactured?: boolean;
    distributorId?: string;
}

interface Props {
    formData: EntityFormData;
    errors?: FieldErrors;
    handleInputChanged: (
        event: any,
        data: { name: string; value?: string; checked?: boolean },
    ) => void;
    onFieldChange: (field: {
        name?: string;
        value?: string;
        checked?: boolean;
    }) => void;
    handleOnBlur?: (fieldName: string) => void;
    handleOnFocus?: (fieldName: string) => void;
    disableLaunchExecutionPlan?: boolean;
    disableDistributor?: boolean;
    type: string;
    executionPlans: any[];
}

const SaveEntityModalForm = (props: Props) => {
    const dispatch = useDispatch();
    const entityType = useAppSelector(
        (state) => state.entity.entityTypes.byId[props.type],
    );

    const party = useAppSelector((state) => state.party);

    const distributors =
        party?.partyRelationships
            ?.filter((relationship) => relationship.toParty)
            .map((relationship) => relationship.toParty) || [];

    useEffect(() => {
        if (entityType) return;
        dispatch(loadEntityType(props.type));
    }, [props.type]);

    useEffect(() => {}, []);

    const {
        handleInputChanged,
        handleOnBlur,
        handleOnFocus,
        onFieldChange,
        disableDistributor = true,
        disableLaunchExecutionPlan = true,
    } = props;
    const {
        label,
        entries,
        executionPlanId,
        launchExecution,
        dueDate,
        distributorId,
        coManufactured,
    } = props.formData;

    const executionPlanOptions = props.executionPlans.map((executionPlan) => ({
        key: executionPlan.id,
        value: executionPlan.id,
        text: `${executionPlan.label} - ${executionPlan.ownerParty?.label}`,
    }));

    const distributorOptions = distributors.map((distributor) => ({
        key: distributor.id,
        value: distributor.id,
        text: distributor.label,
    }));
    const { VisRiskProductProgrammeAutoLaunch } = useGetFeatures();
    return (
        <Form>
            <Form.Group widths="equal">
                <Form.Field>
                    <Text
                        label="Label"
                        value={label}
                        name="label"
                        errors={props.errors}
                        required={true}
                        onChange={handleInputChanged}
                        onBlur={
                            handleOnBlur
                                ? () => handleOnBlur('label')
                                : undefined
                        }
                        onFocus={
                            handleOnFocus
                                ? () => handleOnFocus('label')
                                : undefined
                        }
                    />
                </Form.Field>
            </Form.Group>
            <Form.Field>
                <Item.Group relaxed={false} className="item-group-save-entity">
                    {entityType && (
                        <FormFields
                            fields={
                                entityType
                                    ? entityType.fields.sort(
                                          (a, b) =>
                                              (a.sort || 0) - (b.sort || 0),
                                      )
                                    : []
                            }
                            onChange={onFieldChange}
                            entries={entries || []}
                            fieldIdKey="entityTypeFieldId"
                            fieldsPerRow={2}
                        />
                    )}
                </Item.Group>
            </Form.Field>

            {!disableLaunchExecutionPlan &&
                VisRiskProductProgrammeAutoLaunch && (
                    <>
                        <Header as="h4">
                            A Programme will be launched upon creation
                        </Header>
                        <Form.Group>
                            <Form.Field
                                width={4}
                                control={DateField}
                                label="Due Date"
                                value={dueDate}
                                onChange={handleInputChanged}
                                name="dueDate"
                            ></Form.Field>
                        </Form.Group>
                    </>
                )}

            <Form.Field>
                <Form.Field>
                    <Checkbox
                        label={`This is a co-manufactured product`}
                        name="coManufactured"
                        onChange={handleInputChanged}
                        checked={coManufactured}
                        disabled={disableDistributor}
                    />
                </Form.Field>
            </Form.Field>

            {coManufactured && (
                <>
                    {distributorOptions.length > 0 ? (
                        <Form.Field
                            error={
                                !!props.errors?.distributorId
                                    ? {
                                          content:
                                              props.errors?.distributorId.toString(),
                                      }
                                    : null
                            }
                        >
                            <Select
                                control={Select}
                                name="distributorId"
                                options={distributorOptions}
                                label="Select distributor"
                                disabled={disableDistributor}
                                value={distributorId}
                                required={true}
                                onChange={handleInputChanged}
                                onBlur={
                                    handleOnBlur
                                        ? () => handleOnBlur('distributorId')
                                        : undefined
                                }
                                onFocus={
                                    handleOnFocus
                                        ? () => handleOnFocus('distributorId')
                                        : undefined
                                }
                            />
                        </Form.Field>
                    ) : (
                        <Message
                            style={{ display: 'block' }}
                            error
                            content="You have not added any distributors"
                        />
                    )}
                </>
            )}
        </Form>
    );
};

export default SaveEntityModalForm;
