import React from 'react';
import * as PropTypes from 'prop-types';
import { Form, Icon, Popup } from 'semantic-ui-react';

import FieldToolTip from './FieldToolTip';
import FieldErrors from './FieldErrors';
import Outlier from './Outlier';

const Email = (props) => {
    const {
        label,
        value,
        onChange,
        disabled = false,
        errors,
        required,
        onFocus,
        onBlur,
        editable = true,
        name,
        error,
        outlier,
        excludeFromStatistics,
        dataField,
        isManufacturer,
    } = props;
    const fieldErrors = errors ? errors[name] : undefined;

    if (!editable) {
        return <React.Fragment>{value}</React.Fragment>;
    }

    return (
        <Form.Field required={required} error={error}>
            <label>
                {label}
                <FieldToolTip {...props} />
                {isManufacturer && dataField && (
                    <Outlier
                        value={value}
                        name={name}
                        outlier={outlier}
                        excludeFromStatistics={excludeFromStatistics}
                        onChange={onChange}
                    />
                )}
            </label>
            <Form.Input
                icon="at"
                iconPosition="left"
                placeholder={label}
                value={value || ''}
                onChange={onChange}
                disabled={disabled}
                onFocus={onFocus}
                onBlur={onBlur}
                error={!!fieldErrors}
            />
            {fieldErrors && <FieldErrors errors={fieldErrors} />}
        </Form.Field>
    );
};

Email.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    errors: PropTypes.object,
    required: PropTypes.bool,
    editable: PropTypes.bool,
    error: PropTypes.bool,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    dataField: PropTypes.bool,
    outlier: PropTypes.bool,
    excludeFromStatistics: PropTypes.bool,
    isManufacturer: PropTypes.bool,
};

Email.defaultProps = {
    value: '',
};

export default Email;
