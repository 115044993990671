import { types } from '@babel/core';
import { useMemo } from 'react';
import clsx from 'clsx';
import { Header } from 'semantic-ui-react';
import {
    RAGStatusResult,
    Result,
    StatusReportRunArgs,
    StatusResult,
} from '../../../../../api/statusApi';
import {
    getStatusFlags,
    logicResultToStatus,
    StatusFlags,
} from '../../../../../utils/data/statusUtils';
import DebugComponent from '../DebugComponent';

import './RAGDimension.scss';
import { RAGStatusIcon } from '../../status/RAGStatusIcon';
import { RAGIcon } from '../../status/RAGIcon';
import ApplicableDateLabel from '../../../ApplicableDateLabel';
import { StatusPopupWrapper } from '../StatusPopupWrapper';

interface RAGDimensionProps {
    header?: string;
    ragLabel?: string;
    chartOptions: any;
    maxHeight: number;
    data?: PropsData;
    ragMetric?: string;
    ragField?: string;
    dashboardProperties: DashboardProperties;
    hideRagHeader: boolean;
    hideDimensionHeader: boolean;
}

export interface DashboardProperties {
    PartyIds?: string[];
    applicableDate?: string[];
    dimension?: StatusReportRunArgs['dimension'];
}

export interface PropsData {
    selection: null;
    data: DataData;
}

export interface DataData {
    main: RAGStatusResult;
}

const RAGDimension = (props: RAGDimensionProps) => {
    const {
        header,
        data,
        chartOptions,
        maxHeight,
        ragMetric,
        ragField,
        ragLabel,

        hideRagHeader,
        hideDimensionHeader,
    } = props;

    const { ragResult, args } = useMemo(() => {
        const args = data?.data?.main?.arguments;
        if (data?.data?.main?.results && (ragMetric || ragField)) {
            const result = data?.data?.main?.results.find(
                (result) => result.ref === ragMetric || result.ref === ragField,
            );

            return { ragResult: result, args };
        }
        return { args };
    }, [data, ragMetric, ragField]);

    let statusFlags: StatusFlags = {};

    if (ragResult) {
        statusFlags = getStatusFlags(ragResult);
    }

    const classNames = clsx('rag-dimension', {
        'rag-dimension--error': statusFlags.hasError,
        'rag-dimension--exception': statusFlags.hasException,
        'rag-dimension--caution': statusFlags.hasCaution,
        'rag-dimension--no-results': statusFlags.hasNoResults,
        'rag-dimension--unfinished': statusFlags.hasUnfinished,
        'rag-dimension--positive': statusFlags.hasPositive,
    });

    let label = ragResult?.label;
    if (header) label = header;
    if (ragLabel) label = ragLabel;

    const renderDimensionHeader = (result: StatusResult) => {
        if (
            props.dashboardProperties?.dimension === 'applicableDate' &&
            result.dimensionValue &&
            result.dimensionValue !== 'all'
        )
            return (
                <ApplicableDateLabel
                    applicableDate={result.dimensionValue}
                    applicableDateTypeId={'Quarter'}
                />
            );
    };

    return (
        <StatusPopupWrapper result={ragResult} args={args}>
            <div className={classNames}>
                {!hideRagHeader && <Header size="small">{label}</Header>}

                <div className="rag-dimension__results">
                    {ragResult &&
                        ragResult.statusResults.map((result) => (
                            <div className="rag-dimension__result ">
                                {!hideDimensionHeader &&
                                    renderDimensionHeader(result)}
                                <RAGIcon
                                    status={logicResultToStatus(
                                        result.logicResult,
                                    )}
                                />
                            </div>
                        ))}
                </div>

                {/* {ragResult && <RAGStatusIcon status={ragResult} />} */}

                {/* <DebugComponent ragResult={ragResult} /> */}
            </div>
        </StatusPopupWrapper>
    );
};

RAGDimension.fields = [
    {
        id: 'ragMetric',
        required: false,
        label: 'RAG Metric',
        activityFieldTypeId: 'ModelSearchField',
        options: {
            modelName: 'Status',
            valueProperty: 'ref',
        },
    },
    {
        id: 'ragField',
        required: false,
        label: 'RAG Field',
        activityFieldTypeId: 'ModelSearchField',
        options: {
            modelName: 'ActivityField',
            valueProperty: 'fieldKey',
            refProperty: 'fieldKey',
        },
    },
    {
        id: 'ragLabel',
        required: false,
        label: 'RAG Metric Label',
        activityFieldTypeId: 'Text',
        options: null,
    },

    {
        id: 'hideRagHeader',
        required: false,
        label: 'Hide RAG Header',
        activityFieldTypeId: 'CheckBoxBool',
    },

    {
        id: 'hideDimensionHeader',
        required: false,
        label: 'Hide Dimension Header',
        activityFieldTypeId: 'CheckBoxBool',
    },
];

export default RAGDimension;
