import React from 'react';
import * as PropTypes from 'prop-types';
import ProgrammeListLoader from '../programme/ProgrammeListLoader';
import WorkflowListLoader from '../workflow/WorkflowListLoader';
import ExecutionPlanPartiesStep from './ExecutionPlanPartiesStep';
import { Grid, Header, Segment } from 'semantic-ui-react';
import ExecutionPlanOtherSettings from './ExecutionPlanOtherSettings';
import ProgrammeList from '../programme/ProgrammeList';
import WorkflowList from '../workflow/WorkflowList';
import ExecutionPlanParties from './ExecutionPlanParties';
import { WrapWizardContext } from '../../context/WizardContext';
import workflowApi from '../../api/workflowApi';
import * as ExecutionPlansUtils from '../../utils/ExecutionPlansUtils';

const CreateExecutionPlanComponents = (props) => {
    const {
        currentStep,
        stepForward,
        onChange,
        setOptionsValue,
        wizardChoices,
        setWizardChoices,
    } = props;

    const loadWorkflow = (workflowId) => {
        workflowApi.getWorkflow(workflowId).then((workflow) => {
            const updatedPlan = ExecutionPlansUtils.updateChoicesFromWorkflow(
                wizardChoices,
                workflow,
            );
            setWizardChoices(updatedPlan);
        });
    };

    const workflowSelected = (workflow) => {
        loadWorkflow(workflow.id);
        stepForward();
    };

    const programmeSelected = (programme) => {
        const updatedChoices = { ...wizardChoices, programme };

        if (programme.options && programme.options.enableBinderScoping)
            updatedChoices.enableBinderScoping = true;

        setWizardChoices(updatedChoices);

        stepForward();
    };

    switch (currentStep) {
        case 'PROGRAMME':
            return (
                <React.Fragment>
                    <ProgrammeListLoader onSelect={programmeSelected} />
                </React.Fragment>
            );
        case 'WORKFLOW':
            return (
                <React.Fragment>
                    <WorkflowListLoader onSelect={workflowSelected} />
                </React.Fragment>
            );
        case 'PARTIES':
            return (
                <React.Fragment>
                    <ExecutionPlanPartiesStep
                        options={wizardChoices}
                        needsReview={wizardChoices.needsReview}
                        onChange={onChange}
                    />
                </React.Fragment>
            );
        case 'SETTINGS':
            return (
                <React.Fragment>
                    <ExecutionPlanOtherSettings
                        options={wizardChoices}
                        setOptionsValue={setOptionsValue}
                        setValue={onChange}
                    />
                </React.Fragment>
            );
        case 'CONFIRM':
            return (
                <React.Fragment>
                    <Grid columns={2}>
                        <Grid.Row>
                            <Grid.Column>
                                <Header>Selected Programme</Header>
                                <ProgrammeList
                                    programmes={[wizardChoices.programme]}
                                    itemsPerRow={1}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <Header>Selected Workflow</Header>
                                <WorkflowList
                                    workflows={[wizardChoices.workflow]}
                                    itemsPerRow={1}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                    <Header>Assigned Parties & Users</Header>
                    <Segment>
                        <ExecutionPlanParties
                            options={wizardChoices}
                            needsReview={wizardChoices.needsReview}
                            editable={false}
                        />
                    </Segment>
                </React.Fragment>
            );
        default:
            return null;
    }
};

CreateExecutionPlanComponents.propTypes = {
    currentStep: PropTypes.string,
    stepForward: PropTypes.func,
    onChange: PropTypes.func,
    setOptionsValue: PropTypes.func,
    wizardChoices: PropTypes.object,
    setWizardChoices: PropTypes.func,
};

export default WrapWizardContext(CreateExecutionPlanComponents);
