/* eslint-disable react/no-did-mount-set-state */
import React, { useRef, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { Grid, Icon, Ref } from 'semantic-ui-react';
import { withRouter } from 'react-router';

import TicketPage from '../../ticket/TicketPage/TicketPage';
import { TicketContextConsumer } from '../../../context/TicketContext';
import ExecutionTicketSets from '../ExecutionTicketSets';
import { ExecutionContextConsumer } from '../../../context/ExecutionContext';
import useColumnHeight from '../../../utils/hooks/useColumnHeight';

import './ExecutionSplitView.scss';
import { useGetFeatures } from '../../../utils/Features';

const ExecutionSplitView = (props) => {
    const { categoryName } = props;
    const ticketsColumnRef = useRef(null);
    let ticketListRef;
    const [ref, columnHeight] = useColumnHeight('auto');
    const featureFlags = useGetFeatures();

    useEffect(() => {
        if (ticketListRef) ticketListRef.scrollTop = 0;
    }, [categoryName]);

    const { ticketSets, uiState, currentExecution, match } = props;
    const { splitView, selectedTicketId } = uiState;
    const isExecutionLoading = currentExecution.id != match.params.id;

    const shouldTicketBeStretched =
        featureFlags['UXFullScreenSingleQuestion'] &&
        ticketSets?.length === 1 &&
        ticketSets[0]?.tickets?.length === 1 &&
        splitView;

    const getTicketSetsColumnWidth = () => {
        if (shouldTicketBeStretched) {
            return 16;
        }
        if (splitView) {
            return 7;
        }
        return 16;
    };

    return (
        <React.Fragment>
            <Grid
                columns={splitView ? 2 : 1}
                className={`execution-split-view ${splitView && 'isSplit'}`}
            >
                <Ref innerRef={ref}>
                    <Grid.Column
                        className="tickets-column"
                        width={getTicketSetsColumnWidth()}
                    >
                        <div
                            ref={ticketsColumnRef}
                            className="ticket-list-scroll"
                            style={{
                                height: shouldTicketBeStretched
                                    ? 'auto'
                                    : columnHeight,
                            }}
                        >
                            <ExecutionTicketSets
                                ticketSets={ticketSets}
                                editable={false}
                                hideTicketButton={shouldTicketBeStretched}
                            />
                        </div>
                    </Grid.Column>
                </Ref>
                {splitView && (
                    <Grid.Column
                        className="ticket-column"
                        style={{
                            height: shouldTicketBeStretched
                                ? columnHeight -
                                      ticketsColumnRef?.current?.clientHeight ||
                                  0
                                : columnHeight,
                        }}
                        width={shouldTicketBeStretched ? 16 : 9}
                    >
                        <Icon
                            className="close-button"
                            onClick={() => {
                                props.executionActions.updateUIState({
                                    splitView: false,
                                });
                            }}
                            link
                            name="ellipsis vertical"
                        />

                        <TicketPage
                            fullPageView={false}
                            ticketId={selectedTicketId}
                            isExecutionLoading={isExecutionLoading}
                            currentExecution={currentExecution}
                        />
                    </Grid.Column>
                )}
            </Grid>
        </React.Fragment>
    );
};

ExecutionSplitView.propTypes = {
    ticketSets: PropTypes.array,
    currentTicket: PropTypes.object,
    executionActions: PropTypes.object,
    ticketStateActions: PropTypes.object,
    uiState: PropTypes.object,
    ticketActions: PropTypes.object,
    categoryName: PropTypes.string,
    currentExecution: PropTypes.object,
    match: PropTypes.object,
};

export default withRouter(
    ExecutionContextConsumer(TicketContextConsumer(ExecutionSplitView)),
);
