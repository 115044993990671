//allows to get previous value in hooks based components
//prevProps in componentDidUpdate(prevProps) construction
import { useRef, useEffect } from 'react';

const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

export default usePrevious;
