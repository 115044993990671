import {
    Button,
    Checkbox,
    Dimmer,
    Form,
    Input,
    Loader,
    Modal,
    Select,
} from 'semantic-ui-react';
import * as uiActions from '../../../actions/uiActions';
import { useDispatch } from 'react-redux';
import { useEffect, useState, FC } from 'react';
import { useAppSelector } from '../../../actions/store';
import { loadParties } from '../../../actions/partyActions';
import Date from '../../common/fields/Date';
import { checkValidation, FieldErrors } from '../../../utils/Validation';
import ValidationSchema from '../../../utils/ValidationSchema';
import CheckboxGroup from '../../common/fields/CheckboxGroup';
import UIDGenerator from '../../../utils/UIDGenerator';
import _ from 'lodash';

interface SaveApiKeyModalProps {
    apiKey: any;
    functionApiKeysOptions: { api: string; code: string; item: string }[];
    onSumbit: (apiKey) => void;
}

const SaveApiKeyModal: FC<SaveApiKeyModalProps> = ({
    apiKey,
    functionApiKeysOptions,
    onSumbit,
}) => {
    const [state, setState] = useState({
        label: apiKey?.label || '',
        partyId: apiKey?.partyId || null,
        userId: apiKey?.userId || null,
        apiKeyType: apiKey?.type || null,
        active: apiKey?.active || true,
        deleted: apiKey?.deleted || false,
        expiryDate: null,
        party: null,
        dataAccessCodes: apiKey?.dataAccessCodes?.split(',') || [],
    });
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState<FieldErrors>();
    const dispatch = useDispatch();
    const parties = useAppSelector((state) => state.parties.list);

    useEffect(() => {
        const fetchParties = async () => {
            setIsLoading(true);
            try {
                await dispatch(
                    loadParties({
                        order: 'label ASC',
                    }),
                );
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
            }
        };

        if (parties.length === 0 && !apiKey) {
            fetchParties();
        }
    }, []);

    const handleSubmit = (_, arg) => {
        const result = checkValidation(
            { ...state, dataAccessCodes: state.dataAccessCodes?.toString() },
            apiKey
                ? ValidationSchema.apiKeyUpdate
                : ValidationSchema.apiKeyCreation,
        );
        if (!result.valid) {
            setErrors(result.errors);
            return;
        }

        setErrors(undefined);
        if (apiKey) {
            // if the apikey is passed to the modal, then it's an update operation
            return onSumbit({
                ...apiKey,
                label: state.label,
                active: state.active,
                deleted: state.deleted,
                dataAccessCodes: state.dataAccessCodes?.toString(),
            });
        } else {
            return onSumbit({
                ...state,
                partyId: state.party.id,
                dataAccessCodes: state.dataAccessCodes?.toString(),
            });
        }
    };

    const handleInputChanged = (_, arg) => {
        setState((prevState) => ({
            ...prevState,
            [arg.name]: arg.value || arg.checked,
        }));
    };

    const partyOptions = parties.map((party) => ({
        key: party.id,
        value: party,
        text: party.label,
    }));

    const userOptions = state.party?.users?.map((user) => ({
        key: user.id,
        value: user.id,
        text: user.firstName + ' ' + user.lastName,
    }));

    const apiKeyTypeOptions = [
        {
            key: '0',
            value: 'bot',
            text: 'Bot',
        },
        {
            key: '1',
            value: 'function',
            text: 'Function',
        },
    ];

    const u = UIDGenerator.get();
    const functionOptions = functionApiKeysOptions.map((f) => ({
        key: f.code,
        value: f.api,
        text: f.item,
    }));

    const handleOnChange = (_, data) => {
        let newValue = data.checked
            ? [...state.dataAccessCodes, data.value]
            : state.dataAccessCodes.filter((x) => x !== data.value);

        setState((prevState) => ({ ...prevState, dataAccessCodes: newValue }));
    };

    return (
        <Modal
            open={true}
            onClose={() => dispatch(uiActions.closeModal())}
            size="mini"
            closeOnDimmerClick={false}
        >
            <Dimmer active={isLoading} inverted>
                <Loader disabled={!isLoading} />
            </Dimmer>
            <Modal.Header>
                {apiKey
                    ? `Update ${apiKey.label} Api Key`
                    : 'Create new Api Key'}
            </Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Field
                        name="label"
                        placeholder="Label"
                        label="label"
                        control={Input}
                        value={state.label}
                        onChange={handleInputChanged}
                        error={
                            errors?.label
                                ? {
                                      content: errors?.label.join(', '),
                                  }
                                : undefined
                        }
                        fluid
                    ></Form.Field>
                    {!apiKey && (
                        <>
                            <Form.Field
                                name="party"
                                label="Party"
                                control={Select}
                                value={state.party}
                                options={partyOptions}
                                onChange={handleInputChanged}
                                error={
                                    errors?.party
                                        ? {
                                              content: errors?.party.join(', '),
                                          }
                                        : undefined
                                }
                            ></Form.Field>
                            <Form.Field
                                label="Type"
                                name="apiKeyType"
                                control={Select}
                                value={state.apiKeyType}
                                options={apiKeyTypeOptions}
                                onChange={handleInputChanged}
                                error={
                                    errors?.apiKeyType
                                        ? {
                                              content:
                                                  errors?.apiKeyType.join(', '),
                                          }
                                        : undefined
                                }
                            ></Form.Field>
                            {state.apiKeyType === 'function' && (
                                <>
                                    <Form.Field
                                        label="User"
                                        name="userId"
                                        control={Select}
                                        value={state.userId}
                                        options={userOptions}
                                        onChange={handleInputChanged}
                                        error={
                                            errors?.userId
                                                ? {
                                                      content:
                                                          errors?.userId.join(
                                                              ', ',
                                                          ),
                                                  }
                                                : undefined
                                        }
                                    ></Form.Field>
                                    <Form.Field
                                        error={
                                            errors?.dataAccessCodes
                                                ? true
                                                : undefined
                                        }
                                    >
                                        <label>Permissions</label>
                                        {functionOptions.map(
                                            (option, index) => (
                                                <Form.Checkbox
                                                    key={index}
                                                    label={option.text}
                                                    name={`radioGroup${u}`}
                                                    value={option.key}
                                                    checked={
                                                        state.dataAccessCodes?.indexOf(
                                                            option.key,
                                                        ) !== -1
                                                    }
                                                    onChange={handleOnChange}
                                                />
                                            ),
                                        )}
                                    </Form.Field>
                                </>
                            )}
                            <Date
                                label="Expiry Date"
                                value={null}
                                onChange={handleInputChanged}
                                name="expiryDate"
                                isClearable={true}
                            />
                        </>
                    )}

                    {apiKey && (
                        <>
                            <Form.Field
                                control={Checkbox}
                                checked={state.active}
                                onChange={handleInputChanged}
                                label="Active"
                                name="active"
                            ></Form.Field>
                            <Form.Field
                                control={Checkbox}
                                checked={state.deleted}
                                onChange={handleInputChanged}
                                label="Deleted"
                                name="deleted"
                            ></Form.Field>
                            {state.apiKeyType === 'function' && (
                                <Form.Field
                                    error={
                                        errors?.dataAccessCodes
                                            ? true
                                            : undefined
                                    }
                                >
                                    <label>Permissions</label>
                                    {functionOptions.map((option, index) => (
                                        <Form.Checkbox
                                            key={index}
                                            label={option.text}
                                            name={`radioGroup${u}`}
                                            value={option.key}
                                            checked={
                                                state.dataAccessCodes?.indexOf(
                                                    option.key,
                                                ) !== -1
                                            }
                                            onChange={handleOnChange}
                                        />
                                    ))}
                                </Form.Field>
                            )}
                        </>
                    )}
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button positive onClick={handleSubmit}>
                    {apiKey ? 'Update' : 'Save'}
                </Button>
                <Button
                    secondary
                    onClick={() => dispatch(uiActions.closeModal())}
                >
                    Cancel
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default SaveApiKeyModal;
