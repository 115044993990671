import React from 'react';
import { Button, Table } from 'semantic-ui-react';
import StatusNodeTransitionListRow from './StatusNodeTransitionListRow';
import { StatusNode, Transition } from '../../types';

interface StatusNodeTransitionListProps {
    transitions: Transition[];
    statusNode: StatusNode;
    statusNodes: StatusNode[];
    addTransition: (event: React.SyntheticEvent) => void;
    updateTransition: (
        event: React.SyntheticEvent,
        data: { name: string; value: any },
        transition: Transition,
    ) => void;
    removeTransition: (
        event: React.SyntheticEvent,
        transition: Transition,
    ) => void;
}

const StatusNodeTransitionList = ({
    transitions,
    statusNode,
    statusNodes,
    addTransition,
    updateTransition,
    removeTransition,
}: StatusNodeTransitionListProps) => {
    return (
        <React.Fragment>
            <Table collapsing basic="very">
                <Table.Body>
                    {transitions.map((transition, index) => (
                        <StatusNodeTransitionListRow
                            key={index}
                            transition={transition}
                            statusNode={statusNode}
                            statusNodes={statusNodes}
                            onChange={(event, data) => {
                                updateTransition(event, data, transition);
                            }}
                            onRemove={(event) => {
                                removeTransition(event, transition);
                            }}
                        />
                    ))}
                    <Table.Row>
                        <Table.Cell colSpan={2}>
                            <Button onClick={addTransition}>
                                Add Transition
                            </Button>
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
        </React.Fragment>
    );
};

export default StatusNodeTransitionList;
