import { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import toastr from 'toastr';
import { Link, withRouter } from 'react-router-dom';
import { useAppSelector } from '../../actions/store';
import {
    deactivate,
    loadDistributorInfos,
    loadParty,
} from '../../actions/partyActions';
import DistributorList, { DistributorListContext } from './DistributorList';
import ConfirmWrapper from './../common/ConfirmWrapper';
import {
    Button,
    Container,
    Dimmer,
    Header,
    Icon,
    Loader,
} from 'semantic-ui-react';
import { addEntriesToEntities } from '../../utils/EntityUtils';
import PartyWrapper from '../party/Party';
import CheckPermission from '../common/CheckPermission';
import * as uiActions from '../../actions/uiActions';
import { MODAL_COMPONENTS } from '../common/ModalRoot';
import partyApi, { NotificationType } from '../../api/partyApi';

import * as permissionTypes from '../../constants/permissionTypes';
import { DistributorInformation } from '../../types';
import ConfirmationModalContext from '../../context/ConfirmationModalContext';

interface Props {
    match: {
        params: {
            id: string;
        };
    };
    history: any;
}

const DistributorsPage = ({ history, match }: Props) => {
    const { confirmPopupActions } = useContext(ConfirmationModalContext);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingNotify, setIsLoadingNotify] = useState(false);
    // const entities = useAppSelector(state => state.entity.entities);
    // const entityType = useAppSelector(
    //   state => state.entity.entityTypes.byId['DISTRIBUTOR']
    // );
    const party = useAppSelector((state) => state.party);
    const distributorInfos = useAppSelector(
        (state) => state.distributorInfos?.list,
    );
    const partyId = match.params.id;

    const fetchParty = async () => {
        const filter = {
            include: [],
        };

        await dispatch(loadParty(partyId, filter));

        await dispatch(loadDistributorInfos());
        setIsLoading(false);
    };
    useEffect(() => {
        fetchParty();
    }, []);

    const handleDistributorCreated = () => {
        fetchParty();
    };

    const handleShowBulkImportModal = () => {
        history.push(`/party/${partyId}/bulkImportParties/DIST`);
    };
    const handleShowAddDistributorModal = () => {
        dispatch(
            uiActions.showModal({
                modalType: MODAL_COMPONENTS.CREATE_DISTRIBUTOR_MODAL,
                modalProps: {
                    partyId,
                    onDistributorCreated: handleDistributorCreated,
                },
            }),
        );
    };
    const handleShowBulkRemindModal = () => {
        dispatch(
            uiActions.showModal({
                modalType: MODAL_COMPONENTS.BULK_REMIND_DISTRIBUTORS_MODAL,
                modalProps: {},
            }),
        );
    };

    const distributors = distributorInfos || [];

    const handleCloseModal = () => {
        dispatch(uiActions.closeModal());
    };

    const handleShowFilterModal = (modalProps) => {
        console.log('modalProps', modalProps);

        dispatch(
            uiActions.showModal({
                modalType: MODAL_COMPONENTS.ADD_FILTER_MODAL,
                modalProps: { ...modalProps, onClose: handleCloseModal },
            }),
        );
    };

    const handleSendNotification = async (
        targetPartyId: string,
        notificationType: NotificationType,
    ) => {
        await partyApi.sendNotification({
            notificationType,
            targetPartyId,
            ownerPartyId: partyId,
        });

        toastr.success('Notification sent');
    };

    const handleDoesNotDistribute = async (
        distributor: DistributorInformation,
        cb: () => void,
    ) => {
        confirmPopupActions.showConfirmDialog(
            'Are you sure this Distributor does not distribute your products?',
            async () => doesNotDistributeOnConfirm(distributor, cb),
        );
    };

    const doesNotDistributeOnConfirm = async (
        distributor: DistributorInformation,
        cb: () => void,
    ) => {
        try {
            await dispatch(deactivate(distributor.id, 'PARTY_NOTDISTRIBUTE'));
            await dispatch(loadDistributorInfos());
            cb();
        } catch (error) {
            toastr.error(error.message);
        }
    };

    const handleEditPartyLabel = async (
        distributor: DistributorInformation,
        label: string,
    ) => {
        await partyApi.updateParty(distributor.id, {
            label,
        });
        dispatch({
            type: 'UPDATE_DISTRIBUTOR_INFO',
            distributorinfo: {
                ...distributor,
                label,
            },
        });
    };

    const handleNotifyDistributors = async (
        type: string,
        filter: Record<string, any>,
    ) => {
        setIsLoadingNotify(true);
        try {
            const result = await partyApi.bulkSendNotification({
                type,
                filter,
            });

            toastr.success(
                `Notifications sent to ${result.itemCount} distributor${
                    result.itemCount > 1 || result.itemCount === 0 ? 's' : ''
                }`,
            );
            setIsLoadingNotify(false);
        } catch (error) {
            toastr.error('Error sending notifications');
            setIsLoadingNotify(false);
        }
    };

    return (
        <Container>
            <Dimmer active={isLoading} inverted>
                <Loader disabled={!isLoading} />
            </Dimmer>
            {!isLoading && (
                <>
                    <Header as="h2">Distributors Management</Header>

                    <CheckPermission
                        structuralNodeId={party.structuralNodeId}
                        permissionType={permissionTypes.ADMIN}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <div
                                style={{
                                    flexGrow: 1,
                                }}
                            >
                                <Button
                                    primary
                                    onClick={handleShowBulkImportModal}
                                >
                                    Bulk import distributors
                                </Button>
                                <Button
                                    primary
                                    onClick={handleShowAddDistributorModal}
                                >
                                    Add distributor
                                </Button>
                            </div>

                            <Button.Group>
                                <Button
                                    icon
                                    primary
                                    onClick={handleShowBulkRemindModal}
                                >
                                    Bulk Remind Distributors{' '}
                                    <Icon name="mail" />
                                </Button>
                            </Button.Group>
                        </div>
                    </CheckPermission>

                    <DistributorListContext.Provider
                        value={{
                            handleSendNotification,
                            handleEditPartyLabel,
                            handleDoesNotDistribute,
                            LinkComponent: Link,
                            PermissionCheckComponent: CheckPermission,
                            canEdit: true,
                        }}
                    >
                        <DistributorList
                            distributors={distributors}
                            onShowFilterModal={handleShowFilterModal}
                        />
                    </DistributorListContext.Provider>

                    {/* <pre>{JSON.stringify(distributors, null, '  ')}</pre> */}
                </>
            )}
        </Container>
    );
};

export default withRouter(ConfirmWrapper(DistributorsPage));
