import { FC, useEffect, useState } from 'react';
import {
    Container,
    Dimmer,
    Loader,
    Segment,
    Table,
    TableBody,
} from 'semantic-ui-react';
import QueueApi from '../../api/queueApi';
import toastr from 'toastr';
import Moment from 'react-moment';
import * as dot from 'dot';
import QueueStatisticsWrapper from './QueueStatisticsWrapper';
import QueuesInProgressWrapper from './QueuesInProgressWrapper';
import QueuesFailureWrapper from './QueuesFailureWrapper';

export const getPayloadData = (verb: string, payload: any) => {
    if (!verb) {
        return '';
    }
    const templateFn = dot.template(verb);
    let templateResult;

    try {
        templateResult = templateFn({
            payload,
        });
    } catch (e) {
        templateResult = 'An event occurred';
    }
    return templateResult;
};

const QueueStatusPage: FC = () => {
    const [queueStatistics, setQueueStatistics] = useState([]);
    const [queuesInProgress, setQueuesInProgress] = useState([]);
    const [failedQueues, setFailedQueues] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        const fetchQueueSummary = async () => {
            try {
                setIsLoading(true);
                const res = await QueueApi.getQueueSummary();
                console.log(res);
                setQueueStatistics(res.queueStatistics);
                setQueuesInProgress(res.queuesInProgress);
                setFailedQueues(res.failedQueues);
                setIsLoading(false);
            } catch (error) {
                toastr.error(error);
                setIsLoading(false);
            }
        };
        fetchQueueSummary();
    }, []);

    return (
        <Container>
            <Dimmer active={isLoading} inverted>
                <Loader disabled={!isLoading} />
            </Dimmer>
            <Segment>
                <h2>Queue Statistics</h2>
                <QueueStatisticsWrapper
                    items={queueStatistics}
                    recordsPerPage={10}
                />
            </Segment>
            <Segment>
                <h2>In Progress</h2>
                <QueuesInProgressWrapper
                    items={queuesInProgress}
                    recordsPerPage={10}
                />
            </Segment>
            <Segment>
                <h2>Failures</h2>
                <QueuesFailureWrapper
                    items={failedQueues}
                    recordsPerPage={10}
                />
            </Segment>
        </Container>
    );
};

export default QueueStatusPage;
