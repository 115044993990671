import { useState } from 'react';
import { Dropdown, Header, Menu, Segment } from 'semantic-ui-react';
import TicketBreadcrumbs from '../TicketBreadcrumbs';
import PartyLabel from '../../common/labels/PartyLabel';
import { TicketContextConsumer } from '../../../context/TicketContext';
import { copyFieldsToClipboard } from '../../../utils/copyFieldsToClipboard';
import { useGetFeatures } from '../../../utils/Features';
import { ExecutionTicketExtended } from '../../../types';
import { useDispatch } from 'react-redux';
import * as uiActions from '../../../actions/uiActions';
import { MODAL_COMPONENTS } from '../../common/ModalRoot';
import CheckPermission from '../../common/CheckPermission';
import * as permissionTypes from '../../../constants/permissionTypes';

interface TicketPageMenuProps {
    ticket: ExecutionTicketExtended | undefined;
    ticketStateActions: {
        addTicketToFollowUpList: (ticketId: number) => void;
    };
    fullPageView: boolean;
    onToggleShowFieldKeys: () => void;
    onToggleShowHiddenTransitions: () => void;
}

const TicketPageMenu = ({
    ticket,
    ticketStateActions,
    fullPageView,
    onToggleShowFieldKeys,
    onToggleShowHiddenTransitions,
}: TicketPageMenuProps) => {
    const dispatch = useDispatch();
    const { FollowUp } = useGetFeatures();
    const [isCreateActionModalOpen, setIsCreateActionModalOpen] =
        useState(false);

    if (!ticket) return null;

    const ticketStyle = fullPageView
        ? 'ticket-page-breadcrumb'
        : 'ticket-page-label';

    const handleCopyFieldsToClipboard = () => {
        const fields = ticket?.activity?.fields;
        if (!fields) return;
        copyFieldsToClipboard(fields);
    };

    const handleCreateAction = () => {
        dispatch(
            uiActions.showModal({
                modalType: MODAL_COMPONENTS.CREATE_ACTION_MODAL,
                modalProps: {
                    ticket: ticket,
                },
            }),
        );
    };

    return (
        <>
            {fullPageView && (
                <PartyLabel
                    party={{
                        label: ticket.assignedPartyLabel,
                        id: ticket.assignedPartyId,
                        status: 'ACTIVE',
                    }}
                />
            )}
            <Menu size="tiny" secondary className="ticket-page-menu">
                <Menu.Item fitted className={ticketStyle}>
                    {fullPageView ? (
                        <Segment vertical className="ticket-breadcrumbs">
                            <TicketBreadcrumbs />
                        </Segment>
                    ) : (
                        <Header size="medium" as="h2">
                            {ticket.label}
                        </Header>
                    )}
                </Menu.Item>

                <Menu.Menu position="right">
                    <Dropdown item icon="ellipsis horizontal">
                        <Dropdown.Menu>
                            {FollowUp && (
                                <Dropdown.Item
                                    onClick={() => {
                                        ticketStateActions.addTicketToFollowUpList(
                                            ticket.id,
                                        );
                                    }}
                                >
                                    Follow up
                                </Dropdown.Item>
                            )}
                            <Dropdown.Item
                                onClick={handleCopyFieldsToClipboard}
                            >
                                Copy fields to clipboard
                            </Dropdown.Item>
                            <Dropdown.Item onClick={onToggleShowFieldKeys}>
                                Toggle show field keys
                            </Dropdown.Item>
                            <Dropdown.Item onClick={handleCreateAction}>
                                Create Action
                            </Dropdown.Item>
                            <CheckPermission
                                structuralNodeId={ticket.structuralNodeId}
                                permissionType={permissionTypes.SUPER}
                            >
                                <Dropdown.Item
                                    onClick={onToggleShowHiddenTransitions}
                                >
                                    Toggle show hidden transitions
                                </Dropdown.Item>
                            </CheckPermission>
                        </Dropdown.Menu>
                    </Dropdown>
                </Menu.Menu>
            </Menu>
        </>
    );
};

export default TicketContextConsumer(TicketPageMenu);
