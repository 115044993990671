import * as Mustache from 'mustache';
import ArgTypes from '../ArgTypes';

function Dropdown(args) {
    const {
        dataSet,
        text,
        textTemplate,
        labelText,
        labelTextTemplate,
        value,
        filterRelationship,
        filterProperty,
        title,
        color,
        icon,
        labelLimit,
    } = args;
    const data = args.data[dataSet];

    const options = data
        ? data.map((col, index) => {
              const textValue = textTemplate
                  ? Mustache.render(textTemplate, col)
                  : col[text];
              const labelTextValue = labelTextTemplate
                  ? Mustache.render(labelTextTemplate, col)
                  : col[labelText];
              return {
                  key: index,
                  text: textValue,
                  value: col[value],
                  labelText: labelTextValue,
                  labelLimit,
                  icon:
                      color || icon
                          ? {
                                name: icon || 'circle',
                                color: color ? col[color] : 'black',
                            }
                          : null,
              };
          })
        : [];

    return {
        ...args.data,
        ...args,
        options,
        title,
        filterRelationship,
        filterProperty,
    };
}

Dropdown.args = {
    dataSet: ArgTypes.string,
    outputKey: ArgTypes.string,
    text: ArgTypes.string,
    textTemplate: ArgTypes.string,
    labelText: ArgTypes.string,
    labelTextTemplate: ArgTypes.string,
    labelLimit: ArgTypes.number,
    value: ArgTypes.string,
    filterRelationship: ArgTypes.string,
    filterProperty: ArgTypes.string,
    title: ArgTypes.string,
    color: ArgTypes.string,
    icon: ArgTypes.string,
};

export default Dropdown;
