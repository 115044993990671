import { createStore, compose, applyMiddleware } from 'redux';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import thunk from 'redux-thunk';
import createHistory from 'history/createBrowserHistory';
// 'routerMiddleware': the new way of storing route changes with redux middleware since rrV4.
import { routerMiddleware } from 'react-router-redux';
import { createRootReducer } from '../reducers';
export const history =
    (typeof process !== 'undefined' && process.env.SERVER_REND) ||
    typeof window === 'undefined'
        ? null
        : createHistory();
let createdStore = {};

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import refeshTokenMiddleware from '../middlewares/refreshTokenMiddleware';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['currentUser', 'events'],
};

function configureStoreProd(initialState, cb) {
    const reactRouterMiddleware = routerMiddleware(history);
    const middlewares = [
        // Add other middleware on this line...

        // thunk middleware can also accept an extra argument to be passed to each thunk action
        // https://github.com/gaearon/redux-thunk#injecting-a-custom-argument
        thunk,
        refeshTokenMiddleware,
        reactRouterMiddleware,
    ];

    const persistedReducer = persistReducer(
        persistConfig,
        createRootReducer(history),
    );

    const store = createStore(
        persistedReducer,
        initialState,
        compose(applyMiddleware(...middlewares)),
    );

    let persistor = persistStore(store, null, cb);

    createdStore = store;

    return { store, persistor };
}

function configureStoreDev(initialState, cb) {
    const reactRouterMiddleware = routerMiddleware(history);
    const middlewares = [
        // Add other middleware on this line...

        // Redux middleware that spits an error on you when you try to mutate your state either inside a dispatch or between dispatches.
        // reduxImmutableStateInvariant(),

        // thunk middleware can also accept an extra argument to be passed to each thunk action
        // https://github.com/gaearon/redux-thunk#injecting-a-custom-argument
        thunk,
        refeshTokenMiddleware,
        reactRouterMiddleware,
    ];

    const persistedReducer = persistReducer(
        persistConfig,
        createRootReducer(history),
    );

    const composeEnhancers =
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // add support for Redux dev tools
    const store = createStore(
        persistedReducer,
        initialState,
        composeEnhancers(applyMiddleware(...middlewares)),
    );

    let persistor = persistStore(store, null, cb);

    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept('../reducers', () => {
            const nextReducer = require('../reducers').default; // eslint-disable-line global-require
            store.replaceReducer(nextReducer);
        });
    }

    createdStore = store;

    return { store, persistor };
}

export function getStore() {
    return createdStore;
}

const configureStore =
    process.env.NODE_ENV === 'production'
        ? configureStoreProd
        : configureStoreDev;

export default configureStore;
