import React, { useState, useEffect } from 'react';
import * as _ from 'lodash';
import * as PropTypes from 'prop-types';
import { Header, Container } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ErrorBoundary from './common/ErrorBoundary';
import EventListContainer from './common/events/EventListContainer';
import EventListFilters from './common/events/EventListFilters';
import useQueryString from '../utils/hooks/useQueryString';
import { getQueryStringValue } from '../utils/QueryString';

import * as listFiltersActions from '../actions/listFiltersActions';

const initialFilterValues = {};

const EventsPage = ({ listFilters, history, stateFilters }) => {
    const { pathname } = { ...history.location };
    const { filters: reduxFilters } = { ...stateFilters[pathname] };
    const [filters, setFilters] = useQueryString(
        reduxFilters || initialFilterValues,
        false,
    );
    const [filterWhere, setFilterWhere] = useState({});
    const [isInitial, setInitial] = useState(true);

    const { eventTypeId, userId, targetModel } = filters;
    const { search } = window.location;

    useEffect(() => {
        checkUrlParams();
    }, []);

    useEffect(() => {
        const filter = {};
        if (eventTypeId) {
            filter.eventTypeId = eventTypeId;
        }
        if (userId) {
            filter.modifiedById = userId;
        }
        if (targetModel) {
            filter.targetModel = targetModel;
        }
        setFilterWhere(filter);
        listFilters.setFilters(pathname, filters);
        setInitial(false);
    }, [eventTypeId, userId, targetModel]);

    useEffect(() => {
        if (!isInitial) {
            checkUrlParams();
        }
    }, [search]);

    const checkUrlParams = () => {
        const queryStringValue = getQueryStringValue(undefined, false);
        if (!_.isEqual(filters, queryStringValue)) {
            setFilters(queryStringValue);
            listFilters.setFilters(pathname, queryStringValue);
        }
    };

    const handleFilterChanged = (e, { name, value }) => {
        const isValueAdded = !_.isEmpty(value);
        if (isValueAdded) {
            setFilters({ ...filters, [name]: value });
            return;
        }
        const newFilters = { ...filters };
        delete newFilters[name];
        setFilters(newFilters);
    };

    const shouldUploadEventsOnDidMount = !(
        eventTypeId ||
        userId ||
        targetModel
    );

    return (
        <Container>
            <Header as="h4">Events</Header>
            <EventListFilters
                filters={filters}
                handleFilterChanged={handleFilterChanged}
            />
            <EventListContainer
                showHiddenButton={true}
                filterWhere={filterWhere}
                uploadOnDidMount={shouldUploadEventsOnDidMount}
            />
        </Container>
    );
};

EventsPage.propTypes = {
    listFilters: PropTypes.object,
    history: PropTypes.object,
    stateFilters: PropTypes.object,
};

function mapStateToProps(state) {
    return {
        stateFilters: state.listFilters,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        listFilters: bindActionCreators(listFiltersActions, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ErrorBoundary(EventsPage));
