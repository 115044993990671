export default {
    activities: [],
    activity: {
        edited: false,
        label: '',
        ref: '',
        description: '',
        activityStatusId: 'EDITING',
        tags: [],
        fields: [],
        workflowStatusFieldDefaults: [],
        resolutionNotes: [],
        toDelete: {
            tags: [],
            fields: [],
            resolutions: [],
        },
        meta: {
            fields: [],
        },
    },
    newProgramme: {
        label: '',
        description: ' ',
        programStatusId: 1,
        activitySets: [],
        categories: [],
    },
    activitySet: {
        label: '',
        activities: [],
    },
    tags: [],
    activityStatuses: [],
    activityResolutionTypes: [],
    activityFieldTypes: [],
    workflow: {
        label: '',
        description: '',
        statuses: [],
        statusNodes: [],
    },
    workflowStatus: {
        name: '',
        statusTypeId: null,
        allowDataEntry: false,
        resolutions: [],
        fields: [],
    },
    workflowStatusNode: {
        label: '',
        transitions: [],
    },
    workflowStatusNodeTransition: {
        label: '',
    },
    constants: {
        workflowStatusTypes: [
            {
                id: 'TODO',
                name: 'To Do',
                sort: 1,
                colourId: 'orange',
            },
        ],
        workflowStatusPermissionTypes: [
            {
                id: 1,
                name: 'Resolve',
            },
            {
                id: 2,
                name: 'Comment',
            },
        ],
        workflowTransitionPermissionTypes: [
            {
                id: 1,
                name: 'Allow',
            },
        ],
        partyRelationshipTypes: [],
        permissionTypes: [],
        roleDefinitions: [],
        riskThresholds: [],
        workflowTransitionConditionTypes: [],
        workflowTransitionPostFunctionTypes: [],
        programmeStatuses: [],
        activityFieldTypes: [],
        activityResolutionTypes: [],
        activityStatuses: [],
        currencies: [],
        attachmentClassification: [],
        options: {},
        attachmentType: [],
    },
    party: {
        id: '',
        structuralNodeId: 0,
        label: '',
        createdAt: '',
        users: [],
        userGroups: [],
        partyRelationships: [],
        addresses: [],
    },
    dashboards: {},
    events: {
        gotInitialList: false,
        newEvents: 0,
        eventsList: [],
        isFailed: false,
        errorMessage: '',
    },
    appData: { startRoute: '/' },
    attachments: { data: [] },
    listFiltersData: {},
};
