import { FC, useEffect, useState } from 'react';
import {
    Button,
    Container,
    Dimmer,
    Header,
    Loader,
    Segment,
} from 'semantic-ui-react';
import * as uiActions from '../../actions/uiActions';
import PartyApi from '../../api/partyApi';
import { MODAL_COMPONENTS } from '../common/ModalRoot';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../actions/store';
import { loadParty } from '../../actions/partyActions';
import UserApi from '../../api/userApi';
import { User } from '../../types';
import { renewToken } from '../../actions/userActions';
import ConfirmWrapper from '../common/ConfirmWrapper';

interface DelegatesPageProps {
    confirmPopupActions: any;
}

const DelegatesPage: FC<DelegatesPageProps> = ({ confirmPopupActions }) => {
    const { showConfirmDialog } = confirmPopupActions;
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [user, setUser] = useState<User>(null);

    const delegateId = useAppSelector(
        (state) => state.party.partySettingValue,
    )?.find(
        (partySetting) => partySetting.partySettingId === 'DELEGATE',
    )?.value;
    const partyId = useAppSelector(
        (state) => state.currentUser?.details?.partyId,
    );

    useEffect(() => {
        const loadUser = async () => {
            setIsLoading(true);
            try {
                const result = await UserApi.getUser(delegateId);
                setUser(result);
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
            }
        };
        if (delegateId) {
            loadUser();
        }
    }, [delegateId]);

    useEffect(() => {
        if (partyId) {
            getParty();
        }
    }, []);

    const getParty = () => {
        setIsLoading(true);
        dispatch<any>(
            loadParty(partyId, { include: ['partySettingValue'] }),
        ).then(() => setIsLoading(false));
    };

    const openAssignDelegate = () => {
        dispatch(
            uiActions.showModal({
                modalType: MODAL_COMPONENTS.ASSIGN_DELEGATE,
                modalProps: {
                    onClose: () => {
                        dispatch(uiActions.closeModal());
                        dispatch(renewToken());
                        getParty();
                    },
                },
            }),
        );
    };

    const removeDelegateClicked = () => {
        showConfirmDialog(
            `Are you sure you want to remove delegate ${user.firstName} ${user.lastName}?`,
            handleRemoveDelegate,
        );
    };

    const handleRemoveDelegate = async () => {
        try {
            await PartyApi.removeDelegate(partyId);
            dispatch(renewToken());
            getParty();
        } catch (error) {
            toastr.error(error);
        }
    };

    return (
        <Container>
            <Dimmer active={isLoading} inverted>
                <Loader />
            </Dimmer>
            <Header as="h2">Delegates</Header>
            <Segment>
                <Header as="h3">Delegate authority</Header>
                {delegateId && user ? (
                    <Button onClick={removeDelegateClicked}>
                        Remove delegate
                    </Button>
                ) : (
                    <Button onClick={openAssignDelegate}>Add delegate</Button>
                )}
            </Segment>
            {delegateId && user && (
                <Segment>
                    <Header as="h3">Delegate information</Header>
                    <strong>First name:</strong> {user.firstName}
                    <br />
                    <strong>Surname:</strong> {user.lastName}
                    <br />
                    <strong>Party Name:</strong> {user?.party?.label}
                </Segment>
            )}
        </Container>
    );
};

export default ConfirmWrapper(DelegatesPage);
