import { useDispatch } from 'react-redux';
import { Button, Dimmer, Message, Modal } from 'semantic-ui-react';
import * as uiActions from '../../actions/uiActions';
import { useState } from 'react';
import { convertAPIError } from '../../utils/ErrorUtils';
import { BulkRemindDistributorsForm } from './BulkRemindDistributorsForm';
import PartyApi, { NotificationType } from '../../api/partyApi';

const getBulkSendNotificationFilter = (
    notificationType: NotificationType,
    daysSinceLastNotification: number,
) => {
    switch (notificationType) {
        case 'NOTIFY_USER_FAIR_VALUE':
            return {
                neverLoggedIn: true,
                doNotEmail: false,
                daysSinceLastDistributorDataRequest: {
                    gt: daysSinceLastNotification,
                },
            };
        case 'SELECT_PRODUCTS_REMINDER':
            return {
                neverLoggedIn: false,
                productCount: 0,
                doNotEmail: false,
                daysSinceLastDistributorDataRequest: {
                    gt: daysSinceLastNotification,
                },
            };
        case 'INCOMPLETE_PRODUCT_FORMS':
            return {
                neverLoggedIn: false,
                productsToSubmitCount: { gt: 0 },
                doNotEmail: false,
                daysSinceLastDistributorDataRequest: {
                    gt: daysSinceLastNotification,
                },
            };
        default:
            return {
                daysSinceLastDistributorDataRequest: { gt: 1000000 },
            }; // SH - Add a default with an impossible filter
    }
};

const BulkRemindDistributorsModal = ({}) => {
    const dispatch = useDispatch();

    const [saving, setSaving] = useState(false);
    const [error, setError] = useState<string[]>();
    const handleCloseModal = () => {
        dispatch(uiActions.closeModal());
    };
    const [fieldError, setFieldError] = useState<string>();
    const [bulkRemindDistributorsState, setBulkRemindDistributorsState] =
        useState<{
            notificationType: NotificationType | null;
            distributorStateOptions: {
                key: number;
                text: string;
                value: NotificationType;
            }[];
            daysSinceLastNotification: number;
        }>({
            notificationType: null,
            distributorStateOptions: [
                {
                    key: 1,
                    text: 'Never Logged In',
                    value: 'NOTIFY_USER_FAIR_VALUE',
                },
                {
                    key: 2,
                    text: 'Not Selected Products',
                    value: 'SELECT_PRODUCTS_REMINDER',
                },
                {
                    key: 3,
                    text: 'Incomplete Product Forms',
                    value: 'INCOMPLETE_PRODUCT_FORMS',
                },
            ],
            daysSinceLastNotification: 5,
        });

    const [bulkNotifyResponse, setBulkNotiffyResponse] = useState<{
        queueId: string;
        itemCount: number;
    } | null>(null);

    const handleCreateDistributor = async () => {
        setError(null);

        if (bulkRemindDistributorsState.daysSinceLastNotification < 0) {
            setFieldError('Provide value greater than 0');
            return;
        }

        try {
            setSaving(true);
            const { notificationType, daysSinceLastNotification } =
                bulkRemindDistributorsState;
            const res = await PartyApi.bulkSendNotification({
                type: notificationType,
                filter: getBulkSendNotificationFilter(
                    notificationType,
                    daysSinceLastNotification,
                ),
            });
            setBulkNotiffyResponse(res);
            setSaving(false);
        } catch (caughtError) {
            setSaving(false);
            setError(convertAPIError(caughtError));
        }
    };

    const handleInputChanged = (event, { name, value }) => {
        setBulkRemindDistributorsState({
            ...bulkRemindDistributorsState,
            [name]: value,
        });
    };
    return (
        <Modal
            open={true}
            onClose={handleCloseModal}
            size="mini"
            closeOnDimmerClick={false}
        >
            <Modal.Header>Bulk Remind Distributors</Modal.Header>
            <Modal.Content>
                <BulkRemindDistributorsForm
                    error={error}
                    data={bulkRemindDistributorsState}
                    onChange={handleInputChanged}
                    fieldError={fieldError}
                />
                {bulkNotifyResponse && (
                    <Message success>
                        {bulkNotifyResponse.itemCount} distributors have been
                        added to the notification queue
                    </Message>
                )}
            </Modal.Content>

            <Modal.Actions>
                {!bulkNotifyResponse && (
                    <Button
                        positive
                        onClick={handleCreateDistributor}
                        loading={saving}
                        disabled={
                            saving ||
                            !bulkRemindDistributorsState.notificationType
                        }
                    >
                        Send Notifications
                    </Button>
                )}
                <Button
                    secondary
                    onClick={handleCloseModal}
                    loading={saving}
                    disabled={saving}
                >
                    Close
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default BulkRemindDistributorsModal;
