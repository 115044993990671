import storeProvider from '../store/storeProvider';
import { registerUIState } from '../actions/uiActions';

export function createUIState(componentName, initialState) {
    const store = storeProvider.getStore().store;
    const uiStates = store.getState().uiStatus.uiStates;

    if (!uiStates[componentName]) {
        store.dispatch(registerUIState(componentName, initialState));
    }
}
