import { useEffect, useState } from 'react';
import { Container, Dimmer, Loader } from 'semantic-ui-react';
import { Party, User } from '../../types';
import UsersListPaginated from './UsersListPaginated';
import PartyApi from '../../api/partyApi';

const extractUniqueUsersFromParties = (parties: Party[]) => {
    const uniqueUsers = [];
    const userIds = {};

    parties.forEach((party) => {
        if (party?.users) {
            party?.users.forEach((user) => {
                if (!userIds[user.id]) {
                    uniqueUsers.push(user);
                    userIds[user.id] = true;
                }
            });
        }
    });

    return uniqueUsers;
};

const UsersPage = () => {
    const [users, setUsers] = useState<User[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const loadUsers = async () => {
        setIsLoading(true);
        try {
            const result = await PartyApi.getParties();
            setUsers(extractUniqueUsersFromParties(result));
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        loadUsers();
    }, []);

    return (
        <Container>
            <Dimmer active={isLoading} inverted>
                <Loader />
            </Dimmer>
            <UsersListPaginated
                loadUsers={loadUsers}
                users={users}
                userIsAdmin={true}
                includeParty={true}
            />
        </Container>
    );
};

export default UsersPage;
