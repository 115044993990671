import React, { Component } from 'react';
import { Header, Segment } from 'semantic-ui-react';

import FilterAndPaginate from '../common/FilterAndPaginate';
import StatusTable from './StatusTable';
import Pagination from '../common/Pagination';
import StatusTableRow from './StatusTableRow';
import { Status } from '../../types';

interface StatusListProps {
    paginationOptions: any;
    paginatedItems: any[];
    handleSortChange: (sortField: string) => void;
    handlePaginationChange: (e: any, data: any) => void;
    partyStateActions: any;
    removeFilterValues: (filters: string[]) => void;
    handleAddFilter: (filters: object) => void;
    currentFilters: any;
    onSave: (status: Status) => any;
}

const StatusList = (props: StatusListProps) => {
    const {
        paginatedItems,
        handleSortChange,
        handlePaginationChange,
        paginationOptions,
        removeFilterValues,
        handleAddFilter,
        currentFilters,
        onSave,
    } = props;

    return (
        <React.Fragment>
            <Header as="h2">Metrics</Header>
            {paginatedItems.length ? (
                <React.Fragment>
                    <StatusTable
                        entities={paginatedItems || []}
                        paginationOptions={paginationOptions}
                        handleSortChange={handleSortChange}
                        removeFilterValues={removeFilterValues}
                        handleAddFilter={handleAddFilter}
                        currentFilters={currentFilters}
                    >
                        {paginatedItems.map((status) => (
                            <StatusTableRow
                                status={status}
                                key={status.id}
                                onSave={onSave}
                            />
                        ))}
                    </StatusTable>
                    <Pagination
                        activePage={paginationOptions.activePage}
                        onPageChange={handlePaginationChange}
                        totalPages={paginationOptions.totalPages}
                        style={{ float: 'right' }}
                    />
                </React.Fragment>
            ) : (
                <Segment className="row">
                    <div className="column">
                        <Header as="h3">
                            There are no statuses associated with your Party.
                        </Header>
                    </div>
                </Segment>
            )}
        </React.Fragment>
    );
};

function mapOptions(props: any) {
    return {
        items: props.entities,
        ...props,
    };
}

export default FilterAndPaginate(mapOptions)(StatusList);
