import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { Dropdown, Form } from 'semantic-ui-react';
import toastr from 'toastr';

import { mapThresholdsToOptions } from '../../utils/React';
import FieldToolTip from './fields/FieldToolTip';
import FieldErrors from './fields/FieldErrors';

class DataFieldReference extends Component {
    state = {
        loading: false,
    };

    componentWillMount() {
        this.setState({ loading: true });
        const { loadThresholds } = this.props.constantStateActions;
        loadThresholds()
            .then(() => this.setState({ loading: false }))
            .catch((error) => {
                toastr.error(error);
                this.setState({ loading: false });
            });
    }

    render() {
        const {
            saving,
            value,
            label,
            errors,
            fieldTooltip,
            name,
            onChange,
            onClose,
            onFocus,
            onBlur,
            riskThresholds,
        } = this.props;
        const { loading } = this.state;
        const fieldErrors = errors ? errors[name] : undefined;
        return (
            <React.Fragment>
                <Form.Field>
                    <label>
                        {label}
                        <FieldToolTip {...fieldTooltip} />
                    </label>
                    <Dropdown
                        fluid
                        selection
                        name={name}
                        options={
                            riskThresholds
                                ? mapThresholdsToOptions(riskThresholds)
                                : []
                        }
                        value={riskThresholds ? value : null}
                        placeholder="Data field"
                        disabled={saving || loading}
                        loading={saving || loading}
                        error={!!fieldErrors}
                        onChange={onChange}
                        onClose={onClose}
                        onFocus={onFocus}
                        onBlur={onBlur}
                    />
                </Form.Field>
                {fieldErrors && <FieldErrors errors={fieldErrors} />}
            </React.Fragment>
        );
    }
}

DataFieldReference.propTypes = {
    onChange: PropTypes.func,
    onClose: PropTypes.func,
    saving: PropTypes.bool,
    showUnassigned: PropTypes.bool,
    value: PropTypes.string,
    label: PropTypes.string,
    errors: PropTypes.object,
    fieldTooltip: PropTypes.object,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    name: PropTypes.string,
    constantStateActions: PropTypes.object,
    riskThresholds: PropTypes.array,
};

DataFieldReference.defaultProps = {
    showUnassigned: true,
};

export default DataFieldReference;
