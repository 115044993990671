import { types } from '@babel/core';
import { useMemo } from 'react';
import clsx from 'clsx';
import { Header } from 'semantic-ui-react';
import {
    RAGStatusResult,
    Result,
    ValueRendererMode,
} from '../../../../../api/statusApi';
import {
    getStatusFlags,
    logicResultToStatus,
    StatusFlags,
} from '../../../../../utils/data/statusUtils';
import DebugComponent from '../DebugComponent';

import './RAGRow.scss';
import { RAGStatusIcon } from '../../status/RAGStatusIcon';
import { stringToFieldType } from '../../../../../utils/data/fieldUtils';
import { ValueRenderer } from '../ValueRenderer';
import { StatusPopupWrapper } from '../StatusPopupWrapper';

interface RAGRowProps {
    header?: string;
    ragLabel?: string;
    chartOptions: any;
    maxHeight: number;
    numberToRender: number;
    data?: PropsData;
    ragMetric: string;
    ragField?: string;
    hideLabel?: 'true' | 'false';
    mode?: ValueRendererMode;
}

export interface PropsData {
    selection: null;
    data: DataData;
}

export interface DataData {
    main: RAGStatusResult;
}

export interface Main {
    results: Result[];
}

const RAGRow = (props: RAGRowProps) => {
    const {
        header,
        data,
        chartOptions,
        maxHeight,
        ragMetric,
        ragField,
        ragLabel,
        mode = 'RAG',
        numberToRender,
        hideLabel,
    } = props;

    const { ragResult, args } = useMemo(() => {
        const args = data?.data?.main?.arguments;
        if (data?.data?.main?.results && (ragMetric || ragField)) {
            const result = data?.data?.main?.results.find(
                (result) => result.ref === ragMetric || result.ref === ragField,
            );

            return { ragResult: result, args };
        }
        return { args };
    }, [data, ragMetric, ragField]);

    let statusFlags: StatusFlags = {};

    if (ragResult) {
        statusFlags = getStatusFlags(ragResult);
    }

    const classNames = clsx('rag-row', {
        'rag-row--error': statusFlags.hasError,
        'rag-row--exception': statusFlags.hasException,
        'rag-row--no-results': statusFlags.hasNoResults,
        'rag-row--unfinished': statusFlags.hasUnfinished,
    });

    let label = ragResult?.label;
    if (header) label = header;
    if (ragLabel) label = ragLabel;

    return (
        <StatusPopupWrapper result={ragResult} args={args}>
            <div className={classNames}>
                {hideLabel !== 'true' && (
                    <div className="rag-row--label">{label}</div>
                )}

                {ragResult && mode === 'RAG' && (
                    <RAGStatusIcon status={ragResult} />
                )}
                {ragResult && mode !== 'RAG' && (
                    <ValueRenderer
                        values={ragResult.statusResults.map((result) =>
                            logicResultToStatus(result.logicResult),
                        )}
                        mode={mode}
                        numberToRender={numberToRender}
                        type={ragResult.type}
                    />
                )}

                {/* <DebugComponent
        statusFlags={statusFlags}
        ragResult={ragResult}
        {...props}
      /> */}
            </div>
        </StatusPopupWrapper>
    );
};

RAGRow.fields = [
    {
        id: 'ragMetric',
        required: false,
        label: 'RAG Metric',
        activityFieldTypeId: 'ModelSearchField',
        options: {
            modelName: 'Status',
            valueProperty: 'ref',
        },
    },
    {
        id: 'ragField',
        required: false,
        label: 'RAG Field',
        activityFieldTypeId: 'ModelSearchField',
        options: {
            modelName: 'ActivityField',
            valueProperty: 'fieldKey',
            refProperty: 'fieldKey',
        },
    },
    {
        id: 'ragLabel',
        required: false,
        label: 'RAG Metric Label',
        activityFieldTypeId: 'Text',
        options: null,
    },
    {
        id: 'numberToRender',
        required: false,
        label: 'Number of values to render',
        activityFieldTypeId: 'Number',
        options: null,
    },
    {
        id: 'mode',
        required: false,
        label: 'Mode',
        activityFieldTypeId: 'Select',
        options: {
            values: [
                'RAG',
                'LATEST_VALUE',
                'COMPARE_LATEST_VALUE',
                'TOTAL',
                'AVERAGE',
            ],
        },
    },
    {
        id: 'hideLabel',
        required: false,
        label: 'Hide label',
        activityFieldTypeId: 'YesNo',
        options: null,
    },
];

export default RAGRow;
