import React from 'react';
import * as PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Modal /*, Dropdown*/ } from 'semantic-ui-react';
import * as _ from 'lodash';

import { pluralize } from './../../utils/Pluralize';
import * as uiActions from '../../actions/uiActions';

const QuestionSetModal = ({ activities, label, uiActions }) => {
    const getNumberOfQuestions = () => {
        return `${
            activities ? pluralize('Question', activities.length, true) : ''
        } in
    ${label || ''} question set`;
    };
    return (
        <Modal
            open={true}
            onClose={() => uiActions.closeModal()}
            size="mini"
            closeOnDimmerClick={false}
            closeIcon
        >
            <Modal.Header>{getNumberOfQuestions()}</Modal.Header>
            <Modal.Content>
                {activities
                    ? _.map(activities, (activity, index) => (
                          <p key={index}>
                              {index + 1}. {activity.label}
                          </p>
                      ))
                    : null}
            </Modal.Content>
        </Modal>
    );
};

QuestionSetModal.propTypes = {
    uiActions: PropTypes.object,
    activities: PropTypes.array,
    label: PropTypes.string,
};

function mapDispatchToProps(dispatch) {
    return {
        uiActions: bindActionCreators(uiActions, dispatch),
    };
}

export default connect(null, mapDispatchToProps)(QuestionSetModal);
