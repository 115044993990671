import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { Button, Modal } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ActivityForm from './ActivityForm';
import * as uiActions from '../../actions/uiActions';

class CreateActivityModal extends Component {
    closeModel = () => {
        const { uiActions } = this.props;
        uiActions.closeModal();
    };

    render() {
        const { activityId, cloneActivity } = this.props;
        return (
            <Modal
                size="large"
                open={true}
                onClose={this.closeModel}
                onOpen={this.openModel}
                closeOnDimmerClick={false}
            >
                <Modal.Header>
                    {activityId && !cloneActivity
                        ? 'Edit Question'
                        : 'Create a new Question'}
                </Modal.Header>
                <Modal.Content>
                    <ActivityForm
                        activityId={activityId}
                        cloneActivity={cloneActivity}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={this.closeModel}>Close</Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

CreateActivityModal.propTypes = {
    activityId: PropTypes.string,
    cloneActivity: PropTypes.bool,
    uiActions: PropTypes.object,
};

function mapDispatchToProps(dispatch) {
    return {
        uiActions: bindActionCreators(uiActions, dispatch),
    };
}

export default connect(null, mapDispatchToProps)(CreateActivityModal);
