import { FC, useState } from 'react';
import { Button, Dimmer, Form, Loader, Modal } from 'semantic-ui-react';
import * as uiActions from '../../actions/uiActions';
import { useDispatch } from 'react-redux';
import ServerConfigurationApi, {
    RatingType,
} from '../../api/serverConfigurationApi';
import Text from '../common/fields/Text';
import TextArea from '../common/fields/TextArea';
import toastr from 'toastr';

interface SaveRatingTypeModalProps {
    item: RatingType;
    onSuccess: () => void;
}

const SaveRatingTypeModal: FC<SaveRatingTypeModalProps> = ({
    item,
    onSuccess,
}) => {
    const [isSaving, setIsSaving] = useState(false);
    const [formData, setFormData] = useState<RatingType>({
        id: item?.id || null,
        code: item?.code || '',
        label: item?.label || '',
        description: item?.description || '',
    });
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(uiActions.closeModal());
    };

    const handleSaveEntity = async () => {
        try {
            setIsSaving(true);
            await ServerConfigurationApi.updateRatingType(
                item ? { ...formData, id: item.id } : formData,
            );
            toastr.success('Rating type saved');
            onSuccess();
            handleClose();
            setIsSaving(false);
        } catch (error) {
            toastr.error(error);
            setIsSaving(false);
        }
    };

    const handleInputChanged = (event, { name, value, checked }) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <Modal
            open={true}
            onClose={handleClose}
            size="small"
            closeOnDimmerClick={false}
        >
            <Dimmer active={isSaving} inverted>
                <Loader disabled={!isSaving} />
            </Dimmer>
            <Modal.Header>
                {item ? `Update ${item.label}` : 'Create new look up type'}
            </Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Field
                        control={Text}
                        label="Code"
                        value={formData.code}
                        onChange={handleInputChanged}
                        name="code"
                    ></Form.Field>
                    <Form.Field
                        control={Text}
                        label="Label"
                        value={formData.label}
                        onChange={handleInputChanged}
                        name="label"
                    ></Form.Field>
                    <Form.Field
                        control={TextArea}
                        label="Description"
                        value={formData.description}
                        onChange={handleInputChanged}
                        name="description"
                    ></Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button positive onClick={handleSaveEntity}>
                    {item ? 'Update' : 'Save'}
                </Button>
                <Button secondary onClick={handleClose}>
                    Cancel
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default SaveRatingTypeModal;
