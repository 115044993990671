import { Entity, EntityEntryField } from '../types';

type ReturnVal = Entity & { [key: string]: any };

export const addEntriesToEntityObject = (
    entity: Entity,
    fields?: EntityEntryField[],
): ReturnVal => {
    const result = entity.entries?.reduce(
        // replacing the the id value of entry with the readable value from the field options
        (accum, current) => {
            const field = fields?.find(
                (field) => field.id === current.entityTypeFieldId,
            );
            const ids = current.value?.split(',').map((id) => id.trim()) || [];
            const valueFromOptions = ids
                .map(
                    (id) =>
                        field?.options?.values?.find(
                            (option) => option?.id == id,
                        )?.lookUpValueName || id,
                )
                .join(', '); // Join the transformed values into a readable format

            return {
                ...accum,
                [current.entityTypeFieldId]: valueFromOptions ?? current.value,
            };
        },
        { ...entity },
    );

    return result;
};

export const addEntriesToEntities = (
    entities: Entity[],
    fields?: EntityEntryField[],
) => {
    return entities.map((entity) => addEntriesToEntityObject(entity, fields));
};
