import { Container } from 'semantic-ui-react';
import BulkImportParty from './BulkImportPartyContainer';

interface Props {
    match: {
        params: {
            id: string;
            type: string;
        };
    };
}

const BulkImportPartyPage = (props: Props) => {
    return (
        <Container>
            <BulkImportParty
                type={props.match.params.type}
                partyId={props.match.params.id}
            />
        </Container>
    );
};

export default BulkImportPartyPage;
