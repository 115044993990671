import _ from 'lodash';

export default function (args) {
    const { dataSet, itemHeading, itemReference, itemContent, groupBy } = args;
    const data = args.data[dataSet];

    const groupByValues = _.chain(data)
        .uniqBy(groupBy)
        .map((v) => v[groupBy])
        .value();

    if (!data) return {};
    const items = data.map((dataPoint) => {
        const item = {};
        if (dataPoint[itemHeading]) item.heading = dataPoint[itemHeading];
        if (dataPoint[itemContent]) item.content = dataPoint[itemContent];
        if (dataPoint[itemReference]) item.reference = dataPoint[itemReference];
        if (groupBy) item.groupByValue = dataPoint[groupBy];
        return item;
    });

    const sections = groupByValues.map((section) => {
        return {
            label: section,
            items: items.filter((item) => item.groupByValue === section),
        };
    });

    return { items, sections };
}
