import React from 'react';
import * as PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

import ProgrammeTableRow from './ProgrammeTableRow';
import SortableHeaderCell from '../common/SortableHeaderCell';
import { ProgrammeContextConsumer } from '../../context/ProgrammeContext';

const ProgrammeTable = (props) => {
    const {
        programmes,
        stateActions,
        programmeActions,
        handleSortChange,
        paginationOptions,
        handleAddFilter,
        currentFilters,
        removeFilterValues,
    } = props;
    return (
        <Table celled sortable>
            <Table.Header>
                <Table.Row>
                    <SortableHeaderCell
                        {...paginationOptions}
                        name="label"
                        handleSortChange={handleSortChange}
                        handleAddFilter={handleAddFilter}
                        currentFilters={currentFilters}
                        removeFilterValues={removeFilterValues}
                        isSearchable={true}
                    >
                        Programme Name
                    </SortableHeaderCell>
                    <SortableHeaderCell
                        {...paginationOptions}
                        name="creator"
                        handleSortChange={handleSortChange}
                        handleAddFilter={handleAddFilter}
                        currentFilters={currentFilters}
                        removeFilterValues={removeFilterValues}
                    >
                        Creator
                    </SortableHeaderCell>
                    <SortableHeaderCell
                        {...paginationOptions}
                        name="ownerParty"
                        handleSortChange={handleSortChange}
                        handleAddFilter={handleAddFilter}
                        currentFilters={currentFilters}
                        removeFilterValues={removeFilterValues}
                    >
                        Owner Party
                    </SortableHeaderCell>
                    <SortableHeaderCell
                        {...paginationOptions}
                        name="status"
                        handleSortChange={handleSortChange}
                        handleAddFilter={handleAddFilter}
                        currentFilters={currentFilters}
                        removeFilterValues={removeFilterValues}
                    >
                        Status
                    </SortableHeaderCell>
                    <SortableHeaderCell
                        {...paginationOptions}
                        name="modifiedAt"
                        handleSortChange={handleSortChange}
                        handleAddFilter={handleAddFilter}
                        currentFilters={currentFilters}
                        removeFilterValues={removeFilterValues}
                    >
                        Last Modified
                    </SortableHeaderCell>
                    <Table.HeaderCell>Actions</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {programmes.map((programme, index) => (
                    <ProgrammeTableRow
                        key={index}
                        programme={programme}
                        stateActions={stateActions}
                        programmeActions={programmeActions}
                    />
                ))}
            </Table.Body>
        </Table>
    );
};

ProgrammeTable.propTypes = {
    programmes: PropTypes.array,
    stateActions: PropTypes.object,
    programmeActions: PropTypes.object,
    handleSortChange: PropTypes.func,
    paginationOptions: PropTypes.object,
    handleAddFilter: PropTypes.func,
    currentFilters: PropTypes.array,
    removeFilterValues: PropTypes.func,
};

export default ProgrammeContextConsumer(ProgrammeTable);
