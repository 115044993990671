import React from 'react';
import { StrictDropdownProps } from 'semantic-ui-react';
import { Label, Icon } from 'semantic-ui-react';
import { APPLICABLE_DATE_TYPES } from '../../utils/ExecutionPlanLaunchConsts';
import ApplicableDateLabel from '../common/ApplicableDateLabel';
import Select from '../common/fields/Select';
import ApplicableDateSelect from './ApplicableDateSelect';

type ApplicableDateProps = {
    applicableDate?: string | string[];
    applicableDateTypeId?: string;
    multiple?: boolean;
    onChange: StrictDropdownProps['onChange'];
};

export const ApplicableDate = ({
    applicableDate,
    applicableDateTypeId,
    multiple,
    onChange,
}: ApplicableDateProps) => {
    const handleRemoveDate = (dateToRemove: string) => {
        if (Array.isArray(applicableDate)) {
            const updatedApplicableDates = applicableDate.filter(
                (date) => date !== dateToRemove,
            );
            onChange(null, {
                name: 'applicableDate',
                value: updatedApplicableDates,
            });
        }
    };

    const handleAddDate = (_, { value }) => {
        const updatedApplicableDates = applicableDate
            ? [...applicableDate, value]
            : [value];
        onChange(null, {
            name: 'applicableDate',
            value: updatedApplicableDates,
        });
    };

    return (
        <>
            <Select
                value={applicableDateTypeId}
                options={APPLICABLE_DATE_TYPES}
                label="Applicable Date Type"
                name="applicableDateTypeId"
                onChange={onChange}
            />
            {applicableDateTypeId && (
                <ApplicableDateSelect
                    applicableDate={
                        multiple || Array.isArray(applicableDate)
                            ? undefined
                            : applicableDate
                    }
                    applicableDateTypeId={applicableDateTypeId}
                    handleFormDataChange={multiple ? handleAddDate : onChange}
                />
            )}
            {multiple && Array.isArray(applicableDate) && (
                <div>
                    {applicableDate.map((date) => (
                        <Label key={date}>
                            <ApplicableDateLabel
                                applicableDate={date}
                                applicableDateTypeId={applicableDateTypeId}
                            />

                            <Icon
                                name="delete"
                                onClick={() => handleRemoveDate(date)}
                            />
                        </Label>
                    ))}
                </div>
            )}
        </>
    );
};

export default ApplicableDate;
