import React, { useState } from 'react';
import {
    Segment,
    Accordion,
    Icon,
    Header,
    List,
    Message,
} from 'semantic-ui-react';
import {
    dimensionLabels,
    Result,
    StatusResultArguments,
} from '../../api/statusApi';
import {
    checkRAGValue,
    logicResultToStatus,
} from '../../utils/data/statusUtils';
import { formatApplicableDate } from '../../utils/FormatUtils';
import ApplicableDateLabel from '../common/ApplicableDateLabel';
import { ValueRenderer } from '../common/dashboard/components/ValueRenderer';
import { RAGIcon } from '../common/dashboard/status/RAGIcon';

import EntityLabel from '../common/labels/EntityLabel';
import PartyLabel from '../common/labels/PartyLabel';
import './MetricResultViewer.scss';
import { convertRAGStateToColor } from '../../utils/thresholds/convertToRag';
import { MetricResultViewerAccordion } from './MetricResultViewAccordion';

type MetricResultViewerProps = {
    result: Result;
    args: StatusResultArguments;
};

export const MetricResultViewer = ({
    result,
    args,
}: MetricResultViewerProps) => {
    const singleResult = result.statusResults.length === 1;

    const [activeIndexes, setActiveIndexes] = useState<number[]>(
        singleResult ? [0] : [],
    );

    const { label, description, statusResults, targetFields } = result;

    const handleAccordionClick = (index: number) => {
        if (activeIndexes.includes(index)) {
            setActiveIndexes(activeIndexes.filter((i) => i !== index));
        } else {
            setActiveIndexes([...activeIndexes, index]);
        }
    };

    const renderDimensionValue = (value: string) => {
        if (args.dimension === 'applicableDate')
            return (
                <ApplicableDateLabel
                    applicableDate={value}
                    applicableDateTypeId="Quarter"
                />
            );

        if (args.dimension === 'assignedEntityId')
            return <EntityLabel entityId={value} />;

        return value;
    };

    return (
        <Segment className="metric-result-viewer" basic>
            <Header size="large">{label}</Header>
            <p>{description}</p>
            {/* <ValueRenderer
        values={statusResults.map((statusResult) => statusResult.logicResult)}
        numberToRender={statusResults.length}
        // type={type}
      /> */}

            <Header attached="top" size="medium" as="h3">
                Input Arguments
            </Header>
            <Segment attached="bottom">
                <List>
                    {args.dimension && (
                        <List.Item>
                            <Header size="small">
                                Dimension: {dimensionLabels[args.dimension]}
                            </Header>
                        </List.Item>
                    )}
                    {args.assignedPartyId &&
                        args.assignedPartyId.length > 0 && (
                            <List.Item>
                                <Header size="small">
                                    Assigned{' '}
                                    {args.assignedPartyId.length > 1
                                        ? 'Parties'
                                        : 'Party'}
                                    :
                                    {args.assignedPartyId.map((partyId) => (
                                        <PartyLabel
                                            key={partyId}
                                            partyId={partyId}
                                            clickable={false}
                                        />
                                    ))}
                                </Header>
                            </List.Item>
                        )}

                    {args.applicableDates &&
                        args.applicableDates.length > 0 && (
                            <List.Item>
                                <Header size="small">
                                    Applicable{' '}
                                    {args.applicableDates.length > 1
                                        ? 'Dates'
                                        : 'Date'}
                                    :{' '}
                                    {args.applicableDates
                                        .map((applicableDate) =>
                                            formatApplicableDate(
                                                applicableDate,
                                                'Quarter',
                                            ),
                                        )
                                        .join(', ')}
                                </Header>
                            </List.Item>
                        )}

                    {args.assignedEntityId &&
                        args.assignedEntityId.length > 0 && (
                            <List.Item>
                                <Header size="small">
                                    Assigned{' '}
                                    {args.assignedEntityId.length > 1
                                        ? 'Entities'
                                        : 'Entity'}
                                    :
                                    {args.assignedEntityId.map((entityId) => (
                                        <EntityLabel
                                            key={entityId}
                                            entityId={entityId}
                                        />
                                    ))}
                                </Header>
                            </List.Item>
                        )}
                </List>
            </Segment>

            <Accordion styled fluid>
                {statusResults.map((statusResult, index) => {
                    const isRagValue = checkRAGValue(statusResult.logicResult);

                    const status = logicResultToStatus(
                        statusResult.logicResult,
                    );

                    const thresholdResult = Array.isArray(
                        statusResult.logicResult,
                    )
                        ? statusResult.logicResult[0]?.thresholdResult
                        : statusResult.logicResult.thresholdResult;

                    const color = convertRAGStateToColor(thresholdResult);

                    return (
                        <React.Fragment key={index}>
                            <Accordion.Title
                                active={activeIndexes.includes(index)}
                                onClick={
                                    singleResult
                                        ? undefined
                                        : () => handleAccordionClick(index)
                                }
                                className="metric-result-viewer--accordion-title"
                            >
                                <>
                                    {!singleResult && <Icon name="dropdown" />}
                                    {statusResult.dimensionValue !== 'all' && (
                                        <Header as="span">
                                            {renderDimensionValue(
                                                statusResult.dimensionValue,
                                            )}
                                        </Header>
                                    )}
                                    <Message
                                        className="metric-result-viewer--accordion-title-result"
                                        color={color}
                                        floating
                                    >
                                        Metric Result:
                                        {isRagValue && (
                                            <RAGIcon status={status} />
                                        )}
                                        {!isRagValue && (
                                            <ValueRenderer
                                                values={[
                                                    logicResultToStatus(
                                                        statusResult.logicResult,
                                                    ),
                                                ]}
                                                //   type={type}
                                                numberToRender={1}
                                            />
                                        )}
                                    </Message>
                                </>
                            </Accordion.Title>
                            <Accordion.Content
                                active={activeIndexes.includes(index)}
                            >
                                <MetricResultViewerAccordion
                                    statusResult={statusResult}
                                    targetFields={targetFields}
                                />
                            </Accordion.Content>
                        </React.Fragment>
                    );
                })}
            </Accordion>
            {/* <JSONBuilder
        name="exportFilter"
        value={{ result, args }}
        allowModeChange={true}
      /> */}
        </Segment>
    );
};
