import React from 'react';

const ExecutionContext = React.createContext();

export const ExecutionContextConsumer = (ComponentToWrap) =>
    class WrapContext extends React.Component {
        render() {
            return (
                <ExecutionContext.Consumer>
                    {(context) => {
                        return <ComponentToWrap {...context} {...this.props} />;
                    }}
                </ExecutionContext.Consumer>
            );
        }
    };

export default ExecutionContext;
