import { useEffect, useState } from 'react';
import {
    Button,
    Container,
    Dimmer,
    Form,
    Header,
    Loader,
    Segment,
    Select,
} from 'semantic-ui-react';
import ServerConfigurationApi, {
    RatingType,
    RatingValue,
} from '../../api/serverConfigurationApi';
import toastr from 'toastr';
import RatingValuesTable from './RatingValuesTable';
import { useDispatch } from 'react-redux';
import * as uiActions from '../../actions/uiActions';
import { MODAL_COMPONENTS } from '../common/ModalRoot';

const RatingConfigurationPage = () => {
    const [isLoading, setIsLoading] = useState(false);

    const [ratingTypes, setRatingTypes] = useState<RatingType[]>([]);
    const [selectedRatingTypeId, setSelectedRatingTypeId] = useState<any>();
    const [ratingValues, setRatingValues] = useState<RatingValue[]>([]);

    const dispatch = useDispatch();

    useEffect(() => {
        fetchRatingTypes();
    }, []);

    const fetchRatingTypes = async () => {
        setIsLoading(true);
        try {
            const ratingData = await ServerConfigurationApi.getRatingTypes();
            setRatingTypes(ratingData);
            setIsLoading(false);
        } catch (error) {
            toastr.error(error);
            setIsLoading(false);
        }
    };

    const fetchRatingValuesById = async (id) => {
        setIsLoading(true);
        try {
            const res = await ServerConfigurationApi.getRatingValuesById(id);
            setIsLoading(false);
            setRatingValues(res);
        } catch (error) {
            toastr.error(error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        selectedRatingTypeId && fetchRatingValuesById(selectedRatingTypeId);
    }, [selectedRatingTypeId]);

    const ratingTypesOptions = ratingTypes.map((item) => ({
        key: item.id,
        value: item.id,
        text: item.label,
    }));

    const handleAddRatingType = () => {
        dispatch(
            uiActions.showModal({
                modalType: MODAL_COMPONENTS.SAVE_RATING_TYPE_MODAL,
                modalProps: {
                    onSuccess: fetchRatingTypes,
                },
            }),
        );
    };
    const handleEditRatingType = () => {
        dispatch(
            uiActions.showModal({
                modalType: MODAL_COMPONENTS.SAVE_RATING_TYPE_MODAL,
                modalProps: {
                    item: ratingTypes.find(
                        (x) => x.id === selectedRatingTypeId,
                    ),
                    onSuccess: fetchRatingTypes,
                },
            }),
        );
    };

    const handleAddRatingValue = () => {
        dispatch(
            uiActions.showModal({
                modalType: MODAL_COMPONENTS.SAVE_RATING_VALUE_MODAL,
                modalProps: {
                    onSuccess: () =>
                        fetchRatingValuesById(selectedRatingTypeId),
                },
            }),
        );
    };

    return (
        <Container>
            <Dimmer active={isLoading} inverted>
                <Loader disabled={!isLoading} />
            </Dimmer>
            <Header as="h2">Rating Configuration</Header>
            <Segment>
                <Header as="h3">Rating Type</Header>
                <Form>
                    <Form.Group>
                        <Form.Input width={4}>
                            <Select
                                value={selectedRatingTypeId}
                                fluid
                                options={ratingTypesOptions}
                                onChange={(_, { value }) => {
                                    setSelectedRatingTypeId(value);
                                }}
                            />
                        </Form.Input>
                        <Form.Input>
                            <Button onClick={handleAddRatingType}>Add</Button>
                        </Form.Input>
                        <Form.Input>
                            <Button onClick={handleEditRatingType}>Edit</Button>
                        </Form.Input>
                    </Form.Group>
                </Form>
            </Segment>
            <Segment>
                <Header as="h3">Rating Values</Header>
                <Button onClick={handleAddRatingValue} primary>
                    Add new Rating Value
                </Button>
                <RatingValuesTable
                    // @ts-ignore
                    fetchRatingValuesById={fetchRatingValuesById}
                    ratingValues={ratingValues}
                />
            </Segment>
        </Container>
    );
};

export default RatingConfigurationPage;
