import _ from 'lodash';
import apiTools from './apiTools';
import storeProvider from '../store/storeProvider';

class UserApi {
    static login(user) {
        return apiTools
            .post(`Users/login`, { ...user, includePermissions: false })
            .then((response) => {
                return response.data;
            });
    }

    static getAuthType(email) {
        return apiTools
            .post(`Auths/getAuthType`, { email })
            .then((response) => {
                return response.data;
            });
    }
    static loginSaml(email) {
        return apiTools.post(`Auths/loginSaml`, { email }).then((response) => {
            return response.data;
        });
    }
    static verifySamlToken(token) {
        return apiTools
            .post(`Auths/verifySamlAuhToken`, { token })
            .then((response) => {
                return response.data;
            });
    }

    static verifyTelephoneNumber(data) {
        return apiTools
            .post(`Users/verifyTelephoneNumber`, data)
            .then((response) => {
                return response.data;
            });
    }

    static verifySMSCode(data) {
        return apiTools.post(`Users/verifySMSCode`, data).then((response) => {
            return response.data;
        });
    }

    static loginAsUser(userId) {
        return apiTools
            .post(`Users/loginAsUser`, { userId })
            .then((response) => {
                return response.data;
            });
    }
    static returnToOriginalUser() {
        return apiTools.post(`Users/returnToOriginalUser`).then((response) => {
            return response.data;
        });
    }

    static renewToken() {
        const storeState = storeProvider.getStore().store.getState();
        const { lastPermissionChange } = storeState.currentUser;
        const params = {
            includePermissions: false,
        };
        return apiTools.get(`Users/renewToken`, params).then((response) => {
            return response.data;
        });
    }

    static getUsers(searchQuery, partyId, extrafilter = {}) {
        const filter = _.merge(
            {
                order: 'lastName ASC',
                include: ['party'],
                where: {},
            },
            extrafilter
        );
        if (searchQuery) {
            filter.where = {
                or: [
                    {
                        lastName: {
                            like: `%${searchQuery}%`,
                        },
                    },
                    {
                        firstName: {
                            like: `%${searchQuery}%`,
                        },
                    },
                ],
            };
        }
        if (partyId) {
            if (_.isArray(partyId)) {
                filter.where = {
                    and: [
                        filter.where,
                        {
                            partyId: { inq: partyId },
                        },
                    ],
                };
            } else {
                filter.where = {
                    and: [filter.where, { partyId }],
                };
            }
        }

        return apiTools
            .get('Users', {
                filter,
            })
            .then((response) => {
                return response.data;
            });
    }

    static getUser(id) {
        return apiTools
            .get(`Users/${id}`, { filter: { include: ['party'] } })
            .then((response) => {
                return response.data;
            });
    }

    static saveUser(user) {
        const apiMethod = user.id ? apiTools.patch : apiTools.post;
        return apiMethod(`Users`, user).then((response) => {
            return response.data;
        });
    }

    static resetPassword(userId) {
        return apiTools
            .post(`/Users/${userId}/triggerResetPassword`)
            .then((response) => {
                return response.data;
            });
    }

    static updatePassword(updatePasswordData) {
        return apiTools
            .post(`/Users/resetPassword`, updatePasswordData)
            .then((response) => {
                return response.data;
            });
    }

    static getUserVerificationType(getUserVerificationTypeData) {
        return apiTools
            .post(`Users/getUserVerificationType`, getUserVerificationTypeData)
            .then((response) => {
                return response.data;
            });
    }

    static getUserByPrincipalId(principalId) {
        const filter = {
            where: { principalId },
        };

        return apiTools
            .get('Users', {
                filter,
            })
            .then((response) => {
                return response.data[0];
            });
    }

    static getPrincipal(principalId) {
        return apiTools
            .get(`Principals/${principalId}`, {
                filter: {
                    include: ['user', 'userGroup'],
                },
            })
            .then((response) => {
                return response.data;
            });
    }

    static getPermissionsForNode(nodeId) {
        return apiTools
            .get(`Users/permissionsForNode`, { nodeId })
            .then((response) => {
                return response.data;
            });
    }
    static getNodesByType(type) {
        return apiTools.get(`Users/nodesByType`, { type }).then((response) => {
            return response.data;
        });
    }
}

export default UserApi;
