import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as partyActions from '../../actions/partyActions';
import * as userActions from '../../actions/userActions';
import * as uiActions from '../../actions/uiActions';
import * as constantActions from '../../actions/constantActions';
import PartyContext from '../../context/PartyContext';
import { extractFunctions } from '../../utils/React';
import { MODAL_COMPONENTS } from '../common/ModalRoot';
import { Party } from '../../types';

interface State {
    party: Party;
    parties: Party[];
    constants: {
        partyRelationshipTypes: object[];
    };
}

interface PartyProps {
    [key: string]: any; // Allows any extra props
}

interface ModalProps {
    modalType: string;
    modalProps: object;
}

const PartyWrapper = (ComponentToWrap: FC<any>) => {
    const Party: FC<PartyProps> = (props) => {
        const dispatch = useDispatch();
        const {
            party,
            parties,
            constants: { partyRelationshipTypes },
        } = useSelector((state: State) => state);

        const partyStateActions = bindActionCreators(partyActions, dispatch);
        const userStateActions = bindActionCreators(userActions, dispatch);
        const uiStateActions = bindActionCreators(uiActions, dispatch);
        const constantStateActions = bindActionCreators(
            constantActions,
            dispatch,
        );

        const openModal = useCallback(
            (modalType: string, modalProps: object) => {
                uiStateActions.showModal({
                    modalType,
                    modalProps,
                });
            },
            [uiStateActions],
        );

        const openSaveUserModal = (user = null) =>
            openModal(MODAL_COMPONENTS.SAVE_USER_MODAL, {
                party,
                userStateActions,
                user,
            });

        const openSaveRelationshipModal = (relationship = null) =>
            openModal(MODAL_COMPONENTS.SAVE_RELATIONSHIP_MODAL, {
                partyStateActions,
                relationship,
                relationshipTypes: partyRelationshipTypes,
                party,
            });

        const openSaveUserGroupModal = (userGroup = null) =>
            openModal(MODAL_COMPONENTS.SAVE_USERGROUP_MODAL, {
                party,
                partyStateActions,
                userGroup,
            });

        const openAddUserToUserGroupModal = (userGroup) =>
            openModal(MODAL_COMPONENTS.ADD_USER_TO_USERGROUP_MODAL, {
                party,
                userGroup,
                partyStateActions,
            });

        const openDeleteUserModal = (userGroup, user) =>
            openModal(MODAL_COMPONENTS.REMOVE_USER_FROM_USERGROUP, {
                userGroup,
                user,
                partyStateActions,
            });

        const openSavePartyAddressModal = (address = null) =>
            openModal(MODAL_COMPONENTS.SAVE_PARTY_ADDRESS_MODAL, {
                party,
                partyStateActions,
                address,
            });

        const openCreateThreshold = (threshold = null) =>
            openModal(MODAL_COMPONENTS.SAVE_CREATE_THRESHOLD_MODAL, {
                party,
                partyStateActions,
                constantStateActions,
                threshold,
            });

        const values = {
            party,
            parties,
            partyActions: {
                openSaveUserModal,
                openSaveRelationshipModal,
                openSaveUserGroupModal,
                openAddUserToUserGroupModal,
                openDeleteUserModal,
                openSavePartyAddressModal,
                openCreateThreshold,
                ...extractFunctions(this),
            },
            partyStateActions,
            userStateActions,
            uiActions,
            constantStateActions,
        };

        return (
            <PartyContext.Provider value={values}>
                <ComponentToWrap {...values} {...props} />
            </PartyContext.Provider>
        );
    };

    return Party;
};

export default PartyWrapper;
