import * as types from '../constants/actionTypes';
import update from 'immutability-helper';
import _ from 'lodash';

export default function createPermissionReducer(name = '') {
    return function permissionReducer(
        state = { currentPermission: { __type: name } },
        action,
    ) {
        switch (action.type) {
            case `${types.LOAD_PERMISSION_SUCCESS}_${name}`: {
                const { permission } = action;
                return update(state, {
                    currentPermission: {
                        $set: { ...permission, __type: name },
                    },
                });
            }
            case `${types.LOAD_PERMISSION_SUCCESS}`: {
                const { structuralNode } = action;
                return update(state, {
                    structuralNode: {
                        $set: { ...structuralNode },
                    },
                });
            }

            case `${types.UPDATE_PERMISSION_SUCCESS}`: {
                const { principalRole, roleDefinition } = action;
                if (principalRole.structuralNodeId !== state.structuralNode.id)
                    return state;
                const principalRoleIndex = _.findIndex(
                    state.structuralNode.principalRoles,
                    {
                        id: principalRole.id,
                    },
                );
                const { roleDefinitionId } = principalRole;
                return update(state, {
                    structuralNode: {
                        principalRoles: {
                            [principalRoleIndex]: {
                                roleDefinition: { $set: roleDefinition },
                                roleDefinitionId: { $set: roleDefinitionId },
                            },
                        },
                    },
                });
            }

            case `${types.DELETE_PERMISSION_SUCCESS}`: {
                const { principalRoleId, structuralNodeId } = action;
                if (structuralNodeId !== state.structuralNode.id) return state;
                const principalRoleIndex = _.findIndex(
                    state.structuralNode.principalRoles,
                    {
                        id: principalRoleId,
                    },
                );
                return update(state, {
                    structuralNode: {
                        principalRoles: {
                            $splice: [[principalRoleIndex, 1]],
                        },
                    },
                });
            }

            default:
                return state;
        }
    };
}
