import utilsApi from '../api/utilsApi';
import * as types from '../constants/actionTypes';

export function loadItems(baseConfig) {
    return function (dispatch) {
        return utilsApi.modelCall(baseConfig).then((data) => {
            return dispatch({
                type: `${types.LOAD_ITEMS_SUCCESS}`,
                data,
            });
        });
    };
}

export function clearItems() {
    return {
        type: types.CLEAR_ITEMS,
    };
}
