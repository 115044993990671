import React, { useContext } from 'react';
import * as PropTypes from 'prop-types';
import Moment from 'react-moment';
import { Form, List, Select, Tab, Table } from 'semantic-ui-react';
import toastr from 'toastr';
import { Link } from 'react-router-dom';

import ActionButtons from '../common/ActionButtons';
import ConfirmationModalContext from '../../context/ConfirmationModalContext';
import { Entity, Status } from '../../types';
import * as uiActions from '../../actions/uiActions';
import { MODAL_COMPONENTS } from '../common/ModalRoot';
import { useDispatch } from 'react-redux';
import { deleteStatus } from '../../actions/statusActions';
import { creationStatusOptions } from './StatusEditor';
import { on } from 'events';
import { set } from 'lodash';

interface Props {
    status: Status;
    onSave: (status: Status) => any;
}

const StatusListItem = ({ status, onSave }: Props) => {
    const [saving, setSaving] = React.useState(false);
    const { confirmPopupActions } = useContext(ConfirmationModalContext);
    const dispatch = useDispatch();

    const handleDeleteStatus = () => {
        confirmPopupActions.showConfirmDialog(
            `Are you sure you want to delete ${status.label}?`,
            deleteStatusOnConfirm,
        );
    };
    const deleteStatusOnConfirm = async () => {
        await dispatch(deleteStatus(status));

        // deletePartyStatus(status.id).then(
        //   deleteStatusOnConfirmCallback
        // );
    };
    const deleteStatusOnConfirmCallback = () => {
        toastr.success(`Metric ${status.label} is removed`);
    };

    const handleEditStatus = async (e, { value: creationStatus }) => {
        const updatedStatus = {
            ...status,
            creationStatus,
        };

        setSaving(true);

        try {
            await onSave(updatedStatus);
        } catch (error) {
        } finally {
            setSaving(false);
        }
    };

    return (
        <Table.Row>
            <Table.Cell>
                <Link to={`/metrics/${status.id}`}>{status.label}</Link>
            </Table.Cell>
            <Table.Cell>{status.ref}</Table.Cell>
            <Table.Cell collapsing>
                <Form.Field
                    control={Select}
                    options={creationStatusOptions}
                    onChange={handleEditStatus}
                    value={status.creationStatus}
                    placeholder="Creation Status"
                    loading={saving}
                />
            </Table.Cell>

            <Table.Cell textAlign="center">
                <ActionButtons
                    className="u-show-on-hover__target"
                    deleteClicked={handleDeleteStatus}
                />
            </Table.Cell>
        </Table.Row>
    );
};

StatusListItem.propTypes = {
    status: PropTypes.object.isRequired,
    deletePartyStatus: PropTypes.func.isRequired,
    editPartyStatus: PropTypes.func.isRequired,
    showConfirmDialog: PropTypes.func.isRequired,
};

export default StatusListItem;
