import React, { useState, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import { Form, Button } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';

import Date from '../common/fields/Date';

import * as ticketActions from '../../actions/ticketActions';
import { mapToOptions } from '../../utils/React';

const initialNewDeadline = {
    deadlineDate: null,
    workflowStatusId: null,
};

const TicketAddDeadlineForm = ({ ticket, ticketActions, workflow }) => {
    const [newDeadline, setNewDeadline] = useState(initialNewDeadline);
    const [pending, setPending] = useState(false);
    const [isValid, setIsValid] = useState(false);

    const { deadlineDate, workflowStatusId } = newDeadline;

    useEffect(() => {
        const newIsActiveButton = deadlineDate && workflowStatusId;
        setIsValid(newIsActiveButton);
    }, [deadlineDate, workflowStatusId]);

    const { deadlines, id: ticketId } = ticket;
    const isButtonActive = isValid && !pending;
    const deadlinesWorkflowStatuses = _.map(
        deadlines,
        ({ workflowStatusId }) => workflowStatusId,
    );

    const availableWokflowStatuses = _.filter(
        workflow.statuses,
        ({ id }) => _.indexOf(deadlinesWorkflowStatuses, id) === -1,
    );
    if (availableWokflowStatuses.length === 0) {
        return null;
    }
    const workflowOptions = mapToOptions(availableWokflowStatuses);

    const handleNewDeadlineChange = (e, { name, value }) => {
        setNewDeadline({ ...newDeadline, [name]: value });
    };

    const handleAddDeadlineClick = () => {
        setPending(true);
        ticketActions.addDeadline(ticketId, newDeadline).then(() => {
            setPending(false);
            setNewDeadline(initialNewDeadline);
        });
    };

    return (
        <Form>
            <Form.Group inline>
                <Form.Select
                    fluid
                    label="Workflow Status"
                    options={workflowOptions}
                    placeholder="Workflow"
                    onChange={handleNewDeadlineChange}
                    name="workflowStatusId"
                    value={workflowStatusId}
                />
                <Date
                    label="Deadline Date"
                    value={deadlineDate}
                    onChange={handleNewDeadlineChange}
                    name="deadlineDate"
                    isClearable={true}
                />
                <Button
                    primary
                    onClick={handleAddDeadlineClick}
                    disabled={!isButtonActive}
                    loading={pending}
                >
                    Add
                </Button>
            </Form.Group>
        </Form>
    );
};

TicketAddDeadlineForm.propTypes = {
    ticket: PropTypes.object,
    workflow: PropTypes.object,
    ticketId: PropTypes.number,
    ticketActions: PropTypes.object,
};

function mapStateToProps(state, props) {
    const { ticket } = props;
    const { workflowId } = ticket;
    return {
        workflow: state.workflows.byId[workflowId],
    };
}

function mapDispatchToProps(dispatch) {
    return {
        ticketActions: bindActionCreators(ticketActions, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TicketAddDeadlineForm);
