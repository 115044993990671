import React from 'react';
import * as PropTypes from 'prop-types';
import { WrapWizardContext } from '../../context/WizardContext';
import { Button } from 'semantic-ui-react';

const WizardActions = (props) => {
    const {
        currentStep,
        steps,
        wizardChoices,
        stepForward,
        handleSubmit,
        saving,
    } = props;
    const stepConfig = steps[currentStep];
    if (!stepConfig || stepConfig.hideActions) return null;

    const { formValidationFunction, showSubmitButtons, submitButtonLabel } =
        stepConfig;

    const valid = formValidationFunction
        ? formValidationFunction(wizardChoices)
        : true;

    const handleStepForward = () => {
        stepForward();
    };

    return (
        <React.Fragment>
            {!showSubmitButtons && (
                <Button onClick={handleStepForward} disabled={!valid}>
                    Continue
                </Button>
            )}
            {showSubmitButtons && (
                <Button
                    onClick={handleSubmit}
                    primary
                    disabled={saving}
                    loading={saving}
                >
                    {submitButtonLabel || 'Submit'}
                </Button>
            )}
        </React.Fragment>
    );
};

WizardActions.propTypes = {
    currentStep: PropTypes.string,
    steps: PropTypes.object,
    wizardChoices: PropTypes.object,
    stepForward: PropTypes.func,
    handleSubmit: PropTypes.func,
    saving: PropTypes.bool,
};

export default WrapWizardContext(WizardActions);
