import { FC, useEffect, useState } from 'react';
import {
    Button,
    Container,
    Dimmer,
    Form,
    Header,
    Loader,
    Segment,
} from 'semantic-ui-react';
import ServerConfigurationApi, {
    FeatureFlag,
    ServerConfig,
} from '../../api/serverConfigurationApi';
import CheckBoxBool from '../common/fields/CheckBoxBool';
import toastr from 'toastr';
import Text from '../common/fields/Text';

const ServerConfigurationPage: FC = () => {
    const [isLoading, setIsLoading] = useState(false);

    const [featureFlagList, setFeatureFlagList] = useState<FeatureFlag[]>([]);
    const [isSaveFeatureFlagLoading, setIsSaveFeatureFlagLoading] =
        useState(false);

    const [serverConfigList, setServerConfigList] = useState<ServerConfig[]>(
        [],
    );
    const [
        isSaveServerConfigurationLoading,
        setIsSaveServerConfigurationLoading,
    ] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        const fetchFeatureFlags = async () => {
            try {
                const featureFLags =
                    await ServerConfigurationApi.getFeatureFlagList();
                setFeatureFlagList(featureFLags);

                const serverConfig =
                    await ServerConfigurationApi.getServerConfig();
                setServerConfigList(serverConfig);

                setIsLoading(false);
            } catch (error) {
                toastr.error(error);
                setIsLoading(false);
            }
        };

        fetchFeatureFlags();
    }, []);

    const handleFeatureFlagChange = (code: string, newValue: boolean) => {
        setFeatureFlagList((prevState) => {
            return prevState.map((featureFlag) => {
                if (featureFlag.code === code) {
                    return { ...featureFlag, value: newValue };
                } else return featureFlag;
            });
        });
    };

    const handleSaveFeatureFlagChange = async () => {
        setIsSaveFeatureFlagLoading(true);
        try {
            await ServerConfigurationApi.saveFeatureFlagList(featureFlagList);
            setIsSaveFeatureFlagLoading(false);
        } catch (error) {
            toastr.error(error);
            setIsSaveFeatureFlagLoading(false);
        }
    };

    const handleServerConfigChange = (config_key: string, newValue: string) => {
        setServerConfigList((prevState) => {
            return prevState.map((featureFlag) => {
                if (featureFlag.config_key === config_key) {
                    return { ...featureFlag, config_value: newValue };
                } else return featureFlag;
            });
        });
    };
    const handleSaveServerConfigChange = async () => {
        setIsSaveServerConfigurationLoading(true);
        try {
            await ServerConfigurationApi.saveServerConfigList(serverConfigList);
            setIsSaveServerConfigurationLoading(false);
        } catch (error) {
            toastr.error(error);
            setIsSaveServerConfigurationLoading(false);
        }
    };

    return (
        <Container>
            <Dimmer active={isLoading} inverted>
                <Loader disabled={!isLoading} />
            </Dimmer>
            <Header as="h2">Server Configuration</Header>
            <Segment>
                <Header as="h3">Features</Header>
                <Form>
                    {featureFlagList.map((feature) => (
                        <CheckBoxBool
                            name={feature.code}
                            key={feature.code}
                            label={feature.label}
                            value={feature.value}
                            description={feature.description}
                            onChange={(_, { value }) => {
                                handleFeatureFlagChange(feature.code, value);
                            }}
                        />
                    ))}
                    <Button
                        loading={isSaveFeatureFlagLoading}
                        disabled={isSaveFeatureFlagLoading}
                        onClick={handleSaveFeatureFlagChange}
                    >
                        Save
                    </Button>
                </Form>
            </Segment>
            <Segment>
                <Header as="h3">Server Configuration</Header>
                <Form>
                    {serverConfigList.map((serverConfig) => (
                        <Text
                            name={serverConfig.config_key}
                            key={serverConfig.config_key}
                            label={serverConfig.label}
                            value={serverConfig.config_value}
                            description={serverConfig.description}
                            onChange={(_, { value }) => {
                                handleServerConfigChange(
                                    serverConfig.config_key,
                                    value,
                                );
                            }}
                        />
                    ))}
                    <Button
                        loading={isSaveServerConfigurationLoading}
                        disabled={isSaveServerConfigurationLoading}
                        onClick={handleSaveServerConfigChange}
                    >
                        Save
                    </Button>
                </Form>
            </Segment>
        </Container>
    );
};

export default ServerConfigurationPage;
