import { Dropdown, Form, Input, Message, Select } from 'semantic-ui-react';
import { NotificationType } from '../../api/partyApi';

type BulkRemindDistributorsFormProps = {
    fieldError?: string;
    error?: string[];
    data: DistributorFormData;
    onChange: (event: any, data: { name: string; value?: string }) => void;
};

export type DistributorFormData = {
    notificationType: NotificationType;
    distributorStateOptions: {
        key: number;
        text: string;
        value: NotificationType;
    }[];
    daysSinceLastNotification: number;
};

export const BulkRemindDistributorsForm = ({
    fieldError,
    data,
    onChange,
    error,
}: BulkRemindDistributorsFormProps) => {
    const {
        notificationType,
        distributorStateOptions,
        daysSinceLastNotification,
    } = data;

    const getSelectionMessage = () => {
        switch (notificationType) {
            case 'NOTIFY_USER_FAIR_VALUE':
                return 'This will resend the initial notification email to distributors that have not logged in to the system';
            case 'SELECT_PRODUCTS_REMINDER':
                return 'This will send a reminder email to distributors that have logged into the system but not selected any products';
            case 'INCOMPLETE_PRODUCT_FORMS':
                return 'This will send a reminder email to distributors that have selected products but not completed the forms';
        }
    };

    return (
        <>
            <Form>
                <Form.Field
                    options={distributorStateOptions}
                    control={Dropdown}
                    id="notificationType"
                    name="notificationType"
                    label="Distributor state"
                    placeholder="Distributor state"
                    value={notificationType}
                    onChange={onChange}
                    fluid
                    selection
                />
                {notificationType && <Message>{getSelectionMessage()}</Message>}
                <Form.Field
                    control={Input}
                    fluid
                    placeholder="Days since last notification"
                    label="Days since last notification"
                    value={daysSinceLastNotification}
                    name="daysSinceLastNotification"
                    onChange={onChange}
                    type="number"
                    error={fieldError}
                />
            </Form>
            {error && <Message error>{error.toString()}</Message>}
        </>
    );
};
