import React from 'react';
import * as _ from 'lodash';
import * as ReactHighcharts from 'react-highcharts';

import { gradientColors } from '../../../constants/config';

if (ReactHighcharts.default?.Highcharts?.wrap) {
    ReactHighcharts.default?.Highcharts?.wrap(
        ReactHighcharts.default?.Highcharts.Legend?.prototype,
        'getAllItems',
        function (p) {
            if (!this.options.mergedPieItems) {
                return p.call(this);
            }

            var allItems = [],
                uniquePieNames = [];

            this.chart.series.forEach(function (series) {
                var i, len, point;
                if (!series || !series.options?.showInLegend) return;

                if (series.type === 'pie') {
                    for (i = 0, len = series.data.length; i < len; i++) {
                        point = series.data[i];

                        if (uniquePieNames.indexOf(point.name) === -1) {
                            uniquePieNames.push(point.name);
                            allItems.push(point);
                        }
                    }
                } else {
                    allItems.push(series);
                }
            });

            return allItems;
        },
    );
}
if (ReactHighcharts.default?.Highcharts.setOptions) {
    ReactHighcharts.default?.Highcharts.setOptions({
        colors: [
            //     '#0066cc',
            // '#009900',
            // '#ff9900',	'#009ce5'
            // '#46648c',
            // '#242444',
            gradientColors.teal,
            gradientColors.yellow,
            gradientColors.violet,
            gradientColors.olive,
            gradientColors.orange,
        ],
    });
}

import * as chartConfigs from './chartConfigBase';
import { debug } from 'util';
import { getExportingOptions } from './BarChartComponent';

interface Props {
    data: any;
    chartOptions: any;
    maxHeight: number;
}

const PieChartComponent: React.FC<Props> = ({
    data,
    chartOptions,
    maxHeight,
}) => {
    const createChartConfig = () => {
        const pieData = _.get(data, 'data.pieData');
        const config = _.merge(
            {},
            chartConfigs.pie,
            chartOptions,
            {
                series: pieData,
            },
            { exporting: getExportingOptions(chartOptions) },
        );

        if (maxHeight) config.chart.height = null;

        return config;
    };

    return (
        <div className="component-dashboard-widget chart-component">
            <ReactHighcharts.default config={createChartConfig()} />
        </div>
    );
};

export default PieChartComponent;
