import React, { ErrorInfo, ReactNode, Component } from 'react';

interface ErrorWrapperProps {
    children?: ReactNode;
}

interface ErrorWrapperState {
    hasError: boolean;
    error: Error | null;
}

class SimpleErrorWrapper extends Component<
    ErrorWrapperProps,
    ErrorWrapperState
> {
    state: ErrorWrapperState = {
        hasError: false,
        error: null,
    };

    static getDerivedStateFromError(error: Error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // You can also log the error to an error reporting service
        console.log(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // In development, print the error to the screen, else return null
            if (process.env.NODE_ENV === 'development') {
                return <h1>{this.state.error?.toString()}</h1>;
            } else {
                return null;
            }
        }

        // When there's not an error, render children untouched
        return this.props.children;
    }
}

export default SimpleErrorWrapper;
