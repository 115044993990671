import React, { useEffect, useState, useRef } from 'react';
import queueApi, { QueueStatus } from '../../api/queueApi';
import { QueueStatusViewer } from './QueueStatusViewer';
import { Divider, Header, Message, Segment } from 'semantic-ui-react';
import { QueueErrorViewer } from './QueueErrorViewer';
import { renewToken } from '../../actions/userActions';
import { useDispatch } from 'react-redux';

interface Props {
    queueId: string;
}

const QueueDetails = ({ queueId }: Props) => {
    const dispatch = useDispatch();
    const [queueStatus, setQueueStatus] = useState<QueueStatus>();
    const intervalIdRef = useRef<NodeJS.Timeout | null>(null); // Store the interval ID

    const queueComplete =
        queueStatus?.completeItems + queueStatus?.failedItems ===
        queueStatus?.totalItems;

    useEffect(() => {
        const fetchQueueStatus = async () => {
            try {
                const queueStatus = await queueApi.getQueueStatus(queueId);
                setQueueStatus(queueStatus);

                // Check if queue is complete and clear interval if it is
                const { completeItems, failedItems, totalItems } = queueStatus;
                const isComplete = completeItems + failedItems === totalItems;
                if (isComplete && intervalIdRef.current !== null) {
                    clearInterval(intervalIdRef.current);
                    intervalIdRef.current = null; // Reset the ref after clearing interval
                }
            } catch (e) {
                console.log(e);
            }
        };

        // Immediately fetch queueStatus on mount
        fetchQueueStatus();

        // Set up interval to fetch queueStatus every 3 seconds
        intervalIdRef.current = setInterval(fetchQueueStatus, 3000);

        // Clear interval on unmount
        return () => {
            if (intervalIdRef.current !== null) {
                clearInterval(intervalIdRef.current);
            }
        };
    }, [queueId]);

    useEffect(() => {
        if (!queueComplete) return;
        dispatch(renewToken());
    }, [queueComplete]);

    const getQueueItems = async () => {
        const items = await queueApi.getQueueItems({
            where: { queueId, failed: true },
        });
        return items;
    };

    return (
        <>
            {queueStatus && (
                <Segment>
                    <QueueStatusViewer
                        queueStatus={queueStatus}
                    ></QueueStatusViewer>
                    {queueStatus.failedItems > 0 && (
                        <>
                            <Divider />
                            <QueueErrorViewer
                                queueId={queueId}
                                numberOfFailedItems={queueStatus.failedItems}
                                getQueueItems={getQueueItems}
                            />
                        </>
                    )}
                </Segment>
            )}
        </>
    );
};

export default QueueDetails;
