import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as uiActions from '../../actions/uiActions';
import { Menu, Popup } from 'semantic-ui-react';

import './ContextMenu.scss';

const ContextMenuRoot = (props) => {
    const { uiStatus, uiActions } = props;

    if (!uiStatus.showContextMenu) return null;

    const { positionX, positionY, items, target } = uiStatus.contextMenuOptions;

    const handleMenuClick = (e, data) => {
        const { action } = data;
        uiActions.closeContextMenu();
        uiActions.dispatchAction(action, {
            target,
        });
    };

    return (
        <React.Fragment>
            <Popup
                className="context-menu"
                open={true}
                onClose={() => {
                    uiActions.closeContextMenu();
                }}
                trigger={<div />}
                basic
                style={{
                    top: positionY,
                    left: positionX,
                }}
            >
                <Menu vertical>
                    {items.map((item) => (
                        <Menu.Item
                            onClick={handleMenuClick}
                            action={item.action}
                            key={item.key}
                        >
                            {item.name}
                        </Menu.Item>
                    ))}
                </Menu>
            </Popup>
        </React.Fragment>
    );
};

ContextMenuRoot.propTypes = {
    uiStatus: PropTypes.object,
    uiActions: PropTypes.object,
};

function mapStateToProps(state) {
    return {
        uiStatus: state.uiStatus,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        uiActions: bindActionCreators(uiActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContextMenuRoot);
