import React, { useReducer, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { availableMaps } from '../../../utils/data/DataMapper';
import * as _ from 'lodash';
import { Divider, Dropdown, Header } from 'semantic-ui-react';
import DataMapper from './DataMapper';
import update from 'immutability-helper/index';
import ActionButtons from '../../common/ActionButtons';
import reduceReducers from '../../../reducers/helpers/reduceReducers';
import arrayReducer from '../../../reducers/arrayReducer';

function reducer(state, action) {
    switch (action.type) {
        case 'UPDATE_MAPPERS': {
            const { value } = action;
            return { ...state, value };
        }
        case 'SHOW_VALUE': {
            const { index } = action;
            const newState = update(state, {
                value: {
                    [index]: {
                        visible: {
                            $set: true,
                        },
                    },
                },
            });
            return newState;
        }

        case 'HIDE_VALUE': {
            const { index } = action;
            const newState = update(state, {
                value: {
                    [index]: {
                        visible: {
                            $set: false,
                        },
                    },
                },
            });
            return newState;
        }

        case 'UPDATE_MAPPER_FIELD': {
            const { index, name, value } = action;
            const newState = update(state, {
                value: {
                    [index]: {
                        args: {
                            [name]: {
                                $set: value,
                            },
                        },
                    },
                },
            });
            return newState;
        }
        default:
            return state;
    }
}

const DataMappers = (props) => {
    const { mappers = [], onChange, mappedData } = props;

    const combinedReducer = reduceReducers(reducer, arrayReducer);

    const [state, dispatch] = useReducer(combinedReducer, { value: mappers });

    // useEffect(() => {
    //
    //   const newValue = mappers.map(mapper => ({...mapper, visible:false}));
    //   if (_.isEqual(newValue, state.value)) return;
    //
    //   dispatch({
    //     type: 'UPDATE_MAPPERS',
    //     value: mappers
    //   });
    // }, [mappers]);

    useEffect(() => {
        onChange(state.value);
    }, [state.value]);

    const handleMapperFieldChange = ({ index, name, value }) => {
        dispatch({
            type: 'UPDATE_MAPPER_FIELD',
            index,
            name,
            value,
        });
    };

    return (
        <div>
            {state.value.map((mapper, index) => (
                <React.Fragment key={index}>
                    <ActionButtons
                        removeClicked={() => {
                            dispatch({
                                type: 'REMOVE_VALUE',
                                index,
                            });
                        }}
                        upClicked={() => {
                            dispatch({
                                type: 'MOVE_VALUE_UP',
                                index,
                            });
                        }}
                        downClicked={() => {
                            dispatch({
                                type: 'MOVE_VALUE_DOWN',
                                index,
                            });
                        }}
                        viewClicked={
                            mapper.visible
                                ? null
                                : () => {
                                      dispatch({
                                          type: 'SHOW_VALUE',
                                          index,
                                      });
                                  }
                        }
                        hideClicked={
                            mapper.visible
                                ? () => {
                                      dispatch({
                                          type: 'HIDE_VALUE',
                                          index,
                                      });
                                  }
                                : null
                        }
                    />
                    <Header> {mapper.name} </Header>

                    {mapper.visible && (
                        <DataMapper
                            availableMaps={availableMaps}
                            mapper={mapper}
                            mappedData={mappedData}
                            onFieldChange={(data) => {
                                handleMapperFieldChange({ index, ...data });
                            }}
                        />
                    )}
                    <Divider />
                </React.Fragment>
            ))}

            <Dropdown
                text="Add field"
                icon="add circle"
                floating
                labeled
                button
                scrolling
                className="icon"
                upward
            >
                <Dropdown.Menu>
                    <Dropdown.Header content="Add mapper" />
                    {_.map(availableMaps, (map, key) => (
                        <Dropdown.Item
                            key={key}
                            text={key}
                            value={key}
                            onClick={(e, { value }) => {
                                dispatch({
                                    type: 'ADD_VALUE',
                                    value: {
                                        name: value,
                                        args: {},
                                    },
                                });
                            }}
                        />
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};

DataMappers.propTypes = {
    mappers: PropTypes.array,
    onChange: PropTypes.func,
    mappedData: PropTypes.object,
};

export default DataMappers;
