import * as types from '../constants/actionTypes';
import { checkValidation, transformValidation } from '../utils/Validation';
import workflowApi from '../api/workflowApi';
import ValidationSchema from '../utils/ValidationSchema';
import * as storeTypes from '../constants/storeTypes';
import initialState from '../reducers/initialState';

export function updateWorkflow(workflow, name, value) {
    return { type: `${types.UPDATE_WORKFLOW}_${workflow.__type}`, name, value };
}

export function addStatus(workflow, value) {
    return { type: `${types.WORKFLOW_ADD_STATUS}_${workflow.__type}`, value };
}

export function removeStatus(workflow, status) {
    return {
        type: `${types.WORKFLOW_REMOVE_STATUS}_${workflow.__type}`,
        status,
    };
}

export function updateStatus(workflow, status, name, value) {
    return {
        type: `${types.WORKFLOW_UPDATE_STATUS}_${workflow.__type}`,
        workflow,
        status,
        name,
        value,
    };
}

export function addStatusField(
    workflow,
    status,
    fieldType,
    label = '',
    options = {},
) {
    return {
        type: `${types.ADD_WORKFLOW_STATUS_FIELD}_${workflow.__type}`,
        status,
        fieldType,
        label,
        options,
    };
}

export function updateStatusField(workflow, status, fieldIndex, value) {
    return {
        type: `${types.UPDATE_WORKFLOW_STATUS_FIELD}_${workflow.__type}`,
        status,
        fieldIndex,
        value,
    };
}

export function removeStatusField(workflow, status, fieldIndex) {
    return {
        type: `${types.REMOVE_WORKFLOW_STATUS_FIELD}_${workflow.__type}`,
        status,
        fieldIndex,
    };
}

export function addStatusNode(workflow, value) {
    return {
        type: `${types.WORKFLOW_ADD_STATUS_NODE}_${workflow.__type}`,
        value,
    };
}

export function removeStatusNode(workflow, statusNode) {
    return {
        type: `${types.WORKFLOW_REMOVE_STATUS_NODE}_${workflow.__type}`,
        statusNode,
    };
}

export function updateStatusNode(workflow, statusNode, name, value) {
    return {
        type: `${types.WORKFLOW_UPDATE_STATUS_NODE}_${workflow.__type}`,
        statusNode,
        name,
        value,
    };
}

export function addStatusResolution(workflow, status, resolutionType) {
    return {
        type: `${types.WORKFLOW_ADD_STATUS_RESOLUTION}_${workflow.__type}`,
        status,
        resolutionType,
    };
}

export function removeStatusResolution(workflow, status, resolutionType) {
    return {
        type: `${types.WORKFLOW_REMOVE_STATUS_RESOLUTION}_${workflow.__type}`,
        status,
        resolutionType,
    };
}

export function updateStatusResolution(
    workflow,
    status,
    resolutionType,
    value,
) {
    return {
        type: `${types.WORKFLOW_UPDATE_STATUS_RESOLUTION}_${workflow.__type}`,
        status,
        resolutionType,
        value,
    };
}

export function addStatusPermission(workflow, status, resolutionType) {
    return {
        type: `${types.WORKFLOW_ADD_STATUS_PERMISSION}_${workflow.__type}`,
        status,
        resolutionType,
    };
}

export function removeStatusPermission(workflow, status, resolutionType) {
    return {
        type: `${types.WORKFLOW_REMOVE_STATUS_PERMISSION}_${workflow.__type}`,
        status,
        resolutionType,
    };
}

export function updateStatusPermission(workflow, value) {
    return {
        type: `${types.WORKFLOW_UPDATE_STATUS_PERMISSION}_${workflow.__type}`,
        value,
    };
}

export function addTransition(workflow, statusNode, value) {
    return {
        type: `${types.WORKFLOW_ADD_TRANSITION}_${workflow.__type}`,
        statusNode,
        value,
    };
}

export function removeTransition(workflow, statusNode, transition) {
    return {
        type: `${types.WORKFLOW_REMOVE_TRANSITION}_${workflow.__type}`,
        statusNode,
        transition,
    };
}

export function updateTransition(
    workflow,
    statusNode,
    transition,
    name,
    value,
) {
    return {
        type: `${types.WORKFLOW_UPDATE_TRANSITION}_${workflow.__type}`,
        statusNode,
        transition,
        name,
        value,
    };
}

export function addTransitionPermission(workflow, value) {
    return {
        type: `${types.WORKFLOW_ADD_TRANSITION_PERMISSION}_${workflow.__type}`,
        value,
    };
}

export function removeTransitionPermission(workflow, value) {
    return {
        type: `${types.WORKFLOW_REMOVE_TRANSITION_PERMISSION}_${workflow.__type}`,
        value,
    };
}

export function updateTransitionPermission(workflow, value) {
    return {
        type: `${types.WORKFLOW_UPDATE_TRANSITION_PERMISSION}_${workflow.__type}`,
        value,
    };
}

export function saveStateWorkflow(workflow, extraProperties = {}) {
    return function (dispatch, getState) {
        const stateWorkflow = Object.assign(
            {},
            getState()[`Workflow${workflow.__type}`],
            extraProperties,
        );

        const validation = checkValidation(
            stateWorkflow,
            ValidationSchema.workflow,
        );

        if (!validation.valid) {
            return Promise.reject(transformValidation(validation));
        }

        return workflowApi.saveWorkflow(stateWorkflow).then((savedWorkflow) => {
            return dispatch({
                type: `${types.SAVE_WORKFLOW_SUCCESS}_${workflow.__type}`,
                savedWorkflow,
            });
        });
    };
}

export function setWorkflow(
    workflow = initialState.workflow,
    type = storeTypes.EDITING,
) {
    return {
        type: `${types.SET_WORKFLOW}_${type}`,
        workflow,
    };
}

const loadWorkflowThunk = (workflow) => (dispatch) =>
    Promise.resolve().then(() => {
        return dispatch({
            type: `${types.LOAD_WORKFLOW_SUCCESS}`,
            workflow,
        });
    });

export function loadWorkflow({ id, ticketId, alsoSetWorkflow, type }) {
    return function (dispatch, getState) {
        return workflowApi.getWorkflow(id, ticketId).then((workflow) => {
            return dispatch(loadWorkflowThunk(workflow)).then(() => {
                if (alsoSetWorkflow) {
                    dispatch(setWorkflow(getState().workflows.byId[id]), type);
                }
            });
        });
    };
}

export function loadWorkflows() {
    return function (dispatch) {
        return workflowApi.getWorkflows().then((workflows) => {
            return dispatch({
                type: types.LOAD_WORKFLOWS_SUCCESS,
                workflows,
            });
        });
    };
}
