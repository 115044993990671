import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import components from '../../../ComponentStore';
import { Dropdown, DropdownItem, DropdownMenu, Table } from 'semantic-ui-react';
import * as _ from 'lodash';

import './DataTableComponent.scss';
import {
    downloadCSV,
    downloadImage,
    downloadXLSX,
} from '../../../../../utils/downloadUtils';
import DownloadDropdown from '../KpiComponent/DownloadDropdown';

const serverSideRender =
    typeof process !== 'undefined' && !!process.env.SERVER_REND;

class DataTableComponent extends Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
    }

    renderValue = (value = '', key) => {
        let returnValue;
        let componentName = 'StyledCell';
        if (value && value.component) componentName = value.component;
        const Component = components[componentName];

        if (_.isPlainObject(value)) {
            returnValue = <Component {...value.args} key={key} />;
        } else {
            returnValue = <Component value={value} key={key} />;
        }

        if (
            componentName === 'StyledCell' ||
            componentName === 'AnalyseAndImplement'
        )
            return returnValue;

        return (
            <Table.Cell key={key} {...value.columnArgs} valign="top">
                {returnValue}
            </Table.Cell>
        );
    };

    handleDownloadImage = (format) => {
        downloadImage(this.tableRef.current, format);
    };

    handleDownloadCSV = () => {
        const tableData = this.props.data.data.rowData.map((item) => {
            const obj = item.columnNames.reduce((acc, column, index) => {
                return { ...acc, [column]: item.data[index].args.value };
            }, {});
            return obj;
        });

        downloadCSV(tableData);
    };

    handleDownloadXLSX = () => {
        const columns = this.props.data.data.columnNames.map((column) => ({
            label: column,
            value: column,
        }));
        const tableData = this.props.data.data.rowData.reduce(
            (acc, item) => {
                const content = item.columnNames.reduce(
                    (obj, column, index) => {
                        return {
                            ...obj,
                            [column]: item.data[index].args.value,
                        };
                    },
                    {},
                );

                return {
                    ...acc,
                    content: [...acc.content, content],
                };
            },
            { sheet: 'data', columns, content: [] },
        );

        downloadXLSX([tableData]);
    };

    render() {
        const rowData = _.get(this, 'props.data.data.rowData');
        if (!rowData) return null;

        const columnNames = _.get(rowData, '0.columnNames');

        return (
            <div ref={this.tableRef}>
                <Table
                    verticalAlign="top"
                    clear="all"
                    celled={serverSideRender}
                    fixed={serverSideRender}
                    border={serverSideRender ? 1 : null}
                    cellSpacing={serverSideRender ? 0 : null}
                    cellPadding={serverSideRender ? 5 : null}
                    className={`data-table-component`}
                    ref={(ref) => console.log(ref)}
                >
                    <DownloadDropdown
                        isKPI={false}
                        onDownloadImage={this.handleDownloadImage}
                        onDownloadCSV={this.handleDownloadCSV}
                        onDownloadXLSX={this.handleDownloadXLSX}
                    />
                    {columnNames && (
                        <Table.Header>
                            <Table.Row>
                                {columnNames.map((columnName, index) => {
                                    if (_.isArray(columnName)) {
                                        return columnName.map(
                                            (subColumnName, subIndex) => (
                                                <Table.HeaderCell
                                                    key={`${index}_${subIndex}`}
                                                    valign="top"
                                                >
                                                    {subColumnName}
                                                </Table.HeaderCell>
                                            ),
                                        );
                                    }
                                    return (
                                        <Table.HeaderCell
                                            key={index}
                                            valign="top"
                                        >
                                            {columnName}
                                        </Table.HeaderCell>
                                    );
                                })}
                            </Table.Row>
                        </Table.Header>
                    )}
                    <Table.Body>
                        {rowData.map((row, index) => (
                            <Table.Row key={index} verticalAlign="top">
                                {row.data.map((col, index) =>
                                    this.renderValue(col, index),
                                )}
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </div>
        );
    }
}

DataTableComponent.propTypes = {
    data: PropTypes.object,
    maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default DataTableComponent;
