import { FC, useState } from 'react';
import { Modal } from 'semantic-ui-react';

import * as uiActions from '@brallum/vidende/src/actions/uiActions';
import TermsAndConditionsApi, {
    TermsAndConditions,
} from '@brallum/vidende/src/api/termsAndConditionsApi';
import { useDispatch } from 'react-redux';
import TermsAndConditionsModalContent from '@vidende/components/TermsAndConditionsModalContent';

interface TermsAndConditionsModalProps {
    termsAndConditionsList: TermsAndConditions[];
}

const TermsAndConditionsModal: FC<TermsAndConditionsModalProps> = ({
    termsAndConditionsList,
}) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [acceptedTerms, setAcceptedTerms] = useState(
        termsAndConditionsList.reduce((acc, current) => {
            return { ...acc, [current.code]: { value: false, id: current.id } };
        }, {}),
    );

    const [sendEmail, setSendEmail] = useState(false);

    const handleChange = (_, { name, value }) => {
        setAcceptedTerms((prevState) => {
            return { ...prevState, [name]: { ...prevState[name], value } };
        });
    };

    const handleOpenTemplate = (item: TermsAndConditions) => {
        const newWindow = window.open();
        newWindow.document.write(item.template);
    };

    const handleCloseModal = () => {
        dispatch(uiActions.closeModal());
    };

    const handleSaveAcceptedTermsAndConditions = async () => {
        setIsLoading(true);
        try {
            await TermsAndConditionsApi.acceptTermsAndConditions(
                acceptedTerms,
                sendEmail,
            );
            setIsLoading(false);
            handleCloseModal();
        } catch (error) {
            setIsLoading(false);
        }
    };

    const disabled = Object.values(acceptedTerms).some(
        (item: any) => item.value === false,
    );

    return (
        <Modal
            open={true}
            onClose={handleCloseModal}
            size="small"
            closeOnDimmerClick={false}
        >
            <TermsAndConditionsModalContent
                acceptedTerms={acceptedTerms}
                disabled={disabled}
                onChange={handleChange}
                onOpenTemplate={handleOpenTemplate}
                onSaveAcceptedTermsAndConditions={
                    handleSaveAcceptedTermsAndConditions
                }
                isLoading={isLoading}
                sendEmail={sendEmail}
                onSetSendEmail={setSendEmail}
                termsAndConditionsList={termsAndConditionsList}
            />
        </Modal>
    );
};

export default TermsAndConditionsModal;
