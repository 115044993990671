import React, { useState } from 'react';
import * as _ from 'lodash';
import * as PropTypes from 'prop-types';
import Date from '../fields/Date';
import { Form } from 'semantic-ui-react';
import moment from 'moment/moment';

const DateSelectRangeComponent = (props) => {
    const { callback, propertyToSet, data, externalValue } = props;

    const minDate = _.get(data, 'data.values.minDate');
    const maxDate = _.get(data, 'data.values.maxDate');
    const fromInitial = _.get(externalValue, 'fromDate');
    const toInitial = _.get(externalValue, 'toDate');

    const [valueFrom, setValueFromCurrentValue] = useState(fromInitial);
    const [valueTo, setValueToCurrentValue] = useState(toInitial);

    const handleChange = (e, { value, name }) => {
        switch (name) {
            case 'fromDate': {
                setValueFromCurrentValue(value);
                break;
            }
            case 'toDate': {
                setValueToCurrentValue(value);
                break;
            }
        }

        if (callback && _.isObject(propertyToSet)) {
            const property = _.get(propertyToSet, name);
            if (!property) return;
            callback({
                value,
                property,
            });
        }
    };

    const fromDate = moment(valueFrom);
    const toDate = moment(valueTo);
    return (
        <React.Fragment>
            <Form>
                <Form.Group widths="equal">
                    <Form.Field>
                        <Date
                            minDate={moment(minDate)}
                            maxDate={moment(maxDate).add(1, 'days')}
                            selected={
                                valueFrom
                                    ? moment(valueFrom).add(1, 'hours')
                                    : null
                            }
                            value={valueFrom ? moment(valueFrom) : null}
                            label="From"
                            name="fromDate"
                            onChange={handleChange}
                            selectsStart={true}
                            startDate={fromDate}
                            endDate={toDate}
                            isClearable={true}
                            showLabel={false}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Date
                            minDate={moment(minDate)}
                            maxDate={moment(maxDate).add(1, 'days')}
                            selected={valueTo ? moment(valueTo) : null}
                            value={valueTo ? moment(valueTo) : null}
                            label="To"
                            name="toDate"
                            onChange={handleChange}
                            selectsEnd={true}
                            startDate={fromDate}
                            endDate={toDate}
                            isClearable={true}
                            showLabel={false}
                        />
                    </Form.Field>
                </Form.Group>
            </Form>
        </React.Fragment>
    );
};

DateSelectRangeComponent.propTypes = {
    callback: PropTypes.func,
    data: PropTypes.object,
    propertyToSet: PropTypes.object,
    externalValue: PropTypes.object,
};

export default DateSelectRangeComponent;
