import React, { useReducer, useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import Fields from './Fields';
import Text from './Text';
import update from 'immutability-helper/index';
import FieldToolTip from './FieldToolTip';
import { Form, Segment } from 'semantic-ui-react';
import ActionButtons from '../ActionButtons';

function reducer(state, action) {
    switch (action.type) {
        case 'UPDATE_VALUE': {
            const { index, value } = action;
            const newState = update(state, {
                value: {
                    [index]: {
                        $set: value,
                    },
                },
            });
            return newState;
        }
        case 'DUPLICATE_VALUE': {
            const { index, value } = action;
            const newState = update(state, {
                value: {
                    [`${index} copy`]: {
                        $set: value,
                    },
                },
            });
            return newState;
        }
        case 'REMOVE_VALUE': {
            const { index } = action;
            const newState = update(state, {
                value: {
                    $unset: [index],
                },
            });
            return newState;
        }
        default:
            return state;
    }
}

const ObjectField = (props) => {
    const { type, value = {}, label, onChange, name, width, required } = props;

    const [state, dispatch] = useReducer(reducer, { value });
    const [keyToAdd, setKeyToAdd] = useState('');
    const [valueToAdd, setValueToAdd] = useState(null);

    useEffect(() => {
        onChange({}, { name, value: state.value });
    }, [state.value]);

    const handleUpdateField = (event, { value, index }) => {
        dispatch({
            type: 'UPDATE_VALUE',
            value,
            index,
        });
    };

    if (!type) return null;

    const Field = Fields[type.field];

    return (
        <Form.Field required={required} width={width}>
            <Segment>
                <label>
                    {label}
                    <FieldToolTip {...props} />
                </label>
                {_.map(state.value, (v, index) => (
                    <React.Fragment key={index}>
                        <Field
                            label={index}
                            value={v}
                            onChange={(event, data) => {
                                handleUpdateField(event, { ...data, index });
                            }}
                        />
                        <ActionButtons
                            removeClicked={() => {
                                dispatch({
                                    type: 'REMOVE_VALUE',
                                    index,
                                });
                            }}
                            copyClicked={() => {
                                setValueToAdd(v);
                            }}
                        />
                    </React.Fragment>
                ))}
            </Segment>

            <Form>
                <Form.Group widths="equal">
                    <Text
                        label="Key"
                        placeholder="key"
                        value={keyToAdd}
                        onChange={(e, { value }) => {
                            setKeyToAdd(value);
                        }}
                    />
                    <Field
                        label="Value"
                        value={valueToAdd}
                        onChange={(event, { value }) => {
                            setValueToAdd(value);
                        }}
                    />
                    <ActionButtons
                        addClicked={() => {
                            dispatch({
                                type: 'UPDATE_VALUE',
                                value: valueToAdd,
                                index: keyToAdd,
                            });
                        }}
                    />
                </Form.Group>
            </Form>
        </Form.Field>
    );
};

ObjectField.propTypes = {
    type: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    name: PropTypes.string,
    required: PropTypes.bool,
    width: PropTypes.number,
};

export default ObjectField;
