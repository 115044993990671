import { Container, Grid } from 'semantic-ui-react';
import { ModelExplorer } from './programmeExplorer/ModelExplorer';
import ModelExplorerGroup from './programmeExplorer/ModelExplorerGroup';

const ProgrammeExplorerPage = () => {
    return (
        <Container fluid>
            <ModelExplorerGroup
                explorers={[
                    {
                        modelName: 'Programme',
                        relationshipConfig: {
                            Programme: ['activitySets'],
                            ActivitySet: ['activities'],
                            Activity: ['fields'],
                        },
                    },
                    {
                        modelName: 'StatusReport',
                        relationshipConfig: {
                            StatusReport: ['statuses', 'activityFields'],
                        },
                    },
                    {
                        modelName: 'Dashboard',
                        relationshipConfig: {
                            Dashboard: ['rows'],
                            DashboardRow: ['columns'],
                            DashboardColumn: ['items'],
                        },
                        flattenBy: {
                            Dashboard: ['rows', 'columns', 'items'],
                        },
                    },
                ]}
            />
        </Container>
    );
};

export default ProgrammeExplorerPage;
