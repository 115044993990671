import { get, map } from 'lodash';

export function convertAPIError(error) {
    const messages = get(error, 'response.data.error.details.messages');

    let apiErrors = [];

    if (messages) {
        apiErrors = map(messages, (m) => m[0]);
    } else {
        let message = get(error, 'response.data.error.message');
        if (!message) message = get(error, 'response.statusText');
        if (!message) message = get(error, 'message');
        if (!message || message === 'Internal Server Error')
            message =
                'An unexpected error occurred, please try again. If the problem persists please contact your administrator.';
        apiErrors = [message];
    }

    return apiErrors;
}
