import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import { Dimmer, Loader, Header } from 'semantic-ui-react';
import toastr from 'toastr';

import * as filterActions from '../../actions/filterActions';
import * as uiActions from '../../actions/uiActions';
import Components from './ListComponents';
import { filtersByModel } from '../../constants/apiFilters';
import ErrorBoundary from '../common/ErrorBoundary';
class FilterList extends Component {
    state = {
        loading: false,
        ticketId: null,
        targetModel: '',
    };

    componentDidMount() {
        this.fetchData();
    }

    componentWillUnmount() {
        this.props.filterActions.clearItems();
    }

    fetchData = () => {
        const { callConfig, filterActions } = this.props;
        if (callConfig) {
            const ListComponent = Components[callConfig.model];
            const filter = filtersByModel[callConfig.model]
                ? _.cloneDeep(filtersByModel[callConfig.model])
                : {};
            this.setState({
                loading: true,
                targetModel: callConfig.model,
                ListComponent,
            });

            const config = _.cloneDeep(callConfig);
            config.params.filter = callConfig.params?.filter
                ? _.merge(filter, callConfig.params?.filter)
                : filter;
            filterActions
                .loadItems(config)
                .then(() => {
                    this.setState({ loading: false });
                })
                .catch((error) => {
                    this.setState({ loading: false });
                    toastr.error(error);
                    throw error;
                });
        }
    };

    renderFilterList = (items) => {
        const { onItemClick, callConfig } = this.props;
        const { ListComponent } = this.state;
        if (!ListComponent)
            return (
                <Header>List component not found for {callConfig.model}</Header>
            );
        return items.length ? (
            <ListComponent
                items={items}
                onItemClick={onItemClick}
                callConfig={callConfig}
            />
        ) : (
            <Header>There are no items available</Header>
        );
    };

    render() {
        const { filterData } = this.props;
        const { loading } = this.state;
        return (
            <React.Fragment>
                <Dimmer active={loading} inverted>
                    <Loader disabled={!loading} />
                </Dimmer>
                {filterData && this.renderFilterList(filterData)}
            </React.Fragment>
        );
    }
}

FilterList.propTypes = {
    filterActions: PropTypes.object,
    callConfig: PropTypes.object,
    data: PropTypes.object,
    filterData: PropTypes.array,
    ListItemComponent: PropTypes.func,
    onItemClick: PropTypes.func,
};

function mapStateToProps(state) {
    return {
        filterData: state.filterData,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        uiActions: bindActionCreators(uiActions, dispatch),
        filterActions: bindActionCreators(filterActions, dispatch),
    };
}

export default ErrorBoundary(
    connect(mapStateToProps, mapDispatchToProps)(FilterList),
);
