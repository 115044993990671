export const END_TYPES = {
    NEVER: 'never',
    DATE: 'date',
    OCCURRENCES: 'occurrences',
};

export const REPEAT_TYPES = {
    DAY: 'day',
    WEEK: 'week',
    MONTH: 'month',
    YEAR: 'year',
};

export const LAUNCH_OPTIONS = {
    NOW: 'now',
    IN_FUTURE: 'inFuture',
    ON_RECCURING_SCHEDULE: 'onReccuringSchedule',
};

export const WEEKDAYS = [
    { index: 1, key: 'M', name: 'Mon' },
    { index: 2, key: 'T', name: 'Tue' },
    { index: 4, key: 'W', name: 'Wed' },
    { index: 8, key: 'T', name: 'Thu' },
    { index: 16, key: 'F', name: 'Fri' },
    { index: 32, key: 'S', name: 'Sun' },
    { index: 64, key: 'S', name: 'Sut' },
];

export const LAUNCH_TYPES = {
    ONCE: 'once',
    EVERY: 'every',
};

export const APPLICABLE_DATE_TYPES = {
    DAY: 'Date',
    MONTH: 'Month',
    QUARTER: 'Quarter',
    YEAR: 'Year',
};

export const MONTHS = [
    { id: 0, name: 'January' },
    { id: 1, name: 'February' },
    { id: 2, name: 'March' },
    { id: 3, name: 'April' },
    { id: 4, name: 'May' },
    { id: 5, name: 'June' },
    { id: 6, name: 'July' },
    { id: 7, name: 'August' },
    { id: 8, name: 'September' },
    { id: 9, name: 'October' },
    { id: 10, name: 'November' },
    { id: 11, name: 'December' },
];

export const QUARTERS = [1, 2, 3, 4];
