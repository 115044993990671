import { map, set } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { Importer, ImporterField } from 'react-csv-importer';
import 'react-csv-importer/dist/index.css';
import { useDispatch } from 'react-redux';
import {
    Button,
    Header,
    List,
    Message,
    Progress,
    Segment,
} from 'semantic-ui-react';
import { loadParty } from '@brallum/vidende/src/actions/partyActions';
import { useAppSelector } from '@brallum/vidende/src/actions/store';
import partyApi, {
    ImportParty,
    ImportPartyData,
    NotificationType,
} from '@brallum/vidende/src/api/partyApi';
import { Party, PartyRelationshipType } from '@brallum/vidende/src/types';

interface PresentationProps {
    partyType: PartyRelationshipType; // Please refine the type
    party: Party; // Please refine the type
    loadingState: 'IDLE' | 'PROCESSING' | 'COMPLETE';
    setLoadingState: (state: 'IDLE' | 'PROCESSING' | 'COMPLETE') => void;
    bulkImportRelationships: (
        partyId: string,
        data: {
            rows: ImportParty[];
            queueId: string | null;
        },
    ) => Promise<{
        queueId: string;
        itemCount: number;
    }>;
    queueId: string | null;
    notificationType?: NotificationType;
    hideReference?: boolean;
}

const BulkImportPartyPresentation = ({
    partyType,
    party,
    loadingState,
    setLoadingState,
    bulkImportRelationships,
    queueId,
    notificationType = 'NOTIFY_USER_FAIR_VALUE',
    hideReference,
}: PresentationProps) => {
    const [showCopyMessage, setShowCopyMessage] = useState(false);
    const fields: Record<string, string> = {
        label: 'Label',
        line1: 'Address line 1',
        line2: 'Address line 2',
        line3: 'Address line 3',
        city: 'City',
        zipOrPostcode: 'Postal Code',
        stateProvinceCounty: 'County',
        countryId: 'Country code',
        firstName: 'Contact first name',
        lastName: 'Contact last name',
        email: 'Contact email',
    };
    if (!hideReference) fields.ref = 'Reference';
    const queueIdRef = useRef<string | null>(null);

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(map(fields).join('\t'));
        setShowCopyMessage(true);
    };

    const handleReadyToUpload = async () => {
        setLoadingState('PROCESSING');
    };

    const partyGroupNode = party?.permissionsNode?.children?.[0];

    return (
        <div>
            <Segment>
                {/* <pre>{JSON.stringify(party, null, "  ")}</pre> */}
                <Header size="large">Bulk import {partyType.label}s</Header>
                <Header>
                    In order to import a list of {partyType.label}s, create a
                    CSV files with the following columns to upload:
                </Header>
                <List bulleted>
                    <List.Item>{partyType.label} Label (required)</List.Item>
                    {!hideReference && (
                        <List.Item>
                            Reference (This will be dervied from the label if
                            not supplied)
                        </List.Item>
                    )}
                    <List.Item>Address line 1</List.Item>
                    <List.Item>Address line 2</List.Item>
                    <List.Item>Address line 3</List.Item>
                    <List.Item>City</List.Item>
                    <List.Item>Postal Code</List.Item>
                    <List.Item>County</List.Item>
                    <List.Item>
                        <a
                            href="https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2"
                            target="blank"
                        >
                            2 letter ISO
                        </a>{' '}
                        Country Code
                    </List.Item>
                    <List.Item>Primary contact first name (required)</List.Item>
                    <List.Item>Primary contact last name (required)</List.Item>
                    <List.Item>Primary contact email (required)</List.Item>
                </List>

                <p>
                    <Button
                        primary
                        size="tiny"
                        onClick={handleCopyToClipboard}
                        icon="copy"
                        content="Copy Headers to Clipboard"
                    />
                </p>

                {showCopyMessage && (
                    <Message
                        info
                        onDismiss={() => setShowCopyMessage(false)}
                        header="Copied to clipboard"
                        content="You can now paste the headers into a spreadsheet."
                    />
                )}
            </Segment>

            {loadingState === 'PROCESSING' && (
                <Segment>
                    <Progress percent={0} active>
                        Queueing items..
                    </Progress>
                </Segment>
            )}

            {/* {loadingState === 'COMPLETE' && (
        <Message info header={`${partyType.label} creation complete`} />
      )} */}

            {loadingState === 'IDLE' && (
                <Importer<{
                    label: string;
                    line1: string;
                    line2: string;
                    line3: string;
                    city: string;
                    zipOrPostcode: string;
                    stateProvinceCounty: string;
                    countryId: string;
                    firstName: string;
                    lastName: string;
                    email: string;
                    ref: string;
                }>
                    onStart={(info) => console.log(info)}
                    onComplete={(result) => {
                        setLoadingState('COMPLETE');
                        if (!queueIdRef.current) return;
                        window.location.pathname = `/queue/${queueIdRef.current}`;
                    }}
                    dataHandler={async (rows, { startIndex }) => {
                        const importPartys: ImportParty[] = rows.map(
                            (row, index) => ({
                                index: index + startIndex + 2,
                                partyData: {
                                    ...row,
                                    type: partyType.id,
                                    structuralNodeId: partyGroupNode?.id,
                                },
                                tasks: [{ type: notificationType }],
                            }),
                        );
                        const result = await bulkImportRelationships(party.id, {
                            rows: importPartys,
                            queueId: queueIdRef.current,
                        });

                        queueIdRef.current = result.queueId;
                    }}
                >
                    {map(fields, (value, key) => (
                        <ImporterField
                            key={key}
                            name={key}
                            label={value}
                            optional
                        />
                    ))}
                </Importer>
            )}
        </div>
    );
};

export default BulkImportPartyPresentation;
