import React from 'react';
import {
    Progress,
    Label,
    Icon,
    Segment,
    Message,
    Header,
    Divider,
} from 'semantic-ui-react';

import './QueueStatusViewer.scss';

type QueueStatus = {
    totalItems: number;
    completeItems: number;
    failedItems: number;
    processingItems: number;
    unprocessedItems: number;
};

type QueueStatusViewerProps = {
    queueStatus: QueueStatus;
};

export const QueueStatusViewer = ({ queueStatus }: QueueStatusViewerProps) => {
    const { totalItems, completeItems, failedItems, unprocessedItems } =
        queueStatus;
    const progress = Math.round(
        ((completeItems + failedItems) / totalItems) * 100,
    );

    const isComplete = completeItems + failedItems === totalItems;
    const hasFailed = isComplete && queueStatus?.failedItems > 0;
    let header = 'Queue Processing';
    if (isComplete && !hasFailed) {
        header = 'Queue Complete';
    } else if (hasFailed) {
        header = 'Queue complete with failures';
    }

    return (
        <div className="queue-status-viewer">
            <Message
                positive={isComplete && !hasFailed}
                negative={hasFailed}
                info={!isComplete}
            >
                <div className="queue-status-viewer-message">
                    <Header as="h2">{header}</Header>
                    <Label.Group>
                        <Label color="grey" size="large">
                            <Icon name="clock" />
                            {unprocessedItems}
                        </Label>
                        <Label color="green" size="large">
                            <Icon name="check" />
                            {completeItems}
                        </Label>
                        <Label color="red" size="large">
                            <Icon name="x" />
                            {failedItems}
                        </Label>
                    </Label.Group>
                </div>
                <Divider hidden />
                <Progress
                    percent={progress}
                    progress
                    active={!isComplete}
                    success={isComplete}
                    size="medium"
                />
            </Message>
        </div>
    );
};
