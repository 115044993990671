import { Dropdown } from 'semantic-ui-react';
import { ActivityFieldType, Field, FieldTypeId } from '../../types';
import './FormBuildType.scss';

interface FieldTypeProps {
    value: Field;
    activityFieldTypes: ActivityFieldType[];
    changeFieldType: (id: FieldTypeId) => void;
}

const FormBuilderFieldType: React.FC<FieldTypeProps> = ({
    value,
    activityFieldTypes,
    changeFieldType,
}) => {
    return (
        <div>
            <Dropdown scrolling trigger={<span>{value.fieldType.name}</span>}>
                <Dropdown.Menu className="form-fields-menu">
                    <Dropdown.Header content="Form Fields" />
                    {activityFieldTypes.map((option) => (
                        <Dropdown.Item
                            key={option.id}
                            text={option.name}
                            value={option.id}
                            onClick={() => changeFieldType(option.id)}
                        />
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};

export default FormBuilderFieldType;
