import React from 'react';
import { Popup, Button } from 'semantic-ui-react';
import PrincipalLabel from './PrincipalLabel';
import PrincipalSearch from './PrincipalSearch';
import { Principal } from '../../types';

interface PrincipalFilterProps {
    value: Principal | null;
    onChange: (value: Principal | null) => void;
    label?: string;
    loading?: boolean;
    includeDefaultGroups?: boolean;
    exceptPrincipal?: Principal[];
}

const PrincipalFilter: React.FC<PrincipalFilterProps> = ({
    value,
    onChange,
    label = 'Filter by assigned',
    loading = false,
    includeDefaultGroups = false,
    exceptPrincipal,
}) => {
    return (
        <Popup
            on="click"
            position="bottom left"
            trigger={
                <div style={{ width: 'unset' }}>
                    {value ? (
                        <>
                            <PrincipalLabel
                                principal={value}
                                onClick={() => {}}
                            />
                            <Button
                                compact
                                size="tiny"
                                icon="close"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onChange(null);
                                }}
                            />
                        </>
                    ) : (
                        <Button size="tiny">{label}</Button>
                    )}
                </div>
            }
        >
            <div style={{ width: '200px' }}>
                <PrincipalSearch
                    onChange={(_, { value }) => {
                        onChange(value);
                    }}
                    currentPrincipal={value}
                    label={label}
                    isAutofocus={true}
                    clearable={true}
                    saving={loading}
                    includeDefaultGroups={includeDefaultGroups}
                    exceptPrincipal={exceptPrincipal}
                />
            </div>
        </Popup>
    );
};

export default PrincipalFilter;
