/*global window*/

import apiTools from './apiTools';
import _ from 'lodash';
import storeProvider from '../store/storeProvider';
import { createFileDownload } from '../utils/fileUtils';
import config from '../constants/fileExportConfig';
import toastr from 'toastr';
import pako from 'pako';

const isDev = process.env.NODE_ENV !== 'production';

class DashboardApi {
    static getDashboards() {
        const filter = {
            order: 'label ASC',
        };
        return apiTools.get('Dashboards', { filter }).then((response) => {
            return response.data;
        });
    }
    static getDashboard(id) {
        const filter = {
            include: [
                {
                    relation: 'rows',
                    scope: {
                        include: [
                            {
                                relation: 'columns',
                                scope: {
                                    include: [
                                        {
                                            relation: 'items',
                                            scope: {
                                                include: [
                                                    {
                                                        chart: {
                                                            apiCalls: 'apiCall',
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                },
            ],
        };
        return apiTools.get(`Dashboards/${id}`, { filter }).then((response) => {
            return response.data;
        });
    }

    static addDashboardRow({ id, sort = 0, options = {} }) {
        return apiTools
            .post(`Dashboards/${id}/rows`, {
                sort,
                options,
            })
            .then((response) => {
                return response.data;
            });
    }

    static deleteDashboardRow({ id, rowId }) {
        return apiTools
            .delete(`Dashboards/${id}/rows/${rowId}`)
            .then((response) => {
                return response.data;
            });
    }

    static addDashboardColumn({ id, dashboardRowId, sort = 0, options = {} }) {
        return apiTools
            .post(`Dashboards/${id}/columns`, {
                dashboardRowId,
                sort,
                options: { ...options, sort },
            })
            .then((response) => {
                return response.data;
            });
    }

    static addDashboardItem({
        id,
        dashboardColumnId,
        chartId,
        sort = 0,
        options = {},
    }) {
        return apiTools
            .post(`Dashboards/${id}/items`, {
                dashboardColumnId,
                chartId,
                sort,
                options,
            })
            .then((response) => {
                return response.data;
            });
    }

    static deleteDashboardItem({ id, itemId }) {
        return apiTools
            .delete(`Dashboards/${id}/items/${itemId}`)
            .then((response) => {
                return response.data;
            });
    }

    static deleteDashboardColumn({ id, dashboardColumnId }) {
        return apiTools
            .delete(`Dashboards/${id}/columns/${dashboardColumnId}`)
            .then((response) => {
                return response.data;
            });
    }

    static saveDashboardItem({ id, itemId, data }) {
        return apiTools
            .put(`Dashboards/${id}/items/${itemId}`, data)
            .then((response) => {
                return response.data;
            });
    }
    static saveDashboardColumn({ id, dashboardColumnId, data }) {
        return apiTools
            .put(`Dashboards/${id}/columns/${dashboardColumnId}`, data)
            .then((response) => {
                return response.data;
            });
    }

    static saveDashboardRow({ id, dashboardRowId, data }) {
        return apiTools
            .put(`Dashboards/${id}/rows/${dashboardRowId}`, data)
            .then((response) => {
                return response.data;
            });
    }

    static saveDashboard({ label, id, options = {} }) {
        const method = id ? apiTools.patch : apiTools.post;
        const url = id ? `Dashboards/${id}` : `Dashboards`;
        const data = {
            options,
        };
        if (label) data.label = label;
        return method(url, data).then((response) => {
            return response.data;
        });
    }

    static openDashboardExport(
        id,
        params,
        format = '',
        dashboardData = {},
        filename,
        dashboardProperties,
    ) {
        const storeState = storeProvider.getStore().store.getState();

        const dashboardDataClone = _.cloneDeep(dashboardData);
        dashboardDataClone.dashboardProperties = dashboardProperties;
        const exportConfig = config[format];

        _.forEach(dashboardDataClone.rows, (row) => {
            _.forEach(row.columns, (column) => {
                _.forEach(column.items, (item) => {
                    const data = storeState.chartData[item.id];
                    if (item.chart) {
                        item.chart.data = data;
                    }
                });
            });
        });

        let compressedData = pako.deflate(JSON.stringify(dashboardDataClone), {
            to: 'string',
        });

        return apiTools
            .makeCall({
                method: 'post',
                url: isDev
                    ? 'http://localhost:3000/renderdashboard'
                    : '/renderdashboard',
                encoding: null,
                responseType: exportConfig.responseType,
                data: { dashboardData: compressedData, format: format },
            })
            .then((result) => {
                const { data } = result;

                switch (format) {
                    case 'print':
                        {
                            let printWindow = window.open();
                            printWindow.document.write(data);
                            printWindow.onload = function () {
                                printWindow.focus();
                                printWindow.print();
                                printWindow.close();
                            };
                            printWindow.document.close();
                        }
                        break;
                    default:
                        createFileDownload(
                            data,
                            `${filename}.${format}`,
                            result.headers['content-type'],
                        );
                        break;
                }
            })
            .catch((error) => {
                toastr.error('There was an issue exporting the report.');
            });
    }
}

export default DashboardApi;
