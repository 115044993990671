import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { Button, Modal, Grid, Menu } from 'semantic-ui-react';

import { pluralize } from '../../../utils/Pluralize';
import { menuTabs } from '../../../constants/addAttachments';
import AddAttachmentModalBody from './AddAttachmentModalBody';
import Attachment from './Attachment';
import { WrapAttachmentsContext } from '../../../context/AttachmentContext';

import './AddAttachmentModal.scss';

class AddAttachmentModal extends Component {
    render() {
        const { selectedAttachments, activeTab, uiActions, attachmentActions } =
            this.props;
        const selectedAttachmentsLength = selectedAttachments
            ? selectedAttachments.length
            : 0;
        return (
            <Modal
                open={true}
                onClose={() => uiActions.closeModal()}
                size="small"
                closeOnDimmerClick={false}
            >
                <Modal.Header>Add Attachment</Modal.Header>
                <Modal.Content>
                    <Grid>
                        <Grid.Column width={4}>
                            <Menu pointing vertical fluid>
                                <Menu.Item
                                    name={menuTabs.RECENT}
                                    active={activeTab === menuTabs.RECENT}
                                    onClick={attachmentActions.handleTabClick}
                                />
                                <Menu.Item
                                    name={menuTabs.SEARCH}
                                    active={activeTab === menuTabs.SEARCH}
                                    onClick={attachmentActions.handleTabClick}
                                />
                                <Menu.Item
                                    name={menuTabs.UPLOAD}
                                    active={activeTab === menuTabs.UPLOAD}
                                    onClick={attachmentActions.handleTabClick}
                                />
                                <Menu.Item
                                    name={menuTabs.LINK}
                                    active={activeTab === menuTabs.LINK}
                                    onClick={attachmentActions.handleTabClick}
                                />
                            </Menu>
                        </Grid.Column>

                        <Grid.Column stretched width={12}>
                            <AddAttachmentModalBody />
                        </Grid.Column>
                    </Grid>
                </Modal.Content>

                <Modal.Actions>
                    {(activeTab === menuTabs.RECENT ||
                        activeTab === menuTabs.SEARCH) && (
                        <Button
                            positive
                            onClick={attachmentActions.handleAddAttachmentClick}
                            disabled={selectedAttachmentsLength === 0}
                        >
                            {`Add ${pluralize(
                                'Attachment',
                                selectedAttachmentsLength,
                                true,
                                selectedAttachmentsLength <= 1,
                            )}`}
                        </Button>
                    )}
                    <Button secondary onClick={() => uiActions.closeModal()}>
                        Close
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

AddAttachmentModal.propTypes = {
    selectedAttachments: PropTypes.array,
    activeTab: PropTypes.string,
    attachmentActions: PropTypes.object,
    uiActions: PropTypes.object,
};

export default Attachment(WrapAttachmentsContext(AddAttachmentModal));
