import React from 'react';
import { Checkbox, Input, Table } from 'semantic-ui-react';
import ActionButtons from '../common/ActionButtons';
import StatusDropdown from '../common/StatusDropdown';
import ActivityResolutionList from '../activity/ActivityResolutionList';
import FormBuilder from '../common/FormBuilder';
import {
    ActivityFieldType,
    Field,
    StatusType,
    WorkflowStatus,
    Resolution,
    FieldTypeId,
} from '../../types';
import { CheckboxProps, InputProps } from 'semantic-ui-react';

interface ActivityResolutionType {
    id: string;
    label: string;
    colourId: string;
}

interface WorkflowStatusListRowProps {
    status: WorkflowStatus;
    onChange: (
        event: React.SyntheticEvent<HTMLElement>,
        data: InputProps | CheckboxProps,
    ) => void;
    onRemove: (event: React.SyntheticEvent<HTMLElement>) => void;
    statusTypes: StatusType[];
    activityResolutionTypes: ActivityResolutionType[];
    activityFieldTypes: ActivityFieldType[];
    toggleResolution: (
        status: WorkflowStatus,
        resolutionType: ActivityResolutionType,
        makeAvailable: boolean,
    ) => void;
    onResolutionChange: (
        status: WorkflowStatus,
        resolutionType: ActivityResolutionType,
        value: Partial<Resolution>,
    ) => void;
    addField: (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        status: WorkflowStatus,
        data: { value: FieldTypeId; label?: string; fieldKey?: string },
    ) => void;
    onFieldChange: (
        fieldIndex: number,
        status: WorkflowStatus,
        value: Partial<Field>,
    ) => void;
}

export const WorkflowStatusListRow = ({
    status,
    onChange,
    onRemove,
    statusTypes,
    activityResolutionTypes,
    activityFieldTypes,
    toggleResolution,
    onResolutionChange,
    addField,
    onFieldChange,
}: WorkflowStatusListRowProps) => {
    return (
        <React.Fragment>
            <Table.Row>
                <Table.Cell>
                    <Input
                        value={status.name || ''}
                        name="name"
                        onChange={onChange}
                    />
                </Table.Cell>
                <Table.Cell>
                    <Input
                        value={status.key || ''}
                        name="key"
                        onChange={onChange}
                        placeholder="Key"
                    />
                </Table.Cell>
                <Table.Cell>
                    <Input
                        value={status.order || ''}
                        name="order"
                        onChange={onChange}
                        placeholder="Order"
                    />
                </Table.Cell>
                <Table.Cell>
                    <StatusDropdown
                        statuses={statusTypes}
                        onChange={onChange}
                        value={status.statusTypeId}
                        name="statusTypeId"
                    />
                </Table.Cell>
                <Table.Cell>
                    <Checkbox
                        label="Add resolution"
                        checked={status.addResolution}
                        name="addResolution"
                        onChange={onChange}
                    />
                </Table.Cell>
                <Table.Cell>
                    <Checkbox
                        label="Allow data entry"
                        checked={status.allowDataEntry}
                        name="allowDataEntry"
                        onChange={onChange}
                    />
                </Table.Cell>

                <Table.Cell>
                    <ActionButtons removeClicked={onRemove} />
                </Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell colSpan="6">
                    <FormBuilder
                        activityFieldTypes={activityFieldTypes}
                        addField={(event, data) =>
                            addField(event, status, data)
                        }
                        fields={status.fields}
                        onFieldChange={(fieldIndex, value) =>
                            onFieldChange(fieldIndex, status, value)
                        }
                        isTableView={false}
                    />
                </Table.Cell>
            </Table.Row>
            {status.addResolution ? (
                <Table.Row>
                    <Table.Cell colSpan="6">
                        <ActivityResolutionList
                            activityResolutionTypes={activityResolutionTypes}
                            resolutions={status.resolutions}
                            toggleResolution={(resolutionType, makeAvailable) =>
                                toggleResolution(
                                    status,
                                    resolutionType,
                                    makeAvailable,
                                )
                            }
                            onChange={(resolutionType, value) =>
                                onResolutionChange(
                                    status,
                                    resolutionType,
                                    value,
                                )
                            }
                            editable={true}
                            allowNotes={false}
                        />
                    </Table.Cell>
                </Table.Row>
            ) : null}
        </React.Fragment>
    );
};

export default WorkflowStatusListRow;
