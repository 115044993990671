import React from 'react';
import * as PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

import SortableHeaderCell from '../common/SortableHeaderCell';
import ScheduledLaunchesListItem from './ScheduleLaunchesListItem';
import ConfirmWrapper from './../common/ConfirmWrapper';
import ExecutionPlanWrapper from './ExecutionPlan';

const ScheduledLaunchesList = (props) => {
    const {
        schedules,
        compact = false,
        handleSortChange,
        paginationOptions,
        handleAddFilter,
        currentFilters,
        removeFilterValues,
    } = props;
    const { deleteScheduledExecutionPlan } = props.executionPlanStateActions;
    const { showConfirmDialog } = props.confirmPopupActions;
    return (
        <Table celled sortable>
            <Table.Header>
                <Table.Row>
                    <SortableHeaderCell
                        {...paginationOptions}
                        name="launchDate"
                        handleSortChange={handleSortChange}
                        handleAddFilter={handleAddFilter}
                        currentFilters={currentFilters}
                        removeFilterValues={removeFilterValues}
                    >
                        Launch Date
                    </SortableHeaderCell>
                    <SortableHeaderCell
                        {...paginationOptions}
                        name="dueDate"
                        handleSortChange={handleSortChange}
                        handleAddFilter={handleAddFilter}
                        currentFilters={currentFilters}
                        removeFilterValues={removeFilterValues}
                    >
                        Due Date
                    </SortableHeaderCell>
                    <SortableHeaderCell
                        {...paginationOptions}
                        name="launchType"
                        handleSortChange={handleSortChange}
                        handleAddFilter={handleAddFilter}
                        currentFilters={currentFilters}
                        removeFilterValues={removeFilterValues}
                    >
                        Launch Type
                    </SortableHeaderCell>
                    <SortableHeaderCell
                        {...paginationOptions}
                        name="nextLaunchDate"
                        handleSortChange={handleSortChange}
                        handleAddFilter={handleAddFilter}
                        currentFilters={currentFilters}
                        removeFilterValues={removeFilterValues}
                    >
                        Next Launch Date
                    </SortableHeaderCell>
                    <SortableHeaderCell
                        {...paginationOptions}
                        name="launchType"
                        handleSortChange={handleSortChange}
                    >
                        Repeat Every
                    </SortableHeaderCell>
                    <SortableHeaderCell
                        {...paginationOptions}
                        name="applicableDate"
                        handleSortChange={handleSortChange}
                    >
                        Applicable Date
                    </SortableHeaderCell>
                    <Table.HeaderCell>Actions</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {schedules.map((schedule, index) => (
                    <ScheduledLaunchesListItem
                        key={index}
                        schedule={schedule}
                        compact={compact}
                        deleteScheduledExecutionPlan={
                            deleteScheduledExecutionPlan
                        }
                        showConfirmDialog={showConfirmDialog}
                    />
                ))}
            </Table.Body>
        </Table>
    );
};

ScheduledLaunchesList.propTypes = {
    schedules: PropTypes.array.isRequired,
    compact: PropTypes.bool,
    handleSortChange: PropTypes.func,
    paginationOptions: PropTypes.object,
    handleAddFilter: PropTypes.func,
    currentFilters: PropTypes.array,
    removeFilterValues: PropTypes.func,
    executionPlanStateActions: PropTypes.object,
    confirmPopupActions: PropTypes.object,
};

export default ExecutionPlanWrapper(ConfirmWrapper(ScheduledLaunchesList));
