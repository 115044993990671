import * as types from '../constants/actionTypes';
import { Status } from '../types';

export type StatusState = {
    statuses: Status[];
};

type Action = {
    type: string;
    statuses?: Status[];
    status: Status;
};

export default function statusReducer(
    state: StatusState = {
        statuses: [],
    },
    action: Action,
): StatusState {
    switch (action.type) {
        case types.UPDATE_STATUS_SUCCESS:
            const index = state.statuses.findIndex(
                (status) => action.status.id === status.id,
            );

            return {
                ...state,
                statuses: state.statuses.map((status, i) => {
                    if (i === index) {
                        return action.status;
                    }
                    return status;
                }),
            };

        case types.LOAD_STATUSES_SUCCESS:
            return {
                ...state,
                statuses: action.statuses,
            };
        default:
            return state;
    }
}
