import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import ExecutionPlanParties from './ExecutionPlanParties';
import PermissionsBuilder from '../common/PermissionsBuilder';
import { Header } from 'semantic-ui-react';
import ticketApi from '../../api/ticketApi';
import * as _ from 'lodash';
import { addRoleToPermissions } from '../../utils/PermissionsUtils';

const ExecutionPlanPartiesStep = (props) => {
    const { options, needsReview, onChange } = props;

    const assignedPartyId = _.get(options, 'assignedParty.id');
    const reviewPartyId = _.get(options, 'reviewParty.id');
    useEffect(() => {
        updatePermissions('assignedParty', options.assignedParty);
    }, [assignedPartyId]);
    useEffect(() => {
        updatePermissions('reviewParty', options.reviewParty);
    }, [reviewPartyId]);

    const updatePermissions = (name, value) => {
        if (!value) return;

        if (name === 'assignedParty' || name === 'reviewParty') {
            const partyLabel = value.label;
            const roleDefinition = name === 'reviewParty' ? 'Review' : 'Assess';
            ticketApi.searchTicketPrincipals(partyLabel, true).then((data) => {
                const defaultGroupPrincipal = _.find(data, [
                    'userGroup.isDefaultGroup',
                    true,
                    'userGroup.party.id',
                    value.id,
                ]);
                const role = {
                    principalId: defaultGroupPrincipal.id,
                    principal: defaultGroupPrincipal,
                    roleDefinitionId: roleDefinition,
                };
                const { permissions } = options;

                onChange(null, {
                    name: 'permissions',
                    value: addRoleToPermissions(permissions, role),
                });
            });
        }
    };

    return (
        <React.Fragment>
            <ExecutionPlanParties
                options={options}
                needsReview={needsReview}
                onChange={onChange}
            />
            <p>
                Note: All users chosen above will be given access to launched
                programmes.
            </p>
            <Header as="h3">Extra permissions</Header>
            <PermissionsBuilder
                permissions={options.permissions}
                onChange={(permissions) => {
                    onChange(null, { name: 'permissions', value: permissions });
                }}
            />
        </React.Fragment>
    );
};

ExecutionPlanPartiesStep.propTypes = {
    options: PropTypes.object,
    needsReview: PropTypes.bool,
    onChange: PropTypes.func,
};

export default ExecutionPlanPartiesStep;
