import React from 'react';
import * as PropTypes from 'prop-types';
import { Table, Header } from 'semantic-ui-react';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';

import PartyLabel from '../common/labels/PartyLabel';

const ExecutionSidebarListItem = ({ execution }) => {
    const { assignedParty, label, dueDate } = execution;
    return (
        <Table.Row>
            <Table.Cell>
                <Header
                    as={Link}
                    to={`/execution/${execution.id}`}
                    size="small"
                >
                    {label}
                </Header>
            </Table.Cell>
            <Table.Cell>
                <PartyLabel party={assignedParty} />
            </Table.Cell>
            <Table.Cell>
                {dueDate && <Moment format="DD/MM/YYYY">{dueDate}</Moment>}
            </Table.Cell>{' '}
        </Table.Row>
    );
};

ExecutionSidebarListItem.propTypes = {
    execution: PropTypes.object.isRequired,
};

export default ExecutionSidebarListItem;
