import * as types from '../constants/actionTypes';
import initialState from './initialUiState';
import update from 'immutability-helper/index';
import _ from 'lodash';

function addRef(object, name) {
    const newObject = Object.assign({}, object, { _key: name });

    _.forOwn(object, function (value, key) {
        if (_.isPlainObject(value)) {
            newObject[key] = addRef(value, `${name}.${key}`);
        }
    });

    return newObject;
}

export default function uiReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_CREATE_ACTIVITY_ACTION:
            return update(state, {
                createActivityAction: { $set: action.action },
            });
        case types.REGISTER_UI_STATE: {
            const { componentName, initialState } = action;
            return update(state, {
                uiStates: {
                    [componentName]: {
                        $set: addRef(initialState, componentName),
                    },
                },
            });
        }
        case types.CHANGE_UI_STATE: {
            const { uiState, key, value } = action;

            const updateObj = _.reduceRight(
                uiState._key.split('.'),
                (accumulator, value) => {
                    return {
                        [value]: accumulator,
                    };
                },
                { [key]: { $set: value } },
            );

            return update(state, {
                uiStates: updateObj,
            });
        }
        case types.SHOW_MODAL: {
            const { modalOptions } = action;
            return Object.assign({}, state, {
                showModal: true,
                modalOptions,
            });
        }

        case types.CLOSE_MODAL: {
            return Object.assign({}, state, {
                showModal: false,
            });
        }

        case types.EXPAND_MENU: {
            const { isMenuExpanded } = action;
            return Object.assign({}, state, {
                isMenuExpanded,
            });
        }

        case types.SHOW_SIDEBAR: {
            const { sidebarOptions } = action;
            return Object.assign({}, state, {
                showSidebar: true,
                sidebarOptions,
            });
        }

        case types.CLOSE_SIDEBAR: {
            return Object.assign({}, state, {
                showSidebar: false,
            });
        }

        case types.SHOW_CONTEXT_MENU: {
            const { contextMenuOptions } = action;
            return Object.assign({}, state, {
                showContextMenu: true,
                contextMenuOptions,
            });
        }

        case types.CLOSE_CONTEXT_MENU: {
            return Object.assign({}, state, {
                showContextMenu: false,
            });
        }

        default:
            return state;
    }
}
