import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import FollowUpContext from '../../context/FollowUpContext';
import { bindActionCreators } from 'redux';
import * as ticketActions from '../../actions/ticketActions';
import { connect } from 'react-redux';

function FollowUpWrapper(ComponentToWrap) {
    class FollowUp extends Component {
        render() {
            const { followUpList, ticketsById, ticketStateActions } =
                this.props;

            const values = { followUpList, ticketsById, ticketStateActions };
            return (
                <FollowUpContext.Provider value={values}>
                    <ComponentToWrap {...values} {...this.props} />
                </FollowUpContext.Provider>
            );
        }
    }

    FollowUp.propTypes = {
        followUpList: PropTypes.array,
        ticketsById: PropTypes.object,
        ticketStateActions: PropTypes.object,
    };

    function mapStateToProps(state) {
        return {
            ticketsById: state.tickets.byId,
            followUpList: state.tickets.followUpList,
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            ticketStateActions: bindActionCreators(ticketActions, dispatch),
        };
    }
    return connect(mapStateToProps, mapDispatchToProps)(FollowUp);
}

export default FollowUpWrapper;
