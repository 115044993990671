import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import Moment from 'react-moment';
import { Table, Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import CheckPermission from '../common/CheckPermission';
import ConfirmWrapper from '../common/ConfirmWrapper';
import ActionButtons from '../common/ActionButtons';

import WorkflowWrapper from '../workflow/Workflow';
import TicketStatus from './TicketStatus';

import * as ticketActions from '../../actions/ticketActions';
import * as permissionTypes from '../../constants/permissionTypes';
import { defaultDateFormat } from '../../constants/config';

const TicketDeadlinesRow = ({
    currentTicketStatusNodeId,
    deadline,
    workflowStatusTypes,
    workflowActions,
    currentStatusOverdue,
    ticketActions,
    structuralNodeId,
    confirmPopupActions,
}) => {
    const [isPending, setIsPending] = useState(false);

    const { id, workflowStatusId, deadlineDate, executionTicketId } = deadline;
    const { showConfirmDialog } = confirmPopupActions;
    const { status } =
        workflowActions.getStatusByWorkflowStatus(workflowStatusId);
    const currentTicketWorkflow = workflowActions.getStatusNode(
        currentTicketStatusNodeId,
    );
    const orderCurrentTicketWorkflow = currentTicketWorkflow.status.order;
    const isComplete = status.order <= orderCurrentTicketWorkflow;
    const color = _.find(workflowStatusTypes, {
        id: status.statusTypeId,
    }).colourId;

    const removeCurrentDeadline = () => {
        setIsPending(true);
        ticketActions.removeDeadline(executionTicketId, id).then(() => {
            setIsPending(false);
        });
    };

    const handleRemoveClick = () => {
        showConfirmDialog(
            `Are you sure you want to delete this deadline?`,
            () => {
                removeCurrentDeadline();
            },
            () => {},
            'Yes',
            'No',
        );
    };

    return (
        <Table.Row>
            <Table.Cell>
                <TicketStatus name={status.name} color={color} />
            </Table.Cell>
            <Table.Cell
                negative={
                    currentTicketWorkflow.statusId === workflowStatusId &&
                    currentStatusOverdue
                }
            >
                <Moment format={defaultDateFormat}>{deadlineDate}</Moment>
            </Table.Cell>
            <Table.Cell>
                <Icon
                    name={isComplete ? 'checkmark' : 'close'}
                    color={isComplete ? 'green' : 'red'}
                />
            </Table.Cell>
            <CheckPermission
                permissionType={permissionTypes.CREATE}
                structuralNodeId={structuralNodeId}
            >
                <Table.Cell>
                    <ActionButtons
                        deleteClicked={handleRemoveClick}
                        loading={isPending}
                    />
                </Table.Cell>
            </CheckPermission>
        </Table.Row>
    );
};

TicketDeadlinesRow.propTypes = {
    currentTicketStatusNodeId: PropTypes.string,
    workflowActions: PropTypes.object,
    workflowStatusTypes: PropTypes.array,
    deadline: PropTypes.object,
    currentStatusOverdue: PropTypes.bool,
    ticketActions: PropTypes.object,
    structuralNodeId: PropTypes.number,
    confirmPopupActions: PropTypes.object,
};

function mapStateToProps(state) {
    return {
        workflowStatusTypes: state.constants.workflowStatusTypes,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        ticketActions: bindActionCreators(ticketActions, dispatch),
    };
}

export default ConfirmWrapper(
    WorkflowWrapper(
        connect(mapStateToProps, mapDispatchToProps)(TicketDeadlinesRow),
    ),
);
