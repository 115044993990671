import _ from 'lodash';
import ArgTypes from '../ArgTypes';
import * as Mustache from 'mustache';

const group = function (args) {
    const { dataSet, groupBy, values, data, outputKey, findInGroup } = args;
    if (!data) return;

    const dataToGroup = data[dataSet];

    const groupByValues = _.chain(dataToGroup)
        .uniqBy(groupBy)
        .map((v) => {
            const items = _.filter(dataToGroup, (item) => {
                return _.get(item, groupBy) === _.get(v, groupBy);
                //[groupBy]: v[groupBy]
            });
            const toInclude = {};
            _.forEach(values, (mapValue, mapKey) => {
                let argVal;
                const val = items[0];
                if (mapValue[0] === '#') {
                    argVal = Mustache.render(mapValue.substr(1), val);
                } else {
                    argVal = _.get(val, mapValue);
                    if (_.isUndefined(argVal)) argVal = mapValue;
                }

                toInclude[mapKey] = argVal;
            });

            if (findInGroup) {
                _.forEach(findInGroup, (find) => {
                    _.forEach(items, (item) => {
                        const keyValue = item[find.key];
                        const value = item[find.value];

                        if (keyValue && value) toInclude[keyValue] = value;
                    });
                });
            }

            return {
                value: v[groupBy],
                items,
                ...toInclude,
            };
        })
        .value();

    return {
        ...args,
        data: { ...data, [outputKey || 'groupedData']: groupByValues },
    };
};

//dataSet, groupBy, values, data, outputKey, findInGroup
group.args = {
    dataSet: ArgTypes.string,
    outputKey: ArgTypes.string,
    groupBy: ArgTypes.string,
    findInGroup: ArgTypes.array.of(ArgTypes.object.of(ArgTypes.string)),
    values: ArgTypes.json,
};

export default group;
