import React from 'react';
import * as PropTypes from 'prop-types';
import { Icon, Label, List } from 'semantic-ui-react';

const OptionsList = ({ value, onRemove, editable }) => {
    return (
        <div>
            {editable ? (
                <Label.Group size="tiny">
                    {Array.isArray(value) &&
                        value?.map((option, index) => (
                            <Label key={index}>
                                {option}
                                <Icon
                                    name="delete"
                                    onClick={() => onRemove(index)}
                                />
                            </Label>
                        ))}
                </Label.Group>
            ) : (
                <List bulleted>
                    {value?.map((option, index) => (
                        <List.Item key={index}>{option}</List.Item>
                    ))}
                </List>
            )}
        </div>
    );
};

OptionsList.propTypes = {
    value: PropTypes.array.isRequired,
    onRemove: PropTypes.func.isRequired,
    editable: PropTypes.bool,
};

OptionsList.defaultProps = {
    value: [],
};

export default OptionsList;
