import apiTools from './apiTools';

export interface ImportPartyData {
    label: string;
    ref: string;
    line1?: string;
    line2?: string;
    line3?: string;
    city?: string;
    zipOrPostcode?: string;
    stateProvinceCounty?: string;
    countryId?: string;
    firstName: string;
    lastName: string;
    email: string;
    type: string;
    structuralNodeId?: number;
}

export type NotificationType =
    | 'NOTIFY_USER_FAIR_VALUE'
    | 'SELECT_PRODUCTS_REMINDER'
    | 'INCOMPLETE_PRODUCT_FORMS'
    | 'NOTIFY_SUBDISTRIBUTOR_USER_FAIR_VALUE';

interface RelationshipCreationTask {
    type: NotificationType;
    ownerPartyId?: number;
}
export interface ImportParty {
    partyData: ImportPartyData;
    tasks?: RelationshipCreationTask[];
}

class PartyApi {
    static importRelationship(partyId, data: ImportParty) {
        return apiTools
            .post(`Parties/${partyId}/importRelationship`, data)
            .then((response) => {
                return response.data;
            });
    }

    static bulkImportRelationships(
        partyId: string,
        data: {
            rows: ImportParty[];
            queueId?: string;
        },
    ): Promise<{
        queueId: string;
        itemCount: number;
    }> {
        return apiTools
            .post(`Parties/${partyId}/bulkImportRelationships`, data)
            .then((response) => {
                return response.data;
            });
    }

    static sendNotification(data: {
        targetPartyId: string;
        ownerPartyId: string;
        notificationType: NotificationType;
    }) {
        return apiTools
            .post(`Notifications/sendNotification`, data)
            .then((response) => {
                return response.data;
            });
    }
    static bulkSendNotification(data: {
        type: string;
        filter: Record<string, any>;
    }): Promise<{
        queueId: string;
        itemCount: number;
    }> {
        return apiTools
            .post(`Notifications/bulkDistributorNotification`, data)
            .then((response) => {
                return response.data;
            });
    }

    static getParties(filter = {}) {
        return apiTools
            .get('Parties', {
                filter: {
                    include: [
                        'creator',
                        {
                            relation: 'users',
                            scope: { include: ['loginDetails'] },
                        },
                        { permissionsNode: 'parents' },
                    ],
                    order: 'label ASC',
                    ...filter,
                },
            })
            .then((response) => {
                return response.data;
            });
    }

    static getPartyRelationships(partyId, filter = {}, to = false) {
        return apiTools
            .get(`Parties/${partyId}/partyRelationships${to ? 'To' : ''}`, {
                filter: Object.assign(
                    {},
                    {
                        include: ['relationshipType', 'toParty', 'budgets'],
                    },
                    filter,
                ),
            })
            .then((response) => {
                return response.data;
            });
    }

    static getParty(partyId, filter) {
        return apiTools
            .get(`Parties/${partyId}`, { filter })
            .then((response) => {
                return response.data;
            });
    }

    static createRelationship(fromPartyId, data) {
        return apiTools
            .post(`Parties/${fromPartyId}/partyRelationships`, data)
            .then((response) => {
                return response.data;
            });
    }

    static updateRelationship(fromPartyId, data, relationshipId) {
        return apiTools
            .put(
                `Parties/${fromPartyId}/partyRelationships/${relationshipId}`,
                data,
            )
            .then((response) => {
                return response.data;
            });
    }

    static createUserGroup(partyId, data) {
        return apiTools
            .post(`/Parties/${partyId}/userGroups`, data)
            .then((response) => {
                return response.data;
            });
    }

    static updateUserGroup(partyId, data, userGroupId) {
        return apiTools
            .put(`/Parties/${partyId}/userGroups/${userGroupId}`, data)
            .then((response) => {
                return response.data;
            });
    }

    static createParty(data) {
        return apiTools.post(`/Parties`, data).then((response) => {
            return response.data;
        });
    }

    static updateParty(partyId, data) {
        return apiTools
            .post(`Parties/${partyId}/replace`, data)
            .then((response) => {
                return response.data;
            });
    }

    static addUserToUserGroup(userGroupId, userId) {
        return apiTools
            .put(`UserGroups/${userGroupId}/users/rel/${userId}`)
            .then((response) => {
                return response.data;
            });
    }

    static removeUserFromUserGroup(userGroupId, userId) {
        return apiTools
            .delete(`UserGroups/${userGroupId}/users/rel/${userId}`)
            .then((response) => {
                return response.data;
            });
    }

    static getAddresses(partyId) {
        return apiTools.get(`Parties/${partyId}/addresses`).then((response) => {
            return response.data;
        });
    }

    static saveAddressToParty(partyId, partyAddress, addressId) {
        const apiMethod = addressId ? apiTools.put : apiTools.post;
        const apiPath = addressId
            ? `/Parties/${partyId}/addresses/${addressId}`
            : `/Parties/${partyId}/addresses/`;

        return apiMethod(apiPath, partyAddress).then((response) => {
            return response.data;
        });
    }

    static deleteRelationshipFromParty(partyId, relationshipId) {
        return apiTools
            .delete(
                `/Parties/${partyId}/partyRelationships/${relationshipId}/deactivate`,
            )
            .then((response) => {
                return response.data;
            });
    }

    static deleteUserGroup(partyId, userGroupId) {
        return apiTools
            .delete(`/Parties/${partyId}/userGroups/${userGroupId}/deactivate`)
            .then((response) => {
                return response.data;
            });
    }

    static deleteAddress(partyId, addressId) {
        return apiTools
            .delete(`/Parties/${partyId}/addresses/${addressId}`)
            .then((response) => {
                return response.data;
            });
    }

    static deleteAllAddresses(partyId) {
        return apiTools
            .delete(`/Parties/${partyId}/addresses`)
            .then((response) => {
                return response.data;
            });
    }

    static saveRelationship(fromPartyId, data, relationshipId = null) {
        const apiMethod = relationshipId ? apiTools.put : apiTools.post;
        const apiPath = relationshipId
            ? `Parties/${fromPartyId}/partyRelationships/${relationshipId}`
            : `Parties/${fromPartyId}/partyRelationships`;

        return apiMethod(apiPath, data).then((response) => {
            return response.data;
        });
    }

    static uploadImage(partyId, file, progressCallback, imageType) {
        const apiPath =
            imageType === 'icon'
                ? `Parties/${partyId}/uploadIcon`
                : `Parties/${partyId}/uploadLogo`;

        return apiTools
            .upload(apiPath, file, progressCallback)
            .then((response) => {
                return response.data;
            });
    }

    static getAvailableRiskThresholds() {
        return apiTools.get(`AvailableRiskThresholds`).then((response) => {
            return response.data;
        });
    }

    static saveThreshold(partyId, threshold, thresholdId) {
        const apiMethod = thresholdId ? apiTools.put : apiTools.post;
        const apiPath = thresholdId
            ? `Parties/${partyId}/riskThresholds/${thresholdId}`
            : `Parties/${partyId}/riskThresholds`;
        return apiMethod(apiPath, threshold).then((response) => {
            return response.data;
        });
    }

    static removeThreshold(thresholdId, partyId) {
        return apiTools
            .delete(`Parties/${partyId}/riskThresholds/${thresholdId}`)
            .then((response) => {
                return response.data;
            });
    }

    static addDashboard(partyId, dashboardId) {
        return apiTools
            .put(`Parties/${partyId}/dashboards/rel/${dashboardId}`)
            .then((response) => {
                return response.data;
            });
    }
    static removeDashboard(partyId, dashboardId) {
        return apiTools
            .delete(`Parties/${partyId}/dashboards/rel/${dashboardId}`)
            .then((response) => {
                return response.data;
            });
    }

    static deactivate(partyId, additionalEvent) {
        return apiTools
            .post(`/Parties/${partyId}/deactivate`, {
                ...(additionalEvent ? { additionalEvent } : null),
            })
            .then((response) => {
                return response.data;
            });
    }
    static reactivate(partyId) {
        return apiTools
            .post(`/Parties/${partyId}/reactivate`)
            .then((response) => {
                return response.data;
            });
    }

    static getDistributorInfos(filter = {}) {
        return apiTools
            .get(`DistributorInformations`, { filter })
            .then((response) => {
                return response.data;
            });
    }
    static getPartyApiKeys(filter = {}) {
        return apiTools
            .get(`PartyAPIKeys/getApiKeys`, { filter })
            .then((response) => {
                return response.data;
            });
    }
    static getPartyFunctionApiKeysOptions(filter = {}) {
        return apiTools
            .get(`PartyAPIKeyDataAccesses`, { filter })
            .then((response) => {
                return response.data;
            });
    }
    static createPartyApiKey(apiKey) {
        return apiTools.post(`PartyAPIKeys`, apiKey).then((response) => {
            return response.data;
        });
    }
    static savePartyApiKey(apiKey) {
        return apiTools.patch(`PartyAPIKeys`, apiKey).then((response) => {
            return response.data;
        });
    }
    static assignDelegate(delegateEmail) {
        return apiTools
            .post(`/Parties/assignDelegate`, {
                delegateEmail,
            })
            .then((response) => {
                return response.data;
            });
    }
    static removeDelegate(partyId) {
        return apiTools
            .post(`/Parties/removeDelegate`, {
                partyId,
            })
            .then((response) => {
                return response.data;
            });
    }
    static getAuthTypes() {
        return apiTools.get(`/Parties/getAuthTypes`).then((response) => {
            return response.data;
        });
    }
    static changeAuthType(partyId: string, authType: string) {
        return apiTools
            .post(`/Parties/changeAuthType`, {
                partyId: partyId,
                authType: authType,
            })
            .then((response) => {
                return response.data;
            });
    }

    static changeMetadata(partyId: string, metadata: string) {
        return apiTools
            .post(`/PartySettingValues/changeMetadata`, { partyId, metadata })
            .then((response) => {
                return response.data;
            });
    }
}

export default PartyApi;
