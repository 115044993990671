import _ from 'lodash';

import * as types from '../constants/actionTypes';
import userApi from '../api/userApi';

const keywords = ['SAVE', 'UPDATE', 'CREATE', 'DELETE'];

function refeshTokenMiddleware({ dispatch }) {
    return (next) => (action) => {
        const { type } = action;
        if (
            _.some(_.map(keywords, (keyword) => _.includes(type, keyword))) &&
            _.includes(type, 'SUCCESS')
        ) {
            userApi.renewToken().then((response) => {
                return dispatch({
                    type: `${types.USER_LOGIN_SUCCESS}_CURRENT`,
                    response,
                });
            });
        }
        return next(action);
    };
}

export default refeshTokenMiddleware;
