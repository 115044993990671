import React from 'react';
import * as PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';

function MapStatusesToOptions(statuses) {
    return statuses.map((status) => {
        return {
            key: status.id,
            text: status.label || status.name,
            value: status.id,
            // Commented out at the moment due to update bug
            // content: (
            //   <Label color={status.colourId}>{status.label || status.name}</Label>
            // )
        };
    });
}

const StatusDropdown = ({
    statuses,
    onChange,
    value,
    name,
    inline = false,
}) => {
    const mappedStatusOptions = MapStatusesToOptions(statuses);
    return (
        <Dropdown
            fluid
            selection={!inline}
            options={mappedStatusOptions}
            placeholder="Select Status"
            onChange={onChange}
            value={value}
            name={name}
            inline={inline}
        />
    );
};

StatusDropdown.propTypes = {
    statuses: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string.isRequired,
    inline: PropTypes.bool,
};

export default StatusDropdown;
