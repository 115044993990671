import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';

class SubmitInput extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            value: '',
        };
    }

    componentDidMount() {
        this.input.inputRef.current.value = this.props.value || '';

        if (this.props.focusOnRender) this.input.focus();
    }

    handleChange = () => {
        this.setState({
            value: this.input.inputRef.current.value,
        });
    };

    handleSubmit(event) {
        const value = this.input.inputRef.current.value;
        const { name } = this.props;
        this.props.onSubmit(event, { value, name });
        this.input.inputRef.current.value = '';
    }

    handleFocus = (event) => {
        event.target.select();
    };
    handleBlur = (event) => {
        if (this.props.submitOnBlur) {
            this.handleSubmit(event);
        }
    };
    handleKeyUp = (event) => {
        if (event.key === 'Enter') this.handleSubmit(event);
    };

    render() {
        const { name } = this.props;
        const { placeholder = 'Enter Value' } = this.props;
        return (
            <Input
                placeholder={placeholder}
                name={name}
                size={this.props.size | 'mini'}
                ref={(input) => {
                    this.input = input;
                }}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
                onKeyUp={this.handleKeyUp}
                onChange={this.handleChange}
                fluid
                action={
                    this.state.value
                        ? {
                              icon: 'plus',
                              size: 'mini',
                              onClick: this.handleSubmit,
                          }
                        : false
                }
            />
        );
    }
}

SubmitInput.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    name: PropTypes.string,
    focusOnRender: PropTypes.bool,
    submitOnBlur: PropTypes.bool,
};

export default SubmitInput;
