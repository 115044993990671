/* eslint-disable react/display-name */

import React from 'react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import { Tab } from 'semantic-ui-react';

import ProgrammeContext from '../../context/ProgrammeContext';
import ActivitySets from '../activitySet/ActivitySets';

const ProgrammeCategoryTabs = (props) => {
    const { layout } = props;

    const sortCategories = (categories) => {
        return _.sortBy(categories, ['sort']);
    };

    return (
        <ProgrammeContext.Consumer>
            {(context) => {
                const { currentProgramme } = context;

                let panes = [];

                const noCategorySets = currentProgramme.activitySets.filter(
                    (as) => _.isNull(as.categoryId),
                );

                if (noCategorySets.length > 0)
                    panes.push({
                        menuItem: 'Uncategorised',
                        render: () => {
                            return (
                                <ActivitySets
                                    activitySets={noCategorySets}
                                    editable={false}
                                    layout={layout}
                                />
                            );
                        },
                    });
                panes = [
                    ...panes,
                    ...sortCategories(currentProgramme.categories).map(
                        (category) => {
                            return {
                                menuItem: category.label,
                                render: () => {
                                    return (
                                        <ActivitySets
                                            activitySets={currentProgramme.activitySets.filter(
                                                (as) =>
                                                    as.categoryId ===
                                                    category.id,
                                            )}
                                            editable={false}
                                            layout={layout}
                                        />
                                    );
                                },
                            };
                        },
                    ),
                ];
                return (
                    <Tab
                        menu={{ pointing: true, className: 'wrapping' }}
                        panes={panes}
                    />
                );
            }}
        </ProgrammeContext.Consumer>
    );
};

ProgrammeCategoryTabs.propTypes = {
    layout: PropTypes.string,
};

export default ProgrammeCategoryTabs;
