import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { Button, Modal } from 'semantic-ui-react';
import toastr from 'toastr';

import Attachment from './Attachment';
import { WrapAttachmentsContext } from '../../../context/AttachmentContext';
import AttachmentDetailsForm from './AttachmentDetailsForm';

import './AddAttachmentModal.scss';

class EditAttachmentDetailsModal extends Component {
    uploadAttachmentSuccess = () => {
        toastr.success('Attachment is updated');
        this.props.uiActions.closeModal();
    };

    render() {
        const {
            uiActions,
            attachment,
            attachmentsConstants,
            attachmentStateActions,
            userDetails,
            ticketId = null,
        } = this.props;
        const { originalFileName, isExternalLink, label } = attachment;
        return (
            <Modal
                open={true}
                onClose={() => uiActions.closeModal()}
                size="small"
                closeOnDimmerClick={false}
            >
                <Modal.Header>
                    Edit Attachment {isExternalLink ? label : originalFileName}
                </Modal.Header>
                <Modal.Content>
                    <AttachmentDetailsForm
                        attachment={attachment}
                        attachmentsConstants={attachmentsConstants}
                        attachmentStateActions={attachmentStateActions}
                        uploadAttachmentSuccess={this.uploadAttachmentSuccess}
                        ticketId={ticketId}
                        userDetails={userDetails}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button secondary onClick={() => uiActions.closeModal()}>
                        Close
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

EditAttachmentDetailsModal.propTypes = {
    attachment: PropTypes.object,
    uiActions: PropTypes.object,
    attachmentsConstants: PropTypes.object,
    attachmentStateActions: PropTypes.object,
    ticketId: PropTypes.number,
    userDetails: PropTypes.object,
};

export default Attachment(WrapAttachmentsContext(EditAttachmentDetailsModal));
