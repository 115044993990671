import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as _ from 'lodash';

import TicketResolutionIcons from './TicketResolutionIcons';

const TicketResolutionIconsContainer = (props) => {
    const {
        statusResolutions,
        size = 'large',
        className,
        activityResolutionTypes,
    } = props;

    if (!activityResolutionTypes) return null;

    let error = false;

    const resolutions = _.map(statusResolutions, (statusResolution) => {
        const { id } = statusResolution;
        const resolutionType = _.find(activityResolutionTypes, {
            id: statusResolution.resolution.activityResolutionTypeId,
        });
        if (!resolutionType) {
            error = true;
            return;
        }
        const { icon, colourId } = resolutionType;

        return {
            id,
            current: true,
            resolution: {
                resolutionType: {
                    icon,
                    colourId,
                },
            },
        };
    });

    if (error) return null;

    return (
        <TicketResolutionIcons
            statusResolutions={resolutions}
            size={size}
            className={className}
        />
    );
};

TicketResolutionIconsContainer.propTypes = {
    statusResolutions: PropTypes.array,
    size: PropTypes.string,
    className: PropTypes.string,
    activityResolutionTypes: PropTypes.array,
};

function mapStateToProps(state) {
    return {
        activityResolutionTypes: state.constants.activityResolutionTypes,
    };
}

export default connect(mapStateToProps)(TicketResolutionIconsContainer);
