import React from 'react';
import { find } from 'lodash';
import { WorkflowWrapContext } from '../../context/WorkflowContext';
import { Button, Table } from 'semantic-ui-react';
import WorkflowStatusNodeListRow from './WorkflowStatusNodeListRow';
import { Workflow, StatusNode } from '../../types';

interface WorkflowStatusNodeListProps {
    workflow: Workflow;
    workflowStateActions: {
        updateStatusNode: (
            workflow: Workflow,
            statusNode: StatusNode,
            name: string,
            value: any,
        ) => void;
        addStatusNode: (workflow: Workflow) => void;
        removeStatusNode: (workflow: Workflow, statusNode: StatusNode) => void;
        updateTransition: (
            workflow: Workflow,
            statusNode: StatusNode,
            transition: any,
            name: string,
            value: any,
        ) => void;
        addTransition: (workflow: Workflow, statusNode: StatusNode) => void;
        removeTransition: (
            workflow: Workflow,
            statusNode: StatusNode,
            transition: any,
        ) => void;
    };
}

const WorkflowStatusNodeList = ({
    workflow,
    workflowStateActions,
}: WorkflowStatusNodeListProps) => {
    const updateStatusNode = (
        event: React.SyntheticEvent,
        { name, value }: { name: string; value: any },
        statusNode: StatusNode,
    ) => {
        if (name === 'isStartNode') {
            const currentStartNode = find(workflow.statusNodes, {
                isStartNode: true,
            });
            if (currentStartNode) {
                workflowStateActions.updateStatusNode(
                    workflow,
                    currentStartNode,
                    name,
                    false,
                );
            }
        }
        workflowStateActions.updateStatusNode(
            workflow,
            statusNode,
            name,
            value,
        );
    };

    const addStatusNode = () => {
        workflowStateActions.addStatusNode(workflow);
    };

    const removeStatusNode = (
        event: React.SyntheticEvent,
        statusNode: StatusNode,
    ) => {
        workflowStateActions.removeStatusNode(workflow, statusNode);
    };

    const updateTransition = (
        event: React.SyntheticEvent,
        { name, value }: { name: string; value: any },
        statusNode: StatusNode,
        transition: any,
    ) => {
        workflowStateActions.updateTransition(
            workflow,
            statusNode,
            transition,
            name,
            value,
        );
    };

    const addTransition = (
        event: React.SyntheticEvent,
        statusNode: StatusNode,
    ) => {
        workflowStateActions.addTransition(workflow, statusNode);
    };

    const removeTransition = (
        event: React.SyntheticEvent,
        statusNode: StatusNode,
        transition: any,
    ) => {
        workflowStateActions.removeTransition(workflow, statusNode, transition);
    };

    const { statusNodes, statuses, startStatusNodeUid } = workflow;
    const transitionActions = {
        updateTransition,
        addTransition,
        removeTransition,
    };

    return (
        <React.Fragment>
            <Table collapsing>
                <Table.Body>
                    {statusNodes.map((statusNode, index) => (
                        <WorkflowStatusNodeListRow
                            key={index}
                            statusNode={statusNode}
                            statusNodes={statusNodes}
                            statuses={statuses}
                            onChange={(event, data) =>
                                updateStatusNode(event, data, statusNode)
                            }
                            onRemove={(event) =>
                                removeStatusNode(event, statusNode)
                            }
                            transitionActions={transitionActions}
                            isStartStatusNode={
                                startStatusNodeUid === statusNode.uid
                            }
                        />
                    ))}
                    <Table.Row>
                        <Table.Cell colSpan={4}>
                            <Button onClick={addStatusNode}>
                                Add Status Node
                            </Button>
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
        </React.Fragment>
    );
};

export default WorkflowWrapContext(WorkflowStatusNodeList);
