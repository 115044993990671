import React from 'react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import { Header } from 'semantic-ui-react';
import ReactMarkdown from 'react-markdown';
import WidgetComponents from '../../DashboardWidgetComponents';
import * as Mustache from 'mustache';

const ReportItem = (props) => {
    const { reference, heading, content } = props;

    const renderContent = () => {
        if (_.isArray(content)) {
            return (
                <React.Fragment>
                    {content.map((item, index) => {
                        const Component = WidgetComponents[item.component];

                        if (!Component)
                            return <div>{item.component} not found</div>;

                        const mappedArgs = {};

                        _.forEach(item.args, (val, key) => {
                            let argVal;
                            if (val[0] === '#') {
                                argVal = Mustache.render(val.substr(1), props);
                            } else {
                                argVal = _.get(props, val);
                            }
                            if (_.isUndefined(argVal)) argVal = val;
                            mappedArgs[key] = argVal;
                        });

                        return <Component {...mappedArgs} key={index} />;
                    })}
                </React.Fragment>
            );
        } else {
            return <ReactMarkdown source={content} />;
        }
    };
    return (
        <React.Fragment>
            {heading && (
                <Header as="h4" subheader={reference} content={heading} />
            )}
            {renderContent()}
        </React.Fragment>
    );
};

ReportItem.fields = [
    {
        id: 'component',
        required: false,
        label: 'component',
        activityFieldTypeId: 'Text',
    },
    {
        id: 'args',
        required: false,
        label: 'Args',
        activityFieldTypeId: 'JSONBuilder',
        args: {
            allowModeChange: true,
        },
    },
];

ReportItem.propTypes = {
    reference: PropTypes.string,
    heading: PropTypes.string,
    content: PropTypes.string,
};

export default ReportItem;
