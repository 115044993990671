import { nest } from 'd3-collection';
import _ from 'lodash';

import { colors } from '../../../constants/config';
import ArgTypes from '../ArgTypes';

const Bar = function (args) {
    const {
        xAxis,
        yAxis,
        yColumn,
        stackBy,
        data,
        addTrendLine,
        outputKey = 'barData',
    } = args;
    const sortedData = _.orderBy(
        data[xAxis.dataSet],
        ...(xAxis.sortBy || [xAxis.dataRow, yAxis.dataRow]),
    );

    let seriesNames = _.chain(sortedData)
        .uniqBy(yAxis.dataRow)
        .orderBy(yAxis.sortBy || null, yAxis.sortDirection || null)
        .map((v) => {
            const item = {
                key: v[yAxis.dataRow],
                value: {},
            };

            if (yAxis.dataColour) {
                item.value.color = colors[v[yAxis.dataColour]];
            }
            if (stackBy) {
                item.stack = v[stackBy];
            }
            return item;
        })
        .value();

    if (stackBy) {
        const stackByValues = _.chain(sortedData)
            .uniqBy(stackBy)
            .map((v) => v[stackBy])
            .value();

        const stackSeriesNames = stackByValues.map((stackByValue) => {
            return seriesNames.map((seriesName) => {
                return { ...seriesName, stack: stackByValue };
            });
        });

        seriesNames = _.flatten(stackSeriesNames);
    }

    const newNest = nest();
    if (stackBy) {
        newNest.key((d) => d[stackBy]);
    }
    newNest.key((d) => d[xAxis.dataRow]);
    newNest.key((d) => d[yAxis.dataRow]);
    newNest.rollup((v) => {
        const dataValue = {
            [xAxis.dataRow]: v[0][xAxis.dataRow],
            [yAxis.dataRow]: v[0][yAxis.dataRow],
        };
        if (xAxis.dataId) dataValue[xAxis.dataId] = v[0][xAxis.dataId];
        if (yAxis.dataId) dataValue[yAxis.dataId] = v[0][yAxis.dataId];

        const item = {
            y: _.sumBy(v, yColumn.dataRow),
            dataValue,
            dataSet: xAxis.dataSet,
        };

        if (xAxis.dataValue) {
            item.x = _.sumBy(v, xAxis.dataValue);
        }

        if (yAxis.dataColour) {
            item.color =
                colors[v[0][yAxis.dataColour]] || v[0][yAxis.dataColour];
        }
        if (stackBy) {
            item.stack = v[0][stackBy];
        }

        return item;
    });

    let values;

    if (stackBy) {
        const stackByValues = _.chain(sortedData)
            .uniqBy(stackBy)
            .map((v) => v[stackBy])
            .value();
        const stackedValues = stackByValues.map((stackByValue) => {
            return sortedData.filter(
                (dataRow) => dataRow[stackBy] === stackByValue,
            );
        });

        values = newNest.entries(_.flatten(stackedValues));
        values = _.flatten(values.map((v) => v.values));
    } else {
        values = newNest.entries(sortedData);
    }

    let series = seriesNames.map((seriesItem) => {
        let valuesForData;

        if (stackBy) {
            valuesForData = values.filter(
                (v) => v.values[0].value.stack === seriesItem.stack,
            );
        } else {
            valuesForData = values;
        }
        const item = {
            name: seriesItem.key,
            data: valuesForData.map((v) => {
                const val = _.find(v.values, (value) => {
                    if (stackBy)
                        return (
                            value.key === seriesItem.key &&
                            value.value.stack === seriesItem.stack
                        );
                    return value.key === seriesItem.key;
                });
                const y = val ? val.value : { y: 0 };

                y.args = args;

                return y;
            }),
            cursor: 'pointer',
        };

        if (seriesItem.value.color) item.color = seriesItem.value.color;
        if (seriesItem.stack) item.stack = seriesItem.stack;

        return item;
    });

    if (addTrendLine) {
        const trendSeries = _.find(series, { name: addTrendLine.series });

        const trendSeriesData = {
            type: 'spline',
            name: 'Trend',
            data: _.map(trendSeries.data, 'y'),
            color: 'black',
            style: {
                color: 'black',
            },
            marker: {
                lineWidth: 2,
                lineColor: 'black',
                fillColor: 'white',
            },
        };

        series.push(trendSeriesData);
    }

    const xCategories = nest()
        .key((d) => d[xAxis.dataRow])
        .rollup((r) => {
            return r[xAxis.dataRow];
        })
        .entries(sortedData)
        .map((v) => v.key);

    return {
        ...args,
        data: { ...data, [outputKey]: { series, categories: xCategories } },
        // categories: xCategories,
        // series
    };
};

Bar.args = {
    //xAxis, yAxis, yColumn, stackBy, data, addTrendLine, outputKey
    dataSet: ArgTypes.string,
    outputKey: ArgTypes.string,
    stackBy: ArgTypes.string,
    addTrendLine: ArgTypes.json,

    xAxis: ArgTypes.json,
    yAxis: ArgTypes.json,
    yColumn: ArgTypes.json,
};

export default Bar;
