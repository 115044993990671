import update from 'immutability-helper/index';
import { checkifEdited } from '../../utils/React';
import _ from 'lodash';

export function updateTicketResolution(
    ticket,
    newResolution,
    resolutionProperty
) {
    const resolution = {
        ...newResolution,
        resolution: resolutionProperty,
    };
    const currentResolutionIndex = _.findIndex(ticket.statusResolutions, {
        statusId: newResolution.statusId,
    });
    let newTicket = update(ticket, {
        saving: { $set: false },
        statusResolutions:
            currentResolutionIndex !== -1
                ? { [currentResolutionIndex]: { $set: resolution } }
                : { $push: [resolution] },
        currentResolutionType: {
            $set: resolutionProperty.activityResolutionTypeId,
        },
    });
    return newTicket;
}

export function updateRemoveTicketResolution(ticket, statusId) {
    const currentResolutionIndex = _.findIndex(ticket.statusResolutions, {
        statusId,
    });
    let newTicket = update(ticket, {
        saving: { $set: false },
        currentResolutionType: { $set: null },
        statusResolutions: { $splice: [[currentResolutionIndex, 1]] },
    });
    return newTicket;
}

export function updateFieldEntry(
    ticket,
    activityField,
    value,
    edited = true,
    outlier = false,
    excludeFromStatistics = false
) {
    let newTicketValue = {};
    const fieldsArray = activityField.workflowStatusId
        ? 'workflowEntries'
        : 'entries';
    const fieldKey = activityField.workflowStatusId
        ? 'workFlowStatusFieldId'
        : 'activityFieldId';

    const index = _.findIndex(ticket[fieldsArray], {
        [fieldKey]: activityField.id,
    });

    let codes = undefined;

    // Adding codes to entries for use in display logic
    if (activityField.optionsValue) {
        const selectedIds = String(value).split(',').map(Number);

        codes = selectedIds.map(
            (id) => activityField.options.find((opt) => opt.id === id)?.code
        );
    }

    if (index !== -1) {
        newTicketValue = update(ticket, {
            [fieldsArray]: {
                [index]: {
                    value: { $set: value },
                    edited: { $set: edited },
                    field: { $set: activityField },
                    outlier: { $set: outlier },
                    excludeFromStatistics: { $set: excludeFromStatistics },
                    codes: { $set: codes },
                },
            },
        });
    } else {
        newTicketValue = update(ticket, {
            [fieldsArray]: {
                $push: [
                    {
                        value: value,
                        edited: edited,
                        [fieldKey]: activityField.id,
                        fieldKey: activityField.fieldKey,
                        field: activityField,
                        outlier: outlier,
                        excludeFromStatistics: excludeFromStatistics,
                        codes,
                    },
                ],
            },
        });
    }

    return checkifEdited(newTicketValue, ticket, 'edited', fieldsArray);
}
