import React from 'react';
import * as PropTypes from 'prop-types';
import ReactHighcharts from 'react-highcharts';

import * as chartConfigs from './chartConfigBase';

const SpiderChartComponent = ({ data }) => {
    const createChartConfig = () => {
        return Object.assign(
            {},
            chartConfigs.spider,
            {
                xAxis: {
                    categories: data.categories,
                    tickmarkPlacement: 'on',
                    lineWidth: 0,
                },
                series: data.series,
            },
            { exporting: { enabled: false } },
        );
    };

    return <ReactHighcharts config={createChartConfig()} />;
};

SpiderChartComponent.propTypes = {
    data: PropTypes.object,
};

export default SpiderChartComponent;
