import React, { useEffect, useState } from 'react';
import {
    Button,
    Container,
    Dimmer,
    Header,
    Loader,
    Segment,
} from 'semantic-ui-react';
import ApiKeysList from './ApiKeysList';
import PartyApi from '../../../api/partyApi';
import * as uiActions from '../../../actions/uiActions';
import { useDispatch } from 'react-redux';
import { MODAL_COMPONENTS } from '../../common/ModalRoot';
import toastr from 'toastr';

export type ApiKey = {
    active: boolean;
    deleted: boolean;
    id: string;
    createdAt: string;
    creatorId: string;
    key: string;
    expiryDate: string;
    label: string;
    lastUsed: string | null;
    modifiedAt: string;
    modifiedById: string;
    partyId: string;
    structuralNodeId: number;
    userId: string;
};

const ApiKeysPage = () => {
    const [apiKeys, setApiKeys] = useState<ApiKey[]>([]);
    const [functionApiKeysOptions, setFunctionApiKeysOptions] = useState<
        { api: string; code: string; item: string }[]
    >([]);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        const fetchApiKeys = async () => {
            try {
                const res = await PartyApi.getPartyApiKeys();
                setApiKeys(res);
            } catch (error) {
                toastr.error(error);
            }
            setIsLoading(false);
        };
        const fetchFuncitonApiKeysOptions = async () => {
            try {
                const res = await PartyApi.getPartyFunctionApiKeysOptions();
                setFunctionApiKeysOptions(res);
            } catch (error) {
                toastr.error(error);
            }
            setIsLoading(false);
        };
        fetchApiKeys();
        fetchFuncitonApiKeysOptions();
    }, []);

    const handleAddApiKey = () => {
        dispatch(
            uiActions.showModal({
                modalType: MODAL_COMPONENTS.SAVE_API_KEY_MODAL,
                modalProps: {
                    onSumbit: handleCreateApiKey,
                    functionApiKeysOptions,
                },
            }),
        );
    };

    const handleSaveApiKey = async (apiKey) => {
        try {
            const res = await PartyApi.savePartyApiKey(apiKey);
            const apiKeyIndex = apiKeys.findIndex(
                (x) => x.id?.toLowerCase() === res.id?.toLowerCase(),
            );
            const tempApiKeyArr = [...apiKeys];
            tempApiKeyArr[apiKeyIndex] = res;
            setApiKeys(tempApiKeyArr);
            dispatch(uiActions.closeModal());
        } catch (error) {
            console.log('error', error);
            toastr.error(error);
        }
    };

    const handleCreateApiKey = async (apiKey) => {
        try {
            const res = await PartyApi.createPartyApiKey(apiKey);
            setApiKeys((prevState) => [...prevState, res]);
            dispatch(uiActions.closeModal());
        } catch (error) {
            console.log('error', error);
            toastr.error(error);
        }
    };

    return (
        <Container>
            <Dimmer active={isLoading} inverted>
                <Loader />
            </Dimmer>
            <React.Fragment>
                <Header as="h2">Api Keys</Header>
                <ApiKeysList
                    apiKeys={apiKeys}
                    functionApiKeysOptions={functionApiKeysOptions}
                    onSaveApiKey={handleSaveApiKey}
                />
            </React.Fragment>
            <Segment>
                <Button primary onClick={handleAddApiKey}>
                    Add api key
                </Button>
            </Segment>
        </Container>
    );
};

export default ApiKeysPage;
