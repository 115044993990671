import { chain, debounce } from 'lodash';
import slugify from 'slugify';
import {
    Accordion,
    Button,
    Checkbox,
    Divider,
    Dropdown,
    Form,
    Grid,
    Header,
    Icon,
    Item,
    Label,
    Message,
    Segment,
    Select,
} from 'semantic-ui-react';
import { Field, Status } from '../../types';

import activityApi from '../../api/activityApi';
import workflowApi from '../../api/workflowApi';
import FormField from '../common/FormField';
import EditableHeader from '../common/EditableHeader';
import JSONBuilder from '../common/fields/JSONBuilder';
import TextArea from '../common/fields/TextArea';
import { useEffect, useState } from 'react';
// import { checkStatus, CheckStatusReturn } from "./status";
import { CodeEditor } from '../codeEditor/CodeEditor';
import statusApi from '../../api/statusApi';
import { stringToFieldType } from '../../utils/data/fieldUtils';
import ActionButtons from '../common/ActionButtons';

import './StatusEditor.scss';
import { FieldSelector } from '../activity/FieldSelector';
import StatusScopeEditor from './StatusScopeEditor';
import { StatusTestResult } from './StatusTestResult';
import { ModelSearch } from '../common/search/ModelSearch';
import * as uiActions from '../../actions/uiActions';
import { MODAL_COMPONENTS } from '../common/ModalRoot';
import { useDispatch } from 'react-redux';

const colors = [
    'red',
    'orange',
    'yellow',
    'olive',
    'green',
    'teal',
    'blue',
    'violet',
    'purple',
    'pink',
    'brown',
    'grey',
    'black',
];

export const creationStatusOptions = [
    { text: 'Available', value: 'AVAILABLE' },
    { text: 'Editing', value: 'EDITING' },
    { text: 'Ready for Coding', value: 'CODING' },
    { text: 'Final Approval', value: 'FINAL_APPROVAL' },
    { text: 'Feedback', value: 'FEEDBACK' },
];

interface Props {
    status?: Status;
    onSave: (status: Status) => any;
}

const emptyStatus: Status = {
    checks: {
        logic: `/*
Field Descriptions:


red:
green:

*/
     
// Field Variables:
 `,
    },
    label: '',
    description: '',
    fields: [],
    sort: 0,
    creationStatus: 'EDITING',
};

export const StatusEditor = ({ status, onSave }: Props) => {
    const dispatch = useDispatch();

    const [fields, setFields] = useState<Field[]>([]);
    const [saving, setSaving] = useState(false);
    const [cursorPosition, setCursorPosition] = useState<number>(0);
    const [loadingFields, setLoadingFields] = useState(false);
    const [availablefields, setAvailableFields] = useState<Field[]>([]);
    const [thresholdField, setThresholdField] = useState<Field>();
    const [searchOptions, setSearchOptions] = useState([]);
    const [testData, setTestData] = useState<{
        [key: string]: {
            value: string;
            activityFieldTypeId: string;
            isThreshold?: boolean;
            field?: Field;
        };
    }>({});
    const [currentStatus, setCurrentStatus] = useState(
        status ? status : emptyStatus,
    );
    const [validationResult, setValidationResult] = useState<any>();
    const [validationResultError, setValidationResultError] = useState<{
        message: string;
        stack: string;
    }>();

    const [openAccordions, setOpenAccordions] = useState<string[]>([
        'description',
    ]);

    // const [showFields, setShowFields] = useState(false);
    // const [showAddFields, setAddShowFields] = useState(false);
    // const [showTestResult, setShowTestResult] = useState(false);

    const handleTestStatus = async () => {
        if (!currentStatus?.id) return;
        try {
            const values = Object.keys(testData).reduce((accum, current) => {
                const v = testData[current];

                if (v.value === '') return accum;
                return [
                    ...accum,
                    {
                        fieldKey: current,
                        value: v.value,
                        activityFieldTypeId: v.activityFieldTypeId,
                        options: v.field?.options,
                    },
                ];
            }, []);

            setValidationResultError(null);

            const testResult = await statusApi.testStatus(currentStatus, {
                values,
            });
            if (testResult.error) return setValidationResultError(testResult);
            if (testResult.result.error)
                return setValidationResultError(testResult.result.error);
            setValidationResult(testResult.result);
        } catch (e) {
            console.log(e);
        }
    };

    //fetch threshold field

    useEffect(() => {
        if (!currentStatus.thresholdFieldRef) return;

        const getThresholdField = async () => {
            const fields = (await activityApi.getActivityFields({
                where: {
                    fieldKey: currentStatus.thresholdFieldRef,
                },
                include: ['activity', 'fieldType'],
                order: 'createdAt DESC',
            })) as Field[];

            if (fields && fields.length > 0) {
                const field = fields[0];
                setThresholdField(field);

                setTestData({
                    ...testData,
                    [field.fieldKey]: {
                        activityFieldTypeId: field.activityFieldTypeId,
                        value: field.defaultValue,
                    },
                });
            }
        };

        getThresholdField();
    }, [currentStatus.thresholdFieldRef]);

    useEffect(() => {
        if (!currentStatus.fields || currentStatus.fields.length === 0) return;

        const getFields = async () => {
            const fields = await activityApi.getActivityFields({
                where: {
                    fieldKey: {
                        inq: currentStatus.fields,
                    },
                },
                include: ['activity', 'fieldType'],
                order: 'createdAt DESC',
            });

            const workflowFields = await workflowApi.getWorkflowFields({
                where: {
                    fieldKey: {
                        inq: currentStatus.fields,
                    },
                },
                include: ['fieldType'],
                order: 'createdAt DESC',
            });

            setFields([...fields, ...workflowFields]);
        };

        getFields();

        // activityApi
        //   .getActivityFields({
        //     where: {
        //       fieldKey: {
        //         inq: currentStatus.fields,
        //       },
        //     },
        //     include: ['activity', 'fieldType'],
        //     order: 'createdAt DESC',
        //   })
        //   .then((fields) => {
        //     setFields(fields);
        //   });
    }, [currentStatus.fields]);

    //neq

    useEffect(() => {
        //   try {
        //     const result = checkStatus<string>({
        //       checks: currentStatus.checks,
        //       data: { values: testData },
        //     });
        //     console.log(result);
        //     setValidationResult(result);
        //     setValidationResultError("");
        //   } catch (error) {
        //     setValidationResultError(error.message);
        //   }
        handleTestStatus();
    }, [testData]);

    const handleEditActivity = (activityId: string) => {
        dispatch(
            uiActions.showModal({
                modalType: MODAL_COMPONENTS.CREATE_ACTIVITY_MODAL,
                modalProps: {
                    activityId,
                },
            }),
        );
    };

    const handleSave = async () => {
        setSaving(true);
        try {
            await onSave(currentStatus);
            setSaving(false);
        } catch (e) {
            setSaving(false);
        }
    };

    const handleSaveNew = async () => {
        setSaving(true);
        const {
            checks,
            fields,
            label,
            sort,
            structuralNodeId,
            meta,
            description,
            ref,
            creationStatus,
        } = currentStatus;
        const newStatus = await onSave({
            checks,
            fields,
            label,
            sort,
            structuralNodeId,
            meta,
            description,
            ref,
            creationStatus,
        });
        setCurrentStatus(newStatus);
        setSaving(false);
    };

    const performSearch = debounce(async (searchQuery: string) => {
        if (searchQuery.length < 4) return;

        //User.find({where: {name: {like: '%St%'}}}, function (err, posts) { ... });

        setLoadingFields(true);
        const fields = await activityApi.getActivityFields({
            where: {
                fieldKey: {
                    like: `%${searchQuery}%`,
                },
            },
            limit: 100,
        });
        const workflowFields = await workflowApi.getWorkflowFields({
            where: {
                fieldKey: {
                    like: `%${searchQuery}%`,
                },
            },
            limit: 100,
        });

        // .then((fields: Field[]) => {
        const availableFields = [...fields, ...workflowFields];
        setAvailableFields(availableFields);
        const options = chain(availableFields)
            .uniqBy('fieldKey')
            .orderBy(['fieldKey'])
            .map((field) => ({
                key: field.id,
                text: `${field.fieldKey}  "${
                    field.label.length > 40
                        ? field.label.substring(0, 40) + '...'
                        : field.label
                }"- ${field.activityFieldTypeId}`,
                value: field.fieldKey,
            }))
            .value();

        setSearchOptions(options);
        setLoadingFields(false);
        // });
    }, 500);

    const handleToggleAccordion = (name: string) => {
        if (openAccordions.indexOf(name) !== -1) {
            setOpenAccordions(openAccordions.filter((o) => o !== name));
        } else {
            setOpenAccordions([...openAccordions, name]);
        }
    };

    const handleSearchChange = (e, { searchQuery }) => {
        performSearch(searchQuery);
    };

    const handleFieldSelection = (e, { value }) => {
        if (currentStatus.fields.indexOf(value) !== -1) return;

        const newCode = addFieldToCode(value);

        setCurrentStatus({
            ...currentStatus,
            fields: [...currentStatus.fields, value],
            checks: {
                logic: newCode,
            },
        });
    };

    const handleFieldSelected = (field: Field) => {
        if (currentStatus.fields.indexOf(field.fieldKey) !== -1) return;

        const newCode = addFieldToCode(field.fieldKey, field);

        setCurrentStatus({
            ...currentStatus,
            fields: [...currentStatus.fields, field.fieldKey],
            checks: {
                logic: newCode,
            },
        });
    };

    const handleRemoveField = (fieldKey) => {
        setCurrentStatus({
            ...currentStatus,
            fields: currentStatus.fields?.filter((value) => value !== fieldKey),
            targetFields: currentStatus.targetFields?.filter(
                (value) => value !== fieldKey,
            ),
        });
    };

    const handleToggleTargetField = (fieldKey) => {
        if (currentStatus.targetFields?.includes(fieldKey)) {
            setCurrentStatus({
                ...currentStatus,
                targetFields: currentStatus.targetFields.filter(
                    (value) => value !== fieldKey,
                ),
            });
            return;
        }

        setCurrentStatus({
            ...currentStatus,
            targetFields: currentStatus.targetFields
                ? [...currentStatus.targetFields, fieldKey]
                : [fieldKey],
        });
    };

    const handleCursor = (e) => {
        setCursorPosition(e.currentTarget.selectionStart);
    };

    const addFieldToCode = (fieldKey: string, _field?: Field) => {
        const field =
            _field ||
            availablefields.find((field) => field.fieldKey === fieldKey);
        const code: string = currentStatus.checks.logic;
        const desPosition = code.indexOf('ons:') + 5;
        const variablePosition = code.indexOf('les:') + 5;

        const variableName = slugify(fieldKey, { replacement: '_' });

        let insertStrVar = `const ${variableName} = data.values['${fieldKey}'];\n`;
        if (
            ['Currency', 'Number', 'Integer'].includes(
                _field.activityFieldTypeId,
            )
        ) {
            insertStrVar = `if (typeof ${variableName} !== "number") return null; \n`;
        }
        let newCode = `${code?.slice(
            0,
            variablePosition,
        )}${insertStrVar}${code.slice(variablePosition)}`;

        const insertStrComment = `${variableName} (${field.activityFieldTypeId}) - "${field.label}" \n`;
        newCode = `${newCode?.slice(
            0,
            desPosition,
        )}${insertStrComment}${newCode.slice(desPosition)}`;

        return newCode;
    };

    return (
        <Grid className="status-editor">
            <Grid.Row>
                <Grid.Column width={16}>
                    <EditableHeader
                        placeholder="Label"
                        size="large"
                        value={currentStatus.label}
                        onSubmit={(_, { value: label }) =>
                            setCurrentStatus({ ...currentStatus, label })
                        }
                    />
                    <EditableHeader
                        placeholder="Reference value"
                        size="medium"
                        value={currentStatus.ref}
                        onSubmit={(_, { value: ref }) =>
                            setCurrentStatus({ ...currentStatus, ref })
                        }
                    />

                    <Form.Group>
                        <Form.Field
                            control={Select}
                            options={creationStatusOptions}
                            onChange={(e, { value: creationStatus }) => {
                                setCurrentStatus({
                                    ...currentStatus,
                                    creationStatus,
                                });
                            }}
                            value={currentStatus.creationStatus}
                            placeholder="Creation Status"
                        />
                    </Form.Group>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={8}>
                    <Header>Logic</Header>

                    <CodeEditor
                        value={
                            currentStatus.checks?.logic &&
                            typeof currentStatus.checks?.logic === 'string'
                                ? currentStatus.checks?.logic
                                : ''
                        }
                        onChange={(code) => {
                            setCurrentStatus({
                                ...currentStatus,
                                checks: {
                                    logic: code,
                                },
                            });
                        }}
                        onKeyUp={handleCursor}
                        onClick={handleCursor}
                    />
                    {/* <JSONBuilder
            name="exportFilter"
            value={currentStatus.checks}
            onChange={(_, { value }) => {
              setCurrentStatus({
                ...currentStatus,
                checks: value,
              });
            }}
            allowModeChange={true}
          /> */}

                    <Segment>
                        {currentStatus?.id && (
                            <Button
                                loading={saving}
                                disabled={saving}
                                onClick={handleSave}
                            >
                                Save
                            </Button>
                        )}
                        {!currentStatus?.id && (
                            <Button
                                loading={saving}
                                disabled={saving}
                                onClick={handleSaveNew}
                            >
                                Save New
                            </Button>
                        )}
                    </Segment>
                </Grid.Column>
                <Grid.Column width={8}>
                    {/* <pre>{JSON.stringify(currentStatus.targetFields, null, "  ")}</pre> */}

                    <Accordion styled>
                        <Accordion.Title
                            active={openAccordions.includes('description')}
                            index={0}
                            onClick={() => handleToggleAccordion('description')}
                        >
                            <Icon name="dropdown" />
                            Description
                        </Accordion.Title>
                        <Accordion.Content
                            active={openAccordions.includes('description')}
                        >
                            <Form>
                                <TextArea
                                    name="description"
                                    value={currentStatus.description}
                                    onChange={(_, { value }) => {
                                        setCurrentStatus({
                                            ...currentStatus,
                                            description: value,
                                        });
                                    }}
                                />
                            </Form>
                        </Accordion.Content>

                        <Accordion.Title
                            active={openAccordions.includes('scope')}
                            index={0}
                            onClick={() => handleToggleAccordion('scope')}
                        >
                            <Icon name="dropdown" />
                            Scope
                        </Accordion.Title>
                        <Accordion.Content
                            active={openAccordions.includes('scope')}
                        >
                            <StatusScopeEditor
                                scope={currentStatus.scope}
                                onChange={(scope) => {
                                    setCurrentStatus({
                                        ...currentStatus,
                                        scope,
                                    });
                                }}
                            />
                        </Accordion.Content>

                        <Accordion.Title
                            active={openAccordions.includes('threshold')}
                            index={0}
                            onClick={() => handleToggleAccordion('threshold')}
                        >
                            <Icon name="dropdown" />
                            Threshold
                        </Accordion.Title>
                        <Accordion.Content
                            active={openAccordions.includes('threshold')}
                        >
                            <Form.Field width={5}>
                                <label>Threshold</label>
                                <ModelSearch
                                    name="thresholdFieldRef"
                                    modelName="ActivityField"
                                    value={currentStatus.thresholdFieldRef}
                                    onChange={(_, { value }) => {
                                        setCurrentStatus({
                                            ...currentStatus,
                                            thresholdFieldRef: value,
                                        });
                                    }}
                                    valueProperty="fieldKey"
                                    refProperty="fieldKey"
                                    // include any other necessary props for ModelSearch
                                />
                            </Form.Field>
                        </Accordion.Content>

                        <Accordion.Title
                            active={openAccordions.includes('meta')}
                            index={0}
                            onClick={() => handleToggleAccordion('meta')}
                        >
                            <Icon name="dropdown" />
                            Meta Information
                        </Accordion.Title>
                        <Accordion.Content
                            active={openAccordions.includes('meta')}
                        >
                            <JSONBuilder
                                name="exportFilter"
                                value={currentStatus.meta}
                                onChange={(_, { value }) => {
                                    setCurrentStatus({
                                        ...currentStatus,
                                        meta: value,
                                    });
                                }}
                                allowModeChange={true}
                            />
                        </Accordion.Content>

                        <Accordion.Title
                            active={openAccordions.includes('fields')}
                            index={0}
                            onClick={() => handleToggleAccordion('fields')}
                        >
                            <Icon name="dropdown" />
                            Fields ({currentStatus.fields.length})
                        </Accordion.Title>
                        <Accordion.Content
                            active={openAccordions.includes('fields')}
                        >
                            <Item.Group divided>
                                {currentStatus.fields.map((fieldKey) => {
                                    const _field = fields.find(
                                        (field) => field.fieldKey === fieldKey,
                                    );
                                    if (!_field)
                                        return (
                                            <Item
                                                key={fieldKey}
                                                className="status-editor-field"
                                            >
                                                <Item.Content>
                                                    <Header size="small">
                                                        {fieldKey} not found
                                                    </Header>
                                                </Item.Content>
                                                <Item.Extra>
                                                    <Icon
                                                        size="large"
                                                        name="trash"
                                                        onClick={() =>
                                                            handleRemoveField(
                                                                fieldKey,
                                                            )
                                                        }
                                                    />
                                                </Item.Extra>
                                            </Item>
                                        );
                                    const value =
                                        testData[_field.fieldKey]?.value;

                                    const {
                                        id,
                                        label,
                                        activityFieldTypeId,
                                        options,
                                    } = _field;

                                    const field = {
                                        label,
                                        name: id,
                                        hasFocus: false,
                                        ..._field,
                                        options: options
                                            ? options.values
                                            : null,
                                        type: activityFieldTypeId,
                                    };

                                    const isTargetField =
                                        !!currentStatus.targetFields?.includes(
                                            fieldKey,
                                        );

                                    const hasError =
                                        isTargetField &&
                                        validationResult === 'red';

                                    return (
                                        <Item
                                            key={fieldKey}
                                            className="status-editor-field"
                                        >
                                            <Item.Content>
                                                <Header size="small">
                                                    {field.label} (
                                                    {field.activityFieldTypeId})
                                                </Header>
                                                <Header size="tiny">
                                                    {field.activity?.label}
                                                </Header>

                                                <Header size="medium">
                                                    {' '}
                                                    {field.fieldKey}
                                                </Header>
                                            </Item.Content>
                                            <Item.Extra>
                                                <div>
                                                    <Icon
                                                        size="large"
                                                        name="trash"
                                                        onClick={() =>
                                                            handleRemoveField(
                                                                field.fieldKey,
                                                            )
                                                        }
                                                    />
                                                </div>
                                                <div>
                                                    <Icon
                                                        size="large"
                                                        name="edit outline"
                                                        onClick={() =>
                                                            handleEditActivity(
                                                                field.activityId,
                                                            )
                                                        }
                                                    />
                                                </div>
                                                <Checkbox
                                                    label={
                                                        <label>
                                                            <Icon
                                                                size="large"
                                                                name="target"
                                                            />
                                                        </label>
                                                    }
                                                    checked={
                                                        isTargetField === true
                                                    }
                                                    onClick={() =>
                                                        handleToggleTargetField(
                                                            field.fieldKey,
                                                        )
                                                    }
                                                />
                                            </Item.Extra>
                                            <Divider />
                                        </Item>
                                    );
                                })}
                            </Item.Group>
                        </Accordion.Content>

                        <Accordion.Title
                            active={openAccordions.includes('addFields')}
                            index={0}
                            onClick={() => handleToggleAccordion('addFields')}
                        >
                            <Icon name="dropdown" />
                            Add Fields
                        </Accordion.Title>
                        <Accordion.Content
                            active={openAccordions.includes('addFields')}
                        >
                            <Dropdown
                                fluid
                                selection
                                // multiple={multiple}
                                search={true}
                                options={searchOptions}
                                // value={value}
                                placeholder="Type field key"
                                onChange={handleFieldSelection}
                                onSearchChange={handleSearchChange}
                                disabled={loadingFields}
                                loading={loadingFields}
                            />

                            <FieldSelector
                                onSelect={handleFieldSelected}
                                addedFields={currentStatus.fields}
                            />
                        </Accordion.Content>

                        <Accordion.Title
                            active={openAccordions.includes('testing')}
                            index={0}
                            onClick={() => handleToggleAccordion('testing')}
                        >
                            <Icon name="dropdown" />
                            Testing
                        </Accordion.Title>
                        <Accordion.Content
                            active={openAccordions.includes('testing')}
                        >
                            <Item.Group>
                                {currentStatus.fields.map((fieldKey) => {
                                    const _field = fields.find(
                                        (field) => field.fieldKey === fieldKey,
                                    );
                                    if (!_field) return null;
                                    const value =
                                        testData[_field.fieldKey]?.value;

                                    const {
                                        id,
                                        label,
                                        activityFieldTypeId,
                                        options,
                                    } = _field;

                                    const field = {
                                        label,
                                        name: id,
                                        hasFocus: false,
                                        ..._field,
                                        options: options
                                            ? options.values
                                            : null,
                                        type: activityFieldTypeId,
                                    };

                                    const isTargetField =
                                        !!currentStatus.targetFields?.includes(
                                            fieldKey,
                                        );

                                    const hasError =
                                        isTargetField &&
                                        validationResult === 'red';

                                    return (
                                        <Item
                                            key={fieldKey}
                                            className="status-editor-field"
                                        >
                                            {/* <Item.Content> */}
                                            <div
                                                className={`ticket-fields ui form `}
                                            >
                                                <div
                                                    className={`form-field  ${
                                                        hasError
                                                            ? '--has-exception'
                                                            : ''
                                                    }`}
                                                >
                                                    <FormField
                                                        value={
                                                            typeof value !==
                                                            'undefined'
                                                                ? value.toString()
                                                                : null
                                                        }
                                                        handleFieldDataChange={(
                                                            _,
                                                            value,
                                                        ) => {
                                                            setTestData({
                                                                ...testData,
                                                                [field.fieldKey]:
                                                                    {
                                                                        activityFieldTypeId:
                                                                            field.activityFieldTypeId,
                                                                        value,
                                                                    },
                                                            });
                                                        }}
                                                        field={{
                                                            ...field,
                                                            label: `${field.label} (${field.fieldKey})`,
                                                        }}
                                                        hasException={hasError}
                                                    />
                                                </div>
                                            </div>
                                            {/* </Item.Content> */}
                                            <Divider />
                                        </Item>
                                    );
                                })}

                                {thresholdField && (
                                    <Item className="status-editor-field">
                                        <div
                                            className={`ticket-fields ui form `}
                                        >
                                            <FormField
                                                value={
                                                    testData[
                                                        thresholdField.fieldKey
                                                    ]?.value
                                                }
                                                handleFieldDataChange={(
                                                    _,
                                                    value,
                                                ) => {
                                                    console.log(value);
                                                    setTestData({
                                                        ...testData,
                                                        [thresholdField.fieldKey]:
                                                            {
                                                                activityFieldTypeId:
                                                                    thresholdField.activityFieldTypeId,
                                                                value,
                                                                field: thresholdField,
                                                            },
                                                    });
                                                }}
                                                field={thresholdField}
                                            />
                                        </div>
                                    </Item>
                                )}
                            </Item.Group>

                            {(validationResultError || validationResult) && (
                                <Segment>
                                    <Header>Result</Header>

                                    {validationResult && (
                                        <StatusTestResult
                                            logicResult={
                                                validationResult.result
                                            }
                                        />
                                    )}

                                    {validationResultError && (
                                        <Message negative>
                                            <Message.Header>
                                                {validationResultError.error}{' '}
                                                {validationResultError.message}
                                            </Message.Header>
                                            <pre>
                                                {validationResultError.stack}
                                            </pre>
                                        </Message>
                                    )}
                                </Segment>
                            )}

                            <Button
                                loading={saving}
                                disabled={saving || !status?.checks?.logic}
                                onClick={handleTestStatus}
                            >
                                Test
                            </Button>

                            <Divider />

                            <JSONBuilder
                                allowModeChange={true}
                                value={testData}
                                onChange={() => {}}
                            />
                            {/* 
              <Header>Test Data</Header>
              <pre>{JSON.stringify(testData, null, '  ')}</pre> */}
                        </Accordion.Content>
                        <Accordion.Title
                            active={openAccordions.includes('testing_result')}
                            index={0}
                            onClick={() =>
                                handleToggleAccordion('testing_result')
                            }
                        >
                            <Icon name="dropdown" />
                            Testing Result
                        </Accordion.Title>
                        <Accordion.Content
                            active={openAccordions.includes('testing_result')}
                        >
                            <pre>
                                {JSON.stringify(validationResult, null, 2)}
                            </pre>
                        </Accordion.Content>
                    </Accordion>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};
