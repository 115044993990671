import { Accordion, Button, List } from 'semantic-ui-react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { DraggableActivity } from './ActivityListItem';
import { DraggableComponent } from '../../common/dragAndDrop/DraggableComponent';
import { ProgrammeField } from './ProgrammeFieldManager';
import { Activity } from '../../../types';
import ActionButtons from '../../common/ActionButtons';

import './ActivityViewer.scss';

interface ActivityAccordionProps {
    activity: Activity;
    onDeleted: (activity: Activity, field: Field, fieldIndex: number) => void;
}

const ActivityViewer = ({ activity, onDeleted }: ActivityAccordionProps) => (
    <Droppable droppableId={activity.id} type="field">
        {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
                <List divided className="field-manager-activity-viewer">
                    {activity.fields?.map((field, fieldIndex) => (
                        <Draggable
                            draggableId={field.id}
                            index={fieldIndex}
                            key={field.id}
                        >
                            {(provided, snapshot) => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className={`draggable-component  activity-viewer-field
                  ${snapshot.isDragging ? ' dragging ui label' : 'item'}
                  `}
                                >
                                    <div>
                                        <List.Header>
                                            {field.label} ({' '}
                                            {field.activityFieldTypeId}){' '}
                                        </List.Header>
                                        <p>{field.fieldKey}</p>
                                    </div>
                                    <ActionButtons
                                        deleteClicked={() => {
                                            onDeleted(
                                                activity,
                                                field,
                                                fieldIndex,
                                            );
                                        }}
                                    />
                                </div>
                            )}
                        </Draggable>

                        // <DraggableComponent
                        //   key={field.id}
                        //   draggableId={field.id}
                        //   index={fieldIndex}
                        // >
                        //   <List.Item>
                        //     <List.Header>
                        //       {field.label} ( {field.activityFieldTypeId}){' '}
                        //     </List.Header>
                        //     <p>{field.fieldKey}</p>
                        //   </List.Item>
                        // </DraggableComponent>
                    ))}
                </List>
                {provided.placeholder}
            </div>
        )}
    </Droppable>
);

export default ActivityViewer;
