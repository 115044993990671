import statusApi from '../api/statusApi';
import * as types from '../constants/actionTypes';
import { Status } from '../types';

export function loadStatuses(filter?: any) {
    return function (dispatch) {
        return statusApi.getStatuses(filter).then((statuses) => {
            dispatch({
                type: types.LOAD_STATUSES_SUCCESS,
                statuses,
            });
        });
    };
}
export function deleteStatus(status: Status) {
    return function (dispatch) {
        return statusApi
            .updateStatus({ ...status, deleted: true })
            .then((status) => {
                dispatch({
                    type: types.UPDATE_STATUS_SUCCESS,
                    status,
                });
            });
    };
}
