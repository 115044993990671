import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { Segment, Form, Button, Dimmer, Loader } from 'semantic-ui-react';
import * as _ from 'lodash';
import toastr from 'toastr';

import { WrapAttachmentsContext } from '../../../context/AttachmentContext';
import Text from '../../common/fields/Text';
import RichTextArea from '../../common/fields/RichTextArea';
import Select from '../../common/fields/Select';
import { checkModalFormDataValid } from '../../../utils/Validation';

class AddAttachmentModalLink extends Component {
    state = {
        formData: {
            url: '',
            label: '',
            description: '',
            type: '',
            classification: '',
        },
        isModalFormDataValid: false,
        validations: {
            url: {},
            label: {},
        },
        saving: false,
    };

    handleFormDataChange = (e, { name, value, checked }) => {
        this.setState((prevState) => ({
            formData: {
                ...prevState.formData,
                [name]: value || checked || null,
            },
        }));
    };

    uploadExternalAttachments = () => {
        const { url, label, description, type, classification } =
            this.state.formData;
        const { currentModel, modelType, currentUser } = this.props;
        const { uploadExternalAttachments } = this.props.attachmentStateActions;
        let attachment = { url, label };
        if (description !== '') {
            attachment['description'] = description;
        }
        if (type !== '') {
            attachment['type'] = type;
        }
        if (classification !== '') {
            attachment['classification'] = classification;
        }
        this.setState({ saving: true });
        uploadExternalAttachments(
            modelType,
            currentModel,
            attachment,
            currentUser,
        )
            .then(() => {
                toastr.success('External link is successfully added');
                this.setState({ saving: false });
            })
            .catch((e) => {
                this.setState({ saving: false });
                toastr.error(e);
            });
    };

    handleOnFocus = (name) => {
        const { validations } = this.state;
        validations[name].hasFocus = true;
        this.setState({ validations });
    };

    handleOnBlur = (name) => {
        const { validations } = this.state;
        validations[name].hasFocus = false;
        this.checkValidation(name, this.state.formData[name]);
        this.setState({ validations });
    };

    checkValidation = (name, value) => {
        const { validations } = this.state;
        const modalFormDataValid = checkModalFormDataValid(
            validations,
            name,
            value,
        );
        this.setState({ ...modalFormDataValid });
    };

    getErrors = (propertryName) => {
        const { validations } = this.state;
        const validation = validations[propertryName];
        const isValidationErrors = !_.isEmpty(validation.errors);
        return !validation.hasFocus && isValidationErrors
            ? validation.errors
            : {};
    };

    render() {
        const { saving, isModalFormDataValid } = this.state;
        const { url, label, description, type, classification } =
            this.state.formData;
        const { attachmentType, attachmentClassification } =
            this.props.attachmentsConstants;
        return (
            <Segment>
                <Dimmer active={saving} inverted>
                    <Loader disabled={!saving} />
                </Dimmer>
                <Form>
                    <Form.Group>
                        <Text
                            fluid={true}
                            label="URL"
                            onChange={this.handleFormDataChange}
                            name="url"
                            width={'8'}
                            value={url}
                            errors={this.getErrors('url')}
                            onBlur={() => this.handleOnBlur('url')}
                            onFocus={() => this.handleOnFocus('url')}
                        />
                        <Text
                            fluid={true}
                            label="Label"
                            onChange={this.handleFormDataChange}
                            name="label"
                            width={'8'}
                            value={label}
                            errors={this.getErrors('label')}
                            onBlur={() => this.handleOnBlur('label')}
                            onFocus={() => this.handleOnFocus('label')}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Field className="full-width">
                            <RichTextArea
                                label="Description"
                                name="description"
                                value={description}
                                onChange={this.handleFormDataChange}
                            />
                        </Form.Field>
                    </Form.Group>
                    <Form.Group>
                        <Select
                            label="Type"
                            name="type"
                            options={attachmentType}
                            value={type}
                            onChange={this.handleFormDataChange}
                            width={'8'}
                        />

                        <Select
                            label="Classification"
                            options={attachmentClassification}
                            value={classification}
                            onChange={this.handleFormDataChange}
                            name="classification"
                            width={'8'}
                        />
                    </Form.Group>
                </Form>
                <Button
                    disabled={!isModalFormDataValid}
                    inverted
                    onClick={this.uploadExternalAttachments}
                >
                    Save link
                </Button>
            </Segment>
        );
    }
}

AddAttachmentModalLink.propTypes = {
    attachmentsConstants: PropTypes.object,
    attachmentStateActions: PropTypes.object,
    currentModel: PropTypes.object,
    modelType: PropTypes.string,
    currentUser: PropTypes.object,
};

export default WrapAttachmentsContext(AddAttachmentModalLink);
