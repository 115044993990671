import React from 'react';
import * as PropTypes from 'prop-types';
//import { Card, Header } from 'semantic-ui-react';
import ReactHighcharts from 'react-highcharts';
import { getExportingOptions } from './BarChartComponent';

const BoxPlotChartComponent = (args) => {
    const { chartOptions, data } = args;
    const seriesData = data?.data?.seriesData;
    const categories = data?.data?.categories;
    if (!seriesData) return null;
    const createChartConfig = () => {
        return Object.assign(
            {},
            chartOptions,
            {
                xAxis: {
                    ...chartOptions.xAxis,
                    categories: categories,
                },
                series: seriesData?.map((series) => {
                    return {
                        ...series,
                        name: series.value,
                        animation: false,
                    };
                }),
            },
            { exporting: getExportingOptions(chartOptions) },
        );
    };

    return <ReactHighcharts config={createChartConfig()} />;
};

BoxPlotChartComponent.propTypes = {
    data: PropTypes.array,
};

export default BoxPlotChartComponent;
