import React from 'react';
import * as PropTypes from 'prop-types';
import ActivitySets from './ActivitySets';
import { Grid, Header } from 'semantic-ui-react';
import ProgrammeCategoryBuilder from '../programme/ProgrammeCategoryBuilder';

const CategoryBuilder = (props) => {
    const { programme } = props;

    function unassignedActivities() {
        return programme.activitySets.filter(
            (activitySet) => !(activitySet.categoryTempId >= 0),
        );
    }

    return (
        <Grid columns={2} celled="internally">
            <Grid.Column width={3}>
                <Header>Question Sets</Header>
                <ActivitySets
                    activitySets={unassignedActivities()}
                    {...props}
                    layout="LIST"
                    showActions="add"
                    addClicked={props.setActivitySetCategory}
                    removeClicked={props.setActivitySetCategory}
                />
            </Grid.Column>

            <Grid.Column width={13}>
                <ProgrammeCategoryBuilder programme={programme} {...props} />
            </Grid.Column>
        </Grid>
    );
};

CategoryBuilder.propTypes = {
    programme: PropTypes.object.isRequired,
    setActivitySetCategory: PropTypes.func,
};

export default CategoryBuilder;
