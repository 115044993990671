import { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
    Container,
    Dimmer,
    Form,
    Grid,
    Header,
    Loader,
    Segment,
} from 'semantic-ui-react';
import EntityApi from '../../api/entityApi';
import { ProductInformation } from '../../types';
import toastr from 'toastr';
import Text from '../common/fields/Text';
import TextArea from '../common/fields/TextArea';
import ActionButtons from '../common/ActionButtons';
import * as permissionTypes from '../../constants/permissionTypes';
import ConfirmationModalContext from '../../context/ConfirmationModalContext';
import UserContext from '../../context/UserContext';
import ConfirmWrapper from '../common/ConfirmWrapper';
import { MODAL_COMPONENTS } from '../common/ModalRoot';
import * as uiActions from '../../actions/uiActions';
import { useDispatch } from 'react-redux';
import { deleteEntity } from '../../actions/entityActions';
import { FirstStyleKpiComponent } from '../common/dashboard/components/KpiComponent/FirstStyleKpiComponent';
import KpiComponent from '../common/dashboard/components/KpiComponent/KpiComponent';
import ExecutionPlanApi from '../../api/executionPlanApi';

const ProductPage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [product, setProduct] = useState<ProductInformation>();
    const { id: productId } = useParams<{ id?: string }>();
    const history = useHistory();
    const dispatch = useDispatch();

    const userContext = useContext(UserContext);
    const modalContext = useContext(ConfirmationModalContext);

    const { currentUser } = userContext;

    const fetchProduct = async (id: string) => {
        try {
            setIsLoading(true);
            const res = await EntityApi.getProductInformation(id);
            setProduct(res);
            setIsLoading(false);
        } catch (error) {
            toastr.error(error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (productId) {
            fetchProduct(productId);
        } else history.push('products');
    }, [productId]);

    const structuralNodeId = 2;
    const userHasPermissionToDeleteProduct = currentUser.checkPermissionForNode(
        structuralNodeId,
        permissionTypes.ADMIN,
    );

    const handleNotifyFairValueComplete = () => {
        modalContext.confirmPopupActions.showConfirmDialog(
            `This will email all distributors that have submitted a PVIE for this product so that they can log in an see the fair value assessment. Do you want to continue?`,
            notifyFairValueCompleteOnConfirm,
        );
    };
    const notifyFairValueCompleteOnConfirm = async () => {
        try {
            await EntityApi.notifyFairValueComplete(product.id);
            if (fetchProduct) fetchProduct(productId);
        } catch (error) {}
    };

    const handleNotifyNewProductAdded = () => {
        modalContext.confirmPopupActions.showConfirmDialog(
            `This will notify all distributors that have logged into the system that a new product has been added. Do you want to continue?`,
            notifyNewProductAddedOnConfirm,
        );
    };
    const notifyNewProductAddedOnConfirm = async () => {
        try {
            await EntityApi.notifyNewProductAdded(product.id);
            if (fetchProduct) fetchProduct(productId);
        } catch (error) {}
    };

    const handleDeleteProduct = () => {
        modalContext.confirmPopupActions.showConfirmDialog(
            `Are you sure you want to delete ${product.label} product?`,
            deleteProductOnConfirm,
        );
    };
    const deleteProductOnConfirm = async () => {
        try {
            const productToDelete = await EntityApi.getEntity(product.id);
            dispatch(deleteEntity(productToDelete));
        } catch (error) {
            toastr.error(error);
        }
    };

    const launchGovernanceProgramme = async (
        programmeExecutionPlanId: number,
    ) => {
        if (!programmeExecutionPlanId) {
            return toastr.error('Cannot launch. Contact administrator.');
        }
        if (!product.productOwnerId) {
            return toastr.error('Cannot launch without an owner.');
        }
        if (!product.productReviewerId) {
            return toastr.error('Cannot launch without a reviewer.');
        }

        setIsLoading(true);

        try {
            await ExecutionPlanApi.launchNowExecutionPlan(
                programmeExecutionPlanId,
                {
                    entityId: product.id,
                    assignedPartyUserId: product.productOwnerId,
                    ownerPartyUserId: product.productReviewerId,
                    reviewPartyUserId: product.productReviewerId,
                },
            );
            setIsLoading(false);
            fetchProduct(productId);
        } catch (error) {
            toastr.error(error);
            setIsLoading(false);
        }
    };

    const editProduct = () => {
        dispatch(
            uiActions.showModal({
                modalType: MODAL_COMPONENTS.SAVE_ENTITY_MODAL,
                modalProps: {
                    entityId: product.id,
                    onSave: () => fetchProduct(productId),
                },
            }),
        );
    };

    const viewClicked = (executionId: number) => {
        history.push(`/execution/${executionId}`);
    };

    return (
        <Container>
            <Dimmer active={isLoading} inverted>
                <Loader disabled={!isLoading} />
            </Dimmer>
            <Header as="h2">{product?.label} Summary</Header>
            <Segment>
                <ActionButtons
                    className="u-show-on-hover__target"
                    notifyFairValueComplete={handleNotifyFairValueComplete}
                    notifyNewProductAdded={
                        product?.newProductEmailSent
                            ? null
                            : handleNotifyNewProductAdded
                    }
                    editClicked={editProduct}
                    deleteClicked={
                        userHasPermissionToDeleteProduct
                            ? handleDeleteProduct
                            : null
                    }
                />
            </Segment>
            <Grid className="ui equal width grid vidende-dashboard">
                <Grid.Row stretched className="dashboard-row">
                    {product?.manufacturerProgrammeVisible && (
                        <Grid.Column>
                            <Segment className="dashboard-widget">
                                <KpiComponent
                                    color={product?.manufacturerProgrammeColor}
                                    style={1}
                                    value={product.manufacturerProgrammeStatus}
                                    header={product.manufacturerProgrammeHeader}
                                />
                                <ActionButtons
                                    className="dashboard-action-buttons"
                                    size="medium"
                                    launchGovernanceProgrammeClicked={
                                        !product?.manufacturerProgrammeLaunched &&
                                        (() =>
                                            launchGovernanceProgramme(
                                                product?.manufacturerProgrammeExecutionPlanId,
                                            ))
                                    }
                                    launchGovernanceProgrammeTooltip={
                                        !product?.manufacturerProgrammeLaunched &&
                                        `Launch ${product?.manufacturerProgrammeHeader}`
                                    }
                                    viewClicked={
                                        product?.manufacturerProgrammeLaunched &&
                                        (() =>
                                            viewClicked(
                                                product?.manufacturerProgrammeExecutionId,
                                            ))
                                    }
                                    viewTooltip={
                                        product?.manufacturerProgrammeLaunched &&
                                        `Go to ${product?.manufacturerProgrammeHeader}`
                                    }
                                />
                            </Segment>
                        </Grid.Column>
                    )}
                    {product?.productRiskAssessmentProgrammeVisible && (
                        <Grid.Column>
                            <Segment className="dashboard-widget">
                                <KpiComponent
                                    color={
                                        product?.productRiskAssessmentProgrammeColor
                                    }
                                    style={1}
                                    value={
                                        product?.productRiskAssessmentProgrammeStatus
                                    }
                                    header={
                                        product?.productRiskAssessmentProgrammeHeader
                                    }
                                />
                                <ActionButtons
                                    className="dashboard-action-buttons"
                                    size="medium"
                                    launchGovernanceProgrammeClicked={
                                        !product?.productRiskAssessmentProgrammeLaunched &&
                                        (() =>
                                            launchGovernanceProgramme(
                                                product?.productRiskAssessmentProgrammeExecutionPlanId,
                                            ))
                                    }
                                    launchGovernanceProgrammeTooltip={
                                        !product?.productRiskAssessmentProgrammeLaunched &&
                                        `Launch ${product?.productRiskAssessmentProgrammeHeader}`
                                    }
                                    viewClicked={
                                        product?.productRiskAssessmentProgrammeLaunched &&
                                        (() =>
                                            viewClicked(
                                                product?.productRiskAssessmentProgrammeExecutionId,
                                            ))
                                    }
                                    viewTooltip={
                                        product?.productRiskAssessmentProgrammeLaunched &&
                                        `Go to ${product?.productRiskAssessmentProgrammeHeader}`
                                    }
                                />
                            </Segment>
                        </Grid.Column>
                    )}
                    {product?.productOutcome1ProgrammeVisible && (
                        <Grid.Column>
                            <Segment className="dashboard-widget">
                                <KpiComponent
                                    color={
                                        product?.productOutcome1ProgrammeColor
                                    }
                                    style={1}
                                    value={
                                        product?.productOutcome1ProgrammeStatus
                                    }
                                    header={
                                        product?.productOutcome1ProgrammeHeader
                                    }
                                />
                                <ActionButtons
                                    className="dashboard-action-buttons"
                                    size="medium"
                                    launchGovernanceProgrammeClicked={
                                        !product?.productOutcome1ProgrammeLaunched &&
                                        (() =>
                                            launchGovernanceProgramme(
                                                product?.productOutcome1ProgrammeExecutionPlanId,
                                            ))
                                    }
                                    launchGovernanceProgrammeTooltip={
                                        !product?.productOutcome1ProgrammeLaunched &&
                                        `Launch ${product?.productOutcome1ProgrammeHeader}`
                                    }
                                    viewClicked={
                                        product?.productOutcome1ProgrammeLaunched &&
                                        (() =>
                                            viewClicked(
                                                product?.productOutcome1ProgrammeExecutionId,
                                            ))
                                    }
                                    viewTooltip={
                                        product?.productOutcome1ProgrammeLaunched &&
                                        `Go to ${product?.productOutcome1ProgrammeHeader}`
                                    }
                                />
                            </Segment>
                        </Grid.Column>
                    )}
                    {product?.productOutcome2ProgrammeVisible && (
                        <Grid.Column>
                            <Segment className="dashboard-widget">
                                <KpiComponent
                                    color={
                                        product?.productOutcome2ProgrammeColor
                                    }
                                    style={1}
                                    value={
                                        product?.productOutcome2ProgrammeStatus
                                    }
                                    header={
                                        product?.productOutcome2ProgrammeHeader
                                    }
                                />
                                <ActionButtons
                                    className="dashboard-action-buttons"
                                    size="medium"
                                    launchGovernanceProgrammeClicked={
                                        !product?.productOutcome2ProgrammeLaunched &&
                                        (() =>
                                            launchGovernanceProgramme(
                                                product?.productOutcome2ProgrammeExecutionPlanId,
                                            ))
                                    }
                                    launchGovernanceProgrammeTooltip={
                                        !product?.productOutcome2ProgrammeLaunched &&
                                        `Launch ${product?.productOutcome2ProgrammeHeader}`
                                    }
                                    viewClicked={
                                        product?.productOutcome2ProgrammeLaunched &&
                                        (() =>
                                            viewClicked(
                                                product?.productOutcome2ProgrammeExecutionId,
                                            ))
                                    }
                                    viewTooltip={
                                        product?.productOutcome2ProgrammeLaunched &&
                                        `Go to ${product?.productOutcome2ProgrammeHeader}`
                                    }
                                />
                            </Segment>
                        </Grid.Column>
                    )}
                </Grid.Row>
                <Grid.Row>
                    {product?.productOutcome3ProgrammeVisible && (
                        <Grid.Column>
                            <Segment className="dashboard-widget">
                                <KpiComponent
                                    color={
                                        product?.productOutcome3ProgrammeColor
                                    }
                                    style={1}
                                    value={
                                        product?.productOutcome3ProgrammeStatus
                                    }
                                    header={
                                        product?.productOutcome3ProgrammeHeader
                                    }
                                />
                                <ActionButtons
                                    className="dashboard-action-buttons"
                                    size="medium"
                                    launchGovernanceProgrammeClicked={
                                        !product?.productOutcome3ProgrammeLaunched &&
                                        (() =>
                                            launchGovernanceProgramme(
                                                product?.productOutcome3ProgrammeExecutionPlanId,
                                            ))
                                    }
                                    launchGovernanceProgrammeTooltip={
                                        !product?.productOutcome3ProgrammeLaunched &&
                                        `Launch ${product?.productOutcome3ProgrammeHeader}`
                                    }
                                    viewClicked={
                                        product?.productOutcome3ProgrammeLaunched &&
                                        (() =>
                                            viewClicked(
                                                product?.productOutcome3ProgrammeExecutionId,
                                            ))
                                    }
                                    viewTooltip={
                                        product?.productOutcome3ProgrammeLaunched &&
                                        `Go to ${product?.productOutcome3ProgrammeHeader}`
                                    }
                                />
                            </Segment>
                        </Grid.Column>
                    )}
                    {product?.productOutcome4ProgrammeVisible && (
                        <Grid.Column>
                            <Segment className="dashboard-widget">
                                <KpiComponent
                                    color={
                                        product?.productOutcome4ProgrammeColor
                                    }
                                    style={1}
                                    value={
                                        product?.productOutcome4ProgrammeStatus
                                    }
                                    header={
                                        product?.productOutcome4ProgrammeHeader
                                    }
                                />
                                <ActionButtons
                                    className="dashboard-action-buttons"
                                    size="medium"
                                    launchGovernanceProgrammeClicked={
                                        !product?.productOutcome4ProgrammeLaunched &&
                                        (() =>
                                            launchGovernanceProgramme(
                                                product?.productOutcome4ProgrammeExecutionPlanId,
                                            ))
                                    }
                                    launchGovernanceProgrammeTooltip={
                                        !product?.productOutcome4ProgrammeLaunched &&
                                        `Launch ${product?.productOutcome4ProgrammeHeader}`
                                    }
                                    viewClicked={
                                        product?.productOutcome4ProgrammeLaunched &&
                                        (() =>
                                            viewClicked(
                                                product?.productOutcome4ProgrammeExecutionId,
                                            ))
                                    }
                                    viewTooltip={
                                        product?.productOutcome4ProgrammeLaunched &&
                                        `Go to ${product?.productOutcome4ProgrammeHeader}`
                                    }
                                />
                            </Segment>
                        </Grid.Column>
                    )}
                </Grid.Row>
            </Grid>
            <Segment>
                <Form>
                    <Form.Group widths="equal">
                        <Form.Field>
                            <Text
                                label="Product Owner"
                                name="productOwner"
                                value={product?.productOwner}
                                readonly={true}
                            />
                        </Form.Field>
                        <Form.Field>
                            <div
                                style={{
                                    backgroundColor:
                                        product?.riskRatingColor ||
                                        'transparent',
                                    padding: '0.5em',
                                    borderRadius: '4px',
                                }}
                            >
                                <Text
                                    label="Risk Rating"
                                    name="riskRatingLabel"
                                    value={product?.riskRatingLabel}
                                    readonly={true}
                                />
                            </div>
                        </Form.Field>
                    </Form.Group>

                    <Form.Group widths="equal">
                        <Form.Field>
                            <Text
                                label="Product Reviewer"
                                name="productReviewer"
                                value={product?.productReviewer}
                                readonly={true}
                            />
                        </Form.Field>

                        <Form.Field>
                            <Text
                                label="Last Review Date"
                                name="lastReviewDate"
                                value={product?.lastReviewDate}
                                readonly={true}
                            />
                        </Form.Field>
                    </Form.Group>
                    <Form.Group>
                        <Form.Field width={4}>
                            <Text
                                label="Reference"
                                name="reference"
                                value={product?.reference}
                                readonly={true}
                            />
                        </Form.Field>
                        <Form.Field width={12}>
                            <Text
                                label="Countries"
                                name="country"
                                value={product?.country}
                                readonly={true}
                            />
                        </Form.Field>
                    </Form.Group>
                    <Form.Group>
                        <Form.Field width={4}>
                            <Text
                                label="Group"
                                name="group"
                                value={product?.productGroup}
                                readonly={true}
                            />
                        </Form.Field>
                        <Form.Field width={12}>
                            <Text
                                label="Class"
                                name="class"
                                value={product?.classOfBusiness}
                                readonly={true}
                            />
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Field>
                            <TextArea
                                label="Summary"
                                name="summary"
                                value={product?.summary}
                                readonly={true}
                            />
                        </Form.Field>
                        <Form.Field>
                            <TextArea
                                label="Target Market"
                                name="targetMarket"
                                value={product?.targetMarket}
                                readonly={true}
                            />
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Field>
                            <TextArea
                                label="Product Services Conclusion"
                                name="conclusionProductServices"
                                value={product?.conclusionProductServices}
                                readonly={true}
                            />
                        </Form.Field>
                        <Form.Field>
                            <TextArea
                                label="Price & Value Conclusion"
                                name="conclusionPriceValue"
                                value={product?.conclusionPriceValue}
                                readonly={true}
                            />
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Field>
                            <TextArea
                                label="Customer Understanding Conclusion"
                                name="conclusionCustomerUnderstanding"
                                value={product?.conclusionCustomerUnderstanding}
                                readonly={true}
                            />
                        </Form.Field>
                        <Form.Field>
                            <TextArea
                                label="Consumer Support Conclusion"
                                name="conclusionConsumerSupport"
                                value={product?.conclusionConsumerSupport}
                                readonly={true}
                            />
                        </Form.Field>
                    </Form.Group>
                    <Form.Field>
                        <TextArea
                            label="Notes"
                            name="notes"
                            value={product?.productNotes}
                            readonly={true}
                        />
                    </Form.Field>
                </Form>
            </Segment>
        </Container>
    );
};

export default ConfirmWrapper(ProductPage);
