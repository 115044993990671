import { Icon, List, Popup } from 'semantic-ui-react';
import {
    Activity,
    ActivitySet,
    Field,
    Programme,
    Status,
} from '../../../types';
import { FieldTypeIcon } from '../fields/FieldTypeIcon';
import { useContext, useEffect, useRef } from 'react';
import { ModelExplorerGroupContext } from '../../programme/programmeExplorer/ModelExplorerGroup';

interface ModelInstanceLabelProps {
    modelInstance: any;
    modelName: string;
}

const modelLabelComponents = {
    Programme: (programme: Programme) => {
        return (
            <span>
                {programme.label} ({programme.ref})
            </span>
        );
    },
    ActivitySet: (activitySet: ActivitySet) => {
        return (
            <span>
                {activitySet.label} ({activitySet.ref})
            </span>
        );
    },
    Activity: (activity: Activity) => {
        return (
            <span>
                {activity.label} ({activity.ref})
            </span>
        );
    },
    ActivityField: (activityField: Field) => {
        const elementRef = useRef(null);

        const { highlightModel, hightlightedModels } = useContext(
            ModelExplorerGroupContext,
        );

        const handleClick = (e) => {
            if (e.detail === 2) {
                highlightModel(
                    'ActivityField',
                    activityField.fieldKey,
                    'fieldKey',
                );
            }
        };

        const isHighlighted =
            hightlightedModels?.ActivityField?.fieldKey ===
            activityField.fieldKey;

        useEffect(() => {
            if (elementRef.current && isHighlighted) {
                elementRef.current.scrollIntoView({
                    behavior: 'instant',
                    block: 'center',
                });
                console.log('scrolling to', elementRef.current);
            }
        }, [isHighlighted]);

        return (
            <span
                onClick={handleClick}
                style={{
                    color: isHighlighted ? 'red' : null,
                }}
                ref={elementRef}
            >
                <FieldTypeIcon type={activityField.activityFieldTypeId} />{' '}
                <strong>{activityField.label}</strong> ({activityField.fieldKey}
                )
            </span>
        );
    },
    DashboardRow: (dashboardRow: any) => {
        return (
            <span>
                <strong>DashboardRow: {dashboardRow.options?.sort}</strong>
            </span>
        );
    },
    DashboardColumn: (dashboardColumn: any) => {
        return (
            <span>
                <strong>
                    DashboardColumn: {dashboardColumn.options?.sort}
                </strong>
            </span>
        );
    },
    DashboardItem: (dashboardItem: any) => {
        const { highlightModel, hightlightedModels } = useContext(
            ModelExplorerGroupContext,
        );

        const ragField = dashboardItem.options?.ragField;
        const ragMetric = dashboardItem.options?.ragMetric;

        const handleClick = (e) => {
            if (e.detail === 2 && ragField) {
                highlightModel('ActivityField', ragField, 'fieldKey');
            }
            if (e.detail === 2 && ragMetric) {
                highlightModel('Status', ragMetric, 'ref');
            }
        };

        const isHighlighted =
            (ragField &&
                hightlightedModels?.ActivityField?.fieldKey === ragField) ||
            (ragMetric && hightlightedModels?.Status?.ref === ragMetric);

        return (
            <span
                style={{
                    color: isHighlighted ? 'red' : null,
                }}
                onClick={handleClick}
            >
                <strong> {dashboardItem.options?.header}</strong>{' '}
                {ragField && (
                    <span>
                        <Icon name="i cursor" /> {ragField}
                    </span>
                )}
                {ragMetric && (
                    <span>
                        {' '}
                        <Icon name="calculator" /> {ragMetric}
                    </span>
                )}
            </span>
        );
    },

    Status: (status: Status) => {
        const { highlightModel, hightlightedModels } = useContext(
            ModelExplorerGroupContext,
        );

        const handleMetricClick = (e) => {
            if (e.detail === 2) {
                highlightModel('Status', status.ref, 'ref');
            }
        };

        const isStatusHighlighted =
            hightlightedModels?.Status?.ref === status.ref;

        return (
            <span>
                <span
                    onClick={handleMetricClick}
                    style={{
                        color: isStatusHighlighted ? 'red' : null,
                    }}
                >
                    <Icon name="calculator" /> <strong> {status.label}</strong>{' '}
                    ({status.ref})
                </span>
                <List>
                    {status.fields?.map((field: string) => {
                        const isHighlighted =
                            hightlightedModels?.ActivityField?.fieldKey ===
                            field;

                        const handleClick = (e) => {
                            if (e.detail === 2) {
                                highlightModel(
                                    'ActivityField',
                                    field,
                                    'fieldKey',
                                );
                            }
                        };

                        return (
                            <List.Item
                                onClick={handleClick}
                                style={{
                                    color: isHighlighted ? 'red' : null,
                                }}
                            >
                                <Icon name="i cursor" /> {field}
                            </List.Item>
                        );
                    })}
                </List>
            </span>
        );
    },
};

export const ModelInstanceLabel = ({
    modelInstance,
    modelName,
}: ModelInstanceLabelProps) => {
    const modelLabelComponent = modelLabelComponents[modelName];

    if (!modelLabelComponent) return modelInstance.label;

    return <>{modelLabelComponent(modelInstance)}</>;
};
