import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import { ExecutionContextConsumer } from '../../context/ExecutionContext';
import { Container, Menu } from 'semantic-ui-react';
import DashboardContainer from '../dashboard/DashboardContainer';
import { NavLink } from 'react-router-dom';

class ExecutionReports extends Component {
    componentDidMount() {
        this.redirect();
    }

    componentDidUpdate() {
        this.redirect();
    }

    redirect = () => {
        const execution = this.props.execution || this.props.currentExecution;
        const { programme } = execution;
        const reportsAvailable = _.get(programme, 'options.reports') || [];
        if (
            reportsAvailable.length === 0 ||
            this.props.match.params.dashboardId
        )
            return;

        this.props.history.replace(
            `/execution/${execution.id}/reports/${reportsAvailable[0].id}`,
        );
    };

    render() {
        const execution = this.props.execution || this.props.currentExecution;
        const isExecutionLoading = this.props.isExecutionLoading;
        const { programme } = execution;

        const reportsAvailable = _.get(programme, 'options.reports') || [];
        const chosenReport = _.get(this.props, 'match.params.dashboardId');

        if (!execution.id || isExecutionLoading) return null;

        const entityIds = execution?.entities?.map((entity) => entity.id);

        return (
            <Container>
                <Menu fluid pointing className="wrapping hide-for-print">
                    {reportsAvailable.map((report, index) => {
                        return (
                            <Menu.Item
                                key={index}
                                as={NavLink}
                                to={`/execution/${execution.id}/reports/${report.id}`}
                            >
                                {report.label}
                            </Menu.Item>
                        );
                    })}
                </Menu>
                {chosenReport && (
                    <React.Fragment>
                        <DashboardContainer
                            id={chosenReport}
                            initialProperties={{
                                'relationFilter.wheres.execution': [
                                    { id: execution.id },
                                ],
                                'filter.where.executionId': execution.id,
                                ExecutionId: execution.id,
                                currentAndPreviousExecution: [
                                    execution.id,
                                    execution.previousExecutionId,
                                ],
                                executionId: execution.id,
                                relationshipIds: _.get(
                                    execution,
                                    'filter.assignedPartyRelationship.partyId',
                                ),
                                execution,
                                entityIds,
                                entityId:
                                    entityIds && entityIds.length === 1
                                        ? entityIds[0]
                                        : null,
                            }}
                            forceChartRefresh={true}
                            extraData={[
                                {
                                    key: 'relationships',
                                    data: execution.relationships,
                                },
                            ]}
                        />
                    </React.Fragment>
                )}
            </Container>
        );
    }
}

ExecutionReports.propTypes = {
    execution: PropTypes.object,
    currentExecution: PropTypes.object,
    executionActions: PropTypes.object,
    match: PropTypes.object,
    history: PropTypes.object,
    isExecutionLoading: PropTypes.bool,
};

export default ExecutionContextConsumer(ExecutionReports);
