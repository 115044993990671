import React, { useState, useEffect } from 'react';
import * as _ from 'lodash';
// import { MODAL_COMPONENTS } from './ModalRoot'; // Ensure the path is correct
import * as paginationFiltersUtils from '@brallum/vidende/src/utils/PaginationFilters';

export interface PaginationOptions {
    activePage: number;
    recordsPerPage: number;
    totalPages?: number;
    sortedBy: string | null;
    direction: 'ascending' | 'descending';
    hiddenColumns?: string[];
    onShowFilterModal?: any;
}

interface FilterAndPaginateProps {
    stateFilters?: any;
    path: string;
}

interface MapOptions {
    items: any[];
}

function FilterAndPaginate<T>(mapOptions: (props: T) => MapOptions) {
    return (ComponentToWrap: React.ComponentType<any>) => {
        const FilterAndPaginateComponent: React.FC<
            T & FilterAndPaginateProps & PaginationOptions
        > = (props) => {
            const [paginationOptions, setPaginationOptions] =
                useState<PaginationOptions>({
                    activePage: 1,
                    recordsPerPage: props.recordsPerPage || 20,
                    sortedBy: null,
                    direction: 'ascending',
                    hiddenColumns: props.hiddenColumns || [],
                });
            const [items, setItems] = useState<any[]>([]);
            const [sortedItems, setSortedItems] = useState<any[]>([]);
            const [filteredItems, setFilteredItems] = useState<any[]>([]);
            const [paginatedItems, setPaginatedItems] = useState<any[]>([]);
            const [filters, setFilters] = useState<any[]>([]);

            useEffect(() => {
                const options = mapOptions(props);
                const newStateUpdates = getNewState(
                    props,
                    {
                        paginationOptions,
                        items,
                        sortedItems,
                        filteredItems,
                        filters,
                    },
                    options,
                );

                if (
                    newStateUpdates.paginationOptions.totalPages !==
                    paginationOptions.totalPages
                ) {
                    setPaginationOptions(newStateUpdates.paginationOptions);
                }

                if (newStateUpdates.items) {
                    setItems(newStateUpdates.items);
                }

                if (newStateUpdates.sortedItems) {
                    setSortedItems(newStateUpdates.sortedItems);
                }

                if (newStateUpdates.filteredItems) {
                    setFilteredItems(newStateUpdates.filteredItems);
                }

                if (newStateUpdates.paginatedItems) {
                    setPaginatedItems(newStateUpdates.paginatedItems);
                }
            }, [filters, paginationOptions]);

            useEffect(() => {
                const newState = getNewState(
                    props,
                    {
                        items,
                        sortedItems,
                        filteredItems,
                        paginationOptions,
                        filters,
                    },
                    {
                        ...mapOptions(props),
                    },
                );

                setItems(newState.items);
                setSortedItems(newState.sortedItems);
                setFilteredItems(newState.filteredItems);
                setPaginationOptions(newState.paginationOptions);
                setPaginatedItems(newState.paginatedItems);
            }, [props]);

            const handleSortChange = (clickedColumn) => {
                const newPaginationOptions =
                    paginationFiltersUtils.generateNewPaginationOptions(
                        paginationOptions,
                        clickedColumn,
                    );

                setPaginationOptions(newPaginationOptions);
            };

            const handlePaginationChange = (_event, data) => {
                const newPaginationOptions = {
                    ...paginationOptions,
                    activePage: data.activePage,
                };
                setPaginationOptions(newPaginationOptions);
            };

            const handleAddFilter = (
                property,
                friendlyName,
                isSearchable,
                isFilterable,
            ) => {
                let values = [];
                const propertyIsPresent = _.some(
                    _.map(items, (item) => {
                        const foundProperty =
                            paginationFiltersUtils.recursiveGet(item, property);
                        return typeof foundProperty === 'number'
                            ? foundProperty.toString()
                            : foundProperty;
                    }),
                );

                if (propertyIsPresent) {
                    const itemWithProperty = _.find(items, (item) =>
                        paginationFiltersUtils.recursiveGet(item, property),
                    );
                    const isObject = _.isObject(
                        paginationFiltersUtils.recursiveGet(
                            itemWithProperty,
                            property,
                        ),
                    );
                    const uniqueValues = _.uniqBy(items, (item) => {
                        const value = paginationFiltersUtils.recursiveGet(
                            item,
                            isObject ? `${property}.label` : property,
                        );
                        return isObject ? value.label : value;
                    });
                    values = _.map(uniqueValues, (item) => {
                        const label = paginationFiltersUtils.recursiveGet(
                            item,
                            isObject ? `${property}.label` : property,
                        );
                        const id = isObject
                            ? paginationFiltersUtils.recursiveGet(
                                  item,
                                  `${property}.id`,
                              )
                            : null;
                        let value = label;
                        if (id) value = { id, label };
                        return value;
                    });
                }

                props.onShowFilterModal({
                    property,
                    friendlyName,
                    values,
                    filters,
                    applyFilter,
                    resetFilter,
                    isSearchable,
                    isFilterable,
                });
            };

            const applyFilter = (newFilters, property) => {
                const updatedFilters = paginationFiltersUtils.addNewFilter(
                    filters,
                    newFilters,
                    property,
                );
                setFilters(updatedFilters);
                setPaginationOptions(resetPagination());
            };

            const resetFilter = () => {
                setFilters([]);
                setPaginationOptions(resetPagination());
            };

            const removeFilterValues = (property) => {
                const activeFilters = _.filter(filters, { property: property });
                const activeFiltersLength = activeFilters.length;
                if (activeFiltersLength)
                    activeFiltersLength === filters.length
                        ? resetFilter()
                        : removeFilters(property);
            };

            const removeFilters = (property) => {
                const updatedFilters = _.filter(
                    filters,
                    (filterValue) => filterValue.property !== property,
                );
                setFilters(updatedFilters);
                setPaginationOptions(resetPagination());
            };

            const resetPagination = () => {
                const updatedPaginationOptions = { ...paginationOptions };
                updatedPaginationOptions.activePage = 1;
                return updatedPaginationOptions;
            };

            return (
                <ComponentToWrap
                    handleSortChange={handleSortChange}
                    handleAddFilter={handleAddFilter}
                    handlePaginationChange={handlePaginationChange}
                    currentFilters={filters}
                    removeFilterValues={removeFilterValues}
                    {...props}
                    items={items}
                    sortedItems={sortedItems}
                    filteredItems={filteredItems}
                    paginationOptions={paginationOptions}
                    paginatedItems={paginatedItems}
                />
            );
        };

        return FilterAndPaginateComponent;
    };
}

export default FilterAndPaginate;

function getNewState(
    props: FilterAndPaginateProps,
    state: {
        paginationOptions: PaginationOptions;
        items: paginationFiltersUtils.Item[];
        sortedItems: paginationFiltersUtils.Item[];
        filteredItems: paginationFiltersUtils.Item[];
        filters: any[];
    },
    options: MapOptions,
) {
    const { paginationOptions, filters } = state;
    const { items } = options;
    const filteredItems = paginationFiltersUtils.applyFilter(filters, items);
    const sortedItems = paginationOptions.sortedBy
        ? paginationFiltersUtils.sortItems(filteredItems, paginationOptions)
        : filteredItems;
    const paginatedItems = paginationFiltersUtils.applyPagination(
        sortedItems,
        paginationOptions,
    );

    const totalPages = sortedItems
        ? Math.ceil(sortedItems.length / paginationOptions.recordsPerPage)
        : 0;

    return {
        ...options,
        sortedItems,
        paginationOptions: {
            ...paginationOptions,
            totalPages,
        },
        filteredItems,
        paginatedItems,
    };
}
