import React from 'react';
import * as PropTypes from 'prop-types';
import { PartiesWrapContext } from '../../context/PartiesContext';
import { Table } from 'semantic-ui-react';
import PartiesListItem from './PartiesListItem';
import SortableHeaderCell from '../common/SortableHeaderCell';

const PartiesList = (props) => {
    const {
        parties,
        handleSortChange,
        paginationOptions,
        handleAddFilter,
        currentFilters,
        removeFilterValues,
    } = props;
    return (
        <Table celled sortable>
            <Table.Header>
                <Table.Row>
                    <SortableHeaderCell
                        {...paginationOptions}
                        name="label"
                        handleSortChange={handleSortChange}
                        handleAddFilter={handleAddFilter}
                        currentFilters={currentFilters}
                        removeFilterValues={removeFilterValues}
                        isSearchable={true}
                    >
                        Label
                    </SortableHeaderCell>
                    <SortableHeaderCell
                        {...paginationOptions}
                        name="ref"
                        handleSortChange={handleSortChange}
                        handleAddFilter={handleAddFilter}
                        currentFilters={currentFilters}
                        removeFilterValues={removeFilterValues}
                        isSearchable={true}
                    >
                        Ref
                    </SortableHeaderCell>
                    <SortableHeaderCell
                        {...paginationOptions}
                        name="createdAt"
                        handleSortChange={handleSortChange}
                        handleAddFilter={handleAddFilter}
                        currentFilters={currentFilters}
                        removeFilterValues={removeFilterValues}
                    >
                        Created Date
                    </SortableHeaderCell>
                    <SortableHeaderCell
                        {...paginationOptions}
                        name="creator"
                        handleSortChange={handleSortChange}
                        handleAddFilter={handleAddFilter}
                        currentFilters={currentFilters}
                        removeFilterValues={removeFilterValues}
                    >
                        Created By
                    </SortableHeaderCell>
                    <SortableHeaderCell
                        {...paginationOptions}
                        name="groupLabel"
                        handleSortChange={handleSortChange}
                        handleAddFilter={handleAddFilter}
                        currentFilters={currentFilters}
                        removeFilterValues={removeFilterValues}
                    >
                        Group
                    </SortableHeaderCell>
                    <SortableHeaderCell
                        {...paginationOptions}
                        name="status"
                        handleSortChange={handleSortChange}
                        handleAddFilter={handleAddFilter}
                        currentFilters={currentFilters}
                        removeFilterValues={removeFilterValues}
                    >
                        Active
                    </SortableHeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {parties.map((party, index) => (
                    <PartiesListItem party={party} key={index} />
                ))}
            </Table.Body>
        </Table>
    );
};

PartiesList.propTypes = {
    parties: PropTypes.array,
    handleSortChange: PropTypes.func,
    paginationOptions: PropTypes.object,
    handleAddFilter: PropTypes.func,
    currentFilters: PropTypes.array,
    removeFilterValues: PropTypes.func,
};

export default PartiesWrapContext(PartiesList);
