import * as types from '../constants/actionTypes';
import _ from 'lodash';
import update from 'immutability-helper/index';
import { checkifEdited } from '../utils/React';
import initialState from './initialState';
import UIDGenerator from '../utils/UIDGenerator';
import * as formBuilderHelper from './helpers/formBuilderHelper';

export default function createWorkflowReducer(name = '') {
    return function workflowReducer(
        state = Object.assign({}, initialState.workflow, { __type: name }),
        action,
    ) {
        switch (action.type) {
            case `${types.SET_WORKFLOW}_${name}`: {
                const { workflow } = action;
                return { ...workflow, __type: name };
            }

            case `${types.UPDATE_WORKFLOW}_${name}`: {
                const { name, value } = action;
                let newState = update(state, {
                    [name]: { $set: value },
                });
                return checkifEdited(newState, state);
            }

            case `${types.WORKFLOW_ADD_STATUS}_${name}`: {
                const { value } = action;
                const statusUid = UIDGenerator.get();
                let newState = update(state, {
                    statuses: {
                        $push: [
                            Object.assign(
                                { uid: statusUid },
                                initialState.workflowStatus,
                                value,
                            ),
                        ],
                    },
                    statusNodes: {
                        $push: [
                            Object.assign(
                                { uid: UIDGenerator.get(), statusUid },
                                initialState.workflowStatusNode,
                            ),
                        ],
                    },
                });
                return checkifEdited(newState, state);
            }
            case `${types.WORKFLOW_REMOVE_STATUS}_${name}`: {
                const { status } = action;
                const statusIndex = _.findIndex(state.statuses, {
                    uid: status.uid,
                });
                let newState = update(state, {
                    statuses: { $splice: [[statusIndex, 1]] },
                });
                return checkifEdited(newState, state);
            }
            case `${types.WORKFLOW_UPDATE_STATUS}_${name}`: {
                const { status, name, value } = action;
                const statusIndex = _.findIndex(state.statuses, {
                    uid: status.uid,
                });

                let newState = update(state, {
                    statuses: {
                        [statusIndex]: {
                            [name]: {
                                $set: value,
                            },
                        },
                    },
                });

                //Clear resolutions if "Add Resolution" is unchecked
                if (name === 'addResolution' && value === false) {
                    newState = update(newState, {
                        statuses: {
                            [statusIndex]: {
                                resolutions: {
                                    $set: [],
                                },
                            },
                        },
                    });
                }

                return checkifEdited(newState, state);
            }

            case `${types.ADD_WORKFLOW_STATUS_FIELD}_${name}`: {
                const { status, fieldType, label, options } = action;
                const statusIndex = _.findIndex(state.statuses, {
                    uid: status.uid,
                });

                const newState = update(state, {
                    statuses: {
                        [statusIndex]: {
                            fields: {
                                $set: formBuilderHelper.addField(
                                    state.statuses[statusIndex].fields,
                                    fieldType,
                                    label,
                                    options,
                                ),
                            },
                        },
                    },
                });

                return checkifEdited(newState, state);
            }

            case `${types.UPDATE_WORKFLOW_STATUS_FIELD}_${name}`: {
                const { status, fieldIndex, value } = action;

                const statusIndex = _.findIndex(state.statuses, {
                    uid: status.uid,
                });
                if (statusIndex === -1) return state;

                const newState = update(state, {
                    statuses: {
                        [statusIndex]: {
                            fields: {
                                $set: formBuilderHelper.updateField(
                                    state.statuses[statusIndex].fields,
                                    fieldIndex,
                                    value,
                                ),
                            },
                        },
                    },
                });

                return checkifEdited(newState, state);
            }

            case `${types.REMOVE_WORKFLOW_STATUS_FIELD}_${name}`: {
                const { status, fieldIndex } = action;

                const statusIndex = _.findIndex(state.statuses, {
                    uid: status.uid,
                });

                const newState = update(state, {
                    statuses: {
                        [statusIndex]: {
                            fields: {
                                $set: formBuilderHelper.removeField(
                                    state.statuses[statusIndex].fields,
                                    fieldIndex,
                                ),
                            },
                        },
                    },
                });

                return checkifEdited(newState, state);
            }

            case `${types.WORKFLOW_ADD_STATUS_NODE}_${name}`: {
                const { value } = action;
                let newState = update(state, {
                    statusNodes: {
                        $push: [
                            Object.assign(
                                { uid: UIDGenerator.get() },
                                initialState.workflowStatusNode,
                                value,
                            ),
                        ],
                    },
                });
                return checkifEdited(newState, state);
            }
            case `${types.WORKFLOW_REMOVE_STATUS_NODE}_${name}`: {
                const { statusNode } = action;
                const index = _.findIndex(state.statusNodes, {
                    uid: statusNode.uid,
                });
                let newState = update(state, {
                    statusNodes: { $splice: [[index, 1]] },
                });
                return checkifEdited(newState, state);
            }
            case `${types.WORKFLOW_UPDATE_STATUS_NODE}_${name}`: {
                const { statusNode, name, value } = action;
                const index = _.findIndex(state.statusNodes, {
                    uid: statusNode.uid,
                });
                let newState = update(state, {
                    statusNodes: {
                        [index]: {
                            [name]: {
                                $set: value,
                            },
                        },
                    },
                });
                return checkifEdited(newState, state);
            }

            case `${types.WORKFLOW_ADD_STATUS_RESOLUTION}_${name}`: {
                const { status, resolutionType } = action;

                const index = _.findIndex(state.statuses, { uid: status.uid });

                const newResolution = {
                    activityResolutionTypeId: resolutionType.id,
                    labelOverride: '',
                    note: '',
                    resolutionType: Object.assign({}, resolutionType),
                };

                let newState = update(state, {
                    statuses: {
                        [index]: {
                            resolutions: {
                                $push: [newResolution],
                            },
                        },
                    },
                });
                return checkifEdited(newState, state);
            }
            case `${types.WORKFLOW_REMOVE_STATUS_RESOLUTION}_${name}`: {
                const { status, resolutionType } = action;

                const index = _.findIndex(state.statuses, { uid: status.uid });
                const resolutionIndex = _.findIndex(
                    state.statuses[index].resolutions,
                    {
                        activityResolutionTypeId: resolutionType.id,
                    },
                );

                let newState = update(state, {
                    statuses: {
                        [index]: {
                            resolutions: { $splice: [[resolutionIndex, 1]] },
                        },
                    },
                });
                return checkifEdited(newState, state);
            }
            case `${types.WORKFLOW_UPDATE_STATUS_RESOLUTION}_${name}`: {
                const { status, resolutionType, value } = action;

                const index = _.findIndex(state.statuses, { uid: status.uid });
                const resolutionIndex = _.findIndex(
                    state.statuses[index].resolutions,
                    {
                        activityResolutionTypeId: resolutionType.id,
                    },
                );
                const oldValue =
                    state.statuses[index].resolutions[resolutionIndex];

                let newState = update(state, {
                    statuses: {
                        [index]: {
                            resolutions: {
                                [resolutionIndex]: {
                                    $set: Object.assign({}, oldValue, value),
                                },
                            },
                        },
                    },
                });
                return checkifEdited(newState, state);
            }
            case `${types.WORKFLOW_ADD_STATUS_PERMISSION}_${name}`: {
                // const {value} = action;
                let newState = update(state, {});
                return checkifEdited(newState, state);
            }
            case `${types.WORKFLOW_REMOVE_STATUS_PERMISSION}_${name}`: {
                // const {value} = action;
                let newState = update(state, {});
                return checkifEdited(newState, state);
            }
            case `${types.WORKFLOW_UPDATE_STATUS_PERMISSION}_${name}`: {
                // const {value} = action;
                let newState = update(state, {});
                return checkifEdited(newState, state);
            }
            case `${types.WORKFLOW_ADD_TRANSITION}_${name}`: {
                const { statusNode, value } = action;
                const index = _.findIndex(state.statusNodes, {
                    uid: statusNode.uid,
                });
                let newState = update(state, {
                    statusNodes: {
                        [index]: {
                            transitions: {
                                $push: [
                                    Object.assign(
                                        { uid: UIDGenerator.get() },
                                        initialState.workflowStatusNodeTransition,
                                        value,
                                    ),
                                ],
                            },
                        },
                    },
                });
                return checkifEdited(newState, state);
            }
            case `${types.WORKFLOW_REMOVE_TRANSITION}_${name}`: {
                const { statusNode, transition } = action;
                const statusNodeIndex = _.findIndex(state.statusNodes, {
                    uid: statusNode.uid,
                });
                const transitionIndex = _.findIndex(
                    state.statusNodes[statusNodeIndex].transitions,
                    { uid: transition.uid },
                );
                let newState = update(state, {
                    statusNodes: {
                        [statusNodeIndex]: {
                            transitions: {
                                $splice: [[transitionIndex, 1]],
                            },
                        },
                    },
                });
                return checkifEdited(newState, state);
            }
            case `${types.WORKFLOW_UPDATE_TRANSITION}_${name}`: {
                const { statusNode, transition, name, value } = action;
                const statusNodeIndex = _.findIndex(state.statusNodes, {
                    uid: statusNode.uid,
                });
                const transitionIndex = _.findIndex(
                    state.statusNodes[statusNodeIndex].transitions,
                    { uid: transition.uid },
                );

                let newState = update(state, {
                    statusNodes: {
                        [statusNodeIndex]: {
                            transitions: {
                                [transitionIndex]: {
                                    [name]: {
                                        $set: value,
                                    },
                                },
                            },
                        },
                    },
                });
                return checkifEdited(newState, state);
            }
            case `${types.WORKFLOW_ADD_TRANSITION_PERMISSION}_${name}`: {
                // const {value} = action;
                let newState = update(state, {});
                return checkifEdited(newState, state);
            }
            case `${types.WORKFLOW_REMOVE_TRANSITION_PERMISSION}_${name}`: {
                // const {value} = action;
                let newState = update(state, {});
                return checkifEdited(newState, state);
            }
            case `${types.WORKFLOW_UPDATE_TRANSITION_PERMISSION}_${name}`: {
                // const {value} = action;
                let newState = update(state, {});
                return checkifEdited(newState, state);
            }

            default:
                return state;
        }
    };
}
