import React from 'react';
import * as PropTypes from 'prop-types';
import { ProgrammeContextConsumer } from '../../context/ProgrammeContext';
import { Card } from 'semantic-ui-react';
import ProgrammeListItem from './ProgrammeListItem';

const ProgrammeList = (props) => {
    const { programmes, onSelect, itemsPerRow = 3 } = props;
    return (
        <Card.Group itemsPerRow={itemsPerRow}>
            {programmes.map((programme, index) => (
                <ProgrammeListItem
                    key={index}
                    programme={programme}
                    onSelect={onSelect}
                />
            ))}
        </Card.Group>
    );
};

ProgrammeList.propTypes = {
    programmes: PropTypes.array,
    onSelect: PropTypes.func,
    itemsPerRow: PropTypes.number,
};

export default ProgrammeList;

export const WithContext = ProgrammeContextConsumer(ProgrammeList);
