import { FC, useEffect, useState } from 'react';
import { Button, Dimmer, Form, Loader, Modal, Select } from 'semantic-ui-react';
import * as uiActions from '../../actions/uiActions';
import { useDispatch } from 'react-redux';
import ServerConfigurationApi, {
    LookUpType,
    LookUpValue,
} from '../../api/serverConfigurationApi';
import Text from '../common/fields/Text';
import TextArea from '../common/fields/TextArea';
import toastr from 'toastr';

interface SaveLookUpValueModalProps {
    item: LookUpValue;
    onSuccess: () => void;
}

const SaveLookUpValueModal: FC<SaveLookUpValueModalProps> = ({
    item,
    onSuccess,
}) => {
    const [isSaving, setIsSaving] = useState(false);
    const [formData, setFormData] = useState<LookUpValue>({
        id: item?.id || null,
        color: item?.color || '',
        deleted: item?.deleted || false,
        lookUpTypeId: item?.lookUpTypeId || null,
        lookUpValueCode: item?.lookUpValueCode || '',
        lookUpValueDescription: item?.lookUpValueDescription || '',
        lookUpValueMinimumLevel: item?.lookUpValueMinimumLevel || null,
        lookUpValueName: item?.lookUpValueName || '',
        lookUpValueOrder: item?.lookUpValueOrder || null,
        lookUpValueWeight: item?.lookUpValueWeight || null,
    });
    const dispatch = useDispatch();

    const fetchLookUpTypes = async () => {
        setIsSaving(true);
        try {
            const lookUpData = await ServerConfigurationApi.getLookUpTypes();
            setLookUpTypes(lookUpData);
            setIsSaving(false);
        } catch (error) {
            toastr.error(error);
            setIsSaving(false);
        }
    };

    const [lookUpTypes, setLookUpTypes] = useState<LookUpType[]>([]);

    useEffect(() => {
        fetchLookUpTypes();
    }, []);

    const handleClose = () => {
        dispatch(uiActions.closeModal());
    };

    const handleSaveEntity = async () => {
        try {
            setIsSaving(true);
            await ServerConfigurationApi.updateLookUpValue(
                item ? { ...formData, id: item.id } : formData,
            );
            toastr.success('Look up type saved');
            onSuccess();
            handleClose();
            setIsSaving(false);
        } catch (error) {
            toastr.error(error);
            setIsSaving(false);
        }
    };

    const handleInputChanged = (event, { name, value, checked }) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const lookUpTypesOptions = lookUpTypes.map((item) => ({
        key: item.id,
        value: item.id,
        text: item.lookUpTypeLabel,
    }));

    return (
        <Modal
            open={true}
            onClose={handleClose}
            size="small"
            closeOnDimmerClick={false}
        >
            <Dimmer active={isSaving} inverted>
                <Loader disabled={!isSaving} />
            </Dimmer>
            <Modal.Header>
                {item
                    ? `Update ${item.lookUpValueName}`
                    : 'Create new look up type'}
            </Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Field
                        control={Text}
                        label="Name"
                        value={formData.lookUpValueName}
                        onChange={handleInputChanged}
                        name="lookUpValueName"
                    ></Form.Field>
                    <Form.Field
                        control={Select}
                        label="Look up type"
                        value={formData.lookUpTypeId}
                        options={lookUpTypesOptions}
                        onChange={handleInputChanged}
                        fluid
                        name="lookUpTypeId"
                    ></Form.Field>
                    <Form.Field
                        control={Text}
                        label="Code"
                        value={formData.lookUpValueCode}
                        onChange={handleInputChanged}
                        name="lookUpValueCode"
                    ></Form.Field>
                    <Form.Field
                        control={TextArea}
                        label="Description"
                        value={formData.lookUpValueDescription}
                        onChange={handleInputChanged}
                        name="lookUpValueDescription"
                    ></Form.Field>
                    <Form.Field
                        control={Text}
                        label="Minimum Level"
                        value={formData.lookUpValueMinimumLevel}
                        onChange={handleInputChanged}
                        name="lookUpValueMinimumLevel"
                    ></Form.Field>
                    <Form.Field
                        control={Text}
                        label="Order"
                        value={formData.lookUpValueOrder}
                        onChange={handleInputChanged}
                        name="lookUpValueOrder"
                    ></Form.Field>
                    <Form.Field
                        control={Text}
                        label="Weight"
                        value={formData.lookUpValueWeight}
                        onChange={handleInputChanged}
                        name="lookUpValueWeight"
                    ></Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button positive onClick={handleSaveEntity}>
                    {item ? 'Update' : 'Save'}
                </Button>
                <Button secondary onClick={handleClose}>
                    Cancel
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default SaveLookUpValueModal;
