import React from 'react';
import * as PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

import UserListItem from './UserListItem';
import { PartyWrapContext } from '../../context/PartyContext';
import SortableHeaderCell from '../common/SortableHeaderCell';
import ConfirmWrapper from './../common/ConfirmWrapper';
import * as permissionTypes from '../../constants/permissionTypes';
import CheckPermission from '../common/CheckPermission';

const UserList = (props) => {
    const {
        users,
        handleSortChange,
        handleAddFilter,
        paginationOptions,
        partyActions,
        userStateActions,
        confirmPopupActions,
        party,
        userIsAdmin,
        includeParty,
        loadUsers,
    } = props;

    const { openSaveUserModal } = partyActions;
    const { resetPassword, saveUser } = userStateActions;
    const { showConfirmDialog } = confirmPopupActions;
    const renderUserList = () => {
        return users.map((user, index) => {
            return (
                <UserListItem
                    user={user}
                    key={index}
                    editUser={openSaveUserModal}
                    resetPassword={resetPassword}
                    saveUser={saveUser}
                    loadUsers={loadUsers}
                    showConfirmDialog={showConfirmDialog}
                    structuralNodeId={party.structuralNodeId}
                    userIsAdmin={userIsAdmin}
                    includeParty={includeParty}
                />
            );
        });
    };
    return (
        <Table celled sortable>
            <Table.Header>
                <Table.Row>
                    <SortableHeaderCell
                        {...paginationOptions}
                        name="firstName"
                        handleSortChange={handleSortChange}
                        handleAddFilter={handleAddFilter}
                        isSearchable={true}
                        isFilterable={false}
                    >
                        First Name
                    </SortableHeaderCell>
                    <SortableHeaderCell
                        {...paginationOptions}
                        name="lastName"
                        handleSortChange={handleSortChange}
                        handleAddFilter={handleAddFilter}
                        isSearchable={true}
                        isFilterable={false}
                    >
                        Last Name
                    </SortableHeaderCell>
                    <SortableHeaderCell
                        {...paginationOptions}
                        name="email"
                        handleSortChange={handleSortChange}
                        handleAddFilter={handleAddFilter}
                        isSearchable={true}
                        isFilterable={false}
                    >
                        Email
                    </SortableHeaderCell>
                    {includeParty && (
                        <SortableHeaderCell
                            {...paginationOptions}
                            name="party.label"
                            handleSortChange={handleSortChange}
                            handleAddFilter={handleAddFilter}
                        >
                            Party
                        </SortableHeaderCell>
                    )}
                    <CheckPermission
                        structuralNodeId={party.structuralNodeId}
                        permissionType={permissionTypes.ADMIN}
                        override={userIsAdmin}
                    >
                        <SortableHeaderCell
                            {...paginationOptions}
                            name="loginDetails.lastSuccessfulLogin"
                            handleSortChange={handleSortChange}
                        >
                            Last Successful Login
                        </SortableHeaderCell>
                        <SortableHeaderCell
                            {...paginationOptions}
                            name="loginDetails.locked"
                            handleSortChange={handleSortChange}
                            isSearchable={true}
                        >
                            Locked
                        </SortableHeaderCell>
                        <SortableHeaderCell
                            {...paginationOptions}
                            name="inactive"
                            handleSortChange={handleSortChange}
                            isSearchable={true}
                        >
                            Active
                        </SortableHeaderCell>
                        <SortableHeaderCell
                            {...paginationOptions}
                            name="receivesEmails"
                            handleSortChange={handleSortChange}
                            isSearchable={true}
                        >
                            Receives emails
                        </SortableHeaderCell>
                        <Table.HeaderCell>Actions</Table.HeaderCell>
                    </CheckPermission>
                </Table.Row>
            </Table.Header>
            <Table.Body>{renderUserList()}</Table.Body>
        </Table>
    );
};

UserList.propTypes = {
    users: PropTypes.array.isRequired,
    compact: PropTypes.bool,
    handleSortChange: PropTypes.func,
    handleAddFilter: PropTypes.func,
    paginationOptions: PropTypes.object,
    partyActions: PropTypes.object,
    confirmPopupActions: PropTypes.object,
    userStateActions: PropTypes.object,
    party: PropTypes.object,
    userIsAdmin: PropTypes.bool,
    includeParty: PropTypes.bool,
};

export default PartyWrapContext(ConfirmWrapper(UserList));
