import { RootState } from '../actions/store';
import configureStore from './configureStore';

let store = undefined;

export default {
    init(cb) {
        store = configureStore({}, cb);
    },
    getStore() {
        return store as {
            store: {
                getState: () => RootState;
            };
        };
    },
};
