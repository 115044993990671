import React from 'react';
import * as PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import { connect } from 'react-redux';
import * as _ from 'lodash';

import Select from '../fields/Select';
import UserSearch from '../search/UserSearch';

import './EventListFilters.scss';

const EventListFilters = ({
    filters,
    handleFilterChanged,
    targetModels,
    eventTypes,
}) => {
    const { eventTypeId = null, userId = null, targetModel = null } = filters;

    const targetModelOptions = _.keys(targetModels);

    const eventTypesOptions = _.map(eventTypes, (eventType) => {
        const { id } = eventType;
        const name = _.upperFirst(_.lowerCase(_.startCase(id)));

        return {
            id,
            name,
        };
    });

    return (
        <React.Fragment>
            <Form size="small">
                <Form.Group>
                    <Select
                        options={eventTypesOptions}
                        label="Event type"
                        name="eventTypeId"
                        value={eventTypeId}
                        onChange={handleFilterChanged}
                        clearable={true}
                    />
                    <Form.Field className="select-user-filter">
                        <label>User</label>
                        <Form.Input>
                            <UserSearch
                                onChange={handleFilterChanged}
                                value={userId}
                                name="userId"
                                sortItemsAlphabetically={true}
                                clearable={true}
                            />
                        </Form.Input>
                    </Form.Field>
                    <Select
                        options={targetModelOptions}
                        label="Target Model"
                        name="targetModel"
                        value={targetModel}
                        onChange={handleFilterChanged}
                        clearable={true}
                    />
                </Form.Group>
            </Form>
        </React.Fragment>
    );
};

EventListFilters.propTypes = {
    filters: PropTypes.object,
    handleFilterChanged: PropTypes.func,
    targetModels: PropTypes.object,
    handleClearFilterClicked: PropTypes.func,
    eventTypes: PropTypes.array,
};

function mapStateToProps(state) {
    return {
        eventTypes: state.constants.eventTypes,
        targetModels: state.constants.modelStructure,
    };
}

export default connect(mapStateToProps)(EventListFilters);
