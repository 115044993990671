import { useDispatch, useSelector } from 'react-redux';
import type { TypedUseSelectorHook } from 'react-redux';
import {
    CurrentUser,
    Party,
    ExecutionTicketExtended,
    ExecutionExtended,
    ExecutionPlan,
    PartyRelationshipType,
    Programme,
    ActivityFieldType,
    PrincipalRole,
    RoleDefinition,
} from '../types';
import { FairValueState } from '../reducers/fairValueReducer';
import { EntityState } from '../reducers/entityReducer';
import { StatusState } from '../reducers/statusReducer';
import { PartiesState } from '../reducers/partiesReducer';
import { ModelStructure } from '../modelStrutureTypes';
import { DistributorInfosState } from '../reducers/distributorInfosReducer';

export interface RootState {
    parties: PartiesState;
    party?: Party;
    distributorInfos?: DistributorInfosState;
    currentUser: CurrentUser;
    tickets: {
        followUpList: any[];
        list: any[];
        byId: { [key: string]: ExecutionTicketExtended };
    };
    executions: ExecutionExtended[];
    executionPlans: ExecutionPlan[];
    programmes: Programme[];
    fairValue: FairValueState;
    entity: EntityState;
    status: StatusState;
    constants: {
        workflowStatusTypes: any;
        partyRelationshipTypes: PartyRelationshipType[];
        activityFieldTypes: ActivityFieldType[];
        modelStructure: ModelStructure;
        roleDefinitions: RoleDefinition[];
        attachmentClassification: { id: string; name: string }[];
        attachmentType: { id: string; name: string }[];
        executionPlanCategory: any;
        options: {
            featureFlags: { [key: string]: boolean };
            supportEmail: string;
            maxFileSize: number;
            executionPlanDefaultGovernanceLabel?: string;
            executionPlanDefaultGovernanceId?: number;
            defaultMyPartyList?: boolean;
        };
    };
    chartData: {
        [key: string]: {
            dataCallsHash: string;
            data: any;
        };
    };
    chartDataByHash: {
        [key: string]: {
            data: any;
        };
    };
}

// Use throughout your app instead of plain `useDispatch` and `useSelector`
// export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
