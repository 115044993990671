import * as types from '../constants/actionTypes';
import update from 'immutability-helper/index';
import _ from 'lodash';

export default function programmesReducer(state = [], action) {
    switch (action.type) {
        case types.LOAD_PROGRAMMES_SUCCESS:
            return action.programmes;
        case types.SAVE_PROGRAMME_SUCCESS: {
            const { programme } = action;
            const programmeIndex = _.findIndex(state, { id: programme.id });
            if (programmeIndex !== -1) {
                const programmes = update(state, {
                    [programmeIndex]: { $set: programme },
                });
                return programmes;
            }

            return state;
        }
        default:
            return state;
    }
}
