import React, { useState, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { Card, Header, Dimmer, Loader } from 'semantic-ui-react';
import moment from 'moment';
import * as _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import HighchartsReact from '../charts/ReactHighcharts';
import { colors } from '../../../../../packages/constants';

import TicketWrapper from '../ticket/Ticket';
import WorkflowWrapper from '../workflow/Workflow';
import { TicketContextConsumer } from '../../context/TicketContext';
import { ExecutionContextConsumer } from '../../context/ExecutionContext';
import { history } from '../../store/configureStore';
import { getTicketsFromExectutionToLoad } from '../../utils/TicketsUtils';

const TYPE_MAP = {
    ticketSet: 'Ticket Set',
    category: 'Category',
    simple: 'Ticket',
};

const ExecutionGantt = ({
    match,
    currentExecution,
    executionStateActions,
    tickets,
    ticketActions,
    workflows,
    workflowActions,
    workflowStatusTypes,
}) => {
    const [loading, setLoading] = useState(false);

    const today = moment();
    const day = 1000 * 60 * 60 * 24;
    const { executionId } = match.params;
    const { id: currentExecutionId } = { ...currentExecution };
    const ticketIds = _.keys(tickets);

    useEffect(() => {
        const { id } = currentExecution;

        if (!id || id != parseInt(executionId)) {
            setLoading(true);
            executionStateActions.loadExecution(executionId, 'CURRENT');
        }
    }, []);

    useEffect(() => {
        if (currentExecution && !tickets.length) {
            loadTickets();
        }
    }, [executionId, currentExecutionId]);

    useEffect(() => {
        if (ticketIds.length) {
            const firstKey = _.first(ticketIds);
            const firstTicket = tickets[firstKey];
            const { workflowId, id } = firstTicket;
            workflowActions.loadWorkflow({
                id: workflowId,
                ticketId: id,
            });
        }
        const executionTickets = getTicketsFromExectutionToLoad(
            currentExecution.ticketSets,
        );
        const isAllTicketsLoaded =
            executionTickets.length === _.keys(tickets).length;
        if (isAllTicketsLoaded) {
            setLoading(false);
        }
    }, [ticketIds.length]);

    const loadTickets = () => {
        setLoading(true);
        const ticketsToLoad = getTicketsFromExectutionToLoad(
            currentExecution.ticketSets,
        );
        const ticketSets = [
            {
                tickets: ticketsToLoad,
            },
        ];
        ticketActions.loadTickets(ticketSets);
    };

    const getWorkflowStatus = (workflowId, workflowStatusId) => {
        const workflowStatuses = workflows.byId[workflowId].statuses;
        const { statusTypeId } = _.find(
            workflowStatuses,
            (workflowStatus) => workflowStatus.id === workflowStatusId,
        );
        const workflowStatus = _.find(workflowStatusTypes, {
            id: statusTypeId,
        });

        return workflowStatus;
    };

    const generateData = (ticket) => {
        const { ticketSets } = currentExecution;
        if (_.isEmpty(workflows.byId)) return [];

        const { id, deadlines, workflowId, label } = ticket;
        const deadlinesLength = deadlines.length;
        const sortedDeadlines = _.sortBy(deadlines, 'date');
        const currentTicketSet = _.find(ticketSets, (ticketSet) => {
            const ticketIds = _.map(ticketSet.tickets, (ticket) => ticket.id);
            return _.some(ticketIds, (ticketId) => ticketId === id);
        });
        const ticketSetLabel = currentTicketSet.label || 'Undef';

        return _.map(sortedDeadlines, (deadline, index) => {
            const { workflowStatusId } = deadline;
            const { colourId, name } = getWorkflowStatus(
                workflowId,
                workflowStatusId,
            );
            const currentDeadline = moment(deadline.deadlineDate).valueOf();
            if (deadlinesLength === 1) {
                return {
                    id,
                    parent: ticketSetLabel,
                    start: currentDeadline,
                    color: colourId,
                    workflowStatus: name,
                    milestone: true,
                    name: label,
                    type: 'simple',
                };
            }
            if (deadlinesLength === index + 1) return;
            const nextDeadline = sortedDeadlines[index + 1];
            const nextDeadlineValue = moment(
                nextDeadline.deadlineDate,
            ).valueOf();
            const nextWorkflowStatusId = nextDeadline.workflowStatusId;
            const { colourId: nextDeadlineColor, name: nextWorkflowStatus } =
                getWorkflowStatus(workflowId, nextWorkflowStatusId);
            return {
                id,
                parent: ticketSetLabel,
                start: currentDeadline,
                end: nextDeadlineValue,
                color: nextDeadlineColor,
                name: label,
                type: 'simple',
                workflowStatus: nextWorkflowStatus,
            };
        });
    };

    const generateTicketSetsData = (ticketSetsData) => {
        const { categories } = currentExecution;
        const res = _.map(ticketSetsData, (ticketSetData) => {
            const parent = categories.find(
                (category) => category.id === ticketSetData.executionCategoryId,
            );
            const { label } = { ...parent };
            return {
                id: ticketSetData.label || 'Undef',
                label: ticketSetData.label || 'Undef',
                name: ticketSetData.label || 'Without ticket set',
                parent: label || 'uncategorised',
                type: 'ticketSet',
                color: colors.purple,
            };
        });
        return res;
    };

    const generateCategoriesData = () => {
        const { categories } = currentExecution;
        const categoriesData = _.map(categories, (category) => ({
            id: category.label,
            label: category.label,
            name: category.label,
            type: 'category',
            color: colors.purple, //'transparent'
        }));
        return [
            ...categoriesData,
            {
                id: 'uncategorised',
                label: 'uncategorised',
                name: 'Uncategorised',
                type: 'category',
                color: colors.purple,
            },
        ];
    };

    const getFormattedData = (data) => {
        const nonEmptyRows = _.filter(
            data,
            (ticketData) => !_.isEmpty(ticketData),
        );

        const categoryRows = _.filter(
            nonEmptyRows,
            (row) => row.type === 'category',
        );
        const ticketSetRows = _.filter(
            nonEmptyRows,
            (row) => row.type === 'ticketSet',
        );
        const simpleRows = _.filter(
            nonEmptyRows,
            (row) => row.type === 'simple',
        );
        const simpleRowParentLabels = _.map(simpleRows, (row) => row.parent);

        const ticketSetWithChilds = _.filter(ticketSetRows, (row) =>
            _.includes(simpleRowParentLabels, row.id),
        );
        const ticketSetWithChildsParentLabels = _.map(
            ticketSetWithChilds,
            (row) => row.parent,
        );
        const categoriesWithChilds = _.filter(categoryRows, (row) =>
            _.includes(ticketSetWithChildsParentLabels, row.id),
        );

        return [...simpleRows, ...ticketSetWithChilds, ...categoriesWithChilds];
    };

    const generateSeries = (tickets) => {
        const { ticketSets } = currentExecution;
        if (!ticketSets || !_.keys(workflows.byId).length) return [];

        const ticketsData = _.map(tickets, (ticket, ticketIndex) => {
            return generateData(ticket, ticketIndex);
        });

        const concatedTicketsData = [].concat(...ticketsData);
        const ticketSetsData = generateTicketSetsData(ticketSets);
        const categoriesData = generateCategoriesData();
        const data = [
            ...concatedTicketsData,
            ...ticketSetsData,
            ...categoriesData,
        ];

        const formattedData = getFormattedData(data);
        return [
            {
                data: formattedData,
                name: 'XYI',
                animation: false, // Do not animate dependency connectors
                dragDrop: {
                    draggableX: false,
                    draggableY: false,
                    dragMinY: 0,
                    dragMaxY: 2,
                    dragPrecisionX: day, // Snap to eight hours
                },
                dataLabels: {
                    enabled: false,
                    format: '{point.name}',
                    style: {
                        cursor: 'default',
                        pointerEvents: 'none',
                    },
                },
            },
        ];
    };

    const generateMinMaxValues = (series) => {
        const first = _.head(series);
        if (first) {
            const array = [];
            const simpleRows = _.filter(
                first.data,
                (item) => item.type === 'simple',
            );
            _.map(simpleRows, ({ start, end }) => {
                if (start) {
                    array.push(start);
                }
                if (end) {
                    array.push(end);
                }
            });
            const minDate = _.min(array);
            const maxDate = _.max(array);
            const minMax = {
                min: moment(minDate).subtract(3, 'days').toDate().valueOf(),
                max: moment(maxDate).add(3, 'days').toDate().valueOf(),
            };
            if (today.isBefore(minDate)) {
                minMax.min = today.subtract(3, 'days').toDate().valueOf();
            }
            if (today.isAfter(maxDate)) {
                minMax.max = today.add(3, 'days').toDate().valueOf();
            }

            return minMax;
        }
        return {};
    };

    if (loading) {
        return (
            <Dimmer active={true} inverted>
                <Loader />
            </Dimmer>
        );
    }

    const currentExecutionTickets = tickets
        ? _.filter(tickets, {
              executionId: currentExecution.id,
          })
        : [];
    const ticketsWithDeadlines = _.filter(
        currentExecutionTickets,
        (currentExecutionTicket) => currentExecutionTicket.deadlines.length > 0,
    );
    const series = generateSeries(ticketsWithDeadlines);
    const minMax = generateMinMaxValues(series);

    return (
        <React.Fragment>
            {!!series.length && (
                <Card.Group itemsPerRow={1}>
                    <Card fluid>
                        <Header as="h4" attached="top" block>
                            Gantt
                        </Header>
                        <Card.Content>
                            <Card.Meta textAlign="center">
                                <HighchartsReact
                                    constructorType={'ganttChart'}
                                    allowChartUpdate={true}
                                    options={{
                                        tooltip: {
                                            useHTML: true,
                                            style: {
                                                // maxWidth: '400px !important',
                                                // fontSize: '20px',
                                                overflow: 'auto',
                                                whiteSpace: 'normal !important',
                                            },
                                            headerFormat: '',
                                            pointFormatter: function () {
                                                const point = this;
                                                const { options, name, type } =
                                                    point;
                                                const {
                                                    workflowStatus,
                                                    x: start,
                                                    x2: end,
                                                } = options;
                                                const startDate =
                                                    moment(start).format(
                                                        'DD MMM YYYY',
                                                    );
                                                const endDate =
                                                    moment(end).format(
                                                        'DD MMM YYYY',
                                                    );
                                                const secondTooltipLine =
                                                    workflowStatus
                                                        ? `<br/><span>Workflow Status: <b>${workflowStatus}</b></span><br/>`
                                                        : '<br/>';
                                                const firstLineTitle =
                                                    TYPE_MAP[type];
                                                const tooltip = `<span>${firstLineTitle}: <b>${name}</b></span><br/>
                                         ${secondTooltipLine}
                                         <span>Start: <b>${startDate}</b></span><br/>
                                         <span>End: <b>${endDate}</b></span>`;
                                                return tooltip;
                                            },
                                        },
                                        series,
                                        xAxis: [
                                            {
                                                currentDateIndicator: true,
                                                ...minMax,
                                            },
                                            {},
                                        ],
                                        rangeSelector: {
                                            enabled: true,
                                            selected: 0,
                                        },
                                        scrollbar: {
                                            enabled: true,
                                        },
                                        yAxis: {
                                            uniqueNames: true,
                                            labels: {
                                                useHTML: true,
                                                style: {
                                                    width: '200px',
                                                    height: '40px',
                                                    overflow: 'hidden',
                                                },
                                                events: {
                                                    click: function () {
                                                        const { value } = this;
                                                        const currentTicket =
                                                            _.find(
                                                                tickets,
                                                                (ticket) =>
                                                                    ticket.label ===
                                                                    value,
                                                            );
                                                        if (currentTicket) {
                                                            const { id } =
                                                                currentTicket;
                                                            history.push(
                                                                `ticket/${id}`,
                                                            );
                                                        }
                                                    },
                                                },
                                            },
                                        },
                                    }}
                                />
                            </Card.Meta>
                        </Card.Content>
                    </Card>
                </Card.Group>
            )}
        </React.Fragment>
    );
};

ExecutionGantt.propTypes = {
    currentExecution: PropTypes.object,
    executionStateActions: PropTypes.object,
    execution: PropTypes.object,
    match: PropTypes.object,
    tickets: PropTypes.object,
    ticketActions: PropTypes.object,
    workflowStatusTypes: PropTypes.array,
    workflowActions: PropTypes.object,
    workflows: PropTypes.object,
};

function mapStateToProps(state) {
    return {
        workflowStatusTypes: state.constants.workflowStatusTypes,
    };
}

export default withRouter(
    ExecutionContextConsumer(
        TicketWrapper(
            TicketContextConsumer(
                WorkflowWrapper(connect(mapStateToProps)(ExecutionGantt)),
            ),
        ),
    ),
);
