import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import OptionsModal from './OptionsModal';
import fieldOptions from './dashboardOptionsFields';
import update from 'immutability-helper/index';

class DashboardItemOptionsModal extends Component {
    state = {
        newOptions: {},
    };

    handleValueChange = ({ name, value }) => {
        this.setState(
            update(this.state, {
                newOptions: {
                    [name]: { $set: value },
                },
            }),
        );
    };

    handleSave = () => {
        const { newOptions } = this.state;
        const { options, onSave } = this.props;

        const optionsToSave = Object.assign({}, options, newOptions);

        onSave(optionsToSave);

        this.setState({ newOptions: {} });
    };

    render() {
        const {
            type = 'column',
            open,
            options,
            closeModal,
            extraFields,
        } = this.props;
        const fields = extraFields
            ? [...extraFields, ...fieldOptions[type]]
            : fieldOptions[type];
        const entries = _.map(
            { ...options, ...this.state.newOptions },
            (value, key) => ({ id: key, value }),
        );
        return (
            <OptionsModal
                onChange={(field) => {
                    this.handleValueChange(field);
                }}
                open={open}
                fields={fields}
                closeModal={closeModal}
                entries={entries}
                onSave={this.handleSave}
            />
        );
    }
}

DashboardItemOptionsModal.propTypes = {
    options: PropTypes.object,
    onSave: PropTypes.func,
    closeModal: PropTypes.func,
    type: PropTypes.string,
    open: PropTypes.bool,
};

export default DashboardItemOptionsModal;
