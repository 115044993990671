import ApiTools from './apiTools';

export interface FeatureFlag {
    code: string;
    label: string;
    description: string;
    value: boolean;
}
export interface ServerConfig {
    config_key: string;
    config_value: string;
    exposeToFrontEnd: boolean;
    description: string;
    label: string;
}

export interface LookUpType {
    id: number;
    lookUpTypeCode: string;
    lookUpTypeLabel: string;
    lookUpTypeDescription: string;
}
export interface LookUpValue {
    id: number;
    lookUpTypeId: number | null;
    lookUpType?: LookUpType;
    lookUpValueCode: string;
    lookUpValueName: string;
    lookUpValueOrder: null | number;
    lookUpValueWeight: null | number;
    lookUpValueMinimumLevel: null | number;
    lookUpValueDescription: null | string;
    color: null | string;
    deleted: boolean;
}

export interface RatingType {
    id: number;
    code: string;
    label: string;
    description: string;
}
export interface RatingValue {
    id: number;
    ratingTypeId: number | null;
    ratingType?: RatingType;
    label: string;
    code: string;
    value: number;
    color: null | string;
    description: null | string;
    deleted: boolean;
}

class ServerConfigurationApi {
    static getRatingTypes(): RatingType[] {
        return ApiTools.get(`RatingTypes`, {
            filter: {
                order: 'label ASC',
            },
        }).then((response: { data: RatingType[] }) => {
            return response.data;
        });
    }
    static updateRatingType(data) {
        return ApiTools.patch(`RatingTypes`, data).then(
            (response: { data }) => {
                return response.data;
            },
        );
    }
    static updateRatingValue(data) {
        return ApiTools.patch(`RatingValues`, data).then(
            (response: { data }) => {
                return response.data;
            },
        );
    }
    static deleteRatingValue(id) {
        return ApiTools.delete(`RatingValues/${id}`).then(
            (response: { data }) => {
                return response.data;
            },
        );
    }
    static getRatingValuesById(id: string): RatingValue[] {
        return ApiTools.get(`RatingValues`, {
            filter: { where: { ratingTypeId: id } },
        }).then((response: { data: RatingValue[] }) => {
            return response.data;
        });
    }
    static getFeatureFlagList(): FeatureFlag[] {
        return ApiTools.get(`FeatureFlags`).then(
            (response: { data: FeatureFlag[] }) => {
                return response.data;
            },
        );
    }
    static getServerConfig(): ServerConfig[] {
        return ApiTools.get(`ServerConfigs`).then(
            (response: { data: ServerConfig[] }) => {
                return response.data;
            },
        );
    }
    static getLookUpTypes(): LookUpType[] {
        return ApiTools.get(`LookUpTypes`, {
            filter: {
                order: 'lookUpTypeLabel ASC',
            },
        }).then((response: { data: LookUpType[] }) => {
            return response.data;
        });
    }
    static updateLookUpType(data) {
        return ApiTools.patch(`LookUpTypes`, data).then(
            (response: { data }) => {
                return response.data;
            },
        );
    }
    static updateLookUpValue(data) {
        console.log('data', data);
        return ApiTools.patch(`LookUpValues`, data).then(
            (response: { data }) => {
                return response.data;
            },
        );
    }
    static deleteLookUpValue(id) {
        return ApiTools.delete(`LookUpValues/${id}`).then(
            (response: { data }) => {
                return response.data;
            },
        );
    }
    static getLookUpValuesById(id: string): LookUpValue[] {
        return ApiTools.get(`LookUpValues`, {
            filter: { where: { lookUpTypeId: id } },
        }).then((response: { data: LookUpValue[] }) => {
            return response.data;
        });
    }

    static saveFeatureFlagList(featureFlagList: FeatureFlag[]) {
        return ApiTools.post(`FeatureFlags/saveFeatureFlagList`, {
            featureFlagList,
        }).then((response) => {
            return response.data;
        });
    }
    static saveServerConfigList(serverConfigList: ServerConfig[]) {
        return ApiTools.post(`ServerConfigs/serverConfigList`, {
            serverConfigList,
        }).then((response) => {
            return response.data;
        });
    }
}

export default ServerConfigurationApi;
