import * as types from '../constants/actionTypes';

export function saveStartRoute(route) {
    return {
        type: `${types.SAVE_START_ROUTE}`,
        route,
    };
}

export function setInitialStartRoute() {
    return {
        type: `${types.SET_INITIAL_START_ROUTE}`,
    };
}
