import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { Container, Header, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import ProgrammeTableWrapper from './ProgrammeTableWrapper';
import ProgrammeWrapper from './Programme';
import ErrorBoundary from './../common/ErrorBoundary';
import CheckPermission from '../common/CheckPermission';
import * as permissionTypes from './../../constants/permissionTypes';

class ProgrammesPage extends Component {
    componentDidMount() {
        this.props.programmeStateActions.loadProgrammes();
    }

    render() {
        return (
            <Container>
                <Header as="h2">Programme Library</Header>
                <ProgrammeTableWrapper />
                <CheckPermission
                    permissionType={permissionTypes.SUPER}
                    structuralNodeId={2}
                >
                    <Button as={Link} to="/programme" primary>
                        Create New Programme
                    </Button>
                </CheckPermission>
            </Container>
        );
    }
}

ProgrammesPage.propTypes = {
    programmeStateActions: PropTypes.object.isRequired,
};

export default ErrorBoundary(ProgrammeWrapper(ProgrammesPage));
