import React from 'react';
import * as PropTypes from 'prop-types';
import { Feed } from 'semantic-ui-react';

import ErrorBoundary from '../ErrorBoundary';
import EventListItemContent from './EventListItemContent';
import { EVENT_ICONS, EVENT_COMPONENTS } from '../../../utils/EventConsts';
import { getTimeAgo } from '../../../utils/CommonUtils';

import './EventListItem.scss';
import EventListComparison from './EventListComparison';

const EventListItem = (props) => {
    const { event, model, showComparison, showLink } = props;
    const { eventTypeId, createdAt, targetModel } = event;
    const icon =
        EVENT_ICONS[eventTypeId] ||
        EVENT_ICONS[eventTypeId + targetModel] ||
        EVENT_ICONS.default;
    const EventComponent = EVENT_COMPONENTS[eventTypeId];
    return (
        event && (
            <Feed.Event className="event-list-item">
                <Feed.Label icon={icon} className="event-list-item-label" />
                <Feed.Content>
                    <EventListItemContent
                        event={event}
                        model={model}
                        showLink={showLink}
                    />
                    <Feed.Meta>{getTimeAgo(createdAt)}</Feed.Meta>
                    {showComparison && (
                        <Feed.Extra>
                            <EventListComparison event={event} model={model} />
                            {EventComponent && (
                                <EventComponent event={event} model={model} />
                            )}
                        </Feed.Extra>
                    )}
                </Feed.Content>
            </Feed.Event>
        )
    );
};

EventListItem.propTypes = {
    event: PropTypes.object,
    model: PropTypes.object,
    showComparison: PropTypes.bool,
    showLink: PropTypes.bool,
};

export default ErrorBoundary(EventListItem);
