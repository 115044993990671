import React from 'react';
import * as PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import FormBuilderField from '../common/FormBuilderField';
import * as _ from 'lodash';

const ActivityFields = (props) => {
    const { activity, activityFieldTypes } = props;
    return (
        <Table compact celled>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Label</Table.HeaderCell>
                    <Table.HeaderCell>Key</Table.HeaderCell>
                    <Table.HeaderCell>Type</Table.HeaderCell>
                    <Table.HeaderCell>Options</Table.HeaderCell>
                    <Table.HeaderCell>Data Field Reference</Table.HeaderCell>
                    <Table.HeaderCell>Display Short Label</Table.HeaderCell>
                    <Table.HeaderCell>Data Field</Table.HeaderCell>
                    <Table.HeaderCell>Required</Table.HeaderCell>
                    {/* <Table.HeaderCell /> */}
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {_.sortBy(
                    activity.fields.map((field, index) =>
                        Object.assign({}, field, { originalIndex: index }),
                    ),
                    ['sort'],
                ).map((field) => (
                    <FormBuilderField
                        key={field.originalIndex}
                        value={field}
                        activityFieldTypes={activityFieldTypes}
                        fieldIndex={field.originalIndex}
                        onChange={() => {}}
                        editable={false}
                    />
                ))}
            </Table.Body>
        </Table>
    );
};

ActivityFields.propTypes = {
    activity: PropTypes.object.isRequired,
    activityFieldTypes: PropTypes.array.isRequired,
};

export default ActivityFields;
