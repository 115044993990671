import React from 'react';
import * as PropTypes from 'prop-types';
import { ProgrammeContextConsumer } from '../../context/ProgrammeContext';
import { Label, Table } from 'semantic-ui-react';
import Moment from 'react-moment';
import * as permissionTypes from '../../constants/permissionTypes';
import CheckPermission from '../common/CheckPermission';
import ProgrammeActionButtons from './ProgrammeActionButtons';

const ProgrammeDetails = (props) => {
    const { programme } = props;

    const handleMenuAction = (event, { name }) => {
        const { currentProgramme } = props;
        switch (name) {
            case 'edit':
                props.programmeActions.editProgramme(currentProgramme);
                break;
            case 'launch':
                this.launchProgramme();
                break;
        }
    };
    return (
        <div>
            <Table compact collapsing basic="very" stackable>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell collapsing verticalAlign="top">
                            Status:
                        </Table.Cell>
                        <Table.Cell verticalAlign="top">
                            <Label color={programme.status.colourId}>
                                {programme.status.label}
                            </Label>
                        </Table.Cell>
                    </Table.Row>
                    {programme.creator && (
                        <Table.Row>
                            <Table.Cell collapsing verticalAlign="top">
                                Creator:
                            </Table.Cell>
                            <Table.Cell verticalAlign="top">
                                <Label as="a" color="grey" image>
                                    {programme.creator.firstName}{' '}
                                    {programme.creator.lastName}
                                    {/*<Label.Detail>Company Name</Label.Detail>*/}
                                </Label>
                            </Table.Cell>
                        </Table.Row>
                    )}
                    {programme.ownerParty && (
                        <Table.Row>
                            <Table.Cell collapsing verticalAlign="top">
                                Owner Party:
                            </Table.Cell>
                            <Table.Cell verticalAlign="top">
                                <Label as="a" color="grey" image>
                                    {programme.ownerParty.label}
                                    {/*<Label.Detail>Company Name</Label.Detail>*/}
                                </Label>
                            </Table.Cell>
                        </Table.Row>
                    )}
                    {programme.ref ? (
                        <Table.Row>
                            <Table.Cell collapsing verticalAlign="top">
                                Reference:
                            </Table.Cell>
                            <Table.Cell verticalAlign="top">
                                {programme.ref}
                            </Table.Cell>
                        </Table.Row>
                    ) : (
                        <React.Fragment />
                    )}
                    <Table.Row>
                        <Table.Cell collapsing verticalAlign="top">
                            Created Date:
                        </Table.Cell>
                        <Table.Cell verticalAlign="top">
                            <Moment>{programme.createdAt}</Moment>
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell collapsing verticalAlign="top">
                            Last Modified:
                        </Table.Cell>
                        <Table.Cell verticalAlign="top">
                            <Moment>{programme.modifiedAt}</Moment>
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>

            <CheckPermission
                permissionType={permissionTypes.CREATE}
                structuralNodeId={programme.structuralNodeId}
            >
                <ProgrammeActionButtons handleMenuAction={handleMenuAction} />
            </CheckPermission>
        </div>
    );
};

ProgrammeDetails.propTypes = {
    programme: PropTypes.object.isRequired,
    programmeActions: PropTypes.object.isRequired,
};

export default ProgrammeContextConsumer(ProgrammeDetails);
