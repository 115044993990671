import { Comment } from 'semantic-ui-react';
import ConversationComment from './ConversationComment';
import { Comment as IComment } from '../../types';

interface ConversationCommentsProps {
    comments: IComment[];
    currentUser: any;
    onEdit: (event: any, comment: any) => void;
    onDelete: (event: any, comment: any, deleted: boolean) => void;
    onReply: (event: any, comment: any) => void;
    readOnly?: boolean;
}

const ConversationComments = ({
    comments,
    currentUser,
    ...restProps
}: ConversationCommentsProps) => {
    if (!comments) return null;
    return (
        <Comment.Group>
            {comments.map((comment) => {
                if (comment?.replies?.length > 0)
                    return (
                        <>
                            <ConversationComment
                                key={comment.id}
                                canEdit={
                                    comment.creatorId === currentUser.details.id
                                }
                                isCurrentUserCommentAuthor={
                                    comment.creatorId === currentUser.details.id
                                }
                                comment={comment}
                                {...restProps}
                            >
                                <ConversationComments
                                    comments={comment.replies}
                                    currentUser={currentUser}
                                    {...restProps}
                                />
                            </ConversationComment>
                        </>
                    );
                return (
                    <ConversationComment
                        key={comment.id}
                        canEdit={comment.creatorId === currentUser.details.id}
                        isCurrentUserCommentAuthor={
                            comment.creatorId === currentUser.details.id
                        }
                        comment={comment}
                        {...restProps}
                    />
                );
            })}
        </Comment.Group>
    );
};

export default ConversationComments;
