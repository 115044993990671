import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as uiActions from '../../actions/uiActions';
import { bindActionCreators } from 'redux';
import * as programmeActions from '../../actions/programmeActions';
import * as activityActions from '../../actions/activityActions';
import ProgrammeContext from '../../context/ProgrammeContext';
import { extractFunctions } from '../../utils/React';
import { Confirm, Dimmer, Loader } from 'semantic-ui-react';
import toastr from 'toastr';
import * as _ from 'lodash';

function ProgrammeWrapper(ComponentToWrap) {
    class Programme extends Component {
        state = {
            deleteConfirmationOpen: false,
            programmeToDelete: {},
            saving: false,
        };

        deleteProgramme = (programme) => {
            this.setState({
                programmeToDelete: programme,
                deleteConfirmationOpen: true,
            });
        };

        cloneProgramme = (programme) => {
            this.props.history.push(`/programme?clone=${programme.id}`);
        };

        editProgramme = (programme) => {
            this.props.history.push(`/programme/${programme.id}/edit`);
        };

        gotoProgramme = (programme) => {
            this.props.history.push(`/programme/${programme.id}`);
        };

        confirmDeleteActivity = () => {
            this.setState({
                saving: true,
                deleteConfirmationOpen: false,
            });

            const newValue = Object.assign({}, this.state.programmeToDelete, {
                programStatusId: _.find(this.props.programmeStatuses, {
                    label: 'DEACTIVATED',
                }).id,
            });

            this.props.programmeStateActions
                .saveProgramme(newValue)
                .then(() => {
                    toastr.success('Programme deleted.');
                    this.setState({ saving: false });
                })
                .catch((error) => {
                    toastr.error(error);
                    this.setState({ saving: false });
                });
        };
        cancelDeleteActivity = () => {
            this.setState({
                deleteConfirmationOpen: false,
            });
        };

        render() {
            const {
                currentProgramme,
                newProgramme,
                programmes,
                programmeStatuses,
                programmeStateActions,
                activityStateActions,
                uiActions,
            } = this.props;
            const values = {
                programme: currentProgramme,
                currentProgramme,
                newProgramme,
                programmes,
                programmeStatuses,
                programmeStateActions: programmeStateActions,
                activityStateActions: activityStateActions,
                programmeActions: extractFunctions(this),
                uiActions,
            };
            return (
                <ProgrammeContext.Provider value={values}>
                    <Dimmer active={this.state.saving} inverted>
                        <Loader disabled={!this.state.saving} />
                    </Dimmer>
                    <ComponentToWrap {...values} {...this.props} />
                    <Confirm
                        open={this.state.deleteConfirmationOpen}
                        content="Are you sure you want to delete this programme?"
                        onConfirm={this.confirmDeleteActivity}
                        onCancel={this.cancelDeleteActivity}
                    />
                </ProgrammeContext.Provider>
            );
        }
    }

    Programme.propTypes = {
        currentProgramme: PropTypes.object.isRequired,
        newProgramme: PropTypes.object.isRequired,
        programmeStateActions: PropTypes.object.isRequired,
        activityStateActions: PropTypes.object.isRequired,
        uiActions: PropTypes.object.isRequired,
        programmes: PropTypes.array.isRequired,
        programmeStatuses: PropTypes.array.isRequired,
        history: PropTypes.object,
    };

    function mapStateToProps(state) {
        return {
            uiStatus: state.uiStatus,
            programmes: state.programmes,
            programmeStatuses: state.constants.programmeStatuses,
            currentProgramme: state.currentProgramme,
            newProgramme: state.newProgramme,
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            programmeStateActions: bindActionCreators(
                programmeActions,
                dispatch,
            ),
            activityStateActions: bindActionCreators(activityActions, dispatch),
            uiActions: bindActionCreators(uiActions, dispatch),
        };
    }
    return connect(mapStateToProps, mapDispatchToProps)(Programme);
}

export default ProgrammeWrapper;
