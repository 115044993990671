/* eslint-disable react/display-name */

import React from 'react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import TicketSet from './TicketSet';
import { Label, Tab, Menu } from 'semantic-ui-react';

const TicketSets = ({
    ticketSets,
    displayAsTabs = false,
    onTicketClick,
    selectedTicketId,
    ticketSetComponent,
    hideTicketButton,
}) => {
    const TicketSetComponent = ticketSetComponent || TicketSet;
    const orderedTicketSets = _.orderBy(ticketSets, [
        'sort',
        'assignedPartyRelationship.label',
    ]);
    if (displayAsTabs) {
        const panes = [
            ...orderedTicketSets.map((ticketSet, index) => {
                return {
                    menuItem: (
                        <Menu.Item key={index}>
                            {ticketSet.activitySet.label}
                            {ticketSet.assignedPartyRelationship && (
                                <span>
                                    <br /> (
                                    {
                                        ticketSet.assignedPartyRelationship
                                            .toParty.label
                                    }{' '}
                                    -{' '}
                                    {
                                        ticketSet.assignedPartyRelationship
                                            .relationshipType.label
                                    }{' '}
                                    -{' '}
                                    {ticketSet.assignedPartyRelationship.label})
                                </span>
                            )}
                            <Label attached="top right" size="mini">
                                {ticketSet.tickets.length}
                            </Label>
                        </Menu.Item>
                    ),
                    render: () => {
                        return (
                            <TicketSetComponent
                                ticketSet={ticketSet}
                                showHeader={false}
                            />
                        );
                    },
                };
            }),
        ];

        return (
            <Tab
                menu={{ fluid: true, pointing: false, vertical: true }}
                panes={panes}
            />
        );
    } else {
        return (
            <React.Fragment>
                {orderedTicketSets.map((ticketSet, index) => (
                    <TicketSetComponent
                        key={index}
                        ticketSet={ticketSet}
                        onTicketClick={onTicketClick}
                        selectedTicketId={selectedTicketId}
                        hideTicketButton={hideTicketButton}
                    />
                ))}
            </React.Fragment>
        );
    }
};

TicketSets.propTypes = {
    ticketSets: PropTypes.array.isRequired,
    onTicketClick: PropTypes.func,
    selectedTicketId: PropTypes.number,
    displayAsTabs: PropTypes.bool,
    ticketSetComponent: PropTypes.func,
};

export default TicketSets;
