import React, { useState } from 'react';
import { Button, Form, Header } from 'semantic-ui-react';

const SetApiPath = () => {
    const override = window.localStorage.getItem('apiPathOverride');

    const [apiPath, setApiPath] = useState(override);

    const handleSubmit = () => {
        if (apiPath.length === 0) {
            window.localStorage.removeItem('apiPathOverride');
            return;
        }
        window.localStorage.setItem('apiPathOverride', apiPath);
    };

    const handleChange = (event, { value }) => {
        setApiPath(value);
    };
    return (
        <React.Fragment>
            <Header as="h2">Set API Path</Header>
            <Form>
                <Form.Group>
                    <Form.Input
                        width={12}
                        value={apiPath}
                        onChange={handleChange}
                    />
                    <Form.Input width={4}>
                        <Button fluid onClick={handleSubmit}>
                            Change{' '}
                        </Button>
                    </Form.Input>
                </Form.Group>
            </Form>
        </React.Fragment>
    );
};

export default SetApiPath;
