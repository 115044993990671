import React, { useState, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { Menu } from 'semantic-ui-react';

const DashboardTabsComponent = (props) => {
    const { tabs, callback, propertyToSet } = props;
    const [selectedTab, setSelectedTab] = useState(tabs ? tabs[0].key : null);
    useEffect(() => {
        if (selectedTab && callback) {
            callback({
                value: selectedTab,
                property: propertyToSet,
            });
        }
    }, []);
    if (!tabs) return null;

    const setTab = (tabKey) => {
        setSelectedTab(tabKey);
        if (callback)
            callback({
                value: tabKey,
                property: propertyToSet,
            });
    };

    return (
        <div>
            <Menu fluid pointing className="wrapping">
                {tabs.map((tab, index) => {
                    return (
                        <Menu.Item
                            key={index}
                            onClick={() => {
                                setTab(tab.key);
                            }}
                            active={tab.key === selectedTab}
                        >
                            {tab.label}
                        </Menu.Item>
                    );
                })}
            </Menu>
        </div>
    );
};

DashboardTabsComponent.propTypes = {
    tabs: PropTypes.array,
    callback: PropTypes.func,
    propertyToSet: PropTypes.string,
};

export default DashboardTabsComponent;
