import React from 'react';
import * as PropTypes from 'prop-types';
import { Table, Button } from 'semantic-ui-react';
import toastr from 'toastr';

import UserLabel from '../common/labels/UserLabel';
import ActionButtons from './../common/ActionButtons';

const UserGroupListItem = ({
    userGroup,
    addUser,
    deleteUser,
    deleteUserGroup,
    editUserGroup,
    showConfirmDialog,
    allowAdmin,
}) => {
    const deleteUserGroupClicked = () => {
        showConfirmDialog(
            `Are you sure you want to delete ${userGroup.label} user group?`,
            deleteUserGroupOnConfirm,
        );
    };
    const deleteUserGroupOnConfirm = () => {
        deleteUserGroup(userGroup.partyId, userGroup.id).then(
            deleteUserGroupOnConfirmCallback,
        );
    };
    const deleteUserGroupOnConfirmCallback = () => {
        toastr.success(`UserGroup ${userGroup.label} is removed`);
    };
    const editUserGroupClicked = () => {
        editUserGroup(userGroup);
    };
    return (
        <Table.Row>
            <Table.Cell width={2}>{userGroup.label}</Table.Cell>
            <Table.Cell width={2}>
                <div className="ui grid">
                    <div className="row">
                        <div className="column">
                            {userGroup.users
                                ? userGroup.users.map((user) => (
                                      <UserLabel
                                          key={user.id}
                                          user={user}
                                          deleteUser={
                                              allowAdmin
                                                  ? () =>
                                                        deleteUser(
                                                            userGroup,
                                                            user,
                                                        )
                                                  : null
                                          }
                                      />
                                  ))
                                : null}
                        </div>
                    </div>
                    {allowAdmin && (
                        <div className="row">
                            <div className="column">
                                <Button
                                    primary
                                    onClick={() => addUser(userGroup)}
                                >
                                    Add user
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            </Table.Cell>
            {allowAdmin && (
                <Table.Cell width={1} textAlign="center">
                    <ActionButtons
                        className="u-show-on-hover__target"
                        deleteClicked={deleteUserGroupClicked}
                        editClicked={editUserGroupClicked}
                    />
                </Table.Cell>
            )}
        </Table.Row>
    );
};

UserGroupListItem.propTypes = {
    userGroup: PropTypes.object.isRequired,
    addUser: PropTypes.func.isRequired,
    deleteUser: PropTypes.func.isRequired,
    deleteUserGroup: PropTypes.func.isRequired,
    editUserGroup: PropTypes.func.isRequired,
    showConfirmDialog: PropTypes.func.isRequired,
    allowAdmin: PropTypes.bool,
};

export default UserGroupListItem;
