import React from 'react';
import * as PropTypes from 'prop-types';

const ParagraphComponent = (props) => {
    return <p>{props.content}</p>;
};

ParagraphComponent.propTypes = {
    content: PropTypes.string,
};

export default ParagraphComponent;
