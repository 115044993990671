import { Table } from 'semantic-ui-react';

import DistributorTableRow from './DistributorTableRow';
import SortableHeaderCell from '../common/SortableHeaderCell';
import { DistributorInformation } from '../../types';
import { PaginationOptions } from '@vidende/components/FilterAndPaginate';

interface DistributorListProps {
    distributors: DistributorInformation[];
    handleSortChange: (sortField: string) => void;
    paginationOptions: PaginationOptions;
    handleAddFilter: (filters: object) => void;
    removeFilterValues: (filters: string[]) => void;
    currentFilters: any;
    hiddenColumns?: string[];
}

const DistributorList = (props: DistributorListProps) => {
    const {
        distributors,
        handleSortChange,
        paginationOptions,
        handleAddFilter,
        removeFilterValues,
        currentFilters,
    } = props;

    const renderDistributorList = () => {
        return distributors.map((distributor) => (
            <DistributorTableRow
                distributor={distributor}
                key={distributor.id}
                hiddenColumns={paginationOptions.hiddenColumns}
            />
        ));
    };

    return (
        <Table celled sortable>
            <Table.Header>
                <Table.Row>
                    <SortableHeaderCell
                        {...paginationOptions}
                        name="label"
                        handleSortChange={handleSortChange}
                        handleAddFilter={handleAddFilter}
                        removeFilterValues={removeFilterValues}
                        currentFilters={currentFilters}
                        isSearchable={true}
                    >
                        Distributor Label
                    </SortableHeaderCell>
                    <SortableHeaderCell
                        {...paginationOptions}
                        name="ref"
                        handleSortChange={handleSortChange}
                        handleAddFilter={handleAddFilter}
                        removeFilterValues={removeFilterValues}
                        currentFilters={currentFilters}
                        isSearchable={true}
                    >
                        Ref
                    </SortableHeaderCell>
                    <SortableHeaderCell
                        {...paginationOptions}
                        name="neverLoggedIn"
                        handleSortChange={handleSortChange}
                    >
                        Logged in
                    </SortableHeaderCell>
                    <SortableHeaderCell
                        {...paginationOptions}
                        name="distributorDataRequests"
                        handleSortChange={handleSortChange}
                        handleAddFilter={handleAddFilter}
                        removeFilterValues={removeFilterValues}
                        currentFilters={currentFilters}
                        isSearchable={true}
                    >
                        Data Requests
                    </SortableHeaderCell>
                    <SortableHeaderCell
                        {...paginationOptions}
                        name="emailBounces"
                        handleSortChange={handleSortChange}
                        handleAddFilter={handleAddFilter}
                        removeFilterValues={removeFilterValues}
                        currentFilters={currentFilters}
                        isSearchable={true}
                    >
                        Email Bounces
                    </SortableHeaderCell>
                    <SortableHeaderCell
                        {...paginationOptions}
                        name="productCount"
                        handleSortChange={handleSortChange}
                        handleAddFilter={handleAddFilter}
                        removeFilterValues={removeFilterValues}
                        currentFilters={currentFilters}
                        isSearchable={true}
                    >
                        Product Count
                    </SortableHeaderCell>
                    <SortableHeaderCell
                        {...paginationOptions}
                        name="directDistributor"
                        handleSortChange={handleSortChange}
                    >
                        Sub Distributor
                    </SortableHeaderCell>
                    {paginationOptions.hiddenColumns.includes(
                        'actions',
                    ) ? null : (
                        <Table.HeaderCell>Actions</Table.HeaderCell>
                    )}
                </Table.Row>
            </Table.Header>
            <Table.Body>{renderDistributorList()}</Table.Body>
        </Table>
    );
};

export default DistributorList;
