import { FC } from 'react';
import { Header, Pagination, Segment, Table } from 'semantic-ui-react';
import Moment from 'react-moment';
import FilterAndPaginate from '@vidende/components/FilterAndPaginate';
import { getPayloadData } from './QueueStatusPage';

interface QueuesInProgressWrapperProps {
    paginationOptions: any;
    paginatedItems: any;
    handleSortChange: any;
    handlePaginationChange: any;
    handleAddFilter: any;
    currentFilters: any;
    removeFilterValues: any;
}

const QueuesInProgressWrapper: FC<QueuesInProgressWrapperProps> = ({
    currentFilters,
    handleAddFilter,
    handlePaginationChange,
    handleSortChange,
    paginatedItems,
    paginationOptions,
    removeFilterValues,
}) => {
    return (
        <>
            {paginatedItems.length ? (
                <>
                    <Table>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Party</Table.HeaderCell>
                                <Table.HeaderCell>Action</Table.HeaderCell>
                                <Table.HeaderCell>Created</Table.HeaderCell>
                                <Table.HeaderCell>Payload</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {paginatedItems.map((item) => (
                                <Table.Row>
                                    <Table.Cell>
                                        {item.user.party?.label}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {item.actionTypeData?.label}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Moment>{item.createdAt}</Moment>
                                    </Table.Cell>
                                    <Table.Cell>
                                        {getPayloadData(
                                            item.actionTypeData.verb,
                                            item.payload,
                                        )}
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                    <Pagination
                        activePage={paginationOptions.activePage}
                        onPageChange={handlePaginationChange}
                        totalPages={paginationOptions.totalPages}
                        handleAddFilter={handleAddFilter}
                        currentFilters={currentFilters}
                        removeFilterValues={removeFilterValues}
                    />
                </>
            ) : (
                <Segment className="row">
                    <div className="column">
                        <Header as="h3">There are no items in progress</Header>
                    </div>
                </Segment>
            )}
        </>
    );
};

function mapOptions(props) {
    return {
        items: props.items,
        recordsPerPage: props.recordsPerPage,
    };
}

export default FilterAndPaginate(mapOptions)(QueuesInProgressWrapper);
