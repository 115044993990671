import React from 'react';
import { Checkbox, Input, Select, Table } from 'semantic-ui-react';
import ActionButtons from '../common/ActionButtons';
import StatusNodeTransitionList from './StatusNodeTransitionList';
import { WorkflowStatus, StatusNode, Transition } from '../../types';

interface TransitionActions {
    updateTransition: (
        event: React.SyntheticEvent,
        data: { name: string; value: any },
        statusNode: StatusNode,
        transition: Transition,
    ) => void;
    addTransition: (
        event: React.SyntheticEvent,
        statusNode: StatusNode,
    ) => void;
    removeTransition: (
        event: React.SyntheticEvent,
        statusNode: StatusNode,
        transition: Transition,
    ) => void;
}

interface WorkflowStatusNodeListRowProps {
    statusNode: StatusNode;
    statusNodes: StatusNode[];
    statuses: WorkflowStatus[];
    onChange: (event: React.SyntheticEvent, data: any) => void;
    onRemove: (event: React.SyntheticEvent) => void;
    transitionActions: TransitionActions;
    isStartStatusNode?: boolean;
}

export const WorkflowStatusNodeListRow = ({
    statusNode,
    statusNodes,
    statuses,
    onChange,
    onRemove,
    transitionActions,
}: WorkflowStatusNodeListRowProps) => {
    return (
        <Table.Row>
            <Table.Cell>
                <Input
                    value={statusNode.label}
                    name="label"
                    onChange={onChange}
                />
            </Table.Cell>
            <Table.Cell>
                <Select
                    name="statusUid"
                    onChange={onChange}
                    value={statusNode.statusId}
                    options={statuses.map((status) => ({
                        key: status.id,
                        value: status.id,
                        text: status.name,
                    }))}
                />
            </Table.Cell>
            <Table.Cell>
                <StatusNodeTransitionList
                    statusNode={statusNode}
                    transitions={statusNode.transitions}
                    statusNodes={statusNodes}
                    addTransition={(event) =>
                        transitionActions.addTransition(event, statusNode)
                    }
                    updateTransition={(event, data, transition) =>
                        transitionActions.updateTransition(
                            event,
                            data,
                            statusNode,
                            transition,
                        )
                    }
                    removeTransition={(event, transition) =>
                        transitionActions.removeTransition(
                            event,
                            statusNode,
                            transition,
                        )
                    }
                />
            </Table.Cell>
            <Table.Cell>
                <Checkbox
                    label="Start"
                    name="isStartNode"
                    onChange={(event) => {
                        if (!statusNode.isStartNode)
                            onChange(event, {
                                name: 'isStartNode',
                                value: true,
                            });
                    }}
                    checked={statusNode.isStartNode}
                />
            </Table.Cell>
            <Table.Cell>
                <ActionButtons removeClicked={onRemove} />
            </Table.Cell>
        </Table.Row>
    );
};

export default WorkflowStatusNodeListRow;
