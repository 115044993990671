import _ from 'lodash';
import ArgTypes from '../ArgTypes';

const split = function (args) {
    const { dataSet, valueToSplitKey, valueKeys, splitChar, outputKey, data } =
        args;
    const dataToMap = data[dataSet];

    const outputValues = _.chain(dataToMap)
        .map((value) => {
            let v = _.cloneDeep(value);
            const valueToSplit = _.get(value, valueToSplitKey);
            if (!valueToSplit) return v;

            const splitArr = valueToSplit.split(splitChar);

            _.forEach(splitArr, (value, index) => {
                if (!valueKeys[index]) return;
                v[valueKeys[index]] = value;
            });

            return v;
        })
        .value();

    return { ...args, data: { ...data, [outputKey || dataSet]: outputValues } };
};

split.args = {
    dataSet: ArgTypes.string,
    outputKey: ArgTypes.string,
    splitChar: ArgTypes.string,
    valueToSplitKey: ArgTypes.string,
    valueKeys: ArgTypes.json,
};

export default split;
