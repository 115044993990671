import React from 'react';
import * as PropTypes from 'prop-types';
import { TicketContextConsumer } from '../../context/TicketContext';
import { Breadcrumb } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import ApplicableDateLabel from '../common/ApplicableDateLabel';

const TicketBreadcrumbs = (props) => {
    const ticket = props.ticket || props.currentTicket;
    window.ticket = ticket;
    const {
        id,
        executionId,
        executionLabel,
        executionApplicableDate,
        executionApplicableDateTypeId,
        executionTicketSetLabel,
        activity,
    } = ticket;
    return (
        <Breadcrumb>
            <Breadcrumb.Section as={Link} to={`/execution/${executionId}`} link>
                {executionLabel}{' '}
                {executionApplicableDate && (
                    <span>
                        {' '}
                        (
                        <ApplicableDateLabel
                            applicableDate={executionApplicableDate}
                            applicableDateTypeId={executionApplicableDateTypeId}
                        />
                        )
                    </span>
                )}
            </Breadcrumb.Section>
            {executionTicketSetLabel && (
                <React.Fragment>
                    <Breadcrumb.Divider>/</Breadcrumb.Divider>
                    <Breadcrumb.Section
                        as={Link}
                        to={`/execution/${executionId}`}
                        link
                    >
                        {executionTicketSetLabel}
                    </Breadcrumb.Section>
                </React.Fragment>
            )}
            <Breadcrumb.Divider>/</Breadcrumb.Divider>
            <Breadcrumb.Section active as={Link} to={`/ticket/${id}`} link>
                {activity.label}
            </Breadcrumb.Section>
        </Breadcrumb>
    );
};

TicketBreadcrumbs.propTypes = {
    ticket: PropTypes.object,
    currentTicket: PropTypes.object,
};

export default TicketContextConsumer(TicketBreadcrumbs);
