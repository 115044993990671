import React from 'react';
import * as PropTypes from 'prop-types';
import { Icon, Table } from 'semantic-ui-react';
import * as _ from 'lodash';
import toastr from 'toastr';

import ActionButtons from '../ActionButtons';
import { getTimeAgo } from '../../../utils/CommonUtils';
import FileAccordion from './FileAccordion';
let AttachmentDetailsForm;
if (typeof process !== 'undefined' && !process.env.SERVER_REND) {
    AttachmentDetailsForm = require('./AttachmentDetailsForm').default;
}

// import "./AttachmentsListRow.scss";

const AttachmentsListRow = (props) => {
    const {
        attachment,
        onDelete,
        onDownload,
        isActive = false,
        allowSelection = false,
        onAdd,
        onRemove,
        showDetails = true,
        attachmentsConstants,
        allowEditDetails = false,
        switchEditingMode,
        attachmentStateActions,
        openEditModal,
        isVisibleDetailsForm,
    } = props;
    const clickHandler = () => {
        isActive ? onRemove(attachment) : onAdd(attachment);
    };
    const {
        originalFileName,
        description,
        createdAt,
        creator,
        type,
        classification,
        isExternalLink,
        label,
    } = attachment;
    const getClassificationType = (type, valueId) => {
        const constantsValues = attachmentsConstants[type];
        return _.find(constantsValues, { id: valueId }).label;
    };
    const enableEditMode = () => {
        if (openEditModal) {
            openEditModal(attachment);
        } else {
            switchEditingMode && switchEditingMode(attachment.id);
        }
    };
    const uploadAttachmentSuccess = () => {
        toastr.success('Attachment is updated');
        switchEditingMode && switchEditingMode(attachment.id);
    };
    const fileName = isExternalLink ? label : originalFileName;
    return (
        <React.Fragment>
            <Table.Row active={isActive} verticalAlign="top">
                <Table.Cell>
                    {description ? (
                        <FileAccordion
                            description={description}
                            originalFileName={fileName}
                        />
                    ) : (
                        <span className="table-cell-fix-wrap">{fileName}</span>
                    )}
                </Table.Cell>
                {allowSelection && (
                    <Table.Cell
                        onClick={() => clickHandler()}
                        collapsing
                        textAlign="center"
                        className={description && 'padding-attachments-list'}
                    >
                        <Icon
                            circular
                            inverted
                            name="checkmark"
                            color={isActive ? 'green' : 'grey'}
                            size="small"
                            style={{ opacity: isActive ? 1 : 0.2, margin: 0 }}
                        />
                    </Table.Cell>
                )}
                {showDetails && (
                    <Table.Cell
                        collapsing
                        className={description && 'padding-attachments-list'}
                    >
                        <div>
                            Uploaded {getTimeAgo(createdAt)} <br /> by{' '}
                            {creator.label}
                        </div>
                        <div>
                            Type:
                            {` ${getClassificationType(
                                'attachmentType',
                                type,
                            )}`}
                        </div>
                        <div>
                            Classification:
                            {` ${getClassificationType(
                                'attachmentClassification',
                                classification,
                            )}`}
                        </div>
                    </Table.Cell>
                )}
                {(onDownload || onDelete || allowEditDetails) && (
                    <Table.Cell
                        collapsing
                        className={`hide-for-print ${
                            description && 'padding-attachments-list'
                        } `}
                    >
                        <ActionButtons
                            downloadClicked={
                                onDownload ? () => onDownload(attachment) : null
                            }
                            deleteClicked={
                                onDelete ? () => onDelete(attachment) : null
                            }
                            editClicked={
                                allowEditDetails && enableEditMode
                                    ? () => enableEditMode()
                                    : null
                            }
                        />
                    </Table.Cell>
                )}
            </Table.Row>
            {isVisibleDetailsForm ? (
                <Table.Row>
                    <Table.Cell colSpan="3">
                        <AttachmentDetailsForm
                            attachment={attachment}
                            attachmentsConstants={attachmentsConstants}
                            attachmentStateActions={attachmentStateActions}
                            uploadAttachmentSuccess={uploadAttachmentSuccess}
                        />
                    </Table.Cell>
                </Table.Row>
            ) : null}
        </React.Fragment>
    );
};

AttachmentsListRow.propTypes = {
    attachment: PropTypes.object.isRequired,
    onDelete: PropTypes.func,
    onDownload: PropTypes.func,
    isActive: PropTypes.bool,
    onClick: PropTypes.func,
    allowSelection: PropTypes.bool,
    onAdd: PropTypes.func,
    onRemove: PropTypes.func,
    showDetails: PropTypes.bool,
    attachmentsConstants: PropTypes.object,
    allowEditDetails: PropTypes.bool,
    switchEditingMode: PropTypes.func,
    attachmentStateActions: PropTypes.object,
    editAsModal: PropTypes.bool,
    openEditModal: PropTypes.func,
    isVisibleDetailsForm: PropTypes.bool,
};

export default AttachmentsListRow;
