import * as _ from 'lodash';
import apiTools from './apiTools';
import { ticketFilter, ticketPreviewFilter } from '../constants/apiFilters';

class FairValueApi {
    static getTickets(filter = {}) {
        return apiTools
            .get(
                'FairValueTickets',
                {
                    filter,
                },
                true,
            )
            .then((response) => {
                return response.data;
            });
    }
}

export default FairValueApi;
