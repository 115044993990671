import TicketsSidebarList from '../ticket/TicketsSidebarList';
import ExecutionSidebarList from '../execution/ExecutionSidebarList';
import DistributorInformation from '../distributors/DistributorList';

const Components = {
    ExecutionTicket: TicketsSidebarList,
    Execution: ExecutionSidebarList,
    DistributorInformation,
};

export default Components;
