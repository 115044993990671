import React, { useContext } from 'react';
import * as PropTypes from 'prop-types';
import Moment from 'react-moment';
import { Table } from 'semantic-ui-react';
import toastr from 'toastr';

import ActionButtons from '../../common/ActionButtons';
import PartyLabel from '../../common/labels/PartyLabel';
import ConfirmationModalContext from '../../../context/ConfirmationModalContext';
import { Entity } from '../../../types';
import * as uiActions from '../../../actions/uiActions';
import { MODAL_COMPONENTS } from '../../common/ModalRoot';
import { useDispatch } from 'react-redux';
import { deleteEntity } from '../../../actions/entityActions';

interface Props {
    entity: Entity;
}

const EntityListItem = ({ entity }: Props) => {
    const { confirmPopupActions } = useContext(ConfirmationModalContext);
    const dispatch = useDispatch();

    const handleDeleteEntity = () => {
        confirmPopupActions.showConfirmDialog(
            `Are you sure you want to delete ${entity.label} entity?`,
            deleteEntityOnConfirm,
        );
    };
    const deleteEntityOnConfirm = async () => {
        await dispatch(deleteEntity(entity));

        // deletePartyEntity(entity.id).then(
        //   deleteEntityOnConfirmCallback
        // );
    };
    const deleteEntityOnConfirmCallback = () => {
        toastr.success(`Entity ${entity.label} is removed`);
    };
    const editEntity = () => {
        dispatch(
            uiActions.showModal({
                modalType: MODAL_COMPONENTS.SAVE_ENTITY_MODAL,
                modalProps: {
                    entityId: entity.id,
                },
            }),
        );
    };
    return (
        <Table.Row>
            <Table.Cell width={2}>{entity.label}</Table.Cell>
            <Table.Cell width={2}>{entity.entityTypeId}</Table.Cell>
            <Table.Cell width={2}>
                <Moment>{entity.createdAt}</Moment>
            </Table.Cell>
            <Table.Cell width={1} textAlign="center">
                <ActionButtons
                    className="u-show-on-hover__target"
                    deleteClicked={handleDeleteEntity}
                    editClicked={editEntity}
                />
            </Table.Cell>
        </Table.Row>
    );
};

EntityListItem.propTypes = {
    entity: PropTypes.object.isRequired,
    deletePartyEntity: PropTypes.func.isRequired,
    editPartyEntity: PropTypes.func.isRequired,
    showConfirmDialog: PropTypes.func.isRequired,
};

export default EntityListItem;
