import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Dimmer, Loader } from 'semantic-ui-react';

import * as executionPlanActions from '../../actions/executionPlanActions';
import * as entityActions from '../../actions/entityActions';
import ExecutionPlanContext from '../../context/ExecutionPlanContext';
import { extractFunctions } from '../../utils/React';
import * as storeTypes from '../../constants/storeTypes';
import * as uiActions from '../../actions/uiActions';

function ExecutionPlanWrapper(ComponentToWrap) {
    let options = {
        key: storeTypes.CURRENT,
    };

    class ExecutionPlan extends Component {
        state = {
            deleteConfirmationOpen: false,
            executionPlanToDelete: {},
            saving: false,
            currentModal: '',
            modalConfig: {},
        };

        deleteExecutionPlan = (executionPlan) => {
            this.setState({
                executionPlanToDelete: executionPlan,
                deleteConfirmationOpen: true,
            });
        };

        cloneExecutionPlan = (executionPlan) => {
            this.props.history.push(`/executionPlan?clone=${executionPlan.id}`);
        };

        editExecutionPlan = (executionPlan) => {
            this.props.history.push(`/executionPlan/${executionPlan.id}/edit`);
        };

        gotoExecutionPlan = (executionPlan) => {
            this.props.history.push(`/executionPlan/${executionPlan.id}`);
        };

        render() {
            const {
                executionPlan,
                executionPlans,
                parties,
                executionPlanStateActions,
                entityStateActions,
                history,
                workflows,
                uiActions,
                entities,
            } = this.props;

            const values = {
                executionPlan,
                executionPlans,
                parties,
                history,
                executionPlanStateActions: executionPlanStateActions,
                entityStateActions: entityStateActions,
                executionPlanActions: extractFunctions(this),
                workflows,
                uiActions,
                entities,
            };
            return (
                <ExecutionPlanContext.Provider value={values}>
                    <Dimmer active={this.state.saving} inverted>
                        <Loader disabled={!this.state.saving} />
                    </Dimmer>
                    <ComponentToWrap {...values} {...this.props} />
                </ExecutionPlanContext.Provider>
            );
        }
    }

    ExecutionPlan.propTypes = {
        executionPlan: PropTypes.object,
        executionPlanStateActions: PropTypes.object,
        uiActions: PropTypes.object,
        executionPlans: PropTypes.array.isRequired,
        parties: PropTypes.array.isRequired,
        history: PropTypes.object,
        workflows: PropTypes.array,
    };

    function mapStateToProps(state) {
        return {
            parties: state.parties.list,
            executionPlans: state.executionPlans,
            entities: state.entity.entities,
            executionPlan: state[`ExecutionPlan${options.key}`],
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            executionPlanStateActions: bindActionCreators(
                executionPlanActions,
                dispatch,
            ),
            entityStateActions: bindActionCreators(entityActions, dispatch),
            uiActions: bindActionCreators(uiActions, dispatch),
        };
    }
    return connect(mapStateToProps, mapDispatchToProps)(ExecutionPlan);
}

export default ExecutionPlanWrapper;
