import React from 'react';
import * as PropTypes from 'prop-types';
import { Form, Icon, Popup } from 'semantic-ui-react';
import UIDGenerator from '../../../utils/UIDGenerator';
import FieldToolTip from './FieldToolTip';
import Outlier from './Outlier';

const YesNo = (props) => {
    const {
        label,
        value,
        name,
        onChange,
        disabled = false,
        required,
        editable = true,
        error,
        outlier,
        excludeFromStatistics,
        dataField,
        isManufacturer,
    } = props;
    const u = UIDGenerator.get();
    const valueBool = value ? JSON.parse(value) : null;

    if (!editable) {
        return <React.Fragment>{valueBool ? 'Yes' : 'No'}</React.Fragment>;
    }

    return (
        <Form.Field inline required={required} error={error}>
            <label>
                {label}
                {!props?.descriptionOnScreen && <FieldToolTip {...props} />}
                {isManufacturer && dataField && (
                    <Outlier
                        value={value}
                        name={name}
                        outlier={outlier}
                        excludeFromStatistics={excludeFromStatistics}
                        onChange={onChange}
                    />
                )}
            </label>
            {props?.descriptionOnScreen && props?.description && (
                <>{props.description}</>
            )}
            <Form.Group inline>
                <Form.Radio
                    label="Yes"
                    name={`radioGroup${u}`}
                    checked={valueBool === true}
                    onChange={() => {
                        onChange({}, { value: 'true' });
                    }}
                    disabled={disabled}
                />
                <Form.Radio
                    label="No"
                    name={`radioGroup${u}`}
                    checked={valueBool === false}
                    onChange={() => {
                        onChange({}, { value: 'false' });
                    }}
                    disabled={disabled}
                />
            </Form.Group>
        </Form.Field>
    );
};

YesNo.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    editable: PropTypes.bool,
    dataField: PropTypes.bool,
    outlier: PropTypes.bool,
    excludeFromStatistics: PropTypes.bool,
    isManufacturer: PropTypes.bool,
    descriptionOnScreen: PropTypes.bool,
};

YesNo.defaultProps = {
    value: '',
};

export default YesNo;
