import React, { Component, useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import {
    Button,
    Container,
    Divider,
    Form,
    Grid,
    Segment,
    Message,
} from 'semantic-ui-react';

import UserWrapper from './User';
import Text from '../common/fields/Text';
import userApi from '../../api/userApi';
import ResetPasswordForm, {
    RESET_PASSWORD_STEPS,
} from '@vidende/components/ResetPasswordForm';
import _ from 'lodash';

interface ResetPasswordPageProps {
    userStateActions: any;
    history: any;
    eventsActions: any;
    constantStateActions: any;
}

const ResetPasswordPage: React.FC<ResetPasswordPageProps> = (props) => {
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [telephone, setTelephone] = useState('');
    const [verifyTelephoneError, setVerifyTelephoneError] = useState<any>();
    const [verifySMSError, setVerifySMSError] = useState<any>();
    const [getUserVerificationError, setGetUserVerificationError] =
        useState<any>();

    const [errors, setErrors] = useState<any>({
        validationErrors: null,
        apiErrors: null,
    });
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [passwordResetState, setPasswordResetState] = useState(
        RESET_PASSWORD_STEPS.GET_VERIFICATION_TYPE,
    );
    const [verifyRequest, setVerifyRequest] = useState<{
        token: string;
        verify: 'PHONE_NUMBER' | 'PHONE_REQUEST' | 'SMS';
    }>();

    useEffect(() => {
        const url = new URL(window.location.href);
        setToken(url.searchParams.get('token'));
    }, []);

    const handleGetUserVerification = async () => {
        try {
            setLoading(true);
            const result = await userApi.getUserVerificationType({
                token: token,
                email: email,
            });
            setLoading(false);
            if (result?.verify) {
                setVerifyRequest(result);
                if (result.verify.toUpperCase() === 'SMS') {
                    setPasswordResetState(RESET_PASSWORD_STEPS.SMS);
                }
                if (result.verify.toUpperCase() === 'PHONE_REQUEST') {
                    setPasswordResetState(RESET_PASSWORD_STEPS.PHONE_REQUEST);
                }
                if (result.verify.toUpperCase() === 'PHONE_NUMBER') {
                    setPasswordResetState(RESET_PASSWORD_STEPS.PHONE_NUMBER);
                }
                return;
            }
            setPasswordResetState(RESET_PASSWORD_STEPS.RESET_PASSWORD);
        } catch (error) {
            setLoading(false);
            setGetUserVerificationError(error);
        }
    };

    const handleResetPassword = () => {
        const {
            userStateActions,
            history,
            eventsActions,
            constantStateActions,
        } = props;
        setLoading(true);

        userStateActions
            .updatePassword(email, password, confirmPassword, token)
            .then(() => {
                constantStateActions.loadConfig();
                constantStateActions.loadModelStructure();
                eventsActions.cleanEventsList();
                eventsActions.getEventsList({});
                history.push('/');
            })
            .catch((respErrors) => {
                console.log(respErrors);

                const validationErrors = respErrors.validationErrors;
                let errors = {};
                if (validationErrors) {
                    errors = { validationErrors };
                } else {
                    errors = {
                        apiErrors:
                            respErrors.message ||
                            respErrors.response?.data?.error?.message,
                    };
                }

                setErrors(errors);
                setLoading(false);
            });
    };

    const handleChange = (
        name:
            | 'email'
            | 'password'
            | 'confirmPassword'
            | 'verificationCode'
            | 'telephone',
        value: string,
    ) => {
        if (name === 'email') setEmail(value);
        if (name === 'password') setPassword(value);
        if (name === 'confirmPassword') setConfirmPassword(value);
        if (name === 'verificationCode') setVerificationCode(value);
        if (name === 'telephone') setTelephone(value);
    };

    const renderError = () => {
        const { apiErrors, validationErrors } = errors;
        let errorContent = '';
        if (validationErrors && validationErrors.token)
            errorContent = validationErrors.token;
        if (apiErrors) errorContent = apiErrors;
        if (errorContent) return <Message error content={errorContent} />;
        return null;
    };

    const handleVerifySMSCode = async () => {
        if (!verifyRequest || !verificationCode) return;
        setLoading(true);
        try {
            await userApi.verifySMSCode({
                token: verifyRequest?.token,
                code: verificationCode,
                onlyVerify: true,
            });
            setLoading(false);
            setPasswordResetState(RESET_PASSWORD_STEPS.RESET_PASSWORD);
        } catch (err) {
            setVerifySMSError(err);
            setLoading(false);
        }
    };

    const handlePhoneRequest = async () => {
        if (!verifyRequest || !telephone) return;

        setLoading(true);

        try {
            const result = await userApi.verifyTelephoneNumber({
                telephoneNumber: telephone,
                token: verifyRequest?.token,
            });
            setLoading(false);

            if ('verify' in result) {
                setVerifyRequest(result);
                if (result.verify.toUpperCase() === 'SMS') {
                    setPasswordResetState(RESET_PASSWORD_STEPS.SMS);
                }
                if (result.verify.toUpperCase() === 'PHONE_REQUEST') {
                    setPasswordResetState(RESET_PASSWORD_STEPS.PHONE_REQUEST);
                }
                if (result.verify.toUpperCase() === 'PHONE_NUMBER') {
                    setPasswordResetState(RESET_PASSWORD_STEPS.PHONE_NUMBER);
                }
                setLoading(false);
                return;
            }
        } catch (err) {
            setLoading(false);
            setVerifyTelephoneError(err);
        }
    };

    const resetForm = () => {
        setTelephone('');
        setVerificationCode('');
        setVerifyRequest(undefined);
    };

    return (
        <Container className="login-page">
            <Grid stackable centered columns={3}>
                <Grid.Column>
                    <Divider hidden />
                    <Segment>
                        <ResetPasswordForm
                            currentStep={passwordResetState}
                            formData={{
                                confirmPassword,
                                email,
                                telephone,
                                verificationCode,
                                password,
                            }}
                            onChange={handleChange}
                            loading={loading}
                            onGetUserVerification={handleGetUserVerification}
                            onPhoneRequest={handlePhoneRequest}
                            onResetPassword={handleResetPassword}
                            onVerifySMSCode={handleVerifySMSCode}
                            resetForm={resetForm}
                            resetPasswordError={renderError()}
                            validationErrors={errors?.validationErrors}
                            verifySMSError={_.get(verifySMSError, 'message')}
                            verifyTelephoneError={_.get(
                                verifyTelephoneError,
                                'message',
                            )}
                            getUserVerificationError={_.get(
                                getUserVerificationError,
                                'message',
                            )}
                        />
                    </Segment>
                </Grid.Column>
            </Grid>
        </Container>
    );
};

export default UserWrapper(ResetPasswordPage);
