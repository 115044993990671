import _ from 'lodash';

export default function (args) {
    const { dataSet, data, outputKey, values } = args;
    const dataToMap = data[dataSet];

    const outputValues = _.chain(dataToMap)
        .map((value) => {
            let v = _.cloneDeep(value);

            _.forEach(values, (mapValue) => {
                const { valuesArray, valueKey } = mapValue;

                const valuesToConvert = _.get(value, valuesArray);

                _.map(valuesToConvert, (vtm) => {
                    const valToConvert = _.get(vtm, valueKey);
                    let newValue = valToConvert;
                    try {
                        newValue = JSON.parse(valToConvert);
                    } catch (e) {
                        _.noop;
                    }
                    vtm[valueKey] = newValue;
                });

                v[valuesArray] = valuesToConvert;
            });

            return v;
        })
        .value();

    return {
        ...args,
        data: { ...data, [outputKey || 'mappedValues']: outputValues },
    };
}
