import { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
    Button,
    Dimmer,
    Loader,
    Modal,
    Input,
    Form,
    Dropdown,
} from 'semantic-ui-react';
import * as toastr from 'toastr';
import slugify from 'slugify';

import permissionApi from '../../api/permissionApi';
import * as partyActions from '../../actions/partyActions';
import * as uiActions from '../../actions/uiActions';
import { mapToOptions } from '../../utils/React';
import * as nodeTypes from '../../constants/nodeTypes';
import * as userActions from '../../actions/userActions';

interface Props {
    currentUser: any;
    partyActions: any;
    uiActions: any;
    userActions: any;
}

const CreatePartyModal = ({
    currentUser,
    partyActions,
    uiActions,
    userActions,
}: Props) => {
    const [state, setState] = useState({
        name: '',
        ref: '',
        partyGroupId: '',
        partyGroups: [],
        loading: false,
    });

    useEffect(() => {
        loadPartyGroups();
    }, []);

    useEffect(() => {
        if (!state.name) {
            setState((prevState) => ({ ...prevState, ref: '' }));
            return;
        }
        const slugifiedName = slugify(state.name.toUpperCase(), {
            lower: false,
        }).slice(0, 16);
        setState((prevState) => ({ ...prevState, ref: slugifiedName }));
    }, [state.name]);

    const loadPartyGroups = () => {
        setState((prevState) => ({ ...prevState, loading: true }));
        permissionApi
            .getPermissions({
                where: {
                    structuralNodeTypeId: nodeTypes.PartyGroup,
                },
            })
            .then((result) => {
                setState((prevState) => ({
                    ...prevState,
                    partyGroups: result,
                    loading: false,
                }));
            })
            .catch((error) => {
                toastr.error(error);
                setState((prevState) => ({ ...prevState, loading: false }));
            });
    };

    const handleInputChanged = (
        event: React.ChangeEvent<HTMLInputElement>,
        { name, value, checked }: any,
    ) => {
        setState((prevState) => ({ ...prevState, [name]: value || checked }));
    };

    const createParty = () => {
        const { name, ref, partyGroupId } = state;
        setState((prevState) => ({ ...prevState, loading: true }));
        partyActions
            .createParty(name, ref, partyGroupId, currentUser)
            .then(() => {
                userActions.renewToken();
                toastr.success('New party is added');
                uiActions.closeModal();
            })
            .catch((error) => {
                toastr.error(error);
                setState((prevState) => ({ ...prevState, loading: false }));
            });
    };

    const { loading, name, partyGroupId, partyGroups, ref } = state;

    return (
        <Modal
            open={true}
            onClose={() => uiActions.closeModal()}
            size="mini"
            closeOnDimmerClick={false}
        >
            <Dimmer active={loading} inverted>
                <Loader disabled={!loading} />
            </Dimmer>
            <Modal.Header>Create new party</Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Field required>
                        <label>Party name</label>
                        <Input
                            fluid
                            placeholder="Name"
                            value={name}
                            name="name"
                            onChange={handleInputChanged}
                            isRequired
                        />
                    </Form.Field>
                    <Form.Field required>
                        <label>Party Reference</label>
                        <Input
                            fluid
                            placeholder="Reference"
                            value={ref || ''}
                            name="ref"
                            onChange={handleInputChanged}
                            isRequired
                        />
                    </Form.Field>
                    <Form.Field required>
                        <label>Party permissions group</label>
                        <Dropdown
                            placeholder="Select the group"
                            fluid
                            selection
                            search
                            name="partyGroupId"
                            onChange={handleInputChanged}
                            options={
                                partyGroups ? mapToOptions(partyGroups) : []
                            }
                            value={partyGroupId}
                        />
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button positive onClick={createParty}>
                    Save
                </Button>
                <Button secondary onClick={() => uiActions.closeModal()}>
                    Cancel
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

function mapDispatchToProps(dispatch: any) {
    return {
        uiActions: bindActionCreators(uiActions, dispatch),
        partyActions: bindActionCreators(partyActions, dispatch),
        userActions: bindActionCreators(userActions, dispatch),
    };
}

export default connect(null, mapDispatchToProps)(CreatePartyModal);
