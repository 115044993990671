import entityApi from '../api/entityApi';
import * as types from '../constants/actionTypes';
import { Entity } from '../types';

export function loadEntities(filter?: any) {
    return function (dispatch) {
        return entityApi.getEntities(filter).then((entities) => {
            dispatch({
                type: types.LOAD_ENTITIES_SUCCESS,
                entities,
            });
        });
    };
}
export function loadEntity(id: string, filter?: any) {
    return function (dispatch) {
        return entityApi.getEntity(id, filter).then((entity) => {
            dispatch({
                type: types.LOAD_ENTITY_SUCCESS,
                entity,
            });
        });
    };
}
export function deleteEntity(entity: Entity) {
    return function (dispatch) {
        return entityApi
            .updateEntity(entity, { deleted: true })
            .then((entity) => {
                dispatch({
                    type: types.UPDATE_ENTITY_SUCCESS,
                    entity,
                });
            });
    };
}

export function loadEntityType(type: string) {
    return function (dispatch) {
        return entityApi.getEntityType(type).then((entityType) => {
            dispatch({
                type: types.LOAD_ENTITY_TYPE_SUCCESS,
                entityType,
            });
        });
    };
}

export function loadEntityTypes() {
    return function (dispatch) {
        return entityApi.getEntityTypes().then((entityTypes) => {
            dispatch({
                type: types.LOAD_ENTITY_TYPES_SUCCESS,
                entityTypes,
            });
        });
    };
}
