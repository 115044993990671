import React, { Component, useEffect, useState } from 'react';
import statusApi from '../../api/statusApi';
import {
    Button,
    Container,
    Dimmer,
    Form,
    Input,
    List,
    Loader,
    Modal,
    Segment,
} from 'semantic-ui-react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { StatusReportEditor } from './StatusReportEditor';
import { Field, Status, StatusReport, StatusComposite } from '../../types';
import * as toastr from 'toastr';
import { orderBy, set } from 'lodash';
import { useAppSelector } from '../../actions/store';
import Conversation from '../conversation/Conversation';

interface Props extends RouteComponentProps<{ id: string }> {}

const StatusReportPage = (props: Props) => {
    const [statusReport, setStatusReport] = useState<StatusReport>();
    const [isSaving, setIsSaving] = useState(false);

    const statusReportId = props.match.params.id.toLowerCase();

    const fetchStatusReport = () => {
        statusApi.getStatusReport(statusReportId).then((statusReportData) => {
            setStatusReport(statusReportData);
        });
    };

    useEffect(() => {
        if (statusReportId) fetchStatusReport();
    }, [statusReportId]);

    const handleAddStatus = (status: Status) => {
        setIsSaving(true);
        statusApi
            .addStatusToReport({
                statusReportId: statusReport.id,
                statusId: status.id,
                sort: 0,
            })
            .then((result) => {
                setIsSaving(false);
                toastr.success('Status added to metric report');
                console.log(result);
                fetchStatusReport();
            });
    };

    const handleAddComposite = async (statusComposite: StatusComposite) => {
        setIsSaving(true);
        await statusApi.addStatusCompositeToReport({
            statusReportId: statusReport.id,
            statusComposite,
        });
        setIsSaving(false);
        toastr.success('Status composite added to metric report');
        fetchStatusReport();
    };

    const handleUpdateComposite = async (statusComposite: StatusComposite) => {
        setIsSaving(true);
        await statusApi.updateStatusComposite({
            statusReportId: statusReport.id,
            statusComposite,
        });
        setIsSaving(false);
        toastr.success('Status composite updated');
        fetchStatusReport();
    };

    const handleAddField = (field: Field | Field[]) => {
        //if field is an array create multiple promises before fetchStatusReport

        setIsSaving(true);
        if (Array.isArray(field)) {
            const promises = field.map((f) =>
                statusApi.addFieldToReport({
                    statusReportId: statusReport.id,
                    activityFieldId: f.id,
                    sort: 0,
                }),
            );
            Promise.all(promises).then((result) => {
                console.log(result);
                setIsSaving(false);

                toastr.success('Fields added to metric report');
                fetchStatusReport();
            });
            return; //return to avoid the next fetchStatusReport
        }

        statusApi
            .addFieldToReport({
                statusReportId: statusReport.id,
                activityFieldId: field.id,
                sort: 0,
            })
            .then((result) => {
                setIsSaving(false);
                console.log(result);
                toastr.success('Field added to metric report');
                fetchStatusReport();
            });
    };

    const handleRemoveStatus = (status: Status) => {
        setIsSaving(true);
        statusApi
            .removeStatusFromReport({
                statusReportId: statusReport.id,
                statusId: status.id,
            })
            .then((result) => {
                setIsSaving(false);
                toastr.success('Status removed from metric report');
                console.log(result);
                fetchStatusReport();
            });
    };

    const handleRemoveField = (field: Field) => {
        setIsSaving(true);
        statusApi
            .removeFieldFromReport({
                statusReportId: statusReport.id,
                activityFieldId: field.id,
            })
            .then((result) => {
                setIsSaving(false);
                toastr.success('Field removed from metric report');
                fetchStatusReport();
            });
    };

    const handleSaveStatusReport = async (statusReport: StatusReport) => {
        await statusApi.updateStatusReport(statusReport);
        fetchStatusReport();
    };

    const handleSaveStatus = async (status: Status) => {
        if (status.id) {
            try {
                await statusApi.updateStatus(status);
                toastr.success('Metric saved');
                fetchStatusReport();
            } catch (error) {
                toastr.error(error);
            }
            return;
        }
    };

    return (
        <Container>
            {isSaving && (
                <Dimmer active={isSaving} inverted>
                    <Loader disabled={!isSaving} />
                </Dimmer>
            )}
            {statusReport && (
                <StatusReportEditor
                    statusReport={statusReport}
                    onAddStatus={handleAddStatus}
                    onAddField={handleAddField}
                    onRemoveStatus={handleRemoveStatus}
                    onRemoveField={handleRemoveField}
                    onAddComposite={handleAddComposite}
                    onUpdateComposite={handleUpdateComposite}
                    onSave={handleSaveStatusReport}
                    onSaveStatus={handleSaveStatus}
                />
            )}

            {statusReport?.conversationId && (
                <Segment>
                    <Conversation
                        conversationId={statusReport.conversationId}
                        type="Comment"
                    />
                </Segment>
            )}
        </Container>
    );
};

export default StatusReportPage;
