import React from 'react';
import * as PropTypes from 'prop-types';
import { Menu } from 'semantic-ui-react';

import CheckPermission from '../common/CheckPermission';

import * as permissionTypes from '../../constants/permissionTypes';

const PartyMenu = ({ currentItem, handleItemClick, structuralNodeId }) => {
    return (
        <Menu vertical fluid>
            <Menu.Item
                onClick={handleItemClick}
                name="users"
                active={currentItem === 'users'}
            >
                Users
            </Menu.Item>
            <Menu.Item
                onClick={handleItemClick}
                name="relationships"
                active={currentItem === 'relationships'}
            >
                Relationships
            </Menu.Item>
            <Menu.Item
                onClick={handleItemClick}
                name="entities"
                active={currentItem === 'entities'}
            >
                Entities
            </Menu.Item>
            <Menu.Item
                onClick={handleItemClick}
                name="usergroups"
                active={currentItem === 'usergroups'}
            >
                User groups
            </Menu.Item>
            <Menu.Item
                onClick={handleItemClick}
                name="addresses"
                active={currentItem === 'addresses'}
            >
                Addresses
            </Menu.Item>
            <Menu.Item
                onClick={handleItemClick}
                name="events"
                active={currentItem === 'events'}
            >
                Events
            </Menu.Item>
            <CheckPermission
                structuralNodeId={structuralNodeId}
                permissionType={permissionTypes.ADMIN}
            >
                <Menu.Item
                    onClick={handleItemClick}
                    name="settings"
                    active={currentItem === 'settings'}
                >
                    Settings
                </Menu.Item>
            </CheckPermission>
        </Menu>
    );
};

PartyMenu.propTypes = {
    currentItem: PropTypes.string,
    handleItemClick: PropTypes.func,
    structuralNodeId: PropTypes.number,
};

export default PartyMenu;
