import { Button, Icon, Popup, Table } from 'semantic-ui-react';
import SortableHeaderCell from '../../common/SortableHeaderCell';
import { FC } from 'react';
import { ApiKey } from './ApiKeysPage';
import moment from 'moment';
import toastr from 'toastr';
import { useDispatch } from 'react-redux';
import * as uiActions from '../../../actions/uiActions';
import { MODAL_COMPONENTS } from '../../common/ModalRoot';

interface Props {
    apiKeys: ApiKey[];
    functionApiKeysOptions: { api: string; code: string; item: string }[];
    onSaveApiKey: (apiKey: any) => void;
}

const ApiKeysList: FC<Props> = ({
    apiKeys,
    onSaveApiKey,
    functionApiKeysOptions,
}) => {
    const dispatch = useDispatch();

    const handleCopyApiKey = (apiKey: ApiKey) => {
        navigator.clipboard
            .writeText(apiKey.key)
            .then(() => toastr.success('Api Key was copied'))
            .catch(() => toastr.success("Api Key wasn't copied. Try Again!"));
    };

    const handleEditApiKey = (apiKey) => {
        dispatch(
            uiActions.showModal({
                modalType: MODAL_COMPONENTS.SAVE_API_KEY_MODAL,
                modalProps: {
                    onSumbit: onSaveApiKey,
                    apiKey,
                    functionApiKeysOptions,
                },
            }),
        );
    };

    return (
        <Table celled sortable>
            <Table.Header>
                <Table.Row>
                    <SortableHeaderCell
                        name="label"
                        isSearchable={false}
                        isFilterable={false}
                    >
                        Label
                    </SortableHeaderCell>
                    <SortableHeaderCell
                        name="expiryDate"
                        isSearchable={false}
                        isFilterable={false}
                    >
                        Expiry Date
                    </SortableHeaderCell>
                    <SortableHeaderCell
                        name="partyId"
                        isSearchable={false}
                        isFilterable={false}
                    >
                        PartyId
                    </SortableHeaderCell>
                    <SortableHeaderCell
                        name="key"
                        isSearchable={false}
                        isFilterable={false}
                    >
                        Key
                    </SortableHeaderCell>
                    <SortableHeaderCell
                        name="creatorId"
                        isSearchable={false}
                        isFilterable={false}
                    >
                        CreatorId
                    </SortableHeaderCell>
                    <SortableHeaderCell
                        name="createdAt"
                        isSearchable={false}
                        isFilterable={false}
                    >
                        CreatedAt
                    </SortableHeaderCell>
                    <SortableHeaderCell
                        name="modifiedById"
                        isSearchable={false}
                        isFilterable={false}
                    >
                        ModifiedById
                    </SortableHeaderCell>
                    <SortableHeaderCell
                        name="modifiedAt"
                        isSearchable={false}
                        isFilterable={false}
                    >
                        ModifiedAt
                    </SortableHeaderCell>
                    <SortableHeaderCell
                        name="lastUsed"
                        isSearchable={false}
                        isFilterable={false}
                    >
                        LastUsed
                    </SortableHeaderCell>
                    <SortableHeaderCell
                        name="userId"
                        isSearchable={false}
                        isFilterable={false}
                    >
                        UserId
                    </SortableHeaderCell>
                    <SortableHeaderCell
                        name="active"
                        isSearchable={false}
                        isFilterable={false}
                    >
                        Active
                    </SortableHeaderCell>
                    <SortableHeaderCell
                        name="deleted"
                        isSearchable={false}
                        isFilterable={false}
                    >
                        Deleted
                    </SortableHeaderCell>
                    <SortableHeaderCell
                        name="actions"
                        isSearchable={false}
                        isFilterable={false}
                    >
                        Actions
                    </SortableHeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {apiKeys?.map((apiKey) => (
                    <Table.Row>
                        <Table.Cell>{apiKey.label}</Table.Cell>
                        <Table.Cell>
                            {apiKey.expiryDate &&
                                moment(apiKey.expiryDate).format('DD/MM/YY')}
                        </Table.Cell>
                        <Table.Cell>{apiKey.partyId}</Table.Cell>
                        <Table.Cell>
                            {
                                <Popup
                                    wide
                                    trigger={
                                        <Button
                                            icon="clipboard"
                                            onClick={() =>
                                                handleCopyApiKey(apiKey)
                                            }
                                        />
                                    }
                                    content={'Copy Api Key'}
                                    position="top center"
                                />
                            }
                        </Table.Cell>
                        <Table.Cell>{apiKey.creatorId}</Table.Cell>
                        <Table.Cell>
                            {moment(apiKey.createdAt).format('DD/MM/YY')}
                        </Table.Cell>
                        <Table.Cell>{apiKey.modifiedById}</Table.Cell>
                        <Table.Cell>
                            {moment(apiKey.modifiedAt).format('DD/MM/YY')}
                        </Table.Cell>
                        <Table.Cell>
                            {apiKey.lastUsed &&
                                moment(apiKey.lastUsed).format('DD/MM/YY')}
                        </Table.Cell>
                        <Table.Cell>{apiKey.userId}</Table.Cell>
                        <Table.Cell>
                            {apiKey.active ? (
                                <Icon color="green" name="check" />
                            ) : (
                                <Icon color="red" name="x" />
                            )}
                        </Table.Cell>
                        <Table.Cell>
                            {apiKey.deleted ? (
                                <Icon color="green" name="check" />
                            ) : (
                                <Icon color="red" name="x" />
                            )}
                        </Table.Cell>
                        <Table.Cell>
                            <Popup
                                wide
                                trigger={
                                    <Button
                                        icon="edit"
                                        onClick={() => handleEditApiKey(apiKey)}
                                    />
                                }
                                content={'Edit Api Key'}
                                position="top center"
                            />
                        </Table.Cell>
                    </Table.Row>
                ))}
            </Table.Body>
        </Table>
    );
};

export default ApiKeysList;
