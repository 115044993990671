import { useEffect, useState, createContext } from 'react';
import { Dimmer, Header, List, Loader, Segment } from 'semantic-ui-react';
import utilsApi from '../../../api/utilsApi';
import { ModelTreeItem } from './ModelTreeItem';
import { Programme } from '../../../types';
import { useAppSelector } from '../../../actions/store';
import { ModelInstanceLabel } from '../../common/modelRenderers/ModelInstanceLabel';
import ActionButtons from '../../common/ActionButtons';

export const OpenCloseContext = createContext<{
    openAll: boolean | null;
    setOpenAll?: (value: boolean | null) => void;
}>({
    openAll: null,
});

type ModelTreeRootProps = {
    modelName: string;
    modelId: string;
    relationshipConfig: Record<string, string[]>;
    initialOpen?: boolean;

    flattenBy?: Record<string, string[]>;
    onRemove?: (id: string) => void;
};

const buildInclude = (
    modelName: string,
    relationshipConfig: Record<string, string[]>,
    modelStructure: any,
): any[] => {
    if (!relationshipConfig[modelName]) {
        console.log(`No relationship config for ${modelName}`);
        return [];
    }
    return relationshipConfig[modelName].map((relationship) => {
        if (!modelStructure[modelName].relationships[relationship]) {
            console.log(
                `No relationship config for ${modelName}.${relationship}`,
            );
            return {};
        }

        return {
            relation: relationship,
            scope: {
                include: buildInclude(
                    modelStructure[modelName].relationships[relationship]
                        .modelTo,
                    relationshipConfig,
                    modelStructure,
                ),
            },
        };
    });
};

export const ModelTreeRoot = ({
    modelName,
    modelId,
    relationshipConfig,
    initialOpen = false,
    flattenBy,
    onRemove,
}: ModelTreeRootProps) => {
    const [modelObject, setModelObject] = useState<any>(null);
    const [openAll, setOpenAll] = useState<boolean | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const modelStructure = useAppSelector(
        (state) => state.constants.modelStructure,
    );

    const loadModelObject = () => {
        setLoading(true);
        const includeRelationships = buildInclude(
            modelName,
            relationshipConfig,
            modelStructure,
        );
        console.log(includeRelationships);

        utilsApi
            .modelCall({
                model: modelName,
                endpoint: `/${modelId}`,
                params: {
                    filter: {
                        include: includeRelationships,
                    },
                },
            })
            .then((result) => {
                setModelObject(result);
                setLoading(false);
            });
    };

    useEffect(() => {
        if (modelObject) return;
        loadModelObject();
    }, [modelName, modelId]);

    if (loading && !modelObject) {
        return <Loader active inline="centered" />;
    }

    return (
        <Segment>
            <OpenCloseContext.Provider value={{ openAll, setOpenAll }}>
                <Dimmer active={loading} inverted>
                    <Loader active inline="centered" />;
                </Dimmer>
                <Header>
                    <ModelInstanceLabel
                        modelInstance={modelObject}
                        modelName={modelName}
                    />
                </Header>
                <ActionButtons
                    viewClicked={() => setOpenAll(true)}
                    hideClicked={() => setOpenAll(false)}
                    refreshClicked={loadModelObject}
                    deleteClicked={() => onRemove(modelId)}
                />
                <List>
                    <ModelTreeItem
                        modelName={modelName}
                        modelObject={modelObject}
                        relationshipConfig={relationshipConfig}
                        initialOpen={initialOpen}
                        flattenBy={flattenBy}
                    />
                </List>
            </OpenCloseContext.Provider>
        </Segment>
    );
};
