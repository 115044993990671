import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Menu, Sidebar } from 'semantic-ui-react';

import MoreInfoSidebar from '../ticket/MoreInfoSidebar';
import TicketListSidebar from '../dashboard/TicketListSidebar';
import { MetricResultSidebar } from '../dashboard/MetricResultSidebar';
import { bindActionCreators } from 'redux';
import * as uiActions from '../../actions/uiActions';
import FollowUpSidebar from '../followUp/FollowUpSidebar';
import PartyRelationshipSidebar from '../party/PartyRelationshipSidebar';
import { SIDEBAR_COMPONENTS } from './sidebarComponents';
import SimpleErrorWrapper from './SimpleErrorWrapper';
import TicketReadingViewSidebar from '../ticket/TicketPage/TicketReadingViewSidebar';
import FieldOptionsDescriptionViewSidebar from '@vidende/components/FieldOptionsDescriptionViewSidebar';
export { SIDEBAR_COMPONENTS } from './sidebarComponents';

const SidebarRoot = ({ sidebar, uiActions }) => {
    const { sidebarProps, sidebarType, sidebarConfig } = sidebar.sidebarOptions;

    const closeSidebar = () => {
        uiActions.closeSidebar();
    };

    const renderContent = () => {
        switch (sidebarType) {
            case SIDEBAR_COMPONENTS.TICKET_LIST_SIDEBAR: {
                return (
                    <TicketListSidebar
                        {...sidebarProps}
                        closeSidebar={closeSidebar}
                    />
                );
            }
            case SIDEBAR_COMPONENTS.METRIC_RESULT_VIEWER: {
                return (
                    <MetricResultSidebar
                        {...sidebarProps}
                        closeSidebar={closeSidebar}
                    />
                );
            }
            case SIDEBAR_COMPONENTS.MORE_INFO_SIDEBAR: {
                return (
                    <MoreInfoSidebar
                        {...sidebarProps}
                        closeSidebar={closeSidebar}
                    />
                );
            }
            case SIDEBAR_COMPONENTS.FOLLOW_UP_SIDEBAR: {
                return (
                    <FollowUpSidebar
                        {...sidebarProps}
                        closeSidebar={closeSidebar}
                    />
                );
            }
            case SIDEBAR_COMPONENTS.RELATIONSHIP_INFO_SIDEBAR: {
                return (
                    <PartyRelationshipSidebar
                        {...sidebarProps}
                        closeSidebar={closeSidebar}
                    />
                );
            }
            case SIDEBAR_COMPONENTS.TICKET_READING_VIEW_SIDEBAR: {
                return (
                    <TicketReadingViewSidebar
                        {...sidebarProps}
                        closeSidebar={closeSidebar}
                    />
                );
            }
            case SIDEBAR_COMPONENTS.FIELD_OPTIONS_DESCRIPTION_VIEW_SIDEBAR_PROPS: {
                return (
                    <FieldOptionsDescriptionViewSidebar
                        {...sidebarProps}
                        closeSidebar={closeSidebar}
                    />
                );
            }
            default:
                null;
        }
    };
    if (sidebar.showSidebar) {
        if (!sidebarType) {
            return null;
        }
        return (
            <Sidebar
                as={Menu}
                animation="overlay"
                // icon="labeled"
                direction="right"
                vertical
                visible={true}
                width={sidebarConfig.width || 'very wide'}
                style={{ height: '100%' }}
            >
                <SimpleErrorWrapper>{renderContent()}</SimpleErrorWrapper>
            </Sidebar>
        );
    } else return null;
};

SidebarRoot.propTypes = {
    sidebar: PropTypes.object,
    uiActions: PropTypes.object,
};

function mapStateToProps(state) {
    return {
        sidebar: state.uiStatus,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        uiActions: bindActionCreators(uiActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SidebarRoot);
