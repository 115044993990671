import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import DebugComponent from '../common/dashboard/components/DebugComponent';
import { Table } from 'semantic-ui-react';
import ActionButtons from '../common/ActionButtons';
import chartApi from '../../api/chartApi';
import * as _ from 'lodash';
import Select from '../common/fields/Select';
import Text from '../common/fields/Text';

const ChartApiCallsList = (props) => {
    const { apiCalls, onAdd, onRemove } = props;
    const [availableCalls, setAvailableCalls] = useState([]);
    const [selectedCallId, setSelectedCall] = useState();
    const [name, setName] = useState('main');

    useEffect(() => {
        chartApi.getAPICalls().then((charts) => {
            setAvailableCalls(_.orderBy(charts, 'label'));
        });
    }, []);

    return (
        <div>
            <DebugComponent apiCalls={apiCalls} />

            <Table celled>
                <Table.Body>
                    {apiCalls.map((call, index) => (
                        <Table.Row key={index}>
                            <Table.Cell>{call.name}</Table.Cell>
                            <Table.Cell>{call.apiCall.label}</Table.Cell>
                            <Table.Cell>
                                <ActionButtons
                                    deleteClicked={() => {
                                        onRemove({ id: call.id });
                                    }}
                                />
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
            <Select
                options={availableCalls}
                value={selectedCallId}
                onChange={(e, { value }) => {
                    setSelectedCall(value);
                }}
            />
            <Text
                value={name}
                onChange={(e, { value }) => {
                    setName(value);
                }}
            />
            <ActionButtons
                addClicked={() => {
                    const selectedCall = _.find(availableCalls, {
                        id: selectedCallId,
                    });
                    onAdd({ selectedCall, name });
                }}
            />
        </div>
    );
};

ChartApiCallsList.propTypes = {
    apiCalls: PropTypes.array,
    onAdd: PropTypes.func,
    onRemove: PropTypes.func,
};

export default ChartApiCallsList;
