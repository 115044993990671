import React from 'react';
import _ from 'lodash';
import { Feed, Loader, Header, Divider } from 'semantic-ui-react';

import EventListItem from './EventListItem';
import { useAppSelector } from '../../../actions/store';

interface Event {
    targetModel: string;
    // define other properties here...
}

interface Events {
    eventsList: Event[];
    isFailed: boolean;
    errorMessage?: string;
}

interface EventListProps {
    loading?: boolean;
    showComparison?: boolean;
    showLink?: boolean;
    events: Events;
    size?: number;
    className?: string;
}

const EventList = ({
    loading,
    events,
    size = 10,
    showComparison = true,
    showLink = true,
    className,
}: EventListProps) => {
    const { eventsList, isFailed } = events;

    const models = useAppSelector((state) => state.constants.modelStructure);

    const renderEventList = () => {
        return (
            models &&
            eventsList && (
                <Feed className={className}>
                    {eventsList.length ? (
                        _.map(_.take(eventsList, size), (event, index) => (
                            <EventListItem
                                key={index}
                                event={event}
                                model={models[event.targetModel]}
                                showComparison={showComparison}
                                showLink={showLink}
                            />
                        ))
                    ) : (
                        <p>There are no events</p>
                    )}
                </Feed>
            )
        );
    };

    return (
        <>
            {loading && !isFailed ? (
                <Loader active inline="centered" />
            ) : (
                renderEventList()
            )}
            {isFailed && (
                <>
                    <Divider />
                    <Header as="h6" color="red" textAlign="center">
                        {events.errorMessage}
                    </Header>
                </>
            )}
        </>
    );
};

export default EventList;
