import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import {
    LogicResult,
    RAGStatusResult,
    Result,
    StatusResult,
} from '../../../../../api/statusApi';
import ReactHighcharts from 'react-highcharts';

import * as chartConfigs from '../../chartConfigBase';
import * as uiActions from '../../../../../actions/uiActions';
import { SeriesColumnOptions } from 'highcharts';
import { merge } from 'lodash';

interface MetricChartComponentProps {
    ragMetric?: string;
    ragField?: string;
    ragComposite?: string;
    data?: PropsData;
    header?: string;
}
export interface PropsData {
    selection: null;
    data: DataData;
}

export interface DataData {
    main: RAGStatusResult;
}

export interface Main {
    results: Result[];
}

const convertToHighchartSeries = (
    statusResults: StatusResult[],
): { categories: string[]; series: Highcharts.SeriesColumnOptions[] } => {
    // get the first logicResult array (assumes all statusResults have identical structure)
    const logicResults: LogicResult[] = Array.isArray(
        statusResults[0].logicResult,
    )
        ? statusResults[0].logicResult
        : [statusResults[0].logicResult];

    // sort logicResults array by label in ascending alphabetical order
    const sortedLogicResults = [...logicResults].sort(
        (a, b) => a.label?.localeCompare(b.label || '') || 0,
    );

    const categories = sortedLogicResults.map((result) => result.label || '');
    const series: SeriesColumnOptions[] = [
        {
            name: 'Results',
            type: 'column',
            colorByPoint: true,
            data: sortedLogicResults.map((result) => ({
                y: Number(result.result),
                ref: result.ref,
            })),
        },
    ];

    return { categories, series };
};

const MetricChartComponent = (props: MetricChartComponentProps) => {
    const dispatch = useDispatch();
    const { data, ragMetric, ragField, ragComposite } = props;

    const { ragResult, args } = useMemo(() => {
        const args = data?.data?.main?.arguments;
        if (
            data?.data?.main?.results &&
            (ragMetric || ragField || ragComposite)
        ) {
            const result = data?.data?.main?.results.find(
                (result) =>
                    result.ref === ragMetric ||
                    result.ref === ragField ||
                    result.ref === ragComposite,
            );

            return { ragResult: result, args };
        }
        return { args };
    }, [data, ragMetric, ragField, ragComposite]);

    const handleColumnClick = (metricRef: string) => {
        if (!metricRef) return;
        const result = data?.data?.main?.results.find(
            (result) => result.ref === metricRef,
        );
        if (!result) return;
        dispatch(
            uiActions.showSidebar({
                sidebarType: 'METRIC_RESULT_VIEWER',
                sidebarProps: {
                    result,
                    args,
                },
                sidebarConfig: { width: 'wide' },
            }),
        );
    };

    const { categories, series } = ragResult
        ? convertToHighchartSeries(ragResult.statusResults)
        : { categories: [], series: [] };

    const mergedOptions = merge(
        {},
        chartConfigs.stackedColumn,
        {
            xAxis: {
                categories,
                crosshair: true,
            },
            tooltip: {
                headerFormat: '',
                pointFormat: '<b>{point.category}: {point.y}</b>',
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                },
            },
            legend: {
                enabled: false,
            },
            series,
        },
        {
            plotOptions: {
                series: {
                    cursor: 'pointer',
                    point: {
                        events: {
                            click: function () {
                                handleColumnClick(this.options.ref);
                            },
                        },
                    },
                },
            },
        },
    );

    return (
        <div className="component-dashboard-widget chart-component">
            <ReactHighcharts config={mergedOptions} />
        </div>
    );
};

export default MetricChartComponent;

MetricChartComponent.fields = [
    {
        id: 'ragMetric',
        required: false,
        label: 'RAG Metric',
        activityFieldTypeId: 'ModelSearchField',
        options: {
            modelName: 'Status',
            valueProperty: 'ref',
        },
    },
    {
        id: 'ragComposite',
        required: false,
        label: 'Metric Composite',
        activityFieldTypeId: 'ModelSearchField',
        options: {
            modelName: 'StatusComposite',
            valueProperty: 'ref',
        },
    },
    {
        id: 'ragField',
        required: false,
        label: 'RAG Field',
        activityFieldTypeId: 'ModelSearchField',
        options: {
            modelName: 'ActivityField',
            valueProperty: 'fieldKey',
            refProperty: 'fieldKey',
        },
    },
];
