import React from 'react';
import * as PropTypes from 'prop-types';
import { Table, Label, Form } from 'semantic-ui-react';

import ActionButtons from './../common/ActionButtons';

const ProgrammeReportsTableRow = ({
    report,
    handleDeleteClicked,
    handleReportLabelChanged,
}) => {
    const { label, reportLabel, id } = report;

    const handleLabelChanged = (event, { value }) => {
        handleReportLabelChanged(id, value);
    };

    return (
        <Table.Row>
            <Table.Cell width={6}>
                <Label>{label}</Label>
            </Table.Cell>
            <Table.Cell width={3}>
                <Form>
                    <Form.Field>
                        <Form.Input
                            placeholder="Report Label "
                            value={reportLabel}
                            onChange={handleLabelChanged}
                            fluid
                        />
                    </Form.Field>
                </Form>
            </Table.Cell>
            <Table.Cell width={3}>
                <ActionButtons
                    deleteClicked={() => handleDeleteClicked(report.id)}
                />
            </Table.Cell>
        </Table.Row>
    );
};

ProgrammeReportsTableRow.propTypes = {
    report: PropTypes.object,
    handleDeleteClicked: PropTypes.func,
    handleReportLabelChanged: PropTypes.func,
};

export default ProgrammeReportsTableRow;
