import React from 'react';
import * as PropTypes from 'prop-types';
import { Header, List } from 'semantic-ui-react';

import ApplicableDateLabel from '../common/ApplicableDateLabel';
import PartyLabel from '../common/labels/PartyLabel';

import TicketAssignment from './TicketAssignment';
import TicketResolutionIconsContainer from './TicketResolutionIconsContainer';
import TicketStats from './TicketStats';
import TicketRowStatus from './TicketRowStatus';
import { getTicketLabel } from '../../utils/TicketUtils';

const TicketRowCompact = (props) => {
    const {
        ticket,
        onClick,
        isSelected,
        showProgramme,
        showTicketSet,
        showApplicableDate,
        showAssignedParty,
        onContextMenu,
        followUp,
        handleFollowUpClick,
    } = props;
    const color = ticket.statusNodeColor;
    const {
        statusNodeLabel,
        label,
        attachmentsCount,
        commentsCount,
        statusResolutions,
        executionTicketSetLabel,
    } = ticket;
    const currentTicketLabel = getTicketLabel(
        label,
        showTicketSet,
        executionTicketSetLabel,
    );

    return (
        <React.Fragment>
            <List.Item
                active={isSelected}
                className="ticket-row compact"
                onContextMenu={onContextMenu}
            >
                <TicketRowStatus
                    color={color}
                    followUp={followUp}
                    statusNodeLabel={statusNodeLabel}
                    onFollowUpClick={handleFollowUpClick}
                />

                <React.Fragment>
                    <div
                        className="ticket-row-header"
                        onClick={() => {
                            onClick(ticket);
                        }}
                    >
                        <Header
                            as="h4"
                            content={currentTicketLabel}
                            subheader={
                                showProgramme ? ticket.executionLabel : ''
                            }
                        />
                        {showApplicableDate &&
                            ticket.executionApplicableDate && (
                                <ApplicableDateLabel
                                    applicableDate={
                                        ticket.executionApplicableDate
                                    }
                                    applicableDateTypeId={
                                        ticket.executionApplicableDateTypeId
                                    }
                                />
                            )}

                        <TicketStats
                            attachmentsCount={attachmentsCount}
                            commentsCount={commentsCount}
                        />
                    </div>
                </React.Fragment>

                <div className="actions">
                    {showAssignedParty && (
                        <PartyLabel
                            party={{
                                id: ticket.assignedPartyId,
                                label: ticket.assignedPartyLabel,
                            }}
                        />
                    )}
                    <TicketAssignment ticket={ticket} />
                    {statusResolutions?.length > 0 && (
                        <Header size="tiny">
                            Resolutions:{' '}
                            <TicketResolutionIconsContainer
                                className="resolutions"
                                statusResolutions={statusResolutions}
                            />
                        </Header>
                    )}
                </div>
            </List.Item>
        </React.Fragment>
    );
};

TicketRowCompact.propTypes = {
    ticket: PropTypes.object,
    onClick: PropTypes.func,
    isSelected: PropTypes.bool,
    transitioning: PropTypes.bool,
    resolving: PropTypes.bool,
    resolutionClicked: PropTypes.func,
    transitionClicked: PropTypes.func,
    showProgramme: PropTypes.bool,
    showTicketSet: PropTypes.bool,
    showApplicableDate: PropTypes.bool,
    showAssignedParty: PropTypes.bool,

    onContextMenu: PropTypes.func,
    followUp: PropTypes.bool,
    handleFollowUpClick: PropTypes.func,
};

export default TicketRowCompact;
