import * as types from '../constants/actionTypes';

export default function filterReducer(state = [], action) {
    switch (action.type) {
        case types.LOAD_ITEMS_SUCCESS: {
            return action.data?.data || action.data || [];
        }
        case types.CLEAR_ITEMS: {
            return null;
        }
        default:
            return state;
    }
}
