import React from 'react';
import { Route, RouteProps, useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';
import UserWrapper from './User';
import { clearLocalStorage } from '../../reducers';
import { CurrentUser } from '../../types';

type PrivateRouteProps = {
    component: React.ComponentType<any>;
    currentUser: CurrentUser;
    appActions: {
        saveStartRoute: (path: string) => void;
    };
} & Omit<RouteProps, 'component'>;

function PrivateRoute({
    component: Component,
    currentUser,
    appActions,
    ...rest
}: PrivateRouteProps) {
    const history = useHistory();
    const location = useLocation();

    const redirectToLogin = (location: { pathname: string }) => {
        appActions.saveStartRoute(location.pathname);
        clearLocalStorage();
        history.push('/login');
        return null;
    };

    if (!currentUser.loggedIn) {
        return redirectToLogin(location);
    }

    return (
        <Route
            {...rest}
            render={(props) => (
                <Component
                    {...rest}
                    {...props}
                    query={qs.parse(location.search)}
                />
            )}
        />
    );
}

export default UserWrapper(PrivateRoute);
