import * as types from '../constants/actionTypes';
import { FairValueTicket } from '../types';

export type FairValueState = {
    followUpList: number[];
    list: FairValueTicket[];
    byId: { [key: number]: FairValueTicket };
};

type Action = {
    type: string;
    tickets: FairValueTicket[];
};

export default function fairValueReducer(
    state: FairValueState = {
        followUpList: [],
        list: [],
        byId: {},
    },
    action: Action,
): FairValueState {
    switch (action.type) {
        case types.FAIR_VALUE_LOAD_TICKETS_SUCCESS:
            return {
                ...state,
                list: action.tickets,
                byId: action.tickets.reduce((obj, ticket) => {
                    obj[ticket.id] = ticket;
                    return obj;
                }, {}),
            };
        default:
            return state;
    }
}
