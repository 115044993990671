import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { Header, Segment } from 'semantic-ui-react';

import PartyWrapper from './Party';
import FilterAndPaginate from '../common/FilterAndPaginate';
import RelationshipList from './RelationshipList';
import Pagination from '../common/Pagination';

class PartyRelatationshipList extends Component {
    static propTypes = {
        paginationOptions: PropTypes.object,
        paginatedItems: PropTypes.array,
        handleSortChange: PropTypes.func,
        handlePaginationChange: PropTypes.func,
        partyStateActions: PropTypes.object,
        removeFilterValues: PropTypes.func,
        handleAddFilter: PropTypes.func,
        currentFilters: PropTypes.object,
    };

    render() {
        const {
            paginatedItems,
            handleSortChange,
            handlePaginationChange,
            paginationOptions,
            partyStateActions,
            removeFilterValues,
            handleAddFilter,
            currentFilters,
        } = this.props;
        return (
            <React.Fragment>
                <Header as="h3">Outgoing Relationships</Header>
                {paginatedItems.length ? (
                    <React.Fragment>
                        <RelationshipList
                            relationships={paginatedItems || []}
                            paginationOptions={paginationOptions}
                            handleSortChange={handleSortChange}
                            partyStateActions={partyStateActions}
                            removeFilterValues={removeFilterValues}
                            handleAddFilter={handleAddFilter}
                            currentFilters={currentFilters}
                        />
                        <Pagination
                            activePage={paginationOptions.activePage}
                            onPageChange={handlePaginationChange}
                            totalPages={paginationOptions.totalPages}
                            style={{ float: 'right' }}
                        />
                    </React.Fragment>
                ) : (
                    <Segment>
                        <Header as="h4">
                            There are no Outgoing Relationships in this party
                        </Header>
                    </Segment>
                )}
            </React.Fragment>
        );
    }
}

function mapOptions(props) {
    return {
        items: props.party.partyRelationships,
    };
}

export default PartyWrapper(
    FilterAndPaginate(mapOptions)(PartyRelatationshipList),
);
