import React, { useEffect } from 'react';
import { Form, Select } from 'semantic-ui-react';
import * as PropTypes from 'prop-types';

import { mapToOptions } from '../../utils/React';
import * as nodeTypes from '../../constants/nodeTypes';
import * as permissionTypes from '../../constants/permissionTypes';
import StatusDropdown from '../common/StatusDropdown';
import RichTextArea from '../common/fields/RichTextArea';
import userApi from '../../api/userApi';

const ActivityMainDetails = ({
    activity,
    parties,
    activityStatuses,
    currentUser,
    updateActivityState,
    layout,
}) => {
    const {
        label,
        description,
        ref: reference,
        ownerPartyId,
        structuralNodeId,
        activityStatusId,
        version,
    } = activity;

    const [questionNodes, setQuestionNodes] = React.useState([]);

    useEffect(() => {
        const fetchQuestionNodes = async () => {
            const nodes = await userApi.getNodesByType(nodeTypes.QuestionGroup);
            setQuestionNodes(nodes);
        };

        fetchQuestionNodes();
    }, []);

    if (layout === 'WIDE') {
        return (
            <Form>
                <Form.Group widths="equal">
                    <Form.Field width={4}>
                        <label>Label</label>
                        <Form.Input
                            placeholder="Question Label"
                            value={label}
                            onChange={updateActivityState}
                            name="label"
                        />
                    </Form.Field>
                    <Form.Field width={2}>
                        <label>Reference</label>
                        <Form.Input
                            value={reference}
                            onChange={updateActivityState}
                            placeholder="Question Reference"
                            name="ref"
                        />
                    </Form.Field>
                    <Form.Field width={1}>
                        <label>Version</label>
                        <Form.Input value={version} disabled />
                    </Form.Field>
                    <Form.Field width={3}>
                        <label>Question Group</label>
                        <Form.Field
                            control={Select}
                            options={mapToOptions(questionNodes, null, true)}
                            search
                            placeholder="Select Group"
                            onChange={updateActivityState}
                            value={structuralNodeId}
                            name="structuralNodeId"
                        />
                    </Form.Field>
                    <Form.Field width={3}>
                        <label>Status</label>
                        <StatusDropdown
                            statuses={activityStatuses}
                            onChange={updateActivityState}
                            value={activityStatusId}
                            name="activityStatusId"
                        />
                    </Form.Field>
                    <Form.Field width={3}>
                        <label>Owner Party</label>
                        <Form.Field
                            search
                            control={Select}
                            options={mapToOptions(parties, null, true)}
                            placeholder="Select Status"
                            onChange={updateActivityState}
                            value={ownerPartyId}
                            name="ownerPartyId"
                        />
                    </Form.Field>
                </Form.Group>
                <Form.Group>
                    <RichTextArea
                        label="Description"
                        value={description}
                        onChange={updateActivityState}
                        name="description"
                        fullWidth={true}
                        allowShowHide={true}
                        initialVisible={false}
                    />
                </Form.Group>
            </Form>
        );
    }

    return (
        <Form>
            <Form.Group>
                <Form.Field width={12}>
                    <label>Label</label>
                    <Form.Input
                        placeholder="Question Label"
                        value={label}
                        onChange={updateActivityState}
                        name="label"
                    />
                </Form.Field>
                <Form.Field width={3}>
                    <label>Reference</label>
                    <Form.Input
                        value={reference}
                        onChange={updateActivityState}
                        placeholder="Question Reference"
                        name="ref"
                    />
                </Form.Field>
                <Form.Field width={1}>
                    <label>Version</label>
                    <Form.Input value={version} disabled />
                </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
                <Form.Field>
                    <label>Question Group</label>
                    <Form.Field
                        control={Select}
                        search
                        options={mapToOptions(questionNodes, null, true)}
                        placeholder="Select Group"
                        onChange={updateActivityState}
                        value={structuralNodeId}
                        name="structuralNodeId"
                    />
                </Form.Field>
                <Form.Field>
                    <label>Status</label>
                    <StatusDropdown
                        statuses={activityStatuses}
                        onChange={updateActivityState}
                        value={activityStatusId}
                        name="activityStatusId"
                    />
                </Form.Field>
                <Form.Field>
                    <label>Owner Party</label>
                    <Form.Field
                        control={Select}
                        search
                        options={mapToOptions(parties, null, true)}
                        placeholder="Select Status"
                        onChange={updateActivityState}
                        value={ownerPartyId}
                        name="ownerPartyId"
                    />
                </Form.Field>
            </Form.Group>
            <Form.Group>
                <RichTextArea
                    label="Description"
                    value={description}
                    onChange={updateActivityState}
                    name="description"
                    fullWidth={true}
                />
            </Form.Group>
        </Form>
    );
};

ActivityMainDetails.propTypes = {
    activity: PropTypes.object,
    parties: PropTypes.array,
    activityStatuses: PropTypes.array,
    currentUser: PropTypes.object,
    updateActivityState: PropTypes.func,
    layout: PropTypes.string,
};

export default ActivityMainDetails;
