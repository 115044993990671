import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { Container, Dimmer, Loader, Header, Button } from 'semantic-ui-react';

import ErrorBoundary from '../common/ErrorBoundary';
import PartyWrapper from './Party';
import PartyThresholdsList from './PartyThresholdsList';

class RiskAppetitePage extends Component {
    state = {
        loading: false,
    };
    componentDidMount() {
        this.getParty(this.props);
    }

    getParty = (props) => {
        const { id } = props.match.params;
        if (id) {
            this.loadParty(id);
        }
    };

    loadParty = (id) => {
        const { partyStateActions } = this.props;

        this.setState({ loading: true });

        const filter = {
            include: ['riskThresholds'],
        };

        partyStateActions
            .loadParty(id, filter)
            .then(() => {
                this.setState({ loading: false });
            })
            .catch(() => {
                this.props.history.replace('/notFoundPage');
            });
    };
    openCreateThreshold = () => {
        const { partyActions } = this.props;
        partyActions.openCreateThreshold();
    };
    render() {
        const { loading } = this.state;
        const { party } = this.props;
        return (
            <Container>
                <Dimmer active={loading} inverted>
                    <Loader disabled={!loading} />
                </Dimmer>
                {party.riskThresholds && (
                    <React.Fragment>
                        <Header as="h1">
                            {party.label} - Manage Risk Appetite Thresholds
                        </Header>
                        <PartyThresholdsList />
                        <Button primary onClick={this.openCreateThreshold}>
                            Add new threshold
                        </Button>
                    </React.Fragment>
                )}
            </Container>
        );
    }
}

RiskAppetitePage.propTypes = {
    partyStateActions: PropTypes.object,
    openCreateThreshold: PropTypes.func,
    history: PropTypes.object,
    party: PropTypes.object,
    partyActions: PropTypes.object,
};

export default ErrorBoundary(PartyWrapper(RiskAppetitePage));
