import React, { Component, useEffect, useState } from 'react';
import statusApi from '../../api/statusApi';
import {
    Button,
    Container,
    Form,
    Input,
    List,
    Modal,
    Segment,
} from 'semantic-ui-react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { StatusEditor } from './StatusEditor';
import { Status } from '../../types';
import toastr from 'toastr';
import { orderBy } from 'lodash';
import { useAppSelector } from '../../actions/store';
import Conversation from '../conversation/Conversation';
import ErrorBoundary from '../common/ErrorBoundary';

interface Props extends RouteComponentProps<{ id: string }> {}

const StatusPage = (props: Props) => {
    const [status, setStatus] = useState<Status>();
    const currentUser = useAppSelector((state) => state.currentUser);

    const statusId = props.match.params.id.toLowerCase();

    const fetchStatuses = () => {
        statusApi.getStatus(statusId).then((statusData) => {
            setStatus(statusData);
        });
    };

    useEffect(() => {
        if (statusId && statusId !== 'new') fetchStatuses();
    }, [statusId]);

    const handleSaveStatus = async (status: Status) => {
        if (status.id) {
            try {
                await statusApi.updateStatus(status);
                toastr.success('Metric saved');
            } catch (error) {
                toastr.error(error);
            }

            return;
        }

        const newStatus: Status = {
            ...status,
            structuralNodeId: currentUser.details.party.structuralNodeId,
        };

        try {
            const result = await statusApi.createStatus(newStatus);
            props.history.replace(`/metrics/${result.id}`);
            toastr.success('New Metric created');
        } catch (error) {
            toastr.error(error);
        }
    };

    return (
        <Container>
            {status && (
                <StatusEditor status={status} onSave={handleSaveStatus} />
            )}
            {statusId === 'new' && <StatusEditor onSave={handleSaveStatus} />}
            {status?.conversationId && (
                <Segment>
                    <Conversation
                        conversationId={status.conversationId}
                        type="Comment"
                    />
                </Segment>
            )}
        </Container>
    );
};

export default ErrorBoundary(StatusPage);
