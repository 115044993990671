import { formatApplicableDate } from '../../utils/FormatUtils';

interface ApplicableDateLabelProps {
    applicableDate: string | undefined;
    applicableDateTypeId: string | undefined;
}

const ApplicableDateLabel = ({
    applicableDate,
    applicableDateTypeId,
}: ApplicableDateLabelProps) => {
    if (!applicableDate) return null;
    const formattedDate = formatApplicableDate(
        applicableDate,
        applicableDateTypeId,
    );
    return <>{formattedDate}</>;
};

export default ApplicableDateLabel;
