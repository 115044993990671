import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import toastr from 'toastr';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Dimmer, Loader, Modal, Form } from 'semantic-ui-react';
import * as _ from 'lodash';

import UserSearch from '../common/search/UserSearch';
import * as uiActions from '../../actions/uiActions';

class AddUserToUserGroupModal extends Component {
    state = {
        label: '',
        saving: false,
        partyId: null,
        userGroupId: null,
        user: null,
        userId: '',
    };

    componentWillMount() {
        const { label, id, partyId } = this.props.userGroup;
        this.setState({ userGroupId: id, label, partyId });
    }

    onChange = (event, { name, value }) => {
        const { party } = this.props;
        const user = _.find(party.users, { id: value });
        this.setState({ [name]: value, user });
    };

    addUserToUserGroup = () => {
        const { userGroupId, user } = this.state;
        const { partyStateActions } = this.props;
        this.setState({ saving: true });
        partyStateActions
            .addUserToUserGroup(userGroupId, user)
            .then(this.addUserToUserGroupCallback())
            .catch((error) => this.addUserToUserGroupError(error));
    };

    addUserToUserGroupCallback = () => {
        const { label } = this.state;
        const { uiActions } = this.props;
        toastr.success(`New user is added to ${label} usergroup`);
        uiActions.closeModal();
    };

    addUserToUserGroupError = (error) => {
        toastr.error(error);
        this.setState({ saving: false });
        throw error;
    };

    render() {
        const { saving, label, userId, partyId } = this.state;
        const { uiActions } = this.props;

        return (
            <Modal
                open={true}
                onClose={() => uiActions.closeModal()}
                size="mini"
                closeOnDimmerClick={false}
            >
                <Dimmer active={saving} inverted>
                    <Loader disabled={!saving} />
                </Dimmer>
                <Modal.Header>Add user to {label}</Modal.Header>
                <Modal.Content>
                    <Form.Field>
                        <label>Select User:</label>
                        <Form.Input>
                            <UserSearch
                                onChange={this.onChange}
                                value={userId}
                                name="userId"
                                filterByParty={partyId}
                                sortItemsAlphabetically={true}
                            />
                        </Form.Input>
                    </Form.Field>
                </Modal.Content>

                <Modal.Actions>
                    <Button positive onClick={this.addUserToUserGroup}>
                        Save
                    </Button>
                    <Button secondary onClick={() => uiActions.closeModal()}>
                        Cancel
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

AddUserToUserGroupModal.propTypes = {
    party: PropTypes.object,
    userGroup: PropTypes.object.isRequired,
    partyStateActions: PropTypes.object,
    uiActions: PropTypes.object,
};

function mapDispatchToProps(dispatch) {
    return {
        uiActions: bindActionCreators(uiActions, dispatch),
    };
}

export default connect(null, mapDispatchToProps)(AddUserToUserGroupModal);
