import React, { Component } from 'react';
import PropType from 'prop-types';

import apiTools from './../../api/apiTools';
import { formatBinaryIntoBase64 } from './../../utils/CommonUtils';

class Image extends Component {
    state = {
        loading: false,
        noImage: false,
        base64Img: '',
    };
    componentWillMount() {
        const { url } = this.props;
        this.setState({ loading: true });
        apiTools
            .loadImage(url)
            .then((response) => {
                const base64Img = formatBinaryIntoBase64(response.data);
                this.setState({ base64Img, noImage: false, loading: false });
            })
            .catch(() => {
                this.setState({ noImage: true, loading: false });
            });
    }
    render() {
        const { base64Img, noImage, loading } = this.state;

        if (noImage || loading) return null;
        const RenderComponent = this.props.renderComponent;

        return RenderComponent ? (
            <RenderComponent
                src={`data:image/jpeg;base64,${base64Img}`}
                {...this.props}
            />
        ) : (
            <img src={`data:image/jpeg;base64,${base64Img}`} {...this.props} />
        );
    }
}

Image.propTypes = {
    url: PropType.string,
    renderComponent: PropType.func,
};

export default Image;
