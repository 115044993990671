type PasswordValidationResult = {
    valid: boolean;
    errors: string[];
};

export const verifyPassword = (password: string): PasswordValidationResult => {
    let errors: string[] = [];

    // Check minimum length
    if (password.length < 8) {
        errors.push('Password should be at least 8 characters long.');
    }

    // Check for uppercase
    if (!/[A-Z]/.test(password)) {
        errors.push('Password should contain at least one uppercase letter.');
    }

    // Check for lowercase
    if (!/[a-z]/.test(password)) {
        errors.push('Password should contain at least one lowercase letter.');
    }

    // Check for number
    if (!/[0-9]/.test(password)) {
        errors.push('Password should contain at least one number.');
    }

    // Check for special character
    if (!/[^a-zA-Z0-9]/.test(password)) {
        errors.push('Password should contain at least one special character.');
    }

    return {
        valid: errors.length === 0,
        errors: errors,
    };
};
