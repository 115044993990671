import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import UserContext from '../../context/UserContext';
import userApi from '../../api/userApi';
import e from 'express';
import { useDispatch, useSelector } from 'react-redux';
import { node } from 'prop-types';

export interface CurrentUser {
    hasPermission: (nodeType: string, permissionType: string) => boolean;
    checkPermissionForNode: (
        structuralNodeId: number,
        permissionType: string,
    ) => boolean;
    getPermissionForNode: (structuralNodeId: number) => string;
}

type Args = {
    structuralNodeId?: number;
    permissionType?: string;
    nodeType?: string;
};

const usePermissions = ({
    structuralNodeId,
    permissionType = 'p/view',
    nodeType,
}: Args) => {
    const dispatch = useDispatch();
    const num = useRef(Math.round(Math.random() * 1000));
    const userContext = useContext(UserContext);
    const { currentUser } = userContext;
    //   const hasPermission = (nodeType: string, permissionType: string) =>
    //     currentUser.hasPermission(nodeType, permissionType);

    const permissions = useSelector(
        (state: any) =>
            state.currentUserPermissions.permissionsByNode[structuralNodeId],
    );
    const permissionsByType = useSelector(
        (state: any) =>
            state.currentUserPermissions.permissionsByType[nodeType],
    );

    const hasPermission = useMemo(() => {
        if (nodeType) {
            return permissionsByType?.includes(permissionType);
        }
        if (!Array.isArray(permissions)) return false;
        return permissions.includes(permissionType);
    }, [permissions]);

    useEffect(() => {
        const fetchPermissions = async () => {
            try {
                const response =
                    await userApi.getPermissionsForNode(structuralNodeId);
                const newPermissions = response.map((p: any) => p.Code);
                dispatch({
                    type: 'LOAD_PERMISSIONS_FOR_NODE_SUCCESS_CURRENT',
                    permissions: newPermissions,
                    nodeId: structuralNodeId,
                });
            } catch (error) {
                console.log('error', error);
            }
        };

        if (!Array.isArray(permissions) && structuralNodeId && permissionType) {
            fetchPermissions();
        }
    }, [structuralNodeId, permissions]);

    return { hasPermission, permissions };
};

export default usePermissions;
