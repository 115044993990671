import React from 'react';
import * as PropTypes from 'prop-types';
import { Segment, Loader, Dimmer, Header, Input } from 'semantic-ui-react';
import * as _ from 'lodash';

import AttachmentsList from './AttachmentsList';
import { WrapAttachmentsContext } from '../../../context/AttachmentContext';

const AddAttachmentModalSearch = (props) => {
    const { loading, attachmentsData, search, selectedAttachments } = props;
    const { addValue, removeValue, handleSearchValueChange } =
        props.attachmentActions;
    const activeIndexes = _.map(
        selectedAttachments,
        (selectedAttachment) => selectedAttachment.id,
    );
    const attachments = _.chain(attachmentsData)
        .map((attachment) => attachment)
        .reverse()
        .value();
    return (
        <Segment className="search-attachments-segment">
            <Input
                name="search"
                action={{ icon: 'search' }}
                placeholder="Search..."
                fluid
                value={search}
                onChange={handleSearchValueChange}
            />
            <Segment basic style={{ padding: 0 }}>
                <Dimmer active={loading} inverted>
                    <Loader disabled={!loading} style={{ top: '7rem' }} />
                </Dimmer>
                {search.length > 2 && attachments && attachments.length > 0 && (
                    <AttachmentsList
                        attachments={attachments || []}
                        allowSelection={true}
                        showDetails={false}
                        activeIndexes={activeIndexes}
                        onAddValue={addValue}
                        onRemoveValue={removeValue}
                    />
                )}
                {search.length > 2 &&
                    attachments &&
                    attachments.length === 0 && (
                        <Header as="h4">No attachments were found</Header>
                    )}
            </Segment>
        </Segment>
    );
};

AddAttachmentModalSearch.propTypes = {
    loading: PropTypes.bool,
    attachmentsData: PropTypes.object,
    handleSearchValueChange: PropTypes.func,
    search: PropTypes.string,
    selectedAttachments: PropTypes.array,
    attachmentActions: PropTypes.object,
};

export default WrapAttachmentsContext(AddAttachmentModalSearch);
