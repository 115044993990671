import axios, { CancelToken } from 'axios';

import { Promise } from 'bluebird';
Promise.config({
    cancellation: true,
    warnings: false,
});

import _ from 'lodash';
import storeProvider from '../store/storeProvider';
import * as userActions from '../actions/userActions';
import hashSum from 'hash-sum';
export const api_url = () => {
    const override = window.localStorage.getItem('apiPathOverride');
    if (override) return override;
    // return "http://localhost:4000/api/";
    return window.JSW_CONFIG ? window.JSW_CONFIG.apiUrl : '/api/';
};

const generateHeaders = () => {
    let headers = {};
    const currentUser = storeProvider.getStore().store.getState().currentUser;
    if (currentUser.loggedIn) {
        headers.Authorization = `bearer ${currentUser.token}`;
    }
    return headers;
};

const handleError = (error) => {
    if (
        _.get(error, 'response.status') === 401 &&
        _.get(error, 'response.data.error.code') === 'TOKEN_EXPIRED'
    ) {
        const store = storeProvider.getStore().store;
        store.dispatch(userActions.logout());
    }
};

const getSource = (isCancellable) => {
    return isCancellable ? CancelToken.source() : null;
};

const requestCache = {};

class ApiTools {
    static generateUrl(url) {
        const currentUser = storeProvider
            .getStore()
            .store.getState().currentUser;
        const returnUrl = `${api_url()}${url}?access_token=${
            currentUser.token
        }`;
        return returnUrl;
    }
    static get(url, params = {}, isCancellable) {
        const hash = hashSum(url) + hashSum(params);
        const source = getSource(isCancellable);
        if (requestCache[hash]) return requestCache[hash];
        const promise = new Promise(function (resolve, reject, onCancel) {
            axios
                .get(api_url() + url, {
                    params: Object.assign({}, params),
                    headers: generateHeaders(),
                    cancelToken: isCancellable ? source.token : null,
                })
                .then((response) => {
                    if (requestCache[hash]) delete requestCache[hash];
                    resolve(response);
                })
                .catch((error) => {
                    const isCancel = axios.isCancel(error);
                    if (!isCancel) {
                        if (requestCache[hash]) delete requestCache[hash];
                        if (error.response?.data?.error) {
                            reject(
                                new Error(error.response.data.error.message),
                            );
                        } else {
                            reject(error);
                        }

                        handleError(error);
                    }
                });

            onCancel(function () {
                if (requestCache[hash]) delete requestCache[hash];
                source.cancel();
            });
        });

        requestCache[hash] = promise;

        return promise;
    }
    static post(url, data, params = {}) {
        const promise = new Promise(function (resolve, reject) {
            axios
                .post(api_url() + url, data, {
                    params: Object.assign({}, params),
                    headers: generateHeaders(),
                })
                .then(resolve)
                .catch((error) => {
                    if (error.response?.data?.error) {
                        reject(new Error(error.response.data.error.message));
                    } else {
                        reject(error);
                    }

                    handleError(error);
                });
        });

        return promise;
    }
    static put(url, data, params = {}) {
        const promise = new Promise(function (resolve, reject) {
            axios
                .put(api_url() + url, data, {
                    params: Object.assign({}, params),
                    headers: generateHeaders(),
                })
                .then(resolve)
                .catch((error) => {
                    if (error.response?.data?.error) {
                        reject(new Error(error.response.data.error.message));
                    } else {
                        reject(error);
                    }

                    handleError(error);
                });
        });

        return promise;
    }
    static patch(url, data, params = {}) {
        const promise = new Promise(function (resolve, reject) {
            axios
                .patch(api_url() + url, data, {
                    params: Object.assign({}, params),
                    headers: generateHeaders(),
                })
                .then(resolve)
                .catch((error) => {
                    if (error.response?.data?.error) {
                        reject(new Error(error.response.data.error.message));
                    } else {
                        reject(error);
                    }

                    handleError(error);
                });
        });

        return promise;
    }
    static delete(url, data) {
        const promise = new Promise(function (resolve, reject) {
            axios
                .delete(api_url() + url, {
                    data,
                    headers: generateHeaders(),
                })
                .then(resolve)
                .catch((error) => {
                    if (error.response?.data?.error) {
                        reject(new Error(error.response.data.error.message));
                    } else {
                        reject(error);
                    }

                    handleError(error);
                });
        });

        return promise;
    }
    static upload(url, file, progressCallback, params = {}) {
        const promise = new Promise(function (resolve, reject) {
            const formData = new FormData();
            _.forEach(params, (value, key) => {
                formData.append(key, value);
            });
            formData.append('file', file);
            axios
                .post(api_url() + url, formData, {
                    headers: generateHeaders(),
                    onUploadProgress: (progressEvent) => {
                        if (progressCallback) progressCallback(progressEvent);
                        // do whatever you like with the percentage complete
                        // maybe dispatch an action that will update a progress bar or something
                    },
                })
                .then(resolve)
                .catch((error) => {
                    reject(error);
                    handleError(error);
                });
        });

        return promise;
    }
    static loadImage(url) {
        const promise = new Promise(function (resolve, reject) {
            axios
                .get(api_url() + url, {
                    headers: generateHeaders(),
                    responseType: 'arraybuffer',
                })
                .then((resp) => {
                    resolve(resp);
                })
                .catch((error) => {
                    reject(error);
                    handleError(error);
                });
        });

        return promise;
    }
    static makeCall(args) {
        return axios(args);
    }
}

export default ApiTools;
