import React, { useEffect } from 'react';
import { Container, Button, Header, Dimmer, Loader } from 'semantic-ui-react';
import PartiesListWrapper from './PartiesListWrapper';
import PartiesWrapper from './Parties';
import ErrorBoundary from '../common/ErrorBoundary';
import { set } from 'lodash';

interface PartiesPageProps {
    parties: any[];
    partiesActions: {
        loadParties: () => void;
        openCreatePartyModal: () => void;
    };
}

const PartiesPage = (props: PartiesPageProps) => {
    const { partiesActions } = props;
    const [isLoading, setIsLoading] = React.useState(false);

    useEffect(() => {
        const loadParties = async () => {
            setIsLoading(true);
            try {
                await partiesActions.loadParties();
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
            }
        };
        if (!props.parties || props.parties.length === 0) loadParties();
    }, []);

    const openCreateParty = () => {
        partiesActions.openCreatePartyModal();
    };

    return (
        <Container>
            <Dimmer active={isLoading} inverted>
                <Loader />
            </Dimmer>
            <React.Fragment>
                <Header as="h2">Party Management</Header>
                <PartiesListWrapper />
                <Button primary onClick={openCreateParty}>
                    Add new Party
                </Button>
            </React.Fragment>
        </Container>
    );
};

export default ErrorBoundary(PartiesWrapper(PartiesPage));
