/* eslint-disable import/no-named-as-default */
import React from 'react';
import * as PropTypes from 'prop-types';
import { Switch, Route, withRouter } from 'react-router-dom';
import ReactHighcharts from 'react-highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsCustomEvents from 'highcharts-custom-events';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';

import addSolidGauge from 'highcharts/modules/solid-gauge';
import addSankey from 'highcharts/modules/sankey';

// import highmaps from 'highcharts/modules/map';
// import addEuropeMap from './common/dashboard/mapdata/europe';

import { Grid, Sidebar } from 'semantic-ui-react';
import Moment from 'react-moment';
import { bindActionCreators } from 'redux';

import HomePage from './HomePage';
import NotFoundPage from './NotFoundPage';
import ActivitiesPage from './activity/ActivitiesPage';
import ActivityPage from './activity/ActivityPage';
import CreateProgrammePage from './programme/CreateProgrammePage';
import {
    defaultDashboards,
    defaultDateTimeFormat,
    gradientColors,
} from '../constants/config';
import ProgrammesPage from './programme/ProgrammesPage';
import ProgrammePage from './programme/ProgrammePage';
import ProgrammeExplorerPage from './programme/ProgrammeExplorerPage';
import ProgrammeFieldManagerPage from './programme/ProgrammeFieldManagerPage';
import MainMenu from './common/menus/MainMenu';
import ExecutionPage from './execution/ExecutionPage';
import TicketRedirect from './ticket/TicketRedirect';
import LoginPage from './user/LoginPage';
import PrivateRoute from './user/PrivateRoute';
import { connect } from 'react-redux';
import PermissionPage from './permissions/PermissionPage';
import WorkflowPage from './workflow/WorkflowPage';
import WorkflowsPage from './workflow/WorkflowsPage';
import TicketsDashboard from './ticket/TicketsDashboard';
import ExecutionPlansPage from './executionPlan/ExecutionPlansPage';
import ExecutionPlanPage from './executionPlan/ExecutionPlanPage';

import TopMenu from './common/menus/TopMenu';
import CreateExecutionPlanPage from './executionPlan/CreateExecutionPlanPage';
import PartyPage from './party/PartyPage';
import ModalRoot, { MODAL_COMPONENTS } from './../components/common/ModalRoot';
import PartiesPage from './parties/PartiesPage';
import ApiKeysPage from './admin/ApiKeys/ApiKeysPage';
import DashboardDemoPage from './dashboard/DashboardDemoPage';
import SidebarRoot from './../components/common/SidebarRoot';
import * as uiActions from '../actions/uiActions';
import DashboardLayoutDemo from './dashboard/DashboardLayoutDemo';
import WidgetBuilderPage from './dashboard/ChartBuilderPage';
import DashboardsPage from './dashboard/DashboardsPage';
import DashboardPage from './dashboard/DashboardPage';
import ChartsPage from './charts/ChartsPage';
import ResetPasswordPage from './user/ResetPasswordPage';
import RiskAppetitePage from './party/RiskAppetitePage';
import AdminPage from './admin/AdminPage';
import EventsPage from './EventsPage';
import TicketsPage from './ticket/TicketsPage';
import ApiCallsPage from './apiCalls/ApiCallsPage';
import ApiCallPage from './apiCalls/ApiCallPage';
import UsersPage from './user/UsersPage';
import FollowUpWrapper from './followUp/FollowUp';
import ContextMenuRoot from './contextMenu/ContextMenuRoot';
import CreateFollowUpProgrammePage from './followUp/CreateFollowUpProgrammePage';
import ExecutionsSearchPage from './execution/ExecutionsSearchPage';
import FairValuePage from './fairValue/FairValuePage';
import ProductsPage from './products/ProductsPage';
import DistributorsPage from './distributors/DistributorsPage';
import BulkImportEntityPage from './party/entity/BulkImportEntityPage';
import BulkImportPartyPage from './party/partyimport/BulkImportPartyPage';
import StatusesPage from './status/StatusesPage';
import StatusReportsPage from './status/StatusReportsPage';
import StatusReportPage from './status/StatusReportPage';
import StatusPage from './status/StatusPage';
import ThresholdsPage from './thresholds/ThresholdsPage';
import QueuePage from './queue/QueuePage';
import TestPage from './TestPage';
import DelegatesPage from './delegate/DelegatesPage';
import ServerConfigurationPage from './admin/ServerConfigurationPage';
import TermsAndConditionsApi from '../api/termsAndConditionsApi';
import QueueStatusPage from './queue/QueueStatusPage';
import LookUpConfigurationPage from './admin/LookUpConfigurationPage';
import ProductPage from './product/ProductPage';
import RatingConfigurationPage from './admin/RatingConfigurationPage';

HighchartsMore(ReactHighcharts.Highcharts);
HighchartsCustomEvents(ReactHighcharts.Highcharts);
HighchartsExporting(ReactHighcharts.Highcharts);
HighchartsExportData(ReactHighcharts.Highcharts);
addSolidGauge(ReactHighcharts.Highcharts);
addSankey(ReactHighcharts.Highcharts);
// highmaps(ReactHighcharts.Highcharts);
// addEuropeMap(ReactHighcharts.Highcharts);

// ReactHighcharts.Highcharts.setOptions({
//   colors: [
//     gradientColors.orange,
//     gradientColors.yellow,
//     gradientColors.teal,
//     gradientColors.violet,
//     gradientColors.brown
//   ]
// });

Moment.globalFormat = defaultDateTimeFormat;

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.onRouteChanged();
        }
        if (
            prevProps.currentUser?.loggedIn !==
                this.props.currentUser?.loggedIn &&
            this.props.currentUser?.loggedIn
        ) {
            TermsAndConditionsApi.getTermsAndConditions().then(
                (termsAndConditionsList) => {
                    if (
                        termsAndConditionsList &&
                        termsAndConditionsList.length > 0
                    ) {
                        this.props.uiActions.showModal({
                            modalType:
                                MODAL_COMPONENTS.TERMS_AND_CONDITIONS_MODAL,
                            modalProps: {
                                termsAndConditionsList,
                            },
                        });
                    }
                },
            );
        }
    }

    componentDidMount() {
        if (this.props.currentUser?.loggedIn) {
            TermsAndConditionsApi.getTermsAndConditions().then(
                (termsAndConditionsList) => {
                    if (
                        termsAndConditionsList &&
                        termsAndConditionsList.length > 0
                    ) {
                        this.props.uiActions.showModal({
                            modalType:
                                MODAL_COMPONENTS.TERMS_AND_CONDITIONS_MODAL,
                            modalProps: {
                                termsAndConditionsList,
                            },
                        });
                    }
                },
            );
        }
    }

    onRouteChanged() {
        const {
            sidebar: { showSidebar },
        } = this.props;
        showSidebar && this.closeSidebar();
    }

    closeSidebar = () => {
        const { uiActions } = this.props;
        uiActions.closeSidebar();
    };

    getColumnClasses = (loggedIn, isMenuExpanded) => {
        if (loggedIn) {
            return isMenuExpanded
                ? 'content-column-menu-expanded'
                : 'content-column-menu-hidden';
        }
        return '';
    };

    render() {
        const {
            sidebar: { showSidebar },
        } = this.props;
        const { isMenuExpanded, currentUser } = this.props;
        const { loggedIn } = currentUser;
        return (
            <React.Fragment>
                <ContextMenuRoot />
                <SidebarRoot />
                <Grid columns={2} className="page-layout__main-grid">
                    {loggedIn && (
                        <Grid.Column
                            className={` hide-for-print page-layout__menu-column ${
                                currentUser && isMenuExpanded
                                    ? 'menu-column-expanded'
                                    : ''
                            }`}
                        >
                            <Sidebar.Pushable
                                as="div"
                                onClick={showSidebar ? this.closeSidebar : null}
                                page="true"
                                style={{
                                    overflow: showSidebar ? 'hidden' : null,
                                }}
                            >
                                <Sidebar.Pusher
                                    as="div"
                                    dimmed={showSidebar}
                                    onClick={this.clicked}
                                >
                                    <MainMenu />
                                </Sidebar.Pusher>
                            </Sidebar.Pushable>
                        </Grid.Column>
                    )}
                    <Grid.Column
                        className={`page-layout__content-column ${this.getColumnClasses(
                            loggedIn,
                            isMenuExpanded,
                        )}`}
                    >
                        <Sidebar.Pushable
                            as="div"
                            onClick={showSidebar ? this.closeSidebar : null}
                            page="true"
                            style={{ overflow: showSidebar ? 'hidden' : null }}
                        >
                            <Sidebar.Pusher
                                as="div"
                                dimmed={showSidebar}
                                onClick={this.clicked}
                            >
                                <div
                                    ref={this.handleContextRef}
                                    className={
                                        loggedIn
                                            ? 'app-container'
                                            : 'app-container --logged-out'
                                    }
                                >
                                    {loggedIn && <TopMenu />}

                                    <Switch>
                                        <PrivateRoute
                                            currentUser={currentUser}
                                            exact
                                            path="/"
                                            component={HomePage}
                                        />
                                        <PrivateRoute
                                            currentUser={currentUser}
                                            path="/questions"
                                            component={ActivitiesPage}
                                        />
                                        <PrivateRoute
                                            currentUser={currentUser}
                                            path="/question/:id"
                                            component={ActivityPage}
                                        />
                                        <PrivateRoute
                                            currentUser={currentUser}
                                            path="/programme/:id/edit"
                                            component={CreateProgrammePage}
                                            edit={true}
                                        />
                                        <PrivateRoute
                                            currentUser={currentUser}
                                            path="/programme/:id/fieldManager"
                                            component={
                                                ProgrammeFieldManagerPage
                                            }
                                        />
                                        <PrivateRoute
                                            currentUser={currentUser}
                                            path="/programme/:id"
                                            component={ProgrammePage}
                                        />
                                        <PrivateRoute
                                            currentUser={currentUser}
                                            path="/programme"
                                            component={CreateProgrammePage}
                                        />
                                        <PrivateRoute
                                            currentUser={currentUser}
                                            path="/programmes"
                                            component={ProgrammesPage}
                                        />
                                        <PrivateRoute
                                            currentUser={currentUser}
                                            path="/programmeExplorer"
                                            component={ProgrammeExplorerPage}
                                        />
                                        <PrivateRoute
                                            currentUser={currentUser}
                                            path="/executions"
                                            component={ExecutionsSearchPage}
                                        />
                                        <PrivateRoute
                                            currentUser={currentUser}
                                            path="/execution/:id/:category?/:ticketId?"
                                            component={ExecutionPage}
                                        />
                                        <PrivateRoute
                                            currentUser={currentUser}
                                            path="/ticket/:id"
                                            component={TicketRedirect}
                                        />
                                        <PrivateRoute
                                            currentUser={currentUser}
                                            path="/ticketDashboard"
                                            component={TicketsDashboard}
                                        />
                                        <PrivateRoute
                                            currentUser={currentUser}
                                            path="/permission/:id"
                                            component={PermissionPage}
                                        />
                                        <PrivateRoute
                                            currentUser={currentUser}
                                            path="/workflows"
                                            component={WorkflowsPage}
                                        />
                                        <PrivateRoute
                                            currentUser={currentUser}
                                            path="/workflow/:id"
                                            component={WorkflowPage}
                                        />
                                        <PrivateRoute
                                            currentUser={currentUser}
                                            path="/workflow"
                                            component={WorkflowPage}
                                        />
                                        <PrivateRoute
                                            currentUser={currentUser}
                                            path="/executionPlans"
                                            component={ExecutionPlansPage}
                                        />
                                        <PrivateRoute
                                            currentUser={currentUser}
                                            path="/executionPlan/:id/:category?"
                                            component={ExecutionPlanPage}
                                        />
                                        <PrivateRoute
                                            currentUser={currentUser}
                                            path="/executionPlan"
                                            component={CreateExecutionPlanPage}
                                        />
                                        <PrivateRoute
                                            currentUser={currentUser}
                                            path="/party/:id/riskAppetite"
                                            component={RiskAppetitePage}
                                        />
                                        <PrivateRoute
                                            currentUser={currentUser}
                                            path="/party/:id/bulkImportEntities/:type"
                                            component={BulkImportEntityPage}
                                        />
                                        <PrivateRoute
                                            currentUser={currentUser}
                                            path="/party/:id/bulkImportParties/:type"
                                            component={BulkImportPartyPage}
                                        />
                                        <PrivateRoute
                                            currentUser={this.props.currentUser}
                                            path="/party/:id/distributors"
                                            component={DistributorsPage}
                                        />
                                        <PrivateRoute
                                            currentUser={currentUser}
                                            path="/party/:id/:menuItem?"
                                            component={PartyPage}
                                        />
                                        <PrivateRoute
                                            currentUser={currentUser}
                                            path="/apiKeys"
                                            component={ApiKeysPage}
                                        />
                                        <PrivateRoute
                                            currentUser={currentUser}
                                            path="/parties"
                                            component={PartiesPage}
                                        />
                                        <PrivateRoute
                                            currentUser={currentUser}
                                            path="/dashboardDemo"
                                            component={DashboardDemoPage}
                                        />
                                        <PrivateRoute
                                            currentUser={currentUser}
                                            path="/dashboardLayoutDemo"
                                            component={DashboardLayoutDemo}
                                        />
                                        <PrivateRoute
                                            currentUser={this.props.currentUser}
                                            path="/widgetBuilder/:id"
                                            component={WidgetBuilderPage}
                                        />
                                        <PrivateRoute
                                            currentUser={currentUser}
                                            path="/widgetBuilder"
                                            component={WidgetBuilderPage}
                                        />
                                        <PrivateRoute
                                            currentUser={this.props.currentUser}
                                            path="/dashboards/:id/edit"
                                            component={DashboardPage}
                                            editMode={true}
                                        />
                                        <PrivateRoute
                                            currentUser={this.props.currentUser}
                                            path="/dashboards/:id"
                                            component={DashboardPage}
                                        />
                                        <PrivateRoute
                                            currentUser={this.props.currentUser}
                                            path="/dashboards"
                                            component={DashboardsPage}
                                        />
                                        <PrivateRoute
                                            currentUser={this.props.currentUser}
                                            path="/charts"
                                            component={ChartsPage}
                                        />
                                        <PrivateRoute
                                            currentUser={this.props.currentUser}
                                            path="/apicalls/:id"
                                            component={ApiCallPage}
                                        />
                                        <PrivateRoute
                                            currentUser={this.props.currentUser}
                                            path="/apicalls"
                                            component={ApiCallsPage}
                                        />
                                        <PrivateRoute
                                            currentUser={this.props.currentUser}
                                            path="/admin"
                                            component={AdminPage}
                                        />
                                        <PrivateRoute
                                            currentUser={this.props.currentUser}
                                            path="/serverConfiguration"
                                            component={ServerConfigurationPage}
                                        />
                                        <PrivateRoute
                                            currentUser={this.props.currentUser}
                                            path="/lookUpConfiguration"
                                            component={LookUpConfigurationPage}
                                        />
                                        <PrivateRoute
                                            currentUser={this.props.currentUser}
                                            path="/ratingConfiguration"
                                            component={RatingConfigurationPage}
                                        />
                                        <PrivateRoute
                                            currentUser={this.props.currentUser}
                                            path="/programmeStatus"
                                            component={DashboardPage}
                                            id={
                                                defaultDashboards
                                                    .programmeStatus.id
                                            }
                                            showHeader={true}
                                            forceChartRefresh={false}
                                            enableQueryString={true}
                                        />
                                        <PrivateRoute
                                            currentUser={this.props.currentUser}
                                            path="/reviewManagement"
                                            component={DashboardPage}
                                            id={
                                                defaultDashboards
                                                    .reviewManagement.id
                                            }
                                            showHeader={true}
                                            forceChartRefresh={true}
                                        />
                                        <PrivateRoute
                                            currentUser={this.props.currentUser}
                                            path="/riskDistribution"
                                            component={DashboardPage}
                                            id={
                                                defaultDashboards
                                                    .riskDistribution.id
                                            }
                                            showHeader={true}
                                            forceChartRefresh={true}
                                        />
                                        <PrivateRoute
                                            currentUser={this.props.currentUser}
                                            path="/events"
                                            component={EventsPage}
                                        />
                                        <PrivateRoute
                                            currentUser={this.props.currentUser}
                                            path="/ticketsearch"
                                            component={TicketsPage}
                                        />
                                        <PrivateRoute
                                            currentUser={this.props.currentUser}
                                            path="/users"
                                            component={UsersPage}
                                        />
                                        <PrivateRoute
                                            currentUser={this.props.currentUser}
                                            path="/fairValue"
                                            component={FairValuePage}
                                        />
                                        <PrivateRoute
                                            currentUser={this.props.currentUser}
                                            path="/products"
                                            component={ProductsPage}
                                        />
                                        <PrivateRoute
                                            currentUser={this.props.currentUser}
                                            path="/product/:id"
                                            component={ProductPage}
                                        />
                                        <PrivateRoute
                                            currentUser={this.props.currentUser}
                                            path="/followup"
                                            component={
                                                CreateFollowUpProgrammePage
                                            }
                                        />
                                        <PrivateRoute
                                            currentUser={this.props.currentUser}
                                            path="/metrics/:id"
                                            component={StatusPage}
                                        />
                                        <PrivateRoute
                                            currentUser={this.props.currentUser}
                                            path="/metrics"
                                            component={StatusesPage}
                                        />
                                        <PrivateRoute
                                            currentUser={this.props.currentUser}
                                            path="/metricReports/:id"
                                            component={StatusReportPage}
                                        />{' '}
                                        <PrivateRoute
                                            currentUser={this.props.currentUser}
                                            path="/queue/:id"
                                            component={QueuePage}
                                        />
                                        <PrivateRoute
                                            currentUser={this.props.currentUser}
                                            path="/metricReports"
                                            component={StatusReportsPage}
                                        />
                                        <PrivateRoute
                                            currentUser={this.props.currentUser}
                                            path="/fairvalueSummary"
                                            component={DashboardPage}
                                            id={
                                                defaultDashboards
                                                    .fairvalueSummary.id
                                            }
                                            showHeader={true}
                                            forceChartRefresh={true}
                                        />
                                        <PrivateRoute
                                            currentUser={this.props.currentUser}
                                            path="/fairvalueDistributorStatus"
                                            component={DashboardPage}
                                            id={
                                                defaultDashboards
                                                    .fairvalueDistributorStatus
                                                    .id
                                            }
                                            showHeader={true}
                                            forceChartRefresh={true}
                                        />
                                        <PrivateRoute
                                            currentUser={this.props.currentUser}
                                            path="/fairvalueProductStatus"
                                            component={DashboardPage}
                                            id={
                                                defaultDashboards
                                                    .fairvalueProductStatus.id
                                            }
                                            showHeader={true}
                                            forceChartRefresh={true}
                                        />
                                        <PrivateRoute
                                            currentUser={this.props.currentUser}
                                            path="/fairvaluePOGOverview"
                                            component={DashboardPage}
                                            id={
                                                defaultDashboards
                                                    .fairvaluePOGOverview.id
                                            }
                                            showHeader={true}
                                            forceChartRefresh={true}
                                        />
                                        <PrivateRoute
                                            currentUser={this.props.currentUser}
                                            path="/delegates"
                                            component={DelegatesPage}
                                        />
                                        <PrivateRoute
                                            currentUser={this.props.currentUser}
                                            path="/queueStatus"
                                            component={QueueStatusPage}
                                        />
                                        <Route
                                            path="/resetPassword"
                                            component={ResetPasswordPage}
                                        />
                                        <Route
                                            path="/thresholds"
                                            component={ThresholdsPage}
                                        />
                                        <Route
                                            path="/login"
                                            component={LoginPage}
                                        />
                                        <Route
                                            path="/testPage"
                                            component={TestPage}
                                        />
                                        <Route component={NotFoundPage} />
                                    </Switch>
                                    <ModalRoot />
                                </div>
                            </Sidebar.Pusher>
                        </Sidebar.Pushable>
                    </Grid.Column>
                </Grid>
            </React.Fragment>
        );
    }
}

App.propTypes = {
    children: PropTypes.element,
    currentUser: PropTypes.object,
    isMenuExpanded: PropTypes.bool,
    uiActions: PropTypes.object,
    sidebar: PropTypes.object,
    location: PropTypes.object,
};

function mapStateToProps(state) {
    return {
        currentUser: state.currentUser,
        isMenuExpanded: state.uiStatus.isMenuExpanded,
        sidebar: state.uiStatus,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        uiActions: bindActionCreators(uiActions, dispatch),
    };
}

export default withRouter(
    FollowUpWrapper(connect(mapStateToProps, mapDispatchToProps)(App)),
);
