import * as _ from 'lodash';
import { Comment } from '../types';

export function buildCommentTree(comments: Comment[]): Comment[] {
    const clonedComments = _.cloneDeep(comments);
    const commentMap = clonedComments.reduce<Map<number, Comment>>(
        (map, comment) => {
            comment.replies = []; // Initialize the replies property
            map.set(comment.id, comment);
            return map;
        },
        new Map(),
    );

    return clonedComments.reduce<Comment[]>((tree, comment) => {
        if (comment.parentCommentId === null) {
            tree.push(comment); // Root comments are added directly to the tree
        } else {
            // Find the parent comment in the map and add this comment to its replies
            const parentComment = commentMap.get(comment.parentCommentId);
            parentComment?.replies?.push(comment);
        }
        return tree;
    }, []);
}
