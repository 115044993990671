import _ from 'lodash';
import moment from 'moment';
import ArgTypes from '../ArgTypes';

const groupByDate = function (args) {
    const { dataSet, groupBy, data, outputKey } = args;
    if (!data) return;

    const dataToGroup = _.chain(data).get(dataSet).filter(groupBy).value();

    const maxDate = moment(
        _.chain(dataToGroup).maxBy(groupBy).get(groupBy).value(),
    );
    const minDate = moment(
        _.chain(dataToGroup).minBy(groupBy).get(groupBy).value(),
    );

    const monthsDiff = maxDate.diff(minDate, 'months', true);

    const ranges = [];

    for (let i = 0; i <= monthsDiff; i++) {
        const start = minDate.clone().startOf('month').add(i, 'months');
        const end = minDate.clone().endOf('month').add(i, 'months');

        const items = _.remove(dataToGroup, (row) => {
            const m = moment(_.get(row, groupBy));
            return m.isBetween(start, end, '[)');
        });

        ranges.push({
            start,
            end,
            items,
            label: start.format('MMMM YYYY'),
            i,
        });
    }

    return {
        ...args,
        data: { ...data, [outputKey || 'groupedData']: ranges },
    };
};

groupByDate.args = {
    dataSet: ArgTypes.string,
    outputKey: ArgTypes.string,
    groupBy: ArgTypes.string,
};

export default groupByDate;
