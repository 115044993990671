import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    Icon,
    Label,
    Placeholder,
    SemanticICONS,
    SemanticSIZES,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import './EntityLabel.scss';
import { Entity } from '../../../types';
import { useAppSelector } from '../../../actions/store';
import { loadEntity } from '../../../actions/entityActions';

type EntityLabelProps = {
    entity?: Pick<Entity, 'id' | 'label' | 'entityType'>;
    entityId?: string;
    clickable?: boolean;
    size?: SemanticSIZES;
};

const EntityLabel: React.FC<EntityLabelProps> = ({
    entity,
    entityId,
    clickable = true,
    size,
}) => {
    const dispatch = useDispatch();

    const _entityId = entity?.id || entityId;

    const entityFromState = useAppSelector((state) =>
        _entityId ? state.entity.entitiesById[_entityId] : undefined,
    );
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!entity && entityId && !entityFromState) {
            setLoading(true);
            dispatch<any>(loadEntity(entityId)).then(() => setLoading(false));
        }
        if (entity && !entity?.entityType && !entityFromState?.entityType) {
            setLoading(true);
            dispatch<any>(loadEntity(entity.id)).then(() => setLoading(false));
        }
    }, [dispatch, entity, entityId, entityFromState]);

    const entityToRender: Pick<Entity, 'id' | 'label' | 'entityType'> =
        entity?.entityType ? entity : entityFromState;

    if (loading) {
        return (
            <Placeholder className="entity-label-placeholder">
                <Placeholder.Line />
            </Placeholder>
        );
    }

    if (!entityToRender) return null;

    const renderLabel = () => (
        <Label color="grey" image className="entity-label" size={size}>
            {entityToRender.entityType && (
                <Icon
                    name={
                        (entityToRender.entityType.icon as SemanticICONS) ||
                        'cube'
                    }
                />
            )}
            {entityToRender.label}
        </Label>
    );

    if (!clickable) return renderLabel();

    const executionId = entityFromState?.executions?.[0]?.id;

    return (
        <Link
            to={
                executionId
                    ? `/execution/${executionId}`
                    : `/entity/${entityToRender.id}`
            }
            className="entity-label"
        >
            {renderLabel()}
        </Link>
    );
};

export default EntityLabel;
