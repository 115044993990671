import React from 'react';
import * as PropTypes from 'prop-types';
import ActivitiesListRow from './ActivitiesListRow';
import { Table, Card, Divider } from 'semantic-ui-react';
import ActivitiesFilterRow from './ActivitiesFilterRow';
import { WrapUserContext } from '../../context/UserContext';
import * as _ from 'lodash';

const ActivitiesList = (props) => {
    const { activities, layout = 'FULL' } = props;
    const sortedActivities = _.orderBy(activities, 'sort');
    switch (layout) {
        case 'COMPACT':
            return (
                <React.Fragment>
                    <ActivitiesFilterRow {...props} asTableRow={false} />
                    <Divider />
                    <Card.Group itemsPerRow={1}>
                        {sortedActivities.map((activity) => (
                            <ActivitiesListRow
                                key={activity.id}
                                activity={activity.data || activity}
                                {...props}
                            />
                        ))}
                    </Card.Group>
                </React.Fragment>
            );
        case 'ACTIVITIES':
            return (
                <React.Fragment>
                    {sortedActivities.map((activity) => (
                        <ActivitiesListRow
                            key={activity.id}
                            activity={activity.data || activity}
                            {...props}
                        />
                    ))}
                </React.Fragment>
            );
        case 'CARD':
            return (
                <Card.Group itemsPerRow={2}>
                    {sortedActivities.map((activity) => (
                        <ActivitiesListRow
                            key={activity.id}
                            activity={activity.data || activity}
                            {...props}
                        />
                    ))}
                </Card.Group>
            );
        case 'FULL':
            return (
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell singleLine>
                                Question
                            </Table.HeaderCell>
                            <Table.HeaderCell>Status</Table.HeaderCell>
                            <Table.HeaderCell>Primary Tag</Table.HeaderCell>
                            <Table.HeaderCell>Tags</Table.HeaderCell>
                            <Table.HeaderCell>Reference</Table.HeaderCell>
                            <Table.HeaderCell>Group</Table.HeaderCell>
                            <Table.HeaderCell>Owner Party</Table.HeaderCell>
                            <Table.HeaderCell>Actions</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <ActivitiesFilterRow {...props} />

                        {sortedActivities.map((activity) => (
                            <ActivitiesListRow
                                key={activity.id}
                                activity={activity.data || activity}
                                {...props}
                            />
                        ))}
                    </Table.Body>
                </Table>
            );
    }
};

ActivitiesList.propTypes = {
    activities: PropTypes.array.isRequired,
    editActivity: PropTypes.func,
    deleteActivity: PropTypes.func,
    removeActivity: PropTypes.func,
    addActivity: PropTypes.func,
    moveActivity: PropTypes.func,
    layout: PropTypes.string,
    editable: PropTypes.bool,
};

ActivitiesList.defaultProps = {
    editable: true,
};

export default WrapUserContext(ActivitiesList);
