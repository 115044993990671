import * as _ from 'lodash';
import { verifyPassword } from './verifyPassword';

const regexpEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export type FieldErrors = {
    [key: string]: string[];
};

export function checkValidation(object, validationSchema) {
    let errors = {};

    _.forEach(validationSchema, (value, key) => {
        errors[key] = [];
        const toValidate = object[key];

        if (value.if) {
            const shouldValidate = Object.keys(value.if).every((key) => {
                return object[key] === value.if[key];
            });

            if (!shouldValidate) {
                delete errors[key];
                return;
            }
        }

        if (value.required) {
            if (!toValidate) {
                const errorMessage =
                    value.required.error || `Please supply ${key}`;
                errors[key].push(errorMessage);
            }
        }

        if (value.length) {
            if (!_.isArray(toValidate) || toValidate.length < value.length.gt) {
                const errorMessage =
                    value.length.error || `Please supply ${key}`;
                errors[key].push(errorMessage);
            }
        }

        if (value.validateObjects) {
            const validations = toValidate.map((item) =>
                checkValidation(item, value.validateObjects.schema),
            );
            _.forEach(validations, (validation) => {
                if (
                    !validation.valid &&
                    validation.errors &&
                    Array.isArray(validation.errors)
                ) {
                    errors[key] = [...errors[key], ...validation.errors];
                }
            });
        }

        if (value.email) {
            if (!regexpEmail.test(String(toValidate).toLowerCase())) {
                const errorMessage =
                    value.email.error || `Field ${key} is not valid`;
                errors[key].push(errorMessage);
            }
        }

        if (value.equal) {
            const compareField = value.equal.compareField;
            const compareValue = object[compareField];
            if (compareValue !== toValidate) {
                const errorMessage =
                    value.equal.error ||
                    `Fields ${key} and ${compareField} do not match`;
                errors[key].push(errorMessage);
            }
        }
        if (value.password) {
            const result = verifyPassword(toValidate);
            if (!result.valid) {
                errors[key].push(...result.errors);
            }
        }

        if (errors[key].length === 0) {
            delete errors[key];
        }
    });

    return {
        valid: !!_.isEmpty(errors),
        errors: errors,
    };
}

export function transformValidation(validation) {
    return _.map(_.values(validation.errors), (error) => `${error}<br/>`);
}

export function checkModalFormDataValid(validations, name, value, schema) {
    const validation = checkValidation(
        { [name]: value },
        {
            [name]: {
                required: {
                    error: `Please supply the ${name}`,
                },
            },
            ...schema,
        },
    );
    validations[name].errors = validation.errors;
    validations[name].isValid = validation.valid;
    return {
        validations,
        isModalFormDataValid: _.every(validations, { isValid: true }),
    };
}

type ValidationSchema = {
    [key: string]: {
        required?: {
            error?: string;
        };
    };
};

export function generateValidationOnRecurringScheduleSchema(requestData) {
    let validationSchema: ValidationSchema = {
        every: {
            required: { error: 'Please supply the repeat every property' },
        },
    };
    switch (requestData.endType) {
        case 'date': {
            validationSchema.endDate = {
                required: { error: 'Please supply the end date property' },
            };
            break;
        }
        case 'occurrences': {
            validationSchema.occurrences = {
                required: { error: 'Please supply the occurrences property' },
            };
            break;
        }
    }
    return validationSchema;
}
