import React from 'react';
import * as PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

import ExecutionSidebarListItem from './ExecutionSidebarListItem';
import SortableHeaderCell from '../common/SortableHeaderCell';

const ExecutionsList = (props) => {
    const { executions, handleSortChange, paginationOptions } = props;
    const renderExecutionSidebarList = () => {
        return (
            executions &&
            executions.map((execution) => {
                return (
                    <ExecutionSidebarListItem
                        key={execution.id}
                        execution={execution}
                    />
                );
            })
        );
    };
    return (
        <Table celled sortable>
            <Table.Header>
                <Table.Row>
                    <SortableHeaderCell
                        {...paginationOptions}
                        name="label"
                        handleSortChange={handleSortChange}
                        isSearchable={true}
                    >
                        Programme Name
                    </SortableHeaderCell>
                    <SortableHeaderCell
                        {...paginationOptions}
                        name="assignedParty"
                        handleSortChange={handleSortChange}
                    >
                        Assign Party
                    </SortableHeaderCell>
                    <SortableHeaderCell
                        {...paginationOptions}
                        name="dueDate"
                        handleSortChange={handleSortChange}
                    >
                        Due Date
                    </SortableHeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>{renderExecutionSidebarList()}</Table.Body>
        </Table>
    );
};

ExecutionsList.propTypes = {
    executions: PropTypes.array.isRequired,
    handleSortChange: PropTypes.func,
    paginationOptions: PropTypes.object,
};

export default ExecutionsList;
