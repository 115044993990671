import apiTools from './apiTools';

class ConversationApi {
    static saveComment(conversation, comment) {
        const apiMethod = comment.id ? apiTools.patch : apiTools.post;
        return apiMethod(`Comments`, {
            ...comment,
            conversationId: conversation.id,
        }).then((response) => {
            return response.data;
        });
    }
    static getConversation(conversationId) {
        return apiTools
            .get(`Conversations/${conversationId}`, {
                filter: {
                    include: [
                        {
                            relation: 'comments',
                            scope: {
                                include: ['creator'],
                                where: {
                                    and: [{ history: false }],
                                },
                            },
                        },
                    ],
                },
            })
            .then((response) => {
                return response.data;
            });
    }
}

export default ConversationApi;
