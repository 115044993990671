import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { Container, Dimmer, Loader, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import ExecutionPlanWrapper from './ExecutionPlan';
import ExecutionPlanList from './ExecutionPlanList';
import ErrorBoundary from './../common/ErrorBoundary';

class ExecutionPlansPage extends Component {
    state = {
        loading: false,
    };

    componentWillMount() {
        const { executionPlanStateActions } = this.props;
        this.setState({ loading: true });
        executionPlanStateActions.loadExecutionPlans().then(() => {
            this.setState({ loading: false });
        });
    }

    render() {
        const { loading } = this.state;
        return (
            <Container>
                <Dimmer active={loading} inverted>
                    <Loader disabled={!loading} />
                </Dimmer>
                <ExecutionPlanList />
                <Button as={Link} to="/executionPlan" primary>
                    Create new
                </Button>
            </Container>
        );
    }
}

ExecutionPlansPage.propTypes = {
    executionPlanStateActions: PropTypes.object,
    executionPlanActions: PropTypes.object,
};

export default ErrorBoundary(ExecutionPlanWrapper(ExecutionPlansPage));
