import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import { Dropdown } from 'semantic-ui-react';
import userApi from '../../../api/userApi';

class UserSearch extends Component {
    constructor(props) {
        super(props);
        this.currentUser = null;
        this.state = {
            options: [],
            userId: null,
        };
    }

    componentDidMount() {
        const { value, principalId } = this.props;
        if (value) {
            this.getUser(value);
        }
        if (principalId) {
            this.getUserByPrincipalId(principalId);
        }
    }

    componentDidUpdate(prevProps) {
        const { value: prevPropsValue, principalId: prevPrincipalId } =
            prevProps;
        const { value, principalId } = this.props;
        if (value && prevPropsValue !== value) {
            this.getUser(value);
        }

        if (principalId && prevPrincipalId !== principalId) {
            this.getUserByPrincipalId(principalId);
        }
    }

    mapUsersToOptions = (users) => {
        const { sortItemsAlphabetically = false } = this.props;
        let options = _.map(users, (user) => ({
            key: user.id,
            text: `${user.firstName} ${user.lastName}${
                user.party ? ` (${user.party.label})` : ''
            }`,
            value: user.id,
        }));
        if (sortItemsAlphabetically) {
            options = _.orderBy(options, 'text');
        }
        const optionsBase = [];
        return [...optionsBase, ...options];
    };

    handleOnChange = (event, data) => {
        const chosenUser = _.find(this.state.usersData, { id: data.value });
        this.currentUser = chosenUser;
        if (this.props.returnUserObject) {
            this.props.onChange(
                event,
                Object.assign({}, data, {
                    value: chosenUser,
                }),
            );
        } else {
            this.props.onChange(event, data);
        }
    };

    handleSearchChange = (event, { searchQuery = '' }) => {
        if (searchQuery.length < 3) return;
        this.performSearch(searchQuery, this.props.filterByParty);
    };

    performSearch = (searchQuery, partyId) => {
        userApi.getUsers(searchQuery, partyId).then((data) => {
            const options = this.mapUsersToOptions(data);
            this.setState({
                usersData: data,
                options,
                users: _.keyBy(data, 'id'),
            });
        });
    };

    handleOnClose = (event) => {
        const { onClose } = this.props;
        if (onClose) onClose(event);
        if (!this.currentUser) return;
        const options = this.mapUsersToOptions([this.currentUser]);
        this.setState({ options });
    };

    handleOnOpen = () => {
        if (!this.props.filterByParty) return;
        this.performSearch('', this.props.filterByParty);
    };

    getUser = (value) => {
        userApi.getUser(value).then((data) => {
            const options = this.mapUsersToOptions([data]);
            this.currentUser = data;
            this.setState({
                usersData: [data],
                options,
                users: _.keyBy([data], 'id'),
            });
        });
    };

    getUserByPrincipalId = (principalId) => {
        userApi.getUserByPrincipalId(principalId).then((data) => {
            const options = this.mapUsersToOptions([data]);
            this.currentUser = data;
            this.setState({
                usersData: [data],
                options,
                users: _.keyBy([data], 'id'),
                userId: data.id,
            });
        });
    };

    render() {
        const { saving, value, name, disabled, clearable } = this.props;
        const { options, userId } = this.state;
        return (
            <Dropdown
                fluid
                selection
                clearable={clearable}
                search={true}
                selectOnBlur={false}
                selectOnNavigation={false}
                options={options}
                value={value || userId}
                placeholder="Choose User"
                onChange={this.handleOnChange}
                onOpen={this.handleOnOpen}
                onClose={this.handleOnClose}
                onSearchChange={this.handleSearchChange}
                disabled={disabled || saving}
                loading={saving}
                name={name || ''}
            />
        );
    }
}

UserSearch.propTypes = {
    onChange: PropTypes.func,
    onClose: PropTypes.func,
    saving: PropTypes.bool,
    value: PropTypes.string,
    name: PropTypes.string,
    disabled: PropTypes.bool,
    returnUserObject: PropTypes.bool,
    filterByParty: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.string,
        PropTypes.number,
    ]),
    sortItemsAlphabetically: PropTypes.bool,
    clearable: PropTypes.bool,
    principalId: PropTypes.number,
};

UserSearch.defaultProps = {
    showUnassigned: true,
};

export default UserSearch;
