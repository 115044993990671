import * as types from '../constants/actionTypes';
import activityApi from '../api/activityApi';

export function loadActivities(filters = {}) {
    return function (dispatch) {
        return activityApi.getActivities(filters).then((activities) => {
            dispatch({
                type: types.LOAD_ACTIVITIES_SUCCESS,
                activities,
            });
        });
    };
}
