import apiTools from './apiTools';

class ChartApi {
    static getAPICalls() {
        return apiTools.get('APICalls').then((response) => {
            return response.data;
        });
    }

    static getAPICall({ id }) {
        return apiTools.get(`APICalls/${id}`).then((response) => {
            return response.data;
        });
    }

    static saveAPICall({ label, options, modelArguments, id }) {
        if (id) {
            return apiTools.patch(`APICalls`, {
                id,
                label,
                arguments: modelArguments,
                options,
            });
        }
        return apiTools.post(`APICalls`, {
            label,
            options,
            arguments: modelArguments,
        });
    }

    static getCharts() {
        const filter = {
            include: { apiCalls: 'apiCall' },
        };
        return apiTools.get('Charts', { filter }).then((response) => {
            return response.data;
        });
    }
    static getChart({ id }) {
        const filter = {
            include: { apiCalls: 'apiCall' },
        };
        return apiTools.get(`Charts/${id}`, { filter }).then((response) => {
            return response.data;
        });
    }

    static saveChart({ label, options, id }) {
        if (id) {
            return apiTools.patch(`Charts`, {
                id,
                label,
                options,
            });
        }
        return apiTools.post(`Charts`, {
            label,
            options,
        });
    }

    static addApiCall({ apiCallId, chartId, name }) {
        return apiTools.post(`Charts/${chartId}/apiCalls`, {
            apiCallId,
            chartId,
            name,
        });
    }
    static removeApiCall({ chartId, chartApiCallId }) {
        return apiTools.delete(`Charts/${chartId}/apiCalls/${chartApiCallId}`);
    }
}

export default ChartApi;
