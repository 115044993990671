import React from 'react';
import { Button, Icon } from 'semantic-ui-react';

type ProgrammeActionButtonsProps = {
    handleMenuAction: (
        event: React.MouseEvent<HTMLButtonElement>,
        data: any,
    ) => void;
};

const ProgrammeActionButtons = ({
    handleMenuAction,
}: ProgrammeActionButtonsProps) => {
    return (
        <Button.Group attached="top">
            <Button text="Edit" onClick={handleMenuAction} name="edit">
                <Icon name="edit" />
                Edit Programme
            </Button>
        </Button.Group>
    );
};

export default ProgrammeActionButtons;
