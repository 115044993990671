import React from 'react';
import * as PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

import ProductTableRow from './ProductTableRow';
import SortableHeaderCell from '../common/SortableHeaderCell';
import { getActiveItems } from '../../utils/PartyUtils';
import { useAppSelector } from '../../actions/store';
import { orderBy } from 'lodash';
import { ProductListMode } from './ProductList';

interface ProductListProps {
    entities: any[];
    handleSortChange: (sortField: string) => void;
    paginationOptions: object;
    handleAddFilter: (filters: object) => void;
    removeFilterValues: (filters: string[]) => void;
    currentFilters: object;
    mode?: ProductListMode;
    displayedColumns?: string[];
    fetchProducts?: () => void;
    hideActionButtons?: boolean;
}

const ProductList = (props: ProductListProps) => {
    const entityType = useAppSelector(
        (state) => state.entity.entityTypes.byId['PRODUCT'],
    );

    const selectedEntities = useAppSelector(
        (state) => state.entity.selectedEntities,
    );

    const {
        entities,
        handleSortChange,
        paginationOptions,
        handleAddFilter,
        removeFilterValues,
        currentFilters,
        fetchProducts,
        displayedColumns,
    } = props;

    const renderProductList = () => {
        return getActiveItems(entities).map((product) => (
            <ProductTableRow
                displayedColumns={displayedColumns}
                product={product}
                key={product.id}
                mode={props.mode}
                selected={selectedEntities.includes(product.id)}
                fetchProducts={fetchProducts}
                hideActionButtons={props.hideActionButtons}
            />
        ));
    };

    const orderedFields = orderBy(
        entityType?.fields?.filter(
            (field) => field.activityFieldType !== 'Hidden',
        ),
        ['sort'],
    );
    return (
        <Table celled sortable>
            <Table.Header>
                <Table.Row>
                    <SortableHeaderCell
                        {...paginationOptions}
                        name="label"
                        handleSortChange={handleSortChange}
                        handleAddFilter={handleAddFilter}
                        removeFilterValues={removeFilterValues}
                        currentFilters={currentFilters}
                    >
                        Product Label
                    </SortableHeaderCell>

                    {orderedFields?.map((field) => {
                        if (displayedColumns) {
                            return displayedColumns.includes(field.id) ? (
                                <SortableHeaderCell
                                    {...paginationOptions}
                                    name={field.id}
                                    handleSortChange={handleSortChange}
                                    handleAddFilter={handleAddFilter}
                                    removeFilterValues={removeFilterValues}
                                    currentFilters={currentFilters}
                                >
                                    {field.label}
                                </SortableHeaderCell>
                            ) : null;
                        }
                        return (
                            <SortableHeaderCell
                                {...paginationOptions}
                                name={field.id}
                                handleSortChange={handleSortChange}
                                handleAddFilter={handleAddFilter}
                                removeFilterValues={removeFilterValues}
                                currentFilters={currentFilters}
                            >
                                {field.label}
                            </SortableHeaderCell>
                        );
                    })}

                    {!props.hideActionButtons && (
                        <Table.HeaderCell collapsing>Actions</Table.HeaderCell>
                    )}
                </Table.Row>
            </Table.Header>
            <Table.Body>{renderProductList()}</Table.Body>
        </Table>
    );
};

export default ProductList;
