import React from 'react';
import {
    defaultCurrency,
    defaultDateFormat,
    kpiValueFormats,
} from '../constants/config';
import Moment from 'react-moment';
import { APPLICABLE_DATE_TYPES, MONTHS } from './ExecutionPlanLaunchConsts';
import moment from 'moment';
import * as _ from 'lodash';

export function formatValue(value, format, currencyType?) {
    if (!value || value === '-') return value;
    switch (format) {
        case kpiValueFormats.Percentage: {
            return `${Math.round(value * 100)}%`;
        }
        case kpiValueFormats.Currency: {
            const number = parseInt(value);
            if (number > 1000000) {
                return (
                    (number / 1000000).toLocaleString(undefined, {
                        style: 'currency',
                        currency: currencyType || defaultCurrency,
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                    }) + 'm'
                );
            }
            return number.toLocaleString(undefined, {
                style: 'currency',
                currency: currencyType || defaultCurrency,
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            });
        }
        case kpiValueFormats.Number: {
            return `${value}`;
        }
        case kpiValueFormats.Date: {
            return value ? <Moment format="DD/MM/YY">{value}</Moment> : '';
        }

        default:
            return `${value}`;
    }
}

export function formatApplicableDate(dateValue, dateType) {
    switch (dateType) {
        case APPLICABLE_DATE_TYPES.MONTH: {
            const momentDate = moment(dateValue);
            const month = _.find(MONTHS, { id: momentDate.utc().month() }).name;
            return `${month} ${momentDate.utc().year()}`;
        }
        case APPLICABLE_DATE_TYPES.QUARTER: {
            const momentDate = moment(dateValue);
            return `Q${momentDate.utc().quarter()} ${momentDate.utc().year()}`;
        }
        case APPLICABLE_DATE_TYPES.YEAR: {
            const momentDate = moment(dateValue);
            return `${momentDate.utc().year()}`;
        }
        default: {
            const momentDate = moment(dateValue);
            return momentDate.format(defaultDateFormat);
        }
    }
}
