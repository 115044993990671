import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import {
    Button,
    Modal,
    Input,
    Dropdown,
    Dimmer,
    Loader,
} from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import toastr from 'toastr';

import {
    createPermission,
    loadPermission,
} from '../../actions/permissionActions';
import * as uiActions from '../../actions/uiActions';
import { mapToOptions } from '../../utils/React';

class PermissionModal extends Component {
    state = {
        newNodeName: '',
        newNodeTypeId: '',
        loading: false,
    };

    inputHandler(event, data) {
        this.setState({ newNodeName: data.value });
    }

    dropdownHandler(event, data) {
        this.setState({ newNodeTypeId: data.value });
    }

    createPermission() {
        const {
            id,
            createPermission,
            loadPermission,
            uiActions,
            currentNodeId,
        } = this.props;

        const { newNodeName, newNodeTypeId } = this.state;

        this.setState({ loading: true });

        createPermission(id, newNodeName, newNodeTypeId)
            .then(() => {
                toastr.success('ChildNode was created');
                loadPermission(currentNodeId, null, 'CURRENT');
                this.setState({ loading: false });
                uiActions.closeModal();
            })
            .catch((err) => {
                toastr.error(err);
                this.setState({ loading: false });
            });
    }

    render() {
        const permissionTypesOptions = mapToOptions(this.props.permissionTypes);

        const { name } = this.props;
        const { closeModal } = this.props.uiActions;
        const { loading } = this.state;

        return (
            <div>
                <Modal size="mini" open={true} onClose={() => closeModal()}>
                    <Dimmer active={loading} inverted>
                        <Loader disabled={!loading} />
                    </Dimmer>
                    <Modal.Header>Add node to {name}</Modal.Header>
                    <Modal.Content>
                        <Input
                            fluid
                            placeholder="New node name"
                            onChange={(event, data) =>
                                this.inputHandler(event, data)
                            }
                        />
                        <br />
                        <Dropdown
                            fluid
                            selection
                            placeholder="Node Type"
                            options={permissionTypesOptions}
                            onChange={(event, data) =>
                                this.dropdownHandler(event, data)
                            }
                        />
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => closeModal()}>Cancel</Button>
                        <Button
                            positive
                            icon="checkmark"
                            labelPosition="right"
                            content="Create"
                            onClick={() => this.createPermission()}
                        />
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }
}

PermissionModal.propTypes = {
    uiActions: PropTypes.object.isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    currentNodeId: PropTypes.string.isRequired,
    permissionTypes: PropTypes.array.isRequired,
    createPermission: PropTypes.func.isRequired,
    loadPermission: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
    return {
        uiActions: bindActionCreators(uiActions, dispatch),
        createPermission: (id, name, type) =>
            dispatch(createPermission(id, name, type)),
        loadPermission: (id, name) => dispatch(loadPermission(id, null, name)),
    };
};

export default connect(null, mapDispatchToProps)(PermissionModal);
