import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    Button,
    Checkbox,
    Dimmer,
    Form,
    Input,
    Loader,
    Message,
    Modal,
} from 'semantic-ui-react';
import * as _ from 'lodash';

import * as uiActions from '../../actions/uiActions';
import partyApi from '../../api/partyApi';
import { loadParty } from '../../actions/partyActions';
import { useAppSelector } from '../../actions/store';
import { convertAPIError } from '../../utils/ErrorUtils';
import { checkValidation, FieldErrors } from '../../utils/Validation';
import {
    CreateDistributorForm,
    DistributorFormData,
    distributorFormValidationSchema,
} from './CreateDistributorForm';
import slugify from 'slugify';

type Props = {
    partyId: string;
    onDistributorCreated?: () => void;
};
const CreateDistributorModal = ({ partyId, onDistributorCreated }: Props) => {
    const dispatch = useDispatch();

    const [saving, setSaving] = useState(false);
    const [error, setError] = useState<string[]>();
    const [fieldErrors, setFieldErrors] = useState<FieldErrors>();
    const [distributor, setDistributor] = useState<DistributorFormData>({
        firstName: '',
        lastName: '',
        email: '',
        label: '',
        ref: '',
        isAdmin: true,
        sendNotification: true,
    });

    const party = useAppSelector((state) => state.party);
    const partyGroupNode = party?.permissionsNode?.children?.[0];

    useEffect(() => {
        const fetchParty = async () => {
            const filter = {
                include: [
                    {
                        relation: 'permissionsNode',
                        scope: {
                            include: [
                                {
                                    relation: 'children',
                                    scope: {
                                        where: {
                                            structuralNodeTypeId: 'PartyGroup',
                                        },
                                        include: [],
                                    },
                                },
                            ],
                        },
                    },
                ],
            };

            await dispatch(loadParty(partyId, filter));
        };
        fetchParty();
    }, []);

    useEffect(() => {
        if (!distributor.label) {
            setDistributor((prevState) => ({ ...prevState, ref: '' }));
            return;
        }
        const slugifiedName = slugify(distributor.label.toUpperCase(), {
            lower: false,
        }).slice(0, 16);
        setDistributor((prevState) => ({ ...prevState, ref: slugifiedName }));
    }, [distributor.label]);

    const handleInputChanged = (event, { name, value, checked }) => {
        setDistributor({ ...distributor, [name]: value || checked });
    };

    const handleCloseModal = () => {
        dispatch(uiActions.closeModal());
    };

    const handleCreateDistributor = async () => {
        setError(null);
        setFieldErrors(null);
        const validation = checkValidation(
            distributor,
            distributorFormValidationSchema,
        );

        if (!validation.valid) {
            setFieldErrors(validation.errors);
            return;
        }
        try {
            setSaving(true);
            const { firstName, lastName, email, label, ref, sendNotification } =
                distributor;
            await partyApi.importRelationship(partyId, {
                partyData: {
                    firstName,
                    lastName,
                    email,
                    ref,
                    label,
                    type: 'DIST',
                    structuralNodeId: partyGroupNode.id,
                },
                tasks: sendNotification
                    ? [{ type: 'NOTIFY_USER_FAIR_VALUE' }]
                    : [],
            });
            setSaving(false);
            if (onDistributorCreated) onDistributorCreated();
            handleCloseModal();
        } catch (caughtError) {
            setSaving(false);
            setError(convertAPIError(caughtError));
        }
    };

    return (
        <Modal
            open={true}
            onClose={() => uiActions.closeModal()}
            size="mini"
            closeOnDimmerClick={false}
        >
            <Dimmer active={saving} inverted>
                <Loader disabled={!saving} />
            </Dimmer>
            <Modal.Header>Add New Distributor</Modal.Header>
            <Modal.Content>
                <CreateDistributorForm
                    data={distributor}
                    error={error}
                    fieldErrors={fieldErrors}
                    onChange={handleInputChanged}
                />
            </Modal.Content>

            <Modal.Actions>
                <Button
                    positive
                    onClick={handleCreateDistributor}
                    loading={saving}
                    disabled={saving}
                >
                    Save
                </Button>
                <Button
                    secondary
                    onClick={handleCloseModal}
                    loading={saving}
                    disabled={saving}
                >
                    Cancel
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default CreateDistributorModal;
