/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/no-did-mount-set-state */

import React, { useEffect, useState } from 'react';
import { Form, Header, SemanticWIDTHS } from 'semantic-ui-react';
import * as _ from 'lodash';
import Moment from 'react-moment';
import jsonLogic from 'json-logic-js';

import FormField from './FormField';
import { FieldAuthor } from './FormFieldAuthor';
import { checkValidation } from '../../utils/Validation';
import { defaultDateTimeFormat } from '../../constants/config';
import {
    Field,
    Entry,
    RuntimeField,
    EntityEntryField,
    EntityEntry,
} from '../../types';

// @ts-ignore - Moment type definition is incomplete
Moment.globalFormat = defaultDateTimeFormat;

type FormFieldsProps = {
    entries: (Entry | EntityEntry)[];
    fields: (Field | EntityEntryField)[];
    onChange?: (
        field: RuntimeField,
        isValid: boolean,
        outlier?: boolean,
        excludeFromStatistics?: boolean,
    ) => void;
    onFormValidation?: (isValid: boolean) => void;
    fieldIdKey?: string;
    workflowFieldIdKey?: string;
    parentId?: string;
    enableDisplayLogic?: boolean;
    showFieldKeys?: boolean;
    dataEntryAllowed?: boolean;
    editable?: boolean;
    exceptionFields?: string[];
    highlightRequired?: boolean;
    allFullWidth?: boolean;
    fullWidthFieldTypes?: string[];
    onOpenSideBar?: (field: RuntimeField) => void;
    isManufacturer?: boolean;
    fieldsPerRow?: number;
};

const isEntry = (entry: Entry | EntityEntry): entry is Entry => {
    return entry && 'field' in entry && !!entry.field && 'outlier' in entry;
};

const isField = (field: Field | EntityEntryField): field is Field => {
    return 'displayLogic' in field;
};

export const FormFields = ({
    entries = [],
    fields = [],
    onChange,
    onFormValidation,
    fieldIdKey = 'fieldId',
    workflowFieldIdKey = 'workFlowStatusFieldId',
    parentId,
    enableDisplayLogic = true,
    showFieldKeys = false,
    dataEntryAllowed = true,
    editable = true,
    exceptionFields,
    highlightRequired,
    allFullWidth,
    fullWidthFieldTypes = [],
    onOpenSideBar,
    isManufacturer,
    fieldsPerRow,
}: FormFieldsProps) => {
    const [stateFields, setStateFields] = useState<RuntimeField[]>([]);

    useEffect(() => {
        if (!_.isEmpty(entries) || fields.length > 0) {
            setStateFields(generateFields());
        }
    }, []);

    useEffect(() => {
        if (!_.isEmpty(entries) || fields.length > 0) {
            setStateFields(generateFields());
        }
    }, [entries, showFieldKeys]);

    const handleFieldDataChange = (
        field: RuntimeField,
        value: any,
        outlier?: boolean,
        excludeFromStatistics?: boolean,
    ) => {
        const updatedField = updateFieldValue(field, value);
        if (field.required) {
            validate(updatedField, outlier, excludeFromStatistics);
        } else {
            onChange(updatedField, true, outlier, excludeFromStatistics);
        }
    };

    const updateFieldValue = (
        field: RuntimeField,
        value: any,
    ): RuntimeField => {
        field.value = value !== undefined ? value : '';
        return field;
    };

    const handleFieldOnFocus = (index: number) => {
        stateFields[index].hasFocus = true;
        setStateFields([...stateFields]);
    };

    const handleFieldOnBlur = (index: number) => {
        stateFields[index].hasFocus = false;
        setStateFields([...stateFields]);
        const field = stateFields[index];
        if (field.required) {
            validate(field);
        }
    };

    const generateFields = (): RuntimeField[] => {
        const values = _.keyBy(
            entries.filter(isEntry),
            (entry) => entry.field.fieldKey,
        );

        const filteredFields = enableDisplayLogic
            ? fields.filter((field) => {
                  if (isField(field) && field.displayLogic) {
                      const logicValue = jsonLogic.apply(
                          field.displayLogic,
                          values,
                      );
                      return logicValue;
                  }
                  return true;
              })
            : fields;

        const hydratedFields = _.map(filteredFields, (field, index) => {
            const { id, label } = field;
            const fieldKey = isField(field)
                ? field.fieldKey
                : field.entityTypeId;
            const activityFieldType = isField(field)
                ? field.activityFieldType
                : undefined;
            let optionsCurrent =
                (field.activityFieldTypeId === 'Select' ||
                    field.activityFieldTypeId === 'SearchableSelect' ||
                    field.activityFieldTypeId === 'MultiSelect' ||
                    field.activityFieldTypeId === 'RadioGroup' ||
                    field.activityFieldTypeId === 'Rating' ||
                    field.activityFieldTypeId === 'CheckboxGroup') &&
                field.options?.values
                    ? field.options.values
                    : field.options;

            const entry = getEntry(id);

            const baseField = {
                index,
                name: id,
                hasFocus: false,
                errors: {},
                isValid: getIsValid(field),
                value: getEntryValue(id),
                outlier: isEntry(entry) ? entry.outlier : undefined,
                excludeFromStatistics: isEntry(entry)
                    ? entry.excludeFromStatistics
                    : undefined,
                type: activityFieldType,
                key: id,
                label: showFieldKeys ? `${label} (${fieldKey})` : label,
                parentId,
            };

            return {
                ...field,
                ...baseField,
                options: optionsCurrent,
            } as RuntimeField;
        });

        if (onFormValidation) {
            const requiredFields = _.filter(hydratedFields, { required: true });
            onFormValidation(_.every(requiredFields, { isValid: true }));
        }
        return hydratedFields;
    };

    const getIsValid = (field: { id: string; required?: boolean }): boolean => {
        if (!entries.length) return !field.required;
        return !!getEntryValue(field.id);
    };

    const getEntryValue = (id: string): any => {
        if (!entries.length) return '';
        const isSimpleField = _.keys(entries[0]).includes(fieldIdKey);
        const searchField = isSimpleField ? fieldIdKey : workflowFieldIdKey;
        const index = _.findIndex(entries, {
            [searchField]: id,
        });
        return index === -1 ? '' : entries[index].value;
    };

    const getEntry = (id: string): Entry | EntityEntry | undefined => {
        if (!entries.length) return undefined;
        const isSimpleField = _.keys(entries[0]).includes(fieldIdKey);
        const searchField = isSimpleField ? fieldIdKey : workflowFieldIdKey;
        const index = _.findIndex(entries, {
            [searchField]: id,
        });
        return index === -1 ? undefined : entries[index];
    };

    const validate = (
        field: RuntimeField,
        outlier?: boolean,
        excludeFromStatistics?: boolean,
    ) => {
        const fieldName = field.name!;
        const fieldIndex = field.index!;
        const validation = checkValidation(
            { [fieldName]: stateFields[fieldIndex].value },
            {
                [fieldName]: {
                    required: field.required && {
                        error: `Please supply the ${field.label}`,
                    },
                },
            },
        );

        stateFields[fieldIndex].errors = validation.errors;
        stateFields[fieldIndex].isValid = validation.valid;
        setStateFields([...stateFields]);

        const requiredFields = _.filter(stateFields, { required: true });
        onChange(
            field,
            _.every(requiredFields, { isValid: true }),
            outlier,
            excludeFromStatistics,
        );
    };

    const renderColumnFields = (
        fieldsArray: { field: RuntimeField; key: string }[],
    ) => {
        let result = [];
        for (let i = 0; i < fieldsArray.length / fieldsPerRow; i++) {
            const renderFields = _.slice(
                fieldsArray,
                i * fieldsPerRow,
                i * fieldsPerRow + fieldsPerRow,
            );
            result.push(renderRowFields(renderFields, i));
        }
        return _.forEach(result, (item) => item);
    };

    const renderRowFields = (
        fieldsArray: { field: RuntimeField; key: string }[],
        key: number,
    ) => {
        return (
            <Form.Group
                // @ts-ignore
                widths={`equal ${fieldsArray.length === 1 ? 'full-width' : ''}`}
                key={key}
            >
                {renderFields(fieldsArray)}
            </Form.Group>
        );
    };

    const renderDescription = (entry?: Entry) => {
        if (entry?.modifiedBy) {
            return (
                <FieldAuthor
                    user={entry.modifiedBy}
                    modifiedDate={entry.modifiedAt}
                />
            );
        }
    };

    const isFullWidthField = (activityFieldTypeId: string): boolean => {
        const allFullWidthFieldTypes = [
            'RichTextArea',
            'TextArea',
            'AnalyseAndImplement',
            'FormSectionHeader',
            'YesNo',
            'Threshold',
            ...fullWidthFieldTypes,
        ];
        return _.includes(allFullWidthFieldTypes, activityFieldTypeId);
    };

    const renderFields = (
        fieldsArray: { field: RuntimeField; key: string }[],
    ) => {
        return _.map(fieldsArray, ({ field: fieldObj, key }) => {
            if (!fieldObj) return null;

            const { hasFocus, errors, index } = fieldObj;
            const isField = entries ? _.get(_.first(entries), 'field') : null;
            let entry = null;
            if (entries) {
                entry = isField
                    ? _.find(entries, (entry) => entry.field.id === key)
                    : _.find(
                          entries,
                          (entry) =>
                              isEntry(entry) &&
                              entry.partyRelationshipTypeFieldId === key,
                      );
            }

            const className =
                isFullWidthField(fieldObj.activityFieldTypeId) || allFullWidth
                    ? 'full-width'
                    : '';

            const hasException =
                (highlightRequired && fieldObj.required && !fieldObj.isValid) ||
                (exceptionFields &&
                    exceptionFields.indexOf(fieldObj.fieldKey) !== -1);

            const isDisabled = fieldObj.isReference ? true : !dataEntryAllowed;

            return (
                <div
                    className={`form-field ${className} ${
                        hasException ? '--has-exception' : ''
                    }`}
                    key={index}
                >
                    {!editable &&
                        fieldObj.activityFieldTypeId !==
                            'FormSectionHeader' && (
                            <Header as="h4">{fieldObj.label}</Header>
                        )}
                    <FormField
                        hasException={hasException}
                        key={index}
                        field={fieldObj}
                        errors={hasFocus ? {} : errors}
                        handleFieldOnFocus={() => handleFieldOnFocus(index)}
                        handleFieldOnBlur={() => handleFieldOnBlur(index)}
                        handleFieldDataChange={handleFieldDataChange}
                        editable={editable}
                        disabled={isDisabled}
                        value={fieldObj.value}
                        {...fieldObj}
                        isManufacturer={isManufacturer}
                        handleOpenSideBar={() => {
                            onOpenSideBar(fieldObj);
                        }}
                    />
                    {renderDescription(entry)}
                </div>
            );
        });
    };

    const fieldsArray = _.map(stateFields, (field) => ({
        field,
        key: field.id,
    }));

    return (
        <>
            {fieldsPerRow
                ? renderColumnFields(fieldsArray)
                : renderFields(fieldsArray)}
        </>
    );
};

export default FormFields;
