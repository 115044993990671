import React, { useState, useEffect } from 'react';
import { Icon, Menu } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import qs from 'query-string';
import * as PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { WrapFollowUpContext } from '../../context/FollowUpContext';
import { SIDEBAR_COMPONENTS } from './SidebarRoot';
import EventMenuItem from './events/EventMenuItem';
import { getEventsList } from './../../actions/eventsActions';
import { searchTicketsCount } from './../../actions/ticketActions';
import { fetchEventsTimeout } from '../../constants/config';
import UserWrapper from '../user/User';

import { useGetFeatures } from '../../utils/Features';

const MenuItemsWithPoll = ({
    currentUser,
    followUpList,
    uiActions,
    events: { latestDate, gotInitialList },
    getEventsList,
    searchTicketsCount,
}) => {
    const [loading, setLoading] = useState(false);
    const { FollowUp } = useGetFeatures();
    let timeout;

    useEffect(() => {
        const filter = gotInitialList
            ? {
                  where: { createdAt: { gt: new Date(latestDate) } },
              }
            : {};

        fetchEvents(filter);
        fetchAssignedTicketsCount();

        startsPoll(filter);

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    useEffect(() => {
        const filter = gotInitialList
            ? {
                  where: { createdAt: { gt: new Date(latestDate) } },
              }
            : {};

        startsPoll(filter);

        return () => {
            clearTimeout(timeout);
        };
    }, [latestDate]);

    const ticketFilters = {
        assignedIds: [currentUser.details.principalId],
        workflowStatusTypeIds: ['TODO', 'APPROVAL'],
    };

    const stringifiedFiltersValue = qs.stringify(ticketFilters, {
        arrayFormat: 'comma',
    });

    const fetchAssignedTicketsCount = () => {
        const where = {
            assignedPrincipalId: { inq: [currentUser.details.principalId] },
            statusTypeId: { inq: ['TODO', 'APPROVAL'] },
        };
        searchTicketsCount(where, true);
    };

    const fetchEvents = (filter) => {
        getEventsList(filter).then(() => {
            setLoading(false);
        });
    };

    const startsPoll = (filter) => {
        timeout = setTimeout(() => {
            fetchEvents(filter);
            fetchAssignedTicketsCount();
        }, fetchEventsTimeout);
    };

    return (
        <React.Fragment>
            <Menu.Item
                as={Link}
                to={`/ticketsearch?${stringifiedFiltersValue}`}
            >
                <Icon name="briefcase" size="large" /> {currentUser.ticketCount}
            </Menu.Item>
            {FollowUp && (
                <Menu.Item
                    onClick={() => {
                        uiActions.showSidebar({
                            sidebarType: SIDEBAR_COMPONENTS.FOLLOW_UP_SIDEBAR,
                            sidebarProps: {},
                            sidebarConfig: { width: 'very wide' },
                        });
                    }}
                >
                    <Icon name="clipboard check" size="large" />
                    {followUpList.length}
                </Menu.Item>
            )}

            <EventMenuItem loading={loading} />
        </React.Fragment>
    );
};

MenuItemsWithPoll.propTypes = {
    uiActions: PropTypes.object.isRequired,
    currentUser: PropTypes.object,
    currentUsers: PropTypes.array,
    followUpList: PropTypes.array,
    events: PropTypes.object,
    getEventsList: PropTypes.func,
    searchTicketsCount: PropTypes.func,
};

const mapStateToProps = (state) => {
    return {
        events: state.events,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getEventsList: bindActionCreators(getEventsList, dispatch),
        searchTicketsCount: bindActionCreators(searchTicketsCount, dispatch),
    };
};

export default WrapFollowUpContext(
    UserWrapper(
        connect(mapStateToProps, mapDispatchToProps)(MenuItemsWithPoll),
    ),
);
