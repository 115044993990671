import * as _ from 'lodash';

export default function (args) {
    const { dataColor, dataValue, data } = args;
    let value = dataValue?.dataRow
        ? _.get(data, `${dataValue.dataSet}.${dataValue.dataRow}`)
        : _.get(data, `${dataValue.dataSet}`);
    if (_.isUndefined(value) || _.isNull(value) || _.isNaN(value)) return '';
    return {
        ...args,
        value,
        color: dataColor
            ? _.get(data, [dataColor.dataSet, dataColor.dataRow])
            : null,
    };
}
