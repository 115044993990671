import React from 'react';
import { find, isEmpty } from 'lodash';
import { WorkflowWrapContext } from '../../context/WorkflowContext';
import { Button, Table } from 'semantic-ui-react';
import WorkflowStatusListRow from './WorkflowStatusListRow';
import {
    Workflow,
    WorkflowStatus,
    ActivityFieldType,
    StatusType,
} from '../../types';

interface ActivityResolutionType {
    id: string;
    label: string;
    colourId: string;
}

interface WorkflowStatusListProps {
    workflow: Workflow;
    workflowStateActions: {
        updateStatus: (
            workflow: Workflow,
            status: WorkflowStatus,
            name: string,
            value: any,
        ) => void;
        addStatus: (workflow: Workflow) => void;
        removeStatus: (workflow: Workflow, status: WorkflowStatus) => void;
        updateStatusResolution: (
            workflow: Workflow,
            status: WorkflowStatus,
            resolutionType: string,
            value: any,
        ) => void;
        addStatusResolution: (
            workflow: Workflow,
            status: WorkflowStatus,
            resolutionType: string,
        ) => void;
        removeStatusResolution: (
            workflow: Workflow,
            status: WorkflowStatus,
            resolutionType: string,
        ) => void;
        addStatusField: (
            workflow: Workflow,
            status: WorkflowStatus,
            fieldType: ActivityFieldType,
        ) => void;
        removeStatusField: (
            workflow: Workflow,
            status: WorkflowStatus,
            fieldIndex: number,
        ) => void;
        updateStatusField: (
            workflow: Workflow,
            status: WorkflowStatus,
            fieldIndex: number,
            value: any,
        ) => void;
    };
    statusTypes: StatusType[];
    activityResolutionTypes: ActivityResolutionType[];
    activityFieldTypes: ActivityFieldType[];
}

const WorkflowStatusList = ({
    workflow,
    workflowStateActions,
    statusTypes,
    activityResolutionTypes,
    activityFieldTypes,
}: WorkflowStatusListProps) => {
    const updateStatus = (
        event: React.SyntheticEvent,
        {
            name,
            value,
            checked,
        }: { name: string; value?: any; checked?: boolean },
        status: WorkflowStatus,
    ) => {
        workflowStateActions.updateStatus(
            workflow,
            status,
            name,
            value || checked,
        );
    };

    const addStatus = () => {
        workflowStateActions.addStatus(workflow);
    };

    const removeStatus = (
        event: React.SyntheticEvent,
        status: WorkflowStatus,
    ) => {
        workflowStateActions.removeStatus(workflow, status);
    };

    const onResolutionChange = (
        status: WorkflowStatus,
        resolutionType: string,
        value: any,
    ) => {
        workflowStateActions.updateStatusResolution(
            workflow,
            status,
            resolutionType,
            value,
        );
    };

    const toggleResolution = (
        status: WorkflowStatus,
        resolutionType: string,
        makeAvailable: boolean,
    ) => {
        if (makeAvailable) {
            workflowStateActions.addStatusResolution(
                workflow,
                status,
                resolutionType,
            );
        } else {
            workflowStateActions.removeStatusResolution(
                workflow,
                status,
                resolutionType,
            );
        }
    };

    const addField = (
        event: React.SyntheticEvent,
        status: WorkflowStatus,
        data: { value: string },
    ) => {
        const fieldType = find(
            activityFieldTypes,
            (fieldType) => fieldType.id === data.value,
        );
        if (fieldType) {
            workflowStateActions.addStatusField(workflow, status, fieldType);
        }
    };

    const deleteField = (fieldIndex: number, status: WorkflowStatus) => {
        workflowStateActions.removeStatusField(workflow, status, fieldIndex);
    };

    const onFieldChange = (
        fieldIndex: number,
        status: WorkflowStatus,
        newValue: any,
    ) => {
        if (isEmpty(newValue)) {
            deleteField(fieldIndex, status);
            return;
        }
        workflowStateActions.updateStatusField(
            workflow,
            status,
            fieldIndex,
            newValue,
        );
    };

    const { statuses } = workflow;

    return (
        <React.Fragment>
            <Table>
                <Table.Body>
                    {statuses.map((status, index) => (
                        <WorkflowStatusListRow
                            key={index}
                            status={status}
                            statusTypes={statusTypes}
                            activityResolutionTypes={activityResolutionTypes}
                            activityFieldTypes={activityFieldTypes}
                            onResolutionChange={onResolutionChange}
                            toggleResolution={toggleResolution}
                            onChange={(event, data) =>
                                updateStatus(event, data, status)
                            }
                            onRemove={(event) => removeStatus(event, status)}
                            addField={addField}
                            onFieldChange={onFieldChange}
                        />
                    ))}
                    <Table.Row>
                        <Table.Cell colSpan="2">
                            <Button onClick={addStatus}>Add Status</Button>
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
        </React.Fragment>
    );
};

export default WorkflowWrapContext(WorkflowStatusList);
