import React from 'react';
import * as PropTypes from 'prop-types';
import { Divider, Header, Segment } from 'semantic-ui-react';
import ActivitiesList from '../activity/ActivitiesList';

function ActivitySetFields(props) {
    const {
        value,
        activitySetIndex,
        removeActivityFromSet,
        moveActivityInSet,
        cloneActivity,
        editable,
        activeMenuItem,
    } = props;
    return (
        <Segment>
            <Header>
                {value.label} {value.ref ? `(${value.ref})` : ''}
            </Header>
            <Divider />
            {value.activities.length === 0 ? (
                <Header>No questions added</Header>
            ) : (
                <ActivitiesList
                    activities={value.activities}
                    activeMenuItem={activeMenuItem}
                    layout="ACTIVITIES"
                    removeActivity={(activity) => {
                        removeActivityFromSet(activitySetIndex, activity);
                    }}
                    moveActivity={(activity, increment) => {
                        moveActivityInSet(
                            activitySetIndex,
                            activity,
                            increment,
                        );
                    }}
                    cloneActivity={(activity) => {
                        cloneActivity(activity, value);
                    }}
                    editable={editable}
                />
            )}
        </Segment>
    );
}

ActivitySetFields.propTypes = {
    value: PropTypes.object,
    activitySetIndex: PropTypes.number,
    removeActivityFromSet: PropTypes.func,
    moveActivityInSet: PropTypes.func,
    cloneActivity: PropTypes.func,
    editable: PropTypes.bool,
    activeMenuItem: PropTypes.string,
};

export default ActivitySetFields;
