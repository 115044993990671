import React from 'react';
import * as PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import * as _ from 'lodash';
import ServerRenderIcon from '../common/dashboard/components/ServerRenderIcon';

const TicketResolutionIcons = (props) => {
    const { statusResolutions, size = 'large', className } = props;
    return (
        <div className={className}>
            {_.map(statusResolutions, (statusResolution) => {
                if (!statusResolution.current) return null;
                if (typeof process !== 'undefined' && process.env.SERVER_REND) {
                    return (
                        <ServerRenderIcon
                            key={statusResolution.id}
                            name={
                                statusResolution.resolution.resolutionType.icon
                            }
                            color={
                                statusResolution.resolution.resolutionType
                                    .colourId
                            }
                        />
                    );
                }
                return (
                    <Icon
                        key={statusResolution.id}
                        name={statusResolution.resolution.resolutionType.icon}
                        color={
                            statusResolution.resolution.resolutionType.colourId
                        }
                        size={size}
                    />
                );
            })}
        </div>
    );
};

TicketResolutionIcons.propTypes = {
    statusResolutions: PropTypes.array,
    size: PropTypes.string,
    className: PropTypes.string,
};

export default TicketResolutionIcons;
