import React, { useState, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import * as _ from 'lodash';

const DropDownComponent = (props) => {
    const {
        multiSelect = false,
        callback,
        propertyToSet,
        externalValue,
        search = false,
    } = props;
    const initialValue = externalValue
        ? externalValue
        : props.multiSelect
          ? []
          : null;

    if (!props.data) return null;
    const { options, title } = props.data;

    const [value, setValue] = useState(initialValue);

    useEffect(() => {
        if (initialValue !== value) {
            setValue(initialValue);
        }
    }, [externalValue]);

    const handleChange = (e, { value }) => {
        setValue(value);
        if (callback)
            callback({
                value,
                property: propertyToSet,
            });
    };

    const renderLabel = (label) => {
        const { labelText, text, labelLimit, icon } = label;
        let content = labelText || text;
        if (labelLimit)
            content =
                content.length > labelLimit
                    ? `${content.substring(0, labelLimit)}...`
                    : content;

        return {
            size: 'tiny',
            content,
            icon: icon,
        };
    };

    let o = options;
    if (_.isArray(value)) {
        const diff = _.difference(
            value,
            options.map((o) => o.value),
        );
        if (diff.length > 0)
            o = [...o, ...diff.map((d) => ({ value: d, text: d }))];
    }

    return (
        <React.Fragment>
            <Dropdown
                placeholder={title}
                fluid
                selection
                search={search}
                multiple={multiSelect}
                options={o}
                value={value}
                renderLabel={renderLabel}
                onChange={handleChange}
            />
        </React.Fragment>
    );
};

DropDownComponent.propTypes = {
    data: PropTypes.object,
    propertyToSet: PropTypes.string,
    externalValue: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.string,
        PropTypes.number,
    ]),
    multiSelect: PropTypes.bool,
    search: PropTypes.bool,
    callback: PropTypes.func,
};

DropDownComponent.fields = [
    {
        id: 'multiSelect',
        required: false,
        label: 'Multi Select',
        activityFieldTypeId: 'CheckBoxBool',
    },
];

export default DropDownComponent;
