import ApiTools from './apiTools';

export interface TermsAndConditions {
    id: number;
    code: string;
    effectiveDate: string;
    template: string;
    linkText: string;
    acceptText: string;
}

class TermsAndConditionsApi {
    static getTermsAndConditions(): Promise<TermsAndConditions[]> {
        return ApiTools.get(`VersionedTemplates/getTermsAndConditions`).then(
            (response: { data: TermsAndConditions[] }) => {
                return response.data;
            },
        );
    }
    static acceptTermsAndConditions(
        termsAndConditions: {
            [key: string]: { value: boolean; id: number };
        },
        sendEmail: boolean,
    ) {
        return ApiTools.post(`VersionedTemplates/acceptTermsAndConditions`, {
            termsAndConditions,
            sendEmail,
        }).then((response) => {
            return response;
        });
    }
}

export default TermsAndConditionsApi;
