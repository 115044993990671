import { useState, ChangeEvent, FocusEvent } from 'react';
import { Form, Icon, Popup } from 'semantic-ui-react';
import _, { isString } from 'lodash';

import FieldToolTip from './FieldToolTip';
import NumberField from './Number';
import { ThresholdOptionsType } from './options/ThresholdOptions';
import { Threshold as ThresholdType } from '../../../api/statusApi';
import Outlier from './Outlier';

interface ThresholdProps {
    name: string;
    label?: string;
    value?: string | ThresholdType;
    onChange?: (
        event: null,
        data: {
            value: string;
            name: string;
            outlier?: boolean;
            excludeFromStatistics?: boolean;
        },
    ) => void;
    disabled?: boolean;
    errors?: Record<string, string[]>;
    onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
    onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
    required?: boolean;
    editable?: boolean;
    options?: ThresholdOptionsType;
    outlier?: boolean;
    excludeFromStatistics?: boolean;
    dataField?: boolean;
    isManufacturer?: boolean;
}

const Threshold = (props: ThresholdProps) => {
    const {
        label,
        value: propsValue,
        onChange,
        name,
        disabled = false,
        errors,
        required,
        editable = true,
        options,
        outlier,
        excludeFromStatistics,
        dataField,
        isManufacturer,
    } = props;
    const [value, setValue] = useState<string | ThresholdType | undefined>(
        propsValue,
    );
    const fieldErrors = errors ? errors[name] : undefined;

    const parsedValue: ThresholdType | null =
        isString(propsValue) && propsValue.length > 0
            ? JSON.parse(propsValue)
            : propsValue;

    // useEffect(() => {
    //   if (onChange && debouncedValue !== propsValue)
    //     onChange(null, { value: debouncedValue, name });
    // }, [debouncedValue]);

    // useEffect(() => {
    //   setValue(propsValue);
    // }, [propsValue]);

    const handleValueChanged = (
        e: ChangeEvent<HTMLInputElement>,
        { value, name: thresholdName }: { value: string; name: string },
    ) => {
        if (parsedValue === null) {
            onChange?.(null, {
                value: JSON.stringify({ [thresholdName]: value }),
                name,
            });
        } else {
            onChange?.(null, {
                value: JSON.stringify({
                    ...parsedValue,
                    [thresholdName]: value,
                }),
                name,
            });
        }
    };

    if (!editable) {
        return (
            <>
                {parsedValue?.upper && <>{parsedValue?.upper}</>}
                {parsedValue?.lower && <> / {parsedValue?.lower}</>}
            </>
        );
    }

    return (
        <Form.Field required={required}>
            <label>
                {label}
                {label && (
                    <span>
                        {options?.inverted
                            ? '(Lower is better)'
                            : '(Higher is better)'}
                    </span>
                )}
                <FieldToolTip {...props} />
                {isManufacturer && dataField && (
                    <Outlier
                        value={value}
                        name={name}
                        outlier={outlier}
                        excludeFromStatistics={excludeFromStatistics}
                        onChange={onChange}
                    />
                )}
            </label>
            <Form.Group widths="equal">
                <NumberField
                    name="upper"
                    label="Upper"
                    onChange={handleValueChanged}
                    value={parsedValue?.upper}
                    disabled={disabled}
                />
                <NumberField
                    name="lower"
                    label="Lower"
                    onChange={handleValueChanged}
                    value={parsedValue?.lower}
                    disabled={disabled}
                />
            </Form.Group>
        </Form.Field>
    );
};

export default Threshold;
