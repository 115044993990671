import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Container, Header } from 'semantic-ui-react';

import { loadPartiesWithRelationships } from '../../actions/partyActions';
import { useAppSelector } from '../../actions/store';
import FairValueDistributorView from './FairValueDistributorView';

const FairValuePage = () => {
    return (
        <Container>
            <Header>Fair Value Distributors</Header>

            <FairValueDistributorView />
        </Container>
    );
};

export default FairValuePage;
