import React from 'react';
import * as PropTypes from 'prop-types';
import { Header } from 'semantic-ui-react';
import Moment from 'react-moment';

import { getTimeAgo } from '../../utils/CommonUtils';
import { defaultDateFormat } from '../../constants/config';

import './ExecutionListDueDate.scss';

const ExecutionListDueDate = (props) => {
    const { status, dueDate } = props;
    const isOverdue = status.toLowerCase() === 'overdue';
    const momentDueDate = <Moment format={defaultDateFormat}>{dueDate}</Moment>;
    return isOverdue ? (
        <Header color={'red'} as="h4" className="due-date-header">
            <Header.Content>{momentDueDate}</Header.Content>
            <Header.Subheader>
                Overdue by {getTimeAgo(dueDate, true)}
            </Header.Subheader>
        </Header>
    ) : (
        momentDueDate
    );
};

ExecutionListDueDate.propTypes = {
    status: PropTypes.string,
    dueDate: PropTypes.string,
};

export default ExecutionListDueDate;
