import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import { connect } from 'react-redux';
import ReactHighcharts from 'react-highcharts';
import { Dimmer, Dropdown, Grid, Loader } from 'semantic-ui-react';

import TicketWrapper from './Ticket';
import * as storeTypes from '../../constants/storeTypes';
import ExecutionWrapper from '../execution/Execution';
import ErrorBoundary from './../common/ErrorBoundary';

function mapStateToProps(state) {
    return {
        tickets: state.TicketsDASHBOARD.data,
    };
}

@connect(mapStateToProps)
@ExecutionWrapper
@TicketWrapper
class TicketsDashboard extends Component {
    static propTypes = {
        tickets: PropTypes.array,
        executions: PropTypes.array,
        executionActions: PropTypes.object,
        ticketStateActions: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {
            stacking: 'normal',
            loading: true,
            category: 0,
        };
    }

    componentWillMount() {
        this.setState({ loading: true });

        this.props.executionActions.loadExecutions().then(({ executions }) => {
            this.setState({ execution: executions[0].id }, () => {
                this.refresh();
            });
        });
    }

    refresh = () => {
        this.setState({ loading: true });
        this.props.ticketStateActions
            .loadTickets(storeTypes.DASHBOARD, {
                include: [
                    {
                        relation: 'executionTicketSet',
                        scope: {
                            include: [
                                {
                                    relation: 'activitySet',
                                    scope: {
                                        include: ['category'],
                                    },
                                },
                            ],
                        },
                    },
                    'statusResolutions',
                    'currentStatusNode',
                    'activity',
                ],
                where: {
                    executionId: this.state.execution,
                },
            })
            .then(() => {
                this.setState({ loading: false });
            });
    };

    changeStacking = (event, { value }) => {
        this.setState({ stacking: value, loading: true }, () => {
            this.setState({ loading: false });
        });
    };
    changeExecution = (event, { value }) => {
        this.setState({ execution: value }, () => {
            this.refresh();
        });
    };
    changeCategory = (event, { value }) => {
        this.setState({ category: value, loading: true }, () => {
            this.setState({ loading: false });
        });
    };

    render() {
        if (this.state.loading) {
            return (
                <Dimmer active={this.state.loading} inverted>
                    <Loader disabled={!this.state.loading} />
                </Dimmer>
            );
        }
        // const {tickets} = this.props;
        const tickets = _.chain(this.props.tickets)
            .filter((t) =>
                this.state.category
                    ? t.executionTicketSet.activitySet.category.id ===
                      this.state.category
                    : true,
            )
            .value();

        const executionTicketSet = _.chain(tickets)
            .uniqBy('executionTicketSet.activitySet.label')
            .map((ticket) => ticket.executionTicketSet)
            .filter((ets) =>
                this.state.category
                    ? ets.activitySet.category.id === this.state.category
                    : true,
            )
            .sortBy('id')
            .value();
        const statusTypes = _.chain(tickets)
            .uniqBy('currentStatusNode.status.statusType.id')
            .map((ticket) => ticket.currentStatusNode.status.statusType)
            .sortBy('id')
            .value();
        const categories = _.chain(this.props.tickets)
            .uniqBy('executionTicketSet.activitySet.category.label')
            .map((ticket) => ticket.executionTicketSet.activitySet.category)
            .sortBy('id')
            .value();

        const highChartsData = statusTypes.map((st) => {
            const d = _.countBy(
                tickets.filter(
                    (t) => t.currentStatusNode.status.statusType.id === st.id,
                ),
                'executionTicketSet.activitySet.label',
            );
            return {
                name: st.name,
                data: executionTicketSet.map(
                    (ets) => d[ets.activitySet.label] || 0,
                ),
                color: st.colourId,
            };
        });

        const statusId = 61;
        const ticketsWithResolution = tickets
            .map((ticket) => {
                return Object.assign({}, ticket, {
                    resolution: _.find(ticket.statusResolutions, { statusId }),
                });
            })
            .filter((ticket) => !!ticket.resolution);

        const resolutionTypes = _.chain(ticketsWithResolution)
            .uniqBy('resolution.resolution.id')
            .map((ticket) => ticket.resolution.resolution)
            .sortBy('resolutionType.sort')
            .value();

        const highChartsData2 = resolutionTypes.map((r) => {
            const d = _.countBy(
                ticketsWithResolution.filter(
                    (t) => t.resolution.resolution.id === r.id,
                ),
                'executionTicketSet.activitySet.label',
            );
            return {
                name: r.resolutionType.label,
                data: executionTicketSet.map(
                    (ets) => d[ets.activitySet.label] || 0,
                ),
                color: r.resolutionType.colourId,
            };
        });

        const countByStatusType = _.countBy(
            tickets,
            'currentStatusNode.status.statusType.name',
        );
        const highChartsPieData1 = statusTypes.map((st) => {
            return {
                name: st.name,
                y: countByStatusType[st.name] || 0,
                color: st.colourId,
            };
        });

        const countByresolutionType = _.countBy(
            ticketsWithResolution,
            'resolution.resolution.resolutionType.label',
        );
        const highChartsPieData2 = resolutionTypes.map((r) => {
            return {
                name: r.resolutionType.label,
                y: countByresolutionType[r.resolutionType.label] || 0,
                color: r.resolutionType.colourId,
            };
        });

        window.tickets = tickets;
        window.ticketsWithResolution = ticketsWithResolution;
        window.statusTypes = statusTypes;
        window.executionTicketSet = executionTicketSet;
        window.resolutionTypes = resolutionTypes;
        window.categories = categories;
        window._ = _;

        const config = {
            exporting: { enabled: false },
            chart: {
                type: 'column',
            },
            title: {
                text: 'Tickets by Status',
            },
            xAxis: {
                // categories: ['Apples', 'Oranges', 'Pears', 'Grapes', 'Bananas']
                categories: executionTicketSet.map(
                    (ets) => ets.activitySet.label,
                ),
            },
            yAxis: {
                min: 0,
                stackLabels: {
                    enabled: true,
                    style: {
                        fontWeight: 'bold',
                        color: 'gray',
                    },
                },
                title: {
                    enabled: false,
                },
            },
            legend: {
                align: 'right',
                x: -30,
                verticalAlign: 'top',
                y: 25,
                floating: true,
                backgroundColor: 'white',
                borderColor: '#CCC',
                borderWidth: 1,
                shadow: false,
            },
            tooltip: {
                headerFormat: '<b>{point.x}</b><br/>',
                pointFormat:
                    '{series.name}: {point.y}<br/>Total: {point.stackTotal}',
            },
            plotOptions: {
                column: {
                    stacking: this.state.stacking || 'normal',
                    pointPadding: 0,
                    groupPadding: 0.05,
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true,
                        color: 'white',
                    },
                },
            },
            series: highChartsData,
        };

        const config2 = Object.assign({}, config, {
            title: {
                text: 'Tickets by Resolution',
            },
            series: highChartsData2,
        });

        const spiderConfig = {
            exporting: { enabled: false },
            chart: {
                polar: true,
                type: 'line',
            },

            title: {
                text: false,
                x: -80,
            },

            pane: {
                size: '80%',
            },

            xAxis: {
                categories: executionTicketSet.map(
                    (ets) => ets.activitySet.label,
                ),
                tickmarkPlacement: 'on',
                lineWidth: 0,
            },

            yAxis: {
                gridLineInterpolation: 'polygon',
                lineWidth: 0,
                min: 0,
            },

            tooltip: {
                shared: true,
                pointFormat:
                    '<span style="color:{series.color}">{series.name}: <b>{point.y:,.0f}</b><br/>',
            },

            legend: {
                align: 'right',
                verticalAlign: 'top',
                y: 70,
                layout: 'vertical',
            },

            series: highChartsData,
        };

        const spiderConfig2 = Object.assign({}, spiderConfig, {
            series: highChartsData2,
        });

        const pieConfig1 = {
            exporting: { enabled: false },
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
            },
            title: {
                text: false,
            },
            tooltip: {
                pointFormat: '<b>{point.percentage:.1f}%</b>',
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                        style: {
                            color: 'black',
                        },
                    },
                },
            },
            series: [
                {
                    colorByPoint: true,
                    data: highChartsPieData1,
                    size: '80%',
                    innerSize: '60%',
                },
            ],
        };
        const pieConfig2 = Object.assign({}, pieConfig1, {
            series: [
                {
                    colorByPoint: true,
                    data: highChartsPieData2,
                    size: '80%',
                    innerSize: '60%',
                },
            ],
        });

        const stackOptions = [
            { text: 'Normal', value: 'normal' },
            { text: 'Percent', value: 'percent' },
        ];
        const executionOptions = this.props.executions.map((execution) => ({
            text: `${execution.id} ${execution.programme.label}`,
            value: execution.id,
        }));
        const categoryOptions = [
            { text: 'All', value: 0 },
            ...categories.map((c) => ({
                text: c.label,
                value: c.id,
            })),
        ];

        return (
            <div>
                <Dropdown
                    selection
                    options={executionOptions}
                    value={this.state.execution}
                    onChange={this.changeExecution}
                />
                <Dropdown
                    selection
                    options={categoryOptions}
                    value={this.state.category}
                    onChange={this.changeCategory}
                />

                <Dropdown
                    selection
                    options={stackOptions}
                    value={this.state.stacking}
                    onChange={this.changeStacking}
                />

                <Grid>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <ReactHighcharts
                                config={config}
                                neverReflow={true}
                            />
                        </Grid.Column>
                        <Grid.Column width={16}>
                            <ReactHighcharts
                                config={config2}
                                neverReflow={true}
                            />
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column width={8}>
                            <ReactHighcharts
                                config={spiderConfig}
                                neverReflow={true}
                            />
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <ReactHighcharts
                                config={spiderConfig2}
                                neverReflow={true}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={8}>
                            <ReactHighcharts
                                config={pieConfig1}
                                neverReflow={true}
                            />
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <ReactHighcharts
                                config={pieConfig2}
                                neverReflow={true}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        );
    }
}

export default ErrorBoundary(TicketsDashboard);
