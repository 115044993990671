import * as types from '../constants/actionTypes';
import initialState from './initialState';
import _ from 'lodash';
import update from 'immutability-helper';

export default function partyReducer(state = initialState.party, action) {
    switch (action.type) {
        case types.LOAD_PARTY_SUCCESS:
            return { ...state, ...action.party };
        case types.SAVE_USER_SUCCESS: {
            const user = action.user;
            const isCurrentPartyUser = state.id === user.partyId;
            const userIndex = _.findIndex(state.users, { id: user.id });
            let newState = state;
            if (userIndex != -1) {
                newState = update(
                    state,
                    isCurrentPartyUser
                        ? {
                              users: {
                                  [userIndex]: {
                                      $set: user,
                                  },
                              },
                          }
                        : {
                              users: {
                                  $splice: [[userIndex, 1]],
                              },
                          },
                );
            } else {
                newState = update(newState, {
                    users: {
                        $push: [user],
                    },
                });
            }

            const { userGroups } = state;
            if (userGroups.length) {
                const adminGroupIndex = _.findIndex(userGroups, (usergroup) =>
                    usergroup.name.includes('Admins'),
                );
                const isAdmin =
                    adminGroupIndex != -1
                        ? _.some(userGroups[adminGroupIndex].users, {
                              id: user.id,
                          })
                        : false;

                if (isAdmin !== user.isAdmin) {
                    if (user.isAdmin) {
                        return update(newState, {
                            userGroups: {
                                [adminGroupIndex]: {
                                    users: {
                                        $push: [user],
                                    },
                                },
                            },
                        });
                    } else {
                        const userIndex = _.findIndex(
                            state.userGroups[adminGroupIndex].users,
                            {
                                id: user.id,
                            },
                        );
                        return update(newState, {
                            userGroups: {
                                [adminGroupIndex]: {
                                    users: { $splice: [[userIndex, 1]] },
                                },
                            },
                        });
                    }
                }
            }

            return newState;
        }
        case types.SAVE_RELATIONSHIP_SUCCESS: {
            const { relationship } = action;
            if (state.id !== relationship.fromPartyId) return state;
            const { toParty, relationshipType, entries } = action;
            const relationshipIndex = _.findIndex(state.partyRelationships, {
                id: relationship.id,
            });
            if (relationshipIndex != -1)
                return update(state, {
                    partyRelationships: {
                        [relationshipIndex]: {
                            $set: {
                                ...relationship,
                                toParty,
                                relationshipType,
                                entries,
                            },
                        },
                    },
                });
            return update(state, {
                partyRelationships: {
                    $push: [{ ...relationship, toParty, relationshipType }],
                },
            });
        }
        case types.CREATE_USERGROUP_SUCCESS: {
            const { userGroup } = action;
            const userGroups = [
                ...state.userGroups,
                { ...userGroup, users: [] },
            ];
            return Object.assign({}, state, { userGroups });
        }
        case types.UPDATE_USERGROUP_SUCCESS: {
            const { userGroup } = action;
            const userGroupIndex = _.findIndex(state.userGroups, {
                id: userGroup.id,
            });
            return update(state, {
                userGroups: {
                    [userGroupIndex]: {
                        $set: userGroup,
                    },
                },
            });
        }

        case types.ADD_USER_TO_USERGROUP_SUCCESS: {
            const { response, user } = action;
            const { usergroupid } = response;
            const userGroupIndex = _.findIndex(state.userGroups, {
                id: usergroupid,
            });
            const newUsers = !!state.userGroups[userGroupIndex].users;
            const newState = update(state, {
                userGroups: {
                    [userGroupIndex]: {
                        users: { [newUsers ? '$push' : '$set']: [user] },
                    },
                },
            });
            return newState;
        }
        case types.REMOVE_USER_FROM_USERGROUP_SUCCESS: {
            const { userId, userGroupId } = action;
            const userGroupIndex = _.findIndex(state.userGroups, {
                id: userGroupId,
            });
            const userIndex = _.findIndex(
                state.userGroups[userGroupIndex].users,
                {
                    id: userId,
                },
            );
            let newState = update(state, {
                userGroups: {
                    [userGroupIndex]: { users: { $splice: [[userIndex, 1]] } },
                },
            });
            return newState;
        }
        case types.GET_ADDRESSES_SUCCESS: {
            const { response } = action;
            let newState = update(state, {
                addresses: {
                    $set: response,
                },
            });
            return newState;
        }
        case types.DELETE_RELATIONSHIP_FROM_PARTY_SUCCESS: {
            const { relationshipId } = action;
            const partyRelationshipIndex = _.findIndex(
                state.partyRelationships,
                {
                    id: relationshipId,
                },
            );
            return update(state, {
                partyRelationships: {
                    $splice: [[partyRelationshipIndex, 1]],
                },
            });
        }
        case types.DELETE_USERGROUP_SUCCESS: {
            const { response, userGroupId } = action;
            const userGroupIndex = _.findIndex(state.userGroups, {
                id: userGroupId,
            });
            return update(state, {
                userGroups: {
                    [userGroupIndex]: { $set: response },
                },
            });
        }
        case types.DELETE_ADDRESS_SUCCESS: {
            const { addressId } = action;
            const addressIndex = _.findIndex(state.addresses, {
                id: addressId,
            });
            return update(state, {
                addresses: {
                    $splice: [[addressIndex, 1]],
                },
            });
        }
        case types.DELETE_ALL_ADDRESSES_SUCCESS: {
            return update(state, {
                addresses: {
                    $set: [],
                },
            });
        }

        case types.SAVE_ADDRESS_TO_PARTY_SUCCESS: {
            const { address } = action;
            const addressIndex = _.findIndex(state.addresses, {
                id: address.id,
            });
            if (addressIndex != -1)
                return update(state, {
                    addresses: {
                        [addressIndex]: { $set: address },
                    },
                });
            return update(state, {
                addresses: {
                    $push: [address],
                },
            });
        }

        case `${types.UPLOAD_PARTY_IMAGE}`: {
            const { imageType, files } = action;
            const file = _.head(files).id;
            if (imageType === 'icon') {
                return update(state, {
                    iconAttachmentId: { $set: file },
                });
            }
            return update(state, {
                logoAttachmentId: { $set: file },
            });
        }

        case types.SAVE_THRESHOLD_SUCCESS: {
            const { threshold } = action;
            const thresholdIndex = _.findIndex(state.riskThresholds, {
                id: threshold.id,
            });
            if (thresholdIndex != -1) {
                return update(state, {
                    riskThresholds: {
                        [thresholdIndex]: { $set: threshold },
                    },
                });
            }
            return update(state, {
                riskThresholds: {
                    $push: [threshold],
                },
            });
        }

        case types.REMOVE_THRESHOLD_SUCCESS: {
            const { thresholdId } = action;
            const thresholdIndex = _.findIndex(state.riskAppetite, {
                id: thresholdId,
            });
            let newState = update(state, {
                riskThresholds: { $splice: [[thresholdIndex, 1]] },
            });
            return newState;
        }

        case 'UPDATE_PARTY_SUCCESS': {
            const { party } = action;
            if (state.id !== party.id) return state;
            return { ...state, ...party };
        }

        default:
            return state;
    }
}
