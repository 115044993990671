import React from 'react';
import * as PropTypes from 'prop-types';
import { Header, Placeholder } from 'semantic-ui-react';

import TicketAssignment from './TicketAssignment';
import TicketResolutionIconsContainer from './TicketResolutionIconsContainer';
import WorkflowWrapper from '../workflow/Workflow';
import TicketStats from './TicketStats';
import TicketStatus from './TicketStatus';
import ActionButtons from '../common/ActionButtons';
import ApplicableDateLabel from '../common/ApplicableDateLabel';
import TicketRowStatus from './TicketRowStatus';

const TicketRowFollowUp = (props) => {
    const {
        ticket,
        onClick,
        currentStatusNode,
        userHasInputPermission,
        actions,
        showProgramme,
        showTicketSet,
        showApplicableDate,
        followUp,
        handleFollowUpClick,
    } = props;
    if (!currentStatusNode) {
        return (
            <div className="ticket-row full placeholder">
                <Placeholder fluid>
                    <Placeholder.Header>
                        <Placeholder.Line />
                        <Placeholder.Line />
                    </Placeholder.Header>
                </Placeholder>
            </div>
        );
    }
    const color = ticket.statusNodeColor;
    const {
        statusNodeLabel,
        label,
        attachmentsCount,
        commentsCount,
        statusResolutions,
    } = ticket;

    const subheader = (
        <Header.Subheader>
            {showProgramme && <span>{ticket.executionLabel}</span>}
            {showApplicableDate && ticket.executionApplicableDate && (
                <span>
                    {' '}
                    -{' '}
                    <ApplicableDateLabel
                        applicableDate={ticket.executionApplicableDate}
                        applicableDateTypeId={
                            ticket.executionApplicableDateTypeId
                        }
                    />
                </span>
            )}
        </Header.Subheader>
    );

    return (
        <React.Fragment>
            <div className="ticket-row full">
                <TicketRowStatus
                    color={color}
                    followUp={followUp}
                    statusNodeLabel={statusNodeLabel}
                    onFollowUpClick={handleFollowUpClick}
                />

                <div
                    className="ticket-row-header"
                    onClick={() => {
                        onClick(ticket);
                    }}
                >
                    <Header
                        as="h4"
                        content={`${label} ${
                            showTicketSet
                                ? `(${ticket.executionTicketSetLabel})`
                                : ''
                        }`}
                        subheader={subheader}
                    />
                    <TicketStats
                        attachmentsCount={attachmentsCount}
                        commentsCount={commentsCount}
                    />
                </div>

                <div className="actions">
                    <TicketAssignment ticket={ticket} />
                </div>
                <div className="actions">
                    <TicketStatus
                        color={color}
                        statusNode={currentStatusNode}
                    />
                    {statusResolutions.length > 0 && (
                        <Header size="tiny">
                            Resolutions:{' '}
                            <TicketResolutionIconsContainer
                                className="resolutions"
                                statusResolutions={statusResolutions}
                                disabled={!userHasInputPermission}
                            />
                        </Header>
                    )}
                </div>
                <div className="actions collapsed">
                    <ActionButtons data={ticket} {...actions} />{' '}
                </div>
            </div>
        </React.Fragment>
    );
};

TicketRowFollowUp.propTypes = {
    ticket: PropTypes.object,
    onClick: PropTypes.func,
    isSelected: PropTypes.bool,
    transitioning: PropTypes.bool,
    resolving: PropTypes.bool,
    resolutionClicked: PropTypes.func,
    transitionClicked: PropTypes.func,
    workflowActions: PropTypes.object,
    workflow: PropTypes.object,
    currentUser: PropTypes.object,
    currentStatusNode: PropTypes.object,
    userHasInputPermission: PropTypes.bool,
    actions: PropTypes.object,
    showProgramme: PropTypes.bool,
    showTicketSet: PropTypes.bool,
    showApplicableDate: PropTypes.bool,
    followUp: PropTypes.bool,
    handleFollowUpClick: PropTypes.func,
};

export default WorkflowWrapper(TicketRowFollowUp);
