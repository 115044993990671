import { FC } from 'react';
import { Form, Icon, Popup } from 'semantic-ui-react';
import { Threshold } from '../../../api/statusApi';

interface OutlierProps {
    value: string | number | Threshold;
    name?: string;
    onChange?: (
        event: null,
        data: {
            value: string | number | Threshold;
            name: string;
            outlier?: boolean;
            excludeFromStatistics?: boolean;
        },
    ) => void;
    outlier?: boolean;
    excludeFromStatistics?: boolean;
}

const Outlier: FC<OutlierProps> = ({
    value,
    name,
    onChange,
    outlier,
    excludeFromStatistics,
}) => {
    return (
        <Popup
            wide
            trigger={<Icon name="times circle outline" />}
            content={
                <Form.Group>
                    <Form.Checkbox
                        onChange={(_, data) => {
                            onChange(null, {
                                value,
                                name,
                                outlier: data.checked,
                            });
                        }}
                        label="Outlier"
                        checked={outlier}
                    />
                    <Form.Checkbox
                        label="Exclude From Statistics"
                        onChange={(_, data) => {
                            onChange(null, {
                                value,
                                name,
                                excludeFromStatistics: data.checked,
                            });
                        }}
                        checked={excludeFromStatistics}
                    />
                </Form.Group>
            }
            hoverable={true}
            position="bottom center"
        />
    );
};

export default Outlier;
