import React from 'react';
import * as PropTypes from 'prop-types';
import { Table, Header } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import TicketStatus from './TicketStatus';
import TicketResolutionIconsContainer from './TicketResolutionIconsContainer';
import { getExecutionCategory } from '../../utils/ExecutionUtils';
import PartyLabel from '../common/labels/PartyLabel';
import FormFields from '../common/FormFields';
import ApplicableDateLabel from '../common/ApplicableDateLabel';

const TicketSidebarListItem = ({
    ticket,
    executionLabel = null,
    showResolutions,
    entries,
    isException,
}) => {
    const {
        execution,
        executionTicketSet,
        activity,
        currentStatusNode,
        statusResolutions,
        statusNodeColor,
        statusNodeLabel,
        label,
    } = ticket;
    const ticketId = ticket.id;
    const link = ticket.executionTicketSet
        ? `/execution/${execution.id}/${getExecutionCategory(
              ticket,
          )}/${ticketId}`
        : `/ticket/${ticketId}`;
    return (
        <Table.Row negative={isException}>
            <Table.Cell width={5}>
                <Header as={Link} to={link} size="small">
                    {activity ? activity.label : label}
                </Header>
            </Table.Cell>
            <Table.Cell width={3}>
                {execution ? execution.label : executionLabel}
            </Table.Cell>
            <Table.Cell width={3}>
                {executionTicketSet ? executionTicketSet.label : ''}
            </Table.Cell>
            {/* <Table.Cell width={2}>
        {execution && execution.applicableDate ? (
          <ApplicableDateLabel
            applicableDate={execution.applicableDate}
            applicableDateTypeId={execution.applicableDateTypeId}
          />
        ) : null}
      </Table.Cell> */}
            <Table.Cell width={3}>
                {execution && <PartyLabel party={execution.party} />}
            </Table.Cell>
            {/* <Table.Cell collapsing>
        {(currentStatusNode || statusNodeLabel) && (
          <TicketStatus
            statusNode={currentStatusNode}
            color={statusNodeColor}
            name={statusNodeLabel}
          />
        )}
      </Table.Cell> */}
            <Table.Cell>
                <div className={`ticket-fields `}>
                    <FormFields
                        fields={entries.map((entry) => entry.field)}
                        entries={entries}
                        onChange={() => {}}
                        fieldIdKey="fieldId"
                        editable={false}
                        fullWidthFieldTypes={['YesNo']}
                        enableDisplayLogic={false}
                    />
                </div>
            </Table.Cell>
            {/* {showResolutions && (
        <Table.Cell collapsing>
          <TicketResolutionIconsContainer
            statusResolutions={statusResolutions}
          />
        </Table.Cell>
      )} */}
        </Table.Row>
    );
};

TicketSidebarListItem.propTypes = {
    ticket: PropTypes.object.isRequired,
    executionLabel: PropTypes.string,
    showResolutions: PropTypes.bool,
    isException: PropTypes.bool,
};

export default TicketSidebarListItem;
