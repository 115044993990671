import _ from 'lodash';
import * as types from '../constants/actionTypes';
import update from 'immutability-helper/index';

export default function createExecutionPlanReducer(name = '') {
    return function executionPlanReducer(state = { __type: name }, action) {
        switch (action.type) {
            case `${types.LOAD_EXECUTION_PLAN_SUCCESS}_${name}`: {
                const { executionPlan } = action;
                return Object.assign({}, executionPlan, { __type: name });
            }

            case `${types.PATCH_EXECUTION_PLAN_SUCCESS}_${name}`: {
                const { executionPlan } = action;
                return Object.assign({}, state, executionPlan);
            }

            case `${types.EXECUTION_PLAN_ADD_ACTIVITY_SET_SUCCESS}_${name}`: {
                const { activitySet } = action;
                const newState = update(state, {
                    activitySets: {
                        $push: [activitySet],
                    },
                });
                return newState;
            }

            case `${types.EXECUTION_PLAN_REMOVE_ACTIVITY_SET_SUCCESS}_${name}`: {
                const { planActivitySet } = action;
                const index = _.findIndex(state.activitySets, {
                    id: planActivitySet.id,
                });
                const newState = update(state, {
                    activitySets: { $splice: [[index, 1]] },
                });
                return newState;
            }

            case `${types.EXECUTION_PLAN_ACTIVITY_SET_ADD_RELATIONSHIP_SUCCESS}_${name}`: {
                const { planActivitySet, relationship } = action;
                const index = _.findIndex(state.activitySets, {
                    id: planActivitySet.id,
                });
                let newState = Object.assign({}, state);
                if (!newState.activitySets[index].partyRelationships) {
                    newState = update(newState, {
                        activitySets: {
                            [index]: { partyRelationships: { $set: [] } },
                        },
                    });
                }
                newState = update(newState, {
                    activitySets: {
                        [index]: {
                            partyRelationships: { $push: [relationship] },
                        },
                    },
                });
                return newState;
            }

            case `${types.EXECUTION_PLAN_ACTIVITY_SET_ADD_ENTITY_SUCCESS}_${name}`: {
                const { planActivitySet, entity } = action;
                const index = _.findIndex(state.activitySets, {
                    id: planActivitySet.id,
                });
                let newState = Object.assign({}, state);
                if (!newState.activitySets[index].entities) {
                    newState = update(newState, {
                        activitySets: { [index]: { entities: { $set: [] } } },
                    });
                }
                newState = update(newState, {
                    activitySets: {
                        [index]: { entities: { $push: [entity] } },
                    },
                });
                return newState;
            }

            case `${types.EXECUTION_PLAN_ACTIVITY_SET_REMOVE_ENTITY_SUCCESS}_${name}`: {
                const { planActivitySet, entity } = action;
                const setIndex = _.findIndex(state.activitySets, {
                    id: planActivitySet.id,
                });
                const entityIndex = _.findIndex(
                    state.activitySets[setIndex].entities,
                    {
                        id: entity.id,
                    },
                );
                const newState = update(state, {
                    activitySets: {
                        [setIndex]: {
                            entities: { $splice: [[entityIndex, 1]] },
                        },
                    },
                });
                return newState;
            }

            case `${types.EXECUTION_PLAN_ACTIVITY_SET_REMOVE_RELATIONSHIP_SUCCESS}_${name}`: {
                const { planActivitySet, relationship } = action;
                const setIndex = _.findIndex(state.activitySets, {
                    id: planActivitySet.id,
                });
                const relationshipIndex = _.findIndex(
                    state.activitySets[setIndex].partyRelationships,
                    { id: relationship.id },
                );
                const newState = update(state, {
                    activitySets: {
                        [setIndex]: {
                            partyRelationships: {
                                $splice: [[relationshipIndex, 1]],
                            },
                        },
                    },
                });
                return newState;
            }

            case `${types.LOAD_SCHEDULED_LAUNCHES_LIST_SUCCESS}`: {
                const { schedules } = action.executionPlan;
                return update(state, {
                    schedules: { $set: schedules },
                });
            }

            case `${types.LAUNCH_SCHEDULE_EXECUTION_PLAN_SUCCESS}`: {
                const { response } = action;
                return update(state, {
                    schedules: { $push: [response] },
                });
            }

            case `${types.UPDATE_ACTIVITY_SET_WORKFLOW_SUCCESS}`: {
                const { activitySetId, workflowId } = action;
                const setIndex = _.findIndex(state.activitySets, {
                    id: activitySetId,
                });
                return update(state, {
                    activitySets: {
                        [setIndex]: {
                            workflowId: { $set: workflowId },
                        },
                    },
                });
            }

            case `${types.GET_EXECUTION_PLAN_OWNER_PARTY_RELATIONSHIP_SUCCESS}`: {
                const { partyRelationships } = action;
                return update(state, {
                    ownerParty: {
                        partyRelationships: { $set: partyRelationships },
                    },
                });
            }

            case `${types.REMOVE_SCHEDULED_EXECUTION_PLAN_SUCCESS}`: {
                const { scheduledLaunchId } = action;
                const scheduledLaunchIndex = _.findIndex(state.schedules, {
                    id: scheduledLaunchId,
                });
                return update(state, {
                    schedules: {
                        $splice: [[scheduledLaunchIndex, 1]],
                    },
                });
            }

            case `${types.ASSIGN_RELATIONSHIP_TO_ACTIVITY_SETS_EXECUTION_PLAN}`: {
                const { party, activitySetIds } = action;
                const newStateActivitySets = _.cloneDeep(state.activitySets);
                _.map(newStateActivitySets, (activitySet) => {
                    if (_.includes(activitySetIds, activitySet.id)) {
                        if (activitySet.partyRelationships) {
                            activitySet.partyRelationships.push(party);
                        } else {
                            activitySet.partyRelationships = [party];
                        }
                    }
                });
                return update(state, {
                    activitySets: {
                        $set: newStateActivitySets,
                    },
                });
            }

            case `${types.REMOVE_RELATIONSHIP_FROM_ACTIVITY_SETS_EXECUTION_PLAN}`: {
                const { relationshipId, activitySetIds } = action;
                const newStateActivitySets = _.cloneDeep(state.activitySets);
                _.map(newStateActivitySets, (activitySet) => {
                    if (_.includes(activitySetIds, activitySet.id)) {
                        const relationshipIndex = _.findIndex(
                            activitySet.partyRelationships,
                            { id: relationshipId },
                        );
                        if (relationshipIndex != -1) {
                            activitySet.partyRelationships.splice(
                                relationshipIndex,
                                1,
                            );
                        }
                    }
                });
                return update(state, {
                    activitySets: {
                        $set: newStateActivitySets,
                    },
                });
            }

            case `${types.ASSIGN_ACTIVITY_SET_TO_USER_EXECUTION_PLAN}`: {
                const { activitySetId, principalId } = action;
                const newStateActivitySets = _.cloneDeep(state.activitySets);
                const currentActivitySet = _.find(
                    newStateActivitySets,
                    (activitySet) => activitySet.id === activitySetId,
                );
                currentActivitySet.assignedPrincipalId = principalId;
                return update(state, {
                    activitySets: {
                        $set: newStateActivitySets,
                    },
                });
            }

            default:
                return state;
        }
    };
}
