import _ from 'lodash';
import apiTools from './apiTools';
import { ticketFilter, ticketPreviewFilter } from './../constants/apiFilters';
import { ExecutionTicketExtended, Entry } from '../types';

interface TicketFilter {
    where?: {
        [key: string]: any;
    };
    order?: string;
    skip?: number;
    limit?: number;
    include?: any;
}

interface DeadlineData {
    workflowStatusId: string;
    deadlineDate: string;
}

interface CreateActionTicketData {
    fieldEntryIds: number[];
    executionPlanId: number;
    label: string;
}

class TicketApi {
    static getTickets(
        filter: TicketFilter = {},
    ): Promise<ExecutionTicketExtended[]> {
        return apiTools
            .get(
                'ExecutionTicketListView',
                {
                    filter: Object.assign({}, ticketPreviewFilter, filter),
                },
                true,
            )
            .then((response) => response.data);
    }

    static getTicketsCount(
        where: TicketFilter['where'] = {},
    ): Promise<{ count: number }> {
        return apiTools
            .get('ExecutionTicketListView/count', { where })
            .then((response) => response.data);
    }

    static getStatusResolutions(filter: TicketFilter = {}): Promise<any[]> {
        const defaultFilter: TicketFilter = {
            limit: 10,
            order: 'createdAt DESC',
            include: [
                {
                    relation: 'ticket',
                    scope: {
                        include: ['activity'],
                    },
                },
                'modifiedBy',
                'resolution',
            ],
        };

        return apiTools
            .get('ExecutionTicketWorkflowStatusResolutions', {
                filter: Object.assign({}, defaultFilter, filter),
            })
            .then((response) => response.data);
    }

    static getTicket({
        id,
        isPreview,
    }: {
        id: number;
        isPreview: boolean;
    }): Promise<ExecutionTicketExtended> {
        const filter = isPreview ? ticketPreviewFilter : ticketFilter;
        return apiTools
            .get(`ExecutionTicketListView/${id}`, { filter })
            .then((response) => response.data);
    }

    static searchTicketPrincipals(
        query: string,
        includeDefaultGroups: boolean = false,
        restrictToAssigned: boolean = false,
    ): Promise<any[]> {
        return apiTools
            .get(`Principals/search`, {
                q: query,
                includeDefaultGroups,
                restrictToAssigned,
            })
            .then((response) => response.data);
    }

    static saveTicketEntries(
        ticketId: number,
        entries: Entry[],
    ): Promise<Entry[]> {
        return apiTools
            .post(
                `ExecutionTickets/${ticketId}/entries`,
                entries.map((entry) => {
                    const {
                        activityFieldId,
                        value,
                        outlier,
                        excludeFromStatistics,
                    } = entry;
                    return {
                        activityFieldId,
                        value,
                        outlier,
                        excludeFromStatistics,
                    };
                }),
            )
            .then((response) => response.data);
    }

    static saveTicketWorkflowEntries(
        ticket: ExecutionTicketExtended,
        entries: Entry[],
    ): Promise<Entry[]> {
        return apiTools
            .post(
                `ExecutionTickets/${ticket.id}/workflowEntries`,
                entries.map((entry) => {
                    let { workFlowStatusFieldId, value } = entry;
                    if (_.isPlainObject(value)) value = JSON.stringify(value);
                    return { workFlowStatusFieldId, value };
                }),
            )
            .then((response) => response.data);
    }

    static getTicketWorkflowEntries(
        ticket: ExecutionTicketExtended,
    ): Promise<Entry[]> {
        return apiTools
            .get(`ExecutionTickets/${ticket.id}/workflowEntries`, {
                filter: {
                    include: ['field', 'modifiedBy'],
                    where: {
                        current: true,
                    },
                },
            })
            .then((response) => response.data);
    }

    static transitionTicket(
        ticket: ExecutionTicketExtended,
        transitionId: string,
    ): Promise<ExecutionTicketExtended> {
        return apiTools
            .post(`ExecutionTickets/${ticket.id}/transition`, { transitionId })
            .then((response) => response.data);
    }

    static assignTicket(
        ticket: ExecutionTicketExtended,
        principalId: string,
    ): Promise<ExecutionTicketExtended> {
        return apiTools
            .post(`ExecutionTickets/${ticket.id}/assign`, { principalId })
            .then((response) => response.data);
    }

    static assignTickets(tickets: number[], principalId: string): Promise<any> {
        return apiTools
            .post(`ExecutionTickets/bulkAssign`, { tickets, principalId })
            .then((response) => response.data);
    }

    static resolveTicket(
        ticket: ExecutionTicketExtended,
        statusId: string,
        statusResolutionId: string,
    ): Promise<ExecutionTicketExtended> {
        return apiTools
            .post(`ExecutionTickets/${ticket.id}/createStatusResolution`, {
                statusId,
                statusResolutionId,
            })
            .then((response) => response.data);
    }

    static saveTicket(
        ticket: ExecutionTicketExtended,
    ): Promise<ExecutionTicketExtended> {
        const apiMethod = ticket.id ? apiTools.patch : apiTools.post;
        return apiMethod(`ExecutionTickets`, ticket).then(
            (response) => response.data,
        );
    }

    static deleteAttachment(
        ticket: ExecutionTicketExtended,
        attachmentId: number,
    ): Promise<any> {
        return apiTools
            .delete(`ExecutionTickets/${ticket.id}/attachments/${attachmentId}`)
            .then((response) => response);
    }

    static openAttachment(
        ticket: ExecutionTicketExtended,
        attachmentId: number,
    ): void {
        const url = apiTools.generateUrl(
            `ExecutionTickets/${ticket.id}/downloadAttachments/${attachmentId}`,
        );
        window.open(url, '_blank');
    }

    static getMinAmount(ticketId: number): Promise<any> {
        return apiTools
            .get(`ExecutionTickets/${ticketId}/workflowEntries`, {
                filter: {
                    include: ['minAmount', 'modifiedBy'],
                    where: {
                        current: true,
                    },
                },
            })
            .then((response) => response.data);
    }

    static getTicketForRedirect(id: number): Promise<ExecutionTicketExtended> {
        return apiTools
            .get(`ExecutionTickets/${id}`, {
                filter: {
                    include: [
                        {
                            relation: 'executionTicketSet',
                            scope: {
                                include: ['category'],
                            },
                        },
                    ],
                },
            })
            .then((response) => response.data);
    }

    static addExistingAttachment(
        ticketId: number,
        selectedAttachments: number[],
    ): Promise<any> {
        return apiTools
            .post(`ExecutionTickets/${ticketId}/addAttachments`, {
                id: selectedAttachments,
            })
            .then((response) => response.data);
    }

    static getFilteredTickets(params: any): Promise<any> {
        return apiTools
            .get('ExecutionTicketListView/filterByResolution', params)
            .then((response) => response.data);
    }

    static createImplementationExecution(params: any): Promise<any> {
        return apiTools
            .post('/ExecutionTickets/createImplementationExecution', params)
            .then((response) => response.data);
    }

    static addDeadline(ticketId: number, deadline: DeadlineData): Promise<any> {
        return apiTools
            .post(`/ExecutionTickets/${ticketId}/deadlines`, deadline)
            .then((response) => response.data);
    }

    static removeDeadline(ticketId: number, deadlineId: number): Promise<any> {
        return apiTools
            .delete(`/ExecutionTickets/${ticketId}/deadlines/${deadlineId}`)
            .then((response) => response.data);
    }

    static getSimpleTicket({
        id,
    }: {
        id: number;
    }): Promise<ExecutionTicketExtended> {
        return apiTools
            .get(`ExecutionTickets/${id}`)
            .then((response) => response.data);
    }

    static createActionTicket(
        ticketId: number,
        data: CreateActionTicketData,
    ): Promise<ExecutionTicketExtended> {
        return apiTools
            .post(`ExecutionTickets/${ticketId}/createActionTicket`, data)
            .then((response) => response.data);
    }
}

export default TicketApi;
