import { useState } from 'react';
import { Button, Form, InputOnChangeData } from 'semantic-ui-react';
import { useAppSelector } from '../../actions/store';
import { Field } from '../../types';
import FormBuilderFieldType from '../common/FormBuildFieldType';
import OptionsList from '../common/OptionsList';
import SubmitInput from '../common/SubmitInput';

type FieldData = Pick<
    Field,
    'fieldKey' | 'label' | 'activityFieldTypeId' | 'options'
>;
type FieldFormProps = {
    onSubmit: (field: FieldData) => void;
    isSaving?: boolean;
};

export const FieldForm = ({ onSubmit, isSaving }: FieldFormProps) => {
    const activityFieldTypes = useAppSelector(
        (state) => state.constants.activityFieldTypes,
    );

    const [field, setField] = useState<FieldData>({
        fieldKey: '',
        label: '',
        activityFieldTypeId: 'Number',
        options: {
            values: [],
        },
    });

    const handleChange = (e, { value, id }: InputOnChangeData) => {
        setField({ ...field, [id]: value });
    };

    const handleAddOption = (event, data) => {
        if (data.value === '') return;
        const values = field.options.values
            ? [...field.options.values, data.value]
            : [data.value];
        setField({ ...field, options: { values } });
    };

    const handleRemoveOption = (index) => {
        const values = field.options.values.filter((_, i) => i !== index);
        setField({ ...field, options: { values } });
    };

    const currentFieldType = activityFieldTypes.find(
        (type) => type.name === field.activityFieldTypeId,
    );

    return (
        <Form size="tiny" onSubmit={() => onSubmit(field)}>
            <Form.Field>
                <label>Field Type</label>
                <FormBuilderFieldType
                    value={{ fieldType: { name: field.activityFieldTypeId } }}
                    activityFieldTypes={activityFieldTypes}
                    changeFieldType={(id) => {
                        setField({ ...field, activityFieldTypeId: id });
                    }}
                />
            </Form.Field>
            <Form.Field>
                <label>Field Label</label>
                <Form.Input
                    placeholder="Field Label"
                    id="label"
                    value={field.label || ''}
                    onChange={handleChange}
                    fluid
                />
            </Form.Field>
            <Form.Field>
                <label>Field Key</label>
                <Form.Input
                    placeholder="Field Key"
                    id="fieldKey"
                    value={field.fieldKey || ''}
                    onChange={handleChange}
                    fluid
                />
            </Form.Field>
            {currentFieldType.requiresOptions && (
                <>
                    <OptionsList
                        value={field.options.values || []}
                        onRemove={handleRemoveOption}
                        editable
                    />
                    <SubmitInput
                        placeholder="Add Option"
                        onSubmit={handleAddOption}
                        name="option"
                    />
                </>
            )}

            <Button disabled={isSaving} loading={isSaving}>
                Submit
            </Button>
        </Form>
    );
};
