/* eslint-disable react/no-did-update-set-state */
import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import update from 'immutability-helper/index';

import * as uiActions from '../../actions/uiActions';
import { bindActionCreators } from 'redux';
import * as executionActions from '../../actions/executionActions';
import * as ticketActions from '../../actions/ticketActions';
import ExecutionContext from '../../context/ExecutionContext';
import { extractFunctions } from '../../utils/React';
import executionApi from '../../api/executionApi';
import { hasUserAttachFilesPermission } from '../../utils/ExecutionUtils';
import { MODAL_COMPONENTS } from '../common/ModalRoot';
import * as MODELS from '../../constants/models';

function ExecutionWrapper(ComponentToWrap) {
    class Execution extends Component {
        constructor(props) {
            super(props);
            const ticketId = _.get(props, 'match.params.ticketId');
            this.state = {
                filteredExecutions: [],
                uiState: {
                    splitView: ticketId ? true : false,
                    selectedTicketId: ticketId ? parseInt(ticketId) : null,
                },
                isExecutionLoading: false,
                principalFilter: null,
            };
        }

        componentDidUpdate(prevProps) {
            const currentTicketId = _.get(this.props, 'match.params.ticketId');
            const prevTicketId = _.get(prevProps, 'match.params.ticketId');
            if (currentTicketId) {
                if (currentTicketId != prevTicketId) {
                    const { uiState } = this.state;
                    this.setState({
                        uiState: {
                            ...uiState,
                            selectedTicketId: parseInt(currentTicketId),
                        },
                    });
                }
            } else {
                if (prevTicketId) {
                    this.updateUIState({ splitView: false });
                }
            }
        }

        gotoExecution = (execution) => {
            this.props.history.push(`/execution/${execution.id}`);
        };

        gotoTicket = (ticket) => {
            this.props.history.push(`/execution/${ticket.id}`);
        };

        updateUIState = (stateUpdateObject) => {
            const newUIState = Object.assign(
                {},
                this.state.uiState,
                stateUpdateObject,
            );
            if (newUIState.splitView === false) {
                newUIState.selectedTicketId = null;
                this.setUrl();
            }
            this.setState(
                update(this.state, {
                    uiState: { $set: newUIState },
                }),
            );
        };

        setUrl = (ticketId) => {
            const execution =
                this.props.execution || this.props.currentExecution;

            const newUrl = ticketId
                ? `/execution/${execution.id}/${this.props.match.params.category}/${ticketId}`
                : `/execution/${execution.id}/${this.props.match.params.category}`;

            this.props.history.push(newUrl);
        };

        ticketClicked = (ticket) => {
            this.updateUIState({
                splitView: true,
                selectedTicketId: ticket.id,
            });
            this.setUrl(ticket.id);
        };

        openAttachment = (execution, attachment) => {
            executionApi.openAttachment(execution, attachment.id);
        };

        openAddAttachmentModal = (execution) => {
            const {
                uiActions,
                executionStateActions,
                maxFileSize,
                currentUser,
            } = this.props;
            uiActions.showModal({
                modalType: MODAL_COMPONENTS.ADD_EXISTING_ATTACHMENT,
                modalProps: {
                    currentModel: execution,
                    modelStateActions: executionStateActions,
                    modelType: MODELS.EXECUTION,
                    maxFileSize,
                    currentUser,
                },
            });
        };

        openEditAttachmentDetailsModal = (attachment) => {
            const { uiActions } = this.props;
            const userDetails = this.props.currentUser.details;
            uiActions.showModal({
                modalType: MODAL_COMPONENTS.EDIT_ATTACHMENT_DETAILS,
                modalProps: {
                    attachment,
                    userDetails,
                },
            });
        };

        loadExecution = (id, type) => {
            const { executionStateActions } = this.props;
            this.setState({ isExecutionLoading: true });

            executionStateActions
                .loadExecution(id, type)
                .then(() => {
                    executionStateActions.getExecutionTicketDeadlineCount(id);
                    this.setState({ isExecutionLoading: false });
                })
                .catch(() => {
                    this.props.history.replace('/notFoundPage');
                });
        };

        setFilterPrincipal = (principal) => {
            this.setState({ principalFilter: principal });
        };

        render() {
            const {
                executions,
                currentExecution,
                executionStateActions,
                uiActions,
                workflowStatusTypes,
                ticketActions,
                currentUser,
                attachmentsConstants,
                executionStateTypes,
            } = this.props;

            const userHasAttachFilesPermission = currentUser
                ? hasUserAttachFilesPermission(currentUser, currentExecution)
                : false;

            const { filter, uiState, isExecutionLoading, principalFilter } =
                this.state;
            const values = {
                executions,
                currentExecution,
                uiActions,
                workflowStatusTypes,
                executionStateTypes,
                executionStateActions,
                ticketStateActions: ticketActions,
                executionActions: extractFunctions(this),
                uiState: uiState,
                userHasAttachFilesPermission,
                attachmentsConstants,
                executionsFilter: filter,
                isExecutionLoading:
                    isExecutionLoading || _.isEmpty(currentExecution),
                principalFilter,
                setFilterPrincipal: this.setFilterPrincipal,
            };
            return (
                <ExecutionContext.Provider value={values}>
                    <ComponentToWrap {...values} {...this.props} />
                </ExecutionContext.Provider>
            );
        }
    }

    Execution.propTypes = {
        executionStateActions: PropTypes.object.isRequired,
        ticketActions: PropTypes.object.isRequired,
        currentExecution: PropTypes.object.isRequired,
        uiActions: PropTypes.object.isRequired,
        executions: PropTypes.array.isRequired,
        history: PropTypes.object.isRequired,
        workflowStatusTypes: PropTypes.array,
        executionStateTypes: PropTypes.array,
        execution: PropTypes.object,
        match: PropTypes.object,
        currentUser: PropTypes.object,
        maxFileSize: PropTypes.number,
        attachmentsConstants: PropTypes.object,
    };

    function mapStateToProps(state) {
        const { constants } = state;
        const attachmentsConstants = {
            attachmentClassification: constants.attachmentClassification,
            attachmentType: constants.attachmentType,
        };
        return {
            uiStatus: state.uiStatus,
            executions: state.executions,
            currentExecution: state.currentExecution,
            workflowStatusTypes: state.constants.workflowStatusTypes,
            maxFileSize: state.constants.options.maxFileSize,
            executionStateTypes: state.constants.executionStateTypes,
            attachmentsConstants,
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            executionStateActions: bindActionCreators(
                executionActions,
                dispatch,
            ),
            ticketActions: bindActionCreators(ticketActions, dispatch),
            uiActions: bindActionCreators(uiActions, dispatch),
        };
    }
    return connect(mapStateToProps, mapDispatchToProps)(Execution);
}

export default ExecutionWrapper;
