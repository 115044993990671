import { FC, FormEvent } from 'react';
import { Button, Dimmer, Form, Loader, Modal } from 'semantic-ui-react';
import { TermsAndConditions } from '@brallum/vidende/src/api/termsAndConditionsApi';

interface TermsAndConditionsModalContentProps {
    isLoading: boolean;
    termsAndConditionsList: TermsAndConditions[];
    acceptedTerms: {};
    sendEmail: boolean;
    disabled: boolean;
    onOpenTemplate: (arg) => void;
    onSetSendEmail: (arg: boolean) => void;
    onChange: (
        e: FormEvent<HTMLInputElement>,
        payload: { value: boolean; name: string },
    ) => void;
    onSaveAcceptedTermsAndConditions: () => void;
}

const TermsAndConditionsModalContent: FC<
    TermsAndConditionsModalContentProps
> = ({
    isLoading,
    termsAndConditionsList,
    acceptedTerms,
    sendEmail,
    disabled,
    onOpenTemplate,
    onChange,
    onSetSendEmail,
    onSaveAcceptedTermsAndConditions,
}) => {
    return (
        <>
            <Dimmer active={isLoading} inverted>
                <Loader disabled={!isLoading} />
            </Dimmer>
            <Modal.Header>Terms and Conditions</Modal.Header>
            <Modal.Content>
                <Form>
                    {termsAndConditionsList.map((item) => (
                        <Form.Field key={item.code}>
                            <Form.Checkbox
                                label={
                                    <label>
                                        {item.acceptText}{' '}
                                        <a
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                onOpenTemplate(item);
                                            }}
                                        >
                                            {item.linkText}
                                        </a>
                                    </label>
                                }
                                checked={acceptedTerms[item.code].value}
                                onChange={(e, { checked }) => {
                                    onChange(e, {
                                        name: item.code,
                                        value: checked,
                                    });
                                }}
                            />
                        </Form.Field>
                    ))}
                    <Form.Field>
                        <Form.Checkbox
                            label="Email me the accepted items"
                            checked={sendEmail}
                            onChange={(e, { checked }) => {
                                onSetSendEmail(checked);
                            }}
                        ></Form.Checkbox>
                    </Form.Field>
                </Form>
            </Modal.Content>

            <Modal.Actions>
                <Button
                    positive
                    onClick={onSaveAcceptedTermsAndConditions}
                    loading={isLoading}
                    disabled={isLoading || disabled}
                >
                    Done
                </Button>
            </Modal.Actions>
        </>
    );
};

export default TermsAndConditionsModalContent;
