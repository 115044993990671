import { FC } from 'react';
import { Header, Segment } from 'semantic-ui-react';
import FormFields from '../../common/FormFields';
import { orderBy } from 'lodash';

interface TicketReadingViewSidebarProps {
    closeSidebar: () => void;
    ticket: any;
}

const TicketReadingViewSidebar: FC<TicketReadingViewSidebarProps> = ({
    closeSidebar,
    ticket,
}) => {
    const fields = ticket?.activity?.fields
        ? orderBy(ticket.activity.fields, 'sort').filter(
              (field) => field.activityFieldTypeId !== 'Threshold',
          )
        : null;

    return (
        <Segment basic>
            <Header>{ticket.label}</Header>
            <Segment>
                <FormFields
                    fields={fields}
                    entries={ticket?.entries}
                    editable={false}
                    fieldIdKey="activityFieldId"
                />
            </Segment>
        </Segment>
    );
};

export default TicketReadingViewSidebar;
