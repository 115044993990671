import React from 'react';
import * as PropTypes from 'prop-types';

import FilterAndPaginate from '../common/FilterAndPaginate';
import Pagination from '../common/Pagination';
import ExecutionsList from './ExecutionsList';

const ExecutionSidebarList = (props) => {
    const {
        paginatedItems,
        handleSortChange,
        handlePaginationChange,
        paginationOptions,
    } = props;
    return (
        <React.Fragment>
            <ExecutionsList
                executions={paginatedItems || []}
                paginationOptions={paginationOptions}
                handleSortChange={handleSortChange}
            />
            <Pagination
                activePage={paginationOptions.activePage}
                onPageChange={handlePaginationChange}
                totalPages={paginationOptions.totalPages}
                style={{ float: 'center' }}
                className="sidebar-pagination"
            />
        </React.Fragment>
    );
};

ExecutionSidebarList.propTypes = {
    onClick: PropTypes.func,
    items: PropTypes.array.isRequired,
    paginationOptions: PropTypes.object,
    paginatedItems: PropTypes.array,
    handleSortChange: PropTypes.func,
    handlePaginationChange: PropTypes.func,
};

function mapOptions(props) {
    return {
        items: props.items,
    };
}

export default FilterAndPaginate(mapOptions)(ExecutionSidebarList);
