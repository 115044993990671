import React, { useContext } from 'react';
import * as PropTypes from 'prop-types';
import Moment from 'react-moment';
import { Table } from 'semantic-ui-react';
import toastr from 'toastr';

import ActionButtons from './../common/ActionButtons';
import PartyLabel from '../common/labels/PartyLabel';
import ConfirmationModalContext from '../../context/ConfirmationModalContext';

const RelationshipListItem = ({
    relationship,
    deletePartyRelationship,
    editPartyRelationship,
    showConfirmDialog,
    showOnlyInfoAction,
}) => {
    const { confirmPopupActions } = useContext(ConfirmationModalContext);

    const deleteRelationship = () => {
        showConfirmDialog(
            `Are you sure you want to delete ${relationship.label} entity?`,
            deleteRealtionshipOnConfirm,
        );
    };
    const deleteRealtionshipOnConfirm = () => {
        deletePartyRelationship(relationship.fromPartyId, relationship.id).then(
            deleteRealtionshipOnConfirmCallback,
        );
    };
    const deleteRealtionshipOnConfirmCallback = () => {
        toastr.success(`Relationship ${relationship.label} is removed`);
    };
    const editRelationship = () => {
        editPartyRelationship(relationship);
    };
    return (
        <Table.Row>
            <Table.Cell width={2}>{relationship.label}</Table.Cell>
            <Table.Cell width={2}>
                {relationship.relationshipType.label}
            </Table.Cell>
            <Table.Cell width={2}>
                <PartyLabel party={relationship.toParty} />
            </Table.Cell>
            <Table.Cell width={2}>
                <Moment>{relationship.createdAt}</Moment>
            </Table.Cell>
            <Table.Cell width={1} textAlign="center">
                <ActionButtons
                    className="u-show-on-hover__target"
                    deleteClicked={
                        showOnlyInfoAction ? null : deleteRelationship
                    }
                    editClicked={showOnlyInfoAction ? null : editRelationship}
                    infoClicked={() => {
                        confirmPopupActions.showRelationshipSidebar(
                            relationship,
                        );
                    }}
                />
            </Table.Cell>
        </Table.Row>
    );
};

RelationshipListItem.propTypes = {
    relationship: PropTypes.object.isRequired,
    deletePartyRelationship: PropTypes.func.isRequired,
    editPartyRelationship: PropTypes.func.isRequired,
    showConfirmDialog: PropTypes.func.isRequired,
};

export default RelationshipListItem;
