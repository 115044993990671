import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { Header } from 'semantic-ui-react';
import FilterAndPaginate from '../common/FilterAndPaginate';
import Pagination from '../common/Pagination';
import UserList from '../party/UserList';
import PartyWrapper from '../party/Party';

class UsersListPaginated extends Component {
    static propTypes = {
        paginationOptions: PropTypes.object,
        paginatedItems: PropTypes.array,
        handleSortChange: PropTypes.func,
        handleAddFilter: PropTypes.func,
        handlePaginationChange: PropTypes.func,
        partyStateActions: PropTypes.object,
        userStateActions: PropTypes.object,
        userIsAdmin: PropTypes.bool,
        includeParty: PropTypes.bool,
        loadUsers: PropTypes.func,
    };

    render() {
        const {
            paginatedItems,
            handleSortChange,
            handleAddFilter,
            handlePaginationChange,
            paginationOptions,
            partyStateActions,
            userStateActions,
            userIsAdmin,
            includeParty,
            loadUsers,
        } = this.props;

        return (
            <React.Fragment>
                <Header as="h2">Users</Header>
                <UserList
                    users={paginatedItems || []}
                    paginationOptions={paginationOptions}
                    handleSortChange={handleSortChange}
                    partyStateActions={partyStateActions}
                    userStateActions={userStateActions}
                    userIsAdmin={userIsAdmin}
                    includeParty={includeParty}
                    handleAddFilter={handleAddFilter}
                    loadUsers={loadUsers}
                />
                <Pagination
                    activePage={paginationOptions.activePage}
                    onPageChange={handlePaginationChange}
                    totalPages={paginationOptions.totalPages}
                    style={{ float: 'right' }}
                />
            </React.Fragment>
        );
    }
}

function mapOptions(props) {
    return {
        items: props.users,
    };
}

export default PartyWrapper(FilterAndPaginate(mapOptions)(UsersListPaginated));
