import { ExecutionTicketEntry } from '../types';
import apiTools from './apiTools';
import LoopbackFilter from './apiTypes';

class EntriesApi {
    static getExecutionEntries(
        filter?: LoopbackFilter,
    ): Promise<ExecutionTicketEntry[]> {
        return apiTools
            .get('ExecutionTicketEntryView', { filter })
            .then((response) => {
                return response.data;
            });
    }
}

export default EntriesApi;
