import { useRef } from 'react';
import * as PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';

import { formatValue } from '../../../../../utils/FormatUtils';
import { kpiValueFormats } from '../../../../../constants/config';
import DownloadDropdown from './DownloadDropdown';
import { downloadImage } from '../../../../../utils/downloadUtils';

export const ThirdStyleKpiComponent = ({
    label,
    format = kpiValueFormats.Number,
    color,
    value,
    currencyType,
    onClick,
    tooltip = '',
    chartOptions,
}) => {
    const ref = useRef(null);
    const handleDownoad = (format) => {
        downloadImage(ref.current, format);
    };
    return (
        <div
            ref={ref}
            className="component-dashboard-widget component-kpi third"
            onClick={onClick}
            style={{
                cursor: onClick ? 'pointer' : '',
            }}
        >
            <DownloadDropdown
                chartOptions={chartOptions}
                onDownloadImage={handleDownoad}
                isKPI={true}
            />
            <div className="label-component-kpi">
                <Icon
                    name="circle"
                    className="icon-component-kpi"
                    color={color}
                />
                {label}
            </div>
            <div className="value-component-kpi">
                {formatValue(value, format, currencyType)}
            </div>
        </div>
    );
};

ThirdStyleKpiComponent.propTypes = {
    color: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    format: PropTypes.string,
    currencyType: PropTypes.string,
    onClick: PropTypes.func,
    chartOptions: PropTypes.object,
};
