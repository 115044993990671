import { useState, useEffect, FocusEvent, ChangeEvent } from 'react';
import { Form, Icon, Popup } from 'semantic-ui-react';
import numbro from 'numbro';
import FieldToolTip from './FieldToolTip';
import Outlier from './Outlier';

interface NumberComponentProps {
    name?: string;
    label?: string;
    value?: string | number;
    description?: string;
    onChange?: (
        event: ChangeEvent<HTMLInputElement>,
        data: {
            name: string;
            value: string | number | undefined;
            outlier?: boolean;
            excludeFromStatistics?: boolean;
        },
    ) => void;
    regex?: RegExp;
    disabled?: boolean;
    errors?: object;
    required?: boolean;
    editable?: boolean;
    error?: boolean;
    onFocus?(name: string): string;
    onBlur?(name: string): string;
    alwaysReturnString?: boolean;
    outlier?: boolean;
    excludeFromStatistics?: boolean;
    dataField?: boolean;
    isManufacturer?: boolean;
}

const NumberComponent = ({
    name,
    label,
    value: propValue,
    onChange,
    regex,
    disabled = false,
    description,
    errors,
    required,
    editable = true,
    error,
    onFocus,
    onBlur,
    alwaysReturnString,
    dataField,
    excludeFromStatistics,
    outlier,
    isManufacturer,
}: NumberComponentProps) => {
    const [numberValue, setNumberValue] = useState<number | null>(null);
    const [stringValue, setStringValue] = useState<string>('');
    const [editingStringValue, setEditingStringValue] = useState<string>('');

    const [isFocused, setIsFocused] = useState<boolean>(false);

    useEffect(() => {
        if (propValue !== undefined && propValue !== null) {
            const parsedValue = parseFloat(propValue.toString());
            setNumberValue(isNaN(parsedValue) ? null : parsedValue);
            setEditingStringValue(
                isNaN(parsedValue) ? '' : parsedValue.toString(),
            );
            setStringValue(formatNumber(propValue));
        }
    }, [propValue]);

    const formatNumber = (value: string | number | null): string => {
        if (value === null || value === '') {
            return '';
        }
        return numbro(value).format({ thousandSeparated: true });
    };

    const getDisplayedValue = () => {
        if (isFocused) {
            return editingStringValue;
        }
        return stringValue;
    };

    const displayedValue = getDisplayedValue();

    const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
        setIsFocused(true);
        setStringValue(numberValue !== null ? numberValue.toString() : '');
        onFocus?.(name);
    };

    const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
        setIsFocused(false);
        onBlur?.(name);
    };

    const handleInputChanged = (
        event: ChangeEvent<HTMLInputElement>,
        { name, value }: { name: string; value: string },
    ) => {
        if (value.length < 17) {
            const validRegex = regex ? regex : /^[0-9.]+$/;
            if (value === '' || validRegex.test(value)) {
                const parsedValue =
                    value === '' ? undefined : parseFloat(value);
                onChange?.(event, {
                    name,
                    value: alwaysReturnString ? value : parsedValue,
                });
                setNumberValue(isNaN(parsedValue) ? null : parsedValue);
                setStringValue(value);
                setEditingStringValue(value);
            }
        }
    };

    if (!editable) {
        return <>{displayedValue}</>;
    }

    return (
        <Form.Field required={required} error={error}>
            <label>
                {label}
                <FieldToolTip description={description} />
                {isManufacturer && dataField && (
                    <Outlier
                        value={propValue}
                        name={name}
                        outlier={outlier}
                        excludeFromStatistics={excludeFromStatistics}
                        onChange={onChange}
                    />
                )}
            </label>
            <Form.Input
                type="text"
                placeholder={label}
                value={displayedValue}
                name={name}
                onChange={handleInputChanged}
                onBlur={handleBlur}
                onFocus={handleFocus}
                disabled={disabled}
            />
        </Form.Field>
    );
};

export default NumberComponent;
