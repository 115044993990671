import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { Confirm } from 'semantic-ui-react';
import toastr from 'toastr';

import AttachmentsList from './AttachmentsList';

class AttachmentsListContainer extends Component {
    state = {
        deleteConfirmationOpen: false,
        attachmentToDelete: null,
    };

    deleteAttachment = (attachment) => {
        this.setState({
            deleteConfirmationOpen: true,
            attachmentToDelete: attachment,
        });
    };
    confirmDeleteAttachment = () => {
        const { currentModel, modelStateActions } = this.props;
        const { attachmentToDelete } = this.state;

        modelStateActions
            .deleteAttachment(currentModel, attachmentToDelete)
            .then(() => {
                toastr.success('Attachment Deleted');
            })
            .catch((error) => {
                toastr.error(error);
                throw error;
            });

        this.setState({
            deleteConfirmationOpen: false,
        });
    };
    cancelDeleteAttachment = () => {
        this.setState({
            deleteConfirmationOpen: false,
            attachmentToDelete: null,
        });
    };

    downloadAttachment = (attachment) => {
        const { currentModel, modelActions } = this.props;
        modelActions.openAttachment(currentModel, attachment);
    };

    handleOpenAddAttachmentModal = () => {
        const { currentModel } = this.props;
        const { openAddAttachmentModal } = this.props.modelActions;
        openAddAttachmentModal(currentModel);
    };

    render() {
        const {
            currentModel,
            modelStateActions,
            showDetails,
            attachmentsConstants,
            disabled,
        } = this.props;
        const { openAddAttachmentModal, openEditAttachmentDetailsModal } =
            this.props.modelActions;
        return (
            <React.Fragment>
                <AttachmentsList
                    attachments={currentModel.attachments || []}
                    openAddAttachmentDialog={
                        openAddAttachmentModal
                            ? this.handleOpenAddAttachmentModal
                            : null
                    }
                    onDelete={modelStateActions ? this.deleteAttachment : null}
                    onDownload={this.downloadAttachment}
                    showDetails={showDetails}
                    attachmentsConstants={attachmentsConstants}
                    editAsModal={true}
                    allowEditDetails={true}
                    openEditModal={openEditAttachmentDetailsModal}
                    disabled={disabled}
                />
                <Confirm
                    open={this.state.deleteConfirmationOpen}
                    content="Are you sure you want to delete this attachment?"
                    onConfirm={this.confirmDeleteAttachment}
                    onCancel={this.cancelDeleteAttachment}
                />
            </React.Fragment>
        );
    }
}

AttachmentsListContainer.propTypes = {
    openFileDialog: PropTypes.func,
    openAddAttachmentDialog: PropTypes.func,
    disabled: PropTypes.bool,
    currentModel: PropTypes.object,
    modelStateActions: PropTypes.object,
    modelActions: PropTypes.object,
    modelType: PropTypes.string,
    showDetails: PropTypes.bool,
    attachmentsConstants: PropTypes.object,
};

export default AttachmentsListContainer;
