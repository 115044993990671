import React, { useState, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as _ from 'lodash';
import toastr from 'toastr';
import { Popup } from 'semantic-ui-react';
import { TicketContextConsumer } from '../../context/TicketContext';
import PrincipalLabel from '../common/PrincipalLabel';
import { mapPrincipal } from '../../utils/TicketUtils';
import PrincipalSearch from '../common/PrincipalSearch';
import { getFeatures } from '../../utils/Features';
import { MODAL_COMPONENTS } from '../common/ModalRoot';
import * as uiActions from '../../actions/uiActions';
import { RootState } from '../../actions/store';
import { ExecutionTicketExtended } from '../../types';

interface Principal {
    id: string;
    label: string;
    type: string;
}

interface TicketAssignmentProps extends PropsFromRedux {
    ticket?: ExecutionTicketExtended;
    currentTicket?: ExecutionTicketExtended;
    ticketStateActions: {
        assign: (
            ticket: ExecutionTicketExtended,
            principalId: string | null,
        ) => Promise<void>;
    };
    disabled?: boolean;
    userHasAssignPermission?: boolean;
}

interface TicketAssignmentState {
    currentPrincipal: Principal | null;
    saving: boolean;
    showAssignment: boolean;
}

const TicketAssignment: React.FC<TicketAssignmentProps> = ({
    ticket,
    currentTicket,
    ticketStateActions,
    disabled,
    userHasAssignPermission,
    featureFlags,
    uiActions,
}) => {
    const activeTicket = ticket || currentTicket;

    const [state, setState] = useState<TicketAssignmentState>(() => {
        if (!activeTicket) {
            return {
                currentPrincipal: null,
                saving: false,
                showAssignment: false,
            };
        }

        const {
            assignedPrincipalId,
            assignedPrincipalLabel,
            assignedPrincipalType,
        } = activeTicket;

        const assignedPrincipalObj = {
            id: assignedPrincipalId,
            label: assignedPrincipalLabel,
            type: assignedPrincipalType,
        };

        return {
            currentPrincipal: assignedPrincipalObj
                ? mapPrincipal(assignedPrincipalObj)
                : null,
            saving: false,
            showAssignment: false,
        };
    });

    useEffect(() => {
        if (!activeTicket) return;

        const prevTicket = ticket || currentTicket;

        if (
            activeTicket &&
            prevTicket &&
            (activeTicket.id !== prevTicket.id ||
                activeTicket.assignedPrincipalId !==
                    prevTicket.assignedPrincipalId)
        ) {
            const {
                assignedPrincipalId,
                assignedPrincipalLabel,
                assignedPrincipalType,
            } = activeTicket;

            const assignedPrincipalObj = {
                id: assignedPrincipalId,
                label: assignedPrincipalLabel,
                type: assignedPrincipalType,
            };

            setState((prev) => ({
                ...prev,
                currentPrincipal: mapPrincipal(assignedPrincipalObj),
            }));
            return;
        }

        if (!activeTicket || !activeTicket.assignedPrincipalId) return;

        if (
            !state.currentPrincipal ||
            activeTicket.assignedPrincipalId !== state.currentPrincipal.id
        ) {
            setState((prev) => ({
                ...prev,
                currentPrincipal: {
                    id: activeTicket.assignedPrincipalId,
                    label: activeTicket.assignedPrincipalLabel,
                    type: activeTicket.assignedPrincipalType,
                },
            }));
        }
    }, [activeTicket, ticket, currentTicket]);

    const handleChange = async (
        event: React.SyntheticEvent,
        data: { value: Principal | null },
    ) => {
        const { value } = data;

        // Do not search if chosen principal hasn't changed
        if (
            state.currentPrincipal &&
            value &&
            state.currentPrincipal.id === value.id
        ) {
            setState((prev) => ({ ...prev, showAssignment: false }));
            return;
        }

        setState((prev) => ({ ...prev, saving: true }));

        try {
            await ticketStateActions.assign(
                activeTicket!,
                value ? value.id : null,
            );
            toastr.success('Ticket assignment successful');
            setState({
                saving: false,
                showAssignment: false,
                currentPrincipal: value,
            });
        } catch (error) {
            toastr.error(error as string);
            setState((prev) => ({
                ...prev,
                saving: false,
                showAssignment: false,
            }));
        }
    };

    const labelClicked = () => {
        if (!userHasAssignPermission) {
            return toastr.error(
                'You do not have permission to reassign this ticket',
            );
        }

        if (!disabled) {
            const { AllowAssignmentFilterCurrentParty } =
                getFeatures(featureFlags);

            if (AllowAssignmentFilterCurrentParty) {
                uiActions.showModal({
                    modalType: MODAL_COMPONENTS.ASSIGN_TICKET_MODAL,
                    modalProps: {
                        assignObject: {
                            label: ticket?.label,
                            tickets: [ticket],
                        },
                        isOneTicket: true,
                    },
                });
            } else {
                setState((prev) => ({ ...prev, showAssignment: true }));
            }
        }
    };

    const { currentPrincipal, saving } = state;

    return (
        <Popup
            on="click"
            position="bottom left"
            trigger={
                <div>
                    <PrincipalLabel
                        principal={currentPrincipal}
                        onClick={labelClicked}
                    />
                </div>
            }
            open={state.showAssignment}
            onClose={() =>
                setState((prev) => ({ ...prev, showAssignment: false }))
            }
        >
            <div style={{ width: '250px' }}>
                <PrincipalSearch
                    onChange={handleChange}
                    saving={saving}
                    addUnassignValue={true}
                    currentPrincipal={currentPrincipal}
                />
            </div>
        </Popup>
    );
};

const mapStateToProps = (state: RootState) => ({
    featureFlags: state.constants.options?.featureFlags,
});

const mapDispatchToProps = (dispatch: any) => ({
    uiActions: bindActionCreators(uiActions, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(TicketContextConsumer(TicketAssignment));
