import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { Container, Header, Icon, Segment } from 'semantic-ui-react';
import errorApi from '../../api/errorApi';

const ErrorBoundary = (WrappedComponent) =>
    class ErroCatcher extends Component {
        state = { hasError: false, showMoreInfo: false };

        componentDidCatch = (error, info) => {
            this.catchFunction(error, info);
        };

        catchFunction = (error, info) => {
            this.setState({ hasError: true, error, info });
        };

        toggleshowMoreInfo = () => {
            this.setState({
                showMoreInfo: !this.state.showMoreInfo,
            });
        };

        render() {
            if (this.state.hasError) {
                const { error, showMoreInfo } = this.state;
                errorApi.saveError(error);
                return (
                    <Container>
                        <Header>
                            Something went wrong, please try again.{' '}
                        </Header>
                        <p onClick={this.toggleshowMoreInfo}>
                            More info{' '}
                            <Icon
                                name={showMoreInfo ? 'minus' : 'plus'}
                                fitted
                            />
                        </p>
                        {showMoreInfo && (
                            <Segment>
                                <Header size="small">Error:</Header>
                                <p>{error.message}</p>
                                <Header size="small">Stack:</Header>
                                <p>
                                    {error.stack
                                        .split('\n')
                                        .map((item, key) => {
                                            return (
                                                <span key={key}>
                                                    {item}
                                                    <br />
                                                </span>
                                            );
                                        })}
                                </p>
                            </Segment>
                        )}
                    </Container>
                );
            }
            return <WrappedComponent {...this.props} />;
        }
    };

ErrorBoundary.propTypes = {
    children: PropTypes.object,
};

export default ErrorBoundary;
