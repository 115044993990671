import React from 'react';
import * as PropTypes from 'prop-types';
import { Checkbox, Dropdown, Form, Header, Segment } from 'semantic-ui-react';
import * as _ from 'lodash';
import AnalyseAndImplementOptions from '../common/fields/options/AnalyseAndImplementOptions';
import { granularPermissionTypes } from '../../constants/config';
import { mapToOptions } from '../../utils/React';
import BinderScoping from '../binderScoping/BinderScoping';
import { EntityAssignment } from './EntityAssignment';

const ExecutionPlanOtherSettings = (props) => {
    const { setOptionsValue, setValue, options } = props;
    const { addAllSets } = options;

    const analyseAndImplement = _.get(
        options,
        'meta.options.AnalyseAndImplement',
    );

    return (
        <Segment basic>
            <Segment>
                <Header>Scoping</Header>
                <Form>
                    <Form.Field>
                        <Checkbox
                            label="Scope all question sets"
                            name="addAllSets"
                            checked={addAllSets === true}
                            onChange={setValue}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Allow granular permissions</label>

                        <Dropdown
                            options={mapToOptions(granularPermissionTypes)}
                            fluid
                            selection
                            value={options.granularPermissionTypeId}
                            name="granularPermissionTypeId"
                            onChange={setValue}
                        />
                    </Form.Field>
                </Form>
            </Segment>

            <Segment>
                <Header>Entity Assignment</Header>
                <EntityAssignment />
            </Segment>

            {analyseAndImplement && (
                <Segment>
                    <Header>Analysis & Implementation</Header>
                    <AnalyseAndImplementOptions
                        value={analyseAndImplement}
                        onChange={(data) => {
                            setOptionsValue('AnalyseAndImplement', data);
                        }}
                    />
                </Segment>
            )}

            {options.enableBinderScoping && (
                <Segment>
                    <Header>Binder Scoping</Header>

                    <BinderScoping
                        options={options}
                        onChange={(data) => {
                            setOptionsValue('binderScoping', data);
                        }}
                    />
                </Segment>
            )}
        </Segment>
    );
};

ExecutionPlanOtherSettings.propTypes = {
    analyseAndImplement: PropTypes.object,
    options: PropTypes.object,
    setOptionsValue: PropTypes.func,
    setValue: PropTypes.func,
};

export default ExecutionPlanOtherSettings;
