import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Dimmer, Loader, Modal, Form } from 'semantic-ui-react';
import * as _ from 'lodash';
import toastr from 'toastr';

import * as uiActions from '../../actions/uiActions';
import DataFieldReference from '../common/DataFieldReference';
import Text from '../common/fields/Text';
import Select from '../common/fields/Select';
import { checkModalFormDataValid } from '../../utils/Validation';
import {
    comparisonValuesThresholdModal,
    valueTypeValuesThresholdModal,
} from '../../constants/config';

class SaveCreateThresholdModal extends Component {
    state = {
        dataFieldReference: undefined,
        comparison: undefined,
        valueType: undefined,
        lowValue: undefined,
        highValue: undefined,
        saving: false,
        isModalFormDataValid: false,
        validations: {
            dataFieldReference: {},
            comparison: {},
            valueType: {},
            lowValue: {},
            highValue: {},
        },
    };

    componentWillMount() {
        const { threshold } = this.props;
        if (threshold) {
            const {
                comparison,
                valueType,
                lowValue,
                highValue,
                dataFieldReference,
            } = threshold;
            this.setState({
                dataFieldReference,
                comparison,
                valueType,
                lowValue,
                highValue,
                validations: {
                    dataFieldReference: { isValid: true },
                    comparison: { isValid: true },
                    valueType: { isValid: true },
                    lowValue: { isValid: true },
                    highValue: { isValid: true },
                },
                isModalFormDataValid: true,
            });
        }
    }

    handleInputChanged = (event, { name, value, checked }) => {
        this.setState({ [name]: value || checked }, () => {
            if (value !== undefined) {
                this.checkValidation(name, this.state[name]);
            }
        });
    };

    handleOnFocus = (name) => {
        const { validations } = this.state;
        validations[name].hasFocus = true;
        this.setState({ validations });
    };

    handleOnBlur = (name) => {
        const { validations } = this.state;
        validations[name].hasFocus = false;
        this.checkValidation(name, this.state[name]);
        this.setState({ validations });
    };

    checkValidation = (name, value) => {
        const { validations } = this.state;
        const modalFormDataValid = checkModalFormDataValid(
            validations,
            name,
            value,
        );
        this.setState({ ...modalFormDataValid });
    };

    getErrors = (propertryName) => {
        const { validations } = this.state;
        const validation = validations[propertryName];
        const isValidationErrors = !_.isEmpty(validation.errors);
        return !validation.hasFocus && isValidationErrors
            ? validation.errors
            : {};
    };

    createThreshold = () => {
        const {
            dataFieldReference,
            comparison,
            valueType,
            lowValue,
            highValue,
        } = this.state;

        const { partyStateActions, threshold } = this.props;
        const { structuralNodeId } = this.props.party;
        const partyId = this.props.party.id;

        this.setState({ saving: true });

        const newThreshold = {
            dataFieldReference,
            comparison,
            valueType,
            lowValue,
            highValue,
            structuralNodeId,
        };

        partyStateActions
            .saveThreshold(
                newThreshold,
                partyId,
                threshold ? threshold.id : null,
            )
            .then(this.saveThresholdCallback)
            .catch((error) => this.saveThresholdError(error));
    };

    saveThresholdCallback = () => {
        const { uiActions, threshold } = this.props;
        threshold
            ? toastr.success(
                  `Threshold ${threshold.dataFieldReference} is updated `,
              )
            : toastr.success('New threshold is added ');
        uiActions.closeModal();
    };

    saveThresholdError = (error) => {
        toastr.error(error);
        this.setState({ saving: false });
        throw error;
    };

    render() {
        const {
            saving,
            dataFieldReference,
            comparison,
            loading,
            valueType,
            lowValue,
            highValue,
            isModalFormDataValid,
        } = this.state;
        const { uiActions, threshold, riskThresholds, constantStateActions } =
            this.props;

        return (
            <Modal
                open={true}
                onClose={() => uiActions.closeModal()}
                size="tiny"
                closeOnDimmerClick={false}
            >
                <Dimmer active={saving} inverted>
                    <Loader disabled={!saving} />
                </Dimmer>
                <Modal.Header>
                    {threshold ? `Update threshold` : 'Create new threshold'}
                </Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Field>
                            <DataFieldReference
                                name="dataFieldReference"
                                saving={loading}
                                showUnassigned={false}
                                label="Data Field"
                                value={dataFieldReference}
                                errors={this.getErrors('dataFieldReference')}
                                onChange={this.handleInputChanged}
                                onBlur={() =>
                                    this.handleOnBlur('dataFieldReference')
                                }
                                onFocus={() =>
                                    this.handleOnFocus('dataFieldReference')
                                }
                                constantStateActions={constantStateActions}
                                riskThresholds={riskThresholds}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Select
                                name="comparison"
                                options={comparisonValuesThresholdModal}
                                label="Comparison"
                                value={comparison}
                                errors={this.getErrors('comparison')}
                                onChange={this.handleInputChanged}
                                onBlur={() => this.handleOnBlur('comparison')}
                                onFocus={() => this.handleOnFocus('comparison')}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Select
                                name="valueType"
                                options={valueTypeValuesThresholdModal}
                                label="Value Type"
                                value={valueType}
                                errors={this.getErrors('valueType')}
                                onChange={this.handleInputChanged}
                                onBlur={() => this.handleOnBlur('valueType')}
                                onFocus={() => this.handleOnFocus('valueType')}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Text
                                label="Low Value"
                                value={lowValue}
                                name="lowValue"
                                type="number"
                                errors={this.getErrors('lowValue')}
                                onChange={this.handleInputChanged}
                                onBlur={() => this.handleOnBlur('lowValue')}
                                onFocus={() => this.handleOnFocus('lowValue')}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Text
                                label="High Value"
                                value={highValue}
                                name="highValue"
                                type="number"
                                errors={this.getErrors('highValue')}
                                onChange={this.handleInputChanged}
                                onBlur={() => this.handleOnBlur('highValue')}
                                onFocus={() => this.handleOnFocus('highValue')}
                            />
                        </Form.Field>
                    </Form>
                </Modal.Content>

                <Modal.Actions>
                    <Button
                        positive
                        onClick={this.createThreshold}
                        disabled={!isModalFormDataValid}
                    >
                        {threshold ? 'Update' : 'Save'}
                    </Button>
                    <Button secondary onClick={() => uiActions.closeModal()}>
                        Cancel
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

SaveCreateThresholdModal.propTypes = {
    threshold: PropTypes.object,
    partyStateActions: PropTypes.object,
    uiActions: PropTypes.object,
    userGroup: PropTypes.object,
    party: PropTypes.object,
    constantStateActions: PropTypes.object,
    riskThresholds: PropTypes.array,
};

function mapStateToProps(state) {
    return {
        riskThresholds: state.constants.riskThresholds,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        uiActions: bindActionCreators(uiActions, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SaveCreateThresholdModal);
