import React, { useState, useEffect } from 'react';
import EditableHeader from '../common/EditableHeader';
import { Button, Dropdown, Form } from 'semantic-ui-react';
import * as storeTypes from '../../constants/storeTypes';
import { mapToOptions } from '../../utils/React';
import RichTextArea from '../common/fields/RichTextArea';
import { Workflow, User, Node } from '../../types';
import utilsApi from '../../api/utilsApi';

interface WorkflowDetailsFormProps {
    workflow: Workflow & { edited?: boolean };
    currentUser: User & { nodes: Node[] };
    onChange: (
        event: React.SyntheticEvent,
        data: { name: string; value: any },
    ) => void;
    onSave: () => void;
    saving?: boolean;
    workflowType?: string;
}

const WorkflowDetailsForm: React.FC<WorkflowDetailsFormProps> = ({
    workflow,
    onChange,
    onSave,
    saving = false,
    workflowType,
    currentUser,
}) => {
    const [nodes, setNodes] = useState<Node[]>([]);

    useEffect(() => {
        utilsApi.getModelList('StructuralNode').then((result) => {
            setNodes(result);
        });
    }, []);

    return (
        <Form>
            <EditableHeader
                placeholder="Add workflow name"
                value={workflow.label}
                name="label"
                onSubmit={onChange}
            />

            <Form.Field>
                <Dropdown
                    label="Structural Node"
                    fluid
                    selection
                    search
                    name="structuralNodeId"
                    onChange={onChange}
                    options={nodes.map((node) => ({
                        key: node.id,
                        value: node.id,
                        text: `${node.label || node.name} (Node ${node.id})`,
                    }))}
                    value={workflow.structuralNodeId}
                />
            </Form.Field>
            <Form.Field>
                <RichTextArea
                    label="Description"
                    name="description"
                    value={workflow.description}
                    onChange={onChange}
                />
            </Form.Field>

            <Form.Field>
                {workflowType === storeTypes.CURRENT ? (
                    <Button
                        type="submit"
                        onClick={onSave}
                        loading={saving}
                        disabled={saving || !workflow.edited}
                    >
                        Save Changes
                    </Button>
                ) : (
                    <Button
                        type="submit"
                        onClick={onSave}
                        loading={saving}
                        disabled={saving}
                    >
                        Submit
                    </Button>
                )}
            </Form.Field>
        </Form>
    );
};

export default WorkflowDetailsForm;
