import { useState } from 'react';
import _ from 'lodash';
import { getQueryStringValue, setQueryStringValue } from '../QueryString';

const useQueryString = (initialValue, shouldReturnArray = true) => {
    const queryStringValue = getQueryStringValue(undefined, shouldReturnArray);
    const [value, setValue] = useState(queryStringValue || initialValue);
    const [isInitial, setIsInitial] = useState(true);

    if (initialValue && _.isEmpty(queryStringValue) && isInitial) {
        setIsInitial(false);
        setQueryStringValue(initialValue);
    }

    const getFormattedValue = (data) => {
        const result = {};
        const keys = _.keys(data);
        _.map(keys, (key) => {
            const isNotEmpty = !_.isEmpty(data[key]);
            if (isNotEmpty) {
                result[key] = data[key];
            }
        });
        return result;
    };

    const onSetValue = (newValue) => {
        const formattedValue = getFormattedValue(newValue);
        setValue(formattedValue);
        setQueryStringValue(newValue);
        setIsInitial(false);
    };

    return [value, onSetValue];
};

export default useQueryString;
