import React from 'react';
import * as PropTypes from 'prop-types';

const DebugObject = (props) => {
    const { obj } = props;
    return (
        <div>
            <pre>{JSON.stringify(obj, null, 2)}</pre>
        </div>
    );
};

DebugObject.propTypes = {
    obj: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default DebugObject;
