import React from 'react';
import * as _ from 'lodash';
import ReactMarkdown from 'react-markdown';

import './ProControlObservation.scss';

const ProControlObservation = (props) => {
    return null;

    const structure = _.get(props, 'data.data.ticketSets');
    const data = _.get(props, 'data.data.data');

    if (!structure || !data) return null;

    structure.push({
        programmeActivitySetsLabel: 'Underwriting Testing',
        programmeActivitySetsSort: 45,
    });

    structure.push({
        programmeActivitySetsLabel: 'Claims Testing',
        programmeActivitySetsSort: 90,
    });

    structure.push({
        programmeActivitySetsLabel: 'Accounting Testing',
        programmeActivitySetsSort: 100,
    });

    const activitySets = _.chain(structure)
        .uniqBy('programmeActivitySetsLabel')
        .orderBy('programmeActivitySetsSort')
        .value();
    const keyedByActivitySet = _.chain(structure)
        .filter((a) => a.programmeActivitySetsActivitiesLinkSort)
        .groupBy('programmeActivitySetsLabel')
        .value();

    return (
        <div className="pro-report-control">
            {activitySets.map((as, index) => {
                const activities = _.orderBy(
                    keyedByActivitySet[as.programmeActivitySetsLabel],
                    'programmeActivitySetsActivitiesLinkActivityRef',
                );
                let activityCount = 0;

                // if (!activities || activities.length === 0) return null;

                //Not required.

                return (
                    <div
                        key={index}
                        className="pro-report-control-activity-set"
                    >
                        <h2>
                            {index + 1} {as.programmeActivitySetsLabel}
                        </h2>

                        {activities.map((a, index) => {
                            const entries = _.chain(data)
                                .filter('workflowEntriesValue')
                                .filter({
                                    activityPersistentId:
                                        a.programmeActivitySetsActivitiesLinkActivityPersistentId,
                                })
                                .value();

                            if (!entries || entries.length === 0) return null;

                            activityCount++;

                            const entriesByKey = _.groupBy(
                                entries,
                                'workflowEntriesFieldFieldKey',
                            );

                            const risk = entriesByKey['RISK'];
                            const control = entriesByKey['CONT'];
                            const observation = entriesByKey['OBVS'];

                            return (
                                <div
                                    key={index}
                                    className="pro-report-control-activity"
                                >
                                    <h3>
                                        {
                                            a.programmeActivitySetsActivitiesLinkActivityRef
                                        }{' '}
                                        {
                                            a.programmeActivitySetsActivitiesLinkActivityLabel
                                        }
                                    </h3>
                                    {risk && (
                                        <h4 style={{ fontStyle: 'italic' }}>
                                            Risk:{' '}
                                            {risk.map(
                                                (r) => r.workflowEntriesValue,
                                            )}
                                        </h4>
                                    )}
                                    {control && (
                                        <div>
                                            <h4 className="pro-blue">
                                                Control:
                                            </h4>
                                            <p>
                                                {control.map((r, index) => (
                                                    <ReactMarkdown
                                                        key={index}
                                                        source={
                                                            r.workflowEntriesValue
                                                        }
                                                    />
                                                ))}
                                            </p>
                                        </div>
                                    )}
                                    {observation && (
                                        <div>
                                            <h4 className="pro-blue">
                                                Observation:
                                            </h4>
                                            <p>
                                                {observation.map((r, index) => (
                                                    <ReactMarkdown
                                                        key={index}
                                                        source={
                                                            r.workflowEntriesValue
                                                        }
                                                    />
                                                ))}
                                            </p>
                                        </div>
                                    )}
                                </div>
                            );
                        })}

                        {activityCount === 0 && <p>Not required.</p>}
                    </div>
                );
            })}
        </div>
    );
};

export default ProControlObservation;

// {
//   "executionTicketListId": 1051,
//   "executionTicketListLabel": "Financial Crime",
//   "executionTicketListExecutionTicketSetLabel": "Compliance",
//   "executionTicketListExecutionId": 1002,
//   "executionTicketListExecutionTicketSetId": 1017,
//   "partyRelationshipExecutionTicketSetLabel": "Binder 1",
//   "partyRelationshipExecutionTicketSetId": 5,
//   "workflowEntriesValue": "Ab ut quis aut sit ratione vel rem tempora. Doloremque quos ut. Animi eligendi beatae aut consectetur ex rerum velit reprehenderit in. Culpa ut accusamus rem rerum animi ut molestiae molestiae.",
//   "workflowEntriesFieldFieldKey": null,
//   "workflowEntriesFieldLabel": "Lead Review Notes",
//   "activityRef": "CAP1-10.2",
//   "activityPersistentId": "8C5DFB00-4377-11EA-B50F-BBA93DE21148",
//   "count": 1
// }

// {
//   "executionId": 1002,
//   "programmeActivitySetsLabel": "Customer Outcomes",
//   "programmeActivitySetsSort": 181,
//   "programmeActivitySetsActivitiesLinkSort": 165,
//   "programmeActivitySetsActivitiesLinkActivityLabel": "Post Sale Barriers",
//   "programmeActivitySetsActivitiesLinkActivityPersistentId": "8DDB2520-4377-11EA-B50F-BBA93DE21148",
//   "programmeActivitySetsActivitiesLinkActivityRef": "CAP1-12.5",
//   "count": 1
// }
