import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { Button, Dropdown, Popup } from 'semantic-ui-react';
import * as _ from 'lodash';

import { TicketContextConsumer } from '../../context/TicketContext';
import usePrevious from '../../utils/hooks/usePrevious';
import toastr from 'toastr';

const TicketTransitionButtons = ({
    loading,
    onClick,
    dropdown = true,
    disabled,
    currentStatusNode,
    statusNodeColor,
    currentResolutionType,
    order,
    ticketId,
    showHiddenTransitions,
}) => {
    const [isPopupOpened, setIsPopupOpened] = useState(false);
    const [isInitial, setIsInitial] = useState(true);
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const prevTicketId = usePrevious(ticketId);

    useEffect(() => {
        setIsPopupVisible(false);
    }, [ticketId]);

    useEffect(() => {
        const isCurrentTicket = prevTicketId && prevTicketId === ticketId;
        if (
            !_.isEmpty(currentResolutionType) &&
            !isInitial &&
            !isPopupOpened &&
            isCurrentTicket
        ) {
            setIsPopupOpened(!isPopupOpened);
            setTimeout(() => setIsPopupOpened(isPopupOpened), 5000);
            setIsPopupVisible(true);
        }
        setIsInitial(false);
    }, [currentResolutionType]);

    useEffect(() => {
        if (!isPopupOpened) {
            setTimeout(() => {
                setIsPopupVisible(false);
            }, 500);
        }
    }, [isPopupOpened]);

    const transitions = showHiddenTransitions
        ? currentStatusNode.transitions
        : currentStatusNode.transitions.filter((t) => !t.isHidden);

    const transitionDoubleTop = transitions.length > 1;

    let onClickProps = {};

    if (disabled && !loading) {
        onClickProps = {
            onClick: () =>
                toastr.error(
                    'You do not have permission to perform this action',
                ),
        };
    }

    return dropdown ? (
        <Button.Group
            color={
                statusNodeColor
                    ? statusNodeColor
                    : currentStatusNode.status.statusType?.colourId
            }
            size="small"
            {...onClickProps}
        >
            <Dropdown
                loading={loading}
                text={currentStatusNode.label}
                floating
                labeled
                button
                className="ui right labeled icon button"
                disabled={disabled}
            >
                <Dropdown.Menu>
                    <Dropdown.Header content="Transition this ticket" />
                    {transitions.map((transition) => (
                        <Dropdown.Item
                            key={transition.id}
                            onClick={(event) => onClick(event, transition)}
                        >
                            {transition.label}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </Button.Group>
    ) : (
        <Button.Group className="transition-buttons" vertical {...onClickProps}>
            <Popup
                trigger={
                    <div>
                        {transitions.map((transition) => (
                            <Button
                                key={transition.id}
                                onClick={(event) => onClick(event, transition)}
                                loading={loading}
                                disabled={loading || disabled}
                                icon="right arrow"
                                labelPosition="right"
                                content={transition.label}
                            />
                        ))}
                    </div>
                }
                content="Are you ready to transition this ticket?"
                position="bottom right"
                open={isPopupVisible}
                className={`transition fade ${isPopupOpened ? 'in' : 'out'} ${
                    transitionDoubleTop ? 'doubledTop' : ''
                }`}
                style={{ marginTop: 0 }}
                pinned
                mountNode={
                    document.getElementsByClassName('transition-buttons')[order]
                }
            />
        </Button.Group>
    );
};

TicketTransitionButtons.propTypes = {
    currentTicket: PropTypes.object,
    loading: PropTypes.bool,
    dropdown: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    showHiddenTransitions: PropTypes.bool,
    currentStatusNode: PropTypes.object,
    statusNodeColor: PropTypes.string,
    currentResolutionType: PropTypes.string,
    order: PropTypes.number,
    ticketId: PropTypes.number,
};

export default TicketContextConsumer(TicketTransitionButtons);
