const fieldOptions = {
    dashboard: [
        {
            id: 'formats',
            required: false,
            label: 'Formats',
            activityFieldTypeId: 'CheckboxGroup',
            options: { values: ['print', 'docx', 'xlsx', 'csv', 'pdf'] },
            args: {
                outputArray: true,
            },
        },
        {
            id: 'hideHeaderForPrint',
            required: false,
            label: 'Hide header for print',
            activityFieldTypeId: 'YesNo',
            options: null,
        },
        {
            id: 'customFileName',
            required: false,
            label: 'Custom File Name',
            activityFieldTypeId: 'Text',
            options: null,
        },
        {
            id: 'dashboardProperties',
            required: false,
            label: 'Dashboard Properties',
            activityFieldTypeId: 'JSONBuilder',
            options: null,
            args: {
                allowModeChange: true,
            },
        },
        {
            id: 'metricReport',
            required: false,
            label: 'Metric Report',
            activityFieldTypeId: 'ModelSearchField',
            options: {
                modelName: 'StatusReport',
                valueProperty: 'id',
            },
        },
        {
            id: 'metricReportDimension',
            required: false,
            label: 'Metric Report Dimension',
            activityFieldTypeId: 'Select',
            options: {
                values: [
                    'all',
                    'applicableDate',
                    'assignedPartyId',
                    'assignedEntityId',
                ],
            },
            args: {
                outputArray: true,
            },
        },
    ],
    column: [
        {
            id: 'width',
            required: false,
            label: 'Width',
            activityFieldTypeId: 'Number',
            options: null,
        },
        {
            id: 'sort',
            required: false,
            label: 'Sort',
            activityFieldTypeId: 'Number',
            options: null,
        },
        {
            id: 'gridArea',
            required: false,
            label: 'Grid Area',
            activityFieldTypeId: 'Text',
            options: null,
        },
    ],
    row: [
        {
            id: 'sort',
            required: false,
            label: 'Sort',
            activityFieldTypeId: 'Number',
            options: null,
        },
        {
            id: 'height',
            required: false,
            label: 'Height',
            activityFieldTypeId: 'Number',
            options: null,
        },
        {
            id: 'showOnTab',
            required: false,
            label: 'Show on tab',
            activityFieldTypeId: 'Text',
            options: null,
        },
        {
            id: 'showIfPropertySet',
            required: false,
            label: 'Show if property set',
            activityFieldTypeId: 'Text',
            options: null,
        },
        {
            id: 'hideForPrint',
            required: false,
            label: 'Hide for print',
            activityFieldTypeId: 'YesNo',
            options: null,
        },
        {
            id: 'pageBreakBefore',
            required: false,
            label: 'Page break before row',
            activityFieldTypeId: 'YesNo',
            options: null,
        },
        {
            id: 'gridMode',
            required: false,
            label: 'Grid Mode',
            activityFieldTypeId: 'YesNo',
            options: null,
        },
        {
            id: 'gridModeColumns',
            required: false,
            label: 'Grid Mode Columns',
            activityFieldTypeId: 'Text',
            options: null,
        },
        {
            id: 'gridModeRows',
            required: false,
            label: 'Grid Mode Rows',
            activityFieldTypeId: 'Text',
            options: null,
        },
        {
            id: 'extraStyles',
            required: false,
            label: 'CSS',
            activityFieldTypeId: 'JSONBuilder',
            options: null,
            args: {
                allowModeChange: true,
            },
        },
    ],
    item: [
        {
            id: 'sort',
            required: false,
            label: 'Sort',
            activityFieldTypeId: 'Number',
            options: null,
        },
        {
            id: 'width',
            required: false,
            label: 'Width',
            activityFieldTypeId: 'Number',
            options: null,
        },
        {
            id: 'maxHeight',
            required: false,
            label: 'Max Height',
            activityFieldTypeId: 'Number',
            options: null,
        },
        {
            id: 'header',
            required: false,
            label: 'Header',
            activityFieldTypeId: 'Text',
            options: null,
        },
        {
            id: 'hideHeader',
            required: false,
            label: 'Hide Header',
            activityFieldTypeId: 'CheckBoxBool',
            options: null,
        },
        {
            id: 'extraPadding',
            required: false,
            label: 'Extra Padding',
            activityFieldTypeId: 'CheckBoxBool',
            options: null,
        },
        {
            id: 'plainStyle',
            required: false,
            label: 'Plain Style',
            activityFieldTypeId: 'CheckBoxBool',
            options: null,
        },
        {
            id: 'disableGrow',
            required: false,
            label: 'Disable Grow',
            activityFieldTypeId: 'YesNo',
            options: null,
        },
        {
            id: 'extraMapping',
            required: false,
            label: 'ExtraMapping',
            activityFieldTypeId: 'JSONBuilder',
            options: null,
            args: {
                allowModeChange: true,
            },
        },
        {
            id: 'extraStyles',
            required: false,
            label: 'CSS',
            activityFieldTypeId: 'JSONBuilder',
            options: null,
            args: {
                allowModeChange: true,
            },
        },
        {
            id: 'apiParams',
            required: false,
            label: 'Extra API Params',
            activityFieldTypeId: 'JSONBuilder',
            options: null,
            args: {
                allowModeChange: true,
            },
        },
    ],
};

export default fieldOptions;
