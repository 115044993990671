import { useAppSelector } from '../actions/store';
//custom hook for funcitonal components
export const useGetFeatures = () => {
    const featureFlags = useAppSelector(
        (state) => state.constants.options.featureFlags,
    );
    return {
        ...getFeatures(featureFlags),
    };
};
// function for class components
export const getFeatures = (featureFlags: { [key: string]: boolean }) => {
    return {
        FollowUp:
            checkFeatureFlag('ApplicationFollowUps', featureFlags) ?? true,
        UserPasswordSelfReset:
            checkFeatureFlag('UserPasswordSelfReset', featureFlags) ?? false,
        QueueStatusMenu:
            checkFeatureFlag('QueueStatusMenu', featureFlags) ?? false,
        VisRisk: checkFeatureFlag('VisRisk', featureFlags) ?? false,
        VisRiskFairValue:
            checkFeatureFlag('VisRiskFairValue', featureFlags) ?? false,
        VisRiskUsersMenu:
            checkFeatureFlag('VisRiskUsersMenu', featureFlags) ?? false,
        VisRiskEventsMenu:
            checkFeatureFlag('VisRiskEventsMenu', featureFlags) ?? false,
        VisRiskDelegates:
            checkFeatureFlag('VisRiskDelegates', featureFlags) ?? false,
        Vidende: checkFeatureFlag('Vidende', featureFlags) ?? false,
        VidendeUsersMenu:
            checkFeatureFlag('VidendeUsersMenu', featureFlags) ?? false,
        VidendeEventsMenu:
            checkFeatureFlag('VidendeEventsMenu', featureFlags) ?? false,
        UXFullScreenSingleQuestion:
            checkFeatureFlag('UXFullScreenSingleQuestion', featureFlags) ??
            false,
        VisRiskProductProgrammeAutoLaunch:
            checkFeatureFlag(
                'VisRiskProductProgrammeAutoLaunch',
                featureFlags,
            ) ?? false,
        AllowAssignmentFilterCurrentParty:
            checkFeatureFlag(
                'AllowAssignmentFilterCurrentParty',
                featureFlags,
            ) ?? false,
    };
};

const checkFeatureFlag = (
    featureFlagKey: string,
    featureFlags: { [key: string]: boolean },
) => {
    return (
        featureFlags &&
        featureFlagKey in featureFlags &&
        featureFlags[featureFlagKey]
    );
};
