import { createContext, useState } from 'react';
import { Grid, SemanticWIDTHS } from 'semantic-ui-react';
import { ModelExplorer } from './ModelExplorer';

type ModelExplorerGroupContextProps = {
    highlightModel: (
        modelName: string,
        modelValue: string,
        modelKey: string,
    ) => void;
    hightlightedModels: Record<string, Record<string, string>>;
};

// create ModelExplorerGroup context here
export const ModelExplorerGroupContext =
    createContext<ModelExplorerGroupContextProps>({
        highlightModel: () => {},
        hightlightedModels: {},
    });

type ExplorerConfig = {
    modelName: string;
    relationshipConfig: Record<string, string[]>;
    flattenBy?: Record<string, string[]>;
};

type ModelExplorerGroupProps = {
    explorers: ExplorerConfig[];
};

export const ModelExplorerGroup: React.FunctionComponent<
    ModelExplorerGroupProps
> = ({ explorers }) => {
    const [hightlightedModels, setHightlightedModels] = useState<
        Record<string, Record<string, string>>
    >({});

    const handleHighlightModel = (
        modelName: string,
        modelValue: string,
        modelKey: string,
    ) => {
        setHightlightedModels({
            //   ...hightlightedModels,
            [modelName]: {
                // ...hightlightedModels[modelName],
                [modelKey]: modelValue,
            },
        });
    };

    return (
        <ModelExplorerGroupContext.Provider
            value={{
                highlightModel: handleHighlightModel,
                hightlightedModels,
            }}
        >
            <Grid columns={explorers.length as SemanticWIDTHS}>
                <Grid.Row stretched>
                    {explorers.map((explorerConfig, i) => (
                        <Grid.Column key={i}>
                            <ModelExplorer
                                modelName={explorerConfig.modelName}
                                relationshipConfig={
                                    explorerConfig.relationshipConfig
                                }
                                flattenBy={explorerConfig.flattenBy}
                            />
                        </Grid.Column>
                    ))}
                </Grid.Row>
            </Grid>
        </ModelExplorerGroupContext.Provider>
    );
};

export default ModelExplorerGroup;
