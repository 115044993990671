import * as _ from 'lodash';
import * as permissionTypes from '../constants/permissionTypes';
import * as assessmentKeys from '../constants/assessmentKeys';
import * as transitionErrors from '../constants/transitionErrors';

export function applyResolutionLabelOverride(status, labelOverrides) {
    if (labelOverrides && labelOverrides[status.key]) {
        const result = status.resolutions
            .map((resolution) => {
                return {
                    ...resolution,
                    labelOverride:
                        _.get(
                            labelOverrides[status.key],
                            resolution.activityResolutionTypeId +
                                '.labelOverride',
                        ) || resolution.labelOverride,
                };
            })
            .filter((resolution) => {
                const available = _.get(
                    labelOverrides[status.key],
                    resolution.activityResolutionTypeId + '.available',
                );
                return _.isUndefined(available) ? true : available;
            });
        return result;
    }

    return status.resolutions;
}

export function mapPrincipal(principal) {
    if (!principal || !principal.id) return null;
    return {
        id: principal.id,
        userId: principal.user ? principal.user.id : null,
        firstName: principal.user ? principal.user.firstName : null,
        lastName: principal.user ? principal.user.lastName : null,
        userGroupId: principal.userGroup ? principal.userGroup.id : null,
        userGroupLabel: principal.userGroup ? principal.userGroup.label : null,
        label: principal.label ? principal.label : null,
        type: principal.type ? principal.type : null,
    };
}

function getCurrentResolution(ticket, currentStatusNode) {
    const { resolutions } = currentStatusNode.status;
    const currentResolution =
        resolutions.length > 0
            ? _.find(ticket.statusResolutions, {
                  statusId: resolutions[0].statusId,
              })
            : null;
    return currentResolution;
}

const validation = {
    TICKET_ASSIGNED: (ticket) => {
        return !!ticket.assignedPrincipalId;
    },
    RESOLUTION_CHOSEN: (ticket, currentStatusNode) => {
        const currentResolution = getCurrentResolution(
            ticket,
            currentStatusNode,
        );
        return !!currentResolution;
    },
    FORM_COMPLETE: (ticket) => {
        if (ticket.edited || !ticket.activity) return false;
        const fields = ticket.activity.fields.filter((field) => {
            const entry = _.find(ticket.entries, { activityFieldId: field.id });
            if (!entry || entry.value == '') return true;
        });
        return fields.length === 0;
    },
    RESOLUTION_CHOSEN_POSITIVE: (ticket, currentStatusNode) => {
        const currentResolution = getCurrentResolution(
            ticket,
            currentStatusNode,
        );
        return (
            currentResolution &&
            _.get(currentResolution, 'resolution.resolutionType.id') ===
                'POSITIVE'
        );
    },
    RESOLUTION_CHOSEN_NEGATIVE: (ticket, currentStatusNode) => {
        const currentResolution = getCurrentResolution(
            ticket,
            currentStatusNode,
        );
        return (
            currentResolution &&
            _.get(currentResolution, 'resolution.resolutionType.id') ===
                'NEGATIVE'
        );
    },
    RESOLUTION_CHOSEN_NEUTRAL: (ticket, currentStatusNode) => {
        const currentResolution = getCurrentResolution(
            ticket,
            currentStatusNode,
        );
        return (
            currentResolution &&
            currentResolution.resolution.resolutionType.label === 'NEUTRAL'
        );
    },
    RESOLUTION_CHOSEN_NOT_APPLICABLE: (ticket, currentStatusNode) => {
        const currentResolution = getCurrentResolution(
            ticket,
            currentStatusNode,
        );
        return (
            currentResolution &&
            currentResolution.resolution.resolutionType.label ===
                'NOT_APPLICABLE'
        );
    },
    WORKFLOW_FORM_COMPLETE: (ticket) => {
        if (ticket.edited) return false;
        const fields = ticket.currentStatusNode.status.fields.filter(
            (field) => {
                const entry = _.find(ticket.workflowEntries, {
                    workFlowStatusFieldId: field.id,
                });
                if (!entry || entry.value == '') return true;
            },
        );
        return fields.length === 0;
    },
    RESOLUTION_CHOSEN_POSITIVE_OR_NOT_APPLICABLE: (
        ticket,
        currentStatusNode,
    ) => {
        const currentResolution = getCurrentResolution(
            ticket,
            currentStatusNode,
        );
        return (
            currentResolution &&
            (currentResolution.resolution.resolutionType.label === 'POSITIVE' ||
                currentResolution.resolution.resolutionType.label ===
                    'NOT_APPLICABLE')
        );
    },
    RESOLUTION_CHOSEN_NEGATIVE_OR_NEUTRAL: (ticket, currentStatusNode) => {
        const currentResolution = getCurrentResolution(
            ticket,
            currentStatusNode,
        );
        return (
            currentResolution &&
            (currentResolution.resolution.resolutionType.label === 'NEGATIVE' ||
                currentResolution.resolution.resolutionType.label === 'NEUTRAL')
        );
    },
};

export function validateTransition(ticket, transition, currentStatusNode) {
    const { activity } = ticket;
    const statusKeyIsAssessment = currentStatusNode.status.key === 'ASSESSMENT';
    const currentStatus = currentStatusNode.status;
    const requiredWorkflowFields = _.filter(currentStatus.fields, {
        required: true,
    });
    const requiredEntryFileds = activity
        ? _.filter(activity.fields, { required: true })
        : [];
    if (
        (!transition.conditions || transition.conditions.length === 0) &&
        !requiredWorkflowFields &&
        !(statusKeyIsAssessment && requiredEntryFileds.length)
    )
        return true;
    let result = transition.conditions
        ? transition.conditions.filter((condition) => {
              const conditionKey = condition.id;
              const checkCondition = validation[conditionKey](
                  ticket,
                  currentStatusNode,
              );
              return checkCondition ? false : condition;
          })
        : [];
    if (requiredWorkflowFields.length) {
        const isEmptyFields = _.map(requiredWorkflowFields, (workFlowField) => {
            return _.find(
                ticket.workflowEntries,
                (entry) => entry.field.id === workFlowField.id,
            );
        });
        if (!isEmptyFields.length || _.some(isEmptyFields, _.isEmpty)) {
            result.push(transitionErrors.WORKFLOW_FIELD_SHOULD_BE_NOT_EMPTY);
        }
    }
    if (statusKeyIsAssessment && requiredEntryFileds.length) {
        const isEmptyFields = _.map(requiredEntryFileds, (entryField) => {
            return _.find(
                ticket.entries,
                (entry) => entry.field.id === entryField.id,
            );
        });
        if (!isEmptyFields.length || _.some(isEmptyFields, _.isEmpty)) {
            result.push(transitionErrors.FIELD_SHOULD_BE_NOT_EMPTY);
        }
    }
    return result.length > 0 ? { errors: result } : { errors: false };
}

export function getDefaultValues(ticket, currentStatusNode) {
    if (!ticket || !currentStatusNode) return {};
    const currentStatus = currentStatusNode.status;
    const statusResolutions = ticket.statusResolutions;
    const notes = _.get(ticket, 'activity.meta.notes');
    if (!notes) return {};
    const currentResolution = _.find(statusResolutions, {
        statusId: currentStatus.id,
    });
    if (!currentResolution) return {};
    const resolutionType =
        currentResolution.resolution.activityResolutionTypeId;
    const statusKey = currentStatus.key;
    const values = _.get(notes, `${statusKey}.${resolutionType}`);
    return values;
}

export function checkUserInputPermission(currentStatusNode, permissions) {
    if (!currentStatusNode || !permissions) return false;
    let requiredPermission = permissionTypes.CREATE;

    switch (currentStatusNode.status.key) {
        case assessmentKeys.ASSESSMENT: {
            requiredPermission = permissionTypes.ASSESS;
            break;
        }
        case assessmentKeys.REVIEW: {
            requiredPermission = permissionTypes.REVIEW;
            break;
        }
    }

    if (permissions.includes(requiredPermission)) {
        return true;
    } else {
        return false;
    }
}

// function isUserAssigned(user, ticket) {
//   const { userGroups } = user;
//   const { principalId } = user.details;
//   const { assignedPrincipalId } = ticket;
//   return (
//     principalId === assignedPrincipalId ||
//     !!_.find(userGroups, principalId === assignedPrincipalId)
//   );
// }

export function getAnalyzeAndImplement(nextPropsEntries) {
    return _.some(nextPropsEntries, (nextPropsEntry) =>
        _.get(nextPropsEntry, 'value.analysisCompletionDate'),
    );
}

export function getTicketLabel(label, showTicketSet, executionTicketSetLabel) {
    const needToShowTicketSet = showTicketSet && executionTicketSetLabel;
    const currentTicketLabel = `${label} ${
        needToShowTicketSet ? `(${executionTicketSetLabel})` : ''
    }`;
    return currentTicketLabel;
}
