import apiTools from './apiTools';

class WorkflowApi {
    static getWorkflows() {
        // const storeState = storeProvider.getStore().store.getState();
        return apiTools
            .get('Workflows', {
                filter: {
                    order: 'createdAt DESC',
                    where: {
                        // programStatusId: {
                        //   neq: _.find(storeState.workflowStatuses, {
                        //     label: 'DEACTIVATED'
                        //   }).id
                        // }
                    },
                },
            })
            .then((response) => {
                return response.data;
            });
    }

    static getWorkflow(id, ticketId) {
        const url = ticketId
            ? `ExecutionTicketListView/${ticketId}`
            : `Workflows/${id}`;
        const filter = ticketId
            ? {
                  include: [
                      {
                          relation: 'workflow',
                          scope: {
                              include: [
                                  {
                                      relation: 'statusNodes',
                                      scope: {
                                          include: [
                                              {
                                                  relation: 'transitions',
                                                  scope: {
                                                      include: [
                                                          'conditions',
                                                          'postFunctionTypes',
                                                      ],
                                                  },
                                              },
                                          ],
                                      },
                                  },
                                  {
                                      relation: 'statuses',
                                      scope: {
                                          include: [
                                              {
                                                  relation: 'fields',
                                                  scope: {
                                                      include: ['fieldType'],
                                                  },
                                              },
                                              {
                                                  relation: 'resolutions',
                                                  scope: {
                                                      include: [
                                                          'resolutionType',
                                                      ],
                                                  },
                                              },
                                          ],
                                      },
                                  },
                              ],
                          },
                      },
                      {
                          relation: 'currentStatusNode',
                          scope: {
                              include: [
                                  {
                                      relation: 'status',
                                      scope: {
                                          include: ['fields'],
                                      },
                                  },
                              ],
                          },
                      },
                      'statusResolutions',
                  ],
              }
            : {
                  include: [
                      {
                          relation: 'statuses',
                          scope: {
                              include: [
                                  {
                                      relation: 'fields',
                                      scope: { include: ['fieldType'] },
                                  },
                                  {
                                      relation: 'resolutions',
                                      scope: {
                                          include: ['resolutionType'],
                                      },
                                  },
                                  'statusType',
                              ],
                          },
                      },
                      {
                          relation: 'statusNodes',
                          scope: {
                              include: [
                                  {
                                      transitions: [
                                          'conditions',
                                          'postFunctionTypes',
                                      ],
                                  },
                                  'status',
                              ],
                          },
                      },
                  ],
              };

        return apiTools
            .get(url, {
                filter,
            })
            .then((response) => {
                return ticketId ? response.data.workflow : response.data;
            });
    }

    static saveWorkflow(workflow) {
        const apiMethod = workflow.id ? apiTools.patch : apiTools.post;
        return apiMethod(`Workflows`, workflow).then((response) => {
            return response.data;
        });
    }

    static getWorkflowFields(filter = {}) {
        return apiTools
            .get('WorkFlowStatusFields', {
                filter,
            })
            .then((response) => {
                return response.data;
            });
    }
}

export default WorkflowApi;
