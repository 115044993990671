/* eslint-disable react/no-did-mount-set-state */
import React, { Component } from 'react';
import * as PropTypes from 'prop-types';

import {
    Segment,
    Header,
    Checkbox,
    Button,
    Divider,
    Form,
} from 'semantic-ui-react';

import './MoreInfoSidebar.scss';
import Viewer from '../common/fields/TUIWrapperViewer';

class TicketListSidebar extends Component {
    state = { isReadAllCheckbox: false };
    componentDidMount() {
        const { isReadAll } = this.props;
        this.setState({ isReadAllCheckbox: isReadAll });
    }
    handleConfirmButtonClick = () => {
        const { handleReadAll, closeSidebar } = this.props;
        const { isReadAllCheckbox } = this.state;
        handleReadAll(null, isReadAllCheckbox);
        closeSidebar();
    };
    handleCheckboxClick = (e, { name, checked }) => {
        this.setState({ [name]: checked });
    };
    render() {
        const { ticket, handleReadAll } = this.props;
        const { moreInfo, label } = ticket.activity;
        const { isReadAllCheckbox } = this.state;
        return (
            <Segment textAlign="left" basic padded="very">
                {label && <Header size="small">{label}</Header>}
                <Viewer initialValue={moreInfo} />
                {handleReadAll && (
                    <React.Fragment>
                        <Divider />
                        <Form>
                            <Form.Field>
                                <Checkbox
                                    checked={isReadAllCheckbox === true}
                                    onClick={this.handleCheckboxClick}
                                    label="I confirm I have read the above"
                                    name="isReadAllCheckbox"
                                    className="checkbox-more-info-sidebar"
                                />
                            </Form.Field>
                            <Form.Field>
                                <Button
                                    onClick={this.handleConfirmButtonClick}
                                    primary
                                    className="button-more-info-sidebar"
                                >
                                    Confirm
                                </Button>
                            </Form.Field>
                        </Form>
                    </React.Fragment>
                )}
            </Segment>
        );
    }
}

TicketListSidebar.propTypes = {
    ticket: PropTypes.object,
    handleReadAll: PropTypes.func,
    isReadAll: PropTypes.bool,
    closeSidebar: PropTypes.func,
};

export default TicketListSidebar;
