import React from 'react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import EditableHeader from '../common/EditableHeader';
import { Button, Select, Form } from 'semantic-ui-react';
import { mapToOptions } from '../../utils/React';
import * as storeTypes from '../../constants/storeTypes';
import StatusDropdown from '../common/StatusDropdown';
import RichTextArea from '../common/fields/RichTextArea';
import ProgrammeReports from './ProgrammeReports';

const ProgrammeDetailsForm = ({
    programme,
    onChange,
    onSave,
    parties,
    programmeStatuses,
    saving,
    programmeType,
}) => {
    const partyChosen = (event, { name, value }) => {
        const chosenParty = _.find(parties, { id: value });
        onChange(event, { name, value });
        onChange(event, {
            name: 'structuralNodeId',
            value: chosenParty.structuralNodeId,
        });
    };

    return (
        <Form>
            <EditableHeader
                placeholder="Add programme name"
                value={programme.label}
                name="label"
                onSubmit={onChange}
            />

            <Form.Group widths="equal">
                <Form.Field width={6}>
                    <label>Reference</label>
                    <Form.Input
                        value={programme.ref || ''}
                        onChange={onChange}
                        placeholder="Programme Reference"
                        name="ref"
                    />
                </Form.Field>
                <Form.Field width={6}>
                    <label>Owner Party</label>
                    <Form.Field
                        control={Select}
                        options={mapToOptions(parties)}
                        placeholder="Select Status"
                        onChange={partyChosen}
                        value={programme.ownerPartyId}
                        name="ownerPartyId"
                    />
                </Form.Field>
                <Form.Field width={6}>
                    <label>Status</label>
                    <StatusDropdown
                        statuses={programmeStatuses}
                        onChange={onChange}
                        value={programme.programStatusId}
                        name="programStatusId"
                    />
                </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
                <Form.Field>
                    <RichTextArea
                        value={programme.description}
                        name="description"
                        onChange={onChange}
                    />
                </Form.Field>
                <Form.Field>
                    <ProgrammeReports
                        reports={
                            programme.options ? programme.options.reports : []
                        }
                        programmeType={programmeType}
                    />
                </Form.Field>
            </Form.Group>
        </Form>
    );
};

ProgrammeDetailsForm.propTypes = {
    programme: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    parties: PropTypes.array,
    programmeStatuses: PropTypes.array,
    saving: PropTypes.bool,
    programmeType: PropTypes.string,
};

export default ProgrammeDetailsForm;
