import React from 'react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import Fields from '../fields/Fields';

const ExecutionTicketEntry = (props) => {
    const { data } = props;

    const fieldType = data.activityField.activityFieldTypeId;
    const options = _.get(data, 'activityField.options.values');
    const FieldComponent = Fields[fieldType];

    return (
        <div>
            <FieldComponent
                value={data.value}
                editable={false}
                disabled={true}
                options={options}
            />
        </div>
    );
};

ExecutionTicketEntry.propTypes = {
    data: PropTypes.object,
};

export default ExecutionTicketEntry;
