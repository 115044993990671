import React, { useContext } from 'react';
import * as PropTypes from 'prop-types';
import Moment from 'react-moment';
import { List, Tab, Table } from 'semantic-ui-react';
import toastr from 'toastr';
import { Link } from 'react-router-dom';

import ActionButtons from '../common/ActionButtons';
import ConfirmationModalContext from '../../context/ConfirmationModalContext';
import { Entity } from '../../types';
import * as uiActions from '../../actions/uiActions';
import * as types from '../../constants/actionTypes';
import { MODAL_COMPONENTS } from '../common/ModalRoot';
import { useDispatch } from 'react-redux';
import { deleteEntity } from '../../actions/entityActions';
import { orderBy } from 'lodash';
import { useAppSelector } from '../../actions/store';
import { ProductListMode } from './ProductList';
import UserWrapper from '../user/User';
import { CurrentUser } from '../common/CheckPermission';
import * as permissionTypes from '../../constants/permissionTypes';
import EntityApi from '../../api/entityApi';
import convertStringToBoolean from '../../utils/convertStringToBoolean';

interface Props {
    product: Entity;
    mode?: ProductListMode;
    selected?: boolean;
    currentUser: CurrentUser;
    fetchProducts?: () => void;
    displayedColumns?: string[];
    hideActionButtons?: boolean;
}

const ProductListItem = ({
    product,
    mode = 'LIST',
    selected,
    currentUser,
    fetchProducts,
    displayedColumns,
    hideActionButtons,
}: Props) => {
    const modalContext: any = useContext(ConfirmationModalContext);
    const entityType = useAppSelector(
        (state) => state.entity.entityTypes.byId['PRODUCT'],
    );
    const dispatch = useDispatch();

    const handleDeleteProduct = () => {
        modalContext.confirmPopupActions.showConfirmDialog(
            `Are you sure you want to delete ${product.label} product?`,
            deleteProductOnConfirm,
        );
    };
    const deleteProductOnConfirm = async () => {
        try {
            await dispatch(deleteEntity(product));
        } catch (error) {
            toastr.error(error);
        }
    };
    const deleteProductOnConfirmCallback = () => {
        toastr.success(`Product ${product.label} is removed`);
    };
    const editProduct = () => {
        dispatch(
            uiActions.showModal({
                modalType: MODAL_COMPONENTS.SAVE_ENTITY_MODAL,
                modalProps: {
                    entityId: product.id,
                },
            }),
        );
    };
    const launchGovernanceProgramme = () => {
        dispatch(
            uiActions.showModal({
                modalType: MODAL_COMPONENTS.LAUNCH_GOVERNANCE_PROGRAMME_MODAL,
                modalProps: {
                    mode: 'individual',
                    product,
                },
            }),
        );
    };

    const renderValue = (value: string, isList: boolean) => {
        if (!isList) return value;

        const sortedList = orderBy(value?.split(','));
        return (
            <List>{sortedList?.map((v) => <List.Item>{v}</List.Item>)}</List>
        );
    };

    const handleSelectEntity = () => {
        dispatch({
            type: types.SELECT_ENTITY,
            entity: product,
        });
    };

    const handleDeselectEntity = () => {
        dispatch({
            type: types.DESELECT_ENTITY,
            entity: product,
        });
    };

    const orderedFields = orderBy(
        entityType?.fields?.filter(
            (field) => field.activityFieldType !== 'Hidden',
        ),
        ['sort'],
    );
    const execution = product.executions?.[0];

    const structuralNodeId = 2;
    const userHasPermissionToDeleteProduct = currentUser.checkPermissionForNode(
        structuralNodeId,
        permissionTypes.ADMIN,
    );

    const handleNotifyFairValueComplete = () => {
        modalContext.confirmPopupActions.showConfirmDialog(
            `This will email all distributors that have submitted a PVIE for this product so that they can log in an see the fair value assessment. Do you want to continue?`,
            notifyFairValueCompleteOnConfirm,
        );
    };
    const notifyFairValueCompleteOnConfirm = async () => {
        try {
            await EntityApi.notifyFairValueComplete(product.id);
            if (fetchProducts) fetchProducts();
        } catch (error) {}
    };

    const handleNotifyNewProductAdded = () => {
        modalContext.confirmPopupActions.showConfirmDialog(
            `This will notify all distributors that have logged into the system that a new product has been added. Do you want to continue?`,
            notifyNewProductAddedOnConfirm,
        );
    };
    const notifyNewProductAddedOnConfirm = async () => {
        try {
            await EntityApi.notifyNewProductAdded(product.id);
            if (fetchProducts) fetchProducts();
        } catch (error) {}
    };

    const newProductNotification = convertStringToBoolean(
        product.entries.find((x) => x.entityTypeFieldId === 'PRODUCT_NEW_EMAIL')
            ?.value,
    );

    return (
        <Table.Row>
            <Table.Cell>
                {product?.id ? (
                    <Link to={`/product/${product?.id}`}>{product.label}</Link>
                ) : (
                    product.label
                )}
            </Table.Cell>
            {orderedFields?.map((field) => {
                if (displayedColumns) {
                    return displayedColumns.includes(field.id) ? (
                        <Table.Cell>
                            {renderValue(
                                product[field.id],
                                field.activityFieldType === 'CheckboxGroup',
                            )}
                        </Table.Cell>
                    ) : null;
                }
                return (
                    <Table.Cell>
                        {renderValue(
                            product[field.id],
                            field.activityFieldType === 'CheckboxGroup',
                        )}
                    </Table.Cell>
                );
            })}
            {!hideActionButtons && (
                <Table.Cell textAlign="center">
                    {mode === 'LIST' && (
                        <ActionButtons
                            className="u-show-on-hover__target"
                            deleteClicked={
                                userHasPermissionToDeleteProduct
                                    ? handleDeleteProduct
                                    : null
                            }
                            launchGovernanceProgrammeClicked={
                                launchGovernanceProgramme
                            }
                            notifyFairValueComplete={
                                handleNotifyFairValueComplete
                            }
                            notifyNewProductAdded={
                                newProductNotification ||
                                newProductNotification === null
                                    ? handleNotifyNewProductAdded
                                    : null
                            }
                            editClicked={editProduct}
                        />
                    )}
                    {mode === 'SELECTION' && (
                        <ActionButtons
                            className="u-show-on-hover__target"
                            addClicked={
                                selected === false ? handleSelectEntity : null
                            }
                            minusClicked={
                                selected === true ? handleDeselectEntity : null
                            }
                            color={selected ? 'green' : 'grey'}
                        />
                    )}
                </Table.Cell>
            )}
        </Table.Row>
    );
};

ProductListItem.propTypes = {
    product: PropTypes.object.isRequired,
    deletePartyProduct: PropTypes.func.isRequired,
    editPartyProduct: PropTypes.func.isRequired,
    showConfirmDialog: PropTypes.func.isRequired,
    fetchProducts: PropTypes.func,
    hideActionButtons: PropTypes.bool,
};

export default UserWrapper(ProductListItem);
