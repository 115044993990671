import { Activity, ActivitySet, Programme } from '../../../types';
import { ProgrammeField } from './ProgrammeFieldManager';

export interface MoveFieldAction {
    type: 'MOVE_FIELD';
    payload: {
        fromActivityId: string;
        toActivityId: string;
        fromIndex: number;
        toIndex: number;
    };
}

export interface MoveActivityAction {
    type: 'MOVE_ACTIVITY';
    payload: {
        fromIndex: number;
        toIndex: number;
    };
}

export interface SaveActivityCompleteAction {
    type: 'ACTIVITY_SAVE_SUCCESS';
    payload: {
        activity: Activity;
    };
}

export interface SelectActivityAction {
    type: 'SELECT_ACTIVITY';
    payload: {
        activityIndex: number;
    };
}

export interface DeleteFieldAction {
    type: 'DELETE_FIELD';
    payload: {
        activityId: string;
        fieldIndex: number;
    };
}

interface ProgrammeFieldManagerState {
    programme: Programme;
    activeAccordions: string[];
    activitySaveQueue: Activity[];
    selectedActivityIndex: number;
}

type ActivityAction =
    | MoveFieldAction
    | DeleteFieldAction
    | MoveActivityAction
    | SaveActivityCompleteAction
    | SelectActivityAction;

export const activityReducer = (
    state: ProgrammeFieldManagerState,
    action: ActivityAction,
) => {
    switch (action.type) {
        case 'SELECT_ACTIVITY': {
            const { activityIndex } = action.payload;
            return {
                ...state,
                selectedActivityIndex: activityIndex,
            };
        }

        case 'ACTIVITY_SAVE_SUCCESS': {
            const { activity } = action.payload;
            const newState = {
                ...state,
            };

            let activitySetIndex: number;
            let activityIndex: number;
            for (const [
                index,
                activitySet,
            ] of state.programme.activitySets.entries()) {
                activityIndex = activitySet.activities.findIndex(
                    (_activity) => _activity.id === activity.id,
                );
                if (activitySet.activities[activityIndex]) {
                    activitySetIndex = index;
                    break;
                }
            }
            newState.programme.activitySets[activitySetIndex].activities[
                activityIndex
            ] = activity;
            newState.activitySaveQueue = newState.activitySaveQueue.filter(
                (a) => a.id !== activity.id,
            );
            return newState;
        }

        case 'MOVE_FIELD': {
            const { fromActivityId, toActivityId, fromIndex, toIndex } =
                action.payload;
            let fromActivity: Activity;
            let activitySetIndex: number;
            let activityIndex: number;

            const newState = {
                ...state,
            };

            for (const [
                index,
                activitySet,
            ] of state.programme.activitySets.entries()) {
                activityIndex = activitySet.activities.findIndex(
                    (activity) => activity.id === fromActivityId,
                );
                if (activitySet.activities[activityIndex]) {
                    fromActivity = activitySet.activities[activityIndex];
                    activitySetIndex = index;
                    break;
                }
            }
            if (fromActivityId === toActivityId) {
                const [field] = fromActivity.fields.splice(fromIndex, 1);
                const newActivity = {
                    ...fromActivity,
                    //   fields: fromActivity.fields.splice(toIndex, 0, field),
                };
                newActivity.fields.splice(toIndex, 0, field);

                newActivity.fields = newActivity.fields.map((field, index) => ({
                    ...field,
                    sort: index + 1,
                }));

                newState.programme.activitySets[activitySetIndex].activities[
                    activityIndex
                ] = newActivity;

                newState.activitySaveQueue = [
                    ...newState.activitySaveQueue,
                    newActivity,
                ];

                // return newState;
            } else {
                let toActivity;
                let toActivitySetIndex;
                let toActivityIndex;
                for (const [
                    index,
                    activitySet,
                ] of state.programme.activitySets.entries()) {
                    toActivityIndex = activitySet.activities.findIndex(
                        (activity) => activity.id === toActivityId,
                    );
                    if (activitySet.activities[toActivityIndex]) {
                        toActivity = activitySet.activities[toActivityIndex];
                        toActivitySetIndex = index;
                        break;
                    }
                }

                const field = fromActivity.fields[fromIndex];
                field.activityId = toActivityId;
                const newToActivityFields = [...toActivity.fields];
                newToActivityFields.splice(toIndex, 0, field);
                const newFromActivityFields = [...fromActivity.fields];
                newFromActivityFields.splice(fromIndex, 1);

                const newToActivity = {
                    ...toActivity,
                    fields: newToActivityFields,
                };
                const newFromActivity = {
                    ...fromActivity,
                    fields: newFromActivityFields,
                };

                newToActivity.fields = newToActivity.fields.map(
                    (field, index) => ({
                        ...field,
                        sort: index + 1,
                    }),
                );

                newFromActivity.fields = newFromActivity.fields.map(
                    (field, index) => ({
                        ...field,
                        sort: index + 1,
                    }),
                );

                newState.activitySaveQueue = [
                    ...newState.activitySaveQueue,
                    newToActivity,
                    newFromActivity,
                ];

                newState.programme.activitySets[activitySetIndex].activities[
                    activityIndex
                ] = newFromActivity;
                newState.programme.activitySets[toActivitySetIndex].activities[
                    toActivityIndex
                ] = newToActivity;
            }

            console.log(newState);
            return newState;
        }
        case 'MOVE_ACTIVITY': {
            const { fromIndex, toIndex } = action.payload;
            const newState = {
                ...state,
            };
            const [activity] = newState.programme.activitySets.splice(
                fromIndex,
                1,
            );
            newState.programme.activitySets.splice(toIndex, 0, activity);
            return newState;
        }

        case 'DELETE_FIELD': {
            const { activityId, fieldIndex } = action.payload;
            const newState = { ...state };

            let activitySetIndex: number;
            let activityIndex: number;
            for (const [
                index,
                activitySet,
            ] of state.programme.activitySets.entries()) {
                activityIndex = activitySet.activities.findIndex(
                    (_activity) => _activity.id === activityId,
                );
                if (activitySet.activities[activityIndex]) {
                    activitySetIndex = index;
                    break;
                }
            }

            newState.programme.activitySets[activitySetIndex].activities[
                activityIndex
            ].fields.splice(fieldIndex, 1);

            return newState;
        }

        default:
            return state;
    }
};
