import { LogicResultUnion, Result, ResultValue } from '../../api/statusApi';

export type StatusFlags = {
    hasException?: boolean;
    hasCaution?: boolean;
    hasUnfinished?: boolean;
    hasError?: boolean | string;
    hasNoResults?: boolean;
    hasPositive?: boolean;
};

const validRAGValues = ['EXCEPTION', 'POSITIVE', 'NONE', 'ERROR', 'CAUTION'];

export const getStatusFlags = (status: Result): StatusFlags => {
    let results: ResultValue[] = [];

    // take the last result
    const logicResult =
        status.statusResults[status.statusResults.length - 1]?.logicResult;

    if (logicResult && !Array.isArray(logicResult) && logicResult.error) {
        return {
            hasException: false,
            hasUnfinished: false,
            hasError: logicResult.error,
            hasNoResults: false,
            hasPositive: false,
            hasCaution: false,
        };
    }
    if (logicResult && !Array.isArray(logicResult)) {
        results = [logicResult.result];
    } else if (logicResult && Array.isArray(logicResult)) {
        results = logicResult.map((result) => result.result);
    }

    // TODO: cope with multiple results

    // // const results = status.statusResults.map((result) => result.logicResult);

    // if (status.statusResults) {

    const hasException = results.some((status) => status === 'EXCEPTION');
    const hasCaution =
        !hasException && results.some((status) => status === 'CAUTION');
    const hasUnfinished = results.some((status) => status === 'ERROR');
    const hasError = results.some(
        (status) =>
            status &&
            typeof status === 'object' &&
            'error' in status &&
            status?.error,
    );
    const hasNoResults =
        results.length === 0 || results.every((status) => status === 'NONE');

    const hasPositive =
        !hasException && !hasUnfinished && !hasError && !hasNoResults;

    return {
        hasException,
        hasUnfinished,
        hasError,
        hasNoResults,
        hasPositive,
        hasCaution,
    };
};

export const logicResultToStatus = (
    logicResult: LogicResultUnion,
): ResultValue => {
    let result: ResultValue = null;

    if (Array.isArray(logicResult)) {
        let results = logicResult.map(
            (logicResultItem) => logicResultItem.result,
        );
        results = [...new Set(results)];
        if (results.length === 1) result = results[0];
        result = 'POSITIVE';

        if (results.some((result) => result === 'CAUTION')) result = 'CAUTION';
        if (results.some((result) => result === 'ERROR')) result = 'ERROR';
        if (results.some((result) => result === 'EXCEPTION'))
            result = 'EXCEPTION';
    } else {
        result = logicResult.result;
    }

    return result;
};

export const checkRAGValue = (logicResult: LogicResultUnion): boolean => {
    if (Array.isArray(logicResult)) {
        return logicResult.every((result) => {
            if (typeof result.result === 'string') {
                return validRAGValues.includes(result.result);
            }
            return false;
        });
    }

    if (typeof logicResult.result === 'string') {
        return validRAGValues.includes(logicResult.result);
    }

    // if (typeof value !== 'string') return false;
    // if (validRAGValues.includes(value)) return true;
    return false;
};
