import { Header, List } from 'semantic-ui-react';
import { useAppSelector } from '../../../actions/store';

type ModelInstanceInfoProps = {
    modelObject: any;
    modelName: string;
};

export const ModelInstanceInfo = ({
    modelObject,
    modelName,
}: ModelInstanceInfoProps) => {
    const modelStructure = useAppSelector(
        (state) => state.constants.modelStructure,
    );
    const modelProperties = modelStructure[modelName]?.properties || {};

    return (
        <div
            style={{
                maxWidth: '400px',
                overflow: 'hidden',
            }}
        >
            <Header as="h4">{modelName}</Header>
            <List>
                {Object.keys(modelProperties).map((propertyName) => {
                    const propertyValue = modelObject[propertyName];
                    const isObjectOrArray =
                        typeof propertyValue === 'object' &&
                        propertyValue !== null;

                    if (!propertyValue) return null;

                    return (
                        <List.Item key={propertyName}>
                            <List.Content>
                                <List.Header>{propertyName}</List.Header>
                                <List.Description>
                                    {isObjectOrArray ? (
                                        <pre>
                                            {JSON.stringify(
                                                propertyValue,
                                                null,
                                                2,
                                            )}
                                        </pre>
                                    ) : (
                                        propertyValue
                                    )}
                                </List.Description>
                            </List.Content>
                        </List.Item>
                    );
                })}
            </List>
        </div>
    );
};
