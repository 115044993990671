/* eslint-disable react/no-danger */
/* eslint-disable react/no-did-mount-set-state */
/* eslint-disable react/no-did-update-set-state */
import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import toastr from 'toastr';
import { Placeholder } from 'semantic-ui-react';

import {
    validateTransition,
    checkUserInputPermission,
} from '../../../utils/TicketUtils';
import TicketWrapper from '../Ticket';
import TicketRowCompact from '../TicketRowCompact';
import TicketRowFull from '../TicketRowFull';
import WorkflowWrapper from '../../workflow/Workflow';
import UserWrapper from '../../user/User';
import './TicketRow.scss';
import * as types from '../../../constants/actionTypes';
import { WrapFollowUpContext } from '../../../context/FollowUpContext';

class TicketRow extends Component {
    state = {
        transitioning: false,
        resolving: false,
        recommendationOverride: '',
    };

    componentDidMount() {
        const { ticketActions } = this.props;
        const { id } = this.props.item;

        ticketActions.setTicket({ id, autoLoad: false });
    }

    componentDidUpdate(prevProps) {
        const { ticket } = this.props;
        if (!ticket) return;
        const ticketPrevProps = prevProps.ticket;
        const { workflowId } = ticket;
        if (!ticketPrevProps || workflowId !== ticketPrevProps.workflowId) {
            const { workflowActions } = this.props;
            const { id } = ticket;
            workflowActions.loadWorkflow({
                id: workflowId,
                ticketId: id,
            });
        }
    }

    resolutionClicked = (event, resolution) => {
        const { currentTicket, ticketStateActions } = this.props;
        this.setState({ resolving: true });
        const { statusId, id } = resolution;

        ticketStateActions
            .resolve(currentTicket, statusId, id, resolution)
            .then(() => {
                this.setState({ resolving: false });
            })
            .catch((error) => {
                toastr.error(error);
                this.setState({ resolving: false, error: error });
            });
    };

    transitionClicked = (event, transition) => {
        const { currentTicket, ticketStateActions, workflowActions, ticket } =
            this.props;
        const currentStatusNode = workflowActions.getStatusNode(
            ticket.statusNodeId,
        );
        if (currentTicket.edited) {
            toastr.error('Ticket changes must be saved before transition');
            return;
        }
        const { id } = transition;
        const validate = validateTransition(
            currentTicket,
            transition,
            currentStatusNode,
        );
        if (validate.errors) {
            toastr.error(validate.errors.map((error) => `${error.label}<br/>`));
            return;
        }
        this.setState({ transitioning: true });
        ticketStateActions
            .transition(currentTicket, id)
            .then(() => {
                this.setState({ transitioning: false });
            })
            .catch((error) => {
                toastr.error(error);
                this.setState({ transitioning: false, error: error });
            });
    };

    handleWorkflowFieldChange = (item, field, value) => {
        this.props.ticketStateActions.updateFieldEntry(item, field, value);
    };

    handleContextMenu = (e) => {
        e.preventDefault();
        const { uiActions, ticket, followUpList } = this.props;
        const { pageX, pageY } = e;
        const inFollowUpList = followUpList.includes(ticket.id);
        const items = inFollowUpList
            ? [
                  {
                      key: 'remove',
                      name: 'Remove from follow-up list',
                      action: types.TICKET_REMOVE_FROM_FOLLOW_UP_LIST,
                  },
              ]
            : [
                  {
                      key: 'add',
                      name: 'Add to follow-up list',
                      action: types.TICKET_ADD_TO_FOLLOW_UP_LIST,
                  },
              ];
        uiActions.showContextMenu({
            positionX: pageX,
            positionY: pageY,
            target: ticket,
            items,
        });
    };

    handleFollowUpClick = () => {
        const { ticketStateActions, ticket, followUpList } = this.props;
        const inFollowUpList = followUpList.includes(ticket.id);
        if (inFollowUpList) {
            ticketStateActions.removeTicketFromFollowUpList(ticket.id);
        } else {
            ticketStateActions.addTicketToFollowUpList(ticket.id);
        }
    };

    render() {
        const {
            ticket,
            stripe,
            onClick,
            isSelected,
            compactView,
            currentUser,
            workflow,
            workflowActions,
            disableInput,
            ticketComponent,
            actions,
            extraRowProps,
            followUpList,
            userHasInputPermission,
        } = this.props;
        const currentStatusNode = ticket
            ? workflowActions.getStatusNode(ticket.statusNodeId)
            : null;

        if (!ticket || !currentStatusNode) {
            return (
                <div className="ticket-row full placeholder">
                    <Placeholder fluid>
                        <Placeholder.Header>
                            <Placeholder.Line />
                            <Placeholder.Line />
                        </Placeholder.Header>
                    </Placeholder>
                </div>
            );
        }
        const { transitioning, resolving } = this.state;
        const userHasInputPermission2 = disableInput
            ? false
            : userHasInputPermission;

        const followUp = followUpList.includes(ticket.id);

        const ticketProps = {
            ticket,
            stripe,
            onClick,
            isSelected,
            transitioning,
            resolving,
            resolutionClicked: this.resolutionClicked,
            transitionClicked: this.transitionClicked,
            currentStatusNode,
            userHasInputPermission: userHasInputPermission2,
            workflow,
            actions,
            onContextMenu: this.handleContextMenu,
            followUp,
            handleFollowUpClick: this.handleFollowUpClick,
        };

        let TicketComponent = ticketComponent;
        if (!TicketComponent) {
            TicketComponent = compactView ? TicketRowCompact : TicketRowFull;
        }

        // || compactView ? TicketRowCompact : TicketRowFull

        return <TicketComponent {...ticketProps} {...extraRowProps} />;
    }
}

TicketRow.propTypes = {
    item: PropTypes.object.isRequired,
    ticketStateActions: PropTypes.object,
    stripe: PropTypes.bool,
    isSelected: PropTypes.bool,
    disableInput: PropTypes.bool,
    compactView: PropTypes.bool,
    onClick: PropTypes.func,
    currentUser: PropTypes.object,
    ticketActions: PropTypes.object,
    uiActions: PropTypes.object,
    ticket: PropTypes.object,
    currentTicket: PropTypes.object,
    loadingWorkflow: PropTypes.bool,
    workflowActions: PropTypes.object,
    workflow: PropTypes.object,
    extraRowProps: PropTypes.object,
    followUpList: PropTypes.array,

    ticketComponent: PropTypes.func,
    actions: PropTypes.object,
};

export default WrapFollowUpContext(
    UserWrapper(TicketWrapper(WorkflowWrapper(TicketRow))),
);
