import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Input, List, Icon, Loader, Form } from 'semantic-ui-react';
import * as _ from 'lodash';
import ticketApi from '../../api/ticketApi';
import { mapPrincipalsToOptions } from '../../utils/React';
import { getUnassignedUsers } from '../../utils/ExecutionUtils';
import { WrapUserContext } from '../../context/UserContext';
import { Principal } from '../../types';
import { useAppSelector } from '../../actions/store';

interface PrincipalSearchProps {
    onChange: (
        event: React.SyntheticEvent<HTMLElement>,
        data: { value: Principal | null },
    ) => void;
    onClose?: () => void;
    saving?: boolean;
    currentPrincipal?: Principal | null;
    isAutofocus?: boolean;
    clearable?: boolean;
    label?: string;
    disabled?: boolean;
    includeDefaultGroups?: boolean;
    exceptPrincipal?: Principal[];
    restrictToAssigned?: boolean;
    addUnassignValue?: boolean;
    filterByMyParty?: boolean;
    currentUser?: {
        details: {
            principalId: string;
            partyId: string;
        };
        fullname: string;
    };
}

interface PrincipalSearchState {
    options: Array<{
        key: string | number;
        text: string;
        value: string | null;
    }>;
    principals: Record<string, Principal>;
    searchQuery: string;
    isLoading: boolean;
    filterByMyParty: boolean;
}

const PrincipalSearch: React.FC<PrincipalSearchProps> = ({
    onChange,
    onClose,
    saving = false,
    currentPrincipal = null,
    isAutofocus = true,
    clearable = false,
    label,
    disabled = false,
    includeDefaultGroups = false,
    exceptPrincipal = null,
    restrictToAssigned = false,
    addUnassignValue = false,
    filterByMyParty = false,
    currentUser,
}) => {
    const defaultMyPartyList = useAppSelector(
        (state) => state.constants.options?.defaultMyPartyList ?? false,
    );

    const [state, setState] = useState<PrincipalSearchState>({
        options: currentPrincipal
            ? mapPrincipalsToOptions([currentPrincipal])
            : [],
        principals: currentPrincipal
            ? { [currentPrincipal.id]: currentPrincipal }
            : {},
        searchQuery: '',
        isLoading: false,
        filterByMyParty: defaultMyPartyList,
    });

    const handleSearchChange = useCallback(
        async (searchQuery: string) => {
            setState((prev) => ({ ...prev, isLoading: true, searchQuery }));

            const data = await ticketApi.searchTicketPrincipals(
                searchQuery,
                includeDefaultGroups,
                restrictToAssigned,
            );

            let filteredData = data;
            if (state.filterByMyParty && currentUser) {
                filteredData = data.filter((item) => {
                    return item.user?.party?.id === currentUser.details.partyId;
                });
            }

            let options = mapPrincipalsToOptions(filteredData);

            if (currentUser) {
                options = [
                    {
                        key: currentUser.details.principalId,
                        text: `You (${currentUser.fullname})`,
                        value: currentUser.details.principalId,
                    },
                    ...options.filter(
                        (option) =>
                            option.key !== currentUser.details.principalId,
                    ),
                ];
            }

            if (exceptPrincipal) {
                options = getUnassignedUsers(options, exceptPrincipal);
            }

            if (addUnassignValue) {
                const unassignValue = {
                    key: -1,
                    text: 'Unassigned',
                    value: null,
                };
                options = [unassignValue, ...options];
            }

            setState((prev) => ({
                ...prev,
                options,
                principals: _.keyBy(data, 'id'),
                isLoading: false,
            }));
        },
        [
            state.filterByMyParty,
            currentUser,
            includeDefaultGroups,
            restrictToAssigned,
            exceptPrincipal,
            addUnassignValue,
        ],
    );

    const debouncedSearchRef =
        useRef<_.DebouncedFunc<typeof handleSearchChange>>();

    useEffect(() => {
        debouncedSearchRef.current = _.debounce(handleSearchChange, 500);
        return () => {
            debouncedSearchRef.current?.cancel();
        };
    }, [handleSearchChange]);

    useEffect(() => {
        handleSearchChange(state.searchQuery);
    }, []);

    useEffect(() => {
        if (exceptPrincipal) {
            handleSearchChange(state.searchQuery);
        }
    }, [exceptPrincipal]);

    useEffect(() => {
        handleSearchChange(state.searchQuery);
    }, [state.filterByMyParty]);

    const handleInputChange = (
        _event: React.ChangeEvent<HTMLInputElement>,
        { value }: { value: string },
    ) => {
        setState((prev) => ({ ...prev, searchQuery: value }));
        debouncedSearchRef.current?.(value);
    };

    const handleOptionSelect = (
        event: React.MouseEvent<HTMLElement>,
        value: string | null,
    ) => {
        const { principals } = state;
        const newValue = value ? principals[value] : null;
        onChange(event, { value: newValue });
    };

    const { options, searchQuery, isLoading } = state;

    return (
        <div className="principal-search">
            <Input
                fluid
                icon={<Icon name="search" />}
                placeholder={label || 'Search users & user groups...'}
                value={searchQuery}
                onChange={handleInputChange}
                disabled={saving || disabled}
                autoFocus={isAutofocus}
                loading={isLoading}
            />
            <Form.Checkbox
                label="Only show users in my party"
                checked={state.filterByMyParty}
                onChange={(_, { checked }) => {
                    setState((prev) => ({ ...prev, filterByMyParty: checked }));
                }}
                style={{ margin: '0.5em 0' }}
            />
            {options.length > 0 && (
                <List
                    selection
                    divided
                    style={{
                        maxHeight: '200px',
                        overflowY: 'auto',
                        marginTop: '0.5em',
                        border: '1px solid rgba(34, 36, 38, 0.15)',
                        borderRadius: '0.28571429rem',
                    }}
                >
                    {options.map((option) => (
                        <List.Item
                            key={option.key}
                            onClick={(e) => handleOptionSelect(e, option.value)}
                            style={{ padding: '0.5em 1em', cursor: 'pointer' }}
                        >
                            <List.Content>
                                <List.Header>{option.text}</List.Header>
                            </List.Content>
                        </List.Item>
                    ))}
                </List>
            )}
        </div>
    );
};

export default WrapUserContext(PrincipalSearch);
