import React from 'react';
import { Container, Dimmer, Header, Loader } from 'semantic-ui-react';
import WorkflowStatusList from './WorkflowStatusList';
import WorkflowStatusNodeList from './WorkflowStatusNodeList';
import WorkflowWrapper from './Workflow';
import WorkflowDetailsForm from './WorkflowDetailsForm';
import ErrorBoundary from './../common/ErrorBoundary';
import UserWrapper from '../user/User';
import { Workflow, User } from '../../types';

interface WorkflowPageProps {
    workflows: {
        byId: Record<string, Workflow>;
    };
    workflowActions: {
        setEditingWorkflow: (params: { id: string }) => void;
        editNewWorkflow: () => void;
        saveWorkflow: () => void;
    };
    workflowStateActions: {
        updateWorkflow: (workflow: Workflow, name: string, value: any) => void;
    };
    editingWorkflow: Workflow | null;
    savingWorkflow: boolean;
    loadingWorkflow: boolean;
    match: {
        params: {
            id?: string;
        };
    };
    query?: {
        clone?: string;
    };
    history: {
        push: (path: string) => void;
    };
    currentUser: User;
}

const WorkflowPage: React.FC<WorkflowPageProps> = ({
    workflows,
    workflowActions,
    workflowStateActions,
    editingWorkflow,
    savingWorkflow,
    loadingWorkflow,
    match,
    query,
    currentUser,
}) => {
    React.useEffect(() => {
        if (match.params.id || query?.clone) {
            const id = match.params.id || query.clone;
            workflowActions.setEditingWorkflow({ id });
        } else {
            workflowActions.editNewWorkflow();
        }
    }, [match.params.id, query?.clone]);

    React.useEffect(() => {
        // Check for redirect from New workflow
        if (match.params.id) {
            workflowActions.setEditingWorkflow({
                id: match.params.id,
            });
        }
    }, [match.params.id]);

    const workflowDetailsOnChange = React.useCallback(
        (event: React.SyntheticEvent, data: { name: string; value: any }) => {
            if (editingWorkflow) {
                workflowStateActions.updateWorkflow(
                    editingWorkflow,
                    data.name,
                    data.value,
                );
            }
        },
        [editingWorkflow, workflowStateActions],
    );

    const saveWorkflow = React.useCallback(() => {
        workflowActions.saveWorkflow();
    }, [workflowActions]);

    return (
        <Container fluid>
            <Dimmer active={loadingWorkflow} inverted>
                <Loader disabled={!loadingWorkflow} />
            </Dimmer>
            {editingWorkflow && !loadingWorkflow && (
                <React.Fragment>
                    <WorkflowDetailsForm
                        onChange={workflowDetailsOnChange}
                        workflow={editingWorkflow}
                        onSave={saveWorkflow}
                        saving={savingWorkflow}
                        currentUser={currentUser}
                    />
                    <Header>Statuses</Header>
                    <WorkflowStatusList workflow={editingWorkflow} />
                    <Header>Status Nodes</Header>
                    <WorkflowStatusNodeList workflow={editingWorkflow} />
                </React.Fragment>
            )}
        </Container>
    );
};

export default ErrorBoundary(UserWrapper(WorkflowWrapper(WorkflowPage)));
