import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { Container, Header } from 'semantic-ui-react';
import { WrapUserContext } from '../context/UserContext';
import DashboardContainer from './dashboard/DashboardContainer';
import ErrorBoundary from './common/ErrorBoundary';
import { Redirect } from 'react-router-dom';

class HomePage extends Component {
    render() {
        const { currentUser } = this.props;
        return (
            <Container>
                {currentUser.settings.DEFAULT_DASHBOARD ? (
                    <React.Fragment>
                        <Header size="large">Your Dashboard</Header>
                        <DashboardContainer
                            id={currentUser.settings.DEFAULT_DASHBOARD}
                        />
                    </React.Fragment>
                ) : (
                    <Redirect to="/programmeStatus" />
                )}

                {/*<Grid stackable centered columns={2}>
          <Grid.Column>
            <Header as="h4" attached="top" block>
              Introduction
            </Header>
            <Segment attached="bottom">
              <Header>Welcome to Vidende!</Header>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In
                tempor tellus vitae leo commodo, ut consectetur elit imperdiet.
                Fusce justo nulla, placerat eu vehicula id, viverra eget diam.
                Suspendisse ornare ultrices ante non aliquam. Pellentesque
                tristique aliquam risus, sed rhoncus nulla malesuada et.{' '}
              </p>

              <Header as="h2" to="/executions">
                <Icon name="rocket" />
                <Header.Content>
                  Current Programmes
                  <Header.Subheader as={Link} to="/executions">
                    View your running programmes
                  </Header.Subheader>
                </Header.Content>
              </Header>
              <Header as="h2">
                <Icon name="cogs" />
                <Header.Content>
                  Programme Planning
                  <Header.Subheader as={Link} to="/executionPlans">
                    Plan a programme launch
                  </Header.Subheader>
                </Header.Content>
              </Header>
              <Header as="h2">
                <Icon name="book" />
                <Header.Content>
                  Programme Building
                  <Header.Subheader as={Link} to="/programmes">
                    Build a new programme
                  </Header.Subheader>
                </Header.Content>
              </Header>
            </Segment>
            <Header as="h4" attached="top" block>
              Running Programmes
            </Header>
            <Segment attached="bottom">
              <RecentExecutions history={this.props.history} />
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Header as="h4" attached="top" block>
              Event List
            </Header>
            <Segment attached="bottom">
              <EventList />
            </Segment>
            <Header as="h4" attached="top" block>
              Your tickets
            </Header>
            <Segment attached="bottom">
              <TicketList />
            </Segment>
          </Grid.Column>
        </Grid> */}
            </Container>
        );
    }
}

HomePage.propTypes = {
    history: PropTypes.object,
    currentUser: PropTypes.object,
};

export default ErrorBoundary(WrapUserContext(HomePage));
