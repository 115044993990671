import { Component } from 'react';
import * as PropTypes from 'prop-types';
import {
    Container,
    Dimmer,
    Grid,
    Header,
    Loader,
    Segment,
} from 'semantic-ui-react';
import ProgrammeWrapper from './Programme';
import ProgrammeDetails from './ProgrammeDetails';
import ProgrammeCategoryTabs from './ProgrammeCategoryTabs';
import ActivityWrapper from '../activity/Activity';
import ErrorBoundary from '../common/ErrorBoundary';
import { ProgrammeFieldManager } from './programmeFieldManager/ProgrammeFieldManager';

class ProgrammeFieldManagerPage extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            loading: false,
        };
    }

    componentWillMount() {
        this.setState({ loading: true });

        this.props.programmeStateActions
            .loadProgramme(this.props.match.params.id, 'CURRENT', true)
            .then(() => {
                this.setState({ loading: false });
            });
    }

    render() {
        const { loading } = this.state;
        const programme = this.props.currentProgramme;

        return (
            <Container>
                <Segment>
                    <Dimmer active={loading} inverted>
                        <Loader disabled={!loading} />
                    </Dimmer>
                    {!loading && programme && (
                        <>
                            <ProgrammeFieldManager programme={programme} />
                        </>
                    )}
                </Segment>
            </Container>
        );
    }
}

ProgrammeFieldManagerPage.propTypes = {
    programmeActions: PropTypes.object.isRequired,
    programmeStateActions: PropTypes.object.isRequired,
    currentProgramme: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

export default ErrorBoundary(
    ActivityWrapper(ProgrammeWrapper(ProgrammeFieldManagerPage)),
);
