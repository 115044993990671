import React from 'react';
import * as PropTypes from 'prop-types';
import { Card, List } from 'semantic-ui-react';
import ActionButtons from '../common/ActionButtons';
import { PermissionContextConsumer } from '../../context/PermissionContext';

const PrincipalRole = (props) => {
    const { principal, roleDefinition } = props.data;
    const { permissionActions, data } = props;
    return (
        <Card>
            <Card.Content>
                <Card.Header>{principal.name}</Card.Header>
                <Card.Meta>{roleDefinition.label}</Card.Meta>
                <List>
                    {roleDefinition.permissions.map((permission) => (
                        <List.Item key={permission.id}>
                            {permission.label} ({permission.code})
                        </List.Item>
                    ))}
                </List>
                <ActionButtons
                    deleteClicked={() => {
                        permissionActions.deletePrincipalRole(data);
                    }}
                />
            </Card.Content>
        </Card>
    );
};

PrincipalRole.propTypes = {
    data: PropTypes.object.isRequired,
    permissionActions: PropTypes.object,
};

export default PermissionContextConsumer(PrincipalRole);
