import React from 'react';
import * as PropTypes from 'prop-types';
import { List } from 'semantic-ui-react';
import * as _ from 'lodash';
import * as Mustache from 'mustache';
import Fields from '../../fields/Fields';

const ReportList = (props) => {
    const { value, template, listArgs, fieldType, renderFieldValue } = props;
    if (!_.isArray(value)) return null;

    return (
        <List {...listArgs}>
            {value.map((item, index) => {
                const renderedValue = Mustache.render(template, item);
                const type = fieldType ? _.get(item, fieldType) : 'Text';
                const Field = Fields[type || 'Text'];
                const value = _.get(item, renderFieldValue);

                return (
                    <List.Item key={index}>
                        {renderedValue}
                        {renderFieldValue && (
                            <Field value={value} editable={false} />
                        )}
                    </List.Item>
                );
            })}
        </List>
    );
};

ReportList.propTypes = {
    value: PropTypes.array,
    template: PropTypes.string,
    fieldType: PropTypes.string,
    renderFieldValue: PropTypes.string,
    listArgs: PropTypes.object,
};

export default ReportList;
