import React, { Component } from 'react';

import DashboardContainer from './DashboardContainer';
import ErrorBoundary from './../common/ErrorBoundary';

class DashboardLayoutDemo extends Component {
    render() {
        return (
            <React.Fragment>
                <DashboardContainer
                    id="939BB340-8FFE-11E8-A96F-C561BB00599B"
                    editable={true}
                />
                {/*<DashboardContainer id="DASHBOARD-002" />*/}
            </React.Fragment>
        );
    }
}

export default ErrorBoundary(DashboardLayoutDemo);
