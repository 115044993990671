import _ from 'lodash';
import utilsApi from '../api/utilsApi';
import * as types from '../constants/actionTypes';

export function loadDashboardWidgetData(dataCalls, id, dataCallsHash) {
    return function (dispatch, getState) {
        const state = getState();
        if (state.chartDataByHash[dataCallsHash]) {
            dispatch({
                type: types.LOAD_DASHBOARD_WIDGET_DATA_SUCCESS,
                dataCallsHash,
                data: state.chartDataByHash[dataCallsHash].data,
                id,
            });
            return Promise.resolve();
        }

        const promises = _.map(dataCalls, (callConfig, key) => {
            const promise = new Promise((resolve, reject) => {
                utilsApi
                    .modelCall(callConfig)
                    .then((data) => {
                        resolve({ key, data });
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
            return promise;
        });
        return Promise.all(promises).then((data) => {
            dispatch({
                type: types.LOAD_DASHBOARD_WIDGET_DATA_SUCCESS,
                dataCallsHash,
                data,
                id,
            });
        });
    };
}
