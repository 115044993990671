import React, { Component } from 'react';
import { Button, Container, List } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import * as _ from 'lodash';

import chartApi from '../../api/chartApi';
import ErrorBoundary from './../common/ErrorBoundary';

class ChartsPage extends Component {
    state = {
        charts: [],
        newChartTitle: '',
    };

    componentDidMount() {
        chartApi.getCharts().then((charts) => {
            this.setState({ charts: _.orderBy(charts, 'label') });
        });
    }

    updateStateValue = (event, { name, value }) => {
        this.setState({
            [name]: value,
        });
    };

    render() {
        const { charts } = this.state;
        return (
            <Container>
                <List>
                    {charts.map((chart) => (
                        <List.Item
                            key={chart.id}
                            as={Link}
                            to={`/widgetBuilder/${chart.id}`}
                        >
                            {chart.label}
                        </List.Item>
                    ))}
                </List>
                <Button as={Link} to="widgetBuilder">
                    Create New Chart
                </Button>
            </Container>
        );
    }
}

ChartsPage.propTypes = {};

export default ErrorBoundary(ChartsPage);
