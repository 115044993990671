import html2canvas from 'html2canvas';
import downloadjs from 'downloadjs';
import xlsx, { IJsonSheet } from 'json-as-xlsx';

export const downloadImage = async (
    htmlElement: HTMLElement,
    format: 'png' | 'jpeg',
) => {
    const canvas = await html2canvas(htmlElement);
    const dataURL = canvas.toDataURL(`image/${format}`);
    downloadjs(dataURL, `download.${format}`, `image/${format}`);
};

export const downloadCSV = (array: any[]) => {
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;
    if (!csv.match(/^data:text\/csv/i)) {
        csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    downloadjs(csv, `download.csv`);
};
export const downloadXLSX = (array: IJsonSheet[]) => {
    xlsx(array, { fileName: 'download', extraLength: 3 });
};
export const convertArrayOfObjectsToCSV = (array: any[]) => {
    let result: string;

    const columnDelimiter = ',';
    const lineDelimiter = '\n';
    const keys = Object.keys(array[0]);

    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
        let ctr = 0;
        keys.forEach((key) => {
            if (ctr > 0) result += columnDelimiter;

            result += item[key];

            ctr++;
        });
        result += lineDelimiter;
    });

    return result;
};
