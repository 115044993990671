import { chain, debounce } from 'lodash';
import { type } from 'os';
import { useState } from 'react';
import { Dropdown } from 'semantic-ui-react';

import activityApi from '../../api/activityApi';
import { Field } from '../../types';

type FieldSearchProps = {
    onSelection: (field: Field) => void;
};

export const FieldSearch = ({ onSelection }: FieldSearchProps) => {
    const [searchOptions, setSearchOptions] = useState([]);
    const [loadingFields, setLoadingFields] = useState(false);
    const [availableFields, setAvailableFields] = useState<Field[]>([]);

    const performSearch = debounce(async (searchQuery: string) => {
        if (searchQuery.length < 4) return;

        //User.find({where: {name: {like: '%St%'}}}, function (err, posts) { ... });

        setLoadingFields(true);
        activityApi
            .getActivityFields({
                where: {
                    fieldKey: {
                        like: `%${searchQuery}%`,
                    },
                },
                limit: 100,
            })
            .then((fields: Field[]) => {
                setAvailableFields(fields);
                const options = chain(fields)
                    .uniqBy('fieldKey')
                    .orderBy(['fieldKey'])
                    .map((field) => ({
                        key: field.id,
                        text: `${field.fieldKey}  "${
                            field.label.length > 40
                                ? field.label.substring(0, 40) + '...'
                                : field.label
                        }"- ${field.activityFieldTypeId}`,
                        value: field.fieldKey,
                    }))
                    .value();

                setSearchOptions(options);
                setLoadingFields(false);
            });
    }, 500);

    const handleSearchChange = (e, { searchQuery }) => {
        performSearch(searchQuery);
    };

    const handleFieldSelection = (e, { value }) => {
        const field = availableFields.find((f) => f.fieldKey === value);
        if (field) {
            onSelection(field);
        }
    };

    return (
        <Dropdown
            fluid
            selection
            // multiple={multiple}
            search={true}
            options={searchOptions}
            // value={value}
            placeholder="Type field key"
            onChange={handleFieldSelection}
            onSearchChange={handleSearchChange}
            disabled={loadingFields}
            loading={loadingFields}
        />
    );
};
