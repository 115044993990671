import React, { useReducer, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import Fields from './Fields';
import { Form } from 'semantic-ui-react';
import FieldToolTip from './FieldToolTip';
import ActionButtons from '../ActionButtons';
import arrayReducer from '../../../reducers/arrayReducer';
import reduceReducers from '../../../reducers/helpers/reduceReducers';

function reducer(state, action) {
    switch (action.type) {
        default:
            return state;
    }
}

const combinedReducer = reduceReducers(reducer, arrayReducer);

const ArrayField = (props) => {
    const { type, value = [], label, onChange, name, required, width } = props;

    const [state, dispatch] = useReducer(combinedReducer, { value });
    useEffect(() => {
        onChange({}, { name, value: state.value });
    }, [state.value]);

    const handleUpdateField = (event, { value, index }) => {
        dispatch({
            type: 'UPDATE_VALUE',
            value,
            index,
        });
    };

    if (!type) return null;

    const Field = Fields[type.field];

    if (!_.isArray(state.value)) return null;

    return (
        <Form.Field required={required} width={width}>
            <label>
                {label}
                <FieldToolTip {...props} />
            </label>

            {state.value.map((v, index) => (
                <React.Fragment key={index}>
                    <Field
                        type={type.type}
                        value={v}
                        onChange={(event, data) => {
                            handleUpdateField(event, { ...data, index });
                        }}
                    />
                    <ActionButtons
                        removeClicked={() => {
                            dispatch({
                                type: 'REMOVE_VALUE',
                                index,
                            });
                        }}
                        copyClicked={() => {
                            dispatch({
                                type: 'DUPLICATE_VALUE',
                                index,
                            });
                        }}
                        upClicked={() => {
                            dispatch({
                                type: 'MOVE_VALUE_UP',
                                index,
                            });
                        }}
                        downClicked={() => {
                            dispatch({
                                type: 'MOVE_VALUE_DOWN',
                                index,
                            });
                        }}
                    />
                </React.Fragment>
            ))}

            <ActionButtons
                addClicked={() => {
                    dispatch({
                        type: 'ADD_VALUE',
                    });
                }}
            />
        </Form.Field>
    );
};

ArrayField.propTypes = {
    type: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    name: PropTypes.string,
    required: PropTypes.bool,
    width: PropTypes.number,
};

export default ArrayField;
