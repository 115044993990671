import apiTools from './apiTools';

class ActivityApi {
    static getActivities(filters = {}) {
        const filter = {
            where: {
                activityStatusId: {
                    neq: 'DEACTIVATED',
                },
            },
            order: 'createdAt DESC',
            limit: 500,
            include: ['status', 'permissionsNode'],
        };

        if (filters.label)
            filter.where.label = { like: `%${filters.label}%`, options: 'i' };
        if (filters.ref)
            filter.where.ref = { like: `%${filters.ref}%`, options: 'i' };
        if (filters.activityStatusId)
            filter.where.activityStatusId = { inq: [filters.activityStatusId] };
        if (filters.structuralNodeId)
            filter.where.structuralNodeId = { inq: [filters.structuralNodeId] };
        if (filters.primaryTag)
            filter.where.primaryTagId = { inq: [filters.primaryTag.id] };
        if (filters.ownerPartyId)
            filter.where.ownerPartyId = { inq: [filters.ownerPartyId] };
        if (filters.tags)
            filter.where['tags.id'] = {
                inq: filters.tags.map((tag) => tag.id),
            };

        return apiTools
            .get('Activities', {
                filter,
            })
            .then((response) => {
                return response.data;
            });
    }

    static getActivityFields(filter = {}) {
        return apiTools
            .get('ActivityFields', {
                filter,
            })
            .then((response) => {
                return response.data;
            });
    }

    static getActivity(id) {
        return apiTools
            .get(`Activities/${id}`, {
                filter: {
                    include: [
                        'tags',
                        'primaryTag',
                        'status',
                        'fields',
                        'workflowStatusFieldDefaults',
                        {
                            relation: 'activitySets',
                            scope: {
                                include: ['programme'],
                            },
                        },
                        'creator',
                    ],
                },
            })
            .then((response) => {
                return response.data;
            });
    }

    static saveActivity(
        activity,
        isNewVersion = false,
        overrideUpdateChecks = false,
    ) {
        const apiMethod = activity.id ? apiTools.put : apiTools.post;
        const apiPath = activity.id
            ? `Activities/${activity.id}`
            : `Activities`;
        const params = {};
        if (isNewVersion) params.newVersion = true;
        if (overrideUpdateChecks) params.overrideUpdateChecks = true;
        return apiMethod(apiPath, activity, params).then((response) => {
            return response.data;
        });
    }

    static bulkUpdateFields(fields) {
        ///bulk-update'

        return apiTools
            .put('ActivityFields/bulk-update', fields)
            .then((response) => {
                return response.data;
            });
    }

    static deleteField(field) {
        ///bulk-update'

        return apiTools
            .delete(`ActivityFields/${field.id}`)
            .then((response) => {
                return response.data;
            });
    }

    static createField(id, data) {
        return apiTools
            .post(
                `Activities/F6760EE1-CDAA-11ED-BF3E-EFA80263ACEA/fields`,
                data,
            )
            .then((response) => {
                return response.data;
            });
    }

    static getActivitieFieldTypes() {
        return apiTools.get('ActivityFieldTypes').then((response) => {
            return response.data;
        });
    }

    static getActivitieResolutionTypes() {
        return apiTools.get('ActivityResolutionTypes').then((response) => {
            return response.data;
        });
    }
}

export default ActivityApi;
