import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import partyApi from '../../api/partyApi';
import PartyRelationshipInfo from './PartyRelationshipInfo';
import { Dimmer, Header, Loader, Segment } from 'semantic-ui-react';

const PartyRelationshipSidebar = (props) => {
    const { relationshipId, partyId } = props;

    const [relationship, setRelationship] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchRelationshipInfo() {
            const result = await partyApi.getPartyRelationships(partyId, {
                include: [
                    'fromParty',

                    'toParty',
                    'relationshipType',
                    { entries: ['field'] },

                    {
                        partyRelationships: [
                            'fromParty',
                            'toParty',
                            'relationshipType',
                            { entries: ['field'] },
                        ],
                    },
                ],
                where: {
                    id: relationshipId,
                },
            });

            if (result && result.length > 0) setRelationship(result[0]);

            setLoading(false);
        }

        fetchRelationshipInfo();
    }, []);

    return (
        <Segment basic>
            <Header>Entity Viewer</Header>
            {loading && (
                <Dimmer active={loading} inverted>
                    <Loader />
                </Dimmer>
            )}
            {relationship && (
                <PartyRelationshipInfo relationship={relationship} />
            )}
        </Segment>
    );
};

PartyRelationshipSidebar.propTypes = {
    relationshipId: PropTypes.number,
    partyId: PropTypes.string,
};

export default PartyRelationshipSidebar;
