import React from 'react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import { Header, Item } from 'semantic-ui-react';
import Moment from 'react-moment';

import Fields from '../common/fields/Fields';

const TicketWorkflowEntry = (props) => {
    const { entry } = props;
    const Field = Fields[entry.field.activityFieldTypeId];
    const hasValues = _.has(entry, 'field.options.values');

    return (
        <Item key={entry.id}>
            <Item.Content>
                <Item.Description>
                    <Header size="small" as="h4">
                        {entry.field.label}
                    </Header>
                    <Field
                        width={4}
                        {...entry.field}
                        options={
                            hasValues
                                ? entry.field.options.values
                                : entry.field.options
                        }
                        value={entry ? entry.value : null}
                        editable={false}
                    />
                </Item.Description>

                <Item.Extra>
                    {entry.modifiedBy.firstName} {entry.modifiedBy.lastName} -{' '}
                    <Moment>{entry.modifiedAt}</Moment>
                </Item.Extra>
            </Item.Content>
        </Item>
    );
};

TicketWorkflowEntry.propTypes = {
    entry: PropTypes.object.isRequired,
};

export default TicketWorkflowEntry;
