import React from 'react';
import { Header, Divider, Card, Grid } from 'semantic-ui-react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import ActivitySetCard from './ActivitySetCard';
import ExecutionPlanActivitySetsRelationship from './ExecutionPlanActivitySetsRelationship';

require('./ExecutionPlanActivitySets.scss');

const ExecutionPlanActivitySets = ({
    activitySetsName,
    items,
    activeCategoryId,
}) => {
    return (
        <React.Fragment>
            <Grid>
                <Grid.Row columns={2}>
                    <Grid.Column width={10}>
                        <Header as="h2">{activitySetsName}</Header>
                        <p>
                            Please select which question sets you want to add in
                            scope:
                        </p>
                    </Grid.Column>
                    <Grid.Column width={6} floated="right">
                        <ExecutionPlanActivitySetsRelationship
                            activeCategoryId={activeCategoryId}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Divider />
            <Card.Group className="execution-plan-activity-card-group">
                {items.length
                    ? _.map(items, (item) => (
                          <ActivitySetCard key={item.id} item={item} />
                      ))
                    : null}
            </Card.Group>
        </React.Fragment>
    );
};

ExecutionPlanActivitySets.propTypes = {
    activitySetsName: PropTypes.string,
    items: PropTypes.array,
    activeCategoryId: PropTypes.string,
};

export default ExecutionPlanActivitySets;
