import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { Header } from 'semantic-ui-react';

import { ExecutionPlanWrapContext } from '../../context/ExecutionPlanContext';
import FilterAndPaginate from '../common/FilterAndPaginate';
import PlanList from './PlanList';
import Pagination from '../common/Pagination';

class ExecutionPlanList extends Component {
    render() {
        const {
            paginatedItems,
            handleSortChange,
            handlePaginationChange,
            paginationOptions,
            handleAddFilter,
            currentFilters,
            removeFilterValues,
        } = this.props;

        return (
            <React.Fragment>
                <Header as="h2">Programme Planning</Header>
                <PlanList
                    executionPlans={paginatedItems || []}
                    paginationOptions={paginationOptions}
                    handleSortChange={handleSortChange}
                    handleAddFilter={handleAddFilter}
                    currentFilters={currentFilters}
                    removeFilterValues={removeFilterValues}
                />
                <Pagination
                    activePage={paginationOptions.activePage}
                    onPageChange={handlePaginationChange}
                    totalPages={paginationOptions.totalPages}
                    style={{ float: 'right' }}
                />
            </React.Fragment>
        );
    }
}

ExecutionPlanList.propTypes = {
    paginationOptions: PropTypes.object,
    paginatedItems: PropTypes.array,
    handleSortChange: PropTypes.func,
    handlePaginationChange: PropTypes.func,
    handleAddFilter: PropTypes.func,
    filter: PropTypes.object,
    currentFilters: PropTypes.array,
    removeFilterValues: PropTypes.func,
};

function mapOptions(props) {
    return {
        items: props.executionPlans,
    };
}

export default ExecutionPlanWrapContext(
    FilterAndPaginate(mapOptions)(ExecutionPlanList),
);
