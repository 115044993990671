import { Party, User } from '../types';

export type PartiesState = {
    list: Party[];
    byId: { [id: string]: Party };
};

const initialState: PartiesState = {
    list: [],
    byId: {},
};

type Action =
    | { type: 'LOAD_PARTIES_SUCCESS'; parties: Party[] }
    | { type: 'CREATE_PARTY_SUCCESS'; party: Party; creator: User }
    | { type: 'LOAD_PARTY_SUCCESS'; party: Party }
    | { type: 'UPDATE_PARTY_SUCCESS'; party: Party };

export default function partiesReducer(
    state = initialState,
    action: Action,
): PartiesState {
    switch (action.type) {
        case 'LOAD_PARTIES_SUCCESS': {
            const byId: { [id: string]: Party } = {};
            action.parties.forEach((party) => {
                byId[party.id] = party;
            });
            return { ...state, list: action.parties, byId };
        }
        case 'CREATE_PARTY_SUCCESS': {
            const { party, creator } = action;
            const newParty = { ...party, creator };
            return {
                ...state,
                list: [...state.list, newParty],
                byId: { ...state.byId, [newParty.id]: newParty },
            };
        }
        case 'UPDATE_PARTY_SUCCESS': {
            const { party } = action;
            const existingParty = state.byId[party.id];
            if (!existingParty) return state;
            return {
                ...state,
                list: state.list.map((p) =>
                    p.id === party.id ? { ...existingParty, ...party } : p,
                ),
                byId: {
                    ...state.byId,
                    [party.id]: { ...existingParty, ...party },
                },
            };
        }
        case 'LOAD_PARTY_SUCCESS': {
            const { party } = action;
            return {
                ...state,
                list: state.list.some((p) => p.id === party.id)
                    ? state.list
                    : [...state.list, party],
                byId: { ...state.byId, [party.id]: party },
            };
        }
        default:
            return state;
    }
}
