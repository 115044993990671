import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
    Modal,
    Header,
    Button,
    Checkbox,
    Form,
    Divider,
    Message,
} from 'semantic-ui-react';
import { withRouter } from 'react-router';
import toastr from 'toastr';
import * as _ from 'lodash';
import moment from 'moment';

import * as uiActions from '../../actions/uiActions';
import * as executionPlanActions from '../../actions/executionPlanActions';
import * as userActions from '../../actions/userActions';
import LaunchNowForm from './LaunchNowForm';
import LaunchLaterForm from './LaunchLaterForm';
import LaunchOnScheduleForm from './LaunchOnScheduleForm';
import Text from '../common/fields/Text';
import { checkValidation } from '../../utils/Validation';
import {
    END_TYPES,
    REPEAT_TYPES,
    LAUNCH_OPTIONS,
    LAUNCH_TYPES,
} from '../../utils/ExecutionPlanLaunchConsts';
import { convertAPIError } from '../../utils/ErrorUtils';

class LaunchExecutionPlanModal extends Component {
    state = {
        label: this.props.label,
        formData: {
            launchDate: null,
            dueDate: null,
            startDate: moment().startOf('day').add(1, 'days').format(),
            endDate: '',
            every: 1,
            occurrences: 1,
            endType: END_TYPES.NEVER,
            repeatDays: [
                Math.pow(2, moment().locale('en-gb').add(1, 'days').weekday()),
            ],
            repeatType: REPEAT_TYPES.WEEK,
            applicableDateTypeId: null,
            applicableDate: null,
        },
        launchTime: LAUNCH_OPTIONS.NOW,
        errors: {
            launchDate: false,
            dueDate: false,
        },
        apiErrors: [],
        validations: {
            label: { isValid: true },
        },
        copyValues: false,
        loading: false,
        isFormDataValid: true,
    };

    handleChange = (e, { name, value, checked }) => {
        if (name !== 'copyValues' && name !== 'label') this.cleanInputs();
        this.setState({ [name]: value || checked });
    };

    handleFormDataChange = (e, { name, value, checked }) => {
        this.setState((prevState) => ({
            formData: {
                ...prevState.formData,
                [name]: value || checked,
            },
        }));
    };

    setNewRepeatDays = (name, value) => {
        this.setState(
            (prevState) => ({
                formData: {
                    ...prevState.formData,
                    repeatDays: [
                        Math.pow(2, moment(value).locale('en-gb').weekday()),
                    ],
                },
            }),
            this.handleFormDataChange(null, { name, value }),
        );
    };

    scheduleLaunch = () => {
        const { launchTime } = this.state;
        const executionPlanId = this.props.executionPlan.id;
        this.setState({ loading: true, apiErrors: [] });
        switch (launchTime) {
            case LAUNCH_OPTIONS.IN_FUTURE: {
                this.launchSchedule(executionPlanId);
                break;
            }
            case LAUNCH_OPTIONS.ON_RECCURING_SCHEDULE: {
                this.launchOnReccuringSchedule(executionPlanId);
                break;
            }
            default: {
                this.launchNow(executionPlanId);
                break;
            }
        }
    };

    handleError = (error) => {
        const apiErrors = convertAPIError(error);
        this.setState({ apiErrors });
    };

    launchNow = (executionPlanId) => {
        const { uiActions, history, userActions } = this.props;
        const { launchNowExecutionPlan } = this.props.executionPlaneActions;
        const { copyValues, label } = this.state;
        const { dueDate, applicableDate, applicableDateTypeId } =
            this.state.formData;
        let requestData = { copyValues, label };
        if (dueDate) {
            requestData.dueDate = dueDate;
        }

        if (applicableDate && applicableDateTypeId) {
            requestData.applicableDate = applicableDate;
            requestData.applicableDateTypeId = applicableDateTypeId;
        }

        launchNowExecutionPlan(executionPlanId, requestData)
            .then(({ execution }) => {
                userActions.renewToken();
                this.setState({ loading: false });
                uiActions.closeModal();
                toastr.success('Programme is launched');
                if (Array.isArray(execution)) {
                    history.push(
                        `/executionPlan/${executionPlanId}/runningProgrammes`,
                    );
                } else {
                    history.push(`/execution/${execution.id}`);
                }
            })
            .catch((error) => {
                this.setState({ loading: false });
                this.handleError(error);
            });
    };

    launchSchedule = (executionPlanId) => {
        const { copyValues, label } = this.state;
        const { launchDate, dueDate, applicableDate, applicableDateTypeId } =
            this.state.formData;
        const { uiActions, userActions, executionPlaneActions } = this.props;
        const { launchScheduleExecutionPlan } = executionPlaneActions;

        let requestData = {
            label,
            startDate: launchDate,
            copyValues,
            launchType: LAUNCH_TYPES.ONCE,
            dueDate,
            applicableDate,
        };

        if (dueDate) {
            requestData.dueDate = dueDate;
        }

        if (applicableDate && applicableDateTypeId) {
            requestData.applicableDate = applicableDate;
            requestData.applicableDateTypeId = applicableDateTypeId;
        }

        launchScheduleExecutionPlan(executionPlanId, requestData)
            .then(() => {
                userActions.renewToken();
                this.setState({ loading: false });
                toastr.success('Programme is scheduled');
                uiActions.closeModal();
            })
            .catch((error) => {
                this.setState({ loading: false });
                this.handleError(error);
            });
    };

    launchOnReccuringSchedule = (executionPlanId) => {
        const { uiActions } = this.props;
        const { launchScheduleExecutionPlan } =
            this.props.executionPlaneActions;
        const {
            startDate,
            every,
            repeatType,
            repeatDays,
            endType,
            endDate,
            occurrences,
            dueDate,
            copyValues,
            applicableDate,
            applicableDateTypeId,
        } = this.state.formData;
        let requestData = {
            startDate,
            every,
            repeatType,
            endType,
            copyValues,
            dueDate,
            applicableDate,
            launchType: LAUNCH_TYPES.EVERY,
        };

        if (dueDate) {
            requestData.dueDate = dueDate;
        }

        if ((applicableDate, applicableDateTypeId)) {
            requestData.applicableDate = applicableDate;
            requestData.applicableDateTypeId = applicableDateTypeId;
        }

        if (repeatType === REPEAT_TYPES.WEEK) {
            const daysOfWeek = _.reduce(repeatDays, (a, b) => a | b);
            requestData.daysOfWeek = daysOfWeek;
        }

        switch (endType) {
            case END_TYPES.OCCURRENCES: {
                requestData.occurrences = occurrences;
                break;
            }
            case END_TYPES.DATE: {
                requestData.endDate = endDate;
                break;
            }
        }
        this.setState({ loading: true });
        launchScheduleExecutionPlan(executionPlanId, requestData)
            .then(() => {
                userActions.renewToken();
                this.setState({ loading: false });
                toastr.success('Programme is launched on recurring schedule');
                uiActions.closeModal();
            })
            .catch((error) => {
                this.setState({ loading: false });
                this.handleError(error);
            });
    };

    cleanInputs = () => {
        this.setState((prevState) => ({
            formData: {
                ...prevState.formData,
                launchDate: null,
                dueDate: null,
                applicableDate: null,
            },
        }));
    };

    handleOnFocus = (name) => {
        const { validations } = this.state;
        validations[name].hasFocus = true;
        this.setState({ validations });
    };

    handleOnBlur = (name) => {
        const { validations } = this.state;
        validations[name].hasFocus = false;
        this.checkValidation(name, this.state[name]);
        this.setState({ validations });
    };

    checkValidation = (name, value) => {
        const validation = checkValidation(
            { [name]: value },
            {
                [name]: {
                    required: {
                        error: `Please supply the ${name}`,
                    },
                },
            },
        );
        const { validations } = this.state;
        validations[name].errors = validation.errors;
        validations[name].isValid = validation.valid;
        this.setState({
            validations,
            isFormDataValid: _.every(validations, { isValid: true }),
        });
    };

    getErrors = (propertryName) => {
        const { validations } = this.state;
        const validation = validations[propertryName];
        const isValidationErrors = !_.isEmpty(validation.errors);
        return !validation.hasFocus && isValidationErrors
            ? validation.errors
            : {};
    };

    render() {
        const { uiActions } = this.props;
        const {
            launchTime,
            errors,
            apiErrors,
            formData,
            copyValues,
            loading,
            label,
            isFormDataValid,
        } = this.state;
        return (
            <Modal
                open={true}
                onClose={() => uiActions.closeModal()}
                closeOnDimmerClick={false}
                size="mini"
            >
                <Modal.Content>
                    <Header as="h4">Launch this programme</Header>
                    <Form error={apiErrors.length > 0}>
                        <Form.Field>
                            <Text
                                value={label}
                                fluid={true}
                                label="Execution Name"
                                onChange={this.handleChange}
                                name="label"
                                errors={this.getErrors('label')}
                                onBlur={() => this.handleOnBlur('label')}
                                onFocus={() => this.handleOnFocus('label')}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Checkbox
                                radio
                                label="Launch now"
                                name="launchTime"
                                value={LAUNCH_OPTIONS.NOW}
                                checked={launchTime === LAUNCH_OPTIONS.NOW}
                                onChange={this.handleChange}
                            />
                        </Form.Field>
                        <Form.Field>
                            {launchTime === LAUNCH_OPTIONS.NOW && (
                                <LaunchNowForm
                                    formData={formData}
                                    errors={errors}
                                    handleFormDataChange={
                                        this.handleFormDataChange
                                    }
                                />
                            )}
                        </Form.Field>
                        <Form.Field>
                            <Checkbox
                                radio
                                label="Launch in future"
                                name="launchTime"
                                value={LAUNCH_OPTIONS.IN_FUTURE}
                                checked={
                                    launchTime === LAUNCH_OPTIONS.IN_FUTURE
                                }
                                onChange={this.handleChange}
                            />
                        </Form.Field>
                        <Form.Field>
                            {launchTime === LAUNCH_OPTIONS.IN_FUTURE && (
                                <LaunchLaterForm
                                    formData={formData}
                                    errors={errors}
                                    handleFormDataChange={
                                        this.handleFormDataChange
                                    }
                                />
                            )}
                        </Form.Field>
                        <Form.Field>
                            <Checkbox
                                radio
                                label="Launch on recurring schedule"
                                name="launchTime"
                                value={LAUNCH_OPTIONS.ON_RECCURING_SCHEDULE}
                                checked={
                                    launchTime ===
                                    LAUNCH_OPTIONS.ON_RECCURING_SCHEDULE
                                }
                                onChange={this.handleChange}
                            />
                        </Form.Field>
                        <Form.Field>
                            {launchTime ===
                                LAUNCH_OPTIONS.ON_RECCURING_SCHEDULE && (
                                <LaunchOnScheduleForm
                                    formData={formData}
                                    handleFormDataChange={
                                        this.handleFormDataChange
                                    }
                                    setNewRepeatDays={this.setNewRepeatDays}
                                />
                            )}
                        </Form.Field>
                        <Divider section />
                        {apiErrors.length > 0 && (
                            <Message
                                error
                                list={apiErrors}
                                header="An error occurred"
                            />
                        )}
                        <Form.Field>
                            <Checkbox
                                label="Copy values from previous programme"
                                name="copyValues"
                                checked={copyValues}
                                onChange={this.handleChange}
                            />
                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button secondary onClick={() => uiActions.closeModal()}>
                        Cancel
                    </Button>
                    <Button
                        primary
                        onClick={this.scheduleLaunch}
                        loading={loading}
                        disabled={loading || !isFormDataValid}
                    >
                        Launch
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

LaunchExecutionPlanModal.propTypes = {
    label: PropTypes.string,
    uiActions: PropTypes.object,
    executionPlaneActions: PropTypes.object,
    executionPlan: PropTypes.object,
    history: PropTypes.object.isRequired,
    userActions: PropTypes.object,
};

function mapDispatchToProps(dispatch) {
    return {
        uiActions: bindActionCreators(uiActions, dispatch),
        executionPlaneActions: bindActionCreators(
            executionPlanActions,
            dispatch,
        ),
        userActions: bindActionCreators(userActions, dispatch),
    };
}

export default withRouter(
    connect(null, mapDispatchToProps)(LaunchExecutionPlanModal),
);
