import { FC, useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu } from 'semantic-ui-react';

interface DownloadDropdownProps {
    isKPI: boolean;
    iconMode?: 'inverted' | '';
    onDownloadImage: (arg: 'png' | 'jpeg') => void;
    onDownloadCSV?: () => void;
    onDownloadXLSX?: () => void;
    chartOptions: any;
}

const DownloadDropdown: FC<DownloadDropdownProps> = ({
    isKPI = true,
    iconMode = '',
    chartOptions,
    onDownloadImage,
    onDownloadCSV,
    onDownloadXLSX,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const handleDownloadImage = (format: 'png' | 'jpeg') => {
        setIsOpen(false);
        setTimeout(() => onDownloadImage(format));
    };
    const handleDownloadCSV = () => {
        setIsOpen(false);
        onDownloadCSV?.();
    };
    const handleDownloadXLSX = () => {
        setIsOpen(false);
        onDownloadXLSX?.();
    };

    if (!chartOptions?.download?.visible) {
        return null;
    }

    return (
        <div style={{ position: 'absolute', right: 0, top: 0 }}>
            <Dropdown
                open={isOpen}
                onOpen={() => setIsOpen(true)}
                onClose={() => setIsOpen(false)}
                icon={`${iconMode} bars`}
                pointing="right"
            >
                <DropdownMenu>
                    {chartOptions?.download?.png && (
                        <DropdownItem
                            onClick={() => handleDownloadImage('png')}
                            text="Download PNG image"
                        />
                    )}
                    {chartOptions?.download?.jpg && (
                        <DropdownItem
                            onClick={() => handleDownloadImage('jpeg')}
                            text="Download JPEG image"
                        />
                    )}

                    {chartOptions?.download?.csv && !isKPI && (
                        <DropdownItem
                            onClick={() => handleDownloadCSV()}
                            text="Download CSV"
                        />
                    )}
                    {chartOptions?.download?.xls && !isKPI && (
                        <DropdownItem
                            onClick={() => handleDownloadXLSX()}
                            text="Download XLS"
                        />
                    )}
                </DropdownMenu>
            </Dropdown>
        </div>
    );
};

export default DownloadDropdown;
