/* eslint react/no-danger: 0 */
import React from 'react';
import * as PropTypes from 'prop-types';
import { Feed } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import * as dot from 'dot';
import * as _ from 'lodash';
const EventListItemContent = ({ event, model, showLink }) => {
    const { targetId, eventType } = event;

    if (!eventType) return null;
    const { verb } = eventType;
    const label =
        _.get(event, 'data.label') ||
        _.get(event, 'data.originalFileName') ||
        '';
    const modelName = _.get(model, 'emailString') || event.targetModel;
    const modelArticle = ['a', 'e', 'i', 'o', 'u'].includes(
        modelName.toLowerCase(),
    )
        ? 'an'
        : 'a';
    const templateFn = dot.template(verb);
    const extra = {};
    if (_.isNull(event.targetPrincipalId)) {
        extra.targetPrincipal = { label: 'Unassigned' };
    }
    let templateResult;

    try {
        templateResult = templateFn({
            modelArticle,
            event: { ...event, ...extra },
            model: { ...model, emailString: modelName },
        });
    } catch (e) {
        templateResult = 'An event occurred';
    }

    return (
        <React.Fragment>
            <Feed.Summary>
                <span dangerouslySetInnerHTML={{ __html: templateResult }} />
            </Feed.Summary>
            {showLink && model && model.webUrl && (
                <Feed.Extra>
                    <Link to={`/${model.webUrl}${targetId}`}>{label}</Link>
                </Feed.Extra>
            )}
        </React.Fragment>
    );
};

EventListItemContent.propTypes = {
    event: PropTypes.object,
    model: PropTypes.object,
    showLink: PropTypes.bool,
};

export default EventListItemContent;
