import _ from 'lodash';
import ArgTypes from '../ArgTypes';

const DataTable = function (args) {
    const { dataSet, columns, values, outputKey, data } = args;
    const tableDataSet = data[dataSet];

    const columnNames = columns.map((col) => col.label);
    if (!tableDataSet) return {};
    const rowData = tableDataSet.map((dataPoint) => {
        const toInclude = {
            columnNames,
        };
        _.forEach(values, (mapValue, mapKey) => {
            toInclude[mapKey] = _.get(dataPoint, mapValue) || mapValue;
        });

        const columnData = columns.map((col) => {
            if (_.isString(col.field))
                return {
                    args: { value: _.get(dataPoint, col.field) },
                    columnArgs: col.args,
                };
            const mappedData = _.map(col.field.args, (arg, key) => {
                let fallbackToString = true;
                if (arg[0] === `~`) {
                    arg = arg.substr(1);
                    fallbackToString = false;
                }

                if (arg === '')
                    return {
                        [key]: dataPoint,
                    };

                return {
                    [key]: _.isUndefined(_.get(dataPoint, arg))
                        ? fallbackToString
                            ? arg
                            : ''
                        : _.get(dataPoint, arg),
                };
            });
            const args = _.assign.apply(_, mappedData);
            const component = col.field.component;

            return {
                args,
                component,
                columnArgs: col.args,
            };
        });

        return {
            data: columnData,
            ...toInclude,
        };
    });

    return {
        ...args,
        data: { ...data, columnNames, [outputKey || 'rowData']: rowData },
    };
};

DataTable.args = {
    dataSet: ArgTypes.string,
    outputKey: ArgTypes.string,
    values: ArgTypes.json,
    columns: ArgTypes.json,
};

export default DataTable;
