import { type } from 'os';
import { Threshold } from '../../api/statusApi';
import { RAGState } from '../../types';
import { SemanticCOLORS } from 'semantic-ui-react';

export const convertToRag = (value: number, threshold: Threshold): RAGState => {
    const inverted = threshold.inverted || false;

    if (value >= threshold.upper) {
        return inverted ? 'EXCEPTION' : 'POSITIVE';
    }

    if (!threshold.lower) {
        return inverted ? 'POSITIVE' : 'EXCEPTION';
    }

    if (value < threshold.upper && value >= threshold.lower) {
        return 'CAUTION';
    }
    if (value < threshold.lower) {
        return inverted ? 'POSITIVE' : 'EXCEPTION';
    }
};

export const convertRAGStateToColor = (ragState: RAGState): SemanticCOLORS => {
    switch (ragState) {
        case 'POSITIVE':
            return 'green';
        case 'CAUTION':
            return 'yellow';
        case 'EXCEPTION':
            return 'red';
        default:
            return 'grey';
    }
};
