import React from 'react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import { Label } from 'semantic-ui-react';

const DaySelector = ({ label, weekdays, selectedDays, handleDayChanged }) => {
    const getDayColor = (dayIndex) => {
        return _.includes(selectedDays, dayIndex) ? 'blue' : null;
    };
    return (
        <React.Fragment>
            {label ? <label>{label}</label> : null}
            <Label.Group circular>
                {weekdays.map((weekday) => {
                    const { index, key } = weekday;
                    return (
                        <Label
                            as="a"
                            color={getDayColor(index)}
                            key={index}
                            index={index}
                            size="large"
                            name="repeatDays"
                            onClick={(e, element) =>
                                handleDayChanged(e, element)
                            }
                        >
                            {key}
                        </Label>
                    );
                })}
            </Label.Group>
        </React.Fragment>
    );
};

DaySelector.propTypes = {
    label: PropTypes.string,
    weekdays: PropTypes.array,
    selectedDays: PropTypes.array,
    handleDayChanged: PropTypes.func,
};

export default DaySelector;
