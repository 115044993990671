import React from 'react';

const ExecutionPlanContext = React.createContext();

export const ExecutionPlanWrapContext = (ComponentToWrap) =>
    class ExecutionPlanWrapContext extends React.Component {
        render() {
            return (
                <ExecutionPlanContext.Consumer>
                    {(context) => {
                        return <ComponentToWrap {...context} {...this.props} />;
                    }}
                </ExecutionPlanContext.Consumer>
            );
        }
    };

export default ExecutionPlanContext;
