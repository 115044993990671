import React from 'react';
import * as PropTypes from 'prop-types';
import { Button, Item } from 'semantic-ui-react';

const ExecutionPlanActionButtons = ({ handleMenuAction }) => {
    return (
        <Item>
            <Button.Group floated="right">
                <Button
                    primary
                    text="Launch Programme"
                    onClick={handleMenuAction}
                    name="launch"
                >
                    LAUNCH PROGRAMME
                </Button>
            </Button.Group>
        </Item>
    );
};

ExecutionPlanActionButtons.propTypes = {
    handleMenuAction: PropTypes.func.isRequired,
};

export default ExecutionPlanActionButtons;
