import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import toastr from 'toastr';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Dimmer, Loader, Modal, Input, Form } from 'semantic-ui-react';

import * as uiActions from '../../actions/uiActions';

class SaveUserGroupModal extends Component {
    state = {
        label: '',
        partyId: '',
        saving: false,
    };

    componentWillMount() {
        const { userGroup } = this.props;
        if (userGroup) {
            const { label, partyId } = userGroup;
            this.setState({ label, partyId });
        }
        const { id } = this.props.party;
        this.setState({ partyId: id });
    }

    handleInputChanged = (event, { name, value, checked }) => {
        this.setState({ [name]: value || checked });
    };

    createUserGroup = () => {
        const { label, partyId } = this.state;
        const { partyStateActions, userGroup } = this.props;
        this.setState({ saving: true });
        partyStateActions
            .saveUserGroup(partyId, label, userGroup ? userGroup.id : null)
            .then(this.saveUserGroupCallback)
            .catch((error) => this.saveUserGroupError(error));
    };

    saveUserGroupCallback = () => {
        const { uiActions, userGroup } = this.props;
        toastr.success(
            userGroup
                ? `Usergroup ${userGroup.label} is updated`
                : 'New usergroup is added ',
        );
        uiActions.closeModal();
    };

    saveUserGroupError = (error) => {
        toastr.error(error);
        this.setState({ saving: false });
        throw error;
    };

    render() {
        const { saving, label } = this.state;
        const { uiActions, userGroup } = this.props;

        return (
            <Modal
                open={true}
                onClose={() => uiActions.closeModal()}
                size="mini"
                closeOnDimmerClick={false}
            >
                <Dimmer active={saving} inverted>
                    <Loader disabled={!saving} />
                </Dimmer>
                <Modal.Header>
                    {userGroup
                        ? `Update ${userGroup.label} user group`
                        : 'Create new user group'}
                </Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Field>
                            <Input
                                fluid
                                placeholder="Label"
                                value={label}
                                name="label"
                                onChange={this.handleInputChanged}
                            />
                        </Form.Field>
                    </Form>
                </Modal.Content>

                <Modal.Actions>
                    <Button positive onClick={this.createUserGroup}>
                        {userGroup ? 'Update' : 'Save'}
                    </Button>
                    <Button secondary onClick={() => uiActions.closeModal()}>
                        Cancel
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

SaveUserGroupModal.propTypes = {
    parties: PropTypes.array.isRequired,
    party: PropTypes.object.isRequired,
    partyStateActions: PropTypes.object,
    uiActions: PropTypes.object,
    userGroup: PropTypes.object,
};

function mapDispatchToProps(dispatch) {
    return {
        uiActions: bindActionCreators(uiActions, dispatch),
    };
}

export default connect(null, mapDispatchToProps)(SaveUserGroupModal);
