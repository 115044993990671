import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import toastr from 'toastr';
import * as _ from 'lodash';
import { Header, Segment } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import SearchSort from '../common/SearchSort';

import TicketsSplitView from './TicketsSplitView';
import TicketWrapper from './Ticket';
import UserWrapper from '../user/User';
import DashboardContainer from '../dashboard/DashboardContainer';
import useQueryString from '../../utils/hooks/useQueryString';
import { getQueryStringValue } from '../../utils/QueryString';
import useDebounce from '../../utils/hooks/useDebounce';

import * as listFiltersActions from '../../actions/listFiltersActions';

import './TicketsPage.scss';

const orderOptions = [
    { key: 'label', text: 'Label' },
    { key: 'executionLabel', text: 'Programme' },
    {
        key: 'assignedPartyLabel',
        text: 'Assigned Party',
    },
    {
        key: 'assignedPrincipalLabel',
        text: 'Assignee',
    },
];

const TicketsPage = (props) => {
    const { stateFilters, history, ticketStateActions } = props;
    const { pathname } = { ...history.location };
    const { filters: reduxFilters } = { ...stateFilters[pathname] };
    const { search: locationSearch } = window.location;

    const [tickets, setTickets] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useQueryString(reduxFilters || {});
    const [totalTickets, setTotalTickets] = useState(0);
    const [ticketsPerPage] = useState(15);
    const [currentRequest, setCurrentRequest] = useState(null);
    const [isInitial, setIsInitial] = useState(true);
    const [searchValue, setSearchValue] = useState('');
    const debouncedFilters = useDebounce(filters, 300);

    const totalPages = Math.ceil(totalTickets / ticketsPerPage);

    const {
        ProgramIds,
        PartyIds,
        OwnerPartyIds,
        assignedIds,
        workflowStatusIds,
        workflowStatusTypeIds,
        search,
        sortOrder,
        sortItem,
        page = [1],
    } = debouncedFilters;
    const [activePageString] = page;
    const activePage = Number(activePageString);

    useEffect(() => {
        checkUrlParams();
    }, []);

    useEffect(() => {
        if (!isInitial) {
            loadTickets();
        }
    }, [activePage]);

    useEffect(() => {
        loadTickets(true);
    }, [
        ProgramIds,
        PartyIds,
        OwnerPartyIds,
        assignedIds,
        workflowStatusIds,
        workflowStatusTypeIds,
        search,
        activePage,
    ]);

    useEffect(() => {
        const { listFilters } = props;
        listFilters.setFilters(pathname, { ...filters, sortItem, sortOrder });
        if (sortItem && sortItem.length > 0 && !isInitial) {
            loadTickets();
        }
    }, [sortItem, sortOrder]);

    useEffect(() => {
        checkUrlParams();
    }, [locationSearch]);

    const checkUrlParams = () => {
        const queryStringValue = getQueryStringValue();
        if (!_.isEqual(filters, queryStringValue)) {
            const { listFilters } = props;
            setFilters(queryStringValue);
            setIsInitial(true);
            if (!isInitial) listFilters.setFilters(pathname, queryStringValue);
        }
    };

    const loadTickets = (updateTicketCount = false) => {
        let where = {};
        let order = '';

        setIsInitial(false);
        setLoading(true);
        let currentActivePage = activePage;
        setSearchValue(search || '');

        if (assignedIds && assignedIds.length > 0)
            _.set(where, 'assignedPrincipalId.inq', assignedIds);
        if (ProgramIds && ProgramIds.length > 0)
            _.set(where, 'executionProgramId.inq', ProgramIds);
        if (PartyIds && PartyIds.length > 0)
            _.set(where, 'assignedPartyId.inq', PartyIds);
        if (OwnerPartyIds && OwnerPartyIds.length > 0)
            _.set(where, 'ownerPartyId.inq', OwnerPartyIds);
        if (workflowStatusIds && workflowStatusIds.length > 0)
            _.set(where, 'statusId.inq', workflowStatusIds);
        if (workflowStatusTypeIds && workflowStatusTypeIds.length > 0)
            _.set(where, 'statusTypeId.inq', workflowStatusTypeIds);

        if (sortItem && sortItem.length > 0) {
            order = `${sortItem} ${sortOrder}`;
        }

        if (search && search.length > 0) {
            _.set(where, 'label', {
                like: `%${search}%`,
            });
        }

        const filter = {
            skip: (currentActivePage - 1) * ticketsPerPage,
            limit: ticketsPerPage,
            order,
            where,
        };

        const request = ticketStateActions
            .searchTickets(filter)
            .then((res) => {
                setCurrentRequest(null);
                setTickets(res.tickets);
                setLoading(false);
                if (!updateTicketCount) return;
                ticketStateActions.searchTicketsCount(where).then((res) => {
                    setTotalTickets(res.count);
                    setIsInitial(false);
                });
            })
            .catch((error) => {
                toastr.error(error);
                setLoading(false);
                setCurrentRequest(null);
            });

        if (currentRequest) {
            currentRequest.cancel();
            setCurrentRequest(null);
        }

        setCurrentRequest(request);
    };

    const handlePaginationChange = (e, { activePage }) => {
        setFilters({ ...filters, page: [activePage] });
        setIsInitial(true);
    };

    const handleFilterChange = (data) => {
        const { listFilters } = props;
        const newFiltersValue = { ...data, page: [1] };
        setIsInitial(false);
        setFilters(newFiltersValue);
        listFilters.setFilters(pathname, newFiltersValue);
    };

    const handleOrderItemClick = (e, { value }) => {
        const [order = null] = sortOrder ? [...sortOrder] : [];
        const newFilters = {
            ...filters,
            sortItem: [value],
            sortOrder: [order ? order : 'ASC'],
        };
        setFilters(newFilters);
    };

    const handleOrderFilterChange = () => {
        const [order = null] = sortOrder ? [...sortOrder] : [];
        const newSortOrder = order === 'ASC' ? 'DESC' : 'ASC';
        const newFilters = {
            ...filters,
            sortOrder: [newSortOrder],
        };
        setFilters(newFilters);
    };

    const handleSearchClick = (overrideSearchValue) => {
        setFilters({
            ...filters,
            search: _.isString(overrideSearchValue)
                ? overrideSearchValue
                : searchValue,
        });
    };

    const handleSearchValueChange = (e, { value }) => {
        setSearchValue(value);
    };

    return (
        <div className="ticket-search-page">
            <Segment basic>
                <Header size="medium" as="h2">
                    Ticket Search
                </Header>
                <DashboardContainer
                    id="956A6BC0-CF00-11E9-899E-3F2502C12A12"
                    onPropertyChange={handleFilterChange}
                    initialProperties={filters}
                    forceChartRefresh={false}
                    dashboardProperiesCanBeUpdated={true}
                />
                <SearchSort
                    sort={
                        sortItem
                            ? { item: sortItem, order: sortOrder }
                            : undefined
                    }
                    handleItemClick={handleOrderItemClick}
                    handleOrderFilterChange={handleOrderFilterChange}
                    options={orderOptions}
                    searchValue={searchValue}
                    handleSearchValueChange={handleSearchValueChange}
                    loading={loading}
                    handleSearchClick={handleSearchClick}
                />
            </Segment>

            <TicketsSplitView
                tickets={tickets}
                totalTickets={totalTickets}
                activePage={activePage}
                totalPages={totalPages}
                handlePaginationChange={handlePaginationChange}
                loading={loading}
                ticketsPerPage={ticketsPerPage}
            />
        </div>
    );
};

function mapStateToProps(state) {
    return {
        stateFilters: state.listFilters,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        listFilters: bindActionCreators(listFiltersActions, dispatch),
    };
}

TicketsPage.propTypes = {
    ticketStateActions: PropTypes.object,
    currentUser: PropTypes.object,
    history: PropTypes.object,
    listFilters: PropTypes.object,
    stateFilters: PropTypes.object,
};

export default UserWrapper(
    TicketWrapper(connect(mapStateToProps, mapDispatchToProps)(TicketsPage)),
);
