import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { Container, List } from 'semantic-ui-react';

import PermissionWrapper from './Permission';
import PermissionsNode from './PermissionNode';
import ErrorBoundary from './../common/ErrorBoundary';

class PermissionPage extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            loading: true,
        };
    }

    componentWillMount() {
        this.loadNode(this.props.match.params.id);
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.loadNode(this.props.match.params.id);
        }
    }

    loadNode = (id) => {
        this.setState({ loading: true });
        const { permissionStateActions } = this.props;
        permissionStateActions.loadPermission(id, null, 'CURRENT').then(() => {
            this.setState({ loading: false });
        });
        permissionStateActions.loadPermissionTypes();
        permissionStateActions.loadRoleDefinitions();
    };

    render() {
        const { currentPermission, permissionActions } = this.props;
        return (
            <Container>
                <List>
                    <PermissionsNode
                        node={currentPermission}
                        openCreatePermissionModal={
                            permissionActions.openCreatePermissionModal
                        }
                        addUser={permissionActions.openAddUserModal}
                        currentNodeId={this.props.match.params.id}
                    />
                </List>
            </Container>
        );
    }
}

PermissionPage.propTypes = {
    permissionStateActions: PropTypes.object,
    permissionActions: PropTypes.object,
    currentPermission: PropTypes.object,
    match: PropTypes.object.isRequired,
};

export default ErrorBoundary(PermissionWrapper(PermissionPage));
