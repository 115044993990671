import { FC, useState } from 'react';
import { Button, Form, Input, Modal } from 'semantic-ui-react';
import * as uiActions from '../../actions/uiActions';
import { useDispatch } from 'react-redux';
import PartyApi from '../../api/partyApi';
import * as toastr from 'toastr';
import { renewToken } from '../../actions/userActions';

interface AssignDelegateModalProps {
    onClose: () => void;
}

const AssignDelegateModal: FC<AssignDelegateModalProps> = ({ onClose }) => {
    const [email, setEmail] = useState('');
    const dispatch = useDispatch();

    const assignDelegate = async () => {
        try {
            await PartyApi.assignDelegate(email);
            handleClose();
        } catch (error) {
            toastr.error(error);
        }
    };

    const handleInputChanged = (
        event: React.ChangeEvent<HTMLInputElement>,
        { value }: any,
    ) => {
        setEmail(value);
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <Modal
            open={true}
            onClose={handleClose}
            size="mini"
            closeOnDimmerClick={false}
        >
            <Modal.Header>Assign delegate</Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Field required>
                        <label>Delegate name</label>
                        <Input
                            fluid
                            placeholder="Email"
                            value={email}
                            name="email"
                            onChange={handleInputChanged}
                            isRequired
                        />
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button positive onClick={assignDelegate}>
                    Save
                </Button>
                <Button secondary onClick={handleClose}>
                    Cancel
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default AssignDelegateModal;
