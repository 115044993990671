import * as _ from 'lodash';

export function addRoleToPermissions(permissions, role) {
    if (
        permissions &&
        _.find(permissions, {
            principalId: role.principalId,
            roleDefinitionId: role.roleDefinitionId,
        })
    )
        return permissions;

    return [...(permissions || []), role];
}

export function removeRoleFromPermissions(permissions, role) {
    const newPermissions = _.filter(permissions, (permission) => {
        return !(
            permission.principalId === role.principalId &&
            permission.roleDefinitionId === role.roleDefinitionId
        );
    });
    return newPermissions;
}
export function changeRoleInPermissions(permissions, role) {
    const newPermissions = _.map(permissions, (permission) => {
        if (permission.principalId === role.principalId)
            return { ...permission, roleDefinitionId: role.roleDefinitionId };
        return permission;
    });
    return newPermissions;
}
