import React, { Component } from 'react';
import { Form, Button, Icon } from 'semantic-ui-react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';

import PrincipalFilter from '../common/PrincipalFilter';
import { PermissionContextConsumer } from '../../context/PermissionContext';
import Select from '../common/fields/Select';

import './AddUserPermission.scss';

class AddUserPermission extends Component {
    state = {
        currentPrincipal: null,
        currentPrincipalRoleId: null,
        loading: false,
        saving: false,
    };

    searchHandler = (event, data) => {
        const { value } = data;
        this.setState({ currentPrincipal: value });
    };

    dropdownHandler = (event, { value }) => {
        this.setState({ currentPrincipalRoleId: value });
    };

    addUserHandler = () => {
        const {
            permissionActions,
            structuralNodeId,
            roleDefinitionId = null,
            onAdd,
        } = this.props;
        const { currentPrincipal, currentPrincipalRoleId } = this.state;

        if (onAdd) {
            onAdd({
                principalId: currentPrincipal.id,
                principal: currentPrincipal,
                roleDefinitionId: currentPrincipalRoleId || roleDefinitionId,
            });

            return;
        }

        this.setState({ saving: true });
        permissionActions
            .addPrincipalRole(structuralNodeId, {
                principalId: currentPrincipal.id,
                structuralNodeId,
                roleDefinitionId: currentPrincipalRoleId || roleDefinitionId,
            })
            .then(() => {
                this.setState({
                    currentPrincipal: null,
                    currentPrincipalRoleId: null,
                    saving: false,
                });
            });
    };

    render() {
        const { currentPrincipal, loading, currentPrincipalRoleId, saving } =
            this.state;
        const {
            includeSuperUser = false,
            showRoleSelect = true,
            roleDefinitionId = null,
            exceptPrincipal,
        } = this.props;
        let { roleDefinitions } = this.props;

        if (!roleDefinitions) return <div>no roleDefinitions</div>;
        if (!includeSuperUser)
            roleDefinitions = roleDefinitions.filter(
                (role) => role.id !== 'Superuser',
            );
        return (
            <Form className="add-user-permission-form">
                <Form.Group>
                    <Form.Field width={7}>
                        <label>User or Usergroup:</label>
                        <PrincipalFilter
                            value={currentPrincipal}
                            onChange={(value) =>
                                this.searchHandler(null, { value })
                            }
                            label="Select user or usergroup"
                            loading={loading}
                            includeDefaultGroups={true}
                            exceptPrincipal={exceptPrincipal}
                        />
                    </Form.Field>
                    {showRoleSelect && (
                        <Select
                            name="roleDefinitions"
                            label="Role"
                            options={roleDefinitions}
                            value={currentPrincipalRoleId}
                            onChange={this.dropdownHandler}
                            width={6}
                        />
                    )}

                    <Form.Field
                        width={3}
                        className="add-user-permission-button"
                    >
                        <Button
                            icon
                            labelPosition="right"
                            onClick={this.addUserHandler}
                            disabled={
                                !(
                                    (!_.isEmpty(currentPrincipal) &&
                                        currentPrincipalRoleId) ||
                                    roleDefinitionId
                                ) || saving
                            }
                            loading={saving}
                        >
                            Add
                            <Icon name="plus" />
                        </Button>
                    </Form.Field>
                </Form.Group>
            </Form>
        );
    }
}

AddUserPermission.propTypes = {
    roleDefinitions: PropTypes.array,
    includeSuperUser: PropTypes.bool,
    permissionActions: PropTypes.object,
    structuralNodeId: PropTypes.number,
    permissionStateActions: PropTypes.object,
    roleDefinitionId: PropTypes.string,
    showRoleSelect: PropTypes.bool,
    exceptPrincipal: PropTypes.array,
    onAdd: PropTypes.func,
};

export default PermissionContextConsumer(AddUserPermission);
