import React from 'react';
import * as PropTypes from 'prop-types';
import { Menu } from 'semantic-ui-react';

ActivityFormMenu.propTypes = {
    onChange: PropTypes.func,
    activeItem: PropTypes.string,
};

function ActivityFormMenu(props) {
    const { activeItem, onChange } = props;
    return (
        <Menu fluid pointing className="wrapping">
            <Menu.Item
                name="mainDetails"
                onClick={onChange}
                active={activeItem === `mainDetails`}
            >
                <span>Main Details</span>
            </Menu.Item>
            <Menu.Item
                name="formBuilder"
                onClick={onChange}
                active={activeItem === `formBuilder`}
            >
                <span>Form Builder</span>
            </Menu.Item>
            <Menu.Item
                name="thresholds"
                onClick={onChange}
                active={activeItem === `thresholds`}
            >
                <span>Thresholds</span>
            </Menu.Item>
            <Menu.Item
                name="otherDetails"
                onClick={onChange}
                active={activeItem === `otherDetails`}
            >
                <span>Other Details</span>
            </Menu.Item>
            <Menu.Item
                name="workflowSettings"
                onClick={onChange}
                active={activeItem === `workflowSettings`}
            >
                <span>Workflow Settings</span>
            </Menu.Item>
            <Menu.Item
                name="Meta"
                onClick={onChange}
                active={activeItem === `Meta`}
            >
                <span>Meta</span>
            </Menu.Item>
        </Menu>
    );
}

export default ActivityFormMenu;
