import { FC, useEffect, useState } from 'react';
import {
    Button,
    Divider,
    Form,
    Icon,
    Label,
    Modal,
    Popup,
    Segment,
    Select,
    Table,
} from 'semantic-ui-react';

import style from './LaunchGovernanceProgrammeModal.module.css';
import * as uiActions from '../../../actions/uiActions';
import { useDispatch } from 'react-redux';
import ConfirmWrapper from '../../common/ConfirmWrapper';
import { useAppSelector } from '../../../actions/store';
import { loadExecutionPlans } from '../../../actions/executionPlanActions';
import ApplicableDateSelect from '../../executionPlan/ApplicableDateSelect';
import { FieldErrors, checkValidation } from '../../../utils/Validation';
import ValidationSchema from '../../../utils/ValidationSchema';
import { APPLICABLE_DATE_TYPES } from '../../../utils/ExecutionPlanLaunchConsts';
import executionApi from '../../../api/executionApi';
import { Entity } from '../../../types';
import toastr from 'toastr';
import EntityApi from '../../../api/entityApi';
import convertStringToBoolean from '../../../utils/convertStringToBoolean';
import Text from '../../common/fields/Text';

interface LaunchGovernanceProgrammeModalProps {
    confirmPopupActions: any;
}

const LaunchGovernanceProgrammeModal: FC<
    LaunchGovernanceProgrammeModalProps
> = ({ confirmPopupActions }) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [partyProducts, setPartyProducts] = useState([]);
    const [chosenProducts, setChosenProducts] = useState([]);
    const [productLabel, setProductLabel] = useState('');

    const executionPlans = useAppSelector((state) => state.executionPlans);
    const config = useAppSelector((state) => state.constants.options);
    const currentUser = useAppSelector((state) => state.currentUser);

    const { showConfirmDialog } = confirmPopupActions;
    const handleCloseModal = () => {
        dispatch(uiActions.closeModal());
    };

    const [formData, setFormData] = useState({
        isComan: false,
        applicableDate: null,
        executionPlanId: null,
        lastGovernance: null,
    });
    const [fieldErrors, setFieldErrors] = useState<FieldErrors>();
    useEffect(() => {
        if (executionPlans.length === 0) {
            dispatch(loadExecutionPlans());
        }
        const fetchProducts = async () => {
            setIsLoading(true);
            try {
                const partyProductsRes = await EntityApi.getPartyProducts(
                    currentUser?.details?.partyId,
                );
                if (partyProductsRes) {
                    setPartyProducts(partyProductsRes);
                }
                setIsLoading(false);
            } catch (error) {
                toastr.error(error);
                setIsLoading(false);
            }
        };
        fetchProducts();
    }, []);

    useEffect(() => {
        setChosenProducts(
            chosenProducts.filter((p) => {
                return !isProductAlreadyLaunched(p);
            }),
        );
    }, [formData.applicableDate]);

    const toggleUserClicked = (type: 'individual' | 'grouped' | 'combined') => {
        setFieldErrors(null);

        const validation = checkValidation(
            formData,
            ValidationSchema.governanceProgramme,
        );

        if (!validation.valid) {
            setFieldErrors(validation.errors);
            return;
        }
        showConfirmDialog('Are you sure?', () => toggleUserConfirm(type));
    };

    const toggleUserConfirm = async (
        type: 'individual' | 'grouped' | 'combined',
    ) => {
        try {
            setIsLoading(true);
            if (type === 'grouped' || type === 'combined') {
                await executionApi.bulkLaunchGovernanceProgramme({
                    applicableDate: formData.applicableDate,
                    executionPlanId: formData.executionPlanId,
                    isComan: formData.isComan,
                    entityList: chosenProducts.reduce(
                        (acc, curr) => [...acc, curr.entity.id],
                        [],
                    ),
                    executionLabel: productLabel,
                    isCombined: type === 'combined' ? true : false,
                });
            }
            if (type === 'individual') {
                await executionApi.launchGovernanceProgramme({
                    applicableDate: formData.applicableDate,
                    executionPlanId: formData.executionPlanId,
                    entityList: chosenProducts.reduce(
                        (acc, curr) => [...acc, curr.entity.id],
                        [],
                    ),
                });
            }
            handleCloseModal();
        } catch (error) {
            toastr.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleInputChanged = (event, { name, value, checked }) => {
        setFormData({
            ...formData,
            [name]: value ?? checked,
        });
    };

    const executionPlanOptions = executionPlans.map((executionPlan) => ({
        key: executionPlan.id,
        value: executionPlan.id,
        text: `${executionPlan.label} - ${executionPlan.ownerParty?.label}`,
    }));

    if (
        config.executionPlanDefaultGovernanceId &&
        config.executionPlanDefaultGovernanceLabel &&
        !executionPlanOptions.find(
            (executionPlan) =>
                executionPlan.key === config.executionPlanDefaultGovernanceId,
        )
    ) {
        executionPlanOptions.push({
            key: config.executionPlanDefaultGovernanceId,
            value: config.executionPlanDefaultGovernanceId,
            text: config.executionPlanDefaultGovernanceLabel,
        });
    }

    const handleAddProduct = (product: any) => {
        if (!!chosenProducts.find((p) => p.entityId === product.entityId))
            return;
        setChosenProducts([...chosenProducts, product]);
    };

    const handleDeleteProduct = (product: any) => {
        setChosenProducts(
            chosenProducts.filter((p) => {
                return p.entityId !== product.entityId;
            }),
        );
    };

    const isProductAlreadyLaunched = (product) => {
        if (
            product.governanceProgrammes &&
            product.governanceProgrammes.includes(
                new Date(formData.applicableDate).getFullYear(),
            )
        ) {
            return true;
        }
        return false;
    };

    return (
        <Modal open={true} onClose={handleCloseModal}>
            <Modal.Header>Launch Governance Programme</Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Field
                        name="applicableDate"
                        control={ApplicableDateSelect}
                        value={formData.applicableDate}
                        error={
                            !!fieldErrors?.applicableDate
                                ? {
                                      content:
                                          fieldErrors?.applicableDate.toString(),
                                  }
                                : null
                        }
                        applicableDateTypeId={APPLICABLE_DATE_TYPES.YEAR}
                        applicableDate={formData.applicableDate}
                        handleFormDataChange={handleInputChanged}
                        required={true}
                    />
                    <Form.Field
                        control={Select}
                        name="executionPlanId"
                        options={executionPlanOptions}
                        error={
                            !!fieldErrors?.executionPlanId
                                ? {
                                      content:
                                          fieldErrors?.executionPlanId.toString(),
                                  }
                                : null
                        }
                        label="Select Execution Plan"
                        value={formData.executionPlanId}
                        required={true}
                        onChange={handleInputChanged}
                    />
                </Form>
                <Divider />
                <div className={`${style.TableContainer} theme-visrisk`}>
                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>
                                    Product Name
                                </Table.HeaderCell>
                                <Table.HeaderCell>Reference</Table.HeaderCell>
                                <Table.HeaderCell>Class</Table.HeaderCell>
                                <Table.HeaderCell>Group</Table.HeaderCell>
                                <Table.HeaderCell>
                                    Last Governance
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    Co Manufacturer
                                </Table.HeaderCell>
                                <Table.HeaderCell>Is active</Table.HeaderCell>
                                <Table.HeaderCell collapsing></Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {partyProducts.map((product, index) => {
                                return (
                                    <Table.Row key={index}>
                                        <Table.Cell>
                                            {product.executionTicketSetlabel}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {product.productReference}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {product.productClass}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {product.productGroup}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {product.latestGovernanceProgramme}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {product.coManufacturerId}
                                        </Table.Cell>
                                        <Table.Cell collapsing>
                                            {convertStringToBoolean(
                                                product.productActive,
                                            ) ? (
                                                <Icon
                                                    color="green"
                                                    name="check"
                                                />
                                            ) : (
                                                <Icon color="red" name="x" />
                                            )}
                                        </Table.Cell>
                                        <Table.Cell collapsing>
                                            {!!chosenProducts.find(
                                                (p) =>
                                                    p.entity.id ===
                                                    product.entity.id,
                                            ) ||
                                            isProductAlreadyLaunched(
                                                product,
                                            ) ? (
                                                <Icon
                                                    color="green"
                                                    name="check"
                                                />
                                            ) : (
                                                <Button
                                                    loading={isLoading}
                                                    icon
                                                    onClick={() =>
                                                        handleAddProduct(
                                                            product,
                                                        )
                                                    }
                                                >
                                                    <Icon name="add circle" />
                                                </Button>
                                            )}
                                        </Table.Cell>
                                    </Table.Row>
                                );
                            })}
                        </Table.Body>
                    </Table>
                </div>

                {chosenProducts.length > 0 && (
                    <Segment>
                        {chosenProducts.map((chosenProduct, index) => (
                            <Label
                                key={`${index}: ${chosenProduct.entityId}`}
                                image
                                size="large"
                            >
                                {chosenProduct.executionTicketSetlabel}
                                <Icon
                                    name="delete"
                                    onClick={() =>
                                        handleDeleteProduct(chosenProduct)
                                    }
                                />
                            </Label>
                        ))}

                        {chosenProducts.length > 1 && (
                            <>
                                <Divider></Divider>
                                <Form>
                                    <Text
                                        label="Governance Programme name"
                                        value={productLabel}
                                        onChange={(_, { value }) =>
                                            setProductLabel(value)
                                        }
                                        name="productLabel"
                                        debounce={false}
                                    />
                                </Form>
                            </>
                        )}
                    </Segment>
                )}
            </Modal.Content>

            <Modal.Actions>
                <Popup
                    trigger={
                        <Button
                            onClick={() => toggleUserClicked('individual')}
                            loading={isLoading}
                            disabled={isLoading || chosenProducts.length === 0}
                        >
                            Individual
                        </Button>
                    }
                >
                    One Programme per product - One Ticket per product - One
                    Entity/product per ticket
                </Popup>
                <Popup
                    trigger={
                        <Button
                            onClick={() => toggleUserClicked('grouped')}
                            loading={isLoading}
                            disabled={isLoading || chosenProducts.length === 0}
                        >
                            Grouped
                        </Button>
                    }
                >
                    One Programme- One Ticket per product - One Entity/product
                    per ticket
                </Popup>
                <Popup
                    trigger={
                        <Button
                            onClick={() => toggleUserClicked('combined')}
                            loading={isLoading}
                            disabled={isLoading || chosenProducts.length === 0}
                        >
                            Combined
                        </Button>
                    }
                >
                    One Programme- One Ticket - all Entities/products on one
                    ticket
                </Popup>
            </Modal.Actions>
        </Modal>
    );
};

export default ConfirmWrapper(LaunchGovernanceProgrammeModal);
