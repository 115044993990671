export const SIDEBAR_COMPONENTS = {
    TICKET_LIST_SIDEBAR: 'TICKET_LIST_SIDEBAR',
    METRIC_RESULT_VIEWER: 'METRIC_RESULT_VIEWER',
    MORE_INFO_SIDEBAR: 'MORE_INFO_SIDEBAR',
    FOLLOW_UP_SIDEBAR: 'FOLLOW_UP_SIDEBAR',
    RELATIONSHIP_INFO_SIDEBAR: 'RELATIONSHIP_INFO_SIDEBAR',
    TICKET_READING_VIEW_SIDEBAR: 'TICKET_READING_VIEW_SIDEBAR',
    FIELD_OPTIONS_DESCRIPTION_VIEW_SIDEBAR_PROPS:
        'FIELD_OPTIONS_DESCRIPTION_VIEW_SIDEBAR_PROPS',
    /* other sidebars */
};

export default SIDEBAR_COMPONENTS;
