import React from 'react';
import * as PropTypes from 'prop-types';
import { Button, Icon, Card, Label } from 'semantic-ui-react';
import Viewer from '../common/fields/TUIWrapperViewer';

const WorkflowListItem = ({ workflow, onSelect }) => {
    return (
        <Card>
            <Card.Content>
                <Card.Header>{workflow.label}</Card.Header>
                <Card.Meta>
                    <Viewer initialValue={workflow.description} />
                </Card.Meta>
                <Card.Description />
                <Card.Content extra>
                    {workflow.ownerParty && (
                        <Label as="a" color="grey" image>
                            {workflow.ownerParty.label}
                        </Label>
                    )}

                    {onSelect && (
                        <Button
                            primary
                            floated="right"
                            onClick={() => onSelect(workflow)}
                        >
                            Choose this Workflow
                            <Icon name="right chevron" />
                        </Button>
                    )}
                </Card.Content>
            </Card.Content>
        </Card>
    );
};

WorkflowListItem.propTypes = {
    workflow: PropTypes.object.isRequired,
    onSelect: PropTypes.func,
};

export default WorkflowListItem;
