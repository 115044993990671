import apiTools from './apiTools';

class ConstantApi {
    static getWorkflowStatusTypes() {
        const filter = {};
        return apiTools
            .get('WorkflowStatusTypes', { filter })
            .then((response) => {
                return response.data;
            });
    }
    static getWorkflowTransitionConditionTypes() {
        const filter = {};
        return apiTools
            .get('WorkflowTransitionConditionTypes', { filter })
            .then((response) => {
                return response.data;
            });
    }
    static getWorkflowTransitionPostFunctionTypes() {
        const filter = {};
        return apiTools
            .get('WorkflowTransitionPostFunctionTypes', { filter })
            .then((response) => {
                return response.data;
            });
    }
    static getPartyRelationshipTypes() {
        const filter = { include: ['fields'] };
        return apiTools
            .get('PartyRelationshipTypes', { filter })
            .then((response) => {
                return response.data;
            });
    }
    static saveAdminThreshold(threshold, thresholdId) {
        const apiMethod = thresholdId ? apiTools.patch : apiTools.post;

        const data = thresholdId
            ? { ...threshold, id: thresholdId }
            : threshold;

        return apiMethod(`AvailableRiskThresholds`, data).then((response) => {
            return response.data;
        });
    }

    static removeAdminThreshold(thresholdId) {
        return apiTools
            .delete(`AvailableRiskThresholds/${thresholdId}`)
            .then((response) => {
                return response.data;
            });
    }

    static getAvailableRiskThresholds(filter = {}) {
        return apiTools
            .get(`AvailableRiskThresholds`, { filter })
            .then((response) => {
                return response.data;
            });
    }
}

export default ConstantApi;
