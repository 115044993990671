import apiTools from './apiTools';

class ProgrammeApi {
    static getProgrammeStatuses() {
        return apiTools.get('ProgramStatuses').then((response) => {
            return response.data;
        });
    }

    static getProgrammes() {
        return apiTools
            .get('Programmes', {
                filter: {
                    order: 'createdAt DESC',
                    where: {
                        programStatusId: {
                            neq: 'DEACTIVATED',
                        },
                    },
                    include: ['status', 'creator', 'ownerParty'],
                },
            })
            .then((response) => {
                return response.data;
            });
    }

    static getProgramme(id, includeFields = false) {
        return apiTools
            .get(`Programmes/${id}`, {
                filter: {
                    include: [
                        {
                            relation: 'activitySets',
                            scope: {
                                include: [
                                    {
                                        activitiesLink: {
                                            activity: includeFields
                                                ? ['status', 'fields']
                                                : ['status'],
                                        },
                                    },
                                    {
                                        relation: 'activities',
                                        scope: {
                                            include: includeFields
                                                ? ['status', 'fields']
                                                : ['status'],
                                        },
                                    },
                                ],
                            },
                        },
                        {
                            relation: 'fields',
                            scope: { include: ['fieldType'] },
                        },
                        'categories',
                        'status',
                    ],
                },
            })
            .then((response) => {
                return response.data;
            });
    }

    static launchProgramme(id) {
        return apiTools.post(`/Programmes/${id}/execute`).then((response) => {
            return response.data;
        });
    }

    static saveProgramme(programme) {
        const apiMethod = programme.id ? apiTools.patch : apiTools.post;
        return apiMethod(`Programmes`, programme).then((response) => {
            return response.data;
        });
    }
}

export default ProgrammeApi;
