import Text from '@brallum/vidende/src/components/common/fields/Text';
import * as _ from 'lodash';
import { FC, useState } from 'react';
import { Button, Form, Message } from 'semantic-ui-react';

export enum RESET_PASSWORD_STEPS {
    GET_VERIFICATION_TYPE = 'GET_VERIFICATION_TYPE',
    RESET_PASSWORD = 'RESET_PASSWORD',
    PHONE_REQUEST = 'PHONE_REQUEST',
    PHONE_NUMBER = 'PHONE_NUMBER',
    SMS = 'SMS',
}

interface ResetPasswordFormProps {
    currentStep: RESET_PASSWORD_STEPS;
    loading: boolean;
    validationErrors: any;
    verifySMSError: any;
    verifyTelephoneError: any;
    resetPasswordError: any;
    getUserVerificationError: any;
    formData: {
        email: string;
        verificationCode: string;
        telephone: string;
        password: string;
        confirmPassword: string;
    };
    onGetUserVerification: () => void;
    onChange: (
        name:
            | 'email'
            | 'password'
            | 'confirmPassword'
            | 'verificationCode'
            | 'telephone',
        value: string,
    ) => void;
    onVerifySMSCode: (arg: boolean) => void;
    onPhoneRequest: () => void;
    onResetPassword: () => void;
    resetForm: () => void;
}

const ResetPasswordForm: FC<ResetPasswordFormProps> = ({
    currentStep,
    loading,
    validationErrors,
    verifySMSError,
    verifyTelephoneError,
    resetPasswordError,
    getUserVerificationError,
    formData,
    onGetUserVerification,
    onChange,
    resetForm,
    onVerifySMSCode,
    onPhoneRequest,
    onResetPassword,
}) => {
    if (currentStep === RESET_PASSWORD_STEPS.GET_VERIFICATION_TYPE) {
        return (
            <Form className="form-login" onSubmit={onGetUserVerification}>
                <Text
                    label="Email"
                    value={formData.email}
                    onChange={(_, { value }) => onChange('email', value)}
                    name="email"
                    errors={validationErrors}
                    debounce={false}
                />
                <Button disabled={loading} loading={loading} type="submit">
                    Submit
                </Button>
                {getUserVerificationError && (
                    <Message error>
                        {getUserVerificationError}
                        <br />
                        Click{' '}
                        <a href="#" onClick={resetForm}>
                            here
                        </a>{' '}
                        to return to the login page.
                    </Message>
                )}
            </Form>
        );
    }
    if (
        currentStep === RESET_PASSWORD_STEPS.SMS ||
        currentStep === RESET_PASSWORD_STEPS.PHONE_NUMBER
    ) {
        return (
            <Form
                className="form-phone-request"
                onSubmit={() => onVerifySMSCode(true)}
                error={!!verifySMSError}
            >
                <Message>
                    Please enter the verification code sent to your phone
                    number.
                </Message>
                <Text
                    label="Verification Code"
                    value={formData.verificationCode}
                    onChange={(_, { value }) =>
                        onChange('verificationCode', value)
                    }
                    name="verificationCode"
                    debounce={false}
                />
                <Button disabled={loading} loading={loading}>
                    Submit
                </Button>
                {verifySMSError && (
                    <Message error>
                        {verifySMSError}
                        <br />
                        Click{' '}
                        <a href="#" onClick={resetForm}>
                            here
                        </a>{' '}
                        to return to the login page.
                    </Message>
                )}
            </Form>
        );
    }
    if (currentStep === RESET_PASSWORD_STEPS.PHONE_REQUEST) {
        return (
            <Form
                className="form-phone-request"
                onSubmit={onPhoneRequest}
                error={!!verifyTelephoneError}
            >
                <Message>
                    Please enter your phone number to receive a verification
                    code, you will only need to do this once.
                    <br />
                    Please include your country code with no spaces, e.g.
                    +441234567890.
                </Message>
                <Text
                    label="Telephone Number"
                    value={formData.telephone}
                    onChange={(_, { value }) => onChange('telephone', value)}
                    name="telephone"
                    debounce={false}
                />
                <Button type="submit" loading={loading} disabled={loading}>
                    Submit
                </Button>
                {verifyTelephoneError && (
                    <Message error>
                        {verifyTelephoneError}
                        <br />
                        Click{' '}
                        <a href="#" onClick={resetForm}>
                            here
                        </a>{' '}
                        to return to the login page.
                    </Message>
                )}
            </Form>
        );
    }
    if (currentStep === RESET_PASSWORD_STEPS.RESET_PASSWORD) {
        return (
            <Form className="form-reset-password" onSubmit={onResetPassword}>
                <Text
                    label="Email"
                    value={formData.email}
                    onChange={(_, { value }) => onChange('email', value)}
                    name="email"
                    debounce={false}
                    errors={validationErrors}
                />
                <Text
                    label="New Password"
                    value={formData.password}
                    onChange={(_, { value }) => onChange('password', value)}
                    name="newPassword"
                    type="password"
                    debounce={false}
                    errors={validationErrors}
                />
                <Text
                    label="Confirm Password"
                    value={formData.confirmPassword}
                    onChange={(_, { value }) =>
                        onChange('confirmPassword', value)
                    }
                    name="confirmPassword"
                    type="password"
                    debounce={false}
                    errors={validationErrors}
                />
                <Button disabled={loading} loading={loading}>
                    Submit
                </Button>
                {resetPasswordError && (
                    <Message error content={resetPasswordError} />
                )}
            </Form>
        );
    }
};

export default ResetPasswordForm;
