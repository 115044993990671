import React from 'react';
import * as PropTypes from 'prop-types';
import { Header, Segment } from 'semantic-ui-react';

import AttachmentsListContainer from '../common/attachments/AttachmentsListContainer';
import { ExecutionContextConsumer } from '../../context/ExecutionContext';

const ExecutionAttachmentList = (props) => {
    const {
        executionStateActions,
        executionActions,
        execution,
        currentExecution,
        userHasAttachFilesPermission,
        attachmentsConstants,
    } = props;
    const executionNow = execution || currentExecution;
    if (!userHasAttachFilesPermission) return null;
    return (
        <Segment>
            <Header as="h3">Programme Attachments</Header>
            <AttachmentsListContainer
                modelStateActions={executionStateActions}
                currentModel={executionNow}
                modelActions={executionActions}
                attachmentsConstants={attachmentsConstants}
            />
        </Segment>
    );
};

ExecutionAttachmentList.propTypes = {
    execution: PropTypes.object,
    currentExecution: PropTypes.object,
    executionStateActions: PropTypes.object,
    executionActions: PropTypes.object,
    openFileDialog: PropTypes.func,
    userHasAttachFilesPermission: PropTypes.bool,
    openAddAttachmentDialog: PropTypes.func,
    attachmentsConstants: PropTypes.object,
};

export default ExecutionContextConsumer(ExecutionAttachmentList);
