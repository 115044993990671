import apiTools from './apiTools';
import * as MODELS from '../constants/models';

class GenericApi {
    static uploadAttachment(modelType, id, file, progressCallback) {
        const url =
            modelType === MODELS.EXECUTION
                ? `Executions/${id}/uploadattachments`
                : `ExecutionTickets/${id}/uploadattachments`;
        return apiTools.upload(url, file, progressCallback).then((response) => {
            return response;
        });
    }
    static getAttachments(filter = {}) {
        return apiTools
            .get(`Attachments`, {
                filter: {
                    include: ['creator'],
                    ...filter,
                },
            })
            .then((response) => {
                return response.data;
            });
    }
    static uploadAttachmentDetails(attachment) {
        return apiTools
            .patch(`Attachments/${attachment.id}`, attachment)
            .then((response) => {
                return response.data;
            });
    }
    static uploadExternalAttachment(modelType, id, attachment) {
        const url =
            modelType === MODELS.EXECUTION
                ? `Executions/${id}/addExternalAttachments`
                : `ExecutionTickets/${id}/addExternalAttachments`;
        return apiTools.post(url, attachment).then((response) => {
            return response.data;
        });
    }
}
export default GenericApi;
