import * as _ from 'lodash';
import ArgTypes from '../ArgTypes';

const sort = function (args) {
    const { dataSet, sortBy, data } = args;
    const dataToSort = args.data[dataSet];

    let filteredData = dataToSort;

    if (sortBy && sortBy.length) {
        filteredData = _.orderBy(dataToSort, ...sortBy);
    }

    return { ...args, data: { ...data, [dataSet]: filteredData } };
};

sort.args = {
    dataSet: ArgTypes.string,
    sortBy: ArgTypes.json,
};

export default sort;
