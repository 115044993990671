import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import * as _ from 'lodash';
import toastr from 'toastr';
import { connect } from 'react-redux';

import * as uiActions from '../../../actions/uiActions';
import * as attachmentStateActions from '../../../actions/attachmentActions';
import { extractFunctions } from '../../../utils/React';
import AttachmentContext from '../../../context/AttachmentContext';
import { menuTabs } from '../../../constants/addAttachments';

function AttachmentWrapper(ComponentToWrap) {
    class Attachment extends Component {
        state = {
            search: '',
            selectedAttachments: [],
            activeTab: menuTabs.RECENT,
            loading: false,
        };

        componentDidMount = () => {
            const filter = {
                limit: 10,
                order: 'createdAt DESC',
            };
            this.loadExistingAttachments(filter);
        };

        loadExistingAttachments = (filter = {}) => {
            const { attachmentStateActions } = this.props;
            this.setState({ loading: true });
            attachmentStateActions.loadAttachments(filter).then(() => {
                this.setState({ loading: false });
            });
        };

        removeValue = (attachment) => {
            const { selectedAttachments } = this.state;
            const newSelectedAttachments = [...selectedAttachments];
            const index = _.indexOf(newSelectedAttachments, attachment);
            newSelectedAttachments.splice(index, 1);
            this.setState({ selectedAttachments: newSelectedAttachments });
        };

        addValue = (attachment) => {
            const { selectedAttachments } = this.state;
            this.setState({
                selectedAttachments: [...selectedAttachments, attachment],
            });
        };

        handleAddAttachmentClick = () => {
            const { selectedAttachments } = this.state;
            const { currentModel, modelStateActions } = this.props;
            modelStateActions
                .addExistingAttachment(currentModel, selectedAttachments)
                .then(() => {
                    uiActions.closeModal();
                })
                .catch((e) => toastr.error(e));
        };

        handleSearchValueChange = (e, { value }) => {
            this.setState({ search: value }, () => {
                if (value.length > 2) {
                    this.throttledSearch();
                }
            });
        };

        throttledSearch = _.debounce(() => {
            this.setState({ loading: true });
            const { search } = this.state;
            const filter = {
                where: {
                    originalFileName: {
                        like: `%${search}%`,
                    },
                },
            };
            this.loadExistingAttachments(filter);
        }, 500);

        uploadNewAttachment = () => {
            const { openFileDialog } = this.props;
            openFileDialog();
        };

        handleTabClick = (e, { name }) => {
            const activeTab = name;
            this.setState({ activeTab });
            if (activeTab === menuTabs.RECENT) {
                const filter = {
                    limit: 10,
                    order: 'createdAt DESC',
                };
                this.loadExistingAttachments(filter);
            }
            if (activeTab === menuTabs.SEARCH) {
                const { search } = this.state;
                const filter = {
                    where: {
                        originalFileName: {
                            like: `%${search}%`,
                        },
                    },
                };
                this.loadExistingAttachments(filter);
            }
        };

        render() {
            const {
                currentModel,
                modelStateActions,
                modelType,
                maxFileSize,
                currentUser,
                attachmentsConstants,
                attachmentStateActions,
                attachmentsData,
            } = this.props;
            const values = {
                attachmentActions: extractFunctions(this),
                currentModel,
                modelStateActions,
                modelType,
                maxFileSize,
                currentUser,
                attachmentsConstants,
                attachmentStateActions,
                attachmentsData,
                ...this.state,
            };
            return (
                <AttachmentContext.Provider value={values}>
                    <ComponentToWrap {...values} {...this.props} />
                </AttachmentContext.Provider>
            );
        }
    }

    Attachment.propTypes = {
        openFileDialog: PropTypes.func,
        currentModel: PropTypes.object,
        modelStateActions: PropTypes.object,
        modelType: PropTypes.string,
        maxFileSize: PropTypes.number,
        currentUser: PropTypes.object,
        attachmentsConstants: PropTypes.object,
        attachmentStateActions: PropTypes.object,
        attachmentsData: PropTypes.array,
        uiActions: PropTypes.object,
    };

    function mapStateToProps(state) {
        const { constants } = state;
        const attachmentsConstants = {
            attachmentClassification: constants.attachmentClassification,
            attachmentType: constants.attachmentType,
        };
        return {
            attachmentsConstants,
            attachmentsData: state.attachments.data,
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            uiActions: bindActionCreators(uiActions, dispatch),
            attachmentStateActions: bindActionCreators(
                attachmentStateActions,
                dispatch,
            ),
        };
    }
    return connect(mapStateToProps, mapDispatchToProps)(Attachment);
}

export default AttachmentWrapper;
