import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import { mapToOptions } from '../../utils/React';
import { Form, Select } from 'semantic-ui-react';
import UserSearch from '../common/search/UserSearch';
import RelationshipSearch from '../common/search/RelationshipSearch';
import partyApi from '../../api/partyApi';
import PartyLabel from '../common/labels/PartyLabel';
import UserLabel from '../common/labels/UserLabel';

export default class ExecutionPlanParties extends Component {
    constructor(props) {
        super(props);
        this.state = {
            parties: [],
        };
    }

    componentDidMount() {
        partyApi.getParties().then((result) => {
            this.setState({
                parties: result,
                loading: false,
            });
        });
    }

    render() {
        const { options, onChange, needsReview, editable = true } = this.props;
        const { parties } = this.state;
        return (
            <Form>
                <Form.Group widths="equal">
                    <Form.Field>
                        <label>Owner Party</label>
                        {editable ? (
                            <Form.Field
                                control={Select}
                                options={mapToOptions(parties, null, true)}
                                placeholder="Owner Party"
                                onChange={(event, data) => {
                                    onChange(
                                        event,
                                        Object.assign({}, data, {
                                            value: _.find(parties, {
                                                id: data.value,
                                            }),
                                        }),
                                    );
                                }}
                                value={options.ownerParty.id}
                                name="ownerParty"
                                search
                            />
                        ) : (
                            <PartyLabel party={options.ownerParty} />
                        )}
                    </Form.Field>
                    <Form.Field>
                        <label>Owner Party User</label>
                        {editable ? (
                            <UserSearch
                                onChange={onChange}
                                value={
                                    options.ownerPartyUser
                                        ? options.ownerPartyUser.id
                                        : null
                                }
                                disabled={!options.ownerParty}
                                filterByParty={options.ownerParty.id}
                                name="ownerPartyUser"
                                returnUserObject={true}
                                sortItemsAlphabetically={true}
                            />
                        ) : (
                            <UserLabel user={options.ownerPartyUser} />
                        )}
                    </Form.Field>
                </Form.Group>

                <Form.Group widths="equal">
                    <Form.Field>
                        <label>Assign Party</label>
                        {editable ? (
                            <RelationshipSearch
                                disabled={!options.ownerParty}
                                party={options.ownerParty}
                                onChange={onChange}
                                value={
                                    options.assignedParty
                                        ? options.assignedParty.id
                                        : null
                                }
                                name="assignedParty"
                                returnPartyObject={true}
                                placeholder="Assigned Party"
                                sortItemsAlphabetically={true}
                            />
                        ) : (
                            <PartyLabel party={options.assignedParty} />
                        )}
                    </Form.Field>
                    <Form.Field>
                        <label>Assign Party User</label>
                        {editable ? (
                            <UserSearch
                                onChange={onChange}
                                value={
                                    options.assignedPartyUser
                                        ? options.assignedPartyUser.id
                                        : null
                                }
                                disabled={!options.assignedParty}
                                filterByParty={
                                    options.assignedParty
                                        ? [
                                              options.assignedParty.id,
                                              options.ownerParty.id,
                                          ]
                                        : null
                                }
                                name="assignedPartyUser"
                                returnUserObject={true}
                                sortItemsAlphabetically={true}
                            />
                        ) : (
                            <UserLabel user={options.assignedPartyUser} />
                        )}
                    </Form.Field>
                </Form.Group>

                {needsReview && (
                    <Form.Group widths="equal">
                        <Form.Field>
                            <label>Review Party</label>

                            {editable ? (
                                <RelationshipSearch
                                    disabled={!options.ownerParty.id}
                                    party={options.ownerParty}
                                    onChange={onChange}
                                    value={
                                        options.reviewParty
                                            ? options.reviewParty.id
                                            : null
                                    }
                                    name="reviewParty"
                                    returnPartyObject={true}
                                    placeholder="Review Party"
                                    sortItemsAlphabetically={true}
                                />
                            ) : (
                                <PartyLabel party={options.reviewParty} />
                            )}
                        </Form.Field>
                        <Form.Field>
                            <label>Review Party User</label>
                            {editable ? (
                                <UserSearch
                                    onChange={onChange}
                                    value={
                                        options.reviewPartyUser
                                            ? options.reviewPartyUser.id
                                            : null
                                    }
                                    disabled={!options.reviewParty}
                                    filterByParty={
                                        options.reviewParty
                                            ? [
                                                  options.reviewParty.id,
                                                  options.ownerParty.id,
                                              ]
                                            : null
                                    }
                                    name="reviewPartyUser"
                                    returnUserObject={true}
                                    sortItemsAlphabetically={true}
                                />
                            ) : (
                                <UserLabel user={options.reviewPartyUser} />
                            )}
                        </Form.Field>
                    </Form.Group>
                )}
            </Form>
        );
    }
}

ExecutionPlanParties.propTypes = {
    options: PropTypes.object,
    onChange: PropTypes.func,
    needsReview: PropTypes.bool,
    editable: PropTypes.bool,
};
