import React, { Component } from 'react';
import ExecutionList from './../execution/ExecutionList';
import ExecutionWrapper from './../execution/Execution';
import * as PropTypes from 'prop-types';
import FilterAndPaginate from '../common/FilterAndPaginate';
import Pagination from '../common/Pagination';

class PaginatedExecutionList extends Component {
    componentDidMount() {
        const { executionStateActions, filter } = this.props;
        executionStateActions.loadExecutions(filter);
    }

    render() {
        const {
            paginationOptions,
            paginatedItems,
            handleSortChange,
            handlePaginationChange,
            handleAddFilter,
            currentFilters,
            removeFilterValues,
        } = this.props;
        return (
            <React.Fragment>
                <ExecutionList
                    executions={paginatedItems || []}
                    paginationOptions={paginationOptions}
                    handleSortChange={handleSortChange}
                    handleAddFilter={handleAddFilter}
                    currentFilters={currentFilters}
                    removeFilterValues={removeFilterValues}
                />
                <Pagination
                    activePage={paginationOptions.activePage}
                    onPageChange={handlePaginationChange}
                    totalPages={paginationOptions.totalPages}
                    style={{ float: 'right' }}
                />
            </React.Fragment>
        );
    }
}

PaginatedExecutionList.propTypes = {
    executionStateActions: PropTypes.object.isRequired,
    paginationOptions: PropTypes.object,
    paginatedItems: PropTypes.array,
    handleSortChange: PropTypes.func,
    handlePaginationChange: PropTypes.func,
    handleAddFilter: PropTypes.func,
    filter: PropTypes.object,
    currentFilters: PropTypes.array,
    removeFilterValues: PropTypes.func,
};

function mapOptions(props) {
    return {
        items: props.executions,
    };
}

export default ExecutionWrapper(
    FilterAndPaginate(mapOptions)(PaginatedExecutionList),
);
