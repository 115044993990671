import React from 'react';
import * as PropTypes from 'prop-types';
import { Menu } from 'semantic-ui-react';

const ActivityActionButtons = ({ handleMenuAction }) => {
    return (
        <Menu>
            <Menu.Item
                icon="edit"
                text="Edit"
                onClick={handleMenuAction}
                name="edit"
            />
            <Menu.Item
                icon="copy"
                text="Clone"
                onClick={handleMenuAction}
                name="clone"
            />
        </Menu>
    );
};

ActivityActionButtons.propTypes = {
    handleMenuAction: PropTypes.func.isRequired,
};

export default ActivityActionButtons;
