import React from 'react';
import * as PropTypes from 'prop-types';
import { Icon, Table } from 'semantic-ui-react';
import UserLabel from '../common/labels/UserLabel';
import PartyLabel from '../common/labels/PartyLabel';
import Moment from 'react-moment';

const UserGroupListItem = ({ party }) => {
    return (
        <Table.Row>
            <Table.Cell width={2}>
                <PartyLabel party={party} />
            </Table.Cell>
            <Table.Cell width={1}>{party.ref}</Table.Cell>
            <Table.Cell width={2}>
                <Moment>{party.createdAt}</Moment>
            </Table.Cell>
            <Table.Cell width={2}>
                {party.creator ? <UserLabel user={party.creator} /> : null}
            </Table.Cell>
            <Table.Cell width={2}>{party.groupLabel}</Table.Cell>
            <Table.Cell width={1}>
                {party.status === 'ACTIVE' ? (
                    <Icon color="green" name="check" />
                ) : (
                    <Icon color="red" name="x" />
                )}
            </Table.Cell>
        </Table.Row>
    );
};

UserGroupListItem.propTypes = {
    party: PropTypes.object.isRequired,
};

export default UserGroupListItem;
