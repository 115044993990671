/* eslint-disable react/no-did-mount-set-state */

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';

import * as paginationFiltersUtils from '../../utils/PaginationFilters';
import { MODAL_COMPONENTS } from './ModalRoot';
import * as uiActions from '../../actions/uiActions';
import * as listFiltersActions from '../../actions/listFiltersActions';

// This is a terrible solution, this needs refactoring
function getNewState(props, state, options) {
    const { stateFilters } = props;
    const currentFilter = _.get(
        stateFilters,
        `${options.filterStateKey}.filters`,
    );
    const filters = currentFilter ? currentFilter : [];
    const { paginationOptions } = state;
    const { items } = options;
    const filteredItems = paginationFiltersUtils.applyFilter(filters, items);
    const sortedItems = paginationOptions.sortedBy
        ? paginationFiltersUtils.sortItems(filteredItems, paginationOptions)
        : filteredItems;
    const paginatedItems = paginationFiltersUtils.applyPagination(
        sortedItems,
        paginationOptions,
    );

    const totalPages = sortedItems
        ? Math.ceil(sortedItems.length / paginationOptions.recordsPerPage)
        : 0;

    return {
        ...options,
        sortedItems,
        paginationOptions: {
            ...paginationOptions,
            totalPages,
        },
        filteredItems,
        paginatedItems,
    };
}

function FilterAndPaginate(mapOptions) {
    return (ComponentToWrap) => {
        class FilterAndPaginate extends Component {
            static getDerivedStateFromProps(props, state) {
                const filterStateKey = `${props.path}/${ComponentToWrap.name}`;
                return getNewState(props, state, {
                    ...mapOptions(props),
                    filterStateKey,
                });
            }

            state = {
                items: [],
                sortedItems: [],
                filteredItems: [],
                paginationOptions: {
                    activePage: 1,
                    recordsPerPage: 20,
                    sortedBy: null,
                    direction: 'ascending',
                },
            };

            componentDidMount() {
                const { path, stateFilters } = this.props;
                this.filterStateKey = `${path}/${ComponentToWrap.name}`;
                const paginationOptions = _.get(
                    stateFilters,
                    `${this.filterStateKey}.pagination`,
                );
                if (paginationOptions && !this.options.disableFilterPersist) {
                    this.setState({
                        paginationOptions,
                    });
                }
                if (this.options.disableFilterPersist) {
                    this.resetFilter();
                }
            }

            options = mapOptions(this.props);

            refreshState = () => {
                const { props, state } = this;
                const options = mapOptions(props);
                this.setState(getNewState(props, state, options));
            };

            handleSortChange = (clickedColumn) => {
                const { paginationOptions } = this.state;
                const newPaginationOptions =
                    paginationFiltersUtils.generateNewPaginationOptions(
                        paginationOptions,
                        clickedColumn,
                    );
                this.setState(
                    {
                        paginationOptions: newPaginationOptions,
                    },
                    () => {
                        this.setState({
                            ...this.updateState(this.state, this.props),
                            paginationOptions: this.resetPagination(),
                        });
                    },
                );
            };

            handlePaginationChange = (event, data) => {
                const { listFilters } = this.props;
                const { sortedItems, paginationOptions } = this.state;
                const newPaginationOptions = {
                    ...paginationOptions,
                    activePage: data.activePage,
                };

                listFilters.setPagination(
                    this.filterStateKey,
                    newPaginationOptions,
                );

                const paginatedItems = paginationFiltersUtils.applyPagination(
                    sortedItems,
                    newPaginationOptions,
                );

                this.setState({
                    paginationOptions: newPaginationOptions,
                    paginatedItems,
                });
            };

            handleAddFilter = (
                property,
                friendlyName,
                isSearchable,
                isFilterable,
            ) => {
                const { applyFilter, resetFilter, applySearch } = this;
                const { uiActions } = this.props;
                const { items } = this.state;
                const filters = this.getFilterValue();
                let values = [];
                const propertyIsPresent = _.some(
                    _.map(items, (item) => _.get(item, `${property}`)),
                );
                if (propertyIsPresent) {
                    const itemWithProperty = _.find(
                        items,
                        (item) => item[property],
                    );
                    const isObject = _.isObject(
                        _.get(itemWithProperty, `${property}`),
                    );
                    const uniqueValues = _.uniqBy(
                        items,
                        isObject ? `${property}.label` : `${property}`,
                    );
                    values = _.map(uniqueValues, (item) => {
                        const label = _.get(
                            item,
                            isObject ? `${property}.label` : `${property}`,
                        );
                        const id = isObject
                            ? _.get(item, `${property}.id`)
                            : null;
                        let value = label;
                        if (id) value = { id, label };
                        return value;
                    });
                }

                const handleCloseModal = () => {
                    uiActions.closeModal();
                };

                uiActions.showModal({
                    modalType: MODAL_COMPONENTS.ADD_FILTER_MODAL,
                    modalProps: {
                        property,
                        friendlyName,
                        values,
                        applyFilter,
                        resetFilter,
                        filters,
                        isSearchable,
                        isFilterable,
                        applySearch,
                        onClose: handleCloseModal,
                    },
                });
            };

            applyFilter = (newFilters, property) => {
                const { listFilters } = this.props;
                const filters = this.getFilterValue();
                const updatedFilters = paginationFiltersUtils.addNewFilter(
                    filters,
                    newFilters,
                    property,
                );
                listFilters.setFilters(this.filterStateKey, updatedFilters);
                this.setState(
                    {
                        paginationOptions: this.resetPagination(),
                    },
                    () => {
                        this.refreshState();
                    },
                );
            };

            resetFilter = () => {
                const { listFilters } = this.props;
                listFilters.setFilters(this.filterStateKey, []);
                let newState = {
                    paginationOptions: this.resetPagination(),
                };
                this.setState(newState, () => {
                    this.refreshState();
                });
            };

            removeFilterValues = (property) => {
                const { stateFilters } = this.props;
                const filters = _.get(
                    stateFilters,
                    `${this.filterStateKey}.filters`,
                );
                const activeFilters = _.filter(filters, { property: property });
                const activeFiltersLength = activeFilters.length;
                if (activeFiltersLength)
                    activeFiltersLength === filters.length
                        ? this.resetFilter()
                        : this.removeFilters(property);
            };

            removeFilters = (property) => {
                const { listFilters, stateFilters } = this.props;
                const filters = _.get(
                    stateFilters,
                    `${this.filterStateKey}.filters`,
                );
                const updatedFilters = _.filter(
                    filters,
                    (filterValue) => filterValue.property != property,
                );
                listFilters.setFilters(this.filterStateKey, updatedFilters);
                let newState = {
                    paginationOptions: this.resetPagination(),
                };
                this.setState(newState, () => {
                    this.refreshState();
                });
            };

            updateState = (state, props) => {
                const options = mapOptions(props);
                return getNewState(props, state, options);
            };

            resetPagination = () => {
                const { paginationOptions } = this.state;
                const { listFilters } = this.props;
                const updatedPaginationOptions = { ...paginationOptions };
                updatedPaginationOptions.activePage = 1;

                listFilters.setPagination(
                    this.filterStateKey,
                    updatedPaginationOptions,
                );
                return updatedPaginationOptions;
            };

            getFilterValue = () => {
                const { stateFilters } = this.props;
                return (
                    _.get(stateFilters, `${this.filterStateKey}.filters`) || []
                );
            };

            render() {
                const filters = this.getFilterValue();
                return (
                    <ComponentToWrap
                        handleSortChange={this.handleSortChange}
                        handleAddFilter={this.handleAddFilter}
                        handlePaginationChange={this.handlePaginationChange}
                        currentFilters={filters}
                        removeFilterValues={this.removeFilterValues}
                        {...this.state}
                        {...this.props}
                    />
                );
            }
        }

        FilterAndPaginate.propTypes = {
            uiActions: PropTypes.object,
            stateFilters: PropTypes.object,
            path: PropTypes.string,
            listFilters: PropTypes.object,
            displayedColumns: PropTypes.array,
        };

        function mapStateToProps(state) {
            return {
                stateFilters: state.listFilters,
                path: state.router?.location.pathname,
            };
        }

        function mapDispatchToProps(dispatch) {
            return {
                uiActions: bindActionCreators(uiActions, dispatch),
                listFilters: bindActionCreators(listFiltersActions, dispatch),
            };
        }
        return connect(mapStateToProps, mapDispatchToProps)(FilterAndPaginate);
    };
}

export default FilterAndPaginate;
