import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import {
    Container,
    Dimmer,
    Grid,
    Header,
    Loader,
    Divider,
} from 'semantic-ui-react';
import * as _ from 'lodash';
import toastr from 'toastr';

import ExecutionPlanWrapper from './ExecutionPlan';
import ExecutionPlanDetails from './ExecutionPlanDetails';
import ExecutionPlanActionButtons from './ExecutionPlanActionButtons';
import ExecutionPlanBreadcrumbs from './ExecutionPlanBreadcrumbs';
import ExecutionPlanMenu from './ExecutionPlanMenu';
import ExecutionPlanActivitySets from './ExecutionPlanActivitySets';
import PaginatedExecutionList from './PaginatedExecutionList';
import PaginatedScheduledLaunchesList from './PaginatedScheduledLaunchesList';
import { MODAL_COMPONENTS } from './../common/ModalRoot';
import ErrorBoundary from './../common/ErrorBoundary';
import WorkflowWrapper from '../workflow/Workflow';
import ExecutionPlanSettings from './ExecutionPlanSettings';
import PermissionWrapper from '../permissions/Permission';

class ExecutionPlanPage extends Component {
    state = {
        loading: false,
        activeItem: '',
    };

    componentWillMount() {
        const {
            match,
            executionPlanStateActions,
            entityStateActions,
            workflowStateActions,
            history,
        } = this.props;
        this.setState({ loading: true });
        executionPlanStateActions
            .loadExecutionPlan(match.params.id, 'CURRENT')
            .then((props) => {
                const { executionPlan } = props;

                entityStateActions.loadEntities();

                executionPlanStateActions
                    .getPartyRelationships(
                        executionPlan.ownerPartyId,
                        executionPlan.assignedPartyId,
                    )
                    .then(() => {
                        workflowStateActions.loadWorkflows().then(() => {
                            this.setState({ loading: false });
                        });
                    });
            })
            .catch((error) => {
                this.setState({ loading: false });
                error.response.status === 404
                    ? history.replace('/notFoundPage')
                    : toastr.error(error);
            });
    }

    launchThisProgrammeModal = () => {
        const { label } = this.props.executionPlan.programme;
        const { executionPlan, uiActions } = this.props;
        uiActions.showModal({
            modalType: MODAL_COMPONENTS.LAUNCH_EXECUTION_PLAN_MODAL,
            modalProps: {
                executionPlan,
                label,
            },
        });
    };

    handleMenuAction = (event, { name }) => {
        switch (name) {
            case 'launch':
                this.launchThisProgrammeModal();
                break;
        }
    };

    menuItemChanged = (activeItem) => {
        this.setState({ activeItem });
    };

    createSetsArray = () => {
        const {
            programme: { activitySets, categories },
        } = this.props.executionPlan;
        const { activeItem } = this.state;
        return categories.length
            ? _.filter(activitySets, {
                  categoryId: activeItem,
              })
            : activitySets;
    };

    getNumberOfQuestions = () => {
        const {
            executionPlan: {
                activitySets,
                programme: {
                    activitySets: activitySetsCurrentProgramme,
                    categories,
                },
            },
        } = this.props;

        const result = _.map(activitySets, (activitySet) => {
            return _.find(activitySetsCurrentProgramme, {
                id: activitySet.activitySetId,
            });
        });

        return categories.length
            ? _.map(categories, (category) => {
                  const categoryId = category.id;
                  const numberOfQuestions = _.filter(result, (item) => {
                      if (item) return item.categoryId === category.id;
                  }).length;
                  return { categoryId, numberOfQuestions };
              })
            : [{ categoryId: 0, numberOfQuestions: result.length }];
    };

    getFilterObject = () => {
        const { match } = this.props;
        return { where: { executionPlanId: match.params.id } };
    };

    mainRender = () => {
        const { activeItem } = this.state;
        const { workflows } = this.props;
        switch (activeItem) {
            case 'runningProgrammes':
                return (
                    <React.Fragment>
                        <Header as="h2">Running programmes</Header>
                        <p>Current running programmes using this plan</p>
                        <PaginatedExecutionList
                            filter={this.getFilterObject()}
                            {...this.props}
                        />
                    </React.Fragment>
                );
            case 'scheduledLaunches':
                return (
                    <React.Fragment>
                        <Header as="h2">Scheduled Launches</Header>
                        <PaginatedScheduledLaunchesList {...this.props} />
                    </React.Fragment>
                );
            case 'settings':
                return (
                    <React.Fragment>
                        <Header as="h2">Settings</Header>
                        <ExecutionPlanSettings workflows={workflows.list} />
                    </React.Fragment>
                );
            default: {
                const {
                    programme: { categories },
                } = this.props.executionPlan;
                const currentItem = categories
                    ? _.find(categories, {
                          id: activeItem,
                      })
                    : null;
                const currentMenuItem = currentItem ? currentItem.label : '';
                return (
                    <ExecutionPlanActivitySets
                        activitySetsName={currentMenuItem}
                        items={this.createSetsArray()}
                        activeCategoryId={activeItem}
                    />
                );
            }
        }
    };

    render() {
        const { loading } = this.state;

        if (loading) {
            return (
                <Container fluid>
                    <Dimmer active={loading} inverted>
                        <Loader disabled={!loading} />
                    </Dimmer>
                </Container>
            );
        }
        const {
            programme: { categories },
            label,
        } = this.props.executionPlan;

        return (
            <Container fluid>
                <Dimmer active={loading} inverted>
                    <Loader disabled={!loading} />
                </Dimmer>
                <Grid>
                    <ExecutionPlanBreadcrumbs />
                    <Grid.Row columns={1}>
                        <Grid.Column width={16}>
                            <Divider fitted />
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row columns={1}>
                        <Grid.Column width={16}>
                            <Header>{label}</Header>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row columns={2}>
                        <Grid.Column width={6} floated="left">
                            <ExecutionPlanDetails />
                        </Grid.Column>
                        <Grid.Column width={6} floated="right">
                            <ExecutionPlanActionButtons
                                handleMenuAction={this.handleMenuAction}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Divider fitted />
                    <Grid.Row columns={2} className="flex-width">
                        <Grid.Column floated="left" className="fixed">
                            <ExecutionPlanMenu
                                categories={
                                    categories.length
                                        ? categories
                                        : [{ label: 'Uncategorised', id: 0 }]
                                }
                                numberOfQuestions={this.getNumberOfQuestions()}
                                menuItemChanged={this.menuItemChanged}
                            />
                        </Grid.Column>
                        <Grid.Column width={12} floated="right">
                            {this.mainRender()}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        );
    }
}

ExecutionPlanPage.propTypes = {
    executionPlan: PropTypes.object,
    executionPlanStateActions: PropTypes.object,
    programmeStateActions: PropTypes.object,
    match: PropTypes.object,
    executionPlanActions: PropTypes.object,
    history: PropTypes.object,
    uiActions: PropTypes.object,
    workflowStateActions: PropTypes.object,
    workflows: PropTypes.object,
};

export default ErrorBoundary(
    PermissionWrapper(ExecutionPlanWrapper(WorkflowWrapper(ExecutionPlanPage))),
);
