import { useEffect, useState } from 'react';
import { Button, Dimmer, Loader, Modal } from 'semantic-ui-react';
import toastr from 'toastr';
import { Entity } from '../../../types';
import * as uiActions from '../../../actions/uiActions';
import { useDispatch } from 'react-redux';
import SaveEntityModalForm, { EntityFormData } from './SaveEntityModalForm';
import entityApi from '../../../api/entityApi';
import { findIndex } from 'lodash';
import { useAppSelector } from '../../../actions/store';
import { loadParty } from '../../../actions/partyActions';
import { partyRelationshipsFilter } from './CreateEntityModal';
import convertStringToBoolean from '../../../utils/convertStringToBoolean';
import ExecutionPlanApi from '../../../api/executionPlanApi';

interface Props {
    entityId: string;
    type: string;
    onSave: () => void;
}

const SaveEntityModal = ({ entityId, type, onSave }: Props) => {
    const dispatch = useDispatch();
    const [entity, setEntity] = useState<Entity>();
    const [saving, setSaving] = useState(false);
    const [formData, setFormData] = useState<EntityFormData>({
        label: entity?.label,
        entries: entity?.entries,
        launchExecution: false,
        executionPlanId: null,
    });
    const [executionPlans, setExecutionPlans] = useState([]);

    const party = useAppSelector((state) => state.currentUser.details.party);

    useEffect(() => {
        dispatch(loadParty(party.id, partyRelationshipsFilter));
        const fetchExecutionPlansForProduct = async () => {
            try {
                const response =
                    await ExecutionPlanApi.getExecutionPlansByCategory(
                        'FairValueDistributor',
                    );
                setExecutionPlans(response);
            } catch (error) {
                toastr.error(error);
            }
        };
        fetchExecutionPlansForProduct();
    }, []);

    useEffect(() => {
        if (entity || !entityId) return;

        const fetchEntity = async () => {
            const result = await entityApi.getEntity(entityId);
            setEntity(result);
            const coManufactured = convertStringToBoolean(
                result.entries?.find(
                    (entry) => entry.entityTypeFieldId === 'PRODUCT_ISCOMAN',
                )?.value,
            );

            setFormData({
                label: result.label,
                entries: result.entries,
                launchExecution: false,
                executionPlanId: null,
                coManufactured,
                distributorId:
                    result.entries?.find(
                        (entry) => entry.entityTypeFieldId === 'PRODUCT_COMAN',
                    )?.value || null,
            });
        };

        fetchEntity();
    }, []);

    const handleSaveEntity = async () => {
        try {
            setSaving(true);
            await entityApi.updateEntity(entity, formData);
            toastr.success('Entity Saved');
            setSaving(false);
            onSave();
            handleClose();
        } catch (error) {
            toastr.error(error);
            setSaving(false);
        }
    };

    const handlefieldChange = ({ name, value, checked }) => {
        console.log({ name, value });
        const index = findIndex(formData.entries, {
            entityTypeFieldId: name,
        });
        if (index !== -1) {
            formData.entries[index].value = value;
            setFormData({ ...formData, entries: formData.entries });
        } else {
            const newValue = {
                entityTypeFieldId: name,
                value: value,
            };
            setFormData({
                ...formData,
                entries: [...formData.entries, newValue],
            });
        }
    };
    const handleInputChanged = (event, { name, value, checked }) => {
        setFormData({
            ...formData,
            [name]: value || checked,
        });
    };

    const handleOnFocus = (name) => {};

    const handleOnBlur = (name) => {};

    const handleClose = () => {
        dispatch(uiActions.closeModal());
    };

    return (
        <Modal
            open={true}
            onClose={handleClose}
            size="small"
            closeOnDimmerClick={false}
        >
            <Dimmer active={saving} inverted>
                <Loader disabled={!saving} />
            </Dimmer>
            <Modal.Header>
                {entity ? `Update ${entity.label}` : 'Create new entity'}
            </Modal.Header>
            <Modal.Content>
                {entity && (
                    <SaveEntityModalForm
                        formData={formData}
                        executionPlans={executionPlans}
                        handleInputChanged={handleInputChanged}
                        handleOnFocus={handleOnFocus}
                        handleOnBlur={handleOnBlur}
                        type={entity?.entityTypeId || type}
                        onFieldChange={handlefieldChange}
                    />
                )}
            </Modal.Content>

            <Modal.Actions>
                <Button positive onClick={handleSaveEntity}>
                    {entity ? 'Update' : 'Save'}
                </Button>
                <Button secondary onClick={handleClose}>
                    Cancel
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default SaveEntityModal;
