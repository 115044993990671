import * as types from '../constants/actionTypes';
import dashboardApi from '../api/dashboardApi';

export const fetchDashboardSchema = (id) => {
    return function (dispatch) {
        return dashboardApi
            .getDashboard(id)
            .then((schema) => {
                dispatch({
                    type: types.FETCH_DASHBOARD_SCHEMA_SUCCESS,
                    id,
                    schema,
                });
            })
            .catch(() => {
                dispatch({
                    type: types.FETCH_DASHBOARD_SCHEMA_FAILED,
                    id,
                });
            });
    };
};

export const addDashboardRow = ({ id, options = {} }) => {
    return function (dispatch) {
        return dashboardApi.addDashboardRow({ id, options }).then((row) => {
            dispatch({
                type: types.ADD_DASHBOARD_ROW_SUCCESS,
                id,
                row,
            });
        });
    };
};

export const removeDashboardRow = ({ id, rowId, sort }) => {
    return function (dispatch) {
        return dashboardApi.deleteDashboardRow({ id, rowId, sort }).then(() => {
            dispatch({
                type: types.REMOVE_DASHBOARD_ROW_SUCCESS,
                id,
                rowId,
            });
        });
    };
};

export const addDashboardColumn = ({ id, rowId, sort }) => {
    return function (dispatch) {
        return dashboardApi
            .addDashboardColumn({ id, dashboardRowId: rowId, sort })
            .then((column) => {
                dispatch({
                    type: types.ADD_DASHBOARD_COLUMN_SUCCESS,
                    id,
                    rowId,
                    column,
                });
            });
    };
};

export const removeDashboardColumn = ({ id, rowId, columnId }) => {
    return function (dispatch) {
        return dashboardApi
            .deleteDashboardColumn({ id, dashboardColumnId: columnId })
            .then(() => {
                dispatch({
                    type: types.REMOVE_DASHBOARD_COLUMN_SUCCESS,
                    id,
                    rowId,
                    columnId,
                });
            });
    };
};

export const addDashboardItem = ({
    id,
    rowId,
    columnId,
    chart,
    options,
    sort,
}) => {
    return function (dispatch) {
        return dashboardApi
            .addDashboardItem({
                id,
                dashboardColumnId: columnId,
                chartId: chart ? chart.id : null,
                options,
                sort,
            })
            .then((item) => {
                dispatch({
                    type: types.ADD_DASHBOARD_ITEM_SUCCESS,
                    id,
                    rowId,
                    columnId,
                    item,
                    chart,
                });
            });
    };
};

export const removeDashboardItem = ({ id, rowId, columnId, itemId }) => {
    return function (dispatch) {
        return dashboardApi.deleteDashboardItem({ id, itemId }).then(() => {
            dispatch({
                type: types.REMOVE_DASHBOARD_ITEM_SUCCESS,
                id,
                rowId,
                columnId,
                itemId,
            });
        });
    };
};

export const setColumnOption = ({ id, rowId, columnId, data }) => {
    const { name, value } = data;
    return {
        type: types.SET_DASHBOARD_COLUMN_OPTION,
        id,
        rowId,
        columnId,
        name,
        value,
    };
};

export const setElementOptions = (args) => {
    const { id, type, options } = args;
    switch (type) {
        case 'column': {
            const { rowId, columnId } = args;
            return {
                type: types.SET_DASHBOARD_COLUMN_OPTIONS,
                id,
                rowId,
                columnId,
                value: options,
            };
        }
        case 'row': {
            const { rowId } = args;
            return {
                type: types.SET_DASHBOARD_ROW_OPTIONS,
                id,
                rowId,
                value: options,
            };
        }
        case 'item': {
            const { rowId, columnId, itemId } = args;
            return {
                type: types.SET_DASHBOARD_ITEM_OPTIONS,
                id,
                rowId,
                columnId,
                itemId,
                value: options,
            };
        }
        case 'dashboard': {
            return {
                type: types.SET_DASHBOARD_OPTIONS,
                id,
                value: options,
            };
        }
    }
};

export const saveDashboardItemOptions = ({
    id,
    rowId,
    columnId,
    itemId,
    options,
}) => {
    return function (dispatch) {
        return dashboardApi
            .saveDashboardItem({
                id,
                itemId: itemId,
                data: {
                    options,
                },
            })
            .then(() => {
                dispatch({
                    type: types.DASHBOARD_ITEM_OPTIONS_SAVED_SUCCESS,
                    id,
                    rowId,
                    columnId,
                    itemId,
                });
            });
    };
};
export const saveDashboardColumnOptions = ({
    id,
    rowId,
    columnId,
    options,
}) => {
    return function (dispatch) {
        return dashboardApi
            .saveDashboardColumn({
                id,
                dashboardColumnId: columnId,
                data: {
                    options,
                },
            })
            .then(() => {
                dispatch({
                    type: types.DASHBOARD_COLUMN_OPTIONS_SAVED_SUCCESS,
                    id,
                    rowId,
                    columnId,
                });
            });
    };
};
export const saveDashboardRowOptions = ({ id, rowId, options }) => {
    return function (dispatch) {
        return dashboardApi
            .saveDashboardRow({
                id,
                dashboardRowId: rowId,
                data: {
                    options,
                },
            })
            .then(() => {
                dispatch({
                    type: types.DASHBOARD_ROW_OPTIONS_SAVED_SUCCESS,
                    id,
                    rowId,
                });
            });
    };
};
export const saveDashboardOptions = ({ id, options }) => {
    return function (dispatch) {
        return dashboardApi
            .saveDashboard({
                id,
                options,
            })
            .then(() => {
                dispatch({
                    type: types.DASHBOARD_OPTIONS_SAVED_SUCCESS,
                    id,
                });
            });
    };
};
