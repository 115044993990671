import React from 'react';
import { Icon, Label, SemanticICONS } from 'semantic-ui-react';
import './PrincipalLabel.scss';

interface Principal {
    id: string;
    label?: string;
    userGroupLabel?: string;
    firstName?: string;
    lastName?: string;
    userGroup?: {
        label: string;
    };
    user?: {
        label: string;
    };
    type?: string;
}

interface PrincipalLabelProps {
    principal?: Principal | null;
    onClick?: () => void;
}

const PrincipalLabel: React.FC<PrincipalLabelProps> = (props) => {
    const { principal, onClick } = props;

    if (!principal) {
        return (
            <Label as="a" color="grey" image onClick={onClick}>
                Unassigned
            </Label>
        );
    }

    const generatePrincipalText = (principal: Principal): string => {
        const { userGroupLabel, label, firstName, lastName, userGroup, user } =
            principal;
        if (label) return label;
        if (userGroupLabel) return userGroupLabel;
        if (firstName) return `${firstName} ${lastName}`;
        if (userGroup) return userGroup.label;
        if (user) return user.label;
        return 'Unknown';
    };

    const generateIconType = (principal: Principal): SemanticICONS => {
        const { type, userGroupLabel, userGroup } = principal;
        if (type && type === 'usergroup') return 'users';
        if (userGroupLabel || userGroup) return 'users';
        return 'user';
    };

    const iconName = generateIconType(principal);
    const principalText = generatePrincipalText(principal);

    return (
        <Label
            className="principal-label"
            as="a"
            color="grey"
            image
            onClick={onClick}
        >
            <Icon name={iconName} />
            &nbsp;
            {principalText}
        </Label>
    );
};

export default PrincipalLabel;
