import * as types from '../constants/actionTypes';
import tagApi from '../api/tagApi';

export function loadTags() {
    return function (dispatch) {
        return tagApi.getTags().then((tags) => {
            dispatch({
                type: types.LOAD_TAGS_SUCCESS,
                tags,
            });
        });
    };
}

export function saveTag(tag) {
    return (dispatch) => {
        return tagApi.saveTag(tag).then((response) => {
            return dispatch({
                type: types.CREATE_TAG_SUCCESS,
                tag: response.data,
            });
        });
    };
}
