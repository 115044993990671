import { useState } from 'react';
import { StatusResult } from '../../api/statusApi';
import { ExecutionTicketEntry } from '../../types';
import { orderBy } from 'lodash';
import { Checkbox, Header } from 'semantic-ui-react';
import { EntriesTable } from '../entries/EntriesTable';

type MetricResultViewAccordionProps = {
    statusResult: StatusResult;
    targetFields: string[];
};

export const MetricResultViewerAccordion = ({
    statusResult,
    targetFields,
}: MetricResultViewAccordionProps) => {
    let entries: {
        entry: ExecutionTicketEntry;
        isException?: boolean;
        isTargetField?: boolean;
    }[] = [];
    if (Array.isArray(statusResult.logicResult)) {
        entries = statusResult.logicResult
            .map((result) =>
                result.relevantEntries?.map((entry) => ({
                    entry,
                    isException:
                        result.result === 'EXCEPTION' &&
                        targetFields?.includes(entry.fieldKey),
                    isTargetField: targetFields?.includes(entry.fieldKey),
                })),
            )
            .flat();
    } else {
        const logicResult = statusResult.logicResult;
        entries = logicResult.relevantEntries?.map((entry) => ({
            entry,
            isException:
                logicResult.result === 'EXCEPTION' &&
                targetFields?.includes(entry.fieldKey),
            isTargetField: targetFields?.includes(entry.fieldKey),
        }));
    }

    const hasExceptions = entries.some((e) => e.isException);

    const [showExceptionsOnly, setShowExceptionsOnly] = useState(hasExceptions);

    if (!entries || entries.length === 0) return null;

    //remove duplicates
    let filteredEntries = entries.filter((entry, index, self) => {
        if (showExceptionsOnly && !entry.isException) return false;
        return index === self.findIndex((t) => t.entry.id === entry.entry.id);
    });

    // put target fields first
    filteredEntries = orderBy(
        filteredEntries,
        ['isTargetField', 'entry.partyId'],
        ['desc', 'asc'],
    );

    return (
        <>
            <Header>Relevant Entries</Header>
            {hasExceptions && (
                <Checkbox
                    label="Show exceptions only"
                    checked={showExceptionsOnly}
                    onChange={(e, { checked }) =>
                        setShowExceptionsOnly(checked)
                    }
                />
            )}

            <EntriesTable entries={filteredEntries} />
        </>
    );
};
