import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';

import TicketSets from '../ticket/TicketSets';
import { ExecutionContextConsumer } from '../../context/ExecutionContext';
import ExecutionTicketSet from './ExecutionTicketSet';
import { TicketContextConsumer } from '../../context/TicketContext';
import { ticketsPerExecutionPageCall } from '../../constants/config';

class ExecutionTicketSets extends Component {
    componentDidMount() {
        this.loadTickets();
    }
    componentDidUpdate(prevProps) {
        const ticketSetsPrevious = prevProps.ticketSets;
        const { ticketSets } = this.props;

        if (!_.isEqual(ticketSetsPrevious, ticketSets)) {
            this.loadTickets();
        }
    }

    loadTickets() {
        const { ticketActions, ticketSets } = this.props;
        ticketActions.loadTickets(ticketSets);
    }

    formTicketGroup = () => {
        const { ticketSets } = this.props;
        const ticketGroups = _.map(ticketSets, (ticketSet) => {
            return _.map(ticketSet.tickets, (ticket) => ticket.id);
        });
        let result = [];
        _.forEach(ticketGroups, (ticketGroup) => {
            result.push(_.chunk(ticketGroup, ticketsPerExecutionPageCall));
        });
        return this.reduceArray(_.flatten(result));
    };

    reduceArray = (result) => {
        const limit = ticketsPerExecutionPageCall;
        _.reduce(
            _.flatten(result),
            (accumulator, element, index) => {
                const prevIndex = accumulator.length - 1;
                const prevIndexLength = index
                    ? accumulator[prevIndex].length
                    : 0;
                if (
                    index &&
                    element.length <= limit &&
                    prevIndexLength <= limit &&
                    prevIndexLength + element.length <= limit
                ) {
                    accumulator[prevIndex] = [
                        ...accumulator[prevIndex],
                        ...element,
                    ];
                } else accumulator.push(element);
                return accumulator;
            },
            [],
        );
    };

    render() {
        return (
            <TicketSets
                ticketSetComponent={ExecutionTicketSet}
                {...this.props}
            />
        );
    }
}

ExecutionTicketSets.propTypes = {
    ticketActions: PropTypes.object,
    ticketSets: PropTypes.array,
    userID: PropTypes.number,
};

export default ExecutionContextConsumer(
    TicketContextConsumer(ExecutionTicketSets),
);
