import React, { useEffect, useState } from 'react';
import {
    Accordion,
    Icon,
    Loader,
    List,
    Label,
    Header,
    Table,
    Tab,
    SemanticICONS,
    Modal,
    Button,
} from 'semantic-ui-react';
import queueApi, { QueueActionType, QueueItem } from '../../api/queueApi';
import Moment from 'react-moment';
import { QueueItemDetails } from './QueueItemDetails';
import { map } from 'lodash';

interface QueueErrorViewerProps {
    queueId: string;
    numberOfFailedItems?: number;
    getQueueItems?: () => Promise<QueueItem[]>;
}

interface QueueItemErrorViewerProps {
    item: QueueItem;
    onViewDetails?: (item: QueueItem) => void;
}

const queueActionIconMap: Record<QueueActionType, SemanticICONS> = {
    CREATE_ENTITY: 'box',
    CREATE_PARTY: 'building outline',
    LAUNCH_EXECUTION: 'rocket',
    SEND_EMAIL: 'mail',
};

export const queueActionLabelMap: Record<QueueActionType, string> = {
    CREATE_ENTITY: 'Create Product',
    CREATE_PARTY: 'Create Party',
    LAUNCH_EXECUTION: 'Launch Programme',
    SEND_EMAIL: 'Send Email',
};

export const queueActionDataKeyMap: Record<QueueActionType, string> = {
    CREATE_ENTITY: 'entity',
    CREATE_PARTY: 'partyData',
    LAUNCH_EXECUTION: '',
    SEND_EMAIL: '',
};

export const QueueItemErrorViewer = ({
    item,
    onViewDetails,
}: QueueItemErrorViewerProps) => {
    const [active, setActive] = useState<boolean>(false);

    const handleAccordionClick = (e: React.MouseEvent, titleProps: any) => {
        e.stopPropagation();
        setActive((prevActive) => !prevActive);
    };

    const lastError = item.processingErrors?.[item.processingErrors.length - 1];

    return (
        <Table.Row>
            <Table.Cell collapsing>
                <Icon name={queueActionIconMap[item.actionType]} size="large" />
            </Table.Cell>
            <Table.Cell collapsing>{item.payload?.data?.index}</Table.Cell>
            <Table.Cell>{lastError && <>{lastError.message}</>}</Table.Cell>
            <Table.Cell collapsing>
                <Moment>{lastError.time}</Moment>
            </Table.Cell>
            <Table.Cell collapsing>
                <Button
                    onClick={() => {
                        onViewDetails(item);
                    }}
                >
                    Info
                </Button>
            </Table.Cell>
        </Table.Row>
    );
};

export const QueueErrorViewer = ({
    queueId,
    numberOfFailedItems,
    getQueueItems,
}: QueueErrorViewerProps) => {
    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [failedItems, setFailedItems] = useState<QueueItem[]>([]);

    const [viewedItem, setViewedItem] = useState<QueueItem>();

    const loadFailedItems = async () => {
        setLoading(true);
        try {
            const items = await getQueueItems();
            setFailedItems(items);
        } catch (error) {
            console.error('Failed to fetch failed items:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleAccordionClick = async (
        e: React.MouseEvent,
        titleProps: any,
    ) => {
        const { index } = titleProps;
        const newIndex = activeIndex === index ? null : index;
        setActiveIndex(newIndex);

        if (newIndex !== null && !loading) {
            loadFailedItems();
        }
    };

    useEffect(() => {
        if (activeIndex === 0 && !loading) {
            loadFailedItems();
        }
    }, [numberOfFailedItems]);

    const handleGenerateCSV = async () => {
        const fields = {
            label: 'Label',
            ref: 'Reference',
            line1: 'Address line 1',
            line2: 'Address line 2',
            line3: 'Address line 3',
            city: 'City',
            zipOrPostcode: 'Postal Code',
            stateProvinceCounty: 'County',
            countryId: 'Country code',
            firstName: 'Contact first name',
            lastName: 'Contact last name',
            email: 'Contact email',
        };

        let csv = map(fields).join(',') + ',Error\n';

        try {
            csv =
                csv +
                failedItems
                    .map((item) => {
                        const data =
                            item.payload?.data[
                                queueActionDataKeyMap[item.actionType]
                            ];
                        const returnData = {};
                        Object.keys(fields).forEach((key) => {
                            returnData[key] = data[key];
                        });
                        const lastError =
                            item.processingErrors?.[
                                item.processingErrors.length - 1
                            ];
                        return {
                            ...returnData,
                            error: lastError?.message,
                        };
                    })
                    .map((item) => Object.values(item).join(','))
                    .join('\n');

            const blob = new Blob([csv], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `queue_${queueId}_failed_items.csv`;
            a.click();
        } catch (error) {
            console.error('Failed to generate CSV:', error);
        }
    };

    const renderFailedItems = () => {
        return (
            <Table padded celled>
                <Table.Row>
                    <Table.HeaderCell></Table.HeaderCell>
                    <Table.HeaderCell>Row</Table.HeaderCell>
                    <Table.HeaderCell>Error</Table.HeaderCell>
                    <Table.HeaderCell>Time</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
                {failedItems?.map((item) => (
                    <QueueItemErrorViewer
                        key={item.id}
                        item={item}
                        onViewDetails={(item) => setViewedItem(item)}
                    />
                ))}
            </Table>
        );
    };

    return (
        <>
            <Accordion styled fluid>
                <Accordion.Title
                    active={activeIndex === 0}
                    index={0}
                    onClick={handleAccordionClick}
                >
                    <Icon name="dropdown" />
                    Failed Items
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 0}>
                    {failedItems &&
                        failedItems[0] &&
                        failedItems[0].actionType === 'CREATE_PARTY' && (
                            <Button onClick={handleGenerateCSV}>
                                Download Failed Rows
                            </Button>
                        )}

                    {loading && !failedItems ? (
                        <Loader active inline="centered" />
                    ) : (
                        renderFailedItems()
                    )}
                </Accordion.Content>
            </Accordion>

            <Modal open={!!viewedItem} size="small">
                <Modal.Header>Details</Modal.Header>
                <Modal.Content>
                    <QueueItemDetails item={viewedItem} />
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => setViewedItem(undefined)}>
                        Close
                    </Button>
                </Modal.Actions>
            </Modal>
        </>
    );
};
