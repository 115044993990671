import React from 'react';
import * as PropTypes from 'prop-types';
import ReactHighcharts from 'react-highcharts';
import * as _ from 'lodash';

import * as chartConfigs from './chartConfigBase';

export const getExportingOptions = (chartOptions) => {
    return {
        enabled: chartOptions?.download?.visible || false,
        buttons: {
            contextButton: {
                menuItems: [
                    chartOptions?.download?.png ? 'downloadPNG' : '',
                    chartOptions?.download?.jpg ? 'downloadJPEG' : '',
                    chartOptions?.download?.csv ? 'downloadCSV' : '',
                    chartOptions?.download?.xls ? 'downloadXLS' : '',
                ],
            },
        },
    };
};

const BarChartComponent = ({ data, chartOptions, callback }) => {
    const mergedOptions = _.merge(
        {},
        chartConfigs.stackedColumn,
        chartOptions,
        {
            plotOptions: {
                series: {
                    cursor: 'pointer',
                    point: {
                        events: {
                            click: function () {
                                if (callback) {
                                    _.debounce(() => {
                                        callback({ ...this });
                                    })();
                                }
                            },
                        },
                    },
                },
            },
            exporting: getExportingOptions(),
        },
    );

    const createChartConfig = () => {
        const barData = _.get(data, 'data.barData');
        if (!barData) return mergedOptions;
        return Object.assign({}, mergedOptions, {
            xAxis: {
                categories: barData.categories,
                ...mergedOptions.xAxis,
            },
            series: barData.series.map((series, index) => {
                if (chartOptions.seriesOptions?.[index]) {
                    return Object.assign(
                        {},
                        series,
                        chartOptions.seriesOptions[index],
                    );
                }
                return { ...series };
            }),
        });
    };

    return (
        <div className="component-dashboard-widget chart-component">
            <ReactHighcharts config={createChartConfig()} />
        </div>
    );
};

BarChartComponent.propTypes = {
    header: PropTypes.string,
    data: PropTypes.object,
    chartOptions: PropTypes.object,
    hideHeader: PropTypes.bool,
    callback: PropTypes.func,
};

export default BarChartComponent;
