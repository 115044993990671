import React from 'react';
import * as PropTypes from 'prop-types';
import { Button, Dropdown, Form, Modal } from 'semantic-ui-react';
import { keys } from 'lodash';
import { mapToOptions } from '../../utils/React';

import WidgetComponents from '../common/dashboard/DashboardWidgetComponents';

const AddDashboardItemModal = (props) => {
    const {
        open,
        charts,
        onChange,
        selectedChartId,
        closeModal,
        onSave,
        selectedChartComponent,
    } = props;

    const chartComponentOptions = keys(WidgetComponents).map((name) => ({
        key: name,
        text: name,
        value: name,
    }));

    return (
        <Modal open={open}>
            <Modal.Header>Add Dashboard Item</Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Field>
                        <Dropdown
                            placeholder="Select a chart"
                            fluid
                            selection
                            search
                            clearable
                            name="selectedChartId"
                            onChange={onChange}
                            options={mapToOptions(charts)}
                            value={selectedChartId}
                        />
                    </Form.Field>

                    <Form.Field>
                        <Dropdown
                            placeholder="Select a component"
                            fluid
                            selection
                            search
                            clearable
                            name="selectedChartComponent"
                            onChange={onChange}
                            options={chartComponentOptions}
                            value={selectedChartComponent}
                        />
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button positive onClick={onSave}>
                    Save
                </Button>
                <Button secondary onClick={closeModal}>
                    Cancel
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

AddDashboardItemModal.propTypes = {
    open: PropTypes.bool,
    charts: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    selectedChartId: PropTypes.string,
    selectedChartComponent: PropTypes.string,
    closeModal: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
};

export default AddDashboardItemModal;
