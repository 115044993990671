import React from 'react';
import * as PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

import TicketSidebarListItem from './TicketSidebarListItem';
import SortableHeaderCell from '../common/SortableHeaderCell';

const TicketsList = (props) => {
    const {
        tickets,
        handleSortChange,
        paginationOptions,
        executionLabel,
        showResolutions = false,
        relevantEntries,
        exceptionIds = [],
    } = props;
    const renderTicketSidebarList = () => {
        return (
            tickets &&
            tickets.map((ticket) => {
                const entries = relevantEntries?.filter(
                    (entry) => entry.executionTicketId === ticket.id,
                );
                const isException = exceptionIds.includes(ticket.id);
                return (
                    <TicketSidebarListItem
                        key={ticket.id}
                        ticket={ticket}
                        executionLabel={executionLabel}
                        showResolutions={showResolutions}
                        entries={entries}
                        isException={isException}
                    />
                );
            })
        );
    };
    return (
        <Table celled sortable>
            <Table.Header>
                <Table.Row>
                    <SortableHeaderCell
                        {...paginationOptions}
                        name="activity"
                        handleSortChange={handleSortChange}
                    >
                        Ticket Label
                    </SortableHeaderCell>
                    <SortableHeaderCell
                        {...paginationOptions}
                        name="execution"
                        handleSortChange={handleSortChange}
                    >
                        Programme
                    </SortableHeaderCell>
                    <SortableHeaderCell
                        {...paginationOptions}
                        name="ticketSet"
                        handleSortChange={handleSortChange}
                    >
                        Ticket Set
                    </SortableHeaderCell>
                    {/* <SortableHeaderCell
            {...paginationOptions}
            name="execution.applicableDate"
            handleSortChange={handleSortChange}
          >
            Applicable Date
          </SortableHeaderCell> */}
                    <SortableHeaderCell
                        {...paginationOptions}
                        name="execution.party.label"
                        handleSortChange={handleSortChange}
                    >
                        Party
                    </SortableHeaderCell>
                    {/* <SortableHeaderCell
            {...paginationOptions}
            name="currentStatusNode"
            handleSortChange={handleSortChange}
          >
            Status
          </SortableHeaderCell> */}
                    {/* {showResolutions && <Table.HeaderCell>Resolutions</Table.HeaderCell>} */}
                    {relevantEntries && (
                        <Table.HeaderCell>Relevant Entries</Table.HeaderCell>
                    )}
                </Table.Row>
            </Table.Header>
            <Table.Body>{renderTicketSidebarList()}</Table.Body>
        </Table>
    );
};

TicketsList.propTypes = {
    tickets: PropTypes.array.isRequired,
    relevantEntries: PropTypes.array,
    handleSortChange: PropTypes.func,
    paginationOptions: PropTypes.object,
    executionLabel: PropTypes.string,
    showResolutions: PropTypes.bool,
};

export default TicketsList;
