import apiTools from './apiTools';
import {
    loadExecutionFilter,
    loadMatrixRoleFilter,
} from '../constants/apiFilters';

class ExecutionApi {
    static getExecutions(filter = {}) {
        return apiTools
            .get(
                'ExecutionStatusCategoryViews',
                {
                    filter,
                },
                true,
            )
            .then((response) => {
                return response.data;
            });
    }

    static getExecution(id) {
        return apiTools
            .get(`Executions/${id}`, {
                filter: loadExecutionFilter,
            })
            .then((response) => {
                return response.data;
            });
    }

    static getExecutionTicketSets(partyId, ref) {
        let params = {
            filter: {
                include: [
                    'extendedTickets',
                    {
                        entities: {
                            relation: 'entries',
                            scope: {
                                include: ['field'],
                            },
                        },
                    },
                    {
                        execution: {
                            entities: {
                                relation: 'entries',
                                scope: {
                                    include: ['field'],
                                },
                            },
                        },
                    },
                ],
            },
        };
        if (ref) {
            params = {
                filter: {
                    ...params.filter,
                    where: { ref },
                },
            };
        }

        if (partyId) {
            params = {
                filter: {
                    ...params.filter,
                    where: {
                        ...params.filter.where,
                        assignedPartyId: partyId,
                    },
                },
            };
        }
        return apiTools.get(`ExecutionTicketSets`, params).then((response) => {
            return response.data;
        });
    }

    static getExecutionReport(id) {
        return apiTools
            .get(`/Executions/${id}/createWordReport`, {})
            .then((response) => {
                return response.data;
            });
    }

    static saveExecution(execution) {
        const apiMethod = execution.id ? apiTools.patch : apiTools.post;
        return apiMethod(`Executions`, execution).then((response) => {
            return response.data;
        });
    }

    static openAttachment(execution, attachmentId) {
        const url = apiTools.generateUrl(
            `Executions/${execution.id}/downloadAttachments/${attachmentId}`,
        );
        window.open(url, '_blank');
    }

    static deleteAttachment(execution, attachmentId) {
        const apiMethod = apiTools.delete;
        return apiMethod(
            `Executions/${execution.id}/attachments/${attachmentId}`,
        ).then((response) => {
            return response;
        });
    }

    static addExistingAttachment(executionId, selectedAttachments) {
        return apiTools
            .post(`Executions/${executionId}/addAttachments`, {
                id: selectedAttachments,
            })
            .then((response) => {
                return response.data;
            });
    }

    static loadMatrixRole(executionId) {
        return apiTools
            .get(`/Executions/${executionId}`, { filter: loadMatrixRoleFilter })
            .then((response) => {
                return response.data;
            });
    }

    static saveExecutionDetails(execution) {
        return apiTools
            .patch(`Executions`, {
                ...execution,
            })
            .then((response) => {
                return response.data;
            });
    }

    static getExecutionsCount(where) {
        return apiTools
            .get('ExecutionStatusCategoryViews/count', {
                where,
            })
            .then((response) => {
                return response.data;
            });
    }

    static getExecutionTicketDeadlineCount(executionId) {
        return apiTools
            .get(`/ExecutionTicketDeadline/count`, {
                where: { executionId },
            })
            .then((response) => {
                return response.data;
            });
    }

    static saveExecutionEntries(execution, entries) {
        const { id: executionId } = execution;
        const entriesWithFields = entries.filter((entry) => entry.field);
        const params = entriesWithFields.map((entry) => {
            const { value, field } = entry;
            const { id: programmeFieldId, programmeId } = field;

            return { executionId, programmeFieldId, value, programmeId };
        });
        return apiTools
            .post(`Executions/${execution.id}/entries`, params)
            .then((response) => {
                return response.data;
            });
    }

    static addFairValue(execution, distributors) {
        const params = {
            distributors,
        };
        return apiTools
            .post(`Executions/${execution.id}/addFairValue`, params)
            .then((response) => {
                return response.data;
            });
    }
    static bulkLaunchGovernanceProgramme(params) {
        return apiTools
            .post(`Executions/bulkLaunchGovernanceProgramme`, params)
            .then((response) => {
                return response.data;
            });
    }
    static launchGovernanceProgramme(params) {
        return apiTools
            .post(`Executions/launchGovernanceProgramme`, params)
            .then((response) => {
                return response.data;
            });
    }

    static getDashboards() {
        return apiTools.get(`/Dashboards`).then((response) => {
            return response.data;
        });
    }

    static importEntries(file, progressCallback, params) {
        return apiTools
            .upload(`Executions/importEntries`, file, progressCallback, params)
            .then((response) => {
                return response;
            });
    }
}

export default ExecutionApi;
