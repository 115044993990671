import { useRef } from 'react';
import * as PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';

import { formatValue } from '../../../../../utils/FormatUtils';
import { kpiValueFormats } from '../../../../../constants/config';
import DownloadDropdown from './DownloadDropdown';
import { downloadImage } from '../../../../../utils/downloadUtils';

export const SecondStyleKpiComponent = ({
    label,
    header,
    format = kpiValueFormats.Number,
    buttonText,
    link,
    value,
    currencyType,
    onClick,
    classNames = '',
    tooltip = '',
    chartOptions,
}) => {
    const ref = useRef(null);
    const handleDownoad = (format) => {
        downloadImage(ref.current, format);
    };
    return (
        <div
            ref={ref}
            className={classNames + ' second'}
            onClick={onClick}
            style={{
                cursor: onClick ? 'pointer' : '',
            }}
        >
            <DownloadDropdown
                chartOptions={chartOptions}
                onDownloadImage={handleDownoad}
                isKPI={true}
            />
            <div className="value-component-kpi">
                {formatValue(value, format, currencyType)}
            </div>
            <div className="label-component-kpi">{label || header}</div>
            {link && <Button primary>{buttonText}</Button>}
        </div>
    );
};

SecondStyleKpiComponent.propTypes = {
    link: PropTypes.string,
    buttonText: PropTypes.string,
    label: PropTypes.string,
    header: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    format: PropTypes.string,
    classNames: PropTypes.string,
    currencyType: PropTypes.string,
    onClick: PropTypes.func,
    chartOptions: PropTypes.object,
};
