import React, { Component } from 'react';
import * as PropTypes from 'prop-types';

import FilterList from './FilterList';
import { Button, Header, Segment } from 'semantic-ui-react';
import TicketPage from '../ticket/TicketPage/TicketPage';

class TicketListSidebar extends Component {
    state = {
        ticketId: null,
    };

    onItemClick = (item) => {
        const { baseConfig } = this.props;

        switch (baseConfig.model) {
            case 'Execution':
                this.props.closeSidebar();
                break;
            case 'ExecutionTicket':
                this.setState({
                    ticketId: item.id,
                });
                break;
        }
    };

    closeTicket = () => {
        this.setState({
            ticketId: null,
        });
    };

    render() {
        const { baseConfig, data, header } = this.props;
        const { ticketId } = this.state;
        if (ticketId)
            return (
                <Segment textAlign="left" basic>
                    <Button icon="arrow left" onClick={this.closeTicket} />
                    <TicketPage ticketId={ticketId} />
                </Segment>
            );
        return (
            <Segment textAlign="left" basic>
                {header && <Header size="small">{header}</Header>}
                <FilterList
                    callConfig={baseConfig}
                    data={data}
                    onItemClick={this.onItemClick}
                />
            </Segment>
        );
    }
}

TicketListSidebar.propTypes = {
    data: PropTypes.object,
    baseConfig: PropTypes.object,
    header: PropTypes.string,
    closeSidebar: PropTypes.func,
};

export default TicketListSidebar;
