import WizardContainer from './WizardContainer';
import UserWrapper from '../user/User';
import { checkFormCompleted } from '../../utils/ExecutionPlansUtils';
import toastr from 'toastr';
import ExecutionPlanWrapper from './ExecutionPlan';
import * as _ from 'lodash';
import { useAppSelector } from '../../actions/store';

type CreateExecutionPlanPageProps = {
    currentUser: any;
    executionPlanStateActions: any;
    history: any;
};

const CreateExecutionPlanPage = (props: CreateExecutionPlanPageProps) => {
    const selectedEntities = useAppSelector(
        (state) => state.entity.selectedEntities,
    );

    const steps = {
        PROGRAMME: {
            completed: false,
            current: true,
            stepNumber: 1,
            title: 'Programme',
            hideActions: true,
            description: (
                <span>
                    Choose a programme to launch
                    <br />
                </span>
            ),
            icon: 'book',
        },
        WORKFLOW: {
            completed: false,
            current: false,
            hideActions: true,
            stepNumber: 2,
            title: 'Workflow',
            description: 'Choose a workflow',
            icon: 'exchange',
        },
        PARTIES: {
            completed: false,
            current: false,
            stepNumber: 3,
            title: 'Parties & Users',
            description: 'Choose Parties and Users',
            icon: 'users',
            formValidationFunction: checkFormCompleted,
        },
        SETTINGS: {
            completed: false,
            current: false,
            stepNumber: 4,
            title: 'Settings',
            description: 'Other settings',
            icon: 'settings',
            formValidationFunction: checkFormCompleted,
        },
        CONFIRM: {
            completed: false,
            current: false,
            stepNumber: 5,
            title: 'Confirm',
            description: 'Please confirm your selection',
            icon: 'info',
            formValidationFunction: checkFormCompleted,
            showSubmitButtons: true,
            submitButtonLabel: 'Create Execution Plan',
        },
    };

    const initialChoices = {
        ownerParty:
            props.currentUser && props.currentUser.details.party
                ? props.currentUser.details.party
                : null,
        meta: {
            options: {},
        },
        addAllSets: true,
        granularPermissionTypeId: 'NONE',
        permissions: [],
    };

    const submitFunction = (wizardChoices) => {
        let formObject = {
            ownerPartyId: wizardChoices.ownerParty.id,
            ownerPartyUserId: wizardChoices.ownerPartyUser.id,
            assignedPartyId: wizardChoices.assignedParty.id,
            assignedPartyUserId: wizardChoices.assignedPartyUser.id,
            workflowId: wizardChoices.workflow.id,
            programmeId: wizardChoices.programme.id,
            meta: wizardChoices.meta,
            addAllSets: wizardChoices.addAllSets,
            permissions: wizardChoices.permissions,
            granularPermissionTypeId: wizardChoices.granularPermissionTypeId,
        };

        if (wizardChoices.needsReview) {
            formObject = Object.assign({}, formObject, {
                reviewPartyId: wizardChoices.reviewParty.id,
                reviewPartyUserId: wizardChoices.reviewPartyUser.id,
            });
        }
        if (selectedEntities.length > 0) {
            formObject = Object.assign({}, formObject, {
                meta: {
                    ...formObject.meta,
                    options: {
                        ...formObject.meta.options,
                        assignToEntities: selectedEntities,
                    },
                },
            });
        }

        return props.executionPlanStateActions
            .saveExecutionPlan(formObject)
            .then(({ executionPlan }) => {
                toastr.success('Execution Plan created');
                props.history.push(`/executionPlan/${executionPlan.id}`);
            })
            .catch((error) => {
                toastr.error(error);
                throw error;
            });
    };

    const handleChoiceChange = (name, value, wizardChoices) => {
        if (name === 'assignedParty' || name === 'reviewParty') {
            const previousParty =
                name === 'reviewParty'
                    ? wizardChoices.reviewParty
                    : wizardChoices.assignedParty;
            const roleDefinition = name === 'reviewParty' ? 'Review' : 'Assess';

            if (previousParty) {
                const newPermissions = _.filter(
                    wizardChoices.permissions,
                    (role) => {
                        const partyId = _.get(
                            role,
                            'principal.userGroup.party.id',
                        );
                        return !(
                            role.roleDefinitionId === roleDefinition &&
                            partyId === previousParty.id
                        );
                    },
                );
                wizardChoices.permissions = newPermissions;
            }
        }
        return wizardChoices;
    };

    return (
        <WizardContainer
            steps={steps}
            initialChoices={initialChoices}
            submitFunction={submitFunction}
            changeFunction={handleChoiceChange}
        />
    );
};

export default ExecutionPlanWrapper(UserWrapper(CreateExecutionPlanPage));
