export default function stringToBoolean(value: string | boolean | undefined) {
    if (typeof value === 'boolean') {
        return value;
    }

    const lowerValue = value?.toLowerCase();

    if (lowerValue === 'true') {
        return true;
    } else if (lowerValue === 'false') {
        return false;
    } else {
        return null;
    }
}
