import React from 'react';
import * as PropTypes from 'prop-types';
import { Label } from 'semantic-ui-react';

const StatusLabel = (props) => {
    const { status } = props;

    const getColor = () => {
        switch (status.toLowerCase()) {
            case 'overdue': {
                return 'red';
            }
            case 'complete': {
                return 'green';
            }
            default: {
                return 'grey';
            }
        }
    };

    return (
        <Label color={getColor()} image>
            {status}
        </Label>
    );
};

StatusLabel.propTypes = {
    status: PropTypes.string,
};

export default StatusLabel;
