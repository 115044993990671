import React, { Component } from 'react';
import { Grid, Menu } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import * as PropTypes from 'prop-types';

import ExecutionPermissionSettings from './ExecutionPermissionSettings';
import ExecutionViewMatrixSettings from './ExecutionViewMatrixSettings';
import ExecutionDetailsSettings from './ExecutionDetailsSettings';
import { ExecutionContextConsumer } from '../../context/ExecutionContext';

class ExecutionSettings extends Component {
    componentDidMount() {
        const { id, tab } = this.props.match.params;
        if (!tab)
            this.props.history.replace(`/execution/${id}/settings/details`);
    }

    renderSettingsTab = () => {
        const { history } = this.props;
        const { tab } = this.props.match.params;
        switch (tab) {
            case 'permissions': {
                return <ExecutionPermissionSettings history={history} />;
            }
            case 'view-matrix': {
                return <ExecutionViewMatrixSettings history={history} />;
            }
            case 'details': {
                return <ExecutionDetailsSettings history={history} />;
            }
            default: {
                return <ExecutionDetailsSettings history={history} />;
            }
        }
    };

    render() {
        const { id } = this.props.match.params;
        const { currentExecution } = this.props;

        return (
            <Grid>
                <Grid.Row className="flex-width">
                    <Grid.Column width={4} floated="left" className="fixed">
                        <Menu pointing vertical fluid>
                            <Menu.Item
                                as={NavLink}
                                name="Details"
                                to={`/execution/${id}/settings/details`}
                            />
                            <Menu.Item
                                as={NavLink}
                                name="Permissions"
                                to={`/execution/${id}/settings/permissions`}
                            />
                            {(currentExecution.granularPermissionTypeId ===
                                'TICKETSET' ||
                                currentExecution.granularPermissionTypeId ===
                                    'RELATION') && (
                                <Menu.Item
                                    as={NavLink}
                                    name="Permissions Matrix"
                                    to={`/execution/${id}/settings/view-matrix`}
                                />
                            )}
                        </Menu>
                    </Grid.Column>
                    <Grid.Column width={12} floated="right">
                        {this.renderSettingsTab()}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

ExecutionSettings.propTypes = {
    match: PropTypes.object,
    history: PropTypes.object,
    currentExecution: PropTypes.object,
};

export default ExecutionContextConsumer(ExecutionSettings);
