import React, { useState } from 'react';
import { List } from 'semantic-ui-react';
import { ModelSearch } from '../../common/search/ModelSearch';
import { ModelTreeRoot } from './ModelTreeRoot';
import ActionButtons from '../../common/ActionButtons';
import { ScrollingContent } from '../../common/ScrollingContent';

interface ModelExplorerProps {
    modelName: string;
    relationshipConfig: Record<string, string[]>;
    flattenBy?: Record<string, string[]>;
}

export const ModelExplorer: React.FunctionComponent<ModelExplorerProps> = ({
    modelName,
    relationshipConfig,
    flattenBy,
}) => {
    const [selectedModelIds, setSelectedModelIds] = useState<string[]>([]);

    const onModelSelect = (
        event: React.SyntheticEvent<HTMLElement>,
        data: any,
    ) => {
        const newModelId = data.value as string;
        setSelectedModelIds([...selectedModelIds, newModelId]);
    };

    const onModelRemove = (id: string) => {
        setSelectedModelIds(
            selectedModelIds.filter((modelId) => modelId !== id),
        );
    };

    return (
        <div>
            <ModelSearch
                modelName={modelName}
                onChange={onModelSelect}
                // include any other necessary props for ModelSearch
            />
            <ScrollingContent>
                <List>
                    {selectedModelIds.map((modelId) => (
                        <List.Item key={modelId}>
                            <ModelTreeRoot
                                modelName={modelName}
                                modelId={modelId}
                                relationshipConfig={relationshipConfig}
                                initialOpen={true}
                                flattenBy={flattenBy}
                                onRemove={onModelRemove}
                            />
                        </List.Item>
                    ))}
                </List>
            </ScrollingContent>
        </div>
    );
};
