import { createContext, Component } from 'react';

const ConfirmationModalContext = createContext();

export const ConfirmationModalWrapContext = (ComponentToWrap) =>
    class ConfirmationModalWrapContext extends Component {
        render() {
            return (
                <ConfirmationModalContext.Consumer>
                    {(context) => {
                        return <ComponentToWrap {...context} {...this.props} />;
                    }}
                </ConfirmationModalContext.Consumer>
            );
        }
    };

export default ConfirmationModalContext;
