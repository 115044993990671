import React from 'react';
import * as PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';

import PartyWrapper from './Party';

import PartyUserGroupsList from './PartyUserGroupsList';
import CheckPermission from '../common/CheckPermission';

import * as permissionTypes from '../../constants/permissionTypes';

const PartyUserGroups = ({ party, partyActions }) => {
    const { structuralNodeId } = party;

    const openCreateUserGroupModal = () => {
        partyActions.openSaveUserGroupModal();
    };

    return (
        <div className="row">
            <div className="column">
                <PartyUserGroupsList />

                <CheckPermission
                    structuralNodeId={structuralNodeId}
                    permissionType={permissionTypes.ADMIN}
                >
                    <Button primary onClick={openCreateUserGroupModal}>
                        Add new user group
                    </Button>
                </CheckPermission>
            </div>
        </div>
    );
};

PartyUserGroups.propTypes = {
    partyActions: PropTypes.object,
    party: PropTypes.object,
};

export default PartyWrapper(PartyUserGroups);
