import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as _ from 'lodash';
import { RouteComponentProps } from 'react-router-dom';

import * as uiActions from '../../actions/uiActions';
import * as activityActions from '../../actions/activityActions';
import { extractFunctions } from '../../utils/React';
import ActivityContext from '../../context/ActivityContext';
import { MODAL_COMPONENTS } from '../common/ModalRoot';
import { Activity as ActivityType } from '../../types';

type ActivityProps = {
    history: RouteComponentProps['history'];
    currentActivity: ActivityType;
    activityStateActions: typeof activityActions;
    activityStatuses: Record<string, any>;
    uiActions: typeof uiActions;
} & Record<string, any>;

function ActivityWrapper<P extends ActivityProps>(
    ComponentToWrap: React.ComponentType<P>,
) {
    class ActivityComponent extends React.Component<P> {
        viewActivity = (activity: ActivityType) => {
            this.props.history.push(`/question/${activity.id}`);
        };

        editActivity = (activity: ActivityType) => {
            const { uiActions } = this.props;
            uiActions.showModal({
                modalType: MODAL_COMPONENTS.CREATE_ACTIVITY_MODAL,
                modalProps: {
                    activityId: activity.id,
                },
            });
        };

        render() {
            const { currentActivity, activityStateActions, activityStatuses } =
                this.props;

            const values = {
                currentActivity,
                activityStatuses,
                activityStateActions,
                activityActions: extractFunctions(this),
            };

            return (
                <ActivityContext.Provider value={values}>
                    <ComponentToWrap {...values} {...this.props} />
                </ActivityContext.Provider>
            );
        }
    }

    function mapStateToProps(state: any) {
        return {
            activityStatuses: _.keyBy(state.constants.activityStatuses, 'id'),
            currentActivity: state.currentActivity,
            newActivity: state.newActivity,
        };
    }

    function mapDispatchToProps(dispatch: any) {
        return {
            activityStateActions: bindActionCreators(activityActions, dispatch),
            uiActions: bindActionCreators(uiActions, dispatch),
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(ActivityComponent);
}

export default ActivityWrapper;
