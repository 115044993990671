import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import { Dropdown } from 'semantic-ui-react';
import partyApi from '../../../api/partyApi';

class RelationshipSearch extends Component {
    constructor(props) {
        super(props);
        this.parties = [];
        this.state = {
            options: [],
        };
    }

    componentDidMount() {
        this.performSearch(this.props.party.id);
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.party.id && nextProps.party.id !== this.props.party.id) {
            this.performSearch(nextProps.party.id);
        }
    };

    mapRelationshipsToOptions = (relationships) => {
        const { party, sortItemsAlphabetically } = this.props;
        const optionsBase = [];

        this.parties = [
            party,
            ..._.chain(relationships)
                .filter((relationship) => relationship.toParty)
                .filter((relationship) => relationship.toPartyId !== party.id)
                .map((relationship) => relationship.toParty)
                .uniqBy((party) => party.id)
                .value(),
        ];

        let options = _.map(this.parties, (party) => ({
            key: party.id,
            text: `${party.label}`,
            value: party.id,
        }));

        if (sortItemsAlphabetically) {
            options = _.orderBy(options, 'text');
        }
        return [...optionsBase, ...options];
    };

    handleOnChange = (event, data) => {
        if (this.props.returnPartyObject) {
            this.props.onChange(
                event,
                Object.assign({}, data, {
                    value: _.find(this.parties, { id: data.value }),
                }),
            );
        } else {
            this.props.onChange(event, data);
        }
    };

    // handleSearchChange = (event, { searchQuery = '' }) => {
    //   this.performSearch(searchQuery, this.props.filterByParty);
    // };

    performSearch = (partyId) => {
        if (!partyId) return;
        partyApi.getPartyRelationships(partyId).then((data) => {
            const options = this.mapRelationshipsToOptions(data);
            this.setState({ options, users: _.keyBy(data, 'id') });
        });
    };

    render() {
        const {
            onClose,
            saving,
            value,
            name,
            disabled,
            placeholder = 'Choose Entity',
        } = this.props;
        const { options } = this.state;
        return (
            <Dropdown
                fluid
                selection
                search={true}
                selectOnBlur={false}
                selectOnNavigation={false}
                options={options}
                value={value}
                placeholder={placeholder}
                onChange={this.handleOnChange}
                onClose={onClose}
                onSearchChange={this.handleSearchChange}
                disabled={disabled || saving}
                loading={saving}
                name={name || ''}
            />
        );
    }
}

RelationshipSearch.propTypes = {
    onChange: PropTypes.func,
    onClose: PropTypes.func,
    saving: PropTypes.bool,
    returnPartyObject: PropTypes.bool,
    value: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    party: PropTypes.object,
    sortItemsAlphabetically: PropTypes.bool,
};

RelationshipSearch.defaultProps = {
    showUnassigned: true,
};

export default RelationshipSearch;
