import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { Header, Card, Item, Divider } from 'semantic-ui-react';
import * as _ from 'lodash';
import toastr from 'toastr';

import ActionButtons from './../common/ActionButtons';
import PartyWrapper from './Party';
import { countryOptions } from './../../constants/country';
import ConfirmWrapper from './../common/ConfirmWrapper';

class AddressesList extends Component {
    static propTypes = {
        party: PropTypes.object,
        partyStateActions: PropTypes.object,
        partyActions: PropTypes.object,
        confirmPopupActions: PropTypes.object,
    };

    deleteAddress = (address) => {
        const { showConfirmDialog } = this.props.confirmPopupActions;

        showConfirmDialog(
            `Are you sure you want to delete ${address.line1} address?`,
            () => this.deleteAddressOnConfirm(address),
        );
    };

    deleteAllAddresses = () => {
        const { showConfirmDialog } = this.props.confirmPopupActions;

        showConfirmDialog(
            `Are you sure you want to delete all addresses?`,
            this.deleteAllAddressesOnConfirm,
        );
    };

    deleteAddressOnConfirm = (address) => {
        const { party } = this.props;
        const { deleteAddress } = this.props.partyStateActions;
        deleteAddress(party.id, address.id).then(() =>
            this.deleteAddressOnConfirmCallback(address.line1),
        );
    };

    deleteAddressOnConfirmCallback = (addressLine1) => {
        toastr.success(`Adress ${addressLine1} is removed`);
    };

    deleteAllAddressesOnConfirm = () => {
        const { party } = this.props;
        const { deleteAllAddresses } = this.props.partyStateActions;
        deleteAllAddresses(party.id).then(
            this.deleteAllAddressesOnConfirmCallback,
        );
    };

    deleteAllAddressesOnConfirmCallback = () => {
        toastr.success(`Adresses is removed from party`);
    };

    editAddress = (address) => {
        const { partyActions } = this.props;
        partyActions.openSavePartyAddressModal(address);
    };

    renderAdresses = () => {
        const {
            party: { addresses },
        } = this.props;
        return addresses && addresses.length ? (
            <Card.Group>
                {addresses.map((address) => {
                    const {
                        stateProvinceCounty,
                        countryId,
                        city,
                        zipOrPostcode,
                        line1,
                        line2,
                        line3,
                    } = address;
                    const country = _.find(countryOptions, { key: countryId });
                    return (
                        <Card key={address.id}>
                            <Card.Content>
                                <Card.Description>
                                    {line1}
                                    <br />
                                    {line2}
                                    <br />
                                    {line3}
                                    <br />
                                    {city}
                                    <br />
                                    {stateProvinceCounty}
                                    <br />
                                    {zipOrPostcode}
                                    <br />
                                    {country ? country.text : null}
                                </Card.Description>
                            </Card.Content>
                            <Card.Content extra textAlign="center">
                                <ActionButtons
                                    className="u-show-on-hover__target"
                                    editClicked={() =>
                                        this.editAddress(address)
                                    }
                                    deleteClicked={() =>
                                        this.deleteAddress(address)
                                    }
                                />
                            </Card.Content>
                        </Card>
                    );
                })}
            </Card.Group>
        ) : (
            <div className="row">
                <div className="column">
                    <Header as="h4">
                        There are no addresses at this party
                    </Header>
                </div>
            </div>
        );
    };

    renderDeleteAllButton = () => {
        const {
            party: { addresses },
        } = this.props;
        return (
            addresses &&
            addresses.length > 0 && (
                <Item>
                    <Divider section />
                    <ActionButtons
                        className="u-show-on-hover__target"
                        deleteClicked={() => this.deleteAllAddresses()}
                    />
                </Item>
            )
        );
    };

    render() {
        return (
            <React.Fragment>
                <Header as="h2">Addresses</Header>
                {this.renderAdresses()}
                {this.renderDeleteAllButton()}
            </React.Fragment>
        );
    }
}

export default PartyWrapper(ConfirmWrapper(AddressesList));
