import React, { useEffect, useState, FC } from 'react';
import { Dropdown, Form, SemanticWIDTHS } from 'semantic-ui-react';
import { mapToOptions } from '../../../utils/React';
import utilsApi from '../../../api/utilsApi';

interface ModelSearchProps {
    value?: string | number;
    name?: string;
    label?: string;
    modelName: string; // The name of the model
    required?: boolean;
    width?: SemanticWIDTHS;
    valueProperty?: string;
    refProperty?: string;
    onChange?: (event: React.SyntheticEvent<HTMLElement>, data: any) => void;
}

export const ModelSearch = ({
    value,
    name = 'model',
    label,
    modelName,
    required,
    width,
    valueProperty = 'id',
    refProperty = 'ref',
    onChange,
}: ModelSearchProps) => {
    const [items, setItems] = useState<any[]>([]);
    const [currentItem, setCurrentItem] = useState<any>(null);

    const fetchInitialData = (showAll: boolean = false) => {
        utilsApi
            .modelCall({
                model: modelName,
                endpoint: ``,
                params:
                    value && !showAll
                        ? {
                              filter: {
                                  limit: 50,
                                  where: { [valueProperty]: value },
                              },
                          }
                        : { filter: { limit: 50 } },
            })
            .then((result) => {
                if (result.length === 0) return;
                setItems(result);
                if (value) setCurrentItem(result[0]);
            });
    };

    useEffect(() => {
        fetchInitialData();
    }, [value, modelName]);

    const handleSearchChange = (
        __event: React.SyntheticEvent<HTMLElement>,
        data: { searchQuery: string },
    ) => {
        if (data?.searchQuery.length < 3) return;
        utilsApi
            .modelCall({
                model: modelName,
                endpoint: ``,
                params: {
                    filter: {
                        limit: 50,
                        where: {
                            or: [
                                {
                                    label: {
                                        like: `%${data.searchQuery}%`,
                                    },
                                },
                                {
                                    [refProperty]: {
                                        like: `%${data.searchQuery}%`,
                                    },
                                },
                            ],
                        },
                    },
                },
            })
            .then((result) => {
                setItems(result);
            });
    };

    const handleOnChange = (
        event: React.SyntheticEvent<HTMLElement>,
        data: any,
    ) => {
        onChange?.(event, data);
    };

    const handleOnClose = () => {
        setItems([currentItem]);
    };

    const handleOnFocus = () => {
        fetchInitialData(true);
    };

    return (
        <Form.Field required={required} width={width}>
            <label>{label}</label>
            <Dropdown
                fluid
                selection
                search={true}
                selectOnBlur={false}
                selectOnNavigation={false}
                options={mapToOptions(
                    items,
                    null,
                    true,
                    valueProperty,
                    (item) => {
                        if (item[refProperty])
                            return `${item.label} (${item[refProperty]})`;
                        return item.label;
                    },
                )}
                onSearchChange={handleSearchChange}
                onChange={handleOnChange}
                onClose={handleOnClose}
                onFocus={handleOnFocus}
                value={value}
                placeholder={`Choose ${modelName}`}
                name={name}
                clearable
            />
        </Form.Field>
    );
};
