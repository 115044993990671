import * as _ from 'lodash';

export default function (args) {
    const { dataSet, data, outputKey } = args;
    const dataToMap = data[dataSet];
    let outputValue;

    if (_.isArray(dataToMap) && !!dataToMap[0]) {
        outputValue = dataToMap[0];
    }

    return {
        ...args,
        data: { ...data, [outputKey || 'object']: outputValue },
    };
}
