import React from 'react';
import * as PropTypes from 'prop-types';
import ActivitySets from './ActivitySets';
import { Button, Icon, Segment } from 'semantic-ui-react';

const ActivitySetBuilder = (props) => {
    const { programme, addActivitySet } = props;
    return (
        <React.Fragment>
            {!!programme.activitySets.length && (
                <ActivitySets
                    activitySets={programme.activitySets}
                    {...props}
                />
            )}

            <Segment textAlign="center" basic>
                <Button onClick={addActivitySet}>
                    <Icon name="add" /> Add Question Set
                </Button>
            </Segment>
        </React.Fragment>
    );
};

ActivitySetBuilder.propTypes = {
    programme: PropTypes.object.isRequired,
    updateActivitySet: PropTypes.func,
    selectedActivitySetIndex: PropTypes.number,
    selectActivitySet: PropTypes.func,
    removeActivityFromSet: PropTypes.func,
    removeActivitySet: PropTypes.func,
    moveActivitySet: PropTypes.func,
    moveActivityInSet: PropTypes.func,
    createNewActivity: PropTypes.func,
    addActivitySet: PropTypes.func,
};

export default ActivitySetBuilder;
