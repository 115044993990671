import React from 'react';
import * as PropTypes from 'prop-types';
import { WorkflowWrapContext } from '../../context/WorkflowContext';
import { Table } from 'semantic-ui-react';
import WorkflowTableRow from './WorkflowTableRow';

const WorkflowTable = (props) => {
    const { workflows } = props;
    return (
        <Table celled>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell singleLine>
                        Workflow Name
                    </Table.HeaderCell>
                    <Table.HeaderCell>Creator</Table.HeaderCell>
                    <Table.HeaderCell>Created Data</Table.HeaderCell>
                    <Table.HeaderCell>Last Modified</Table.HeaderCell>
                    <Table.HeaderCell>Actions</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {workflows.list.map((workflow, index) => (
                    <WorkflowTableRow key={index} workflow={workflow} />
                ))}
            </Table.Body>
        </Table>
    );
};

WorkflowTable.propTypes = {
    workflows: PropTypes.array.isRequired,
};

export default WorkflowTable;

export const WithContext = WorkflowWrapContext(WorkflowTable);
