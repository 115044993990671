import React from 'react';
import { Table } from 'semantic-ui-react';
import * as _ from 'lodash';
import components from '../../../ComponentStore';

export function renderValue(value = '', key) {
    let returnValue;
    let componentName = 'StyledCell';
    if (value && value.component) componentName = value.component;
    const Component = components[componentName];

    if (!Component) return <div>{componentName} not found</div>;

    if (_.isPlainObject(value)) {
        returnValue = (
            <Component
                {...value.args}
                columnArgs={value.columnArgs}
                key={key}
            />
        );
    } else {
        returnValue = <Component value={value} key={key} />;
    }

    if (
        componentName === 'StyledCell' ||
        componentName === 'AnalyseAndImplement'
    )
        return returnValue;

    return (
        <Table.Cell key={key} {...value.columnArgs} valign="top">
            {returnValue}
        </Table.Cell>
    );
}
