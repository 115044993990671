import React from 'react';
import * as PropTypes from 'prop-types';
import { Item } from 'semantic-ui-react';
import * as _ from 'lodash';
import TicketWorkflowEntry from './TicketWorkflowEntry';
import { TicketContextConsumer } from '../../context/TicketContext';

const TicketWorkflowEntries = (props) => {
    const { currentTicket } = props;
    const entries = props.entries || currentTicket.workflowEntries;
    return (
        <React.Fragment>
            {entries && entries.length > 0 && (
                <Item.Group divided relaxed={false}>
                    {_.orderBy(entries, 'field.sort').map((entry) => (
                        <TicketWorkflowEntry key={entry.id} entry={entry} />
                    ))}
                </Item.Group>
            )}
        </React.Fragment>
    );
};

TicketWorkflowEntries.propTypes = {
    currentTicket: PropTypes.object,
    entries: PropTypes.array,
};

export default TicketContextConsumer(TicketWorkflowEntries);
