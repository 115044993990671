import _ from 'lodash';
import Mustache from 'mustache';

// Define the types
type Args = { [key: string]: string };
type Data = { [key: string]: any };

/**
 * Process and map item arguments.
 *
 * For each argument, the function checks its value.
 * If the value starts with '#', it will be treated as a Mustache template, and rendered with the provided data.
 * Otherwise, the function will attempt to get the value from the data using lodash's _.get method.
 * If the resulting value is undefined, the function will default back to the original value.
 *
 * @param {Args} args - An object containing the args to process.
 * @param {Data} data - An object to be used as context for Mustache rendering or lodash's _.get.
 *
 * @returns {Args} The mappedArgs object, which contains the processed args.
 */
export function mapArgs(args: Args, data: Data): Args {
    // Initialize the result object
    const mappedArgs: Args = {};

    // Iterate over each argument
    _.forEach(args, (val: string, key: string) => {
        let argVal;

        // Check if the value should be rendered as a Mustache template
        if (val[0] === '#') {
            argVal = Mustache.render(val.substr(1), data);
        }
        if (val === '*') {
            argVal = data;
        } else {
            // Otherwise, attempt to get the value from data
            argVal = _.get(data, val);
        }

        // If the resulting value is undefined, default back to the original value
        if (_.isUndefined(argVal)) argVal = val;

        // Add the processed argument to the result object
        mappedArgs[key] = argVal;
    });

    return mappedArgs;
}
