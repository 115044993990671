/* eslint-disable react/no-did-update-set-state */
import React, { Component } from 'react';
import { Dropdown, Icon, Menu } from 'semantic-ui-react';
import * as _ from 'lodash';
import * as PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { replaceSymbolsToLowerCase } from '../../utils/CommonUtils';
import { ExecutionContextConsumer } from '../../context/ExecutionContext';
import ExecutionSplitView from './ExecutionSplitView/ExecutionSplitView';
import { TicketContextConsumer } from '../../context/TicketContext';
import { getFeatures } from '../../utils/Features';

class ExecutionCategoryMenu extends Component {
    state = {
        menuItems: [],
        currentTicket: null,
    };

    componentWillMount() {
        const { props } = this;
        const execution = props.execution || props.currentExecution;
        let menuItems = [];
        if (_.get(execution.ticketSets, 'length')) {
            const noCategorySets = _.filter(execution.ticketSets, (ticketSet) =>
                _.isNull(ticketSet.executionCategoryId),
            );
            if (noCategorySets.length > 0) menuItems.push('Uncategorised');
            if (execution)
                _.orderBy(execution.categories, 'sort').map((category) => {
                    this.categoryHasTicketSets(category) &&
                        menuItems.push(category.label);
                });
            this.setState({ menuItems }, () => {
                if (this.props.match.params.id == execution.id) {
                    const { category } = this.props;
                    if (!category) this.redirect();
                }
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.category != this.props.category) {
            const { category } = this.props;
            if (!category) this.redirect();
        }
        if (
            !_.isEqual(
                prevProps.currentExecution.id,
                this.props.currentExecution.id,
            )
        ) {
            const { currentExecution } = this.props;
            const { filter } = currentExecution;
            const partyId = filter ? filter.assignedPartyRelationship : null;
            let menuItems = [];
            if (currentExecution)
                _.orderBy(currentExecution.categories, 'sort').map(
                    (category) => {
                        this.filteredCategoriesHasTicketSets(
                            category,
                            partyId,
                        ) && menuItems.push(category.label);
                    },
                );

            const noCategorySets = _.filter(
                currentExecution.ticketSets,
                (ticketSet) => _.isNull(ticketSet.executionCategoryId),
            );
            if (noCategorySets.length > 0) menuItems.push('Uncategorised');

            this.setState({ menuItems }, () => {
                const { category } = this.props;
                const menu = _.map(menuItems, (item) =>
                    replaceSymbolsToLowerCase(item, '-'),
                );
                if (!_.includes(menu, category)) this.redirect();
            });
        }
    }

    redirect = () => {
        const { menuItems } = this.state;
        const { history } = this.props;
        const execution = this.props.execution || this.props.currentExecution;
        if (menuItems.length === 0) return;
        history.replace(
            `/execution/${execution.id}/${replaceSymbolsToLowerCase(
                menuItems[0],
                '-',
            )}`,
        );
    };

    getCategoryByLabel = (label) => {
        const execution = this.props.execution || this.props.currentExecution;
        const { categories } = execution;
        const categoryIndex = _.findIndex(
            categories,
            (category) =>
                replaceSymbolsToLowerCase(category.label.toLowerCase(), ' ') ===
                replaceSymbolsToLowerCase(label.toLowerCase(), ' '),
        );
        if (categoryIndex != -1) return categories[categoryIndex];
        return categories[0];
    };

    getCurrentCategory = () => {
        const { category } = this.props;
        const categoryLabel = replaceSymbolsToLowerCase(category, ' ');
        return this.getCategoryByLabel(categoryLabel);
    };

    renderUncategorised = () => {
        const { ticketId } = this.props;
        const execution = this.props.execution || this.props.currentExecution;
        const { filter } = execution;
        const assignedPartyRelationshipId = filter
            ? filter.assignedPartyRelationship.partyId
            : null;
        let noCategorySets = _.filter(execution.ticketSets, (ticketSet) =>
            _.isNull(ticketSet.executionCategoryId),
        );
        if (assignedPartyRelationshipId) {
            noCategorySets = _.filter(
                noCategorySets,
                (ticketSet) =>
                    ticketSet.assignedPartyRelationshipId ===
                    assignedPartyRelationshipId,
            );
        }
        return (
            <ExecutionSplitView
                ticketSets={noCategorySets}
                editable={false}
                ticketClicked={this.ticketClicked}
                ticketId={ticketId}
            />
        );
    };

    renderCategorised = () => {
        const execution = this.props.execution || this.props.currentExecution;
        const currentCategory = this.getCurrentCategory();
        const { filter } = execution;
        const assignedPartyRelationshipId = filter
            ? filter.assignedPartyRelationship.partyId
            : null;
        let ticketSets = _.filter(
            execution.ticketSets,
            (ticketSet) => ticketSet.executionCategoryId === currentCategory.id,
        );
        if (assignedPartyRelationshipId) {
            ticketSets = _.filter(
                ticketSets,
                (ticketSet) =>
                    ticketSet.assignedPartyRelationshipId ===
                    assignedPartyRelationshipId,
            );
        }

        const sortedTicketSets = _.orderBy(ticketSets, 'activitySet.sort');
        return (
            <ExecutionSplitView
                ticketSets={sortedTicketSets}
                editable={false}
                categoryName={currentCategory ? currentCategory.label : ''}
            />
        );
    };

    categoryHasTicketSets = (category) => {
        const { id } = category;
        const execution = this.props.execution || this.props.currentExecution;
        const categoryTicketSets = execution.ticketSets.filter(
            (ticketSet) => ticketSet.executionCategoryId === id,
        );
        return categoryTicketSets.length > 0;
    };

    renderTab = () => {
        const { category } = this.props;
        switch (category) {
            case 'uncategorised': {
                return this.renderUncategorised();
            }
            default: {
                return this.renderCategorised();
            }
        }
    };

    filteredCategoriesHasTicketSets = (category, filter) => {
        const { id } = category;
        const execution = this.props.execution || this.props.currentExecution;
        const ticketSets = filter
            ? _.filter(execution.ticketSets, {
                  assignedPartyRelationshipId: filter.partyId,
              })
            : execution.ticketSets;
        const categoryTicketSets = ticketSets.filter(
            (ticketSet) => ticketSet.executionCategoryId === id,
        );
        return categoryTicketSets.length > 0;
    };

    followUpAll = (menuItem) => {
        const { ticketStateActions } = this.props;
        const execution = this.props.execution || this.props.currentExecution;
        const category = this.getCategoryByLabel(menuItem);
        let ticketSets = _.filter(
            execution.ticketSets,
            (ticketSet) => ticketSet.executionCategoryId === category.id,
        );
        let idsToFollowUp = _.flatten(
            _.map(ticketSets, (ticketSet) => {
                return _.chain(ticketSet.tickets)
                    .map((ticket) => ticket.id)
                    .value();
            }),
        );
        ticketStateActions.addTicketsToFollowUpList(idsToFollowUp);
    };

    assignCategory = (menuItem) => {
        const { ticketActions } = this.props;
        const execution = this.props.execution || this.props.currentExecution;

        const category = this.getCategoryByLabel(menuItem);
        let ticketSets = _.filter(
            execution.ticketSets,
            (ticketSet) => ticketSet.executionCategoryId === category.id,
        );

        const tickets = [].concat(
            ..._.map(ticketSets, (ticketSet) => ticketSet.tickets),
        );
        ticketActions.openAssignTicketsModal(
            { label: category.label, tickets },
            false,
        );
    };

    render() {
        const { menuItems } = this.state;
        const { category, history } = this.props;
        const execution = this.props.execution || this.props.currentExecution;
        const { categories } = { ...execution };
        const { FollowUp } = getFeatures(this.props.featureFlags);
        return (
            <React.Fragment>
                {categories && categories.length > 0 && (
                    <Menu fluid pointing className="wrapping">
                        {menuItems.map((menuItem, index) => {
                            const id = replaceSymbolsToLowerCase(menuItem, '-');
                            if (!_.isObject(menuItem))
                                return (
                                    <Menu.Item
                                        key={index}
                                        active={id === category}
                                        onClick={() => {
                                            history.push(
                                                `/execution/${execution.id}/${id}`,
                                            );
                                        }}
                                    >
                                        {menuItem}

                                        <Menu.Menu position="right">
                                            <Dropdown
                                                selectOnBlur={false}
                                                icon={null}
                                                basic
                                                trigger={
                                                    <Icon name="ellipsis horizontal" />
                                                }
                                            >
                                                <Dropdown.Menu>
                                                    {FollowUp && (
                                                        <Dropdown.Item
                                                            onClick={() =>
                                                                this.followUpAll(
                                                                    id,
                                                                )
                                                            }
                                                        >
                                                            Follow up all
                                                        </Dropdown.Item>
                                                    )}
                                                    <Dropdown.Item
                                                        onClick={() =>
                                                            this.assignCategory(
                                                                id,
                                                            )
                                                        }
                                                    >
                                                        Assign all
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Menu.Menu>
                                    </Menu.Item>
                                );
                        })}
                    </Menu>
                )}
                {categories && category && this.renderTab()}
            </React.Fragment>
        );
    }
}

ExecutionCategoryMenu.propTypes = {
    execution: PropTypes.object,
    currentExecution: PropTypes.object,
    uiState: PropTypes.object,
    uiActions: PropTypes.object,
    ticketStateActions: PropTypes.object,
    ticketActions: PropTypes.object,
    category: PropTypes.string,
    ticketId: PropTypes.number,
    history: PropTypes.object,
    executionsFilter: PropTypes.object,
    match: PropTypes.object,
};

function mapStateToProps(state) {
    return {
        featureFlags: state.constants.options.featureFlags,
    };
}

export default withRouter(
    TicketContextConsumer(
        ExecutionContextConsumer(
            connect(mapStateToProps, null)(ExecutionCategoryMenu),
        ),
    ),
);
