import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { List } from 'semantic-ui-react';
import * as _ from 'lodash';

import ActivitySet from './ActivitySet';
import ActivityFormMenu from '../activity/ActivityFormMenu';

const ActivitySets = (props) => {
    const { activitySets, selectActivitySet, selectedActivitySetId, layout } =
        props;

    const sortActivitySets = () => {
        return _.sortBy(activitySets, ['sort']);
    };

    const [activeMenuItem, setActiveMenuItem] = useState('mainDetails');

    return (
        <div>
            {
                {
                    FULL: (
                        <React.Fragment>
                            {sortActivitySets().map((activitySet, index) => (
                                <ActivitySet
                                    {...props}
                                    key={index}
                                    value={activitySet}
                                    activitySetIndex={index}
                                    isActive={
                                        activitySet.uid ===
                                        selectedActivitySetId
                                    }
                                    onClick={() =>
                                        selectActivitySet(activitySet)
                                    }
                                />
                            ))}
                        </React.Fragment>
                    ),
                    FIELDS: (
                        <React.Fragment>
                            <ActivityFormMenu
                                onChange={(e, { name }) => {
                                    setActiveMenuItem(name);
                                }}
                                activeItem={activeMenuItem}
                            />
                            {sortActivitySets().map((activitySet, index) => (
                                <ActivitySet.Fields
                                    {...props}
                                    key={index}
                                    value={activitySet}
                                    activitySetIndex={index}
                                    isActive={
                                        activitySet.uid ===
                                        selectedActivitySetId
                                    }
                                    onClick={() =>
                                        selectActivitySet(activitySet)
                                    }
                                    activeMenuItem={activeMenuItem}
                                />
                            ))}
                        </React.Fragment>
                    ),
                    LIST: (
                        <List divided verticalAlign="middle">
                            {sortActivitySets().map((activitySet, index) => (
                                <ActivitySet.ListItem
                                    {...props}
                                    key={index}
                                    value={activitySet}
                                />
                            ))}
                        </List>
                    ),
                    SCOPING: (
                        <List divided verticalAlign="middle">
                            {sortActivitySets().map((activitySet, index) => (
                                <ActivitySet.Scoping
                                    {...props}
                                    key={index}
                                    value={activitySet}
                                />
                            ))}
                        </List>
                    ),
                }[layout]
            }
        </div>
    );
};

ActivitySets.propTypes = {
    activitySets: PropTypes.array.isRequired,
    updateActivitySet: PropTypes.func,
    selectActivitySet: PropTypes.func,
    removeActivityFromSet: PropTypes.func,
    removeActivitySet: PropTypes.func,
    moveActivitySet: PropTypes.func,
    moveActivityInSet: PropTypes.func,
    createNewActivity: PropTypes.func,
    selectedActivitySetId: PropTypes.number,
    layout: PropTypes.string,
};

ActivitySets.defaultProps = {
    layout: 'FULL',
    selectActivitySet: () => {},
};
export default ActivitySets;
