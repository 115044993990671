const ArgTypes = {
    number: {
        field: 'Number',
    },
    string: {
        field: 'Text',
    },
    bool: {
        field: 'CheckBoxBool',
    },
    array: {
        field: 'ArrayField',
        of: (type) => {
            return { field: 'ArrayField', type };
        },
    },
    object: {
        field: 'ObjectField',
        of: (type) => {
            return { field: 'ObjectField', type };
        },
    },
    json: {
        field: 'JSONBuilder',
    },
};

export default ArgTypes;
