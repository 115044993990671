import React from 'react';
import * as PropTypes from 'prop-types';
import { Label, Table } from 'semantic-ui-react';
import ActionButtons from '../common/ActionButtons';
import Moment from 'react-moment';
import { WorkflowWrapContext } from '../../context/WorkflowContext';

const WorkflowTableRow = ({ workflow, workflowActions }) => {
    return (
        <Table.Row>
            <Table.Cell singleLine>
                <a
                    href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        workflowActions.gotoWorkflow(workflow);
                    }}
                >
                    {workflow.label}
                </a>
            </Table.Cell>
            <Table.Cell>
                {workflow.creator && (
                    <Label as="a" color="grey" image>
                        {workflow.creator.firstName} {workflow.creator.lastName}
                    </Label>
                )}
            </Table.Cell>
            <Table.Cell>
                <Moment>{workflow.createdAt}</Moment>
            </Table.Cell>
            <Table.Cell>
                <Moment>{workflow.modifiedAt}</Moment>
            </Table.Cell>
            <Table.Cell>
                <ActionButtons
                    className="u-show-on-hover__target"
                    editClicked={() => {}}
                    deleteClicked={() => {}}
                />
            </Table.Cell>
        </Table.Row>
    );
};

WorkflowTableRow.propTypes = {
    workflow: PropTypes.object,
    workflowActions: PropTypes.object.isRequired,
};

export default WorkflowWrapContext(WorkflowTableRow);
