import React, { useState, useEffect } from 'react';
import moment from 'moment';
import * as _ from 'lodash';
import Date from '../common/fields/Date';
import Select from '../common/fields/Select';
import {
    APPLICABLE_DATE_TYPES,
    MONTHS,
    QUARTERS,
} from '../../utils/ExecutionPlanLaunchConsts';
import { getYearOptions } from '../../utils/ExecutionPlansUtils';

type ApplicableDateSelectProps = {
    applicableDate?: string;
    handleFormDataChange: (
        event: React.ChangeEvent<HTMLInputElement> | null,
        data: { name: string; value: string },
    ) => void;
    applicableDateTypeId?: string;
    multiple?: boolean;
};

export const ApplicableDateSelect = ({
    applicableDate,
    handleFormDataChange,
    applicableDateTypeId,
}: ApplicableDateSelectProps) => {
    const momentApplicableDate = moment.utc(applicableDate);
    const [month, setMonth] = useState(
        applicableDate ? momentApplicableDate.get('month') : null,
    );
    const [quarter, setQuarter] = useState(
        applicableDate ? momentApplicableDate.get('quarter') : null,
    );
    const [year, setYear] = useState(
        applicableDate ? momentApplicableDate.get('year') : null,
    );

    useEffect(() => {
        if (year !== null) {
            let value = moment();
            switch (applicableDateTypeId) {
                case APPLICABLE_DATE_TYPES.YEAR: {
                    value = value.year(year).startOf('year');
                    updateValue(value);
                    break;
                }
                case APPLICABLE_DATE_TYPES.MONTH: {
                    if (_.isInteger(month)) {
                        value = value.year(year).month(month).startOf('month');
                        updateValue(value);
                    }
                    break;
                }
                case APPLICABLE_DATE_TYPES.QUARTER: {
                    if (quarter) {
                        value = value
                            .year(year)
                            .quarter(quarter)
                            .endOf('quarter');
                        updateValue(value);
                    }
                    break;
                }
            }
        }
    }, [month, quarter, year, applicableDateTypeId]);

    const mapper: { [key: string]: (value: number) => void } = {
        month: setMonth,
        quarter: setQuarter,
        year: setYear,
    };

    const changeDateSelect = (
        e: React.SyntheticEvent,
        { name, value }: { name: string; value: number },
    ) => {
        mapper[name](value);
    };

    const updateValue = (value: moment.Moment) => {
        const newApplicableDate = moment
            .utc(value.valueOf() + value.utcOffset() * 60000)
            .toISOString();
        if (newApplicableDate !== applicableDate) {
            handleFormDataChange(null, {
                name: 'applicableDate',
                value: newApplicableDate,
            });
        }
    };

    if (applicableDateTypeId === null) return null;
    if (applicableDateTypeId === APPLICABLE_DATE_TYPES.DAY) {
        return (
            <Date
                label="Applicable Date"
                value={applicableDate}
                onChange={handleFormDataChange}
                name="applicableDate"
                isClearable={true}
            />
        );
    }
    let selectComponent;

    switch (applicableDateTypeId) {
        case APPLICABLE_DATE_TYPES.MONTH: {
            selectComponent = (
                <Select
                    options={MONTHS}
                    label="Month"
                    name="month"
                    value={month}
                    onChange={changeDateSelect}
                />
            );
            break;
        }
        case APPLICABLE_DATE_TYPES.QUARTER: {
            selectComponent = (
                <Select
                    options={QUARTERS}
                    label="Quarter"
                    name="quarter"
                    value={quarter}
                    onChange={changeDateSelect}
                />
            );
            break;
        }
    }

    return (
        <>
            {selectComponent}
            <Select
                options={getYearOptions()}
                label="Year"
                name="year"
                value={year}
                onChange={changeDateSelect}
            />
        </>
    );
};

export default ApplicableDateSelect;
