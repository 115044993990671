import React from 'react';
import * as PropTypes from 'prop-types';

import './EventComponentComment.scss';
import Viewer from '../fields/TUIWrapperViewer';

const EventComponentComment = (props) => {
    const { event } = props;
    return (
        <div className="event-component-comment">
            <Viewer initialValue={event.data.content} />
        </div>
    );
};

EventComponentComment.propTypes = {
    event: PropTypes.object,
};

export default EventComponentComment;
