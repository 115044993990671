import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import {
    Button,
    Divider,
    Form,
    Header,
    Select,
    Table,
} from 'semantic-ui-react';
import * as toastr from 'toastr';

import Image from '../common/Image';

import PartyDashboards from './PartyDashboards';

import CheckPermission from '../common/CheckPermission';
import PartyWrapper from './Party';
import UploadPartyIcon, { WrapUploadPartyIconContext } from './UploadPartyIcon';

import * as permissionTypes from '../../constants/permissionTypes';
import { api_url } from '../../api/apiTools';
import PartyApi from '../../api/partyApi';
import Text from '../common/fields/Text';
import TextArea from '../common/fields/TextArea';

const PartySettings = ({ openFileDialog, party, partyStateActions }) => {
    const { structuralNodeId } = party;
    const [authType, setAuthType] = useState(party.authType);
    const [authTypeOptions, setAuthTypeOptions] = useState([]);
    const [metaData, setMetaData] = useState(
        party?.partySettingValue?.find(
            (x) => x.partySettingId === 'SAML_METADATA',
        )?.value ?? '',
    );
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchAuthTypes = async () => {
            const res = await PartyApi.getAuthTypes();
            setAuthTypeOptions(res);
        };
        fetchAuthTypes();
    }, []);

    useEffect(() => {
        setAuthType(party.authType || 'PASS');
        setMetaData(
            party?.partySettingValue?.find(
                (x) => x.partySettingId === 'SAML_METADATA',
            )?.value ?? '',
        );
    }, [party]);

    const handleDeactivate = async () => {
        setIsLoading(true);
        try {
            await partyStateActions.deactivate(party.id);
        } catch (error) {
            toastr.error(error.message);
            setIsLoading(false);
        }
        setIsLoading(false);
    };
    const handleReactivate = async () => {
        setIsLoading(true);
        try {
            await partyStateActions.reactivate(party.id);
        } catch (error) {
            toastr.error(error.message);
            setIsLoading(false);
        }
        setIsLoading(false);
    };

    const handleChangeAuthType = async (_, { value }) => {
        try {
            setAuthType(value);
            await PartyApi.changeAuthType(party.id, value);
        } catch (error) {
            toastr.error(error);
        }
    };

    const handleSaveMetadata = async () => {
        setIsLoading(true);
        try {
            await PartyApi.changeMetadata(party.id, metaData);
            toastr.success('Party metadata was saved');
        } catch (error) {
            toastr.error(error.message);
            setIsLoading(false);
        }
        setIsLoading(false);
    };

    return (
        <CheckPermission
            structuralNodeId={structuralNodeId}
            permissionType={permissionTypes.ADMIN}
        >
            <div className="row">
                <div className="column">
                    <Header as="h2">Settings</Header>

                    <Table celled collapsing>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>Icon</Table.Cell>
                                <Table.Cell>
                                    {party.iconAttachmentId && (
                                        <Image
                                            style={{ width: 100 }}
                                            url={`Parties/${party.id}/icon`}
                                            key={party.iconAttachmentId}
                                        />
                                    )}
                                </Table.Cell>
                                <Table.Cell>
                                    <Button
                                        onClick={() => {
                                            openFileDialog('icon');
                                        }}
                                        size="tiny"
                                    >
                                        Upload Icon
                                    </Button>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Logo</Table.Cell>
                                <Table.Cell>
                                    {party.logoAttachmentId && (
                                        <img
                                            style={{ width: 100 }}
                                            src={`${api_url()}Parties/${
                                                party.id
                                            }/logo`}
                                            key={party.logoAttachmentId}
                                        />
                                    )}
                                </Table.Cell>
                                <Table.Cell>
                                    <Button
                                        onClick={() => {
                                            openFileDialog('logo');
                                        }}
                                        size="tiny"
                                    >
                                        Upload Logo
                                    </Button>
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>

                    <CheckPermission
                        structuralNodeId={party.structuralNodeId}
                        permissionType={permissionTypes.SUPER}
                    >
                        <Form>
                            <Form.Field
                                onChange={handleChangeAuthType}
                                width={4}
                                control={Select}
                                value={authType}
                                options={authTypeOptions}
                                label="Authentication method"
                            />
                            {authType === 'OKTA' && (
                                <>
                                    <Form.Field
                                        width={16}
                                        control={TextArea}
                                        label="Saml metadata"
                                        value={metaData}
                                        onChange={(_, { value }) =>
                                            setMetaData(value)
                                        }
                                    ></Form.Field>
                                    <Form.Button
                                        loading={isLoading}
                                        onClick={handleSaveMetadata}
                                    >
                                        Save
                                    </Form.Button>
                                </>
                            )}
                        </Form>
                    </CheckPermission>

                    <CheckPermission
                        structuralNodeId={party.structuralNodeId}
                        permissionType={permissionTypes.ADMIN}
                    >
                        <Divider />
                        {party.status === 'ACTIVE' && (
                            <Button
                                onClick={handleDeactivate}
                                negative
                                loading={isLoading}
                            >
                                Deactivate Party
                            </Button>
                        )}
                        {party.status === 'INACTIVE' && (
                            <Button
                                onClick={handleReactivate}
                                loading={isLoading}
                            >
                                Reactivate Party
                            </Button>
                        )}
                    </CheckPermission>

                    <CheckPermission
                        structuralNodeId={party.structuralNodeId}
                        permissionType={permissionTypes.SUPER}
                    >
                        <Header as="h3">Dashboards</Header>
                        <PartyDashboards />
                    </CheckPermission>
                </div>
            </div>
            {/* <Link to={`/party/${party.id}/riskAppetite`}>Manage Risk Appetite</Link> */}
        </CheckPermission>
    );
};

PartySettings.propTypes = {
    openFileDialog: PropTypes.func,
    party: PropTypes.object,
};

function mapUploadPartyIconOptions(props) {
    const { party, partyStateActions } = props;

    return {
        model: { ...party, partyStateActions },
    };
}

function uploadSuccessCallback(props, files, imageType) {
    props.partyStateActions.uploadImage(files, imageType);
}

export default PartyWrapper(
    UploadPartyIcon(
        mapUploadPartyIconOptions,
        uploadSuccessCallback,
    )(WrapUploadPartyIconContext(PartySettings)),
);
