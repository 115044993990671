import React from 'react';
import { Table } from 'semantic-ui-react';
import Moment from 'react-moment';
import * as _ from 'lodash';
import { lightColors } from '../../../../constants/config';
import * as PropTypes from 'prop-types';
import numbro from 'numbro';
import * as Mustache from 'mustache';

const DataTableStyledCell = (props) => {
    const { value, color, format, columnArgs, template } = props;

    const formatValue = (value, format) => {
        switch (format) {
            case 'DATE':
                return value ? (
                    <Moment format="DD/MM/YYYY">{value}</Moment>
                ) : (
                    ''
                );
            case 'PERCENTAGE':
                return _.isFinite(value) ? `${Math.round(value * 100)}%` : null;
            case 'CURRENCY':
                return _.isFinite(Number(value))
                    ? numbro(Number(value).toFixed(2)).format({
                          thousandSeparated: true,
                          mantissa: 2,
                      })
                    : null;
            case 'TEMPLATE': {
                const renderedValue = Mustache.render(template, value);
                // eslint-disable-next-line react/no-danger
                return (
                    <div dangerouslySetInnerHTML={{ __html: renderedValue }} />
                );
            }
            default:
                return value;
        }
    };
    return (
        <Table.Cell
            style={{
                background: lightColors[color],
            }}
            valign="top"
            {...columnArgs}
        >
            {formatValue(value, format)}
        </Table.Cell>
    );
};

DataTableStyledCell.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object,
    ]),
    color: PropTypes.string,
    format: PropTypes.string,
    template: PropTypes.string,
    columnArgs: PropTypes.object,
};

export default DataTableStyledCell;
