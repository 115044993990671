/* eslint-disable react/no-did-mount-set-state */
import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import {
    Button,
    Dimmer,
    Loader,
    Grid,
    Message,
    Segment,
    Checkbox,
    Form,
} from 'semantic-ui-react';
import * as _ from 'lodash';
import toastr from 'toastr';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as activityTypes from '../../constants/storeTypes';
import * as activitiesActions from '../../actions/activitiesActions';
import * as activityActions from '../../actions/activityActions';
import * as tagActions from '../../actions/tagActions';
import * as uiActions from '../../actions/uiActions';
import * as partyActions from '../../actions/partyActions';
import UserWrapper from '../user/User';
import ActivityFormBody from './ActivityFormBody';
import RichTextArea from '../common/fields/RichTextArea';

import './ActivityForm.scss';
import ActivityFormMenu from './ActivityFormMenu';

class ActivityForm extends Component {
    state = {
        activity: Object.assign({}, this.props.activity),
        createNew: false,
        keepOld: false,
        saving: false,
        loading: false,
        activeItem: 'mainDetails',
        errorMessage: [],
        isNewVersion: false,
        isVersionNotesVisible: false,
    };

    componentDidMount() {
        const {
            activityType,
            activityId,
            cloneActivity,
            partyActions,
            tagActions,
            tags,
            activity,
        } = this.props;

        if (!tags.length) {
            tagActions.loadTags();
        }

        partyActions.loadParties();
        if (cloneActivity) {
            this.setState({ loading: true });
            this.props.activityActions
                .loadActivity(activityId, activityType)
                .then(() => {
                    this.setState({ loading: false });
                });

            return;
        }

        if (activity.fields) return;
        if (activityId) {
            this.setState({ loading: true });
            this.props.activityActions
                .loadActivity(activityId, activityType)
                .then(() => {
                    this.setState({ loading: false });
                });
            return;
        }

        this.resetForm();
    }

    updateActivityState = (event, data) => {
        const {
            activity: { id: activityId },
            activityActions,
            activityType,
        } = this.props;
        const { name, value } = data;

        activityActions.updateActivityValue(
            activityType,
            name,
            value,
            activityId,
        );
    };

    addField = (event, data) => {
        const { activityActions, activityType, activityFieldTypes, activity } =
            this.props;
        activityActions.addActivityField(
            activityType,
            _.find(
                activityFieldTypes,
                (fieldType) => fieldType.id === data.value,
            ),
            data.label || '',
            {},
            activity.id,
            activity.ref
                ? activity.ref + '-' + (data.fieldKey || '')
                : data.fieldKey || '',
        );
    };

    deleteField = (fieldIndex) => {
        const { activityActions, activityType, activity } = this.props;
        activityActions.removeActivityField(
            activityType,
            fieldIndex,
            activity.id,
        );
    };

    onFieldChange = (fieldIndex, newValue) => {
        if (_.isEmpty(newValue)) {
            this.deleteField(fieldIndex);
            return;
        }
        const { activityActions, activityType, activity } = this.props;
        activityActions.updateActivityField(
            activityType,
            fieldIndex,
            newValue,
            activity.id,
        );
    };

    addNewTag = (event, data) => {
        const { name, value } = data;
        this.setState({ saving: true });
        this.props.tagActions.saveTag(value).then((res) => {
            toastr.success('New Tag created.');
            this.setState({ saving: false });
            this.updateActivityState(
                {},
                {
                    name: name,
                    value:
                        name === 'primaryTag'
                            ? res.tag
                            : [...this.props.activity.tags, res.tag],
                },
            );
            if (name === 'primaryTag')
                this.updateActivityState(
                    {},
                    {
                        name: 'primaryTagId',
                        value: res.tag.id,
                    },
                );
        });
    };

    saveActivity = () => {
        const {
            activityType,
            activityActions,
            createActivityAction,
            dispatch,
            activity,
        } = this.props;
        const { isNewVersion, overrideUpdateChecks } = this.state;
        this.setState({ saving: true, errorMessage: [] });
        activityActions
            .saveStateActivity(
                activityType,
                isNewVersion,
                overrideUpdateChecks,
                activity.id,
            )
            .then((res) => {
                toastr.success(
                    activityType === activityTypes.NEW
                        ? 'New Question created'
                        : 'Changes saved',
                );
                if (createActivityAction) {
                    const action = Object.assign({}, createActivityAction, {
                        value: res.activity,
                    });
                    if (action.type) dispatch(action);
                }

                this.setState({
                    saving: false,
                    isVersionNotesVisible: false,
                    errorMessage: [],
                });

                if (activityType === activityTypes.NEW) {
                    this.resetForm();
                    if (!this.state.createNew) {
                        this.closeModel();
                    }
                }
            })
            .catch((error) => {
                const errorMessage = error.message ? [error.message] : [];
                this.setState({
                    saving: false,
                    errorMessage,
                    isVersionNotesVisible: false,
                });
                throw error;
            })
            .finally(() => {
                this.setState({ saving: false });
            });
    };

    resetForm = () => {
        if (this.state.keepOld) {
            this.props.activityActions.updateActivityValue(
                this.props.activityType,
                'label',
                '',
            );
            return;
        }
        this.props.activityActions.resetActivity(
            this.props.activityType,
            {
                activityStatusId: 'EDITING',
            },
            'NEW',
        );
    };

    closeModel = () => {
        const { uiActions } = this.props;
        uiActions.closeModal();
    };

    handleMenuItemChange = (e, { name }) => {
        this.setState({ activeItem: name });
    };

    handleNewVersionClick = (e, { name, checked }) => {
        this.setState({ [name]: checked });
    };

    handleOverrideCheckClick = (e, { name, checked }) => {
        this.setState({ [name]: checked });
    };

    toggleVersionNotes = (isVersionNotesVisible) => {
        this.setState({ isVersionNotesVisible });
    };

    updateResolution = (statusKey, resolution, value) => {
        const { activityActions, activityType, activity } = this.props;
        activityActions.updateResolutionLabel(
            activityType,
            statusKey,
            resolution.id,
            value,
            activity.id,
        );
    };

    render() {
        const {
            loading,
            saving,
            activeItem,
            errorMessage,
            isNewVersion,
            isVersionNotesVisible,
            overrideUpdateChecks,
        } = this.state;
        const {
            activity,
            activityType,
            tags,
            activityFieldTypes,
            activityResolutionTypes,
            parties,
            activityStatuses,
            currentUser,
            activeMenuItem,
            layout,
            workflowStatusInputTypes,
        } = this.props;
        const isActivityCurrent = activityType === activityTypes.CURRENT;
        const { edited, versionNotes } = activity;
        return (
            <div className="activity-form">
                {loading && (
                    <Dimmer active={loading} inverted>
                        <Loader disabled={!loading} />
                    </Dimmer>
                )}
                {isVersionNotesVisible ? (
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <Form>
                                    <RichTextArea
                                        label="Please enter a description of the edits to the question"
                                        value={versionNotes}
                                        onChange={this.updateActivityState}
                                        name="versionNotes"
                                        fullWidth={true}
                                    />
                                </Form>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Button
                                    type="submit"
                                    onClick={this.saveActivity}
                                    loading={saving}
                                    disabled={saving || !edited}
                                >
                                    Save Changes
                                </Button>
                                <Button
                                    onClick={() =>
                                        this.toggleVersionNotes(false)
                                    }
                                    loading={saving}
                                >
                                    Back
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                ) : (
                    <React.Fragment>
                        {!activeMenuItem && (
                            <ActivityFormMenu
                                onChange={this.handleMenuItemChange}
                                activeItem={activeItem}
                            />
                        )}
                        <Segment>
                            <ActivityFormBody
                                activeItem={activeMenuItem || activeItem}
                                activity={activity}
                                parties={parties}
                                activityStatuses={activityStatuses}
                                currentUser={currentUser}
                                tags={tags}
                                activityFieldTypes={activityFieldTypes}
                                activityResolutionTypes={
                                    activityResolutionTypes
                                }
                                updateActivityState={this.updateActivityState}
                                addField={this.addField}
                                onFieldChange={this.onFieldChange}
                                addNewTag={this.addNewTag}
                                updateResolution={this.updateResolution}
                                layout={layout}
                                workflowStatusInputTypes={
                                    workflowStatusInputTypes
                                }
                            />
                        </Segment>
                        {edited && (
                            <Form>
                                <Form.Group>
                                    <Form.Field>
                                        <Button
                                            type="submit"
                                            onClick={
                                                isNewVersion
                                                    ? () =>
                                                          this.toggleVersionNotes(
                                                              true,
                                                          )
                                                    : this.saveActivity
                                            }
                                            loading={saving}
                                            disabled={saving || !edited}
                                        >
                                            {isActivityCurrent
                                                ? `Save Changes`
                                                : `Submit`}
                                        </Button>
                                    </Form.Field>
                                    <Form.Field floated="right">
                                        {isActivityCurrent && edited && (
                                            <Checkbox
                                                label="Create New Version"
                                                onChange={
                                                    this.handleNewVersionClick
                                                }
                                                checked={isNewVersion}
                                                name="isNewVersion"
                                                className="new-version-checkbox"
                                            />
                                        )}
                                    </Form.Field>
                                    <Form.Field floated="right">
                                        {isActivityCurrent && edited && (
                                            <Checkbox
                                                label="Override update check"
                                                onChange={
                                                    this
                                                        .handleOverrideCheckClick
                                                }
                                                checked={overrideUpdateChecks}
                                                name="overrideUpdateChecks"
                                                className="new-version-checkbox"
                                            />
                                        )}
                                    </Form.Field>
                                </Form.Group>
                            </Form>
                        )}
                        {errorMessage.length > 0 && (
                            <Message
                                floated="right"
                                header={`${
                                    activityTypes.CURRENT
                                        ? 'Editing'
                                        : 'Creating'
                                } of the activity is not allowed for the following reason${
                                    errorMessage.length > 1 ? 's' : ''
                                }:`}
                                error
                                list={errorMessage}
                            />
                        )}
                    </React.Fragment>
                )}
            </div>
        );
    }
}

ActivityForm.propTypes = {
    activity: PropTypes.object.isRequired,
    tags: PropTypes.array.isRequired,
    parties: PropTypes.array.isRequired,
    activityStatuses: PropTypes.array.isRequired,
    activityType: PropTypes.string,
    currentUser: PropTypes.object.isRequired,
    activityFieldTypes: PropTypes.array,
    activityResolutionTypes: PropTypes.array,
    activityActions: PropTypes.object,
    partyActions: PropTypes.object,
    tagActions: PropTypes.object,
    createActivityAction: PropTypes.func,
    dispatch: PropTypes.func,
    uiActions: PropTypes.object,
    activityId: PropTypes.string,
    activeMenuItem: PropTypes.string,
    layout: PropTypes.string,
    cloneActivity: PropTypes.bool,
    workflowStatusInputTypes: PropTypes.array,
};

function mapStateToProps(state, ownProps) {
    const { activityId, cloneActivity } = ownProps;

    // Add null checks
    const activity =
        activityId && !cloneActivity
            ? (state.activity &&
                  state.activity.byId &&
                  state.activity.byId[activityId]) ||
              {}
            : (state.activity &&
                  state.activity.byId &&
                  state.activity.byId['NEW']) ||
              {};

    return {
        activity,
        activityType:
            activityId && !cloneActivity
                ? activityTypes.CURRENT
                : activityTypes.NEW,
        cloneActivity: cloneActivity,
        tags: state.tags || [],
        parties: state.parties ? state.parties.list || [] : [],
        activityStatuses: state.constants
            ? state.constants.activityStatuses || []
            : [],
        activityFieldTypes: state.constants
            ? state.constants.activityFieldTypes || []
            : [],
        activityResolutionTypes: state.constants
            ? state.constants.activityResolutionTypes || []
            : [],
        createActivityAction: state.uiStatus
            ? state.uiStatus.createActivityAction
            : null,
        workflowStatusInputTypes: state.constants
            ? state.constants.workflowStatusInputType || []
            : [],
    };
}

function mapDispatchToProps(dispatch) {
    return {
        activityActions: bindActionCreators(activityActions, dispatch),
        activitiesActions: bindActionCreators(activitiesActions, dispatch),
        partyActions: bindActionCreators(partyActions, dispatch),
        tagActions: bindActionCreators(tagActions, dispatch),
        uiActions: bindActionCreators(uiActions, dispatch),
        dispatch: dispatch,
    };
}

export default UserWrapper(
    connect(mapStateToProps, mapDispatchToProps)(ActivityForm),
);
