import _ from 'lodash';

export function extractData({ dataSet, dataRow, data, key }) {
    return data[dataSet].map((row) => ({ [key]: row[dataRow] }));
}

export function mergeDataCall({
    dashboardWhereFilters,

    dashboardProperties,
    allowedProperties,
    dataCalls,
    id,
    extraParams,
}) {
    const clonedDataCalls = _.cloneDeep(dataCalls);

    _.forEach(dashboardProperties, (value, key) => {
        const allowedPropertiesFilter = _.filter(allowedProperties, {
            name: key,
        });

        _.forEach(allowedPropertiesFilter, (allowedProperty) => {
            if (allowedProperty && !(_.isArray(value) && value.length === 0)) {
                _.forEach(clonedDataCalls, (datacall, index) => {
                    if (
                        allowedProperty.dataSet &&
                        index !== allowedProperty.dataSet
                    )
                        return;
                    _.set(
                        clonedDataCalls[index],
                        'params.' + allowedProperty.argument,
                        value,
                    );
                });
            }
        });
    });

    _.forEach(dashboardWhereFilters, (value, key) => {
        if (id && id === key) return;
        for (const call of _.keys(dataCalls)) {
            clonedDataCalls[call].params.relationFilter.wheres = _.merge(
                {},
                clonedDataCalls[call].params.relationFilter.wheres,
                value,
            );
            for (const join of _.keys(value)) {
                if (
                    _.findIndex(
                        clonedDataCalls[call].params.relationFilter.joins,
                        (j) => {
                            return !!_.get(j, join);
                        },
                    ) === -1
                ) {
                    const newJoin = _.reduce(
                        _.reverse(join.split('.')),
                        function (result, value) {
                            if (!result) return value;
                            return { [value]: result };
                        },
                        null,
                    );

                    for (let [index, filterJoin] of clonedDataCalls[
                        call
                    ].params.relationFilter.joins.entries()) {
                        if (
                            (_.isObject(filterJoin) &&
                                _.get(filterJoin, _.keys(newJoin)[0])) ||
                            filterJoin === _.keys(newJoin)[0]
                        ) {
                            if (_.isString(filterJoin))
                                filterJoin = { [filterJoin]: true };
                            const merge = _.mergeWith(
                                filterJoin,
                                newJoin,
                                this.mergeFunc,
                            );
                            filterJoin = merge;
                            clonedDataCalls[call].params.relationFilter.joins[
                                index
                            ] = merge;
                        }
                    }
                }
            }
        }
    });

    // add apiParams to dataCalls
    if (extraParams) {
        _.forEach(clonedDataCalls, (datacall, index) => {
            clonedDataCalls[index].params = _.merge(
                {},
                clonedDataCalls[index].params,
                extraParams,
            );
        });
    }
    return clonedDataCalls;
}

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export function difference(object, base) {
    if (!base) return object;
    return _.transform(object, (result, value, key) => {
        if (!_.isEqual(value, base[key])) {
            result[key] =
                _.isObject(value) && _.isObject(base[key])
                    ? difference(value, base[key])
                    : value;
        }
    });
}
