import React from 'react';
import { Editor } from '@toast-ui/react-editor';

const WrappedEditor = (props) => {
    const { forwardedRef } = props;

    // 3. Pass down forwardRef to Editor(the real component that needs)
    return <Editor {...props} ref={forwardedRef} usageStatistics={false} />;
};

// WrappedEditor.propTypes = {
//   props: PropTypes.object,
//   forwardedRef: PropTypes.shape({
//     current: PropTypes.instanceOf(Element)
//   }).isRequired
// }

export default WrappedEditor;
