import * as types from '../constants/actionTypes';

export default function executionsReducer(state = [], action) {
    switch (action.type) {
        case types.LOAD_EXECUTIONS_SUCCESS:
            return action.executions;
        default:
            return state;
    }
}
