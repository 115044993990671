import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';

import ExecutionCategoryMenu from './ExecutionCategoryMenu';
import TicketWrapper from '../ticket/Ticket';
import { createUIState } from '../../reducers/uiStateManager';
import { ExecutionContextConsumer } from '../../context/ExecutionContext';

class ExecutionFull extends Component {
    constructor(props, context) {
        super(props, context);

        createUIState('ExecutionPage', {
            ExecutionCategoryMenu: {
                views: [
                    {
                        name: 'VIEW1',
                        label: 'View 1',
                    },
                    {
                        name: 'VIEW2',
                        label: 'View 2',
                    },
                ],
                currentView: {
                    name: 'VIEW1',
                    label: 'View 1',
                },
            },
        });
    }

    render() {
        const uiState = this.props.uiStatus?.uiStates.ExecutionPage || {};
        let { category, ticketId } = this.props.match.params;
        const { isExecutionLoading } = this.props;
        if (ticketId) ticketId = parseInt(ticketId);
        return (
            <React.Fragment>
                {!isExecutionLoading && (
                    <Grid celled="internally">
                        <Grid.Row columns={2}>
                            <Grid.Column width={16}>
                                <ExecutionCategoryMenu
                                    uiState={
                                        uiState.ExecutionCategoryMenu || {}
                                    }
                                    category={category}
                                    ticketId={ticketId}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                )}
            </React.Fragment>
        );
    }
}

ExecutionFull.propTypes = {
    executionStateActions: PropTypes.object.isRequired,
    currentExecution: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    uiStatus: PropTypes.object,
    history: PropTypes.object,
    isExecutionLoading: PropTypes.bool,
};

export default TicketWrapper(ExecutionContextConsumer(ExecutionFull));
