import { useState } from 'react';
import { Form, Checkbox, Divider, Select } from 'semantic-ui-react';
import { mapToOptions } from '../../utils/React';
import Fields from './fields/Fields';
import FormBuilderFieldType from './FormBuildFieldType';
import ActionButtons from './ActionButtons';
import FieldOptions from './fields/options/FieldOptions';
import JSONBuilder from './fields/JSONBuilder';
import { ModelSearch } from './search/ModelSearch';
import FormField from './FormField';
import {
    ActivityFieldType,
    Field,
    FieldTypeId,
    Status,
    FieldOptionValue,
} from '../../types';
import React from 'react';
import { useAppSelector } from '../../actions/store';

type FormBuilderItemProps = {
    field: Field;
    onFieldChange: (index: number, value: Partial<Field>) => void;
    activityFieldTypes: ActivityFieldType[];
    layout?: 'WIDE';
    workflowStatusInputTypes?: Array<{ id: string }>;
    lookUpTypesOptions: any[];
    ratingTypesOptions: any[];
};

export const FormBuilderItem = ({
    field,
    onFieldChange,
    activityFieldTypes,
    layout,
    workflowStatusInputTypes,
    lookUpTypesOptions,
    ratingTypesOptions,
}: FormBuilderItemProps) => {
    const [showDataFields, setShowDataFields] = useState(false);

    const statuses = useAppSelector((state) =>
        state.status.statuses.filter((status) => !status.deleted),
    );

    const {
        originalIndex,
        label,
        fieldKey,
        fieldType,
        options = null,
        optionsValue = null,
        dataFieldReference,
        dataField,
        required,
        displayShortLabel,
        activityFieldTypeId,
        workflowStatusInputTypeId,
        displayLogic,
        defaultValue,
        thresholdFieldRef,
        description,
        descriptionOnScreen,
        isReference,
    } = field;
    const FieldOption =
        fieldType.name in FieldOptions
            ? FieldOptions[fieldType.name]
            : undefined;

    const handleFieldChange = (
        e: unknown,
        {
            name,
            value,
            checked,
        }: { name: string; value?: any; checked?: boolean },
    ) => {
        const newValue = { ...field, [name]: value ?? checked };
        if (name === 'isReference' && !checked) {
            // Clear reference metric when unchecking isReference
            const { options: currentOptions = {} } = newValue;
            const { referenceMetricId: _, ...restOptions } = currentOptions;
            newValue.options = restOptions;
        }
        delete newValue.originalIndex;
        onFieldChange(originalIndex, newValue);
    };

    const handleReferenceMetricChange = (
        e: unknown,
        { value }: { value: string },
    ) => {
        const newValue = {
            ...field,
            options: {
                ...(field.options || {}),
                referenceMetricId: value,
            },
        } as Field;
        delete newValue.originalIndex;
        onFieldChange(originalIndex, newValue);
    };

    const handleFieldTypeChange = (fieldTypeId: string) => {
        if (activityFieldTypeId === fieldTypeId) return;
        const newFieldType = activityFieldTypes.find(
            ({ id }) => id === fieldTypeId,
        );
        if (!newFieldType) return;

        const newValue = {
            ...field,
            activityFieldTypeId: fieldTypeId as FieldTypeId,
            fieldType: newFieldType,
            options: newFieldType.requiresOptions ? options : null,
        } as Partial<Field>;

        delete newValue.originalIndex;
        onFieldChange(originalIndex, newValue);
    };

    const handleChangeOptions = (optionsValue: any) => {
        const newValue = { ...field, options: optionsValue };
        onFieldChange(originalIndex, newValue);
    };

    const handleSelect = (value: any) => {
        const newValue = {
            ...field,
            options: {
                values: value,
            },
            optionsValue: value,
        };
        delete newValue.originalIndex;
        onFieldChange(originalIndex, newValue);
    };

    const changeSort = (increment: number) => {
        const { sort = 0 } = field;
        const newValue = {
            ...field,
            sort: sort + increment,
        };
        delete newValue.originalIndex;
        onFieldChange(originalIndex, newValue);
    };

    const deleteField = () => {
        onFieldChange(originalIndex, {});
    };

    const statusTypes = workflowStatusInputTypes?.map(({ id }) => ({
        id,
        name: id.toLowerCase().replace(/\b\w/g, (c) => c.toUpperCase()),
    }));

    const statusTypeOptions = mapToOptions(statusTypes, 'name');

    const getFieldOptions = () => {
        if (field.activityFieldTypeId === 'Rating') {
            return ratingTypesOptions;
        }
        return lookUpTypesOptions;
    };

    if (layout === 'WIDE') {
        return (
            <React.Fragment key={originalIndex}>
                <Form>
                    <Form.Group widths="equal">
                        <Fields.Text
                            placeholder="Field Label"
                            value={label || ''}
                            name="label"
                            onChange={handleFieldChange}
                        />
                        <Form.Field>
                            <FormBuilderFieldType
                                value={field}
                                activityFieldTypes={activityFieldTypes}
                                changeFieldType={handleFieldTypeChange}
                            />
                        </Form.Field>
                        {fieldType.requiresOptions && (
                            <Form.Field>
                                {FieldOption ? (
                                    <FieldOption
                                        value={options}
                                        onChange={handleChangeOptions}
                                    />
                                ) : (
                                    <Select
                                        value={optionsValue}
                                        fluid
                                        options={getFieldOptions()}
                                        onChange={(_, { value }) =>
                                            handleSelect(value)
                                        }
                                    />
                                )}
                            </Form.Field>
                        )}
                        {/* ... rest of the wide layout JSX ... */}
                    </Form.Group>
                </Form>
            </React.Fragment>
        );
    }

    let optionsCurrent =
        (field.activityFieldTypeId === 'Select' ||
            field.activityFieldTypeId === 'SearchableSelect' ||
            field.activityFieldTypeId === 'MultiSelect' ||
            field.activityFieldTypeId === 'Rating' ||
            field.activityFieldTypeId === 'RadioGroup' ||
            field.activityFieldTypeId === 'CheckboxGroup') &&
        field.options?.values
            ? field.options.values
            : field.options;

    return (
        <React.Fragment key={originalIndex}>
            <Form>
                <Form.Group widths="equal">
                    <Fields.Text
                        label="Field Label"
                        value={label || ''}
                        name="label"
                        onChange={handleFieldChange}
                        width={4}
                    />
                    <Fields.Text
                        label="Field Key"
                        value={fieldKey || ''}
                        name="fieldKey"
                        onChange={handleFieldChange}
                        width={1}
                    />
                    <Form.Field width={1}>
                        <label>Field Type</label>
                        <FormBuilderFieldType
                            value={field}
                            activityFieldTypes={activityFieldTypes}
                            changeFieldType={handleFieldTypeChange}
                        />
                    </Form.Field>
                    {fieldType.requiresOptions ? (
                        <Form.Field width={2}>
                            <label>Options</label>
                            {FieldOption ? (
                                <FieldOption
                                    value={options}
                                    onChange={handleChangeOptions}
                                />
                            ) : (
                                <Select
                                    value={optionsValue}
                                    fluid
                                    options={getFieldOptions()}
                                    onChange={(_, { value }) =>
                                        handleSelect(value)
                                    }
                                />
                            )}
                        </Form.Field>
                    ) : null}

                    <Form.Field width={2}>
                        <label>Actions</label>
                        <ActionButtons
                            upClicked={() => changeSort(-1)}
                            downClicked={() => changeSort(1)}
                            deleteClicked={deleteField}
                            viewClicked={
                                !showDataFields
                                    ? () => setShowDataFields(true)
                                    : undefined
                            }
                            hideClicked={
                                showDataFields
                                    ? () => setShowDataFields(false)
                                    : undefined
                            }
                        />
                    </Form.Field>
                </Form.Group>

                {showDataFields && (
                    <>
                        <Form.Group widths="equal">
                            <Form.Field width={5}>
                                <label>Threshold</label>
                                <ModelSearch
                                    name="thresholdFieldRef"
                                    modelName="ActivityField"
                                    value={thresholdFieldRef}
                                    onChange={handleFieldChange}
                                    valueProperty="fieldKey"
                                    refProperty="fieldKey"
                                />
                            </Form.Field>
                            <Fields.Text
                                label="Display Short Label"
                                name="displayShortLabel"
                                value={displayShortLabel || ''}
                                onChange={handleFieldChange}
                                width={6}
                            />
                            <Form.Field width={4}>
                                <label>Assign field to workflow status</label>
                                <Form.Field
                                    control={Select}
                                    options={statusTypeOptions}
                                    placeholder="Workflow Status Type"
                                    onChange={handleFieldChange}
                                    value={workflowStatusInputTypeId}
                                    name="workflowStatusInputTypeId"
                                />
                            </Form.Field>
                            <Form.Field width={1}>
                                <label>Data Field</label>
                                <Form.Input
                                    name="dataField"
                                    control={Checkbox}
                                    checked={dataField || false}
                                    onChange={(e, data) =>
                                        handleFieldChange(e, {
                                            name: 'dataField',
                                            value: data.checked,
                                        })
                                    }
                                />
                            </Form.Field>
                            <Form.Field width={1}>
                                <label>Required</label>
                                <Form.Input
                                    name="required"
                                    control={Checkbox}
                                    checked={required || false}
                                    onChange={(e, data) =>
                                        handleFieldChange(e, {
                                            name: 'required',
                                            value: data.checked,
                                        })
                                    }
                                />
                            </Form.Field>
                            <Form.Field width={1}>
                                <label>Reference</label>
                                <Form.Input
                                    name="isReference"
                                    control={Checkbox}
                                    checked={isReference || false}
                                    onChange={(e, data) =>
                                        handleFieldChange(e, {
                                            name: 'isReference',
                                            value: data.checked,
                                        })
                                    }
                                />
                            </Form.Field>
                        </Form.Group>

                        {isReference && (
                            <Form.Group widths="equal">
                                <Form.Field width={16}>
                                    <label>Reference Metric</label>
                                    <Select
                                        fluid
                                        placeholder="Select Reference Metric"
                                        options={statuses.map((status) => ({
                                            key: status.id,
                                            value: status.id,
                                            text: status.label,
                                        }))}
                                        value={
                                            field.options?.referenceMetricId ||
                                            ''
                                        }
                                        onChange={handleReferenceMetricChange}
                                    />
                                </Form.Field>
                            </Form.Group>
                        )}

                        <Form.Group widths="equal">
                            <Form.Field width={8}>
                                <Fields.TextArea
                                    label="Description"
                                    value={description || ''}
                                    name="description"
                                    onChange={handleFieldChange}
                                />
                                <Fields.CheckBoxBool
                                    label="Description on screen"
                                    value={descriptionOnScreen}
                                    name="descriptionOnScreen"
                                    onChange={handleFieldChange}
                                />
                                <label>Default Value</label>
                                <FormField
                                    field={{
                                        ...field,
                                        options: optionsCurrent,
                                    }}
                                    value={defaultValue}
                                    name="defaultValue"
                                    handleFieldDataChange={(e, data) => {
                                        handleFieldChange(e, {
                                            name: 'defaultValue',
                                            value: data,
                                        });
                                    }}
                                />
                            </Form.Field>
                            <Form.Field width={8}>
                                <label>Display Logic</label>
                                <JSONBuilder
                                    name="displayLogic"
                                    value={displayLogic}
                                    onChange={handleFieldChange}
                                    allowModeChange={true}
                                />
                                <label>Options</label>
                                <JSONBuilder
                                    name="options"
                                    value={options}
                                    onChange={handleFieldChange}
                                    allowModeChange={true}
                                />
                            </Form.Field>
                        </Form.Group>
                    </>
                )}
            </Form>
            <Divider />
        </React.Fragment>
    );
};

export default FormBuilderItem;
