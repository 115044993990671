import React, { Component } from 'react';
import { Confirm } from 'semantic-ui-react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { extractFunctions } from '../../utils/React';

import * as uiActions from './../../actions/uiActions';
import ConfirmationModalContext from '../../context/ConfirmationModalContext';
import { SIDEBAR_COMPONENTS } from './sidebarComponents';

function ConfirmWrapper(ComponentToWrap) {
    class ConfirmRoot extends Component {
        state = {
            showConfirm: false,
            content: '',
        };

        showConfirmDialog = (
            content,
            onConfirm,
            onReject = null,
            confirmButton = null,
            cancelButton = null,
        ) => {
            this.onConfirm = onConfirm;
            this.onReject = onReject;
            this.confirmButton = confirmButton;
            this.cancelButton = cancelButton;
            this.setState({ showConfirm: true, content });
        };

        hideConfirmDialog = () => {
            this.setState({ showConfirm: false });
        };

        handleConfirm = () => {
            this.onConfirm();
            this.hideConfirmDialog();
        };

        handleReject = () => {
            this.onReject && this.onReject();
            this.hideConfirmDialog();
        };

        showRelationshipSidebar = (relationship) => {
            const { uiActions } = this.props;
            uiActions.showSidebar({
                sidebarType: SIDEBAR_COMPONENTS.RELATIONSHIP_INFO_SIDEBAR,
                sidebarProps: {
                    relationshipId: relationship.id,
                    partyId: relationship.fromPartyId,
                },
                sidebarConfig: { width: 'wide' },
            });
        };

        render() {
            const values = {
                confirmPopupActions: extractFunctions(this),
            };
            const { showConfirm, content } = this.state;
            return (
                <React.Fragment>
                    <ConfirmationModalContext.Provider value={values}>
                        <ComponentToWrap {...values} {...this.props} />
                    </ConfirmationModalContext.Provider>
                    <Confirm
                        content={content}
                        open={showConfirm}
                        onCancel={this.handleReject}
                        onConfirm={this.handleConfirm}
                        cancelButton={
                            this.cancelButton ? this.cancelButton : 'Cancel'
                        }
                        confirmButton={
                            this.confirmButton ? this.confirmButton : 'OK'
                        }
                    />
                </React.Fragment>
            );
        }
    }

    ConfirmRoot.propTypes = {
        uiStatus: PropTypes.object,
        uiActions: PropTypes.object,
    };

    function mapStateToProps(state) {
        return {
            uiStatus: state.uiStatus,
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            uiActions: bindActionCreators(uiActions, dispatch),
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(ConfirmRoot);
}

export default ConfirmWrapper;
