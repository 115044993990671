import React from 'react';

const WorkflowContext = React.createContext();

export const WorkflowWrapContext = (ComponentToWrap) =>
    class WorkflowWrapContext extends React.Component {
        render() {
            return (
                <WorkflowContext.Consumer>
                    {(context) => {
                        return <ComponentToWrap {...context} {...this.props} />;
                    }}
                </WorkflowContext.Consumer>
            );
        }
    };

export default WorkflowContext;
