import update from 'immutability-helper';

import initialState from './initialState';
import * as types from '../constants/actionTypes';

export default function listFiltersReducer(
    state = initialState.listFiltersData,
    action,
) {
    switch (action.type) {
        case `${types.SET_LIST_FILTERS}`: {
            const { route, filters } = action;
            return update(state, {
                $merge: {
                    [route]: {
                        ...state[route],
                        filters,
                    },
                },
            });
        }
        case `${types.SET_LIST_PAGINATION}`: {
            const { route, pagination } = action;
            return update(state, {
                $merge: {
                    [route]: {
                        ...state[route],
                        pagination,
                    },
                },
            });
        }
        default:
            return state;
    }
}
