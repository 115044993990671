import React from 'react';
import { Form, Table, Checkbox } from 'semantic-ui-react';
import _ from 'lodash';
import SubmitInput from './SubmitInput';
import OptionsList from './OptionsList';
import FieldOptions from '../common/fields/options/FieldOptions';
import FormBuilderFieldType from './FormBuildFieldType';
import ActionButtons from './ActionButtons';
import { ActivityFieldType, Field, FieldType, FieldTypeId } from '../../types';

interface FieldTypeProps {
    id: string;
    name: string;
    requiresOptions: boolean;
}

interface FormBuilderFieldProps {
    value: Field;
    fieldIndex: number;
    onChange: (fieldIndex: number, newValue: Field) => void;
    activityFieldTypes: ActivityFieldType[];
    editable?: boolean;
}

const FormBuilderField = ({
    value,
    fieldIndex,
    onChange,
    activityFieldTypes,
    editable,
}: FormBuilderFieldProps) => {
    const handleLabelChange = (value, data, fieldIndex) => {
        const newValue = Object.assign({}, value, {
            label: data.value,
        });
        delete newValue.originalIndex;
        onChange(fieldIndex, newValue);
    };
    const handleKeyChange = (value, data, fieldIndex) => {
        const newValue = Object.assign({}, value, {
            fieldKey: data.value,
        });
        delete newValue.originalIndex;
        onChange(fieldIndex, newValue);
    };
    const changeSort = (value, increment, fieldIndex) => {
        const newValue = Object.assign({}, value, {
            sort: value.sort + increment,
        });
        delete newValue.originalIndex;
        onChange(fieldIndex, newValue);
    };
    const deleteField = (fieldIndex) => {
        onChange(fieldIndex, null);
    };

    const addOption = (event, data) => {
        if (data.value === '') return;
        const newValue = Object.assign({}, value, {
            options: {
                values: value.options.values
                    ? [...value.options.values, data.value]
                    : [data.value],
            },
        });
        delete newValue.originalIndex;
        onChange(fieldIndex, newValue);
    };

    // const removeOption = (optionIndex) => {
    //   const newValue = Object.assign({}, value, {
    //     options: {
    //       values: value.options.values.filter(
    //         (option, index) => index != optionIndex
    //       ),
    //     },
    //   });
    //   delete newValue.originalIndex;
    //   onChange(fieldIndex, newValue);
    // };

    const changeFieldType = (id: FieldTypeId) => {
        if (value.activityFieldTypeId === id) return;
        const newFieldType = _.find(activityFieldTypes, { id: id });
        const newValue = Object.assign({}, value, {
            activityFieldTypeId: newFieldType.id,
            fieldType: newFieldType,
            options: newFieldType.requiresOptions ? value.options : [],
        });
        delete newValue.originalIndex;
        onChange(fieldIndex, newValue);
    };

    const handleChangeOptions = (optionsValue) => {
        const newValue = Object.assign({}, value, {
            options: optionsValue,
        });
        onChange(fieldIndex, newValue);
    };

    const handleFieldChanged = (name, newValue) => {
        const updatedValue = Object.assign({}, value, {
            ...value,
            [name]: newValue,
        });
        onChange(fieldIndex, updatedValue);
    };

    let FieldOption;

    if (value.fieldType && FieldOptions[value.fieldType.name])
        FieldOption = FieldOptions[value.fieldType.name];

    const {
        label,
        fieldKey,
        fieldType,
        options,
        dataFieldReference,
        dataField,
        required,
        displayShortLabel,
    } = value;
    return (
        <Table.Row>
            <Table.Cell width={2}>
                {editable ? (
                    <Form>
                        <Form.Field>
                            <Form.Input
                                placeholder="Field Label"
                                value={label || ''}
                                onChange={(e, data) =>
                                    handleLabelChange(value, data, fieldIndex)
                                }
                                fluid
                            />
                        </Form.Field>
                    </Form>
                ) : (
                    <span>{label}</span>
                )}
            </Table.Cell>
            <Table.Cell collapsing>
                {editable ? (
                    <Form>
                        <Form.Field>
                            <Form.Input
                                placeholder="Field Key"
                                value={fieldKey || ''}
                                onChange={(e, data) =>
                                    handleKeyChange(value, data, fieldIndex)
                                }
                                fluid
                            />
                        </Form.Field>
                    </Form>
                ) : (
                    <span>{fieldKey}</span>
                )}
            </Table.Cell>
            <Table.Cell collapsing>
                {editable ? (
                    <FormBuilderFieldType
                        value={value}
                        activityFieldTypes={activityFieldTypes}
                        changeFieldType={changeFieldType}
                    />
                ) : (
                    <span>{fieldType.name}</span>
                )}
            </Table.Cell>
            <Table.Cell collapsing className="collapsing-min-width">
                {value.fieldType.requiresOptions &&
                    (FieldOption ? (
                        <FieldOption
                            value={options}
                            onChange={handleChangeOptions}
                        />
                    ) : (
                        <div>
                            {
                                editable ? (
                                    <React.Fragment>
                                        {/* <OptionsList
                    value={options.values || []}
                    onRemove={removeOption}
                    editable={editable}
                  /> */}
                                        <SubmitInput
                                            placeholder="Add Option"
                                            onSubmit={addOption}
                                            name="option"
                                        />
                                    </React.Fragment>
                                ) : null
                                // <OptionsList
                                //   value={options.values}
                                //   onRemove={removeOption}
                                //   editable={editable}
                                // />
                            }
                        </div>
                    ))}
            </Table.Cell>

            <Table.Cell collapsing>
                {editable ? (
                    <Form.Field>
                        <Form.Input
                            name="dataFieldReference"
                            placeholder="Data Field Reference"
                            value={dataFieldReference || ''}
                            onChange={(e, data) =>
                                handleFieldChanged(data.name, data.value)
                            }
                            fluid
                        />
                    </Form.Field>
                ) : (
                    <span>{dataFieldReference}</span>
                )}
            </Table.Cell>

            <Table.Cell collapsing>
                {editable ? (
                    <Form.Field>
                        <Form.Input
                            name="displayShortLabel"
                            placeholder="Display Short Label"
                            value={displayShortLabel || ''}
                            onChange={(e, data) =>
                                handleFieldChanged(data.name, data.value)
                            }
                            fluid
                        />
                    </Form.Field>
                ) : (
                    <span>{displayShortLabel}</span>
                )}
            </Table.Cell>

            <Table.Cell collapsing>
                {editable ? (
                    <Form.Field>
                        <Form.Input
                            name="dataField"
                            control={Checkbox}
                            placeholder="Data Field"
                            checked={dataField || ''}
                            onChange={(e, data) =>
                                handleFieldChanged(data.name, data.checked)
                            }
                            fluid
                        />
                    </Form.Field>
                ) : (
                    <Checkbox checked={dataField} readOnly />
                )}
            </Table.Cell>

            <Table.Cell collapsing>
                {editable ? (
                    <Form.Field>
                        <Form.Input
                            name="required"
                            control={Checkbox}
                            placeholder="Required"
                            checked={required || ''}
                            onChange={(e, data) =>
                                handleFieldChanged(data.name, data.checked)
                            }
                            fluid
                        />
                    </Form.Field>
                ) : (
                    <Checkbox checked={required} readOnly />
                )}
            </Table.Cell>

            {editable && (
                <Table.Cell collapsing>
                    <ActionButtons
                        upClicked={() => changeSort(value, -1, fieldIndex)}
                        downClicked={() => changeSort(value, 1, fieldIndex)}
                        deleteClicked={() => deleteField(fieldIndex)}
                    />
                </Table.Cell>
            )}
        </Table.Row>
    );
};

export default FormBuilderField;
