import * as types from '../constants/actionTypes';
import userApi from '../api/userApi';
import { checkValidation, transformValidation } from '../utils/Validation';
import ValidationSchema from '../utils/ValidationSchema';
import { clearLocalStorage } from '../reducers';

export function logout() {
    clearLocalStorage();
    return {
        type: `${types.USER_LOGOUT}_CURRENT`,
    };
}

export function login(email, password) {
    return function (dispatch) {
        const loginCredentials = { email, password };
        const validation = checkValidation(
            loginCredentials,
            ValidationSchema.loginCredentials,
        );

        if (!validation.valid) {
            return Promise.reject({ validationErrors: validation.errors });
        }

        return userApi.login({ email, password }).then((response) => {
            if ('details' in response) {
                return dispatch({
                    type: `${types.USER_LOGIN_SUCCESS}_CURRENT`,
                    response,
                });
            } else {
                return dispatch({
                    type: types.USER_EXTRA_AUTH_REQUIRED,
                    response,
                });
            }
        });
    };
}

export function verifySamlToken(token) {
    return function (dispatch) {
        return userApi.verifySamlToken(token).then((response) => {
            if ('details' in response) {
                return dispatch({
                    type: `${types.USER_LOGIN_SUCCESS}_CURRENT`,
                    response,
                });
            } else {
                return dispatch({
                    type: types.USER_EXTRA_AUTH_REQUIRED,
                    response,
                });
            }
        });
    };
}

export function verifySMSCode({ token, code, onlyVerify }) {
    return function (dispatch) {
        return userApi
            .verifySMSCode({ token, code, onlyVerify })
            .then((response) => {
                if ('details' in response) {
                    return dispatch({
                        type: `${types.USER_LOGIN_SUCCESS}_CURRENT`,
                        response,
                    });
                } else {
                    return dispatch({
                        type: types.USER_EXTRA_AUTH_REQUIRED,
                        response,
                    });
                }
            });
    };
}

export function loginAsUser(userId) {
    return function (dispatch) {
        return userApi.loginAsUser(userId).then((response) => {
            return dispatch({
                type: `${types.USER_LOGIN_SUCCESS}_CURRENT`,
                response,
            });
        });
    };
}

export function returnToOriginalUser() {
    return function (dispatch) {
        return userApi.returnToOriginalUser().then((response) => {
            return dispatch({
                type: `${types.USER_LOGIN_SUCCESS}_CURRENT`,
                response,
            });
        });
    };
}

export function renewToken() {
    return function (dispatch) {
        return userApi.renewToken().then((response) => {
            return dispatch({
                type: `${types.USER_LOGIN_SUCCESS}_CURRENT`,
                response,
            });
        });
    };
}

export function loadUsers() {
    return function (dispatch) {
        return userApi.getUsers().then((users) => {
            return dispatch({
                type: `${types.LOAD_USERS_SUCCESS}_CURRENT`,
                users,
            });
        });
    };
}

export function saveUser(
    firstName,
    lastName,
    email,
    partyId,
    isAdmin,
    receivesEmails,
    userId = null,
    inactive,
    authType,
) {
    return function (dispatch) {
        const newUser = userId
            ? {
                  firstName,
                  lastName,
                  email,
                  partyId,
                  isAdmin,
                  receivesEmails,
                  id: userId,
                  inactive,
                  authType,
              }
            : {
                  firstName,
                  lastName,
                  email,
                  partyId,
                  isAdmin,
                  receivesEmails,
                  inactive,
                  authType,
              };
        const validation = checkValidation(
            newUser,
            ValidationSchema.createPartyUser,
        );

        if (!validation.valid) {
            return Promise.reject(transformValidation(validation));
        }

        return userApi.saveUser(newUser).then((user) => {
            return dispatch({
                type: `${types.SAVE_USER_SUCCESS}`,
                user,
            });
        });
    };
}

export function resetPassword(userId) {
    return function (dispatch) {
        return userApi.resetPassword(userId).then((response) => {
            return dispatch({
                type: `${types.REQUEST_RESET_PASSWORD_SUCCESS}`,
                response,
            });
        });
    };
}

export function updatePassword(email, newPassword, confirmPassword, token) {
    return function (dispatch) {
        const updatePasswordData = { email, newPassword, token };
        const validation = checkValidation(
            { ...updatePasswordData, confirmPassword },
            ValidationSchema.updatePasswordData,
        );

        if (!validation.valid) {
            return Promise.reject({ validationErrors: validation.errors });
        }

        return userApi.updatePassword(updatePasswordData).then((response) => {
            return dispatch({
                type: `${types.UPDATE_PASSWORD_SUCCESS}`,
                response,
            });
        });
    };
}
