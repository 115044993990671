import { FC } from 'react';
import { Icon, Popup } from 'semantic-ui-react';

interface FieldDescriptionViewProps {
    handleOpenSideBar: () => void;
}

const FieldDescriptionView: FC<FieldDescriptionViewProps> = ({
    handleOpenSideBar,
}) => {
    const handleClick = () => {
        handleOpenSideBar();
    };
    return (
        <Popup
            wide
            trigger={
                <Icon
                    style={{ cursor: 'pointer', marginLeft: 3 }}
                    onClick={handleClick}
                    name="question circle outline"
                />
            }
            content={'Click for item guidance'}
            hoverable={true}
            position="bottom center"
        />
    );
};

export default FieldDescriptionView;
