import React from 'react';
import * as PropTypes from 'prop-types';
import { WorkflowWrapContext } from '../../context/WorkflowContext';
import { Card } from 'semantic-ui-react';
import WorkflowListItem from './WorkflowListItem';

const WorkflowList = (props) => {
    const { workflows, onSelect, itemsPerRow = 3 } = props;
    return (
        <Card.Group itemsPerRow={itemsPerRow}>
            {workflows.map((workflow, index) => (
                <WorkflowListItem
                    key={index}
                    workflow={workflow}
                    onSelect={onSelect}
                />
            ))}
        </Card.Group>
    );
};

WorkflowList.propTypes = {
    workflows: PropTypes.array,
    onSelect: PropTypes.func,
    itemsPerRow: PropTypes.number,
};

export default WorkflowList;

export const WithContext = WorkflowWrapContext(WorkflowList);
