import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { Dimmer, Loader } from 'semantic-ui-react';
import toastr from 'toastr';
import * as _ from 'lodash';

import PrincipalRoleTable from './PrincipalRoleTable';
import AddUserPermission from './AddUserPermission';
import Permission from './Permission';
import * as permissionTypes from './../../constants/permissionTypes';
import UserWrapper from '../user/User';
import { permissionFilter } from '../../constants/apiFilters';

class ManagePermissions extends Component {
    state = {
        loading: true,
    };
    componentDidMount() {
        this.loadPermissions();
    }
    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.history, this.props.history))
            this.loadPermissions();
    }
    loadPermissions = () => {
        const { modelStateActions } = this.props;
        const { permissionsNodeId } = this.props;
        this.setState({ loading: true });
        modelStateActions
            .loadPermission(permissionsNodeId, permissionFilter)
            .then(() => {
                this.setState({ loading: false });
            })
            .catch((e) => toastr.error(e.message));
    };
    render() {
        const { loading } = this.state;
        const { permissionsNodeId, currentUser } = this.props;
        const userIsAdmin = currentUser.checkPermissionForNode(
            permissionsNodeId,
            permissionTypes.ADMIN,
        );
        const userIsSuper = currentUser.checkPermissionForNode(
            permissionsNodeId,
            permissionTypes.SUPER,
        );
        return (
            <React.Fragment>
                <Dimmer active={loading} inverted>
                    <Loader disabled={!loading} />
                </Dimmer>
                {!loading && (
                    <PrincipalRoleTable
                        isAdminPermission={userIsAdmin}
                        includeSuperUser={userIsSuper}
                    />
                )}
                {userIsAdmin && (
                    <AddUserPermission
                        structuralNodeId={permissionsNodeId}
                        includeSuperUser={userIsSuper}
                    />
                )}
            </React.Fragment>
        );
    }
}

ManagePermissions.propTypes = {
    permissionsNodeId: PropTypes.number.isRequired,
    modelStateActions: PropTypes.object,
    currentUser: PropTypes.object,
    history: PropTypes.object,
};

export default UserWrapper(Permission(ManagePermissions));
