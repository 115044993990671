import _ from 'lodash';
import ArgTypes from '../ArgTypes';

const mapArrayToObject = function (args) {
    const { dataSet, data, outputKey, maps } = args;
    const dataToMap = data[dataSet];

    const outputValues = _.chain(dataToMap)
        .map((value) => {
            let v = _.cloneDeep(value);

            _.forEach(maps, (mapValue) => {
                const { keyToMap, mapBy, mapTo } = mapValue;

                const valuesToMap = _.get(value, keyToMap);
                const mappedValues = {};

                _.forEach(valuesToMap, (vtm) => {
                    mappedValues[_.get(vtm, mapBy)] = vtm;
                });

                v[mapTo] = mappedValues;
            });

            return v;
        })
        .value();

    return {
        ...args,
        data: { ...data, [outputKey || 'mappedValues']: outputValues },
    };
};

mapArrayToObject.args = {
    dataSet: ArgTypes.string,
    outputKey: ArgTypes.string,
    maps: ArgTypes.json,
};

export default mapArrayToObject;
