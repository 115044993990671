import React from 'react';

import ProRecommendationTable from './custom/pro/ProRecommendationTable';
import ProControlObservation from './custom/pro/ProControlObservation';
import ProContents from './custom/pro/ProContents';
import ProCover from './custom/pro/ProCover';
import ProExecSummary from './custom/pro/ProExecSummary';

const components = {
    ProRecommendationTable,
    ProControlObservation,
    ProContents,
    ProCover,
    ProExecSummary,
};

const CustomComponent = (props) => {
    // eslint-disable-next-line react/prop-types
    const { component } = props;

    if (!component) return null;

    const Component = components[component];
    if (!Component) return null;
    return (
        <React.Fragment>
            <Component {...props} />
        </React.Fragment>
    );
};

export default CustomComponent;
