import React, { useRef } from 'react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import WidgetComponents from '../DashboardWidgetComponents';
import DownloadDropdown from './KpiComponent/DownloadDropdown';
import { downloadCSV, downloadImage } from '../../../../utils/downloadUtils';

const GroupComponent = (props) => {
    const {
        dataSet,
        data,
        items,
        component,
        itemWrapperComponent,
        propsFilter,
        wrapperPropsFilter,
        number,
        numberOffset = 1,
    } = props;
    const ref = useRef();

    let itemsToRender = [];

    if (dataSet && data) {
        itemsToRender = data.data[dataSet];
    }

    if (items) itemsToRender = items;

    let Component;
    let ItemWrapperComponent = React.Fragment;
    let wrapperComponentProps = {};
    let componentProps = props;

    if (component) {
        Component = WidgetComponents[component];
        if (propsFilter)
            componentProps = _.pick(
                props,
                propsFilter.map((pf) => pf.split('~').join('')),
            );
    }
    if (itemWrapperComponent) {
        ItemWrapperComponent = WidgetComponents[itemWrapperComponent];
        if (wrapperPropsFilter)
            wrapperComponentProps = _.pick(props, wrapperPropsFilter);
    }

    const handleDownloadImage = (format) => {
        downloadImage(ref.current, format);
    };
    const handleDownloadCSV = () => {
        const rawTableData = props.items.find(
            (item) => item.component === 'DataTableRow',
        );

        const tableData = rawTableData?.columnNames?.reduce(
            (acc, current, index) => {
                const data = rawTableData.data[index]?.args;
                return {
                    ...acc,
                    [current]: data?.value || data?.source || data?.name,
                };
            },
            {},
        );

        downloadCSV([tableData]);
    };
    const handleDownloadXLSX = () => {
        const rawTableData = props.items.find(
            (item) => item.component === 'DataTableRow',
        );

        const columns = rawTableData?.columnNames?.map((column) => ({
            label: column,
            value: column,
        }));

        const tableData = rawTableData?.data?.reduce(
            (acc, current, index) => {
                return {
                    ...acc,
                    content: [
                        {
                            ...acc.content[0],
                            [rawTableData?.columnNames[index]]:
                                current?.args?.value ||
                                current?.args?.source ||
                                current?.args?.name,
                        },
                    ],
                };
            },
            { sheet: 'data', columns, content: [] },
        );
    };
    return (
        <div ref={ref}>
            {Component && (
                <Component {...componentProps} number={number + numberOffset} />
            )}
            <ItemWrapperComponent {...wrapperComponentProps}>
                {itemsToRender &&
                    itemsToRender.map((item, index) => {
                        const args = {
                            ...item,
                            number: index,
                            groupNumber: number + numberOffset,
                        };
                        delete args.ref;
                        return itemWrapperComponent === 'DataTableWrapper' &&
                            item.component === 'DataTableRow' ? (
                            <>
                                <GroupComponent {...args} key={index} />
                                <DownloadDropdown
                                    {...props}
                                    isKPI={false}
                                    onDownloadImage={handleDownloadImage}
                                    onDownloadCSV={handleDownloadCSV}
                                    onDownloadXLSX={handleDownloadXLSX}
                                />
                            </>
                        ) : (
                            <GroupComponent {...args} key={index} />
                        );
                    })}
            </ItemWrapperComponent>

            {/*<ReactJson src={props} collapsed={true} />*/}
        </div>
    );
};

GroupComponent.propTypes = {
    dataSet: PropTypes.string,
    data: PropTypes.object,
    items: PropTypes.array,
    component: PropTypes.string,
    itemWrapperComponent: PropTypes.string,
    propsFilter: PropTypes.array,
    wrapperPropsFilter: PropTypes.array,
    number: PropTypes.number,
    numberOffset: PropTypes.number,
};

export default GroupComponent;
